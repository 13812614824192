export const testaiusersanswers = (
  singleanswersdata,
  multipleanswersdata,
  marks
) => {
  let answer = {
    panda_test_ai_users_answers_id: 25,
    panda_test_ai_users_answers_regdate: "2019-02-08T06:48:45.000Z",
    panda_test_ai_users_answers_multiple_answers: multipleanswersdata,
    panda_test_ai_users_answers_single_answers: singleanswersdata,
    panda_test_ai_users_answers_student_id: 1,
    panda_test_ai_users_answers_random_test_id: "5c5d6c9dc0e96",
    panda_test_ai_users_answers_all_the_questions: marks,
    unitID: 385,
    total_questions: 10,
    rightf_answers: 1,
    test_time_spent: 0,
    difficulty: "1",
    totalmarks: 10,
    marks: 1,
    test_duration: 0,
    unit_text: "Unit 2: Soil",
    marks_percent: "10",
    panda_test_ai_users_answers_guest_session_id: "guestsession",
  };

  return answer;
};

export const testalgov2aiusersanswers = (answeringmap, questionpull) => {
  let answer = {
    panda_auto_test_algo_v2_users_answers_id: 445,
    panda_auto_test_algo_v2_users_answers_test_question_pull: questionpull,
    panda_auto_test_algo_v2_users_answers_session_batchid:
      "otU7VbaKAA1612696413",
    panda_auto_test_algo_v2_users_answers_submitted_at:
      "2021-02-07T12:13:39.000Z",
    panda_auto_test_algo_v2_users_answers_answering_map: answeringmap,
    panda_auto_test_algo_v2_users_answers_user_of_test_id: 922,
    panda_auto_test_algo_v2_users_answers_session_of_test_id: "SESSIO TEST ID",
  };

  return answer;
};

export default {
  testaiusersanswers,
  testalgov2aiusersanswers,
};
