import { useEffect, useRef } from "react";
import { FeaturesSummary } from "./detailchart";

import { statsOfLastWeek } from "../../../../functions/statsOfLastWeek";
import { student_stats_of_last_week_start } from "../../../../store/actions/student.dashboard.homepage";

import { useDispatch, useSelector } from "react-redux";
const SchoolUserFeatures = (props) => {
  const dispatch = useDispatch();
  const today = useRef(new Date().getTime());
  const studentDashboardhomePage = useSelector(
    (state) => state.studentDashboardhomePage
  );
  useEffect(() => {
    dispatch(
      student_stats_of_last_week_start({
        token: props.token,
        userId: props.userId,
        day: today.current,
      })
    );
  }, []);
  const stats_of_last_week = statsOfLastWeek(
    studentDashboardhomePage.stats_of_last_week
      ? studentDashboardhomePage.stats_of_last_week
      : []
  );
  return (
    <FeaturesSummary
      title="Features"
      color="blue"
      titleColor="blue"
      height={100}
      activities={stats_of_last_week}
      time="This week"
    />
  );
};

export default SchoolUserFeatures;
