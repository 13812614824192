import { useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import GetStarted from "../../../../common/features/GetStarted";
// import pageContent from "../../../guest/guesttest/pageContent";
import TabSelector from "../../../../elements/tab/TabSelector";
import NotFound from "../../../../common/features/NotFound";
import { FeatureItemCard } from "../../../../elements/card";
import { CardBody, FeatureTitle } from "../../../../elements/text";
import Input from "../../../../elements/input";
import { changeChosenUnitSummarynotes } from "../../../../../store/actions/features/summarynotes";
import { useDispatch, useSelector } from "react-redux";
import PageContent from "../../../guest/guesttest/pageContent";
import Sidebar from "../sidebar";
import Topnavbar from "../topnavbar";
import ScrollToTop from "../../../../common/ScrollToTop";

const SummaryNotes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchterm, setSearchterm] = useState("");
  const units = [
    {
      name: "Deserunt laboris",
      viewed: true,
      notes: [
        {
          title: "Note Title",
          author: "John",
          type: "opanda",
          imgsrc: "/images/preview/bio.svg",
        },
        {
          title: "Note Title",
          author: "John",
          type: "community",
          imgsrc: "/images/preview/bio.svg",
        },
        {
          title: "Note Title",
          author: "John",
          type: "community",
          imgsrc: "/images/preview/bio.svg",
        },
        {
          title: "Note Title",
          author: "John",
          type: "community",
          imgsrc: "/images/preview/bio.svg",
        },
      ],
    },
    {
      name: "Adipisicing ",
      viewed: false,
      notes: [
        {
          title: "Note Title",
          author: "John",
          type: "opanda",
          imgsrc: "/images/preview/bio.svg",
        },
        {
          title: "Note Title",
          author: "John",
          type: "community",
          imgsrc: "/images/preview/bio.svg",
        },
      ],
    },
    {
      name: "Dolore minim",
      viewed: false,
      notes: [
        {
          title: "Note Title",
          author: "John",
          type: "opanda",
          imgsrc: "/images/preview/bio.svg",
        },
      ],
    },
    {
      name: "Dolore ex ",
      viewed: false,
      notes: [
        {
          title: "Note Title",
          author: "John",
          type: "opanda",
          imgsrc: "/images/preview/bio.svg",
        },
      ],
    },
    {
      name: "Sint eiusmod fugiat.",
      viewed: false,
      notes: [
        {
          title: "Note Title",
          author: "John",
          type: "opanda",
          imgsrc: "/images/preview/bio.svg",
        },
      ],
    },
    {
      name: "Adipisicing occaecat",
      viewed: false,
      notes: [
        {
          title: "Note Title",
          author: "John",
          type: "opanda",
          imgsrc: "/images/preview/bio.svg",
        },
      ],
    },
    {
      name: "Dolore minimum",
      viewed: false,
      notes: [
        {
          title: "Note Title",
          author: "John",
          type: "opanda",
          imgsrc: "/images/preview/bio.svg",
        },
      ],
    },
    {
      name: "Dolore ex enim",
      viewed: false,
      notes: [
        {
          title: "Note Title",
          author: "John",
          type: "opanda",
          imgsrc: "/images/preview/bio.svg",
        },
      ],
    },
  ];
  const tabs = units.map((unit) => unit.name);
  tabs.unshift("initial page");
  const chosenUnit = useSelector(
    (state) => state.features.summarynotes.chosenUnit
  );
  const [selectedUnit, setSelectedUnit] = useTabs(
    tabs,
    chosenUnit ? chosenUnit : "initial page"
  );
  const [showUnits, setShowUnits] = useState(false);
  const [subject, setSubject] = useState("Biology");

  const [selectedTab, setSelectedTab] = useTabs(
    ["All", "O'Genius Panda", "Community"],
    "O'Genius Panda"
  );
  const [index, setindex] = useState(0);
  return (
    <div className="bg-white h-full w-full grid grid-cols-3 md:grid-cols-4">
    <Sidebar setindex={setindex} />
    <Topnavbar />
    <div className={`md:grid grid-cols-12 w-full min-h-screen-sec`}>
      <div
        className={`p-3 bg-gray-200 ${
          showUnits ? "h-screen-sec" : ""
        } md:h-full col-span-3`}
      >
        <div className="flex justify-between items-center">
          <div className="w-50%">
            <Input
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [
                  { value: "Biology", displayValue: "Biology" },
                  { value: "Chemistry", displayValue: "Chemistry" },
                  { value: "Physics", displayValue: "Physics" },
                  { value: "Math", displayValue: "Math" },
                  { value: "Geography", displayValue: "Geography" },
                ],
              }}
              value={subject}
              changed={setSubject}
              validation={{ required: true }}
              shouldValidate
              error="Subject is required"
            />
          </div>
          <CardBody name="Senior one" color="blue" />
          {showUnits ? (
            <BiChevronUp
              className="md:hidden"
              size={28}
              color="#183F71"
              onClick={() => setShowUnits(!showUnits)}
            />
          ) : (
            <BiChevronDown
              className="md:hidden"
              size={28}
              color="#183F71"
              onClick={() => setShowUnits(!showUnits)}
            />
          )}
        </div>
        <div className={`${showUnits ? "" : "hidden"} md:block`}>
          <Input
            elementType="input"
            elementConfig={{
              type: "text",
              placeholder: "Search...",
            }}
            value={searchterm}
            changed={setSearchterm}
          />
          <ul className="py-5">
            {units.map((unit, index) => (
              <li
                key={index}
                className="flex items-center justify-between py-2 cursor-pointer"
                onClick={() => {
                  dispatch(changeChosenUnitSummarynotes(unit.name));
                  setSelectedUnit(unit.name);
                  setShowUnits(false);
                }}
              >
                <CardBody
                  name={`Unit${index + 1}: ${unit.name}`}
                  color="blue"
                  additional={unit.name === selectedUnit ? "font-bold" : ""}
                />
                {unit.viewed ? (
                  <FaLockOpen color="#183F71" />
                ) : (
                  <FaLock color="#183F71" />
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className={`${
          showUnits ? "hidden md:block" : ""
        } md:relative p-3 bg-white md:h-full max-h-myscreen-sec md:max-h-screen-sec col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
      >
        <TabPanel
          hidden={selectedUnit !== "initial page"}
          className="transform transition duration-500 ease-in-out"
        >
          <FeatureTitle name="Summary notes" />
          <>
            <nav className="flex justify-between md:justify-start space-x-4">
              <TabSelector
                isActive={selectedTab === "All"}
                title="All"
                onClick={() => setSelectedTab("All")}
              />
              <TabSelector
                isActive={selectedTab === "O'Genius Panda"}
                title="O'Genius Panda"
                onClick={() => setSelectedTab("O'Genius Panda")}
              />
              <TabSelector
                isActive={selectedTab === "Community"}
                title="Community"
                onClick={() => setSelectedTab("Community")}
              />
            </nav>
            <TabPanel hidden={false}>
              <GetStarted type="a unit" feature="summary notes" />
            </TabPanel>
          </>
        </TabPanel>
        {units.map((unit, idx) => (
          <TabPanel
            key={idx}
            hidden={selectedUnit !== unit.name}
            className="transform transition duration-500 ease-in-out"
          >
            <FeatureTitle name="Summary notes" />
            <>
              <nav className="flex justify-between md:justify-start space-x-4">
                <TabSelector
                  isActive={selectedTab === "All"}
                  title="All"
                  onClick={() => setSelectedTab("All")}
                />
                <TabSelector
                  isActive={selectedTab === "O'Genius Panda"}
                  title="O'Genius Panda"
                  onClick={() => setSelectedTab("O'Genius Panda")}
                />
                <TabSelector
                  isActive={selectedTab === "Community"}
                  title="Community"
                  onClick={() => setSelectedTab("Community")}
                />
              </nav>
              <TabPanel hidden={selectedTab !== "All"}>
                {unit.notes.length === 0 ? (
                  <NotFound feature="summary note" />
                ) : (
                  <div className="md:grid grid-cols-3">
                    {unit.notes.map((note, index) => (
                      <FeatureItemCard
                        key={index}
                        {...note}
                        unit={unit.name}
                        unitNumber={idx + 1}
                        feature="summary note"
                        clicked={() =>
                          history.push(
                            `/student/features/summarynotes/${index + 1}`
                          )
                        }
                      />
                    ))}
                  </div>
                )}
              </TabPanel>
              <TabPanel hidden={selectedTab !== "O'Genius Panda"}>
                {unit.notes.filter((note) => note.type === "opanda").length ===
                0 ? (
                  <NotFound feature="summary note" />
                ) : (
                  <div className="md:grid grid-cols-3">
                    {unit.notes
                      .filter((note) => note.type === "opanda")
                      .map((note, index) => (
                        <FeatureItemCard
                          key={index}
                          {...note}
                          unit={unit.name}
                          unitNumber={idx + 1}
                          feature="summary note"
                          clicked={() =>
                            history.push(
                              `/student/features/summarynotes/${index + 1}`
                            )
                          }
                        />
                      ))}
                  </div>
                )}
              </TabPanel>
              <TabPanel hidden={selectedTab !== "Community"}>
                {unit.notes.filter((note) => note.type === "community")
                  .length === 0 ? (
                  <NotFound feature="summary note" />
                ) : (
                  <div className="md:grid grid-cols-3">
                    {unit.notes
                      .filter((note) => note.type === "community")
                      .map((note, index) => (
                        <FeatureItemCard
                          key={index}
                          {...note}
                          unit={unit.name}
                          unitNumber={idx + 1}
                          feature="summary note"
                          clicked={() =>
                            history.push(
                              `/student/features/summarynotes/${index + 1}`
                            )
                          }
                        />
                      ))}
                  </div>
                )}
              </TabPanel>
            </>
          </TabPanel>
        ))}
      </div>
    </div>
    <ScrollToTop />
    </div>
  );
};

export default SummaryNotes;
