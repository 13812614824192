import PageContent from '../pageContent';
import {
  CardBody,
  CardSubText,
  CardTitle,
  FeatureTitle,
} from '../../../elements/text';
import { Button, ButtonWithIcon } from '../../../elements/button';
import { TabPanel, useTabs } from 'react-headless-tabs';
import TabSelector from './TabSelector';
import Input from '../../../elements/input';
import { useEffect, useState } from 'react';
import { BsForward, BsPencilSquare, BsReply } from 'react-icons/bs';
import MessageCard from './MessageCard';
import Notification from '../../../elements/notification/notification';
import { useHistory } from 'react-router-dom';
import {
  MdAnnouncement,
  MdArchive,
  MdAttachFile,
  MdCalendarToday,
  MdDelete,
  MdDownload,
  MdFormatUnderlined,
  MdImage,
  MdMessage,
} from 'react-icons/md';
import Attachment from './Attachment';
import Modal from '../../../elements/modal/modal';

import axios from "../../../../axios-base";
import { useSelector } from 'react-redux';
import NotFound from '../../../common/features/NotFound';

const Communication = () => {
  const history = useHistory();
  const userAuth = useSelector((state) => state.auth);
  const token = useSelector((state) => state.auth.token);
  const [messageType, setMessageType] = useState('all');
  const messages__ = [
    {
      content:
        'Deserunt nulla sit aliquip irure veniam sint culpa do incididunt quis. Dolore ea adipisicing veniam culpa eu dolor non adipisicing consectetur irure tempor velit sunt. Veniam deserunt adipisicing veniam fugiat ea veniam id quis id. Magna esse tempor aute enim cillum ad labore incididunt mollit ad.',
      sender: 'Student',
      time: 'Just now',
      new: true,
    },
    {
      content:
        'Lorem sint excepteur ex enim culpa sint id nostrud proident. Velit consectetur elit pariatur minim qui excepteur esse. Exercitation quis ea officia minim adipisicing ullamco culpa Lorem. Nisi eu exercitation non irure laboris nisi minim deserunt laborum ipsum do amet nulla in. Occaecat adipisicing nisi ullamco eu cillum nisi consequat cillum cillum magna in amet. Duis labore sit dolor proident quis laboris eu sunt irure consectetur sunt cillum est. Officia amet magna culpa laborum proident qui consequat in reprehenderit nisi ad.',
      sender: 'Parent',
      time: '12:09',
    },
    {
      content:
        'Lorem sint excepteur ex enim culpa sint id nostrud proident. Velit consectetur elit pariatur minim qui excepteur esse. Exercitation quis ea officia minim adipisicing ullamco culpa Lorem. Nisi eu exercitation non irure laboris nisi minim deserunt laborum ipsum do amet nulla in. Occaecat adipisicing nisi ullamco eu cillum nisi consequat cillum cillum magna in amet. Duis labore sit dolor proident quis laboris eu sunt irure consectetur sunt cillum est. Officia amet magna culpa laborum proident qui consequat in reprehenderit nisi ad.',
      sender: 'Dos',
      time: '12:09',
    },
  ];
  const groups = [
    { name: 'All messages' },
    { name: 'Parents' },
    { name: 'Teachers' },
    { name: 'Students' },
    // { name: 'Announcements' },
    // { name: 'SMS' },
  ];

  const users = [
    { name: 'Teacher Eric', description: 'Mathematics, Physics' },
    { name: 'Teacher Mugabo', description: 'Chemistry, Physics' },
    { name: 'Teacher Kanani', description: 'Biology' },
    { name: 'Nabimana Jean Paul', description: 'Sinior 1' },
    { name: 'Eric', description: 'Senior 6 MCE' },
    { name: 'Parent Kalisa', description: 'Mathematics, Physics' },
    { name: 'Parent Kalisa', description: 'Mathematics, Physics' },
  ];

  const tabs = groups.map((group) => group.name);
  const [selectedTab, setSelectedTab] = useTabs(tabs, 'All messages');
  const [searchTerm, setSearchTerm] = useState('');
  const [readMessage, setReadMessage] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [showForwardModal, setShowForwardModal] = useState(false);
  const [reply, setReply] = useState('');
  const [forwardSearchTerm, setForwardSearchTerm] = useState('');
  const [showAnnounceModal, setShowAnnounceModal] = useState(false);
  const [showSmsModal, setShowSmsModal] = useState(false);
  const [announceSubject, setAnnounceSubject] = useState('');
  const [announceText, setAnnounceText] = useState('');
  const [smsSendId, setSmsSendId] = useState('');
  const [smsLevel, setSmsLevel] = useState('');
  const [smsClass, setSmsClass] = useState('');
  const [smsStream, setSmsStream] = useState('');
  const [smsText, setSmsText] = useState('');

  const [messages, setMessages] = useState([]);

  //console.log("selectedTab", selectedTab)


  useEffect(() => {
    axios
      .post(
        `/opanda/notifications/schoolMessagesSent`,
        {
          school_id: userAuth.user.id,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
       // console.log(response.data);
        if (response?.data?.message === "Messages sent by school") {
          const AllMessages = response?.data?.results?.map((message) => {
            const message_ = message[0];
            const users_types = [...new Set(message[1])];
            const Messages_pool = []
            for (let i = 0; i < users_types.length; i++) {
              const user_type = users_types[i];
              Messages_pool.push({
                content: message_.notif_data,
                sender: "Me",
                time: message_.date_created,
                type: user_type,
              });
            }
            // Optionally, return Messages_pool if needed outside the loop
            return Messages_pool;
          });
          //console.log(AllMessages);
          const flattenedArray = AllMessages.flat();
          //console.log(flattenedArray);
          setMessages(flattenedArray);

        }


      })
      .catch((err) => {
        alert("An error occured, please try again later", err)
      });
  }, []);

  return (
    <PageContent>
      <Modal
        small
        show={showForwardModal}
        modalClosed={() => setShowForwardModal(false)}
      >
        <CardTitle name="Forward Message to" />
        <div className="flex space-x-2">
          <Input
            elementType="input"
            elementConfig={{
              type: 'text',
              placeholder: 'Add subject',
            }}
            value={forwardSearchTerm}
            changed={setForwardSearchTerm}
          />
          <Input
            elementType="select"
            elementConfig={{
              startingValue: 'Filter messages',
              options: [
                { value: 'All', displayValue: 'All' },
                { value: 'Students', displayValue: 'Students' },
                { value: 'Teachers', displayValue: 'Teachers' },
                { value: 'School', displayValue: 'School' },
                { value: 'Parents', displayValue: 'Parents' },
              ],
            }}
            changed={() => { }}
          />
        </div>
        <ul>
          {users.map((user, index) => (
            <li key={index} className="my-2 flex space-x-2 items-center">
              <input type="checkbox" />
              <div className="w-12 h-12 rounded-full bg-gray-200"></div>
              <div className="flex flex-col">
                <CardBody name={user.name} additional="font-semibold" />
                <CardSubText name={user.description} />
              </div>
            </li>
          ))}
        </ul>
      </Modal>
      <Modal
        small
        show={showAnnounceModal}
        modalClosed={() => setShowAnnounceModal(false)}
      >
        <CardBody name="Type:" />
        <ul>
          <li className="flex space-x-2 items-center">
            <input type="radio" name="announce" />
            <CardBody name="All" />
          </li>
          <li className="flex space-x-2 items-center">
            <input type="radio" name="announce" />
            <CardBody name="Students" />
          </li>
          <li className="flex space-x-2 items-center">
            <input type="radio" name="announce" />
            <CardBody name="Teacher" />
          </li>
        </ul>
        <div className="w-50%">
          <Input
            elementType="input"
            elementConfig={{
              type: 'text',
              placeholder: 'Add subject',
            }}
            value={announceSubject}
            changed={setAnnounceSubject}
          />
        </div>
        <Input
          elementType="textarea"
          elementConfig={{
            placeholder: 'Add text',
            rows: 5,
          }}
          value={announceText}
          changed={setAnnounceText}
        />
        <div className="py-2 flex space-x-2 items-center">
          <Button name="Send" outline="false" color="blue" clicked={() => { }} />
          <MdImage color="#839098" />
          <MdAttachFile color="#839098" />
          <MdFormatUnderlined color="#839098" />
        </div>
      </Modal>
      <Modal
        medium
        show={showSmsModal}
        modalClosed={() => setShowSmsModal(false)}
      >
        <div className="flex space-x-5">
          <div>
            <CardBody name="Type:" />
            <ul>
              <li className="flex space-x-2 items-center">
                <input type="radio" name="announce" />
                <CardBody name="All" />
              </li>
              <li className="flex space-x-2 items-center">
                <input type="radio" name="announce" />
                <CardBody name="Students" />
              </li>
              <li className="flex space-x-2 items-center">
                <input type="radio" name="announce" />
                <CardBody name="Teacher" />
              </li>
              <li className="flex space-x-2 items-center">
                <input type="radio" name="announce" />
                <CardBody name="Class" />
              </li>
              <li className="flex space-x-2 items-center">
                <input type="radio" name="announce" />
                <CardBody name="School" />
              </li>
            </ul>
          </div>
          <div class="w-full">
            <div className="w-50%">
              <Input
                label="Send ID"
                elementType="input"
                elementConfig={{
                  type: 'text',
                  placeholder: 'Add ID',
                }}
                value={smsSendId}
                changed={setSmsSendId}
              />
            </div>
            <div className="flex space-x-2">
              <Input
                label="Level"
                elementType="select"
                elementConfig={{
                  startingValue: 'Choose Level',
                  options: [
                    { value: '1', displayValue: 'S1' },
                    { value: '2', displayValue: 'S2' },
                    { value: '3', displayValue: 'S3' },
                    { value: '4', displayValue: 'S4' },
                    { value: '5', displayValue: 'S5' },
                    { value: '6', displayValue: 'S6' },
                  ],
                }}
                value={smsLevel}
                changed={setSmsLevel}
              />
              <Input
                label="Class"
                elementType="select"
                elementConfig={{
                  startingValue: 'Choose Class',
                  options: [
                    { value: '1', displayValue: 'S1' },
                    { value: '2', displayValue: 'S2' },
                    { value: '3', displayValue: 'S3' },
                    { value: '4', displayValue: 'S4' },
                    { value: '5', displayValue: 'S5' },
                    { value: '6', displayValue: 'S6' },
                  ],
                }}
                value={smsClass}
                changed={setSmsClass}
              />
              <Input
                label="Stream"
                elementType="select"
                elementConfig={{
                  startingValue: 'Choose Stream',
                  options: [
                    { value: '1', displayValue: 'S1' },
                    { value: '2', displayValue: 'S2' },
                    { value: '3', displayValue: 'S3' },
                    { value: '4', displayValue: 'S4' },
                    { value: '5', displayValue: 'S5' },
                    { value: '6', displayValue: 'S6' },
                  ],
                }}
                value={smsStream}
                changed={setSmsStream}
              />
            </div>
          </div>
        </div>
        <Input
          elementType="textarea"
          elementConfig={{
            placeholder: 'Add text',
            rows: 5,
          }}
          value={smsText}
          changed={setSmsText}
        />
        <div className="py-2 flex space-x-2 items-center">
          <Button name="Send" outline="false" color="blue" clicked={() => { }} />
          <MdImage color="#839098" />
          <MdAttachFile color="#839098" />
          <MdFormatUnderlined color="#839098" />
        </div>
      </Modal>
      <div className="p-3 bg-white-blue">
        <FeatureTitle name="Communication" />
        <div className="md:grid grid-cols-12 w-full min-h-screen-sec">
          <div className="md:h-full col-span-3">
            <div className="flex justify-center">
              <ButtonWithIcon
                name="Compose"
                outline="true"
                color="blue"
                isSquare
                onClick={() => history.push('/school/communication/compose')}
              >
                <BsPencilSquare color="#183F71" size={26} />
              </ButtonWithIcon>
            </div>
            <div className="py-5 bg-white h-full m-2 rounded p-4">
              {groups.map((group, index) => (
                <TabSelector
                  key={index}
                  isActive={selectedTab === group.name}
                  title={group.name}
                  new={group.unread}
                  onClick={() => setSelectedTab(group.name)}
                />
              ))}
            </div>
          </div>
          <div
            className={`bg-gray-200 p-3 m-2 md:h-full max-h-myscreen-sec ${readMessage ? 'col-span-5' : 'col-span-7'
              } overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
          >
            <div className="flex justify-between items-end">
              <div className="w-50%">
                {/* <Input
                  elementType="input"
                  elementConfig={{
                    type: 'text',
                    placeholder: 'Inbox',
                  }}
                  value={searchTerm}
                  changed={setSearchTerm}
                /> */}
              </div>
              {1 > 3 && selectedTab === 'Students' && (
                <ButtonWithIcon
                  name="Announce"
                  outline="true"
                  color="blue"
                  isSquare
                  onClick={() => setShowAnnounceModal(true)}
                >
                  <MdAnnouncement color="#183F71" size={26} />
                </ButtonWithIcon>
              )}
              {selectedTab === 'SMS' && (
                <ButtonWithIcon
                  name="SMS"
                  outline="true"
                  color="blue"
                  isSquare
                  onClick={() => setShowSmsModal(true)}
                >
                  <MdMessage color="#183F71" size={26} />
                </ButtonWithIcon>
              )}
            </div>
            {groups.map((group, index) => (
              <TabPanel key={index} hidden={selectedTab !== group.name}>
                {selectedTab === 'All messages' && (
                  <div className="w-30%">
                    {/* <Input
                      elementType="select"
                      elementConfig={{
                        startingValue: 'Filter messages',
                        options: [
                          { value: 'all', displayValue: 'All' },
                          { value: 'student', displayValue: 'Students' },
                          { value: 'school', displayValue: 'School' },
                          { value: 'parent', displayValue: 'Parents' },
                          // {
                          //   value: 'Has Attachment',
                          //   displayValue: 'Has Attachment',
                          // },
                        ],
                      }}
                      changed={(value) => {
                        setMessageType(value);
                      }}
                    /> */}
                  </div>
                )}
                {messages.filter((message) => {
                  if (selectedTab === 'All messages') {
                    return true;
                  } else {
                    return message.type + "s" === selectedTab.toLowerCase();
                  }
                }).map((message, index) => (
                  <MessageCard
                    key={index}
                    {...message}
                    onClick={() => setReadMessage(true)}
                  />
                ))}
                {messages.filter((message) => {
                  if (selectedTab === 'All messages') {
                    return true;
                  } else {
                    return message.type + "s" === selectedTab.toLowerCase();
                  }
                }).length === 0 && <NotFound feature="messages" />}
              </TabPanel>
            ))}
          </div>
          {readMessage ? (
            <div className="p-3 rounded-lg bg-white md:h-full max-h-myscreen-sec col-span-5 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
              <div className="flex justify-between items-center">
                <div className="flex space-x-2 items-center">
                  <MdDelete color="#839098" size={24} />
                  <MdArchive color="#839098" size={24} />
                </div>
                <div className="flex space-x-2  items-center px-2 bg-white rounded shadow">
                  <MdCalendarToday color="#183F71" />
                  <CardBody name="April 4, 2022" color="blue" />
                </div>
              </div>
              <CardTitle additional="py-2" name="Teacher Eric" />
              <CardBody additional="py-2" name="Subject" />
              <CardBody
                additional="py-2"
                name="Pariatur deserunt fugiat dolore nostrud proident commodo proident tempor veniam sit pariatur laboris Lorem exercitation. Voluptate nisi Lorem quis commodo non culpa laborum ullamco quis aliquip aliqua cupidatat. Officia aliqua dolore veniam elit ex amet do sit incididunt. Lorem voluptate ea dolor do occaecat. Do amet in enim fugiat labore id proident. Irure ullamco nisi aliqua veniam et et ad."
              />
              <div className="flex flex-wrap">
                <Attachment thumbnail="/images/thumbnail.png" />
                <Attachment thumbnail="/images/thumbnail.png" />
                <Attachment thumbnail="/images/thumbnail.png" />
                <Attachment thumbnail="/images/thumbnail.png" />
              </div>
              {showReply ? (
                <div>
                  <Input
                    elementType="textarea"
                    elementConfig={{
                      type: 'text',
                      placeholder: 'Add text',
                      row: 3,
                    }}
                    value={reply}
                    changed={setReply}
                  />
                  <div className="py-2 flex space-x-2 items-center">
                    <Button
                      name="Send"
                      outline="false"
                      color="blue"
                      clicked={() => { }}
                    />
                    <MdImage color="#839098" />
                    <MdAttachFile color="#839098" />
                    <MdFormatUnderlined color="#839098" />
                  </div>
                </div>
              ) : (
                <div className="flex justify-between items-center py-5">
                  <div className="flex space-x-2">
                    <ButtonWithIcon
                      name="Reply"
                      outline="false"
                      color="blue"
                      isSquare
                      onClick={() => setShowReply(true)}
                    >
                      <BsReply color="#FFF" />
                    </ButtonWithIcon>
                    <ButtonWithIcon
                      name="Forward"
                      outline="false"
                      color="blue"
                      isSquare
                      onClick={() => setShowForwardModal(true)}
                    >
                      <BsForward color="#FFF" />
                    </ButtonWithIcon>
                  </div>
                  <ButtonWithIcon
                    name="Download All"
                    outline="true"
                    color="blue"
                    isSquare
                    onClick={() => { }}
                  >
                    <MdDownload color="#183F71" size={26} />
                  </ButtonWithIcon>
                </div>
              )}
            </div>
          ) : (
            <div className="py-2 md:h-full max-h-myscreen-sec col-span-3 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
              {/* <Notification auth /> */}
            </div>
          )}
        </div>
      </div>
    </PageContent>
  );
};

export default Communication;
