import Layout from '../../components/common/Layout';
import Communication from '../../components/pages/teacher/communication/Communication';

const TeacherCommunicationContainer = () => {
  return (
    <Layout>
      <Communication />
    </Layout>
  );
};

export default TeacherCommunicationContainer;
