import { CardSubText } from '../../../elements/text';
import { AnchorButton } from '../../../elements/button';
import { useTranslation } from 'react-i18next';

const NotificationBar = (props) => {
  const { t } = useTranslation();
  return (
    <div className="bg-red w-full sticky">
      <div className="mx-auto flex justify-center py-1">
        <CardSubText
          name={t('You are currently in Preview mode. To gain full access, ')}
          alignment="center"
          mobileAlignment="left"
          color="white"
        />
        <AnchorButton
          to="enroll"
          name={t('Register today')}
          color="white"
          alignment="center"
        />
        &nbsp;
      </div>
    </div>
  );
};
export default NotificationBar;
