import { useDispatch, useSelector } from "react-redux";
import { Button, TextButton } from "../../../../elements/button";
import Table from "../../../../elements/table/table";
import {
  CardBody,
  CardTitle,
  FeatureTitle,
  SectionTitle,
  SmallText,
} from "../../../../elements/text";
import { useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Modal from "../../../../elements/modal/modal";
import {
  activateExtraPackage,
  changePackageModalState,
  setChosenExtraPackage,
} from "../../../../../store/actions/payment";
import Spinner from "../../../../elements/spinner";
import * as actionTypes from "../../../../../store/actions";

const PageContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const tableheader1 = ["Name", "Package", "Bought on", "Days left"];
  const tableheader2 = ["Name", "Description", "Status", "Level", "Action"];

  const mainpackage = useSelector((state) => state.auth.user.activepackage);
  const extrapackages = useSelector((state) => state.auth.user.extras);
  const showPackageModal = useSelector((state) => state.payment.showPackageModal);
  const chosenExtraPackage = useSelector( (state) => state.payment.chosenExtraPackage);
  const token = useSelector((state) => state.auth.token);
  const userId = useSelector((state) => state.auth.userId);
  const activationLoading = useSelector((state) => state.payment.activationLoading);
  const activation = useSelector((state) => state.payment.activation);

  const [tbody1, setTBody1] = useState([]);
  const [tbody2, setTBody2] = useState([]);

  const tablebody1 = useRef([]);
  const extrastablebody = useRef([]);
  
  useEffect(() => {
    if (mainpackage) {
      tablebody1.current = [];
      Object.values(mainpackage).forEach((value) => {
        try {
          // convert object to key's array
          const now = new Date();
          const date2 = new Date(value.panda_user_payments_regdate);
          // To calculate the time difference of two dates
          const Difference_In_Time = now.getTime() - date2.getTime();
          // To calculate the no. of days between two dates
          const daysLeft =
            Math.round(
              Number(value.panda_user_payments_duration) -
                Difference_In_Time / (1000 * 3600 * 24)
            ) + " days";
          // iterate over object
          tablebody1.current.push({
            td1: { data: "You", type: "text" },
            td2: { data: "Individual", type: "text" },
            td3: {
              data: value.panda_user_payments_regdate.split("T")[0],
              type: "text",
            },
            td4: { data: daysLeft, type: "text" },
          });
        } catch (error) {
          console.error("Error processing data:", error);
        }
      });
      setTBody1(tablebody1.current);
    }
  }, [mainpackage]);

  useEffect(() => {
    if (extrapackages) {
      extrastablebody.current = [];
      try {
        Object.values(extrapackages).forEach((value, index) => {
          // convert object to key's array
          const keys = Object.values(value);
          const actionbutton = (
            <TextButton
              name="Activate"
              color="blue"
              clicked={() => {
                dispatch(
                  setChosenExtraPackage({
                    ...value,
                    userid: userId,
                    number: index + 1,
                  })
                );
                dispatch(changePackageModalState(true));
              }}
            />
          );
          extrastablebody.current.push({
            td1: { data: keys[0], type: "text" },
            td2: { data: keys[1], type: "text" },
            td3: { data: keys[2] ? "Active" : "Inactive", type: "text" },
            td4: { data: keys[3], type: "text" },
            td5: {
              data: <>{actionbutton}</>,
              type: "component",
            },
          });
        });
      } catch (error) {
        // console.error("Error processing data:", error);
      }
      setTBody2(extrastablebody.current);
    }
  }, [dispatch, extrapackages, userId]);

  useEffect(() => {
    if (activation) {
      dispatch(actionTypes.logout());
      history.push("/");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [activation, dispatch, history]);

  return (
    <div className="bg-white-blue md:flex w-full">
      <Modal
        small
        show={showPackageModal}
        modalClosed={() => {
          dispatch(setChosenExtraPackage(null));
          dispatch(changePackageModalState(false));
        }}
      >
        <div className="w-80% m-auto py-5">
          <SectionTitle name="Here's what you selected" alignment="center" />
          <div className="flex flex-col justify-between items-start span-y-2 py-3">
            <div className="text-center">
              {/* <CardTitle name={``} color="blue" /> */}
              <div className="flex space-x-2">
                <CardTitle name={`Package ${chosenExtraPackage?.number}`} />
                <CardBody name={chosenExtraPackage?.name} />
              </div>
              <div className="flex space-x-2">
                <CardTitle name={`Description: `} />
                <CardBody name={chosenExtraPackage?.features} />
              </div>
            </div>
            <div className="flex flex-col">
              <SmallText name={`Upon activation, the system will re-log you in for the package to take effect`} color="blue" />
            </div>
          </div>
          <div className="flex justify-around">
            <Button
              name={"Cancel"}
              outline="true"
              color="blue"
              clicked={() => {
                dispatch(setChosenExtraPackage(null));
                dispatch(changePackageModalState(false));
              }}
            />
            <Button
              name={
                activationLoading ? (
                  <Spinner size="6" color="white" />
                ) : (
                  "Activate"
                )
              }
              outline="false"
              color="blue"
              clicked={() => {
                dispatch(setChosenExtraPackage(null));
                dispatch(changePackageModalState(false));
                dispatch(activateExtraPackage(chosenExtraPackage, token));
              }}
            />
          </div>
        </div>
      </Modal>
      <div className="w-full p-3 bg-white-blue">
        <FeatureTitle name="Payment" alignment="right" />
        <div className="relative overflow-x-auto sm:rounded-lg my-4">
          <CardTitle name="Active Package" color="blue" />
          {/* <Button
            name="Add package"
            outline="false"
            color="blue"
            additional="float-right"
            clicked={() => {
              history.push(`/student/user/payment/create`);
            }}
            isSmall="true"
          /> */}
          <Table thead={tableheader1} tbody={tbody1} />
          <CardTitle name="Extra Academic levels" color="blue" />
          <Button
            name="Add Level"
            outline="false"
            color="blue"
            additional="float-right"
            clicked={() => {
              history.push(`/student/user/payment/extras/create`);
            }}
            isSmall="true"
          />
          <Table thead={tableheader2} tbody={tbody2} />
        </div>
      </div>
    </div>
  );
};

export default PageContent;
