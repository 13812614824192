export const COMPLETE_SIGNUP_ACTION_START = 'COMPLETE_SIGNUP_ACTION_START';
export const COMPLETE_SIGNUP_ACTION_SUCCESS = 'COMPLETE_SIGNUP_ACTION_SUCCESS';
export const COMPLETE_SIGNUP_ACTION_FAIL = 'COMPLETE_SIGNUP_ACTION_FAIL';
export const COMPLETE_SIGNUP_ACTION_CLICKED = 'COMPLETE_SIGNUP_ACTION_CLICKED';

export const completeSignupStart = (actionName) => {
  if(actionName?.isParent === true){
    return {
      type: COMPLETE_SIGNUP_ACTION_START,
      payload: actionName
    }; 
  }else{
    return {
      type: COMPLETE_SIGNUP_ACTION_START,
    };
  }
};

export const completeSignupSuccess = (actionName) => {
  return {
    type: COMPLETE_SIGNUP_ACTION_SUCCESS,
    action: actionName,
  };
};

export const completeSignupFail = () => {
  return {
    type: COMPLETE_SIGNUP_ACTION_FAIL,
  };
};

export const completeSignupClicked = (actionName) => {
  return {
    type: COMPLETE_SIGNUP_ACTION_CLICKED,
    action: actionName,
  };
};

export const completeSignup = (actionName) => {
  return (dispatch) => {
    dispatch(completeSignupStart(actionName));
    setTimeout(() => {
      dispatch(completeSignupSuccess(actionName));
    }, 1000); //wait for 1 second
  };
};
