import { useSelector } from "react-redux";
import { ContentContainer } from "../../../common/content/container";
import Spinner from "../../../elements/spinner";
import { PageBody, SectionTitle } from "../../../elements/text";

export const Books = (props) => {
  const learningresources = useSelector(
    (state) => state.previewpanda.learningresources
  );
  const learningresourcesLoading = useSelector(
    (state) => state.previewpanda.learningresourcesLoading
  );
  const books = useSelector((state) => state.previewpanda.books);
  const booksLoading = useSelector((state) => state.previewpanda.booksLoading);
  return (
    <>
      {learningresourcesLoading ? (
        <div className="flex flex-row justify-center w-full">
          <Spinner size="20" color="blue" />
        </div>
      ) : (
        <div  style={{ height: "100vh" }}
        className="container flex flex-col items-start relative  overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100 p-3 pt-5 py-5 bg-gray-200">
     
          <SectionTitle
            name={learningresources[props.index]?.fullname}
            alignment="left"
            color="blue"
          />
          <div className="h-24 sm:h-40 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100"><PageBody
            name={learningresources[props.index]?.description}
            alignment="left"
            color="blue"
          /></div>
          {booksLoading ? (
            <div className="flex flex-row justify-center w-full">
              <Spinner size="20" color="blue" />
            </div>
          ) : (
            <ContentContainer name="" data={books} slider={false} type="two" />
          )}
        </div>
      )}
    </>
  );
};
