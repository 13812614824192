import axios from "../../axios-base";

export const STUDENT_DASHBOARD_NOTIFICATION_COUNT_UPDATES_INIT =
  "STUDENT_DASHBOARD_NOTIFICATION_COUNT_UPDATES_INIT";
export const STUDENT_DASHBOARD_NOTIFICATION_COUNT_UPDATES_SUCCESS =
  "STUDENT_DASHBOARD_NOTIFICATION_COUNT_UPDATES_SUCCESS";
export const STUDENT_DASHBOARD_NOTIFICATION_COUNT_UPDATES_FAILED =
  "STUDENT_DASHBOARD_NOTIFICATION_COUNT_UPDATES_FAILED";

export function student_dashboard_notification_count_init() {
  return {
    type: STUDENT_DASHBOARD_NOTIFICATION_COUNT_UPDATES_INIT
  };
}
export function student_dashboard_notification_count_success(data) {
  return {
    type: STUDENT_DASHBOARD_NOTIFICATION_COUNT_UPDATES_SUCCESS,
    payload: data
  };
}
export function student_dashboard_notification_count_fail(error) {
  return {
    type: STUDENT_DASHBOARD_NOTIFICATION_COUNT_UPDATES_FAILED,
    payload: error
  };
}

export function student_dashboard_notification_count_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_notification_count_init());
    dispatch(student_dashboard_notification_count_success(data));
  };
}
