import Layout from '../../../components/common/Layout';
import Simulations from '../../../components/pages/student/simulations/Simulations';

const SimulationsContainer = () => {
  return (
    <Layout>
      <Simulations />
    </Layout>
  );
};

export default SimulationsContainer;
