import Layout from '../components/common/Layout';
import { Helmet } from 'react-helmet';
import Dashboard from '../components/pages/parent/dashboard/dashboard';

const ParentHome = () => {
  return (
    <Layout>
      <Helmet>
        <title>OPANDA - PARENT</title>
      </Helmet>
      <Dashboard />
    </Layout>
  );
};

export default ParentHome;
