
export const Message = (props) => {
  if (props.type === "error") {
    return (
      <p
        className={
          props.viewable === true
            ? "text-red font-bold text-lg z-40 text-center"
            : "hidden"
        }
      >
        {props.message}
      </p>
    );
  } else {
    return (
      <p className="text-tiffany-blue font-bold text-lg z-40">
        {props.message}
      </p>
    );
  }
};
