import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import parse from 'react-html-parser';

const MultichoiceAnswerOptionGroupAutoTest = (props) => {
    const { answers } = props;
    const dispatch = useDispatch();
    const [text, setText] = useState('');
    const [isAnswer, setIsAnswer] = useState(false);
    const [UpdateUI, setUpdateUI] = useState(false);
    useEffect(() => {

    }, [props.multipleChoicePicks]);
    const pickAnswer = (val, qnber, questionId) => {
        props.markSelectedMultipleChoiceQuestion(qnber, val, "multiple", questionId);
        setUpdateUI(!UpdateUI);
    }


    const renderCheckedStatus = (questionNber, answerId, collectedAnswers) => {
        console.log("multiple",collectedAnswers);
        for (var i = 0; i < collectedAnswers.length; i++) {
            if ((parseInt(collectedAnswers[i].question_number) === parseInt(questionNber + "")) && (parseInt(collectedAnswers[i].answer_number) === parseInt(answerId + ""))) {
                return true;
            }
        }
        return false;
    }
    return (
        <div>
            {props.answers.map((item, i) => {
                return <div>
                    <table>
                        <tr>
                            <td>{(renderCheckedStatus(props.questionnber, item.panda_auto_questions_answers_id, props.multipleChoicePicks))?<input checked={true} onChange={(e) => pickAnswer(e.target.value, props.questionnber, props.id)} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' type="checkbox" name={`answer--${item.panda_auto_questions_answers_id}`} value={`${item.panda_auto_questions_answers_id}`} />
                            :<input checked={false} onChange={(e) => pickAnswer(e.target.value, props.questionnber, props.id)} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' type="checkbox" name={`answer--${item.panda_auto_questions_answers_id}`} value={`${item.panda_auto_questions_answers_id}`} />}</td>
                            <td>{parse(item.panda_auto_questions_answers_ans_data)}</td>
                        </tr>
                    </table>
                </div>
            })}
        </div>
    );
};
export default MultichoiceAnswerOptionGroupAutoTest;
