import Course from '../../../components/pages/teacher/summarynotes/SummaryNote';
import Layout from '../../../components/common/Layout';

const Summurynote = () => {
  return (
    <Layout>
      <Course />
    </Layout>
  );
};

export default Summurynote;
