import Layout from '../components/common/Layout';
import { Helmet } from 'react-helmet';
import Dashboard from '../components/pages/teacher/dashboard/dashboard';

const TeacherHome = () => {
  return (
    <Layout>
      <Helmet>
        <title>OPANDA - TEACHER</title>
      </Helmet>
      <Dashboard />
    </Layout>
  );
};

export default TeacherHome;
