import { updateObject } from "../../shared/utility";
import {
  STUDENT_DASHBOARD_GET_REFERER_ID_INIT,
  STUDENT_DASHBOARD_GET_REFERER_ID_SUCCESS,
  STUDENT_DASHBOARD_GET_REFERER_ID_FAILED,
  STUDENT_DASHBOARD_UPDATE_REFERER_ID_INIT,
  STUDENT_DASHBOARD_UPDATE_REFERER_ID_SUCCESS,
  STUDENT_DASHBOARD_UPDATE_REFERER_ID_FAILED,
  STUDENT_DASHBOARD_GET_LINKED_ACCOUNTS_INIT,
  STUDENT_DASHBOARD_GET_LINKED_ACCOUNTS_SUCCESS,
  STUDENT_DASHBOARD_GET_LINKED_ACCOUNTS_FAILED,
  STUDENT_DASHBOARD_REMOVE_LINKED_ACCOUNT_INIT,
  STUDENT_DASHBOARD_REMOVE_LINKED_ACCOUNT_SUCCESS,
  STUDENT_DASHBOARD_REMOVE_LINKED_ACCOUNT_FAILED,
} from "../actions/student.dashboard.settings.link";
const initialReducer = {
  refererid: 0,
  loading: true,
  accounts: [],
  pending_accounts: [],
  message: "",
};

function student_dashboard_get_referer_id_init(state, action) {
  return updateObject(state, {
    loading: true,
  });
}
function student_dashboard_get_referer_id_success(state, action) {
  return updateObject(state, {
    refererid: action.payload,
    loading: false,
    message: "success",
  });
}
function student_dashboard_get_referer_id_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    loading: false,
  });
}

function student_dashboard_update_referer_id_init(state, action) {
  return updateObject(state, {
    loading: true,
  });
}
function student_dashboard_update_referer_id_success(state, action) {
  return updateObject(state, {
    refererid: action.payload,
    loading: false,
    message: "success",
  });
}
function student_dashboard_update_referer_id_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    loading: false,
  });
}

function student_dashboard_get_linked_accounts_init(state, action) {
  return updateObject(state, {
    loading: true,
  });
}
function student_dashboard_get_linked_accounts_success(state, action) {
  return updateObject(state, {
    accounts: action.payload,
    loading: false,
    message: "success",
  });
}
function student_dashboard_get_linked_accounts_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    loading: false,
  });
}

function student_dashboard_update_linked_account_init(state, action) {
  return updateObject(state, {
    loading: true,
  });
}
function student_dashboard_update_linked_account_success(state, action) {
  return updateObject(state, {
    loading: false,
    message: "success",
  });
}
function student_dashboard_update_linked_account_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    loading: false,
  });
}

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case STUDENT_DASHBOARD_GET_REFERER_ID_INIT:
      return student_dashboard_get_referer_id_init(state, action);
    case STUDENT_DASHBOARD_GET_REFERER_ID_SUCCESS:
      return student_dashboard_get_referer_id_success(state, action);
    case STUDENT_DASHBOARD_GET_REFERER_ID_FAILED:
      return student_dashboard_get_referer_id_failed(state, action);
    case STUDENT_DASHBOARD_UPDATE_REFERER_ID_INIT:
      return student_dashboard_update_referer_id_init(state, action);
    case STUDENT_DASHBOARD_UPDATE_REFERER_ID_SUCCESS:
      return student_dashboard_update_referer_id_success(state, action);
    case STUDENT_DASHBOARD_UPDATE_REFERER_ID_FAILED:
      return student_dashboard_update_referer_id_failed(state, action);
    case STUDENT_DASHBOARD_GET_LINKED_ACCOUNTS_INIT:
      return student_dashboard_get_linked_accounts_init(state, action);
    case STUDENT_DASHBOARD_GET_LINKED_ACCOUNTS_SUCCESS:
      return student_dashboard_get_linked_accounts_success(state, action);
    case STUDENT_DASHBOARD_GET_LINKED_ACCOUNTS_FAILED:
      return student_dashboard_get_linked_accounts_failed(state, action);
      case STUDENT_DASHBOARD_REMOVE_LINKED_ACCOUNT_INIT:
        return student_dashboard_update_linked_account_init(state, action);
      case STUDENT_DASHBOARD_REMOVE_LINKED_ACCOUNT_SUCCESS:
        return student_dashboard_update_linked_account_success(state, action);
      case STUDENT_DASHBOARD_REMOVE_LINKED_ACCOUNT_FAILED:
        return student_dashboard_update_linked_account_failed(state, action);
     
      default:
      return state;
  }
};
export default reducer;
