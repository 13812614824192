const Otp = (props) => {
  return (
    <div className="flex justify-center items-center py-3 pl-12">
      {props.otp.map((number, index) => (
        <input
          key={index}
          onChange={(e) => props.changeOtpInputHandler(e, index)}
          className="rounded w-9 h-9 m-2"
          style={{fontSize:"13px"}}
          type="text"
          value={number}
          maxLength="1"
          onFocus={(e) => e.target.select()}
        />
      ))}
    </div>
  );
};

export default Otp;
