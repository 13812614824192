// Page.js
import React, { useState, useEffect } from 'react';
import RichTextEditor from './RichTextEditor';
import PageNavigation from './PageNavigation';
import SubmitButton from './SubmitButton';
import { Button } from '../../../elements/button';
import RadioOptions from './RadioOptions';
import axios from "../../../../axios-base";
import { useSelector } from "react-redux";
import Input from '../../../elements/input';
import Spinner from '../../../elements/spinner';
import { student_dashboard_homepage_get_books_start } from '../../../../store/actions/student.dashboard.library';
import { useDispatch } from "react-redux";
const Page = (props) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [pageContent, setPageContent] = useState([]); // Initial content for one page
    const [selectedOption, setSelectedOption] = useState(0);
    const user = useSelector((state) => state?.auth);
    const user_id = useSelector((state) => state?.auth?.user.id);
    const token = useSelector((state) => state?.auth?.token);
    const [bookId, setBookId] = useState(0);
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const [subject, setSubject] = useState("");
    const [level, setLevel] = useState("");
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");








    const handleOptionChange = (option) => {
        setSelectedOption(option);
        // Perform any other actions upon option change if needed
    };

    const handleContentChange = (html) => {
        setPageContent((prevContent) => {
            const updatedContent = [...prevContent];
            updatedContent[currentPage] = html;
            return updatedContent;
        });
    };

    const handleNext = () => {
        if (currentPage < pageContent.length - 1) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const handleSubmit = () => {
        // Logic for submitting all page contents
        console.log('All page contents submitted:', pageContent);

    };

    useEffect(() => {
        const subject = props?.summmarynotes?.chosenSubject;
        const unitname = props?.summmarynotes?.chosenUnit;
        const academic_level = props?.params?.academic_level;
    }, []);

    const getAllImages = (htmls) => {
        const regex = /<img[^>]+src="([^">]+)"/g;
        const images = [];
        let match;
        for (var i = 0; i < htmls.length; i++) {
            const html = htmls[i];
            while ((match = regex.exec(html))) {
                const imageUrl = match[1];
                if (imageUrl.startsWith('data:image')) {
                    images.push(imageUrl);
                }
            }
        }
        return images;
    };



    // Function to convert base64 to Blob
    function base64ToBlob(base64Data) {
        const byteString = atob(base64Data.split(',')[1]);
        const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    }

    // Function to upload base64 images as JPGs with enumerated names
    async function uploadImagesWithNames(images) {
        const uploadPromises = images.map(async (base64Data, index) => {
            const blob = base64ToBlob(base64Data);

            // Create a unique filename with timestamp and index
            const timestamp = Date.now();
            const fileName = `${timestamp}-summary_note_${Date.now()}_${index}.jpg`;

            // Create a FormData object and append the blob
            const formData = new FormData();
            formData.append('image', blob, fileName);

            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/opanda/uploadImages`, {
                    method: 'POST',
                    body: formData
                    // Add headers if needed, like authorization or content-type
                });

                if (response.ok) {
                    // Once the image is uploaded, return an object with base64 data and filename
                    return { base64Data, fileName };
                } else {
                    throw new Error('Upload failed');
                }
            } catch (error) {
                console.error('Error:', error);
                return null;
            }
        });

        return Promise.all(uploadPromises);
    }
    // Find book content by Id
    useEffect(() => {
        const bookId = props.teacherDashboardLibrary.bookId;
        if (bookId == 0) {
            setSubject(props.teacherDashboardLibrary.subject);
            setLevel(props.teacherDashboardLibrary.createLevel);
            setDescription(props.teacherDashboardLibrary.description);
            setTitle(props.teacherDashboardLibrary.bookTitle);
            return;
        }
        setLoader(true);
        axios.post(
            `/opanda/studentsibrary/findYourWrittenBookInTheLibrary`,
            {
                user_id: user_id,
                bookId: bookId
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            }
        )
            .then((response) => {
                const data = response.data.results;
                setBookId(data.book.panda_books_id)
                setPageContent([...data.pages.map(page => page.panda_pages_data)])
                setSelectedOption(data.book.panda_books_mark_final)
                setLoader(false);
                //--
                setSubject(data.book.panda_books_subject);
                setLevel(data.book.panda_books_level);
                setDescription(data.book.panda_books_summary);
                setTitle(data.book.panda_books_name);

            })
            .catch((err) => {
            });


    }, []);

    const uploadMyImages = async (images_data) => {
        // Call the function to upload images and save key-value pairs after upload
        setLoader(true);
        uploadImagesWithNames(images_data)
            .then(results => {
                const imageKeyValuePairs = {}; // Object to store image base64 and filenames

                results.forEach(result => {
                    // Process each result from the backend
                    if (result) {
                        imageKeyValuePairs[result.fileName] = [result.base64Data, result.fileName];

                    }
                });

                //--Replace all images
                const pageContentPool = pageContent;
                for (var i = 0; i < pageContentPool.length; i++) {
                    let newPageContent = pageContentPool[i];
                    Object.keys(imageKeyValuePairs).forEach(key => {
                        newPageContent = newPageContent.replaceAll(imageKeyValuePairs[key][0], `${process.env.REACT_APP_BACKEND_URL}/simulation_thumbnails/${key}`);
                    });
                    pageContentPool[i] = newPageContent;
                }
                const allPageContent = [...pageContentPool];
                setPageContent([...allPageContent]);
                if (allPageContent[0] === '' || allPageContent[0] === null || allPageContent[0] === undefined) {
                    alert("Make sure your book has at least one page");
                    setLoader(false);
                    return;
                }
                axios
                    .post(
                        `/opanda/studentsibrary/writeOrUpdateAbook`,
                        {
                            user_id: user_id,
                            subject: subject,
                            level: level,
                            description: description,
                            title: title,
                            bookId: bookId,
                            pageCount: allPageContent.length,
                            pages: allPageContent

                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${token}`
                            }
                        }
                    )
                    .then((response) => {
                        alert("Book created successfully");
                        setBookId(response.data.results.panda_books_id);
                        setLoader(false);
                        dispatch(
                            student_dashboard_homepage_get_books_start({
                                token: token,
                                user_id: user_id,
                                academic_level: props.teacherDashboardLibrary.createLevel
                            })
                        );
                    })
                    .catch((err) => {
                    });



            })
            .catch(error => {
                console.error('Error:', error);
                setLoader(false);
                return {};
            });

    }


    const deleteCurrentPage = (currentPageToDelete) => {
        if (window.confirm("Are you sure you want to delete this page?")) {
            setPageContent((prevContent) => {
                const updatedContent = [...prevContent];
                updatedContent.splice(currentPageToDelete, 1);
                if (currentPageToDelete > 0) {
                    setCurrentPage((prevPage) => prevPage - 1);
                }
                return updatedContent;
            });
        }
    }

    const addNewPage = () => {
        setPageContent((prevContent) => {
            const updatedContent = [...prevContent, ""];
            if (currentPage < updatedContent.length - 1) {
                setCurrentPage((prevPage) => prevPage + 1);
            }
            return updatedContent;
        });
    };
    function getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }






    return (
        (!loader) ? <div className="container mx-auto" style={{ display: 'grid', gridTemplateColumns: '90% 10%', zIndex: 1 }}>

            <div>
                <div><PageNavigation pageCount={(pageContent.length === 0) ? 1 : pageContent.length} currentPage={currentPage} deleteCurrentPage={deleteCurrentPage} addNewPage={addNewPage} onNext={handleNext} onPrevious={handlePrevious} />
                    <RichTextEditor
                        handleContentChange={handleContentChange}
                        initialContent={pageContent[currentPage]}
                        content={pageContent[currentPage]}
                        key={1}
                    /></div>
            </div>
            <div className="mt-10">

                <Button
                    name="Save/Update"
                    outline="false"
                    color="green"
                    isSmall={true}
                    isSquare
                    clicked={async () => {
                        //console.log(process.env.REACT_APP_BACKEND_URL);
                        await uploadMyImages(getAllImages(pageContent))
                    }}
                />

                <div className='m-3'><RadioOptions key={getRandomNumber(1, 100)} bookId={bookId} selectedOption={selectedOption} onOptionChange={handleOptionChange} />
                </div>
            </div>
        </div> : <Spinner size="32" color="blue" />

    );
};

export default Page;
