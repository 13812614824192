

export const shuffleSuggestedFeatures = (mapRes: any, mapComponents: any) => {
  const filteredSuggestedFeatures = [];
  
  for (var i = 0; i < mapRes.length; i++) {

    if (filteredSuggestedFeatures.length < 5) {
      if (mapRes[i].item_name === "simulations") {
        filteredSuggestedFeatures.push(mapComponents[0])

      } else if (mapRes[i].item_name === "summarynotes") {
        filteredSuggestedFeatures.push(mapComponents[1])
      } else if (mapRes[i].item_name === "challenges") {
        filteredSuggestedFeatures.push(mapComponents[2])
      } else if (mapRes[i].item_name === "pastpapers") {
        filteredSuggestedFeatures.push(mapComponents[3])
      } else if (mapRes[i].item_name === "library") {
        filteredSuggestedFeatures.push(mapComponents[4])
      } else if (mapRes[i].item_name === "onlineclasses") {
        //filteredSuggestedFeatures.push(mapComponents[5])
      } else if (mapRes[i].item_name === "practice") {
        filteredSuggestedFeatures.push(mapComponents[6])
      } else if (mapRes[i].item_name === "myclasses") {
        filteredSuggestedFeatures.push(mapComponents[7])
      } else if (mapRes[i].item_name === "tests") {
        filteredSuggestedFeatures.push(mapComponents[8])
      }
    }
  }

  return filteredSuggestedFeatures;

};


