export function showsidebar() {
  return {
    type: "SHOW_SIDEBAR",
  };
}

export function hidesidebar() {
  return {
    type: "HIDE_SIDEBAR",
  };
}

export function changetab(tab) {
  return {
    type: "CHANGE_TAB",
    payload: tab,
  };
}

export function changeStudentDashboardMenu(menu) {
  return {
    type: "STUDENT_DASHBOARD_CHANGE_MENU",
    payload: menu,
  };
}

export function changeStudentDashboardtab(tab) {
  return {
    type: "STUDENT_DASHBOARD_CHANGE_TAB",
    payload: tab,
  };
}

export function changeStudentDashboardMobileVisibility(status) {
  return {
    type: "STUDENT_DASHBOARD_CHANGE_MOBILE_VISIBILITY",
    status,
  };
}

export function showcontentmodal(status, showcontentmodal = { data: "data" }) {
  return {
    type: "SHOW_CONTENT_MODAL",
    payload: status,
    showcontentmodal: showcontentmodal,
  };
}

export function changefaqtab(status) {
  return {
    type: "CHANGE_FAQ_TAB",
    payload: status,
  };
}

export function changeSideBarCollapseState(status) {
  return {
    type: "CHANGE_SIDEBAR_COLLAPSE_STATE",
    status: status,
  };
}

export function changeGenericTab(tab) {
  return {
    type: "CHANGE_GENERIC_TAB",
    tab,
  };
}
