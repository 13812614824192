import { bring_subject_image_src } from "./programs";


export const getAllTests = (data1: any) => {
  const tests = []

  if (data1.length > 0 && data1[0]) {
    const data = data1[0];
    for (var i = 0; i < data.length; i++) {
      //---  
      for (var n = 0; n < data[i].schedules.length; n++) {
        //console.log("####", data[i].schedules[n].uploader)
        tests.push(
          {
            title: data[i].schedules[n].test_name,
            level: data[i].academic_level,
            unit: data[i].schedules[n].unit,
            imgsrc: bring_subject_image_src(data[i].subject).imgsrc,
            type: (data[i].schedules[n].type === "test") ? "Test" : "Quiz",
            due: (new Date(data[i].schedules[n].dateschedule).getFullYear() + '-' + (((new Date(data[i].schedules[n].dateschedule).getMonth() + 1) < 10) ? ("0" + (new Date(data[i].schedules[n].dateschedule).getMonth() + 1)) : (new Date(data[i].schedules[n].dateschedule).getMonth() + 1)) + '-' + new Date(data[i].schedules[n].dateschedule).getDate()),
            // Date remove seconds and timezone
            dateSince: new Date(data[i].schedules[n].dateschedule),
            isMyTest: (data[i].schedules[n].uploader === "opanda") ? true : false,
            subject: data[i].subject,
            id: data[i].schedules[n].test_id,
            owner: data[i].schedules[n].teacherid,
          }
        );
      }

    }
  }


  return tests;

};


