import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { Button, GoBackButton, TextButton } from '../../../../elements/button'
import Input from '../../../../elements/input'
import { PaymentsTable } from '../../../../elements/table/table'
import { CardTitle, CardBody, 
         FeatureTitle 
} from '../../../../elements/text'
import PageContent from '../../pageContent'
import  PrevNext  from '../../../landing/enroll/PrevNext'

const allSubscriptions = [
  {
    id: 1,
    packageName: 'Daily',
    amount: '500',
    duration: '1 day',
    description: 'sample_description',
  },
  {
    id: 2,
    packageName: 'Basic',
    amount: '3000',
    duration: '1 month',
    description: 'sample_description',
  },
  {
    id: 3,
    packageName: 'Standard',
    amount: '6000',
    duration: '3 months',
    description: 'sample_description',
  },
  {
    id: 4,
    packageName: 'Premium',
    amount: '20000',
    duration: '1 year',
    description: 'sample_description',
  },
] 
const allSubscriptionsData = [];


const AddPackage = () => {
  const history = useHistory();
  const [accountToSponsor, setAccountToSponsor] = useState();
  const [isRegistered, setIsRegistered] = useState(false);
  const [competency, setCompetency] = useState();
  const subscriptionsHeader = ["Package Name", "Amount", "Duration", "Description", "Action"];
  const [showChangeProgram, setShowChangeProgram] = useState(false)

Object.values(allSubscriptions.filter((user) => user.status !=="")).forEach(
  (value) => {
    const keys = Object.values(value);
    const purchaseButton = (
      <>
        <TextButton
          name="Purchase"
          color="blue"
          clicked={() => {
            history.push(`/parent/renewpayment/addpackage/payment`); 
          }}
        />
      </>
    );
    allSubscriptionsData.push({
      td1: { data: keys[1], type: "text" },
      td2: { data: keys[2], type: "text" },
      td3: { data: keys[3], type: "text" },
      td4: { data: keys[4], type: "text" },
      td5: {
        data: <>{purchaseButton}</>,
        type: "component",
      },
    });
  }
);

  return (
    <PageContent>
    <div className='w-full h-full p-4 overflow-x-auto'>
    <FeatureTitle name="Renew payments" />
    <GoBackButton
          link="/parent/renewpayment"
          action="Back"
          location="Payment new package"

        />
    <div className='py-4 sm:w-2/4 lg:w-2/5'>
    <Input 
    label="Choose account to sponsor*"
    elementType="select"
    elementConfig={{
      type: 'text',
      placeholder: 'User name -> email',
      startingValue: "SELECT",
      options: [
        { value: 'emaillogin@type.com', displayValue: 'emaillogin@type.com'},
        { value: 'emaillogin1@type.com', displayValue: 'emaillogin1@type.com'},
        { value: 'emaillogin2@type.com', displayValue: 'emaillogin2@type.com'},
        { value: 'emaillogin3@type.com', displayValue: 'emaillogin3@type.com'},
      ]
    }}
    value={accountToSponsor}
    changed={setAccountToSponsor}
    />
    <div className="flex items-center py-4">
    <input 
    type="checkbox"
    value={isRegistered}
    changed={setIsRegistered}
    /> 
    <CardBody 
    name="My child's account has not yet been registered"
    additional="px-2"
    />
    </div>
    </div>
    <div className='lg:w-3/4 w-full'>
      <div className='flex lg:items-center justify-between flex-col lg:flex-row'>
        <CardTitle name="Package Subscription" /> 
        <div className='flex justify-end md:items-center flex-col md:flex-row'>
          <CardBody 
          name="Competency Based on curriculum" 
          additional="px-2"
          />
          <PrevNext 
          px="5" 
          pageName=" " 
          showChooseProgram="false"
          />
        </div>
      </div>
    <div className="relative overflow-x-auto sm:rounded-lg mb-2 min-h-40 my-2">
        {allSubscriptions.length > 0 ? (
          <PaymentsTable thead={subscriptionsHeader} tbody={allSubscriptionsData} />   
          ):(
            ''
          )}
    </div>
    </div>
    </div>
    </PageContent>
  )
}

export default AddPackage
