import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../../../../../constants/constants";
import { convertToRoman } from "../../../../../../shared/utility";
import {
  addMultipleQuestionOption,
  removeMultipleQuestionOption,
  setCurrentQuestion,
  setMulitpleQuestionOptions,
} from "../../../../../../store/actions/features/tests";
import { Button } from "../../../../../elements/button";
import Input from "../../../../../elements/input";
import RichTextEditor from "../../../../../elements/RichTextEditor";
import { CardBody } from "../../../../../elements/text";
import AnswerOption from "./AnswerOption";
import AnswerOptionForQuestionBank from "./AnswerOptionForQuestionBank";

const AddMultipleQuestionFromQuestionBank = (props) => {
  const dispatch = useDispatch();
  const [marks, setMarks] = useState(props?.question?.marks);
  const [duration, setDuration] = useState(props?.question?.duration);
  const [question, setQuestion] = useState(props?.question?.question);
  const [explanation, setExplanation] = useState(props?.question?.explanation);
  const [options, setOptions] = useState(props?.question?.answers);
  const [currentQuestion, setCurrentQuestion] = useState(props?.questionNber);
 

  // useEffect(() => {
  //   if (Object.keys(currentQuestion).length !== 0) {
  //     setMarks(currentQuestion.marks);
  //     setDuration(currentQuestion.duration);
  //     setQuestion(currentQuestion.question);
  //     setExplanation(currentQuestion.explanation);
  //     dispatch(setMulitpleQuestionOptions(currentQuestion.options));
  //   }
  // }, []);

  // useEffect(() => {
  //   dispatch(
  //     setCurrentQuestion({
  //       type: constants.multiple_choice,
  //       marks: marks,
  //       duration: duration,
  //       question: question,
  //       explanation: explanation,
  //       options: options,
  //     })
  //   );
  // }, [dispatch, marks, duration, question, explanation, options]);

  const addOption = () => {
    // dispatch(addMultipleQuestionOption({ id: options.length }));
    //setOptions([...options, ""]);
    const newOptions = props?.question?.answers;
    props.updateMultipleChoiceQuestionAnswers(props?.questionNber, [...newOptions, { value: "", isAnswer: false }]);
  };

  const removeAnswerOption = (val, i) => {
    if (window.confirm("Are you sure you want to remove this option?")) {
      const newOptions = props?.question?.answers.filter((option, index) => index !== i);
      props.updateMultipleChoiceQuestionAnswers(props?.questionNber, [...newOptions]);
    }
  };


  const updateAnswersOptionsIn = (id, option) => {
    const newOptions = props?.question?.answers;
    newOptions[id].value = option;
    props.updateMultipleChoiceQuestionAnswers(props?.questionNber, [...newOptions]);
  }

  const updateAnswersOptionsInStatus = (id, isAnswer) => {
    const newOptions = props?.question?.answers;
    newOptions[id].isAnswer = isAnswer;
    props.updateMultipleChoiceQuestionAnswers(props?.questionNber, [...newOptions]);
  }


  return (
    <div className="grid grid-cols-5">
      <div className="col-span-1 bg-blue-500">
        <input type="checkbox" onChange={(e) => {
          props.selectQuestion(props?.questionNber, e.target.checked)
        }} style={{ borderRadius: 10, color: "blue", fontSize: 20 }} />
      </div>
      <div className="w-70% border-t border-gray-500 col-span-4 bg-red-500">

        <CardBody name={props?.question?.question} />
        <CardBody name={props?.question?.explanation} />
        <CardBody name="List Of Answers" additional="font-semibold" />
        {props?.question?.answers.map((option, i) => (
          <div className="pl-10">
            <CardBody name={`Option ${convertToRoman(i + 1)}.`} />
            <AnswerOptionForQuestionBank
              key={i}
              id={i}
              option={option}
              updateAnswersOptionsIn={updateAnswersOptionsIn}
              updateAnswersOptionsInStatus={updateAnswersOptionsInStatus}
              removeAnswerOption={() => {
                removeAnswerOption(option, i);
              }}
            />
          </div>
        ))}
        {/* <Button
        name="Add to test"
        isSquare
        outline="false"
        color="blue"
        additional="mb-5"
        clicked={() => { }}
      /> */}
      </div></div>
  );
};

export default AddMultipleQuestionFromQuestionBank;
