import { updateObject } from "../../utils/updateObject";
import { VERIFY_SCHOOL_OTP_FAIL, VERIFY_SCHOOL_OTP_INIT, VERIFY_SCHOOL_OTP_SUCCESS } from "../actions/token";



const initialReducer = {
  status: null,
  loading: false,
};

const verifySchoolOTPInit = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const verifySchoolOTPSuccess = (state, action) => {
  return updateObject(state, {
    status: action.payload,
    loading: false,
  });
};

const verifySchoolOTPFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case VERIFY_SCHOOL_OTP_INIT:
      return verifySchoolOTPInit(state, action);
    case VERIFY_SCHOOL_OTP_SUCCESS:
      return verifySchoolOTPSuccess(state, action);
    case VERIFY_SCHOOL_OTP_FAIL:
      return verifySchoolOTPFail(state, action);
    default:
      return state;
  }
};
export default reducer;
