import { useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { useEffect } from "react";
import Input from '../../../elements/input';
import { useDispatch, useSelector } from "react-redux";
import PageContent from '../pageContent';
import { useTabs } from 'react-headless-tabs';
import { CardBody, CardTitle, FeatureTitle } from '../../../elements/text';
import { TabPanel } from 'react-headless-tabs';
import GetStarted from '../../../common/features/GetStarted';
import NotFound from '../../../common/features/NotFound';
import { FeatureItemCard } from '../../../elements/card';
import { useHistory } from 'react-router-dom';
import TabSelector from '../../../elements/tab/TabSelector';
import Modal from '../../../elements/modal/modal';
import { Button, ButtonWithIcon } from '../../../elements/button';
import { MdAttachment } from 'react-icons/md';
import { GiHamburgerMenu } from 'react-icons/gi';
import { GrClose } from 'react-icons/gr';
import { student_dashboard_homepage_get_books_start } from '../../../../store/actions/student.dashboard.library';
import { bring_subject_image_src } from '../../../../functions/programs';
import { crypt } from '../../../../functions/cyphers';
import axios from "../../../../axios-base";
import FileUploader from './FileUploader';
import FileUploaderImage from './FileUploaderImage';
import PdfThumbnail from './PdfThumbnail';
import PdfThumbnailImage from './PdfThumbnailImage';
import { use } from 'i18next';
import Spinner from '../../../elements/spinner';
import { teacher_dashboard_library_write_doc_start } from '../../../../store/actions/teacher.dashboard.library';

const Library = () => {
  const history = useHistory();
  const [showSubjects, setShowSubjects] = useState(false);
  const [level, setLevel] = useState('1');
  const [searchterm, setSearchterm] = useState('');
  const userAuth = useSelector((state) => state.auth);
  const [isDocLoading, setIsDocLoading] = useState(false);
  const [isDocArchivingLoading, setIsDocArchivingLoading] = useState(false);
  const [pages, setNumberOfPages] = useState(0);

  // const subjects = [
  //   {
  //     name: 'Biology',
  //     books: [
  //       {
  //         title: 'Book Title',
  //         imgsrc: '/images/preview/bio.svg',
  //         author: 'Cedric',
  //         type: 'opanda',
  //       },
  //       {
  //         title: 'Book Title',
  //         imgsrc: '/images/preview/math.svg',
  //         author: 'Paccy',
  //         type: 'opanda',
  //       },
  //       {
  //         title: 'Book Title',
  //         imgsrc: '/images/preview/bio.svg',
  //         author: 'Cedric',
  //         type: 'opanda',
  //       },
  //       {
  //         title: 'Book Title',
  //         imgsrc: '/images/preview/bio.svg',
  //         author: 'Cedric',
  //         type: 'mine',
  //       },
  //     ],
  //   },
  //   {
  //     name: 'Math',
  //     books: [
  //       {
  //         title: 'Book Title',
  //         imgsrc: '/images/preview/bio.svg',
  //         author: 'Cedric',
  //         type: 'opanda',
  //       },
  //       {
  //         title: 'Book Title',
  //         imgsrc: '/images/preview/bio.svg',
  //         author: 'Paccy',
  //         type: 'community',
  //       },
  //       {
  //         title: 'Book Title',
  //         imgsrc: '/images/preview/math.svg',
  //         author: 'Cedric',
  //         type: 'community',
  //       },
  //       {
  //         title: 'Book Title',
  //         imgsrc: '/images/preview/bio.svg',
  //         author: 'Cedric',
  //         type: 'community',
  //       },
  //     ],
  //   },
  //   {
  //     name: 'History',
  //     books: [
  //       {
  //         title: 'Book Title',
  //         imgsrc: '/images/preview/bio.svg',
  //         author: 'Cedric',
  //         type: 'opanda',
  //       },
  //       {
  //         title: 'Book Title',
  //         imgsrc: '/images/preview/bio.svg',
  //         author: 'Cedric',
  //         type: 'opanda',
  //       },
  //       {
  //         title: 'Book Title',
  //         imgsrc: '/images/preview/math.svg',
  //         author: 'Paccy',
  //         type: 'opanda',
  //       },
  //       {
  //         title: 'Book Title',
  //         imgsrc: '/images/preview/bio.svg',
  //         author: 'Cedric',
  //         type: 'opanda',
  //       },
  //     ],
  //   },
  //   {
  //     name: 'Geography',
  //     books: [],
  //   },
  //   {
  //     name: 'Physics',
  //     books: [],
  //   },
  //   {
  //     name: 'English',
  //     books: [],
  //   },
  // ];
  const [showCreateBookModal, setShowCreateBookModal] = useState(false);
  const [action, setAction] = useState('upload');
  const [subject, setSubject] = useState('BIOLOGY');
  const [subjects, setSubjects] = useState([]);
  const [createLevel, setCreateLevel] = useState('1');
  const [description, setDescription] = useState('');
  const [resourceName, setResourceName] = useState('');
  const [bookTitle, setBookTitle] = useState('');
  const studentDashboardLibrary = useSelector((state) => state.studentDashboardLibrary);

  const dispatch = useDispatch();
  useEffect(() => {
    //-
    dispatch(
      student_dashboard_homepage_get_books_start({
        token: userAuth.token,
        user_id: userAuth.userId,
        academic_level: level
      })
    );
  }, [level]);
  const getSubjects = (pool) => {
    if (pool?.data?.results) {
      const clean_pool = pool?.data?.results;
      for (var i = 0; i < clean_pool?.length; i++) {
        const image = bring_subject_image_src(clean_pool[i].name);
        clean_pool[i].imgsrc = (image !== undefined) ? image?.imgsrc : "/images/preview/book.png"
        //-----
        for (var n = 0; n < clean_pool[i]?.books?.length; n++) {
          clean_pool[i].books[n].imgsrc = clean_pool[i].imgsrc;
        }
      }
      return clean_pool;
    } else {
      return [];
    }
  }

  const tabs = getSubjects(studentDashboardLibrary.student_dashboard_homepage_get_books).map((subject) => subject.name);
  tabs.unshift('initial page');
  const [selectedSubject, setSelectedSubject] = useTabs(tabs, 'initial page');

  const [selectedTab, setSelectedTab] = useTabs(
    ['All', "O'Genius Panda", 'Community', 'My Docs'],
    "O'Genius Panda"
  );

  // Save the book
  const saveBook = () => {
    if (action === 'upload') {
      // save the book with the resources
      if (description == "" || resourceName == "") {
        alert("Empty fields?!!! Please fill in all the fields to proceed.");
        return;
      }
      if (selectedImage && selectedDoc) {

      } else {
        alert("A PDF and it's thumbnail are needed?!!! Please upload them to proceed.");
        return;
      }
      setIsDocLoading(true);
      axios
        .post(
          `/opanda/studentsibrary/createabookwithdoc`,
          {
            user_id: userAuth.user.id, academic_level: createLevel,
            type: "digital", subject: subject,
            description: description, resourceName: resourceName,
            imageThumbail: selectedImage, pdfLink: selectedDoc,
            pages: pages
          },
          {
            headers: {
              authorization: `Bearer ${userAuth.token}`,
            },
          }
        )
        .then((response) => {
          //--Clear form
          setDescription('');
          setResourceName('');
          setBookTitle('');
          setNumberOfPages(0);
          setLevel(createLevel);
          setSelectedImage(null);
          setSelectedDoc(null);
          setIsDocLoading(false);
          setShowCreateBookModal(false)
          dispatch(
            student_dashboard_homepage_get_books_start({
              token: userAuth.token,
              user_id: userAuth.userId,
              academic_level: createLevel
            })
          );

        })
        .catch((err) => {
          setIsDocLoading(false);
        });


    } else {
      // save the book
      if (bookTitle == "" || description == "") {
        alert("Empty fields?!!! Please fill in all the fields to proceed.");
        return;
      } else {

        dispatch(
          teacher_dashboard_library_write_doc_start({
            bookTitle: bookTitle,
            description: description,
            createLevel: createLevel,
            subject: subject,
            bookId: 0
          })
        );
        history.push('/teacher/features/library/new')
      }
    }
  };

  useEffect(() => {
    axios
      .put(
        `/opanda/past-papers/getSubjects`,
        { userId: userAuth.user.id },
        {
          headers: {
            authorization: `Bearer ${userAuth.token}`,
          },
        }
      )
      .then((response) => {
        const subjectData = response.data.results
          .map(pool => ({ subject: pool.subject }))
          .sort((a, b) => a.subject.localeCompare(b.subject));

        setSubjects(subjectData.map((subject) => {
          return { value: subject.subject, displayValue: subject.subject }
        }
        ));


      })
      .catch((err) => { });
  }, []);

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState(null);
  useEffect(() => {

  }, [selectedImage, selectedDoc]);

  const setTheImage = (image) => {
    setSelectedImage(image)
  };

  const setTheDoc = (doc) => {
    setSelectedDoc(doc)
  }
  const removeDoc = () => {
    setSelectedDoc(null)
  }
  const removeImage = () => {
    setSelectedImage(null)
  }


  return (
    <PageContent>
      <Modal
        medium
        show={showCreateBookModal}
        modalClosed={() => setShowCreateBookModal(false)}
      >
        <CardTitle name="Add book" />
        <div className="w-50%">
          <Input
            elementType="select"
            elementConfig={{
              startingValue: 'SELECT',
              options: [
                { value: 'upload', displayValue: 'Upload Document' },
                { value: 'write', displayValue: 'Write Document' },
              ],
            }}
            value={action}
            changed={setAction}
            validation={{ required: true }}
            shouldValidate
            error="Invalid action"
          />
        </div>
        {action === 'upload' ? (
          (!isDocLoading) ? <>
            <div className="flex space-x-2">
              <Input
                label="Subject"
                elementType="select"
                elementConfig={{
                  startingValue: 'SELECT',
                  options: [
                    ...subjects
                  ],
                }}
                value={subject}
                changed={setSubject}
                validation={{ required: true }}
                shouldValidate
                error="subject is required"
              />
              <Input
                label="Class"
                elementType="select"
                elementConfig={{
                  startingValue: 'SELECT',
                  options: [
                    { value: '1', displayValue: 'S1' },
                    { value: '2', displayValue: 'S2' },
                    { value: '3', displayValue: 'S3' },
                    { value: '4', displayValue: 'S4' },
                    { value: '5', displayValue: 'S5' },
                    { value: '6', displayValue: 'S6' },
                  ],
                }}
                value={createLevel}
                changed={setCreateLevel}
                validation={{ required: true }}
                shouldValidate
                error="Class is required"
              />
            </div>
            <Input
              label="Description"
              elementType="textarea"
              elementConfig={{
                type: 'text',
                placeholder: 'Add description',
              }}
              value={description}
              changed={setDescription}
              shouldValidate
              error="Description is required"
            />
            <div className="w-100%">
              <Input
                label="Number of pages"
                elementType="input"
                elementConfig={{
                  type: 'number',
                  placeholder: 'Enter number of pages',
                }}
                value={pages}
                changed={setNumberOfPages}
                shouldValidate
                error="Resource name is required"
              />
            </div>
            <CardBody name="Resources" />
            <div className="flex space-x-2 items-end mb-4">
              <div className="w-50%">
                <Input
                  label="Resource name"
                  elementType="input"
                  elementConfig={{
                    type: 'text',
                    placeholder: 'Enter name',
                  }}
                  value={resourceName}
                  changed={setResourceName}
                  shouldValidate
                  error="Resource name is required"
                />
              </div>
              {/* <ButtonWithIcon
                name="Attach"
                outline="true"
                color="blue"
                onClick={() => { }}
              >
                <MdAttachment color="#183F71" size={26} />
              </ButtonWithIcon> */}
              <FileUploaderImage setTheImage={setTheImage} key={100} />
              <FileUploader setTheDoc={setTheDoc} key={101} />
            </div>
            <PdfThumbnail removeDoc={removeDoc} key={102} selectedDoc={selectedDoc} Thumbnail={(selectedDoc != null) ? true : false} />
            <PdfThumbnailImage removeImage={removeImage} key={103} selectedImage={selectedImage} Thumbnail={selectedImage != null ? `${process.env.REACT_APP_BACKEND_URL}/files_docs/${selectedImage}` : null} />

            <div className="pt-3 flex justify-center space-x-5">
              <Button
                name="Cancel"
                outline="true"
                color="blue"
                clicked={() => setShowCreateBookModal(false)}
              />
              <Button
                name="Save"
                outline="false"
                color="blue"
                clicked={() => {
                  saveBook()
                }}
              />
            </div>
          </> : <Spinner size="10" color="blue" />
        ) : (action === 'write') ? (
          <>
            <div className="flex space-x-2">
              <Input
                label="Book Title"
                elementType="input"
                elementConfig={{
                  type: 'text',
                  placeholder: 'Enter book title',
                }}
                value={bookTitle}
                changed={setBookTitle}
              />
              <Input
                label="Subject"
                elementType="select"
                elementConfig={{
                  startingValue: 'SELECT',
                  options: [...subjects
                  ],
                }}
                value={subject}
                changed={setSubject}
                validation={{ required: true }}
                shouldValidate
                error="subject is required"
              />
              <Input
                label="Class"
                elementType="select"
                elementConfig={{
                  startingValue: 'SELECT',
                  options: [
                    { value: '1', displayValue: 'S1' },
                    { value: '2', displayValue: 'S2' },
                    { value: '3', displayValue: 'S3' },
                    { value: '4', displayValue: 'S4' },
                    { value: '5', displayValue: 'S5' },
                    { value: '6', displayValue: 'S6' },
                  ],
                }}
                value={createLevel}
                changed={setCreateLevel}
                validation={{ required: true }}
                shouldValidate
                error="Class is required"
              />
            </div>
            <Input
              label="Description"
              elementType="textarea"
              elementConfig={{
                type: 'text',
                placeholder: 'Add description',
              }}
              value={description}
              changed={setDescription}
            />
            <div className="pt-3 flex justify-center space-x-5">
              <Button
                name="Cancel"
                outline="true"
                color="blue"
                clicked={() => setShowCreateBookModal(false)}
              />
              <Button
                name="Save"
                outline="false"
                color="blue"
                clicked={() => {
                  saveBook()

                }}
              />
            </div>
          </>
        ) : <Spinner size="20" color="blue" />}
      </Modal>
      <div className={`md:grid grid-cols-12 w-full md:h-full min-h-screen-sec`}>
        <div
          className={`p-3 bg-gray-200 ${showSubjects ? 'h-screen-sec' : ''
            } md:h-full col-span-3`}
        >
          <div className="flex justify-between items-center">
            <Input
              elementType="select"
              elementConfig={{
                startingValue: 'SELECT',
                options: [
                  { value: '1', displayValue: 'Senior 1' },
                  { value: '2', displayValue: 'Senior 2' },
                  { value: '3', displayValue: 'Senior 3' },
                  { value: '4', displayValue: 'Senior 4' },
                  { value: '5', displayValue: 'Senior 5' },
                  { value: '6', displayValue: 'Senior 6' },
                ],
              }}
              value={level}
              changed={setLevel}
              validation={{ required: true }}
              shouldValidate
              error="Level is required"
            />

          </div>
          <div className={`${showSubjects ? '' : 'hidden'} md:block`}>
            <Input
              elementType="input"
              elementConfig={{
                type: 'text',
                placeholder: 'Search...',
              }}
              value={searchterm}
              changed={setSearchterm}
            />
            {(getSubjects(studentDashboardLibrary.student_dashboard_homepage_get_books).length > 0) ? <ul className="py-5 p-5  h-90 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
              {getSubjects(studentDashboardLibrary.student_dashboard_homepage_get_books)?.filter((subject, index) => (subject.name).toLowerCase().includes(searchterm.toLowerCase()) || searchterm === "")?.sort((a, b) => {
                // Assuming 'name' is a string property of each subject
                // and you want to sort the subjects alphabetically by their names
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                  return 1;
                }
                return 0;
              })
                .map((subject, index) => (
                <li key={index}>
                  <div
                    className="py-2 cursor-pointer"
                    onClick={() => {
                      setSelectedSubject(subject.name);
                      setShowSubjects(false);
                    }}
                  >
                    <CardBody
                      name={subject.name}
                      color="blue"
                      additional={
                        subject.name === selectedSubject ? 'font-bold' : ''
                      }
                    />
                  </div>
                </li>
              ))}
            </ul> : <Spinner size="20" color="blue" />}
          </div>
        </div>
        <div
          className={`${showSubjects ? 'hidden md:block' : ''
            } md:relative p-3 bg-white md:h-full h-screen-second mb-16 pb-16 md:max-h-screen-sec col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
        >
          <TabPanel
            hidden={selectedSubject !== 'initial page'}
            className="transform transition duration-500 ease-in-out"
          >
            <FeatureTitle name="Library" />
            <>
              <nav className="flex justify-between md:justify-start space-x-4">
                <TabSelector
                  isActive={selectedTab === 'All'}
                  title="All"
                  onClick={() => setSelectedTab('All')}
                />
                <TabSelector
                  isActive={selectedTab === "O'Genius Panda"}
                  title="O'Genius Panda"
                  onClick={() => setSelectedTab("O'Genius Panda")}
                />
                <TabSelector
                  isActive={selectedTab === 'Community'}
                  title="Community"
                  onClick={() => setSelectedTab('Community')}
                />
                <TabSelector
                  isActive={selectedTab === 'My Docs'}
                  title="My Docs"
                  onClick={() => setSelectedTab('My Docs')}
                />
              </nav>
              <TabPanel hidden={false}>
                <GetStarted type="a subject" feature="library" />
              </TabPanel>
            </>
          </TabPanel>
          {getSubjects(studentDashboardLibrary.student_dashboard_homepage_get_books).map((subject, index) => (
            <TabPanel
              key={index}
              hidden={selectedSubject !== subject.name}
              className="transform transition duration-500 ease-in-out"
            >
              <FeatureTitle name="Library" />
              <>
                <nav className="flex justify-between md:justify-start space-x-4">
                  <TabSelector
                    isActive={selectedTab === 'All'}
                    title="All"
                    onClick={() => setSelectedTab('All')}
                  />
                  <TabSelector
                    isActive={selectedTab === "O'Genius Panda"}
                    title="O'Genius Panda"
                    onClick={() => setSelectedTab("O'Genius Panda")}
                  />
                  <TabSelector
                    isActive={selectedTab === 'Community'}
                    title="Community"
                    onClick={() => setSelectedTab('Community')}
                  />
                  <TabSelector
                    isActive={selectedTab === 'My Docs'}
                    title="My Docs"
                    onClick={() => setSelectedTab('My Docs')}
                  />
                </nav>
                <TabPanel hidden={selectedTab !== 'All'}>
                  {subject?.books?.length === 0 ? (
                    <NotFound feature="book" />
                  ) : (
                    <div className="md:grid grid-cols-3">
                      {subject?.books?.map((book, index) => (
                        <FeatureItemCard
                          key={index}
                          {...book}
                          feature="library"
                          clicked={() =>
                            history.push(
                              `/teacher/features/library/${book.id}?bookType=${book.bookType}&dataUrl=${crypt(
                                "O'Genius Panda Library",
                                book.mediasrc
                              )}`
                            )
                          }
                        />
                      ))}
                    </div>
                  )}
                </TabPanel>
                <TabPanel hidden={selectedTab !== "O'Genius Panda"}>
                  {subject?.books?.filter((book) => book.type === 'opanda')
                    .length === 0 ? (
                    <NotFound feature="book" />
                  ) : (
                    <div className="md:grid grid-cols-3">
                      {subject?.books
                        ?.filter((book) => book.type === 'opanda')
                        .map((book, index) => (
                          <FeatureItemCard
                            key={index}
                            {...book}
                            feature="library"
                            clicked={() =>

                              history.push(
                                `/teacher/features/library/${book.id}?bookType=${book.bookType}&dataUrl=${crypt(
                                  "O'Genius Panda Library",
                                  book.mediasrc
                                )}`
                              )

                            }
                          />
                        ))}
                    </div>
                  )}
                </TabPanel>
                <TabPanel hidden={selectedTab !== 'Community'}>
                  {subject?.books?.filter((book) => book.type === 'community')
                    .length === 0 ? (
                    <NotFound feature="book" />
                  ) : (
                    <div className="md:grid grid-cols-3">
                      {subject?.books
                        ?.filter((book) => book.type === 'community')
                        .map((book, index) => (
                          <FeatureItemCard
                            key={index}
                            {...book}
                            feature="library"
                            clicked={() =>
                              history.push(
                                `/teacher/features/library/${book.id}?bookType=${book.bookType}&dataUrl=${crypt(
                                  "O'Genius Panda Library",
                                  book.mediasrc
                                )}`
                              )
                            }
                          />
                        ))}
                    </div>
                  )}
                </TabPanel>
                <TabPanel hidden={selectedTab !== 'My Docs'}>
                  {subject?.books?.filter((book) => book.type === 'My Docs')
                    .length === 0 ? (
                    <>
                      <NotFound
                        feature="book"
                        isCreate
                        create={() => setShowCreateBookModal(true)}
                      />
                    </>
                  ) : (
                    <>
                      <div className="flex justify-end">
                        <Button
                          name="+ Create"
                          outline="true"
                          color="blue"
                          clicked={() => setShowCreateBookModal(true)}
                        />
                      </div>
                      {(!isDocArchivingLoading) ? <div className="md:grid grid-cols-3">
                        {subject?.books
                          ?.filter((book) => book.type === 'My Docs')
                          .map((book, index) => (
                            (book.bookType === "docs") ? <div key={index} style={{ borderRadius: 6, borderLeft: "3px solid #183F71", padding: "4px" }}><span>PDF</span><FeatureItemCard
                              key={index}
                              {...book}
                              feature="library"
                              clicked={() =>
                                history.push(
                                  `/teacher/features/library/${book.id}?level=${createLevel}&bookType=${book.bookType}&dataUrl=${crypt(
                                    "O'Genius Panda Library",
                                    book.mediasrc
                                  )}`
                                )
                              }
                            /><Button
                                name="X"
                                isSquare
                                outline="false"
                                color="red"
                                additional="mb-5"
                                clicked={() => {
                                  if (window.confirm('Are you sure you want to delete this book?')) {
                                    setIsDocArchivingLoading(true);
                                    axios
                                      .post(
                                        `/opanda/studentsibrary/archiveThePDFInTheLibrary`,
                                        {
                                          book_id: book.id,
                                          user_id: userAuth.user.id,
                                          academic_level: level
                                        },
                                        {
                                          headers: {
                                            authorization: `Bearer ${userAuth.token}`,
                                          },
                                        }
                                      )
                                      .then((response) => {
                                        setIsDocArchivingLoading(false);
                                        dispatch(
                                          student_dashboard_homepage_get_books_start({
                                            token: userAuth.token,
                                            user_id: userAuth.userId,
                                            academic_level: level
                                          })
                                        );

                                      })
                                      .catch((err) => {
                                        setIsDocArchivingLoading(false);
                                      });

                                  }
                                }}
                              /></div> : <div key={index} style={{ borderRadius: 6, borderLeft: "3px solid #183F71", padding: "4px" }}><span>Written Book</span><FeatureItemCard
                                key={index}
                                {...book}
                                feature="library"
                                clicked={() => {


                                  dispatch(
                                    teacher_dashboard_library_write_doc_start({
                                      bookTitle: bookTitle,
                                      description: description,
                                      createLevel: createLevel,
                                      subject: subject,
                                      bookId: book.id
                                    })
                                  );
                                  history.push('/teacher/features/library/new')
                                }
                                }
                              /></div>
                          ))}
                      </div> : <Spinner size="20" color="blue" />}
                    </>
                  )}
                </TabPanel>
              </>
            </TabPanel>
          ))}
        </div>
        <div
          style={{ zIndex: "1000" }}
          className={`${showSubjects ? "block" : ""
            } md:hidden fixed w-full md:w-auto  md:relative p-3 bg-gray-200 bottom-0  flex flex-row md:flex-col justify-between items-center`}
        >
          <div className="flex w-full justify-around md:flex-col md:items-center md:space-y-10 z-auto">
            {showSubjects ? (
              <GrClose
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSubjects(!showSubjects)}
              />
            ) : (
              <GiHamburgerMenu
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSubjects(!showSubjects)}
              />
            )}
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default Library;
