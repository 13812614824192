import Layout from '../../../components/common/Layout';
import TestStats from '../../../components/pages/teacher/test/TestStats';

const TestStatsContainer = () => {
  return (
    <Layout>
      <TestStats />
    </Layout>
  );
};

export default TestStatsContainer;
