import PageContent from "../../pageContent";
import Notification from "../../../../elements/notification/notification";
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CardTitle, FeatureTitle, PageTitle } from "../../../../elements/text";
import Table from "../../../../elements/table/table";
import { TextButton } from "../../../../elements/button";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  student_dashboard_get_linked_accounts_start,
  student_dashboard_get_referer_id_start,
  student_dashboard_remove_linked_account_start,
} from "../../../../../store/actions/student.dashboard.settings.link";

const LinkAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const tableheader1 = ["Name", "User type", "Linked on", "Action"];
  const [isCopied, setIsCopied] = useState(false);
  const refererid = useSelector((state) => state.userReferal.refererid);
  const userreferrals = useSelector((state) => state.userReferal.accounts);
  const token = useSelector((state) => state.auth.token);
  const userID = useSelector((state) => state.auth.userId);
  const refID = useSelector((state) => state.auth.user.refID);
  useEffect(() => {
    dispatch(
      student_dashboard_get_linked_accounts_start({
        user_id: userID,
        token: token,
      })
    );
    if (refID === 0 || refID === "") {
      dispatch(
        student_dashboard_get_referer_id_start({
          user_id: userID,
          token: token,
        })
      );
    }
  }, [dispatch, refID, userID, token]);

  const tablebody1 = [];
  const tablebody2 = [];
  Object.values(
    userreferrals.filter((user) => user.status === "linked")
  ).forEach((value) => {
    const keys = Object.values(value);
    const actionbutton = (
      <>
        <TextButton
          name="Unlink"
          color="red"
          clicked={() => {
            dispatch(
              student_dashboard_remove_linked_account_start({
                id: keys[0],
                token: token,
              })
            );
            dispatch(
              student_dashboard_get_linked_accounts_start({
                user_id: userID,
                token: token,
              })
            );
          }}
        />
      </>
    );
    tablebody1.push({
      td1: { data: keys[1], type: "text" },
      td2: { data: keys[2], type: "text" },
      td3: { data: keys[4], type: "text" },
      td6: {
        data: <>{actionbutton}</>,
        type: "component",
      },
    });
  });
  Object.values(
    userreferrals.filter((user) => user.status === "pending")
  ).forEach((value) => {
    const keys = Object.values(value);
    const actionbutton = (
      <>
        <TextButton
          name="Unlink"
          color="red"
          clicked={() => {
            history.push(`/student/user/linkedaccounts/${keys[0]}`); //here must a test id
          }}
        />
      </>
    );
    tablebody2.push({
      td1: { data: keys[1], type: "text" },
      td2: { data: keys[2], type: "text" },
      td3: { data: keys[3], type: "text" },
      td4: { data: keys[4], type: "text" },
      td5: { data: keys[5], type: "text" },
      td6: {
        data: <>{actionbutton}</>,
        type: "component",
      },
    });
  });
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  return (
    <PageContent>
      <div
        className={`md:grid  bg-gray-200 grid-cols-12 w-full min-h-screen-sec max-h-myscreen-sec md:max-h-screen-sec overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
      >
        <div className={`p-3 md:h-full col-span-9`}>
          <FeatureTitle name="Link accounts" />

          <div className="p-3 space-y-4">
            <span className="flex flex-row justify-items-center items-center">
              <CardTitle name="USER ID:" color="blue" />

              <AiOutlineInfoCircle
                color="#183F71"
                size={18}
              ></AiOutlineInfoCircle>
            </span>
            <span className="flex flex-row justify-items-center items-center space-x-4">
              <PageTitle
                name={refID === 0 || refID === "" ? refererid : refID}
                color="blue"
              />{" "}
              <CopyToClipboard
                text={refID === 0 || refID === "" ? refererid : refID}
                onCopy={onCopyText}
              >
                <div className="copy-area span-x-2">
                  <button className="cursor-pointer  px-4 py-1 transition ease-in duration-200 text-center text-blue rounded hover:bg-blue hover:text-white border-2 border-blue focus:outline-none">
                    Copy
                  </button>
                  <span
                    className={`copy-feedback text-sm font-normal text-center text-blue ${
                      isCopied ? "active" : "hidden"
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </span>
          </div>
          <div className="space-y-4">
            <CardTitle name="Linked accounts" color="blue" />
            <div className="bg-white relative overflow-x-auto sm:rounded-lg mb-4">
              <Table thead={tableheader1} tbody={tablebody1} />
            </div>
            <CardTitle name="Pending accounts" color="blue" />
            <div className="bg-white relative overflow-x-auto sm:rounded-lg  mb-4">
              <Table thead={tableheader1} tbody={tablebody2} />
            </div>
          </div>
        </div>
        <div className={`p-3 col-span-3`}>
          <Notification auth />
        </div>
      </div>
    </PageContent>
  );
};

export default LinkAccount;
