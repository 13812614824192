import { updateObject } from "../../../shared/utility";
import {
  GET_AUTOMATED_TEST_LIST_FAIL,
  GET_AUTOMATED_TEST_LIST_INIT,
  GET_AUTOMATED_TEST_LIST_SUCCESS,
  
} from "../../actions/features/test.progress";

const initialReducer = {
  autotestlist: [],
  loading: false,
  message: "",
};

const getAutomatedTestListInit = (state, action) => {
  return updateObject(state, {
    autotestlist: [],
    loading: true,
    message: "",
  });
};

const getAutomatedTestListSuccess = (state, action) => {
  return updateObject(state, {
    autotestlist: action.payload,
    loading: false,
    message: "success",
  });
};

const getAutomatedTestListFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: action.payload,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case GET_AUTOMATED_TEST_LIST_INIT:
      return getAutomatedTestListInit(state, action);
    case GET_AUTOMATED_TEST_LIST_SUCCESS:
      return getAutomatedTestListSuccess(state, action);
    case GET_AUTOMATED_TEST_LIST_FAIL:
      return getAutomatedTestListFail(state, action);
    default:
      return state;
  }
};

export default reducer;
