import { CardTitle, PageBody, SectionTitle } from "../../../elements/text";

export const ContentPanel = (props) => {
  const { title, content } = props;
  return (
    <div>
      <SectionTitle name={title} color="blue" alignment="left" />
      {content?.map((f, k) => (
        <div className="md:ml-4 mt-6 mb-4" key={k}>
          <CardTitle name={f.subtitle} color="blue" />
          <PageBody name={f.bodyHtml} color="black" />
        </div>
      ))}
    </div>
  );
};
