import { updateObject } from '../../shared/utility';
import {
  CHANGE_ASK_QUESTION_MODAL_STATUS,
  CHANGE_COMMENT_MODAL_STATUS,
  SET_COMMENT,
  SET_QUESTION,
} from '../actions/community';

const initialReducer = {
  comment: '',
  question: '',
  showCommentModal: false,
  showAskQuestionModal: false,
};

const setComment = (state, action) => {
  return updateObject(state, {
    comment: action.payload,
  });
};

const setQuestion = (state, action) => {
  return updateObject(state, {
    question: action.payload,
  });
};

const changeCommentModalStatus = (state, action) => {
  return updateObject(state, {
    showCommentModal: action.payload,
  });
};

const changeAskQuestionModalStatus = (state, action) => {
  return updateObject(state, {
    showAskQuestionModal: action.payload,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case SET_COMMENT:
      return setComment(state, action);
    case SET_QUESTION:
      return setQuestion(state, action);
    case CHANGE_COMMENT_MODAL_STATUS:
      return changeCommentModalStatus(state, action);
    case CHANGE_ASK_QUESTION_MODAL_STATUS:
      return changeAskQuestionModalStatus(state, action);
    default:
      return state;
  }
};

export default reducer;
