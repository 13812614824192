import React, { useEffect, useState } from 'react';

function TimePicker(props) {
    const [time, setTime] = useState('12:00');

    const handleChange = (event) => {
        setTime(event.target.value);
        props.callBack(event.target.value);
    };

    useEffect(() => {
        props.callBack(time);
    }, [time]);

    return (
        <div>
            <input
                type="time"
                id="timePicker"
                value={time}
                onChange={handleChange}
            />
        </div>
    );
}

export default TimePicker;
