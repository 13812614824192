import Layout from '../../../components/common/Layout';
import Dashboard from '../../../components/pages/school/classes/dashboard';

const Classes = () => {
  return (
    <Layout>
      <Dashboard />
    </Layout>
  );
};
 
export default Classes; 
