import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";

export const getTeacherOpenEndedQuestionsStart = () => {
  return {
    type: actionTypes.GET_OPEN_ENDED_TEACHER_QUESTIONS_START,
  };
};

export const getTeacherOpenEndedQuestionsSuccess = (questions) => {
  return {
    type: actionTypes.GET_OPEN_ENDED_TEACHER_QUESTIONS_SUCCESS,
    questions: questions,
  };
};

export const getOneTeacherOpenEndedQuestionsSuccess = (onequestion) => {
  return {
    type: actionTypes.GET_OPEN_ENDED_TEACHER_QUESTIONS_SUCCESS,
    onequestion: onequestion,
  };
};

export const getTeacherOpenEndedQuestionsFail = (error) => {
  return {
    type: actionTypes.GET_OPEN_ENDED_TEACHER_QUESTIONS_FAIL,
    error: error,
  };
};

export const getTeacherOpenEndedQuestions = () => {
  return (dispatch) => {
    dispatch(getTeacherOpenEndedQuestionsStart());
    axios
      .get(`/opanda/panda-teacher-openended-questions`)
      .then((response) => {
        dispatch(getTeacherOpenEndedQuestionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTeacherOpenEndedQuestionsFail(err.message));
      });
  };
};

export const registerTeacherOpenEndedQuestions = (object) => {
  return (dispatch) => {
    dispatch(getTeacherOpenEndedQuestionsStart());
    axios
      .post(
        `/opanda/panda-teacher-openended-questions/register-questions`,
        object
      )
      .then((response) => {
        dispatch(getTeacherOpenEndedQuestionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTeacherOpenEndedQuestionsFail(err.message));
      });
  };
};
