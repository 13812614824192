
import { TabPanel, useTabs } from 'react-headless-tabs';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef } from "react";
import { MdMenu } from 'react-icons/md';
import { IoSettingsOutline } from 'react-icons/io5';
import { useHistory } from "react-router-dom";
import parse from 'react-html-parser';
import { propTypes } from 'react-bootstrap/esm/Image';
import { CardBody } from '../../../../elements/text';
import { Button } from '../../../../elements/button';
import Spinner from '../../../../elements/spinner';
import GroupParticipants from './GroupParticipants';
const GroupSettingsTab = (props) => {
    const [showSettingsMenu, setShowSettingsMenu] = useState(false);
    const [showParticipants, setShowParticipants] = useState(false);

    return (
        <div key={props.group_id} className=""  >
            <button onClick={() => { setShowSettingsMenu(!showSettingsMenu) }} style={{ border: "1px solid gray", borderRadius: 5 }} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold  p-1 rounded-l">
                ...
            </button>
            {(showSettingsMenu) ? <ul style={{ listStyle: "none", zIndex: 23, background: "white", border: "1px solid gray", borderRadius: 4, position: "relative", padding: 9 }}><li style={{ borderBottom: "2px solid blue", borderRadius: 4, cursor: "pointer" }} onClick={() => {
                setShowSettingsMenu(!showSettingsMenu)
                props.seeParticipants()
            }} ><CardBody
                    name={"Group participants"}
                    color="blue"
                    additional="font-bold"
                /></li>
            </ul> : ""}
        </div>
    );
};

export default GroupSettingsTab;
