import Profile from '../../components/pages/school/complete/profile';
import Layout from '../../components/common/Layout';

const SchoolProfileContainer = () => {
  return (
    <Layout>
      <Profile />
    </Layout>
  );
};

export default SchoolProfileContainer;