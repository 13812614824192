import phoneImg from '../../../images/featurephone.png';
import { CardBody } from '../../elements/text';
const Momopay = () => {
  return (
    <div className="p-2 flex items-center justify-between space-x-4">
      <div className="w-80">
        <img
          className="w-full h-full object-cover object-center"
          src={phoneImg}
          alt=""
        />
      </div>
      <CardBody
        name="After completing your payment, please contact 0786 010 169 / 0780 792 008 for our team to validate your account"
        color="blue"
      />
    </div>
  );
};

export default Momopay;
