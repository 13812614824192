import { useState, useEffect } from "react";
import { CardSubText, CardTitle } from "../../../../../elements/text";
import { Button } from "../../../../../elements/button";
import { completeSignup } from "../../../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../../../elements/spinner";
import * as actions from "../../../../../../store/actions/";
import { Message } from "../../../../../common/messages";
import constants from "../../../../../../constants/constants";
import { useHistory } from 'react-router-dom';
const SalesInformation = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.completeSignup.loading);
  const userId = useSelector((state) => state.auth.userId);
  const user = useSelector((state) => state.auth.user);
  const profile = useSelector((state) => state.profile.profile);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [dataerror, setdataerror] = useState("");
  const history = useHistory();
  const [howDidYouLearnAboutUs, setHowDidYouLearnAboutUs] =
    useState("O'Genius staff");
  const [referralId, setReferralId] = useState(
    user !== undefined || user !== undefined ? user?.refID : ""
  );
  const submitsalesinfo = () => {
    const userObject = {
      sales_info_howyoulearnaboutopanda: howDidYouLearnAboutUs,
      refID: referralId,
      userId,
      completed: 1
    };
    if (
      userObject?.sales_info_howyoulearnaboutopanda !== "" &&
      userObject?.userId !== ""
    ) {

      dispatch(actions.completeprofileinformation(userObject));
      dispatch(actions.updateUserAccountInfoFinal(userObject));
      //dispatch(completeSignup("Sales Information"));
    } else {
      setIsSubmitted(true);
      setdataerror("Some data are missing");
    }
  };
  useEffect(() => {
    //----
    if (user !== null) {
      if (user?.paymentvalid !== true) {
      } else if (user?.paymentvalid === true) {
        if (user?.user_type === constants.studentusertype) {
          if (user?.isprofilecompleted === 1) {
            history.push("/student");
          } else {
            //history.push("/complete");
          }
        } else if (user?.user_type === constants.teacherusertype) {
          if (user?.isprofilecompleted === 1) {
            history.push("/teacher");
          } else {
            history.push("/teacher/user/profile");
          }
        } else if (user?.user_type === constants.schoolusertype) {
          if (user?.isprofilecompleted === 1) {
            history.push("/school");
          } else {
            history.push("/school/complete/profile");
          }
        } else if (user?.user_type === constants.parentusertype) {
          if (user?.isprofilecompleted === 1) {
            history.push("/parent");
          } else {
            history.push("/parent/complete/profile");
          }
        }
      }
    } else {
      history.push("/");
    }
    setInterval(() => {
      if (user !== null) {
        if (user?.paymentvalid !== true) {
        } else if (user?.paymentvalid === true) {
          if (user?.user_type === constants.studentusertype) {
            if (user?.isprofilecompleted === 1) {
              history.push("/student");
            } else {
              //history.push("/complete");
            }
          } else if (user?.user_type === constants.teacherusertype) {
            if (user?.isprofilecompleted === 1) {
              history.push("/teacher");
            } else {
              history.push("/teacher/user/profile");
            }
          } else if (user?.user_type === constants.schoolusertype) {
            if (user?.isprofilecompleted === 1) {
              history.push("/school");
            } else {
              history.push("/school/complete/profile");
            }
          } else if (user?.user_type === constants.parentusertype) {
            if (user?.isprofilecompleted === 1) {
              history.push("/parent");
            } else {
              history.push("/parent/complete/profile");
            }
          }
        }
      } else {
        history.push("/");
      }
    }, 8000);

  }, []);

  return (
    <>
      {loading ? (
        <Spinner size="32" color="blue" />
      ) : (
        <div style={{ height: "100vh" }} className="bg-white drop-shadow-md mt-5 p-2 md:px-10 md:h-tab-screen-recovery md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
          <CardTitle name="Sales Information" color="black" />
          <CardSubText
            name="How did you learn about O'Genius Panda?"
            color="black"
          />
          <div className="py-5">
            <div className="flex space-x-3 items-center py-1">
              <input
                type="radio"
                value="O'Genius staff"
                name="sales"
                onChange={(event) =>
                  setHowDidYouLearnAboutUs(event.target.value)
                }
              />
              <CardSubText name="O'Genius staff" color="black" />
            </div>
            <div className="flex space-x-3 items-center py-1">
              <input
                type="radio"
                value="Social Media"
                name="sales"
                onChange={(event) =>
                  setHowDidYouLearnAboutUs(event.target.value)
                }
              />
              <CardSubText name="Social Media" color="black" />
            </div>
            <div className="flex space-x-3 items-center py-1">
              <input
                type="radio"
                value="Radio/TV Ads"
                name="sales"
                onChange={(event) =>
                  setHowDidYouLearnAboutUs(event.target.value)
                }
              />
              <CardSubText name="Radio/TV Ads" color="black" />
            </div>
            <div className="flex space-x-3 items-center py-1">
              <input
                type="radio"
                value="School"
                name="sales"
                onChange={(event) =>
                  setHowDidYouLearnAboutUs(event.target.value)
                }
              />
              <CardSubText name="School" color="black" />
            </div>
            <div className="flex space-x-3 items-center py-1">
              <input
                type="radio"
                value="Friend"
                name="sales"
                onChange={(event) =>
                  setHowDidYouLearnAboutUs(event.target.value)
                }
              />
              <CardSubText name="Friend" color="black" />
            </div>
            <div className="flex space-x-3 items-center py-1">
              <input
                type="radio"
                value="Referral"
                name="sales"
                onChange={(event) =>
                  setHowDidYouLearnAboutUs(event.target.value)
                }
              />
              <CardSubText name="Referral" color="black" />
              <input
                className="w-full px-1 py-0 outline-none border-px border-solid border-blue rounded focus:border-blue focus:ring-transparent focus:outline-0 focus:outline-transparent"
                type="text"
                placeholder="Referral ID if any"
                value={referralId}
                onChange={(event) => {
                  setReferralId(event.target.value);
                }}
              />
            </div>
          </div>
          {/* <CardSubText
            name="If you want to apply to be an ambassador for 
        O'Genius Panda, click the button below?"
            color="black"
          />
          <br />
          <Button name="Apply" outline="true" color="blue" /> */}
          <Message type={"error"} viewable={true} message={dataerror} />
          <div className="flex space-x-2 justify-center py-5">
            {/* <Button name="Previous" outline="true" color="blue" /> */}
            <Button
              name="Next"
              outline="true"
              color="blue"
              clicked={submitsalesinfo}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SalesInformation;
