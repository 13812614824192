import React, { useRef } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { store } from "../../../../store/store";
import { LoginCard } from "../../../elements/card";
import { CardBody, SectionTitle } from "../../../elements/text";

const HeroSection = (props) => {
  const { t } = useTranslation();
  const loginemail = useRef(null);
  return (
    <div className="bg-white my-4">
      <div className="w-full flex flex-col justify-center align-center pt-8 pb-4 space-y-16 max-w-7xl mx-auto px-8">
        <SectionTitle
          name={t("Log into O'Genius Panda")}
          color="blue"
          alignment="center"
        />
        <CardBody
          name="O'Genius Panda is a web-based platform designed to help users improve their knowledge and skills through personalized learning experiences. There are different types of users who can access the platform, including students, teachers, and administrators."
          color="blue"
          alignment="center"
        />
        <div className="flex flex-col space-y-6 md:space-y-0 grid grid-cols-2 gap-4 grid-cols-1 md:grid-cols-3 align-center justify-center">
          <div onClick={() => {
            const element = document.getElementById('loginView');
            if (element) {
              window.scrollTo(0, element.offsetTop - 100);
            }
          }}><LoginCard
              user="student"
              buttonText={t("Student Login")}
              clicked={props.clicked}
            /></div>
          {<span onClick={() => {
            const element = document.getElementById('loginView');
            if (element) {
              window.scrollTo(0, element.offsetTop - 100);
            }
          }}><LoginCard
              user="parent"
              buttonText={t("Parent Login")}
              clicked={props.clicked}
            /></span>}
          <div onClick={() => {
            const element = document.getElementById('loginView');
            if (element) {
              window.scrollTo(0, element.offsetTop - 100);
            }
          }}><LoginCard
              user="teacher"
              buttonText={t("Teacher Login")}
              clicked={props.clicked}
            /></div>
          <div onClick={() => {
            const element = document.getElementById('loginView');
            if (element) {
              window.scrollTo(0, element.offsetTop - 100);
            }
          }}><LoginCard
              user="school"
              buttonText={t("School Login")}
              clicked={props.clicked}
            /></div>
          {/*<LoginCard
            user="Creators"
            buttonText={t("Creator Login")}
            clicked={props.clicked}
  />*/}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    visibility: store.getState().reducer.mobilesidebar,
    tabselected: store.getState().reducer.tabselected,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changetab: (tab) => store.dispatch({ type: "CHANGE_TAB", payload: tab }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HeroSection);
