import PageContent from "../../pageContent";
import { FeatureTitle } from "../../../../elements/text";
import Notification from "../../../../elements/notification/notification";
import YoutubeEmbed from "../../../../elements/YoutubeEmbed";
import Input from "../../../../elements/input";
import { useState } from "react";
import { Button } from "../../../../elements/button";
const Help = () => {
  const [suggestion, setSuggestion] = useState("");
  const videos = [
    {
      embedId: "rokGy0huYEA",
    },
    {
      embedId: "rokGy0huYEA",
    },
    {
      embedId: "rokGy0huYEA",
    },
    {
      embedId: "rokGy0huYEA",
    },
    {
      embedId: "rokGy0huYEA",
    },
    {
      embedId: "rokGy0huYEA",
    },
  ];

  return (
    <PageContent>
      <div
        className={`md:grid  bg-gray-200 grid-cols-12 w-full min-h-screen-sec max-h-myscreen-sec md:max-h-screen-sec overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
      >
        <div className={`p-3 md:h-full col-span-9`}>
          <FeatureTitle name="Help center" />

          <div className="grid grid-cols-3 gap-3 relative overflow-x-auto sm:rounded-lg mb-4">
            {videos.map((embedId, index) => (
              <YoutubeEmbed key={index} embedId={embedId} />
            ))}
          </div>
          <div className="space-y-4 md:w-3/4">
            <Input
              label="Do you have a specific help video you need?"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Enter suggestion here",
              }}
              value={suggestion}
              changed={setSuggestion}
              validation={{ required: false }}
              shouldValidate
              error="Optional"
            />
            <Button
              name="Submit"
              outline="true"
              color="blue"
              clicked={() => {}}
            />
          </div>
        </div>

        <div className={`p-3 col-span-3`}>
          <Notification auth />
        </div>
      </div>
    </PageContent>
  );
};

export default Help;
