import { updateObject } from "../../../../shared/utility";
import {
  GET_TEACHER_PROGRAMS_START,
  GET_ONE_TEACHER_PROGRAMS_SUCCESS,
  GET_TEACHER_PROGRAMS_FAIL,
  GET_TEACHER_PROGRAMS_SUCCESS,
} from "../../../actions/features/teacher/programs";

const initialReducer = {
  programs: [],
  programsLoading: null,
  programsError: null,
  program: null,
};


const getTeacherProgramsStart = (state, action) => {
  return updateObject(state, {
    programs: [],
    programsError: null,
    programsLoading: true,
    program: null,
  });
};

const getTeacherProgramsSuccess = (state, action) => {
  return updateObject(state, {
    programs: action.payload,
    programsError: null,
    programsLoading: false,
    program: null,
  });
};

const getOneTeacherProgramsSuccess = (state, action) => {
  return updateObject(state, {
    programs: [],
    programsError: null,
    programsLoading: false,
    program: action.payload,
  });
};

const getTeacherProgramsFail = (state, action) => {
  return updateObject(state, {
    programs: [],
    programsError: action.error,
    programsLoading: false,
    program: null,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case GET_TEACHER_PROGRAMS_START:
      return getTeacherProgramsStart(state, action);
    case GET_TEACHER_PROGRAMS_SUCCESS:
      return getTeacherProgramsSuccess(state, action);
    case GET_ONE_TEACHER_PROGRAMS_SUCCESS:
      return getOneTeacherProgramsSuccess(state, action);
    case GET_TEACHER_PROGRAMS_FAIL:
      return getTeacherProgramsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
