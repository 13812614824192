import Modal from "../../../elements/modal/modal";
import { useSelector, useDispatch } from "react-redux";
import { changePaymentDoneModalState } from "../../../../store/actions/payment";
import PrevNext from "./PrevNext";
import { PaymentOptionsCard } from "../../../elements/card";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const PaymentOptions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const showPaymentDoneModal = useSelector(
    (state) => state.payment.showPaymentDoneModal
  );
  const chosenPackage = useSelector((state) => state.payment.chosenPackage);

  useEffect(() => {
    if (!chosenPackage) {
      history.push("/payment/payment");
    }
  }, [chosenPackage, history]);

  return (
    <div className="h-full bg-gray-200 pb-5 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
      {/*<PrevNext
        px="5"
        previousLink="/payment/payment"
        previousName="Pricing Package"
        pageName="Payment Options"
  />*/}
      <PaymentOptionsCard />
    </div>
  );
};

export default PaymentOptions;
