import { useEffect, useState } from 'react';
import { Button } from '../../../../../elements/button';
import RichTextEditor from '../../../../../elements/RichTextEditor';
import { useDispatch } from 'react-redux';
import { changeMultipleQuestionOption } from '../../../../../../store/actions/features/tests';
import { CardBody } from '../../../../../elements/text';

const AnswerOptionForQuestionBank = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const [text, setText] = useState('');
  const [isAnswer, setIsAnswer] = useState(false);

  useEffect(() => {
    // dispatch(
    //   changeMultipleQuestionOption({
    //     id: id,
    //     value: text,
    //     isAnswer: isAnswer,
    //   })
    // );
  }, [text, isAnswer, id]);

  return (
    <div>
      <CardBody name={props.option.value} />
      <div className="flex space-x-2 justify-between">
        <div>
          <input
            id={props.id}
            type={'checkbox'}
            value={props.option.isAnswer}
            checked={props.option.isAnswer}
            disabled={true}
            onChange={(e) => {
              //setIsAnswer(e.target.checked);
              props.updateAnswersOptionsInStatus(id, e.target.checked);
            }}
          />
        </div>       
      </div>
    </div>
  );
};
export default AnswerOptionForQuestionBank;
