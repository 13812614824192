// src/MultipleSelector.js
import React, { useState, useRef, useEffect } from 'react';

const MultipleSelector = ({ onSelectionChange }) => {
  const options = ['Parents', 'Teachers', 'Students', 'Announcements'];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleOption = (option) => {
    const newSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];

    setSelectedOptions(newSelectedOptions);
    onSelectionChange(newSelectedOptions);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full max-w-sm mx-auto p-4">
      <div className="relative">
        <button
        style={{border: '1px solid #e2e8f0'}}
          className="w-full px-4 py-2 border rounded-md bg-white text-gray-700 border-gray-400"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          {selectedOptions.length > 0
            ? selectedOptions.join(', ')
            : 'Select Recipients'}
        </button>
        {dropdownOpen && (
          <div
            ref={dropdownRef}
            className="absolute z-10 w-full mt-2 bg-white border rounded-md shadow-lg"
          >
            {options.map((option) => (
              <label
                key={option}
                className="flex items-center px-4 py-2 hover:bg-gray-100"
              >
                <input
                  type="checkbox"
                  className="form-checkbox"
                  checked={selectedOptions.includes(option)}
                  onChange={() => toggleOption(option)}
                />
                <span className="ml-2">{option}</span>
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MultipleSelector;
