import Layout from '../../../components/common/Layout';
import EventDetails from '../../../components/pages/teacher/schedule/eventdetails';

const Schedule = () => {
  return (
    <Layout>
      <EventDetails />
    </Layout>
  );
};

export default Schedule; 
