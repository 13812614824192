// import Footer from "../components/common/footer";
import Footer from '../components/common/footer';
import Header from '../components/common/header/header';
import ScrollToTop from '../components/common/ScrollToTop';
import PageContent from '../components/pages/landing/privacy/pageContent';
const PrivacyPolicy = (props) => {
  return (
    <div className="scrollbar-hidden font-poppins h-screen">
     <Header />
       <PageContent />
      <Footer />
      <ScrollToTop />
    </div>
  );
};


export default PrivacyPolicy;