import { CardTitle, CardSubText } from "../../text";
import { useTranslation } from "react-i18next";
import { OtherLinkButton } from "../../button";
import { FaAward } from "react-icons/fa";

const Achievement = (props) => {
  const { t } = useTranslation();
  const instant_achievement = [];

  return instant_achievement.length > 0 ? (
    <div className="bg-white px-6 py-3 filter drop-shadow-md rounded-2xl w-full">
      <CardTitle name={props.title} color={props.titleColor} />
      {props.list.map((achievement, index) => (
        <div key={index} className="flex items-center space-x-4 p-2">
          <FaAward size={24} color={achievement.color} />
          <CardSubText name={achievement.name} />
        </div>
      ))}
      <OtherLinkButton color="blue" to="/termsofservice" name={t("More")} />
    </div>
  ) : (
    <div></div>
  );
};

export default Achievement;
