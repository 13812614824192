import parse from 'react-html-parser';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend'
import MatchingAnswerCard from '../../MatchingAnswerCard';
import { useState } from 'react';
import { useDrop } from 'react-dnd';
import DragAndDropItem from './DragAndDropItem';
import {isMobile} from 'react-device-detect';
const DragAndDrop = (props) => {

  const questiondata =
    props?.questiondata?.questiondata.panda_drag_and_drop_2020_03_question_data;
  const questionId = props?.questiondata?.questiondata.panda_drag_and_drop_2020_03_id;
  const answers =
    props?.questiondata?.questiondata.panda_drag_and_drop_2020_03_question_data_answers;
  let answersArray = [];
  let regWord;
  if (answers !== undefined) {
    answersArray = answers.split('~----~');
    //regWord = new RegExp(answersArray.join('|'), 'i');
    regWord = answersArray;
  }

  let displayabledata;
  let questionparts = [];
  const data =
    questiondata !== undefined
      ? (questiondata)
      : ('<p></p>').toString();
  if (data !== undefined) {
    const quest = data;
    questionparts = quest.split(' ');
  }


  const matchTexts = (arr, two) => {
    let decision = false;
    for (var n = 0; n < arr.length; n++) {
      const one = (arr[n]).trim()
      if (one.toLowerCase() === ((two).trim()).toLowerCase().replace("<p>", "").replace("</p>", "") || one.toLowerCase() + "." === ((two).trim()).toLowerCase().replace("<p>", "").replace("</p>", "") || one.toLowerCase() + "!" === ((two).trim()).toLowerCase().replace("<p>", "").replace("</p>", "") || one.toLowerCase() + "?" === ((two).trim()).toLowerCase().replace("<p>", "").replace("</p>", "") || one.toLowerCase() + "," === ((two).trim()).toLowerCase().replace("<p>", "").replace("</p>", "") || one.toLowerCase() + ";" === ((two).trim()).toLowerCase().replace("<p>", "").replace("</p>", "")) {
        decision = true;
      }
    }
    return [decision, (two).trim()]
  }
  const getSanitizedQuestion = (questionparts) => {
    let counter1 = -1;
    const all_special_components = [];

    for (var i = 0; i < questionparts.length; i++) {
      if (matchTexts(regWord, questionparts[i])[0]) {
        counter1 = (counter1 + 1);
      }

      /*<CompleteSentenceResponceCard
          key={i}
          listofanswers={answersArray[counter1]}
          index={counter1}
          questionId={questionId}
        />*/
      questionparts[i] = matchTexts(regWord, questionparts[i])[0] ? (
        `<mark ondrop="drop(event)" ondragover="allowDrop(event)"  style='background:#03b2cb;padding:2px;border-radius:6px;white-space: nowrap;'><span  id=word--${i}>......................</span></mark> `
      ) : (
        ((questionparts[i]).trim()) + " "
      );

      all_special_components.push((matchTexts(regWord, questionparts[i])[0] ? (
        <mark ondrop='drop(event)' ondragover='allowDrop(event)' style='background:#03b2cb;padding:2px;border-radius:6px;white-space: nowrap;'><span id={`word--${i}`}>......................</span></mark>
      ) : (
        ((questionparts[i]).trim()) + " "
      )))
    }
    var tobeparsed = "";
    for (var n = 0; n < questionparts.length; n++) {
      tobeparsed = tobeparsed + questionparts[n];
    }
    tobeparsed = (`${tobeparsed}`);
    let spaceNber = 0;
    for (var i = 0; i < all_special_components.length; i++) {
      const item = all_special_components[i];
      if (typeof item === 'string' && item.includes("<p>")) {
        all_special_components[i] = <span> &nbsp;{(item.replace("<p>", ""))}&nbsp;</span>
      } else if (typeof item === 'string' && item.includes("</p>")) {
        all_special_components[i] = <span>&nbsp;{item.replace("</p>", "")}&nbsp;</span>
      } else if (typeof item === 'string' && !item.includes("<mark")) {
        all_special_components[i] = <span>&nbsp;{item}&nbsp;</span>
      } else {
        spaceNber++;
        all_special_components[i] = <DragAndDropItem spaceNber={spaceNber} calling={"answer" + props.questionId} questionId={props.questionId} currentQuestionNumber={props.currentQuestionNumber} dragAndDrop={props.dragAndDrop} callDragAndDrop={props.callDragAndDrop} />;
      }
    }
    return all_special_components;
  }
  const hasNative =
  document && (document.elementsFromPoint || document.msElementsFromPoint)

  function getDropTargetElementsAtPoint(x, y, dropTargets) {
    return dropTargets.filter((t) => {
      const rect = t.getBoundingClientRect()
      return (
        x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
      )
    })
  }
  
  // use custom function only if elementsFromPoint is not supported
  const backendOptions = {
    getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint
  }
  if (isMobile) {
    return (
      <DndProvider backend={TouchBackend} options={backendOptions}>
        <>
          <label>
            {displayabledata !== undefined
              ? parse(displayabledata)
              : parse('<p></p>')}
          </label>
          <>
            <div className="flex flex-wrap space-x-2 my-2">
              {answersArray.reverse().map((option, id) => (
                <MatchingAnswerCard name={option} calling={"answer" + props.questionId} id={id + (Math.random() * 13)} key={id + (Math.random() * 13)} />
              ))}
            </div>
            <span className="flex flex-wrap">
              {(getSanitizedQuestion(questionparts))}
            </span>
          </>
        </>
      </DndProvider>
    );
  }else{
    return (
      <DndProvider backend={HTML5Backend}>
        <>
          <label>
            {displayabledata !== undefined
              ? parse(displayabledata)
              : parse('<p></p>')}
          </label>
          <>
            <div className="flex flex-wrap space-x-2 my-2">
              {answersArray.reverse().map((option, id) => (
                <MatchingAnswerCard name={option} calling={"answer" + props.questionId} id={id + (Math.random() * 13)} key={id + (Math.random() * 13)} />
              ))}
            </div>
            <span className="flex flex-wrap">
              {(getSanitizedQuestion(questionparts))}
            </span>
          </>
        </>
      </DndProvider>
    );
  }
  
};

export default DragAndDrop;
