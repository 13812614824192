import { useRef, useState, useEffect } from "react";
import Input from "../../../../../elements/input";
import { CardSubText, CardTitle } from "../../../../../elements/text";
import countryOptions from "../../../../../../utils/countries";
import { Provinces, Districts, Sectors } from "rwanda";
import ParentInfo from "../../parentInfo";
import { Button } from "../../../../../elements/button";
import { completeSignup } from "../../../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../../../elements/spinner";
import * as actions from "../../../../../../store/actions/";
import { Message } from "../../../../../common/messages";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdWarning } from "react-icons/md";
import { useHistory } from 'react-router-dom';
//----
import constants from "../../../../../../constants/constants";
import PhonePicker from "../../phonePicker";

const BasicInformation = () => {
  const getDate = () => {
    const fiveYearsAgo = new Date();
    fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 10);
    return fiveYearsAgo;
  }
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.completeSignup.loading);
  const avatars = useSelector((state) => state.completeSignup.avatars);
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const [errorMessage, setErrorMessage] = useState('');
  const [firstName, setFirstName] = useState(
    user != undefined || user != null ? user?.names.split(" ")[1] : ""
  );
  const [lastName, setLastName] = useState(
    user != undefined || user != null ? user?.names.split(" ")[0] : ""
  );
  const [dateOfBirth, setDateOfBirth] = useState(getDate());
  const [gender, setGender] = useState(user != undefined || user != null ? user?.gender : "");
  const [phoneNumber, setPhoneNumber] = useState(
    user != undefined && user != null ? user?.telephone : ""
  );
  const [phoneNumber1, setPhoneNumber1] = useState(
    user != undefined && user != null ? user?.telephone : ""
  );
  const [country, setCountry] = useState("Rwanda");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [sector, setSector] = useState("");
  const [locationInputsDisabled, setLocationInputsDisabled] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [dataerror, setdataerror] = useState("");
  const [selectedAvatar, setSelectedAvatar] = useState((user?.avatarimage && user?.avatarimage.toString().length > 4) ? user?.avatarimage : avatars[0].imgSrc);
  const [currentPageAvatarCap, setCurrentPageAvatarCap] = useState(11);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [avatar, setAvatar] = useState((user?.avatarimage && user?.avatarimage.toString().length > 4) ? user?.avatarimage : avatars[0].imgSrc);
  const history = useHistory();
  if (
    country !== "" &&
    country !== "Rwanda" &&
    locationInputsDisabled !== true
  ) {
    setLocationInputsDisabled(true);
  }
  if (locationInputsDisabled === true && country === "Rwanda") {
    setLocationInputsDisabled(false);
  }
  const [pageIndex, setPageIndex] = useState(0);

  // Define the number of elements to display per page
  const pageSize = 25;

  // Calculate the total number of pages
  const totalPages = Math.ceil(avatars.length / pageSize);

  // Get the current page of elements
  const paginatedAvatars = avatars.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

  // Handle the previous button click
  const handlePrevClick = () => {
    if (pageIndex > 0) {
      setPageIndex(prevPageIndex => prevPageIndex - 1);
    }
  };

  // Handle the next button click
  const handleNextClick = () => {
    if (pageIndex < totalPages - 1) {
      setPageIndex(prevPageIndex => prevPageIndex + 1);
    }
  };
  useEffect(() => {
    //----
    if (user !== null) {
      if (user?.paymentvalid !== true) {
      } else if (user?.paymentvalid === true) {
        if (user?.user_type === constants.studentusertype) {
          if (user?.isprofilecompleted === 1) {
            history.push("/student");
          } else {
            //history.push("/complete");
          }
        } else if (user?.user_type === constants.teacherusertype) {
          if (user?.isprofilecompleted === 1) {
            history.push("/");
            //history.push("/teacher");
          } else {
            history.push("/teacher/user/profile");
          }
        } else if (user?.user_type === constants.schoolusertype) {
          if (user?.isprofilecompleted === 1) {
            history.push("/school");
          } else {
            history.push("/school/complete/profile");
          }
        } else if (user?.user_type === constants.parentusertype) {
          if (user?.isprofilecompleted === 1) {
            history.push("/login");
          } else {
            history.push("/parent/complete/profile");
          }
        }
      }
    } else {
      history.push("/");
    }
    //--dateofbirth     
    if (user && user.country !== "DEFAULT") {
      setCountry(user.country);
    } else {
      setCountry("Rwanda");
    }
    setTimeout(() => {
      if (user && user.province !== "DEFAULT") {
        setProvince(user.province);
        setDistricts(arrayToOptionsHandler(Districts(user.province)));
        setSectors([]);
      } else {
        setProvince("");
        setDistricts([]);
        setSectors([]);
      }
    }, 2000);
    setTimeout(() => {
      if (user && user.district !== "DEFAULT") {
        setDistrict(user.district);
        setSectors(arrayToOptionsHandler(Sectors(user.province, user.district)));
      } else {
        setDistrict("");
        setSectors([]);
      }
    }, 4000);
    setTimeout(() => {
      if (user && user.sector !== "DEFAULT") {
        setSector(user.sector);
      } else {
        setSector("");
      }
    }, 7000);

    setDateOfBirth((user?.dateofbirth) ? new Date(user?.dateofbirth) : getDate());
    setGender((user?.gender) ? (user?.gender.toUpperCase().startsWith("M")) ? "M" : "F" : "")



  }, []);

  // Render the current page of elements
  const renderAvatars = () => {
    return (
      paginatedAvatars.map((avatar, index) => (
        <div
          key={index}
          className="w-8 h-8 8rounded-full border border-gray-500 cursor-pointerta"
          onClick={() => { setSelectedAvatar(avatar.imgSrc); setAvatar(avatar.imgSrc); }}
        >
          <img
            className="w-full h-full rounded-full object-cover object-center"
            src={avatar.imgSrc}
            alt={avatar.name}
          />
        </div>
      ))
    );
  };
  const parentsInfoRef = useRef();
  const phoneRef = useRef();


  const submitbasicinfo = () => {
    const userObject = {
      userId: user?.id,
      names: lastName + " " + firstName,
      telephone: phoneRef.current?.guardianPhones,
      dateofbirth: dateOfBirth,
      // gender: gender,
      province: province,
      district: district,
      sector: sector,
      country: country,
      guardian_name: parentsInfoRef.current?.guardianNames,
      guardian_tel: parentsInfoRef.current?.guardianPhones,
      avatarimage: selectedAvatar,
    };
    const today = new Date();
    const minDate = new Date(today.getFullYear() - 10, today.getMonth(), today.getDate());

    if (dateOfBirth && dateOfBirth.getTime() <= minDate.getTime()) {      
    } else {
      setDateOfBirth(new Date());
      setErrorMessage('You must be at least 10 years old.');
      return;
    }
    if (
      lastName !== "" &&
      firstName !== "" &&
      dateOfBirth !== "" &&
      // gender !== "" &&
      country !== "" &&
      phoneRef.current?.guardianPhones !== ""
    ) {
      dispatch(actions.updateUserAccountInfo(userObject));
      dispatch(completeSignup("Personal Information"));
    } else {
      setIsSubmitted(true);
      setdataerror("Some data are missing");
    }
  };

  const arrayToOptionsHandler = (arr = []) => {
    return arr.map((element) => {
      const item = {};
      item.value = element;
      item.displayValue = element;
      return item;
    });
  };

  const setTheProvince = (value) => {
    setProvince(value);
    setDistricts(arrayToOptionsHandler(Districts(value)));
    setSectors([]);
  };
  const setTheDistrict = (value) => {
    setDistrict(value);
    setSectors(arrayToOptionsHandler(Sectors(province, value)));
  };
  const handleDateChange = (date) => {
    // Check if date is valid and at least 10 years old
    const today = new Date();
    const minDate = new Date(today.getFullYear() - 10, today.getMonth(), today.getDate());

    if (date && date.getTime() <= minDate.getTime()) {
      setDateOfBirth(date);
      setErrorMessage(null);
    } else {
      setDateOfBirth(new Date());
      setErrorMessage('You must be at least 10 years old.');
    }
  };
  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);

    // Validate phone number format
    const phoneNumberRegex = /^\+[1-9]\d{1,14}$/;
    setIsValidPhoneNumber(phoneNumberRegex.test(value));
  }


  return (
    <>
      {loading ? (
        <Spinner size="32" color="blue" />
      ) : (
        <div className="bg-white drop-shadow-md mt-5 p-2 md:px-10 md:h-tab-screen md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
          <CardTitle name="Basic Information" color="black" />
          <div className="md:grid grid-cols-3 gap-2">
            <Input
              label="First Name"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
              }}
              value={firstName}
              changed={setFirstName}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              isSubmitted={isSubmitted}
              error="First Name is required"
            />
            <Input
              label="Last Name"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
              }}
              value={lastName}
              changed={setLastName}
              validation={{ required: true }}
              shouldValidate
              error="Last Name is required"
            />
            <div
              className={`w-full box-border mt-2 flex flex-col justify-center align-start`}
            >
              <CardSubText name={"Date of Birth"} color="black" />
              <DatePicker
                selected={dateOfBirth}
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
                placeholderText="Select a date"
                isClearable
              />
              {errorMessage && <span>
                <MdWarning size="14" color="#BE1E2D" />
                <CardSubText name={errorMessage} color="red" alignment="center" />
              </span>}
            </div>
          </div>
          <div className="md:hidden">
            {/* <Input
              label="Gender"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [
                  { value: "M", displayValue: "Male" },
                  { value: "F", displayValue: "Female" },
                ],
              }}
              value={gender}
              changed={setGender}
              validation={{ required: true }}
              shouldValidate
              error="Gender is required"
            /> */}
            <div className="flex space-x-2">
              <Input
                label="Country"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: countryOptions,
                }}
                value={country}
                changed={setCountry}
                validation={{ required: true }}
                shouldValidate
                error="Country is required"
              />
              {(country.includes("Rwanda")) ? <Input
                label="Province"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: arrayToOptionsHandler(Provinces()),
                  disabled: locationInputsDisabled,
                }}
                value={province}
                changed={setTheProvince}
                validation={{ required: true }}
                shouldValidate
                error="Province is required"
              /> : ""}
            </div>
            {(country.includes("Rwanda")) ? <div className="flex space-x-2">
              <Input
                label="District"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: districts,
                  disabled: locationInputsDisabled,
                }}
                value={district}
                changed={setTheDistrict}
                validation={{ required: true }}
                shouldValidate
                error="District is required"
              />
              <Input
                label="Sector"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: sectors,
                  disabled: locationInputsDisabled,
                }}
                value={sector}
                changed={setSector}
                validation={{ required: true }}
                shouldValidate
                error="Sector is required"
              />
            </div> : ""}
            {/* <Input
              label="Phone Number"
              elementType="phone"
              value={phoneNumber}
              defaultCountry="RW"
              changed={setPhoneNumber}
            /> */}
            <PhonePicker telephone={(user?.telephone) ? user?.telephone : ""} ref={phoneRef} />
          </div>
          <div className="hidden md:grid grid-cols-3 gap-2">
            {/* <Input
              label="Gender"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [
                  { value: "M", displayValue: "Male" },
                  { value: "F", displayValue: "Female" },
                ],
              }}
              value={gender}
              changed={setGender}
              validation={{ required: true }}
              shouldValidate
              error="Gender is required"
            /> */}
            <Input
              label="Country"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: countryOptions,
              }}
              value={country}
              changed={setCountry}
              validation={{ required: true }}
              shouldValidate
              error="Country is required"
            />
            <div></div>
            {(country.includes("Rwanda")) ? <Input
              label="Province"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: arrayToOptionsHandler(Provinces()),
                disabled: locationInputsDisabled,
              }}
              value={province}
              changed={setTheProvince}
              validation={{ required: true }}
              shouldValidate
              error="Province is required"
            /> : ""}
            {(country.includes("Rwanda")) ? <Input
              label="District"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: districts,
                disabled: locationInputsDisabled,
              }}
              value={district}
              changed={setTheDistrict}
              validation={{ required: true }}
              shouldValidate
              error="District is required"
            /> : ""}
            {(country.includes("Rwanda")) ? <Input
              label="Sector"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: sectors,
                disabled: locationInputsDisabled,
              }}
              value={sector}
              changed={setSector}
              validation={{ required: true }}
              shouldValidate
              error="Sector is required"
            /> : ""}
            {/* <Input
              label="Phone Number"
              elementType="phone"
              value={phoneNumber1}
              defaultCountry="RW"
              changed={setPhoneNumber1}              
            /> */}
            <PhonePicker telephone={(user?.telephone) ? user?.telephone : ""} ref={phoneRef} />
          </div>
          <div className={user?.user_type == "student" ? "block" : "hidden"}>
            <ParentInfo guardian_name={(user?.guardian_name) ? user?.guardian_name : ""} guardian_tel={(user?.guardian_tel) ? user?.guardian_tel : ""} ref={parentsInfoRef} />
          </div>
          <Message type={"error"} viewable={true} message={dataerror} />
          <div>
            <CardTitle name="Choose an avatar" color="black" />
            <div className="flex justify-between md:justify-start md:space-x-10 items-center">
              <div className="grid grid-cols-3 w:32 gap-2 h-48 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100 p-3">
                {renderAvatars()}
                <button style={{ visibility: (pageIndex > 0) ? "visible" : "hidden" }} className=" cursor-pointer h-10   transition ease-in duration-200 text-center hover:text-red bg-blue hover:bg-white text-white hover:text-blue border-2 border-blue hover:border-blue focus:outline-none " disabled={pageIndex === 0} onClick={handlePrevClick}>
                  Previous
                </button>
                <button style={{ visibility: (pageIndex < totalPages - 1) ? "visible" : "hidden" }} className=" cursor-pointer h-10   transition ease-in duration-200 text-center hover:text-red bg-blue hover:bg-white text-white hover:text-blue border-2 border-blue hover:border-blue focus:outline-none " disabled={pageIndex === totalPages - 1} onClick={handleNextClick}>
                  Next
                </button>
              </div>
              <div className="w-40 h-40 rounded-full border border-gray-500">
                <img
                  className="w-full h-full rounded-full object-cover object-center"
                  src={avatar}
                  alt={avatar}
                />
              </div>
            </div>
          </div>
          <div className="flex space-x-2 justify-center py-5">
            {/* <Button name="Previous" outline="true" color="blue" /> */}
            <Button
              name="Next"
              outline="true"
              color="blue"
              clicked={submitbasicinfo}
            />
          </div>
        </div>
      )}
    </>
  );
};

export const BasicInformationTeach = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.completeSignup.loading);
  const user = useSelector((state) => state.auth.user);
  const [firstName, setFirstName] = useState(
    user != undefined || user != null ? user?.names.split(" ")[1] : ""
  );
  const [lastName, setLastName] = useState(
    user != undefined || user != null ? user?.names.split(" ")[0] : ""
  );
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [sector, setSector] = useState("");
  const [locationInputsDisabled, setLocationInputsDisabled] = useState(false);

  if (
    country !== "" &&
    country !== "Rwanda" &&
    locationInputsDisabled !== true
  ) {
    setLocationInputsDisabled(true);
  }
  if (locationInputsDisabled === true && country === "Rwanda") {
    setLocationInputsDisabled(false);
  }

  const parentsInfoRef = useRef();

  const submitbasicinfo = () => {
    const userObject = {
      userId: user?.id,
      names: lastName + " " + firstName,
      dateofbirth: dateOfBirth,
      gender: gender,
      province: province,
      district: district,
      sector: sector,
      country: country,
      guardian_name: parentsInfoRef.current?.guardianNames,
      guardian_tel: parentsInfoRef.current?.guardianPhones,
    };
    dispatch(actions.updateUserAccountInfo(userObject));
    dispatch(completeSignup("Personal Information"));
  };

  const arrayToOptionsHandler = (arr = []) => {
    return arr.map((element) => {
      const item = {};
      item.value = element;
      item.displayValue = element;
      return item;
    });
  };
  return (
    <>
      {loading ? (
        <Spinner size="32" color="blue" />
      ) : (
        <div className="bg-white drop-shadow-md mt-5 p-2 md:px-10 md:h-tab-screen md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
          <CardTitle name="Basic Information" color="black" />
          <div className="md:grid grid-cols-3 gap-2">
            <Input
              label="First Name"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
              }}
              value={firstName}
              changed={setFirstName}
              validation={{ required: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Last Name"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
              }}
              value={lastName}
              changed={setLastName}
              validation={{ required: true }}
              shouldValidate
              error="Last Name is required"
            />
            <Input
              label="Date of Birth"
              elementType="input"
              elementConfig={{
                type: "date",
                placeholder: "dd-mm-yyyy",
                max: new Date(),
              }}
              value={dateOfBirth}
              changed={setDateOfBirth}
              validation={{ required: true, olderThan10: true }}
              shouldValidate
              error="Date of Birth is required"
            />
          </div>
          <div className="md:hidden">
            <Input
              label="Gender"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [
                  { value: "M", displayValue: "Male" },
                  { value: "F", displayValue: "Female" },
                ],
              }}
              value={gender}
              changed={setGender}
              validation={{ required: true }}
              shouldValidate
              error="Gender is required"
            />
            <div className="flex space-x-2">
              <Input
                label="Country"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: countryOptions,
                }}
                value={country}
                changed={setCountry}
                validation={{ required: true }}
                shouldValidate
                error="Country is required"
              />
              <Input
                label="Province"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: arrayToOptionsHandler(Provinces()),
                  disabled: locationInputsDisabled,
                }}
                value={province}
                changed={setProvince}
                validation={{ required: true }}
                shouldValidate
                error="Province is required"
              />
            </div>
            <div className="flex space-x-2">
              <Input
                label="District"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: arrayToOptionsHandler(Districts(province)),
                  disabled: locationInputsDisabled,
                }}
                value={district}
                changed={setDistrict}
                validation={{ required: true }}
                shouldValidate
                error="District is required"
              />
              <Input
                label="Sector"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: arrayToOptionsHandler(Sectors(province, district)),
                  disabled: locationInputsDisabled,
                }}
                value={sector}
                changed={setSector}
                validation={{ required: true }}
                shouldValidate
                error="Sector is required"
              />
            </div>
            <Input
              label="Phone Number"
              elementType="phone"
              value={phoneNumber}
              changed={setPhoneNumber}
            />
          </div>
          <div className="hidden md:grid grid-cols-3 gap-2">
            <Input
              label="Gender"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [
                  { value: "M", displayValue: "Male" },
                  { value: "F", displayValue: "Female" },
                ],
              }}
              value={gender}
              changed={setGender}
              validation={{ required: true }}
              shouldValidate
              error="Gender is required"
            />
            <Input
              label="Country"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: countryOptions,
              }}
              value={country}
              changed={setCountry}
              validation={{ required: true }}
              shouldValidate
              error="Country is required"
            />
            <div></div>
            <Input
              label="Province"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: arrayToOptionsHandler(Provinces()),
                disabled: locationInputsDisabled,
              }}
              value={province}
              changed={setProvince}
              validation={{ required: true }}
              shouldValidate
              error="Province is required"
            />
            <Input
              label="District"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: arrayToOptionsHandler(Districts(province)),
                disabled: locationInputsDisabled,
              }}
              value={district}
              changed={setDistrict}
              validation={{ required: true }}
              shouldValidate
              error="District is required"
            />
            <Input
              label="Sector"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: arrayToOptionsHandler(Sectors(province, district)),
                disabled: locationInputsDisabled,
              }}
              value={sector}
              changed={setSector}
              validation={{ required: true }}
              shouldValidate
              error="Sector is required"
            />
            <Input
              label="Phone Number"
              elementType="phone"
              value={phoneNumber}
              changed={setPhoneNumber}
            />
          </div>
          <div className="flex space-x-2 justify-center py-5">
            <Button
              name="Next"
              outline="true"
              color="blue"
              clicked={submitbasicinfo}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default BasicInformation;
