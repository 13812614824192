const countries = [
  {
    value: 'Afghanistan',
    displayValue: 'Afghanistan',
  },
  {
    value: 'Aland Islands',
    displayValue: 'Aland Islands',
  },
  {
    value: 'Albania',
    displayValue: 'Albania',
  },
  {
    value: 'Algeria',
    displayValue: 'Algeria',
  },
  {
    value: 'American Samoa',
    displayValue: 'American Samoa',
  },
  {
    value: 'Andorra',
    displayValue: 'Andorra',
  },
  {
    value: 'Angola',
    displayValue: 'Angola',
  },
  {
    value: 'Anguilla',
    displayValue: 'Anguilla',
  },
  {
    value: 'Antarctica',
    displayValue: 'Antarctica',
  },
  {
    value: 'Antigua and Barbuda',
    displayValue: 'Antigua and Barbuda',
  },
  {
    value: 'Argentina',
    displayValue: 'Argentina',
  },
  {
    value: 'Armenia',
    displayValue: 'Armenia',
  },
  {
    value: 'Aruba',
    displayValue: 'Aruba',
  },
  {
    value: 'Australia',
    displayValue: 'Australia',
  },
  {
    value: 'Austria',
    displayValue: 'Austria',
  },
  {
    value: 'Azerbaijan',
    displayValue: 'Azerbaijan',
  },
  {
    value: 'Bahamas',
    displayValue: 'Bahamas',
  },
  {
    value: 'Bahrain',
    displayValue: 'Bahrain',
  },
  {
    value: 'Bangladesh',
    displayValue: 'Bangladesh',
  },
  {
    value: 'Barbados',
    displayValue: 'Barbados',
  },
  {
    value: 'Belarus',
    displayValue: 'Belarus',
  },
  {
    value: 'Belgium',
    displayValue: 'Belgium',
  },
  {
    value: 'Belize',
    displayValue: 'Belize',
  },
  {
    value: 'Benin',
    displayValue: 'Benin',
  },
  {
    value: 'Bermuda',
    displayValue: 'Bermuda',
  },
  {
    value: 'Bhutan',
    displayValue: 'Bhutan',
  },
  {
    value: 'Bolivia',
    displayValue: 'Bolivia',
  },
  {
    value: 'Bonaire, Sint Eustatius and Saba',
    displayValue: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    value: 'Bosnia and Herzegovina',
    displayValue: 'Bosnia and Herzegovina',
  },
  {
    value: 'Botswana',
    displayValue: 'Botswana',
  },
  {
    value: 'Bouvet Island',
    displayValue: 'Bouvet Island',
  },
  {
    value: 'Brazil',
    displayValue: 'Brazil',
  },
  {
    value: 'British Indian Ocean Territory',
    displayValue: 'British Indian Ocean Territory',
  },
  {
    value: 'Brunei Darussalam',
    displayValue: 'Brunei Darussalam',
  },
  {
    value: 'Bulgaria',
    displayValue: 'Bulgaria',
  },
  {
    value: 'Burkina Faso',
    displayValue: 'Burkina Faso',
  },
  {
    value: 'Burundi',
    displayValue: 'Burundi',
  },
  {
    value: 'Cambodia',
    displayValue: 'Cambodia',
  },
  {
    value: 'Cameroon',
    displayValue: 'Cameroon',
  },
  {
    value: 'Canada',
    displayValue: 'Canada',
  },
  {
    value: 'Cape Verde',
    displayValue: 'Cape Verde',
  },
  {
    value: 'Cayman Islands',
    displayValue: 'Cayman Islands',
  },
  {
    value: 'Central African Republic',
    displayValue: 'Central African Republic',
  },
  {
    value: 'Chad',
    displayValue: 'Chad',
  },
  {
    value: 'Chile',
    displayValue: 'Chile',
  },
  {
    value: 'China',
    displayValue: 'China',
  },
  {
    value: 'Christmas Island',
    displayValue: 'Christmas Island',
  },
  {
    value: 'Cocos (Keeling) Islands',
    displayValue: 'Cocos (Keeling) Islands',
  },
  {
    value: 'Colombia',
    displayValue: 'Colombia',
  },
  {
    value: 'Comoros',
    displayValue: 'Comoros',
  },
  {
    value: 'Congo',
    displayValue: 'Congo',
  },
  {
    value: 'Congo, the Democratic Republic of the',
    displayValue: 'Congo, the Democratic Republic of the',
  },
  {
    value: 'Cook Islands',
    displayValue: 'Cook Islands',
  },
  {
    value: 'Costa Rica',
    displayValue: 'Costa Rica',
  },
  {
    value: 'Cote D Ivoire',
    displayValue: 'Cote D Ivoire',
  },
  {
    value: 'Croatia',
    displayValue: 'Croatia',
  },
  {
    value: 'Cuba',
    displayValue: 'Cuba',
  },
  {
    value: 'Curacao',
    displayValue: 'Curacao',
  },
  {
    value: 'Cyprus',
    displayValue: 'Cyprus',
  },
  {
    value: 'Czech Republic',
    displayValue: 'Czech Republic',
  },
  {
    value: 'Denmark',
    displayValue: 'Denmark',
  },
  {
    value: 'Djibouti',
    displayValue: 'Djibouti',
  },
  {
    value: 'Dominica',
    displayValue: 'Dominica',
  },
  {
    value: 'Dominican Republic',
    displayValue: 'Dominican Republic',
  },
  {
    value: 'Ecuador',
    displayValue: 'Ecuador',
  },
  {
    value: 'Egypt',
    displayValue: 'Egypt',
  },
  {
    value: 'El Salvador',
    displayValue: 'El Salvador',
  },
  {
    value: 'Equatorial Guinea',
    displayValue: 'Equatorial Guinea',
  },
  {
    value: 'Eritrea',
    displayValue: 'Eritrea',
  },
  {
    value: 'Estonia',
    displayValue: 'Estonia',
  },
  {
    value: 'Ethiopia',
    displayValue: 'Ethiopia',
  },
  {
    value: 'Falkland Islands (Malvinas)',
    displayValue: 'Falkland Islands (Malvinas)',
  },
  {
    value: 'Faroe Islands',
    displayValue: 'Faroe Islands',
  },
  {
    value: 'Fiji',
    displayValue: 'Fiji',
  },
  {
    value: 'Finland',
    displayValue: 'Finland',
  },
  {
    value: 'France',
    displayValue: 'France',
  },
  {
    value: 'French Guiana',
    displayValue: 'French Guiana',
  },
  {
    value: 'French Polynesia',
    displayValue: 'French Polynesia',
  },
  {
    value: 'French Southern Territories',
    displayValue: 'French Southern Territories',
  },
  {
    value: 'Gabon',
    displayValue: 'Gabon',
  },
  {
    value: 'Gambia',
    displayValue: 'Gambia',
  },
  {
    value: 'Georgia',
    displayValue: 'Georgia',
  },
  {
    value: 'Germany',
    displayValue: 'Germany',
  },
  {
    value: 'Ghana',
    displayValue: 'Ghana',
  },
  {
    value: 'Gibraltar',
    displayValue: 'Gibraltar',
  },
  {
    value: 'Greece',
    displayValue: 'Greece',
  },
  {
    value: 'Greenland',
    displayValue: 'Greenland',
  },
  {
    value: 'Grenada',
    displayValue: 'Grenada',
  },
  {
    value: 'Guadeloupe',
    displayValue: 'Guadeloupe',
  },
  {
    value: 'Guam',
    displayValue: 'Guam',
  },
  {
    value: 'Guatemala',
    displayValue: 'Guatemala',
  },
  {
    value: 'Guernsey',
    displayValue: 'Guernsey',
  },
  {
    value: 'Guinea',
    displayValue: 'Guinea',
  },
  {
    value: 'Guinea-Bissau',
    displayValue: 'Guinea-Bissau',
  },
  {
    value: 'Guyana',
    displayValue: 'Guyana',
  },
  {
    value: 'Haiti',
    displayValue: 'Haiti',
  },
  {
    value: 'Heard Island and Mcdonald Islands',
    displayValue: 'Heard Island and Mcdonald Islands',
  },
  {
    value: 'Holy See (Vatican City State)',
    displayValue: 'Holy See (Vatican City State)',
  },
  {
    value: 'Honduras',
    displayValue: 'Honduras',
  },
  {
    value: 'Hong Kong',
    displayValue: 'Hong Kong',
  },
  {
    value: 'Hungary',
    displayValue: 'Hungary',
  },
  {
    value: 'Iceland',
    displayValue: 'Iceland',
  },
  {
    value: 'India',
    displayValue: 'India',
  },
  {
    value: 'Indonesia',
    displayValue: 'Indonesia',
  },
  {
    value: 'Iran, Islamic Republic of',
    displayValue: 'Iran, Islamic Republic of',
  },
  {
    value: 'Iraq',
    displayValue: 'Iraq',
  },
  {
    value: 'Ireland',
    displayValue: 'Ireland',
  },
  {
    value: 'Isle of Man',
    displayValue: 'Isle of Man',
  },
  {
    value: 'Israel',
    displayValue: 'Israel',
  },
  {
    value: 'Italy',
    displayValue: 'Italy',
  },
  {
    value: 'Jamaica',
    displayValue: 'Jamaica',
  },
  {
    value: 'Japan',
    displayValue: 'Japan',
  },
  {
    value: 'Jersey',
    displayValue: 'Jersey',
  },
  {
    value: 'Jordan',
    displayValue: 'Jordan',
  },
  {
    value: 'Kazakhstan',
    displayValue: 'Kazakhstan',
  },
  {
    value: 'Kenya',
    displayValue: 'Kenya',
  },
  {
    value: 'Kiribati',
    displayValue: 'Kiribati',
  },
  {
    value: 'Korea, Democratic People s Republic of',
    displayValue: 'Korea, Democratic People s Republic of',
  },
  {
    value: 'Korea, Republic of',
    displayValue: 'Korea, Republic of',
  },
  {
    value: 'Kosovo',
    displayValue: 'Kosovo',
  },
  {
    value: 'Kuwait',
    displayValue: 'Kuwait',
  },
  {
    value: 'Kyrgyzstan',
    displayValue: 'Kyrgyzstan',
  },
  {
    value: 'Lao People Democratic Republic',
    displayValue: 'Lao People Democratic Republic',
  },
  {
    value: 'Latvia',
    displayValue: 'Latvia',
  },
  {
    value: 'Lebanon',
    displayValue: 'Lebanon',
  },
  {
    value: 'Lesotho',
    displayValue: 'Lesotho',
  },
  {
    value: 'Liberia',
    displayValue: 'Liberia',
  },
  {
    value: 'Libyan Arab Jamahiriya',
    displayValue: 'Libyan Arab Jamahiriya',
  },
  {
    value: 'Liechtenstein',
    displayValue: 'Liechtenstein',
  },
  {
    value: 'Lithuania',
    displayValue: 'Lithuania',
  },
  {
    value: 'Luxembourg',
    displayValue: 'Luxembourg',
  },
  {
    value: 'Macao',
    displayValue: 'Macao',
  },
  {
    value: 'Macedonia, the Former Yugoslav Republic of',
    displayValue: 'Macedonia, the Former Yugoslav Republic of',
  },
  {
    value: 'Madagascar',
    displayValue: 'Madagascar',
  },
  {
    value: 'Malawi',
    displayValue: 'Malawi',
  },
  {
    value: 'Malaysia',
    displayValue: 'Malaysia',
  },
  {
    value: 'Maldives',
    displayValue: 'Maldives',
  },
  {
    value: 'Mali',
    displayValue: 'Mali',
  },
  {
    value: 'Malta',
    displayValue: 'Malta',
  },
  {
    value: 'Marshall Islands',
    displayValue: 'Marshall Islands',
  },
  {
    value: 'Martinique',
    displayValue: 'Martinique',
  },
  {
    value: 'Mauritania',
    displayValue: 'Mauritania',
  },
  {
    value: 'Mauritius',
    displayValue: 'Mauritius',
  },
  {
    value: 'Mayotte',
    displayValue: 'Mayotte',
  },
  {
    value: 'Mexico',
    displayValue: 'Mexico',
  },
  {
    value: 'Micronesia, Federated States of',
    displayValue: 'Micronesia, Federated States of',
  },
  {
    value: 'Moldova, Republic of',
    displayValue: 'Moldova, Republic of',
  },
  {
    value: 'Monaco',
    displayValue: 'Monaco',
  },
  {
    value: 'Mongolia',
    displayValue: 'Mongolia',
  },
  {
    value: 'Montenegro',
    displayValue: 'Montenegro',
  },
  {
    value: 'Montserrat',
    displayValue: 'Montserrat',
  },
  {
    value: 'Morocco',
    displayValue: 'Morocco',
  },
  {
    value: 'Mozambique',
    displayValue: 'Mozambique',
  },
  {
    value: 'Myanmar',
    displayValue: 'Myanmar',
  },
  {
    value: 'Namibia',
    displayValue: 'Namibia',
  },
  {
    value: 'Nauru',
    displayValue: 'Nauru',
  },
  {
    value: 'Nepal',
    displayValue: 'Nepal',
  },
  {
    value: 'Netherlands',
    displayValue: 'Netherlands',
  },
  {
    value: 'Netherlands Antilles',
    displayValue: 'Netherlands Antilles',
  },
  {
    value: 'New Caledonia',
    displayValue: 'New Caledonia',
  },
  {
    value: 'New Zealand',
    displayValue: 'New Zealand',
  },
  {
    value: 'Nicaragua',
    displayValue: 'Nicaragua',
  },
  {
    value: 'Niger',
    displayValue: 'Niger',
  },
  {
    value: 'Nigeria',
    displayValue: 'Nigeria',
  },
  {
    value: 'Niue',
    displayValue: 'Niue',
  },
  {
    value: 'Norfolk Island',
    displayValue: 'Norfolk Island',
  },
  {
    value: 'Northern Mariana Islands',
    displayValue: 'Northern Mariana Islands',
  },
  {
    value: 'Norway',
    displayValue: 'Norway',
  },
  {
    value: 'Oman',
    displayValue: 'Oman',
  },
  {
    value: 'Pakistan',
    displayValue: 'Pakistan',
  },
  {
    value: 'Palau',
    displayValue: 'Palau',
  },
  {
    value: 'Palestinian Territory, Occupied',
    displayValue: 'Palestinian Territory, Occupied',
  },
  {
    value: 'Panama',
    displayValue: 'Panama',
  },
  {
    value: 'Papua New Guinea',
    displayValue: 'Papua New Guinea',
  },
  {
    value: 'Paraguay',
    displayValue: 'Paraguay',
  },
  {
    value: 'Peru',
    displayValue: 'Peru',
  },
  {
    value: 'Philippines',
    displayValue: 'Philippines',
  },
  {
    value: 'Pitcairn',
    displayValue: 'Pitcairn',
  },
  {
    value: 'Poland',
    displayValue: 'Poland',
  },
  {
    value: 'Portugal',
    displayValue: 'Portugal',
  },
  {
    value: 'Puerto Rico',
    displayValue: 'Puerto Rico',
  },
  {
    value: 'Qatar',
    displayValue: 'Qatar',
  },
  {
    value: 'Reunion',
    displayValue: 'Reunion',
  },
  {
    value: 'Romania',
    displayValue: 'Romania',
  },
  {
    value: 'Russian Federation',
    displayValue: 'Russian Federation',
  },
  {
    value: 'Rwanda',
    displayValue: 'Rwanda',
  },
  {
    value: 'Saint Barthelemy',
    displayValue: 'Saint Barthelemy',
  },
  {
    value: 'Saint Helena',
    displayValue: 'Saint Helena',
  },
  {
    value: 'Saint Kitts and Nevis',
    displayValue: 'Saint Kitts and Nevis',
  },
  {
    value: 'Saint Lucia',
    displayValue: 'Saint Lucia',
  },
  {
    value: 'Saint Martin',
    displayValue: 'Saint Martin',
  },
  {
    value: 'Saint Pierre and Miquelon',
    displayValue: 'Saint Pierre and Miquelon',
  },
  {
    value: 'Saint Vincent and the Grenadines',
    displayValue: 'Saint Vincent and the Grenadines',
  },
  {
    value: 'Samoa',
    displayValue: 'Samoa',
  },
  {
    value: 'San Marino',
    displayValue: 'San Marino',
  },
  {
    value: 'Sao Tome and Principe',
    displayValue: 'Sao Tome and Principe',
  },
  {
    value: 'Saudi Arabia',
    displayValue: 'Saudi Arabia',
  },
  {
    value: 'Senegal',
    displayValue: 'Senegal',
  },
  {
    value: 'Serbia',
    displayValue: 'Serbia',
  },
  {
    value: 'Serbia and Montenegro',
    displayValue: 'Serbia and Montenegro',
  },
  {
    value: 'Seychelles',
    displayValue: 'Seychelles',
  },
  {
    value: 'Sierra Leone',
    displayValue: 'Sierra Leone',
  },
  {
    value: 'Singapore',
    displayValue: 'Singapore',
  },
  {
    value: 'Sint Maarten',
    displayValue: 'Sint Maarten',
  },
  {
    value: 'Slovakia',
    displayValue: 'Slovakia',
  },
  {
    value: 'Slovenia',
    displayValue: 'Slovenia',
  },
  {
    value: 'Solomon Islands',
    displayValue: 'Solomon Islands',
  },
  {
    value: 'Somalia',
    displayValue: 'Somalia',
  },
  {
    value: 'South Africa',
    displayValue: 'South Africa',
  },
  {
    value: 'South Georgia and the South Sandwich Islands',
    displayValue: 'South Georgia and the South Sandwich Islands',
  },
  {
    value: 'South Sudan',
    displayValue: 'South Sudan',
  },
  {
    value: 'Spain',
    displayValue: 'Spain',
  },
  {
    value: 'Sri Lanka',
    displayValue: 'Sri Lanka',
  },
  {
    value: 'Sudan',
    displayValue: 'Sudan',
  },
  {
    value: 'Suriname',
    displayValue: 'Suriname',
  },
  {
    value: 'Svalbard and Jan Mayen',
    displayValue: 'Svalbard and Jan Mayen',
  },
  {
    value: 'Swaziland',
    displayValue: 'Swaziland',
  },
  {
    value: 'Sweden',
    displayValue: 'Sweden',
  },
  {
    value: 'Switzerland',
    displayValue: 'Switzerland',
  },
  {
    value: 'Syrian Arab Republic',
    displayValue: 'Syrian Arab Republic',
  },
  {
    value: 'Taiwan, Province of China',
    displayValue: 'Taiwan, Province of China',
  },
  {
    value: 'Tajikistan',
    displayValue: 'Tajikistan',
  },
  {
    value: 'Tanzania, United Republic of',
    displayValue: 'Tanzania, United Republic of',
  },
  {
    value: 'Thailand',
    displayValue: 'Thailand',
  },
  {
    value: 'Timor-Leste',
    displayValue: 'Timor-Leste',
  },
  {
    value: 'Togo',
    displayValue: 'Togo',
  },
  {
    value: 'Tokelau',
    displayValue: 'Tokelau',
  },
  {
    value: 'Tonga',
    displayValue: 'Tonga',
  },
  {
    value: 'Trinidad and Tobago',
    displayValue: 'Trinidad and Tobago',
  },
  {
    value: 'Tunisia',
    displayValue: 'Tunisia',
  },
  {
    value: 'Turkey',
    displayValue: 'Turkey',
  },
  {
    value: 'Turkmenistan',
    displayValue: 'Turkmenistan',
  },
  {
    value: 'Turks and Caicos Islands',
    displayValue: 'Turks and Caicos Islands',
  },
  {
    value: 'Tuvalu',
    displayValue: 'Tuvalu',
  },
  {
    value: 'Uganda',
    displayValue: 'Uganda',
  },
  {
    value: 'Ukraine',
    displayValue: 'Ukraine',
  },
  {
    value: 'United Arab Emirates',
    displayValue: 'United Arab Emirates',
  },
  {
    value: 'United Kingdom',
    displayValue: 'United Kingdom',
  },
  {
    value: 'United States',
    displayValue: 'United States',
  },
  {
    value: 'United States Minor Outlying Islands',
    displayValue: 'United States Minor Outlying Islands',
  },
  {
    value: 'Uruguay',
    displayValue: 'Uruguay',
  },
  {
    value: 'Uzbekistan',
    displayValue: 'Uzbekistan',
  },
  {
    value: 'Vanuatu',
    displayValue: 'Vanuatu',
  },
  {
    value: 'Venezuela',
    displayValue: 'Venezuela',
  },
  {
    value: 'Viet Nam',
    displayValue: 'Viet Nam',
  },
  {
    value: 'Virgin Islands, British',
    displayValue: 'Virgin Islands, British',
  },
  {
    value: 'Virgin Islands, U.s.',
    displayValue: 'Virgin Islands, U.s.',
  },
  {
    value: 'Wallis and Futuna',
    displayValue: 'Wallis and Futuna',
  },
  {
    value: 'Western Sahara',
    displayValue: 'Western Sahara',
  },
  {
    value: 'Yemen',
    displayValue: 'Yemen',
  },
  {
    value: 'Zambia',
    displayValue: 'Zambia',
  },
  {
    value: 'Zimbabwe',
    displayValue: 'Zimbabwe',
  },
];
export default countries;
