import { updateObject } from "../../shared/utility";
import {
  STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_GET_SUBJECTS_WITH_SELECT_UNITS_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_GET_SUBJECTS_WITH_SELECT_UNITS_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_GET_SUBJECTS_WITH_SELECT_UNITS_FAILED,
  //---Mark simulation as viewed
  STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_MARK_A_SIMULATION_AS_VIEWED_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_MARK_A_SIMULATION_AS_VIEWED_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_MARK_A_SIMULATION_AS_VIEWED_FAILED,
  //---Simulations documents, videos and
  STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_DOCUMENTS_AND_VIDEOS_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_DOCUMENTS_AND_VIDEOS_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_DOCUMENTS_AND_VIDEOS_FAILED,
} from "../actions/student.dashboard.simulations";
//--
const initialReducer = {
  student_dashboard_homepage_simulations_get_subjects_with_select_units: [],
  student_dashboard_homepage_simulations_get_subjects_with_select_units_loading: true,
  message: "",
  //--Mark simulations as viewed
  student_dashboard_homepage_simulations_mark_a_simulation_as_viewed: [],
  student_dashboard_homepage_simulations_mark_a_simulation_as_viewed_loading: true,
  message1: "",
  //---Simulations documents, videos and 
  student_dashboard_homepage_simulations_documents_and_videos: [],
  student_dashboard_homepage_simulations_documents_and_videos_loading: true,
  message2: "",
};

function student_dashboard_homepage_simulations_get_subjects_with_select_units_init(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_simulations_get_subjects_with_select_units_loading: true,
  });
}
function student_dashboard_homepage_simulations_get_subjects_with_select_units_success(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_simulations_get_subjects_with_select_units: action.payload,
    student_dashboard_homepage_simulations_get_subjects_with_select_units_loading: false,
    message: "success"
  });
}
function student_dashboard_homepage_simulations_get_subjects_with_select_units_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    student_dashboard_homepage_simulations_get_subjects_with_select_units_loading: false,
  });
}
//---Mark simulation as viewed
function student_dashboard_homepage_simulations_mark_a_simulation_as_viewed_init(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_simulations_mark_a_simulation_as_viewed_loading: true,
  });
}
function student_dashboard_homepage_simulations_mark_a_simulation_as_viewed_success(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_simulations_mark_a_simulation_as_viewed: action.payload,
    student_dashboard_homepage_simulations_mark_a_simulation_as_viewed_loading: false,
    message1: "success"
  });
}
function student_dashboard_homepage_simulations_mark_a_simulation_as_viewed_failed(state, action) {
  return updateObject(state, {
    message1: action.payload,
    student_dashboard_homepage_simulations_mark_a_simulation_as_viewed_loading: false,
  });
}
//---Simulations documents, videos and
function student_dashboard_homepage_simulations_documents_and_videos_init(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_simulations_documents_and_videos_loading: true,
  });
}
function student_dashboard_homepage_simulations_documents_and_videos_success(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_simulations_documents_and_videos: action.payload,
    student_dashboard_homepage_simulations_documents_and_videos_loading: false,
    message2: "success"
  });
}
function student_dashboard_homepage_simulations_documents_and_videos_failed(state, action) {
  return updateObject(state, {
    message2: action.payload,
    student_dashboard_homepage_simulations_documents_and_videos_loading: false,
  });
}
const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_GET_SUBJECTS_WITH_SELECT_UNITS_INIT:
      return student_dashboard_homepage_simulations_get_subjects_with_select_units_init(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_GET_SUBJECTS_WITH_SELECT_UNITS_SUCCESS:
      return student_dashboard_homepage_simulations_get_subjects_with_select_units_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_GET_SUBJECTS_WITH_SELECT_UNITS_FAILED:
      return student_dashboard_homepage_simulations_get_subjects_with_select_units_failed(state, action);
    //---Mark simulation as viewed  
    case STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_MARK_A_SIMULATION_AS_VIEWED_INIT:
      return student_dashboard_homepage_simulations_mark_a_simulation_as_viewed_init(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_MARK_A_SIMULATION_AS_VIEWED_SUCCESS:
      return student_dashboard_homepage_simulations_mark_a_simulation_as_viewed_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_MARK_A_SIMULATION_AS_VIEWED_FAILED:
      return student_dashboard_homepage_simulations_mark_a_simulation_as_viewed_failed(state, action);
    //---Simulations documents, videos and
    case STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_DOCUMENTS_AND_VIDEOS_INIT:
      return student_dashboard_homepage_simulations_documents_and_videos_init(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_DOCUMENTS_AND_VIDEOS_SUCCESS:
      return student_dashboard_homepage_simulations_documents_and_videos_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_SIMULATIONS_DOCUMENTS_AND_VIDEOS_FAILED:
      return student_dashboard_homepage_simulations_documents_and_videos_failed(state, action);
    default:
      return state;
  }
};
export default reducer;
