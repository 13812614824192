import Datatable from "../../../elements/table/datatable";
import { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Actionlist from "../../../elements/table/actionlist";
import { useDispatch, useSelector } from "react-redux";
import {
  school_dashboard_users_count_start,
  school_dashboard_users_start,
} from "../../../../store/actions/school.dashboard.users";
import UsersActionlist from "./usersactionlist";


const UsersTable = () => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth);
  const schoolDashboardUsers = useSelector(
    (state) => state.schoolDashboardUsers
  );
  const [showinglist, setShowinglist] = useState();
  let toggle_key = 1;
  const toggleActionList =(k)=>{
    if(toggle_key){
      setShowinglist(k);
      toggle_key = 0;
    }
    else{
      setShowinglist(-1);
      toggle_key = 1;
    }
    
  };

  const tableheader1 = [
    {
      id: 1,
      name: "Names",
      selector: (row) => row.names,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Class",
      selector: (row) => row.class,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "User type",
      selector: (row) => row.usertype,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "OTP",
      selector: (row) => row.otp,
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Action",
      selector: (row) => row.action,
      sortable: false,
      reorder: true,
    },
  ];
  let tablebody1 = [];
 

  useEffect(() => {
    dispatch(
      school_dashboard_users_count_start({
        token: userAuth.token,
        school_id: userAuth.userId,
      })
    );
    dispatch(
      school_dashboard_users_start({
        token: userAuth.token,
        school_id: userAuth.userId,
      })
    );
  }, [dispatch, userAuth.token, userAuth.userId]);


  schoolDashboardUsers?.school_dashboard_users?.map((value, key) => {
    tablebody1.push({
      names: value.names,
      class: value.class,
      usertype: value.usertype,
      status: value.status,
      otp: value.otp,
      action: (
        <UsersActionlist
          k={key}
          showinglist={showinglist}
          onClick={() => toggleActionList(key)}
          user_id={value.user_id}
          names= {value.names}
          row_id={value.uploadedid}
        />
      ),
    });
  });
  const memoizedData = useMemo(() => tablebody1, [tablebody1]);
  return (
    <div className="w-full min-h-full relative overflow-x-auto sm:rounded-lg">
      {tablebody1.length === 0 ? (
        <></>
      ) : (
        <>
          <Datatable columns={tableheader1} data={memoizedData}/>
        </>
      )}
    </div>
  );
};

export default UsersTable;
