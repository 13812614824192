export const PageTitle = function (props) {
  let classes =
    'block text-4xl lg:text-5xl break-words font-extrabold tracking-tight md:text-' +
    props.alignment +
    ' text-' +
    props.color +
    ' text-' +
    props.mobileAlignment;

  return <span className={classes}>{props.name}</span>;
};
export const PageBody = function (props) {
  let classes =
    'text-base mt-4 break-words md:text-' +
    props.alignment +
    ' text-' +
    props.color +
    ' text-' +
    props.mobileAlignment;

  return <p className={classes}>{props.name}</p>;
};

export const SectionTitle = function (props) {
  let classes =
    props.additional +
    ' block text-xl font-bold text-' +
    props.alignment +
    ' text-' +
    props.color;

  return <span className={classes}>{props.name}</span>;
};

export const CardTitle = function (props) {
  let classes =
    props.additional +
    ' truncate block text-md font-semibold text-' +
    props.alignment +
    ' text-' +
    props.color;

  return <span className={classes}>{props.name}</span>;
};

export const CardBodyWithExtras = function (props) {
  let classes = 'hidden md:block text-md text-' +
    props.alignment +
    ' text-' +
    props.color +
    ' ' +
    props.additional;

  return (
    <p onClick={props.onClick} className={classes}>
      {props.name}
    </p>
  );
};

export const CardBody = function (props) {
  let classes = props.additional+' block text-md text-' +
    props.alignment +
    ' text-' +
    props.color ;

  return (
    <p onClick={props.onClick} className={classes}>
      {props.name}
    </p>
  );
};

export const CardSubText = function (props) {
  let classes =
    'text-sm font-normal text-' +
    props.alignment +
    ' text-' +
    props.color +
    ' ' +
    props.additional;

  return <span className={classes}>{props.name}</span>;
};

export const SmallText = function (props) {
  let classes =
    'text-xs text-' +
    props.alignment +
    ' text-' +
    props.color +
    ' ' +
    props.additional;

  return <span onClick={props.onClick} className={classes}>{props.name}</span>;
};

export const FeatureTitle = (props) => {
  return (
    <div className="flex">
      <div className="grow-0 flex flex-col items-end">
        <SectionTitle name={props.name} color="blue" />
        <div className="mt-2 mb-5 grow-0 w-10 h-1 rounded-full bg-yellow"></div>
      </div>
    </div>
  );
};
