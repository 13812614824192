import { TestimonyCard } from "../../../elements/card";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Spinner from "../../../elements/spinner";

// install Swiper modules
SwiperCore.use([Navigation]);

const Testimony = (props) => {
  const { t } = useTranslation();
  const testimonies = useSelector((state) => state.home.testimonies);
  const testimoniesLoading = useSelector(
    (state) => state.home.testimoniesLoading
  );
  return (
    <div className="bg-yellow py-20">
      {testimoniesLoading ? (
        <div className="flex flex-row justify-center">
          <Spinner size="20" color="white" />
        </div>
      ) : (
        <Swiper
          spaceBetween={100}
          slidesPerView={1}
          navigation={true}
          className="md:bg-mascot bg-no-repeat bg-right bg-contain w-full flex max-w-7xl oneSwiper"
        >
          {testimonies?.map((testimony, key) => (
            <SwiperSlide className="py-10 md:py-30 px-8 md:px-20" key={key}>
              <TestimonyCard
                testimony={testimony.personsquote}
                person={testimony.personsname}
                position={testimony.personsworkplace}
                src={`${process.env.REACT_APP_BACKEND_URL}/opanda/${testimony.personsthumbnail}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default Testimony;
