import React, { useEffect, useState } from 'react';
import Input from '../../../elements/input';
import { Button } from '../../../elements/button';
import axios from "../../../../axios-base";
import { useSelector } from "react-redux";
import Spinner from '../../../elements/spinner';

const VideoPreviewComponent = (props) => {
    const [videoUrl, setVideoUrl] = useState('');
    const [thumbnailUrl, setThumbnailUrl] = useState('');
    const [videos, setVideos] = useState([]);
    const [error, setError] = useState('');
    const userAuth = useSelector((state) => state.auth);
    const [isSearchingSaveVideo, setIsSearchingSaveVideo] = useState(false);
    const token = userAuth.token;

    const addVideo = () => {
        // URL validation
        try {
            new URL(videoUrl);
        } catch (error) {
            setError('Please enter a valid video URL.');
            return;
        }

        try {
            new URL(thumbnailUrl);
        } catch (error) {
            setError('Please enter a valid thumbnail URL.');
            return;
        }

        setVideos((vids_) => {
            const vids = [...vids_];
            vids.push({ videoUrl: videoUrl, thumbnailUrl: thumbnailUrl });
            return [...vids];
        });

        setVideoUrl('');
        setThumbnailUrl('');
        setError(''); // Clear any previous error
    };

    const removeVideo = (index) => {
        const updatedVideos = [...videos];
        updatedVideos.splice(index, 1);
        setVideos(updatedVideos);
    };

    useEffect(() => {
        // Your effect code here
    }, [videos]);

    useEffect(() => {
        axios
            .post(
                `/opanda/summarynotes/cbcsummarynotesfindMyVideosOncourse`,
                {
                    classId: props.classId
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                }
            )
            .then((response) => {
                const vids = response.data.videos.split(',').map(item => {
                    const [videoUrl, thumbnailUrl] = item.split('-@#!!~%-');
                    return { videoUrl, thumbnailUrl };
                });
                setVideos(vids);
            })
            .catch((err) => {
            });

    }, []);

    const saveVideos = () => {
        if (videos.length === 0) {
            alert('Please add at least one video.');
        }
        setIsSearchingSaveVideo(true);
        const transformedData = videos.map(item => `${item.videoUrl}-@#!!~%-${item.thumbnailUrl}`);
        axios
            .post(
                `/opanda/summarynotes/cbcsummarynotesaddVideos`,
                {
                    videos: transformedData.join(','),
                    classId: props.classId
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                }
            )
            .then((response) => {
                setIsSearchingSaveVideo(false);
                axios
                    .post(
                        `/opanda/summarynotes/cbcsummarynotesfindMyVideosOncourse`,
                        {
                            classId: props.classId
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${token}`
                            }
                        }
                    )
                    .then((response) => {
                        const vids = response.data.videos.split(',').map(item => {
                            const [videoUrl, thumbnailUrl] = item.split('-@#!!~%-');
                            return { videoUrl, thumbnailUrl };
                        });
                        setVideos(vids);
                        props.closePopUpViewVideos();
                    })
                    .catch((err) => {
                    });
            })
            .catch((err) => {
                setIsSearchingSaveVideo(false);
            });
    }

    return (
        (!isSearchingSaveVideo) ? <div className='z-20' style={{ zIndex: 100000, overflow: "auto" }}>
            <Input
                label="Video URL"
                elementType="input"
                elementConfig={{
                    type: "text",
                    placeholder: "Video URL",
                }}
                value={videoUrl}
                changed={(value) => {
                    setVideoUrl(value);
                    setError('');
                }}
            />
            <Input
                label="Thumbnail URL"
                elementType="input"
                elementConfig={{
                    type: "text",
                    placeholder: "Thumbnail URL",
                }}
                value={thumbnailUrl}
                changed={(value) => {
                    setThumbnailUrl(value);
                    setError('');
                }}
            />

            {error && <p className="text-red-500" style={{ color: "red" }}>{error}</p>}
            <Button
                name="Add Video"
                outline="true"
                color="green"
                clicked={addVideo}
            />

            <div className="grid grid-cols-3 gap-4" style={{ display: 'flex', flexWrap: 'wrap', overflowY: "auto", height: 230 }}>
                {videos.map((video, index) => (
                    <div key={index} style={{ width: '180px', margin: '10px', border: "1px solid #cdcdcd", borderRadius: 5 }}>
                        <Button
                            name="Remove"
                            outline="true"
                            color="red"
                            clicked={() => removeVideo(index)}
                        />
                        <p className="text-blue-500" style={{ color: "blue" }}>Thumbnail Preview</p>
                        <img
                            src={video.thumbnailUrl}
                            alt="Thumbnail"
                            style={{ width: '30%', height: 'auto' }}
                        />
                        <p className="text-blue-500" style={{ color: "blue" }}>Video preview</p>
                        <iframe
                            title="Video"

                            src={video.videoUrl}
                            controls
                            style={{ width: '100%', height: 'auto' }}
                        ></iframe>
                    </div>
                ))}
            </div>

            <p className='bg-blue-100'>Total Videos: {videos.length}</p>
            <Button
                name="Save videos"
                outline="true"
                color="green"
                clicked={saveVideos}
            />
        </div> : <Spinner size="20" color="blue" />
    );
};

export default VideoPreviewComponent;
