import Modal from "../../../../../elements/modal/modal";
import { useSelector, useDispatch } from "react-redux";
import { changePaymentDoneModalState } from "../../../../../../store/actions/payment";

import { PaymentOptionsCard } from "../../../../../elements/card";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import PrevNext from "../../../../landing/enroll/PrevNext";

const PaymentOptions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const showPaymentDoneModal = useSelector(
    (state) => state.payment.showPaymentDoneModal
  );
  const chosenPackage = useSelector((state) => state.payment.chosenPackage);

  useEffect(() => {
    if (!chosenPackage) {
      history.push("/student/user/payment/extras/create");
    }
  }, [chosenPackage, history]);

  return (
    <div className="h-full bg-gray-200 pb-5 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
      <PrevNext
        px="5"
        previousLink="/student/user/payment/extras/create"
        previousName="Packages"
        pageName="Payment Options"
      />
      <PaymentOptionsCard _dashboard="true"/>
    </div>
  );
};

export default PaymentOptions;
