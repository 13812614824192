import { useState } from "react";
import Input from "../../../elements/input";
import { CardBody } from "../../../elements/text";
import { useHistory } from "react-router-dom";
import { Button } from "../../../elements/button";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { student_dashboard_homepage_tests_notifs_start, teacher_dashboard_homepage_tests_notifs_start } from "../../../../store/actions/student.dashboard.tests";
// import { getAllTests } from "../../../../functions/getAllTests";
import Spinner from "../../../elements/spinner";
import { student_dashboard_homepage_tests_subjects_units_start } from "../../../../store/actions/student.dashboard.tests.subjects.units";
import {
  subjectsFormatted,
  subjectsFormattedWithUnits_Multisel,
} from "../../../../functions/subjectsFormatted";

const AutoTestForm = () => {
  // const [showSubjects, setShowSubjects] = useState(false);
  const [level, setLevel] = useState("1");
  const [subject, setSubject] = useState("BIOLOGY");
  const [showState, setShowState] = useState(false);
  const [unit, setUnit] = useState("");
  const [difficulityLevel, setDifficulityLevel] = useState("");
  const dispatch = useDispatch();
  const userAth = useSelector((state) => state.auth);
  const history = useHistory();
  const studentDashboardTestsSubjectsUnits = useSelector(
    (state) => state.studentDashboardTestsSubjectsUnits
  );
  const [showSelectBox, setShowSelectBox] = useState(true);
  const myId = useSelector((state) => state.auth.user.id);
  useEffect(() => {
    //---Get all in app activities
    dispatch(
      teacher_dashboard_homepage_tests_notifs_start({
        academic_level: level,
        myId: myId
      })
    );
    //----Get Subjects and units--
    dispatch(
      student_dashboard_homepage_tests_subjects_units_start({
        academic_level: level,
      })
    );
    setLevel(level);
  }, []);
  //---
  const subjects_units =
    studentDashboardTestsSubjectsUnits.dashboard_homepage_tests_subjects_units;
  const getAllTheSubjectsFormatted = subjectsFormatted(subjects_units);
  const getAllTheSubjectsFormatted_withUnits_MUltisel =
    subjectsFormattedWithUnits_Multisel(subjects_units, subject);
    //console.log("fdfdfdf",...getAllTheSubjectsFormatted_withUnits_MUltisel);
  const getMultiselectedUnits = (data) => {
    const units_pulled = [];
    for (var n = 0; n < data.length; n++) {
      units_pulled.push(data[n].value);
    }
    setUnit(units_pulled.join(","));
  };
  
  return (
    <div
      className={` ${
        showSelectBox ? "h-myscreen" : ""
      } p-3 bg-gray-200 md:h-full col-span-4`}
    >
      <div className="md:hidden flex justify-between items-center">
        <CardBody name={`Senior 1`} color="blue" />
      </div>
      <div className={`${showSelectBox ? "block" : "hidden"} md:block`}>
        <CardBody
          name="Select a subject, academic level and unit to start your automated test"
          alignment="left"
        />
        <div className="flex flex-col md:flex-row md:justify-between md:items-center md:space-x-2">
          {getAllTheSubjectsFormatted.length > 0 &&
          !studentDashboardTestsSubjectsUnits.dashboard_homepage_tests_subjects_units_loading ? (
            <Input
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [...getAllTheSubjectsFormatted],
              }}
              value={subject}
              changed={setSubject}
              validation={{ required: true }}
              shouldValidate
              error="Subject is required"
            />
          ) : (
            <Spinner size="12" color="blue" />
          )}
          <Input
            elementType="select"
            elementConfig={{
              startingValue: "SELECT",
              options: [
                ...[
                  {
                    value: `${1}`,
                    displayValue: `Senior ${1}`,
                  },{
                    value: `${2}`,
                    displayValue: `Senior ${2}`,
                  },{
                    value: `${3}`,
                    displayValue: `Senior ${3}`,
                  },{
                    value: `${4}`,
                    displayValue: `Senior ${4}`,
                  },{
                    value: `${5}`,
                    displayValue: `Senior ${5}`,
                  },{
                    value: `${6}`,
                    displayValue: `Senior ${6}`,
                  },
                ],
              ],
            }}
            value={level}
            changed={setLevel}
            validation={{ required: true }}
            shouldValidate
            error="Level is required"
          />
        </div>
      </div>
      {!studentDashboardTestsSubjectsUnits.dashboard_homepage_tests_subjects_units_loading ? (
        <>
          <Input
            elementType="multipleselect"
            elementConfig={{
              defaultOptions: [],
              options: [...getAllTheSubjectsFormatted_withUnits_MUltisel],
            }}
            name="units"
            getMultiselectedUnits={getMultiselectedUnits}
            changed={setDifficulityLevel}
            validation={{ required: true }}
            shouldValidate
            error="Difficulity level is required"
          />
        </>
      ) : (
        <Spinner size="12" color="blue" />
      )}
      <Input
        elementType="select"
        elementConfig={{
          startingValue: "SELECT",
          options: [
            { value: "0", displayValue: "Beginner" },
            { value: "1", displayValue: "Intermediate" },
            { value: "2", displayValue: "Advanced" },
            { value: "3", displayValue: "Expert" },
          ],
        }}
        value={difficulityLevel}
        changed={setDifficulityLevel}
        validation={{ required: true }}
        shouldValidate
        error="Difficulity level is required"
      />
      <div className="flex justify-around py-5">
        <Button
          name="My Progress"
          outline="false"
          color="red"
          clicked={() => {
            history.push(`/teacher/features/testprogress/`);
          }}
        />
        <Button
          name="Start"
          outline="false"
          color="blue"
          clicked={() => {
            if (
              level !== "SELECT" &&
              level !== "" &&
              unit !== "SELECT" &&
              unit !== "" &&
              difficulityLevel !== "SELECT" &&
              difficulityLevel !== "" &&
              (difficulityLevel === "0" ||
                difficulityLevel === "1" ||
                difficulityLevel === "2" ||
                difficulityLevel === "3")
            ) {
              history.push(
                `/teacher/features/tests/${0}?scheduled=false&autotest=true&subject=${subject}&unit=${unit}&difficulty=${difficulityLevel}&level=${level}`
              );
            } else {
              alert(`Some fields are empty!?!`);
              setShowState(true);
            }
          }}
        />
      </div>
    </div>
  );
};

export default AutoTestForm;