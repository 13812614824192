import Payment from '../../../components/pages/teacher/user/payment';
import Layout from '../../../components/common/Layout';

const TeacherPaymentContainer = () => {
  return (
    <Layout>
      <Payment />
    </Layout>
  );
};
 
export default TeacherPaymentContainer;