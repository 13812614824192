import { BiChevronUp, BiChevronDown } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { CardTitle } from '../../text';

export function NavList({
  color="black",
  isActive,
  children,
  onClick,
  name,
  icon,
  subTabs,
  changeSelectedTab,
}) {
  const isSidebarCollapsed = useSelector(
    (state) => state.reducer.sideBarCollapse
  );
  // console.log(isActive, name);
  return (
    <li className="flex flex-col group w-full justify-between cursor-pointer items-center mb-4">
      <div
        className="w-full flex justify-between items-center" id={name}      
        onClick={onClick}
      >
        <div
          className={`flex items-center ${
            isSidebarCollapsed ? 'w-full flex-col justify-center' : 'space-x-2'
          }`}
        >
          <div className="w-8 lg:w-10 h-8 lg:h-10 rounded-full flex justify-center items-center bg-blue">
            {icon}
          </div>
          <CardTitle
            name={name}
            color={color}
            additional={`${isSidebarCollapsed ? 'text-xs' : 'text-base'}`}
          />
        </div>
        {subTabs.length === 0 ? null : isActive ? (
          <BiChevronUp
            className={`${isSidebarCollapsed ? 'hidden' : ''}`}
            size={30}
          />
        ) : (
          <BiChevronDown
            className={`${isSidebarCollapsed ? 'hidden' : ''}`}
            size={30}
          />
        )}
      </div>
      <ul className={`w-full mt-2 py-2 px-4 md:w-48 ${isSidebarCollapsed ? 'hidden' : ''}`}>
        {children}
      </ul>
    </li>
  );
}
// parent Navlis layout
export function ParentNavList({
  color='blue',
  isActive,
  children,
  onClick,
  name,
  icon,
  subTabs,
  changeSelectedTab,
}) {
  const isSidebarCollapsed = useSelector(
    (state) => state.reducer.sideBarCollapse
  );
  // console.log(isActive, name);
  return (
    <li className="flex flex-col group w-full justify-between cursor-pointer items-center mb-4">
      <div
        className="w-full flex justify-between items-center" id={name}      
        onClick={onClick}
      >
        <div
          className={`flex items-center ${
            isSidebarCollapsed ? 'w-full flex-col justify-center' : 'space-x-2'
          }`}
        >
          <div className="w-8 lg:w-10 h-8 lg:h-10 rounded-full flex justify-center items-center bg-white">
            {icon}
          </div>
          <CardTitle
            name={name}
            color={color}
            additional={`${isSidebarCollapsed ? 'text-xs' : 'text-base'}`}
          />
        </div>
        {subTabs.length === 0 ? null : isActive ? (
          <BiChevronUp
            className={`${isSidebarCollapsed ? 'hidden' : ''}`}
            size={30}
          />
        ) : (
          <BiChevronDown
            className={`${isSidebarCollapsed ? 'hidden' : ''}`}
            size={30}
          />
        )}
      </div>
      <ul className={`mt-2 py-2 md:w-48 ${isSidebarCollapsed ? 'hidden' : ''}`}>
        {children}
      </ul>
    </li>
  );
}
export function ParentPagesNavList({
  color='blue',
  isActive,
  children,
  onClick,
  name,
  icon,
  subTabs,
  changeSelectedTab,
}) {
  const isSidebarCollapsed = useSelector(
    (state) => state.reducer.sideBarCollapse
  );
  // console.log(isActive, name);
  return (
    <li className="flex flex-col group w-full justify-between cursor-pointer items-center mb-4">
      <div
        className="w-full flex justify-between items-center px-1" id={name}      
        onClick={onClick}
      >
        <div
          className={`flex items-center ${
            isSidebarCollapsed ? 'w-full flex-col justify-center' : 'space-x-2'
          }`}
        >
          <CardTitle
            name={name}
            color={color}
            additional={`${isSidebarCollapsed ? 'text-xs' : 'text-base'}`}
          />
        </div>
        {subTabs.length === 0 ? null : isActive ? (
          <BiChevronUp
            className={`${isSidebarCollapsed ? 'hidden' : ''}`}
            size={30}
          />
        ) : (
          <BiChevronDown
            className={`${isSidebarCollapsed ? 'hidden' : ''}`}
            size={30}
          />
        )}
      </div>
      <ul className={`mt-2 py-2 md:w-48 ${isSidebarCollapsed ? 'hidden' : ''}`}>
        {children}
      </ul>
    </li>
  );
}
