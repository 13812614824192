import Layout from '../../../components/common/Layout';
import MarkTest from '../../../components/pages/teacher/test/MarkTest';

const MarkTestContainer = () => {
  return (
    <Layout>
      <MarkTest />
    </Layout>
  );
};

export default MarkTestContainer;
