import Help from '../../../components/pages/school/user/help/index';
import Layout from '../../../components/common/Layout';

const SchoolHelpContainer = () => {
  return (
    <Layout>
      <Help />
    </Layout>
  );
};

export default SchoolHelpContainer;