import Header from "./header/header";
import Footer from "./footer";
const Layout = (props) => {
  return (
    <div className="h-screen bg-gray-100 3xl:px-48 3xl:py-6 flex flex-col justify-between">
      <Header />
      <div className="flex-1 h-[100vh]">{props.children}</div>
      <Footer/>
    </div>
  );
};
 
export default Layout;
