import Layout from '../../../components/common/Layout';
import NewSummaryNote from '../../../components/pages/teacher/summarynotes/NewSummaryNote';

const NewCourse = () => {
  return (
    <Layout>
      <NewSummaryNote />
    </Layout>
  );
};

export default NewCourse;
