import { updateObject } from "../../../../shared/utility";
import {
  GET_ONE_TEACHERS_PASTPAPERS_SUCCESS, GET_TEACHERS_PASTPAPERS_FAIL, GET_TEACHERS_PASTPAPERS_START, 
  GET_TEACHERS_PASTPAPERS_SUCCESS, ADD_QUESTION_TO_PAPER, SET_PAPER_QUESTIONS_INIT,
  SAVE_PAST_PAPER_AS_DRAFT,REMOVE_QUESTION_TO_PAPER,SET_QUESTIONS_FROM
} from "../../../actions/features/teacher/pastpapers";

const initialReducer = {
    pastpapers: [],
    pastpapersLoading: null,
    pastpapersError: null,
    pastpaper: null,
    questions: [],
  };
  
  const getTeachersPastpapersStart = (state, action) => {
    return updateObject(state, {
      pastpapers: [],
      pastpapersError: null,
      pastpapersLoading: true,
      pastpaper: null,
    });
  };
  
  const getTeachersPastpapersSuccess = (state, action) => {
    return updateObject(state, {
      pastpapers: action.payload,
      pastpapersError: null,
      pastpapersLoading: false,
      pastpaper: null,
    });
  };
  
  const getOneTeachersPastpapersSuccess = (state, action) => {
    return updateObject(state, {
      pastpapers: [],
      pastpapersError: null,
      pastpapersLoading: false,
      pastpaper: action.payload,
      questions:[],
    });
  };
  
  const getTeachersPastpapersFail = (state, action) => {
    return updateObject(state, {
      pastpapers: [],
      pastpapersError: action.error,
      pastpapersLoading: false,
      pastpaper: null,
    });
  };

  const addQuestionToPastPaper = (state, action) => {
    return updateObject(state, {
      questions: state.questions.concat(action.payload),
    });
  }; 

  const setQuestionsFromDB = (state, action) => {
    return updateObject(state, {
      questions: action.payload,
    });
  };

  const removeQuestionToPastPaper = (state, action) => {
    return updateObject(state, {
      questions: action.payload,
    });
  };
  
  const setQuestionsInit = (state, action)=>{
    return updateObject(state, {
      questions: [],
    });
  }

  const savePastPaperAsDraft = (state, action) => {
    return updateObject(state, {
      pastpapers: [],
      pastpapersLoading: null,
      pastpapersError: null,
      pastpaper: action.payload,
      questions:[],
    });
  }; 

  const reducer = (state = initialReducer, action) => {
    switch (action.type) {
      case GET_TEACHERS_PASTPAPERS_START:
        return getTeachersPastpapersStart(state, action);
      case GET_TEACHERS_PASTPAPERS_SUCCESS:
        return getTeachersPastpapersSuccess(state, action);
      case GET_ONE_TEACHERS_PASTPAPERS_SUCCESS:
        return getOneTeachersPastpapersSuccess(state, action);
      case GET_TEACHERS_PASTPAPERS_FAIL:
        return getTeachersPastpapersFail(state, action);
      case ADD_QUESTION_TO_PAPER:
        return addQuestionToPastPaper(state, action);
      case SET_PAPER_QUESTIONS_INIT:
        return setQuestionsInit(state, action);
      case SAVE_PAST_PAPER_AS_DRAFT:
        return savePastPaperAsDraft(state, action);
      case REMOVE_QUESTION_TO_PAPER:
        return removeQuestionToPastPaper(state, action);
        case SET_QUESTIONS_FROM:
        return setQuestionsFromDB(state,action);
      default:
        return state;
    }
  };
  
  export default reducer;
  