

export const newSubjects = (mapRes: any, mapComponents: any) => {

  const filteredSRecentSubjectSpecialization = [];

  for (var i = 0; i < mapRes.length; i++) {
    if (mapRes[i].length > 0) {
      if (mapRes[i][0].subject === "Agriculture") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[0].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }

      } else if (mapRes[i][0].subject === "ART AND CRAFTS") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[1].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "BIOLOGY") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[2].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "CHEMISTRY") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[3].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "COMPUTER SCIENCE") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[4].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "ECONOMICS") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[5].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "ELECTRICITY") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[6].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "ELECTRICITY FOT TVET") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[7].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "ELECTRONICS") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[8].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "ELECTRONICS FOR TVET") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[9].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "English") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[10].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "ENTREPRENEURSHIP") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[11].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "FINE ART AND CRAFTS") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[12].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "FRANÇAIS") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[13].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "GEOGRAPHY") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[14].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "Geography and Environment") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[15].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "HISTORY") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[16].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "Literature in English") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[17].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "MACHINE ASSEMBLY") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[18].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "MATHEMATICS") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[20].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "PHYSICS") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[21].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "RELIGION AND ETHICS") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[22].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      }

    }

  }

  return filteredSRecentSubjectSpecialization;

};
export const recommendedSubjects = (mapRes: any, mapComponents: any) => {

  const filteredSRecentSubjectSpecialization = [];

  for (var i = 0; i < mapRes.length; i++) {
    if (mapRes[i].length > 0) {
      if (mapRes[i][0].subject === "Agriculture") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[0].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }

      } else if (mapRes[i][0].subject === "ART AND CRAFTS") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[1].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "BIOLOGY") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[2].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "CHEMISTRY") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[3].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "COMPUTER SCIENCE") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[4].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "ECONOMICS") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[5].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "ELECTRICITY") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[6].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "ELECTRICITY FOT TVET") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[7].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "ELECTRONICS") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[8].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "ELECTRONICS FOR TVET") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[9].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "English") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[10].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "ENTREPRENEURSHIP") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[11].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "FINE ART AND CRAFTS") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[12].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "FRANÇAIS") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[13].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "GEOGRAPHY") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[14].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "Geography and Environment") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[15].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "HISTORY") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[16].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "Literature in English") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[17].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "MACHINE ASSEMBLY") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[18].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "MATHEMATICS") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[19].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "PHYSICS") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[20].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      } else if (mapRes[i][0].subject === "RELIGION AND ETHICS") {
        for (var n = 0; n < mapRes[i].length; n++) {
          filteredSRecentSubjectSpecialization.push({
            id: mapRes[i][n].id,
            subject: mapRes[i][0].subject,
            imgsrc: mapComponents[21].imgsrc,
            tag: "Notes",
            unit: mapRes[i][n].unit,
            created_at: mapRes[i][n].live_visibility_regdate,
          })
        }
      }

    }

  }

  return filteredSRecentSubjectSpecialization;

};

