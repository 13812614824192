import React from "react";
import { TextButton } from "../button";
import Input from "../input";
import { useEffect } from "react";
import Tbodyv1WrappedCheckBox from "./tbodyv1WrappedCheckBox";
const TableBody = (props) => {
  const [checked, setChecked] = React.useState(false);
  const [startoffset, setStartoffset] = React.useState(0);
  const [endoffset, setEndoffset] = React.useState(3);
  const handleChange = () => {
    setChecked(!checked);
  };
  const data = props.tbody;
  const data_ =
    data.length === 0 ? (
      <tr
        key={0}
        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
      >
        <td
          className="bg-gray-200 px-4 py-2 border border-gray-200 text-center text-black"
          width="10"
          colSpan={5}
        >
          No data to diplay now
        </td>
      </tr>
    ) : (
      data.slice(startoffset, endoffset).map((item, index) => (
        <tr
          key={index}
          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
        >
          <td
            className="bg-gray-200 px-4 py-2 border border-gray-200 text-center text-black"
            width="10"
          >
            {index + 1}
          </td>
          {Object.entries(item)?.map((value, key) => (
            <td
              className="px-4 py-2 border border-gray-200 text-left text-black truncate"
              key={key}
              width="20"
            >
              {value[1].type === "text" ? (
                value[1].data
              ) : value[1].type === "component" ? (
                value[1].data
              ) : (
                <Tbodyv1WrappedCheckBox thead={props.thead} data={value} />
              )}
            </td>
          ))}
        </tr>
      ))
    );

  const data__ = (
    <tr className="py-2 px-4 border border-gray-200">
      <td colSpan={`${props.colspan + 1}`}>
        <div className="flex w-full justify-end">
          {data.length === 0 ? (
            <></>
          ) : data.length !== data_.length ? (
            <TextButton
              color="blue"
              clicked={() => setEndoffset(endoffset + 5)}
              name="See more"
              additional="px-2 py-2 font-bold float-right"
            />
          ) : (
            ""
          )}
        </div>
      </td>
    </tr>
  );
  useEffect(() => {}, []);

  return (
    <>
      <tbody>
        {data_}
        {data__}
      </tbody>
    </>
  );
};

export default TableBody;
