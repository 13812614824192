import Layout from '../../../components/common/Layout';
import Book from '../../../components/pages/student/library/Book';

const BookContainer = (props) => {
  return (
    <Layout>
      <Book {...props} />
    </Layout>
  );
};

export default BookContainer;
