import {
    useState,
    useEffect,
    forwardRef,
    useRef,
    useImperativeHandle,
  } from "react";
  import { replaceReact } from "replace-react";
  import { FcAnswers } from "react-icons/fc";
  import parse from "react-html-parser";
  import "./test.css";
  import { DndProvider, useDrag, useDrop } from "react-dnd";
  import { HTML5Backend } from "react-dnd-html5-backend";
  import * as constants from "./constants";
  import * as qconstants from "./qconstants";
  import { hidesidebar } from "../store/actions/sidebaractiontypes";
  import ReactQuill from "react-quill";
  import "react-quill/dist/quill.snow.css";
  import { useDispatch, useSelector } from "react-redux";
  import * as actions from "../store/actions/";
  import { InputTextArea } from "./Algorithms";
  
  export const TeacherTestFillin = forwardRef((props, ref) => {
    return (
      <div className="px-8 py-5 flex flex-col">
        <h5>Teacher Creates Test</h5>
        <div className="flex space-x-4 pt-3">
          <a
            href="/teacher-test"
            className="bg-gray-500 rounded-md text-white text-center px-2"
          >
            Multiple Choice
          </a>
          <a
            href="/teacher-test-fillin"
            className="bg-gray-500 rounded-md text-white text-center px-2"
          >
            Fillin
          </a>
          <a
            href="/teacher-test-matching"
            className="bg-gray-500 rounded-md text-white text-center px-2"
          >
            Matching
          </a>
          <a
            href="/teacher-test-openended"
            className="bg-gray-500 rounded-md text-white text-center px-2"
          >
            Open Ended
          </a>
        </div>
        <div className="flex flex-col pt-8 space-y-3">
          <h4>Fillin Question</h4>
          <div className="w-3/5">
            <InputTextArea />
          </div>
        </div>
      </div>
    );
  });