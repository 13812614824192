import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import parse from 'react-html-parser';
import { CardBody } from '../../../../../../elements/text';
import { useDrag } from 'react-dnd';
const MatchingTypeAutoQuestionItem = (props) => {
    const { answers } = props;
    const dispatch = useDispatch();
    const [text, setText] = useState('');
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "autoDragAndDrop",
        item: { id: props.name },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    useEffect(() => {

    }, []);
   

    return (
        <span ref={drag}  className='cursor-default p-2 bg-blue text-center px-1 py-1 text-white m-1 text-sm space-y-1.5'>
            {props.item}
        </span>
    );
};
export default MatchingTypeAutoQuestionItem;
