// Page.js
import React, { useState, useEffect } from 'react';
import RichTextEditor from './RichTextEditor';
import PageNavigation from './PageNavigation';
import { Button } from '../../../elements/button';
import RadioOptions from './RadioOptions';
import axios from "../../../../axios-base";
import { useSelector } from "react-redux";
import Input from '../../../elements/input';
import Spinner from '../../../elements/spinner';
import { CardTitle } from '../../../elements/text';
import { replaceAllLInksWithRealSource } from '../../../../functions/commonfunctions';
import parse from 'react-html-parser';
import { convertBufferToString } from '../../../../functions/trendingSubjects';
const Page = (props) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [pageContent, setPageContent] = useState([{ question: "", answer: "" }]); // Initial content for one page
    const [selectedOption, setSelectedOption] = useState("unpublished");
    const user = useSelector((state) => state?.auth);
    const user_id = useSelector((state) => state?.auth?.user.id);
    const token = useSelector((state) => state?.auth?.token);
    const [pastpaper, setPastpaper] = useState([props.pastpaper]);
    const [loader, setLoader] = useState(false);
    const [pastpaperId, setPastpaperId] = useState(0);





    const handleOptionChange = (option) => {
        setSelectedOption(option);
        // Perform any other actions upon option change if needed
    };

    const handleContentChangeQuestion = (html) => {
        setPageContent((prevContent) => {
            const updatedContent = [...prevContent];
            updatedContent[currentPage].question = html;
            return updatedContent;
        });
    };

    const handleContentChangeAnswer = (html) => {
        setPageContent((prevContent) => {
            const updatedContent = [...prevContent];
            updatedContent[currentPage].answer = html;
            return updatedContent;
        });
    };

    const handleNext = () => {
        if (currentPage < pageContent.length - 1) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const handleSubmit = () => {
        // Logic for submitting all page contents
        console.log('All page contents submitted:', pageContent);

    };

    useEffect(() => {
        const subject = props?.summmarynotes?.chosenSubject;
        const unitname = props?.summmarynotes?.chosenUnit;
        const academic_level = props?.params?.academic_level;
    }, []);

    const getAllImages = (htmls) => {
        const regex = /<img[^>]+src="([^">]+)"/g;
        const images = [];
        let match;
        for (var i = 0; i < htmls.length; i++) {
            const html = htmls[i].question;
            while ((match = regex.exec(html))) {
                const imageUrl = match[1];
                if (imageUrl.startsWith('data:image')) {
                    images.push(imageUrl);
                }
            }
        }

        for (var i = 0; i < htmls.length; i++) {
            const html = htmls[i].answer;
            while ((match = regex.exec(html))) {
                const imageUrl = match[1];
                if (imageUrl.startsWith('data:image')) {
                    images.push(imageUrl);
                }
            }
        }
        return images;
    };



    // Function to convert base64 to Blob
    function base64ToBlob(base64Data) {
        const byteString = atob(base64Data.split(',')[1]);
        const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    }

    // Function to upload base64 images as JPGs with enumerated names
    async function uploadImagesWithNames(images) {
        const uploadPromises = images.map(async (base64Data, index) => {
            const blob = base64ToBlob(base64Data);

            // Create a unique filename with timestamp and index
            const timestamp = Date.now();
            const fileName = `${timestamp}-summary_note_${Date.now()}_${index}.jpg`;

            // Create a FormData object and append the blob
            const formData = new FormData();
            formData.append('image', blob, fileName);

            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/opanda/uploadImages`, {
                    method: 'POST',
                    body: formData
                    // Add headers if needed, like authorization or content-type
                });

                if (response.ok) {
                    // Once the image is uploaded, return an object with base64 data and filename
                    return { base64Data, fileName };
                } else {
                    throw new Error('Upload failed');
                }
            } catch (error) {
                console.error('Error:', error);
                return null;
            }
        });

        return Promise.all(uploadPromises);
    }
    const getDataFromBlob = (data) => {
        return parse(replaceAllLInksWithRealSource(data));
    };
    const uploadMyImages = async (images_data) => {
        // Call the function to upload images and save key-value pairs after upload
        setLoader(true);
        uploadImagesWithNames(images_data)
            .then(results => {
                const imageKeyValuePairs = {}; // Object to store image base64 and filenames

                results.forEach(result => {
                    // Process each result from the backend
                    if (result) {
                        imageKeyValuePairs[result.fileName] = [result.base64Data, result.fileName];

                    }
                });

                //--Replace all images in questions
                const pageContentPool = pageContent;
                for (var i = 0; i < pageContentPool.length; i++) {
                    let newPageContent = pageContentPool[i].question;
                    Object.keys(imageKeyValuePairs).forEach(key => {
                        newPageContent = newPageContent.replaceAll(imageKeyValuePairs[key][0], `${process.env.REACT_APP_BACKEND_URL}/simulation_thumbnails/${key}`);
                    });
                    pageContentPool[i].question = newPageContent;
                }
                const allPageContent = [...pageContentPool];
                // Replace all images in answers
                for (var i = 0; i < allPageContent.length; i++) {
                    let newPageContent = allPageContent[i].answer;
                    Object.keys(imageKeyValuePairs).forEach(key => {
                        newPageContent = newPageContent.replaceAll(imageKeyValuePairs[key][0], `${process.env.REACT_APP_BACKEND_URL}/simulation_thumbnails/${key}`);
                    });
                    allPageContent[i].answer = newPageContent;
                }
                setPageContent([...allPageContent]);


                axios
                    .put(
                        `/opanda/past-papers/saveOrUpdatePastPaper`,
                        {
                            user_id: user_id,
                            subject: pastpaper[0].subject,
                            academicYear: pastpaper[0].academicYear,
                            level: pastpaper[0].level,
                            name: pastpaper[0].name,
                            description: pastpaper[0].description,
                            questions: pageContent,
                            type: pastpaper[0].type,
                            pastpaperId: pastpaperId

                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${token}`
                            }
                        }
                    )
                    .then((response) => {
                        setLoader(false);
                        setLoader(true);
                        axios
                            .put(`/opanda/past-papers/findMyPastPaper`, {
                                user_id: user_id,
                                subject: pastpaper[0].subject,
                                academicYear: pastpaper[0].academicYear,
                                level: pastpaper[0].level,
                                name: pastpaper[0].name,
                                description: pastpaper[0].description,
                                questions: pageContent,
                                type: pastpaper[0].type,
                                pastpaperId: pastpaperId

                            },
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                        "Authorization": `Bearer ${token}`
                                    }
                                })
                            .then((response) => {
                                const dataPool = [];
                                for (var i = 0; i < response.data.results.length; i++) {
                                    const question = String.fromCharCode(...response.data.results[i].past_papers_questions_data.data);
                                    const answer = String.fromCharCode(...response.data.results[i].past_papers_questions_answer.data);
                                    dataPool.push({ question: question, answer: answer });

                                }
                                setPageContent([...dataPool]);
                                setPastpaperId(response.data.pastpaper_id);
                                setSelectedOption((response.data.pastpapers_published == 1) ? "unpublished" : (response.data.pastpapers_published == 0) ? "published" : "archived");
                                setLoader(false);
                                alert("Pastpaper created successfully");

                            })
                            .catch((err) => {
                                setLoader(false);
                            });

                    })
                    .catch((err) => {
                        setLoader(false);
                        console.log(err);
                    });



            })
            .catch(error => {
                console.error('Error:', error);
                return {};
            });

    }


    const deleteCurrentPage = (currentPageToDelete) => {
        if (window.confirm("Are you sure you want to delete this page?") && currentPageToDelete > 0) {
            setPageContent((prevContent) => {
                if (currentPageToDelete > 0) {
                    setCurrentPage((prevPage) => prevPage - 1);
                }
                const updatedContent = [...prevContent];
                updatedContent.splice(currentPageToDelete, 1);
                console.log(updatedContent, currentPageToDelete)

                return updatedContent;
            });
        }
    }

    const addNewPage = () => {
        setPageContent((prevContent) => {
            const updatedContent = [...prevContent, { question: "", answer: "" }];
            if (currentPage < updatedContent.length - 1) {
                setCurrentPage((prevPage) => prevPage + 1);
            }
            return updatedContent;
        });
    };
    function getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    useEffect(() => {
        setLoader(true);
        axios
            .put(`/opanda/past-papers/findMyPastPaper`, {
                user_id: user_id,
                subject: pastpaper[0].subject,
                academicYear: pastpaper[0].academicYear,
                level: pastpaper[0].level,
                name: pastpaper[0].name,
                description: pastpaper[0].description,
                questions: pageContent,
                type: pastpaper[0].type,
                pastpaperId: pastpaperId

            },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                })
            .then((response) => {
                const dataPool = [];
                for (var i = 0; i < response.data.results.length; i++) {
                    const question = String.fromCharCode(...response.data.results[i].past_papers_questions_data.data);
                    const answer = String.fromCharCode(...response.data.results[i].past_papers_questions_answer.data);
                    dataPool.push({ question: question, answer: answer });

                }
                setPageContent([...dataPool]);
                setPastpaperId(response.data.pastpaper_id);
                setSelectedOption((response.data.pastpapers_published == 1) ? "unpublished" : (response.data.pastpapers_published == 0) ? "published" : "archived");
                setLoader(false);

            })
            .catch((err) => {
                setLoader(false);
            });
    }, []);





    return (
        (!loader) ? <div className="container mx-auto" style={{ display: 'grid', gridTemplateColumns: '98% 1%', zIndex: 1 }}>

            <div>
                <CardTitle
                    name={`${pastpaper[0].subject} - ${pastpaper[0].level} - ${pastpaper[0].academicYear}`}
                    color="blue"
                    additional="font-bold text-center"
                />
                <div><PageNavigation pageCount={(pageContent.length === 0) ? 1 : pageContent.length} currentPage={currentPage} deleteCurrentPage={deleteCurrentPage} addNewPage={addNewPage} onNext={handleNext} onPrevious={handlePrevious} />
                    <CardTitle
                        name="Question"
                        color="blue"
                        additional="font-bold text-center"
                    />
                    {pageContent[currentPage]?.question !== undefined && <RichTextEditor
                        handleContentChange={handleContentChangeQuestion}
                        initialContent={pageContent[currentPage].question}
                        content={pageContent[currentPage].question}
                        key={1}
                    />}
                    <CardTitle
                        name="Answer"
                        color="blue"
                        additional="font-bold text-center"
                    />
                    <div style={{ marginLeft: "10%" }}>
                        {pageContent[currentPage]?.question !== undefined && <RichTextEditor
                            handleContentChange={handleContentChangeAnswer}
                            initialContent={pageContent[currentPage].answer}
                            content={pageContent[currentPage].answer}
                            key={1}
                        />}
                    </div>
                </div>
            </div>
            <div className="mt-10">

                <Button
                    name="Save/Update"
                    outline="false"
                    color="green"
                    isSmall={true}
                    isSquare
                    clicked={async () => {
                        //console.log(process.env.REACT_APP_BACKEND_URL);
                        await uploadMyImages(getAllImages(pageContent))
                    }}
                />

                <div className='m-3'><RadioOptions key={getRandomNumber(1, 100)} classId={pastpaperId} selectedOption={selectedOption} onOptionChange={handleOptionChange} />
                </div>
            </div>
        </div> : <Spinner size="32" color="blue" />

    );
};

export default Page;
