const TabSelector = (props) => {
  return (
    <button
      className={`group inline-flex space-x-1 items-center px-1 py-4 border-b-2 font-medium text-sm leading-5 cursor-pointer whitespace-nowrap ${
        props.isActive
          ? 'border-blue text-blue focus:outline-none focus:text-blue focus:border-blue'
          : 'border-transparent hover:text-blue hover:border-blue focus:text-blue focus:border-blue'
      }`}
      onClick={props.onClick}
    >
      <span>{props.title}</span>
      {props.new > 0 ? (
        <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none bg-red rounded-full">
          {props.new}
        </span>
      ) : null}
    </button>
  );
};

export default TabSelector;
