import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";

export const getUsersStart = () => {
  return {
    type: actionTypes.GET_USERS_START,
  };
};

export const getUsersSuccess = (users) => {
  return {
    type: actionTypes.GET_USERS_SUCCESS,
    users: users,
  };
};

export const getOneUsersSuccess = (user) => {
  return {
    type: actionTypes.GET_ONE_USERS_SUCCESS,
    user: user,
  };
};

export const getUsersFail = (error) => {
  return {
    type: actionTypes.GET_USERS_FAIL,
    error: error,
  };
};

export const getOneUsersbyIdStart = () => {
  return {
    type: actionTypes.GET_ONE_USERS_BY_ID_START,
  };
};

export const getOneUsersbyIdSuccess = (user) => {
  return {
    type: actionTypes.GET_ONE_USERS_BY_ID_SUCCESS,
    user: user,
  };
};

export const getOneUsersByIdFail = (error) => {
  return {
    type: actionTypes.GET_ONE_USERS_BY_ID_FAIL,
    error: error,
  };
};

export const completeprofileinformation = () => {
  return (dispatch) => {
    dispatch(getUsersStart());
    axios
      .get(`/opanda/panda-users`)
      .then((response) => {
        dispatch(getUsersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getUsersFail(err.message));
      });
  };
};

export const updateUserAccountInfo = (object) => {
  return (dispatch) => {
    dispatch(getUsersStart());
    axios
      .put(`/opanda/panda-users/update-user-account-info`, object)
      .then((response) => {
        dispatch(getUsersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getUsersFail(err.message));
      });
  };
};

export const getOneUsers = (username) => {
  return (dispatch) => {
    dispatch(getUsersStart());
    axios
      .get(`/opanda/panda-users/find-user/${username}`)
      .then((response) => {
        dispatch(getOneUsersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getUsersFail(err.message));
      });
  };
};

export const getOneUserbyId = (id) => {
  return (dispatch) => {
    dispatch(getOneUsersbyIdStart());
    axios
      .get(`/opanda/panda-users/find-user-id/${id}`)
      .then((response) => {
        dispatch(getOneUsersbyIdSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getOneUsersByIdFail(err.message));
      });
  };
};

export const getOneUsersByOtp = (otp) => {
  return (dispatch) => {
    dispatch(getUsersStart());
    axios
      .get(`/opanda/panda-users/find-user-otp/${otp}`)
      .then((response) => {
        dispatch(getOneUsersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getUsersFail(err.message));
      });
  };
};

export const createAccount = (object) => {
  return (dispatch) => {
    dispatch(getUsersStart());
    axios
      .post(`/opanda/panda-users/create-account`, object)
      .then((response) => {
        dispatch(getOneUsersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getUsersFail(err.message));
      });
  };
};
