import Layout from '../../../components/common/Layout';
import NewBook from '../../../components/pages/teacher/library/NewBook';

const NewBookContainer = (props) => {
  return (
    <Layout>
      <NewBook {...props} />
    </Layout>
  );
};

export default NewBookContainer;
