import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";

export const getStudentsAnsweringMapsStart = () => {
  return {
    type: actionTypes.GET_PANDA_STUDENTS_ANSWERING_MAPS_START,
  };
};

export const getStudentsAnsweringMapsSuccess = (answeringmaps) => {
  return {
    type: actionTypes.GET_PANDA_STUDENTS_ANSWERING_MAPS_SUCCESS,
    answeringmaps: answeringmaps,
  };
};

export const getOneStudentsAnsweringMapsSuccess = (answeringmap) => {
  return {
    type: actionTypes.GET_ONE_PANDA_STUDENTS_ANSWERING_MAPS_SUCCESS,
    answeringmap: answeringmap,
  };
};

export const getStudentsAnsweringMapsFail = (error) => {
  return {
    type: actionTypes.GET_PANDA_STUDENTS_ANSWERING_MAPS_FAIL,
    error: error,
  };
};

export const getStudentsAnsweringMaps = () => {
  return (dispatch) => {
    dispatch(getStudentsAnsweringMapsStart());
    axios
      .get(`/opanda/panda-students-answering-maps`)
      .then((response) => {
        dispatch(getStudentsAnsweringMapsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getStudentsAnsweringMapsFail(err.message));
      });
  };
};

export const createStudentsAnsweringMaps = (object) => {
  return (dispatch) => {
    dispatch(getStudentsAnsweringMapsStart());
    axios
      .post(`/opanda/panda-students-answering-maps/submit-test-map`, object)
      .then((response) => {
        dispatch(getOneStudentsAnsweringMapsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getStudentsAnsweringMapsFail(err.message));
      });
  };
};
