import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import PageContent from "../../../pageContent";
import {
  Button,
  ButtonLink,
  GoBackButton,
} from "../../../../../elements/button";
import Spinner from "../../../../../elements/spinner";
import Notification from "../../../../../elements/notification/notification";
import Table from "../../../../../elements/table/table";
import Accordion from "../../../../../elements/accordion";
import Layout from "../../../../../common/Layout";

const CreateForm = (props) => {
  const history = useHistory();
  const loading = useSelector((state) => state.programs.programsLoading);

  const tableheader1 = ["", "Goal"];
  const tablebody1 = [
    {
      td1: { data: true, type: "checkbox" },
      td2: {
        data: (
          <Accordion
            title="Pass this class above 65% average Marks"
            content={[
              "1. Do at least one unit quiz for every student",
              "2. Do at least one unit quiz for every student",
              "3. Do at least one unit quiz for every student",
            ]}
          />
        ),
        type: "component",
      },
    },
    {
      td1: { data: false, type: "checkbox" },
      td2: {
        data: (
          <Accordion
            title="Pass this class above 65% average Marks"
            content="1. Do at least one unit quiz for every student"
          />
        ),
        type: "component",
      },
    },
    {
      td1: { data: false, type: "checkbox" },
      td2: {
        data: (
          <Accordion
            title="Pass this class above 65% average Marks"
            content="1. Do at least one unit quiz for every student"
          />
        ),
        type: "component",
      },
    },
  ];

  return (
    <Layout>
      <PageContent>
        <div
          className={`relative w-full p-3 col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
        >
          <GoBackButton
            link="/school/features/subjects"
            action="Add goals"
            location="Subject"
          />
          {loading ? (
            <Spinner size="32" color="blue" />
          ) : (
            <div className="bg-white drop-shadow-md mt-5 p-2 md:px-10 md:h-tab-screen md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
              <div className="relative overflow-x-auto sm:rounded-lg">
                <Table thead={tableheader1} tbody={tablebody1} />
              </div>
              <div className="flex space-x-2 justify-center py-5">
                <ButtonLink
                  name="Cancel"
                  outline="true"
                  color="blue"
                  page="/school/features/subjects"
                />
                <Button
                  name="Finish"
                  outline="false"
                  color="blue"
                  clicked={() => history.push("/school/features/subjects")}
                />
              </div>
            </div>
          )}
        </div>
        <div className="w-35% mt-5">
          <Notification auth />
        </div>
      </PageContent>
    </Layout>
  );
};

export default CreateForm;
