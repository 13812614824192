
import { TabPanel, useTabs } from 'react-headless-tabs';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef } from "react";
import { MdMenu } from 'react-icons/md';
import { IoSettingsOutline } from 'react-icons/io5';
import { useHistory } from "react-router-dom";
import parse from 'react-html-parser';
import { propTypes } from 'react-bootstrap/esm/Image';
import { CardBody } from '../../../../elements/text';
import { Button } from '../../../../elements/button';
import Spinner from '../../../../elements/spinner';
import axios from "../../../../../axios-base";
import NotFound from '../../../../common/features/NotFound';
const GroupParticipants = (props) => {
    const [memberShipReviewed, setMemberShipReviewed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [participantStatusUpdated, setParticipantStatusUpdated] = useState(false);
    const userAuth = useSelector((state) => state.auth);

    useEffect(() => {
        //--Bring all people who requested to get into my group  --props.group_id



    }, []);
    const customClassFlex = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: 100
    };
    const customClassFlexItem = {
        width: "30%",
        height: 50,
        backgroundColor: "lightblue",
        textAlign: "center",
        lineHeight: 50
    };
    return (<div key={props.group_id}  >
        {(!props.RequestsLoading) ? (props.GroupParticipantsPool.length > 0) ? props.GroupParticipantsPool?.map((item, index) => {
            return <div key={props.group_id} className="flex  justify-left  w-full my-5 filter" >
                <div className="flex flex-col w-60">
                    <CardBody
                        name={item.name}
                        color="blue"
                        additional="font-bold"
                    />
                    <CardBody
                        name={(item.school) ? item.school : "---"}
                        color="gray-500"
                        additional="font-bold"
                    />
                </div>
                <div className="flex divide-x divide-gray-500">
                    {(props.groupCreator === userAuth.userId && item.user_id !== userAuth.userId) ? (!loading && props.groupCreator === userAuth.userId && item.user_id !== userAuth.userId) ? <>
                        {(!participantStatusUpdated) ? <Button
                            isSmall={true}
                            name="Remove"
                            outline="true"
                            color="red"
                            clicked={() => {
                                setLoading(true)
                                if(!window.confirm("Are you sure, you want to remove this user from this group?")){
                                    return;
                                }
                                axios
                                    .post(
                                        `/opanda/community/get_n_community_revoke_group_membership`,
                                        {
                                            academic_level: userAuth.academic_level,
                                            user_id: item.user_id,
                                            group_id: props.group_id,
                                            decision: true
                                        },
                                        {
                                            headers: {
                                                "Content-Type": "application/json",
                                                "Authorization": `Bearer ${userAuth.token}`
                                            }
                                        }
                                    )
                                    .then((response) => {
                                        setParticipantStatusUpdated(true)
                                        setLoading(false)

                                    })
                                    .catch((err) => {
                                        setLoading(false)
                                    });

                            }}
                        /> : <span style={{ color: "green", fontWeight: "bold" }}>Membership revoked</span>}</> : <Spinner size="20" color="blue" /> : ""}
                </div></div>
        }) : <NotFound feature={` new Requests`} /> : <Spinner size="20" color="blue" />}</div>
    );
};

export default GroupParticipants;
