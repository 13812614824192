import Layout from '../../../../common/Layout';
import CreateForm from './create';

const CreateClassesContainer = () => {
  return (
    <Layout>
      <CreateForm />
    </Layout>
  );
};

export default CreateClassesContainer;
