import { MdDownload } from "react-icons/md";

const Attachment = (props) => {
  return (
    <div className="m-1 rounded-lg relative w-40 h-20">
      <img
        className="rounded-lg w-full h-full object-cover object-center"
        src={props.thumbnail}
        alt=""
      />
      <MdDownload
        size={20}
        color="#fff"
        className="border border-white rounded absolute bottom-2 left-2 cursor-pointer z-50"
      />
    </div>
  );
};

export default Attachment;
