import { updateObject } from "../../utils/updateObject";

import * as actionsTypes from "../actions/actionTypes";

const initialReducer = {
  users: [],
  usersError: null,
  usersLoading: false,
  user: null,
};

const getUsersStart = (state, action) => {
  return updateObject(state, {
    users: [],
    usersError: null,
    usersLoading: true,
    user: null,
  });
};

const getOneUsersbyIdStart = (state, action) => {
  return updateObject(state, {
    usersLoading: true
  });
};
const getOneUsersByIdSuccess = (state, action) => {
  return updateObject(state, {
    usersLoading: false,
    user: action.user,
  });
};

const getOneUsersByIdFail = (state, action) => {
  return updateObject(state, {
    usersError: action.error,
    usersLoading: false,
   
  });
};

const getUsersSuccess = (state, action) => {
  return updateObject(state, {
    users: action.users,
    usersError: null,
    usersLoading: false,
    user: null,
  });
};

const getUsersFail = (state, action) => {
  return updateObject(state, {
    users: [],
    usersError: action.error,
    usersLoading: false,
    user: null,
  });
};


const getOneUsersSuccess = (state, action) => {
  return updateObject(state, {
    users: [],
    usersError: null,
    usersLoading: false,
    user: action.user,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case actionsTypes.GET_USERS_START:
      return getUsersStart(state, action);
    case actionsTypes.GET_USERS_SUCCESS:
      return getUsersSuccess(state, action);
    case actionsTypes.GET_ONE_USERS_SUCCESS:
      return getOneUsersSuccess(state, action);
    case actionsTypes.GET_USERS_FAIL:
      return getUsersFail(state, action);
      case actionsTypes.GET_ONE_USERS_BY_ID_START:
      return getOneUsersbyIdStart(state, action);
      case actionsTypes.GET_ONE_USERS_BY_ID_SUCCESS:
      return getOneUsersByIdSuccess(state, action);
      case actionsTypes.GET_ONE_USERS_BY_ID_FAIL:
      return getOneUsersByIdFail(state, action);
    default:
      return state;
  }
};
export default reducer;
