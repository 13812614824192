import { useState } from "react";
import { useEffect } from "react";
import { SectionTitle, CardBody, SmallText } from "../../../elements/text";
import Input from "../../../elements/input";
import { Button } from "../../../elements/button";
import { OtherLinkButton } from "../../../elements/button";
import { useHistory } from "react-router-dom";
import student from "../../../../images/signup.png";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions/";
import constants from "../../../../constants/constants";

const Resetpasswordform = () => {
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [initLoad, setInitLoad] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.user);

  const passworReset = () => {
    if (password === "" && password.length <= 5) {
      alert("Password can't be empty!!?");
      return;
    }
    const object = { password, username: auth?.username };
    dispatch(actions.resetpassword(object));

  };
  const user = useSelector((state) => state.auth.user);
  const passwordReset = useSelector((state) => state.auth.passwordReset);


  useEffect(() => {
    dispatch(actions.getRecoveryQuestions());
    //--
    setInterval(() => {
      if (user !== null) {
        if (passwordReset === true) {
          if (user?.user_type === constants.studentusertype) {
            if (user?.isprofilecompleted === 1) {
              history.push("/student");
            } else {
              history.push("/complete");
            }
          } else if (user?.user_type === constants.teacherusertype) {
            if (user?.isprofilecompleted === 1) {
              //history.push("/teacher");
              history.push("/");
            } else {
              history.push("/teacher/user/profile");
            }
          } else if (user?.user_type === constants.schoolusertype) {
            if (user?.isprofilecompleted === 1) {
              history.push("/school");
            } else {
              history.push("/school/complete/profile");
            }
          } else if (user?.user_type === constants.parentusertype) {
            if (user?.isprofilecompleted === 1) {
              history.push("/parent");
            } else {
              history.push("/parent/complete/profile");
            }
          }
        }
      } else {
        // history.push("/");
      }
    }, 3000);

  }, [user]);

  return (
    <div>
      <div className="hidden md:block text-center w-30% m-auto">
        <SectionTitle name="Reset Password" color="blue" />
        <CardBody
          name="Create a new password that you will remember."
          color="blue"
        />
      </div>
      <div className="flex md:h-60vh justify-center items-center md:space-x-10 md:m-20 xl:m-40">
        <div className="md:w-50% md:h-full hidden md:block">
          <img
            className="w-full h-full object-cover rounded-3xl"
            src={student}
            alt=""
          />
        </div>
        <div className="md:w-50% md:h-full md:flex flex-col justify-around bg-gray-200 rounded-3xl text-center m-4 md:m-auto p-4">
          <div className="md:hidden">
            <SectionTitle name="Recover Account" color="blue" />
            <CardBody
              name="Create a new password that you will remember."
              color=""
            />
          </div>
          <div className="py-3">
            <Input
              elementType="input"
              withIcon
              elementConfig={{
                type: isShowPassword ? "text" : "password",
                placeholder: "Create password",
              }}
              value={password}
              changed={setPassword}
              validation={{ required: true, minLength: 5 }}
              shouldValidate
              error="Password must be at least 5 characters"
            >
              {!isShowPassword ? (
                <BsEye
                  className="cursor-pointer"
                  onClick={() => setIsShowPassword(!isShowPassword)}
                />
              ) : (
                <BsEyeSlash
                  className="cursor-pointer"
                  onClick={() => setIsShowPassword(!isShowPassword)}
                />
              )}
            </Input>
            <Input
              elementType="input"
              withIcon
              elementConfig={{
                type: isShowConfirmPassword ? "text" : "password",
                placeholder: "Confirm password",
              }}
              value={confirmPassword}
              changed={setConfirmPassword}
              validation={{
                required: true,
                equal: true,
                equivalence: password,
              }}
              shouldValidate
              error="Password do not match"
            >
              {!isShowConfirmPassword ? (
                <BsEye
                  className="cursor-pointer"
                  onClick={() =>
                    setIsShowConfirmPassword(!isShowConfirmPassword)
                  }
                />
              ) : (
                <BsEyeSlash
                  className="cursor-pointer"
                  onClick={() =>
                    setIsShowConfirmPassword(!isShowConfirmPassword)
                  }
                />
              )}
            </Input>
          </div>
          <div className="py-3">
            <Button
              name="Save Password"
              outline="true"
              color="blue"
              clicked={passworReset}
            />
          </div>
          {/* <div className="flex justify-center py-3">
            <SmallText
              name="This method not working for you?&nbsp;"
              color="black"
              alignment="center"
            />
            <OtherLinkButton
              color="blue"
              to="/forget"
              name="Try another method."
              size="xs"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Resetpasswordform;
