import { Books } from "./books";
import { CBC } from "./cbc";
import { DAP } from "./dap";
import SideBar from "./sidebar";
import TopNavBar from "./topnavbar";

import ContentModal from "../../../elements/modal/contentmodal";
import { connect, useSelector } from "react-redux";
import { TabPanel } from "../../../elements/tab/tabpanel";
import { store } from "../../../../store/store";
import { useState } from "react";

const PageContent = (props) => {
  const { tabselected } = props;
  const [index, setindex] = useState(0);
  
  return (
    <div className="bg-white h-full w-full grid grid-cols-3 md:grid-cols-4">
      <ContentModal />
      <SideBar setindex={setindex} />
      <TopNavBar />
      <div className="bg-white col-span-3 md:col-span-3 pl-6 pr-6 md:pr-0">
        <TabPanel panel="cbc" tabselected={tabselected}>
          <CBC  index={index} />
        </TabPanel>
        <TabPanel panel="dap" tabselected={tabselected}>
          <DAP  index={index} />
        </TabPanel>
        <TabPanel panel="books" tabselected={tabselected}>
          <Books   index={index} />
        </TabPanel>
      </div>
    </div>
  );
};

const mapStateToProps = () => {
  return {
    visibility: store.getState().reducer.mobilesidebar,
    tabselected: store.getState().reducer.tabselected,
  };
};
const mapDispatchToProps = () => {
  return {
    changetab: (tab) => store.dispatch({ type: "CHANGE_TAB", payload: tab }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PageContent);
