import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import parse from 'react-html-parser';
const SinglechoiceAnswerOptionGroupAutoTest = (props) => {
    const { answers } = props;
    const dispatch = useDispatch();
    const [text, setText] = useState('');
    const [isAnswer, setIsAnswer] = useState([]);
    const [UpdateUI, setUpdateUI] = useState(false);

    useEffect(() => {
    }, [props.singleChoicePicks]);

    const pickAnswer = (val, qnber, questionId, event) => {
        props.markSelectedMultipleChoiceQuestion(qnber, val, "single", questionId);
        setUpdateUI(!UpdateUI);

    }

    const renderCheckedStatus = (questionNber, answerId, collectedAnswers) => {
        for (var i = 0; i < collectedAnswers?.length; i++) {
            if ((parseInt(collectedAnswers[i].question_number) === parseInt(questionNber + "")) && (parseInt(collectedAnswers[i].answer_number) === parseInt(answerId + ""))) {
                return true;
            }
        }
        return false;
    }
    const find_me_in_user_answers = (answers_by_user, answerId, check, questionId) => {

        for (var i = 0; i < answers_by_user.length; i++) {
            if (parseInt(answers_by_user[i].answerId) === (answerId) && check && parseInt(questionId) === parseInt(answers_by_user[i].questionId)) {
                return <span className="text-green" style={{ color: "green", fontSize: 10 }}>You chose this answer!!!! Hooray!!</span>
            } else if (parseInt(answers_by_user[i].answerId) === (answerId) && parseInt(questionId) === parseInt(answers_by_user[i].questionId)) {
                return <span className="text-red-600" style={{ color: "red", fontSize: 10 }}>You chose this answer!!!! Sorry!!</span>
            }
        }
        return "";
    };
    return (
        <div><form>
            <fieldset>
                {answers.map((item, i) => {
                    return <div>
                        <table>
                            <tr>
                                <td><input checked={(item.panda_auto_questions_answers_isanswer === 1) ? true : false} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' type="radio" name={`answer--${item.panda_tests_questions_answers_question_id}`} value={`${item.panda_auto_questions_answers_id}`} />

                                </td>
                                <td>{parse(item.panda_auto_questions_answers_ans_data)}</td>
                                <td>{find_me_in_user_answers(props.user_data, item.panda_auto_questions_answers_id, (item.panda_auto_questions_answers_isanswer === 1) ? true : false, item.panda_auto_questions_answers_question_id)}</td>
                            </tr>
                        </table>
                    </div>
                })}</fieldset>
        </form>
        </div>
    );
};
export default SinglechoiceAnswerOptionGroupAutoTest;
