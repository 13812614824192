import { updateObject } from "../../shared/utility";
import {
  STUDENT_DASHBOARD_HOMEPAGE_DATACOLLECTION_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_DATACOLLECTION_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_DATACOLLECTION_FAILED,
  
  
} from "../actions/student.dashboard.datacollection";
const initialReducer = {
  datacollection: [],
  datacollection_loading: true,
  message: "",
};

function student_datacollection_init(state, action) {
  return updateObject(state, {
    datacollection_loading: true,
  });
}
function student_datacollection_success(state, action) {
  return updateObject(state, {
    datacollection: action.payload,
    datacollection_loading: false,
    message: "success"
  });
}
function student_datacollection_fail(state, action) {
  return updateObject(state, {
    message: action.payload,
    datacollection_loading: false,
  });
}





const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case STUDENT_DASHBOARD_HOMEPAGE_DATACOLLECTION_INIT:
      return student_datacollection_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_DATACOLLECTION_SUCCESS:
      return student_datacollection_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_DATACOLLECTION_FAILED:
      return student_datacollection_fail(state, action);  
    default:
      return state;
  }
};
export default reducer;
