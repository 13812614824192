
const colorMixtures = [
  { "color": "red", "hexColor": "#BE1E2D" },
  { "color": "blue", "hexColor": "#183F71" },
  { "color": "yellow", "hexColor": "#E8AD26" },
  { "color": "black", "hexColor": "#000000" },
  { "color": "purple", "hexColor": "#800080" },
  { "color": "aliceblue", "hexColor": "#F0F8FF" }
];
export const statsOfLastWeek = (mapRes: any) => {

  const structure_sample = [{ "name": "Simulations", "value": 3, "color": "red", "hexColor": "#BE1E2D" },
  { "name": "Courses", "value": 2, "color": "blue", "hexColor": "#183F71" },
  { "name": "Test", "value": 5, "color": "yellow", "hexColor": "#E8AD26" }];
  //----
  const CleanStats = [];
  try {
    if (mapRes.grouped_activities.length > 0) {
      for (var i = 0; i < mapRes.grouped_activities.length; i++) {
        if (i < 4) {
          CleanStats.push({
            "name": mapRes.grouped_activities[i].item_name,
            "value": mapRes.grouped_activities[i].count,
            "color": colorMixtures[i].color,
            "hexColor": colorMixtures[i].hexColor
          });
        }
      }
    }
  } catch (err) {

  }


  return CleanStats;

};


