import { useState } from 'react';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import { Button } from '../../../elements/button';
import Input from '../../../elements/input';
import { CardBody, CardSubText } from '../../../elements/text';
import parse from 'react-html-parser';

const MarkQuestion = (props) => {
  const [showQuestion, setShowQuestion] = useState(false);
  const [marks, setMarks] = useState(0);

  return (
    <div className="p-5">
      <CardBody
        name={`Question ${props.questionNumber} - ${props.type}`}
        additional="font-semibold"
      />
      <div className="border-b border-gray-500 pb-2">
        <div className="flex justify-between items-center">
          <CardBody name="Question data" />
          {showQuestion ? (
            <BsFillEyeSlashFill
              className="cursor-pointer"
              onClick={() => setShowQuestion((currentState) => !currentState)}
            />
          ) : (
            <BsFillEyeFill
              className="cursor-pointer"
              onClick={() => setShowQuestion((currentState) => !currentState)}
            />
          )}
        </div>
        {showQuestion ? <>{parse(props.question)}</> : null}
      </div>
      <div>
        <CardBody
          name={
            props.type === 'Multiple questions' ? 'Answer options' : 'Answer'
          }
        />
        {props.type === 'Multiple question' && (
          <ul className="py-2">
            {props.options.map((option, index) => (
              <li className="flex space-x-2 items-center">
                <input
                  type="radio"
                  name="option"
                  disabled={true}
                  value={option.value}
                  checked={option.isSelected}
                />
                <CardSubText name={option.value} />
              </li>
            ))}
          </ul>
        )}
        {(props.type === 'Drag and drop' ||
          props.type === 'Complete sentence' ||
          props.type === 'Open ended') && (
          <div className="rounded bg-white border border-gray-500 p-2">
            {parse(props.answer)}
          </div>
        )}
      </div>
      <div className="w-48 flex space-x-2 items-end">
        <Input
          withIcon
          elementType="input"
          elementConfig={{
            type: 'text',
          }}
          value={marks}
          changed={setMarks}
          validation={{ required: true, minimum: 0, maximum: props.marks }}
          shouldValidate
          error={`maximum 2`}
        >
          <CardBody name={`/${props.marks}`} color="gray-500" />
        </Input>
        <Button name="Save" outline="true" color="blue" clicked={() => {}} />
      </div>
    </div>
  );
};

export default MarkQuestion;
