export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_SELECTED_COMMENT_TEXT = 'CHANGE_SELECTED_COMMENT_TEXT';

export const changeLanguage = (lang) => {
  return {
    type: CHANGE_LANGUAGE,
    language: lang,
  };
};

export const changeSelectedCommentText = (text) => {
  return {
    type: CHANGE_SELECTED_COMMENT_TEXT,
    payload: text,
  };
};
