import { updateObject } from "../../shared/utility";
import {
  STUDENT_DASHBOARD_HOMEPAGE_TESTS_SUBJECTS_UNITS_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_TESTS_SUBJECTS_UNITS_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_TESTS_SUBJECTS_UNITS_FAILED,
  //----Get auto test
  STUDENT_DASHBOARD_HOMEPAGE_TEACHER_MADE_TEST_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_TEACHER_MADE_TEST_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_TEACHER_MADE_TEST_FAILED,
  //----Get teacher based tests
  STUDENT_DASHBOARD_HOMEPAGE_AUTO_TEST_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_AUTO_TEST_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_AUTO_TEST_FAILED,
  //----Save teacher made test
  STUDENT_DASHBOARD_HOMEPAGE_SAVE_TEACHER_MADE_TEST_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_SAVE_TEACHER_MADE_TEST_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_SAVE_TEACHER_MADE_TEST_FAILED,
  //----Save auto made test
  STUDENT_DASHBOARD_HOMEPAGE_SAVE_AUTO_MADE_TEST_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_SAVE_AUTO_MADE_TEST_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_SAVE_AUTO_MADE_TEST_FAILED,
  //----Get auto made test results and answers
  STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_AUTO_MADE_TEST_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_AUTO_MADE_TEST_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_AUTO_MADE_TEST_FAILED,
  //----Get teacher made test results and answers
  STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_TEACHER_MADE_TEST_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_TEACHER_MADE_TEST_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_TEACHER_MADE_TEST_FAILED,
  //----Get teacher made test answer sheet-----------
  STUDENT_DASHBOARD_HOMEPAGE_GET_TEACHER_MADE_TEST_ANSWER_SHEET_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_GET_TEACHER_MADE_TEST_ANSWER_SHEET_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_GET_TEACHER_MADE_TEST_ANSWER_SHEET_FAILED,
  //----Get auto made test answer sheet-----------
  STUDENT_DASHBOARD_HOMEPAGE_GET_AUTO_TEST_ANSWER_SHEET_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_GET_AUTO_TEST_ANSWER_SHEET_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_GET_AUTO_TEST_ANSWER_SHEET_FAILED,
  //-----------create a challenge from existing teacher made test
  STUDENT_DASHBOARD_CREATE_TEACHER_MADE_CHALLENGE_INIT,
  STUDENT_DASHBOARD_CREATE_TEACHER_MADE_CHALLENGE_SUCCESS,
  STUDENT_DASHBOARD_CREATE_TEACHER_MADE_CHALLENGE_FAILED,
  //----create a challenge from existing auto made test-----------
  STUDENT_DASHBOARD_CREATE_AUTO_MADE_CHALLENGE_INIT,
  STUDENT_DASHBOARD_CREATE_AUTO_MADE_CHALLENGE_SUCCESS,
  STUDENT_DASHBOARD_CREATE_AUTO_MADE_CHALLENGE_FAILED,
  //----challenge stats-----------
  STUDENT_DASHBOARD_CHALLENGES_STATS_INIT,
  STUDENT_DASHBOARD_CHALLENGES_STATS_SUCCESS,
  STUDENT_DASHBOARD_CHALLENGES_STATS_FAILED,
  //--Tests and assessments progress--
  STUDENT_DASHBOARD_ASSESSMENTS_STATS_INIT,
  STUDENT_DASHBOARD_ASSESSMENTS_STATS_SUCCESS,
  STUDENT_DASHBOARD_ASSESSMENTS_STATS_FAILED,
  //--Tests and assessments progress item results--
  STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_RESULTS_INIT,
  STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_RESULTS_SUCCESS,
  STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_RESULTS_FAILED,
  //--Tests and assessments progress item answer sheet--
  STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_ANSWER_SHEET_INIT,
  STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_ANSWER_SHEET_SUCCESS,
  STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_ANSWER_SHEET_FAILED,
} from "../actions/student.dashboard.tests.subjects.units";
const initialReducer = {
  dashboard_homepage_tests_subjects_units: [],
  dashboard_homepage_tests_subjects_units_loading: true,
  message: "",
  //---Teacher base tests
  dashboard_homepage_teacher_made_test: [],
  dashboard_homepage_teacher_made_test_loading: true,
  message0: "",
  //---Auto tests
  dashboard_homepage_auto_test: [],
  dashboard_homepage_auto_test_loading: true,
  message1: "",
  //---Save teacher made test
  dashboard_homepage_save_teacher_made_test: [],
  dashboard_homepage_save_teacher_made_test_loading: true,
  message2: "",
  //---Save auto made test
  dashboard_homepage_save_auto_made_test: [],
  dashboard_homepage_save_auto_made_test_loading: true,
  message3: "",
  //---Get auto made test results and answers
  dashboard_homepage_results_for_auto_made_test: [[], [], [], ""],
  dashboard_homepage_results_for_auto_made_test_loading: true,
  message4: "",
  //---Get teacher made test results and answers
  dashboard_homepage_results_for_teacher_made_test: [],
  dashboard_homepage_results_for_teacher_made_test_loading: true,
  message5: "",
  //---Get teacher made test results and answers
  dashboard_homepage_get_teacher_made_test_answer_sheet_test: [],
  dashboard_homepage_get_teacher_made_test_answer_sheet_loading: true,
  message6: "",

  //---Get automated made test results and answers
  dashboard_homepage_get_auto_test_answer_sheet_test: [],
  dashboard_homepage_get_auto_test_answer_sheet_loading: true,
  message7: "",
  //---create a challenge from existing teacher made test-------create_teacher_made_challenge 
  dashboard_create_teacher_made_challenge: [],
  dashboard_create_teacher_made_challenge_loading: false,
  dashboard_create_teacher_made_challenge_launched: false,
  dashboard_create_teacher_made_challenge_launched_attempt: false,
  message8: "",
  //----create a challenge from existing auto made test-----------
  dashboard_create_auto_made_challenge: [],
  dashboard_create_auto_made_challenge_loading: false,
  dashboard_create_auto_made_challenge_launched: false,
  dashboard_create_auto_made_challenge_launched_attempt: false,
  message9: "",
  //----challenge stats-----------
  dashboard_challenges_stats: [],
  dashboard_challenges_stats_loading: false,
  message10: "",
  //--Tests and assessments progress--
  dashboard_assessments_stats: [],
  dashboard_assessments_stats_loading: false,
  message11: "",
  //--Tests and assessments progress item results--
  dashboard_assessments_progress_item_results: [],
  dashboard_assessments_progress_item_results_loading: false,
  message12: "",
  //--Tests and assessments progress item answer sheet--
  dashboard_assessments_progress_item_answer_sheet: [],
  dashboard_assessments_progress_item_answer_sheet_loading: false,
  message13: "",
  

};

function student_dashboard_homepage_tests_subjects_units_init(state, action) {
  return updateObject(state, {
    dashboard_homepage_tests_subjects_units_loading: true,
  });
}
function student_dashboard_homepage_tests_subjects_units_success(state, action) {
  return updateObject(state, {
    dashboard_homepage_tests_subjects_units: action.payload,
    dashboard_homepage_tests_subjects_units_loading: false,
    message: "success"
  });
}
function student_dashboard_homepage_tests_subjects_units_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    dashboard_homepage_tests_subjects_units_loading: false,
  });
}
//-----teacher based test
function student_dashboard_homepage_teacher_made_test_init(state, action) {
  return updateObject(state, {
    dashboard_homepage_teacher_made_test_loading: true,
    dashboard_homepage_teacher_made_test: [],
    //----clear some data
    dashboard_create_teacher_made_challenge: [],
    dashboard_create_teacher_made_challenge_loading: false,
    dashboard_create_teacher_made_challenge_launched: false,
    dashboard_create_teacher_made_challenge_launched_attempt: false,
    message8: "",
  });
}
function student_dashboard_homepage_teacher_made_test_success(state, action) {
  return updateObject(state, {
    dashboard_homepage_teacher_made_test: action.payload,
    dashboard_homepage_teacher_made_test_loading: false,
    message0: "success"
  });
}
function student_dashboard_homepage_teacher_made_test_failed(state, action) {
  return updateObject(state, {
    message0: action.payload,
    dashboard_homepage_teacher_made_test_loading: false,
  });
}
//----auto tests--
function student_dashboard_homepage_auto_test_init(state, action) {
  return updateObject(state, {
    dashboard_homepage_auto_test_loading: true,
    dashboard_homepage_auto_test: [],
  });
}
function student_dashboard_homepage_auto_test_success(state, action) {
  return updateObject(state, {
    dashboard_homepage_auto_test: action.payload,
    dashboard_homepage_auto_test_loading: false,
    message1: "success"
  });
}
function student_dashboard_homepage_auto_test_failed(state, action) {
  return updateObject(state, {
    message1: action.payload,
    dashboard_homepage_auto_test_loading: false,
  });
}
//----Save teacher made test--
function student_dashboard_save_teacher_made_test_init(state, action) {
  return updateObject(state, {
    dashboard_homepage_save_teacher_made_test_loading: true,
    dashboard_create_teacher_made_challenge_launched: false,
    dashboard_homepage_save_teacher_made_test: []
  });
}
function student_dashboard_save_teacher_made_test_success(state, action) {
  return updateObject(state, {
    dashboard_homepage_save_teacher_made_test: action.payload,
    dashboard_homepage_save_teacher_made_test_loading: false,
    message2: "success"
  });
}
function student_dashboard_save_teacher_made_test_failed(state, action) {
  return updateObject(state, {
    message2: action.payload,
    dashboard_homepage_save_teacher_made_test_loading: false,
  });
}
//----Save auto made test--
function student_dashboard_save_auto_made_test_init(state, action) {
  return updateObject(state, {
    dashboard_homepage_save_auto_made_test_loading: true,
    dashboard_homepage_save_auto_made_test: []
  });
}
function student_dashboard_save_auto_made_test_success(state, action) {
  return updateObject(state, {
    dashboard_homepage_save_auto_made_test: action.payload,
    dashboard_homepage_save_auto_made_test_loading: false,
    message3: "success"
  });
}
function student_dashboard_save_auto_made_test_failed(state, action) {
  return updateObject(state, {
    message3: action.payload,
    dashboard_homepage_save_auto_made_test_loading: false,
  });
}

//----Get auto made test results and answers
function student_dashboard_homepage_results_for_auto_made_test_init(state, action) {
  return updateObject(state, {
    dashboard_homepage_results_for_auto_made_test_loading: true,
    dashboard_homepage_results_for_auto_made_test: [],
    //-----
    dashboard_create_auto_made_challenge: [],
    dashboard_create_auto_made_challenge_loading: false,
    dashboard_create_auto_made_challenge_launched: false,
    dashboard_create_auto_made_challenge_launched_attempt: false,
    message9: "",
  });
}
function student_dashboard_homepage_results_for_auto_made_test_success(state, action) {
  return updateObject(state, {
    dashboard_homepage_results_for_auto_made_test: action.payload,
    dashboard_homepage_results_for_auto_made_test_loading: false,
    message4: "success"
  });
}
function student_dashboard_homepage_results_for_auto_made_test_failed(state, action) {
  return updateObject(state, {
    message4: action.payload,
    dashboard_homepage_results_for_auto_made_test_loading: false,
  });
}
//----Get teacher made test results and answers
function student_dashboard_homepage_results_for_teacher_made_test_init(state, action) {
  return updateObject(state, {
    dashboard_homepage_results_for_teacher_made_test_loading: true,
    dashboard_homepage_results_for_teacher_made_test: []
  });
}
function student_dashboard_homepage_results_for_teacher_made_test_success(state, action) {
  return updateObject(state, {
    dashboard_homepage_results_for_teacher_made_test: action.payload,
    dashboard_homepage_results_for_teacher_made_test_loading: false,
    message5: "success"
  });
}
function student_dashboard_homepage_results_for_teacher_made_test_failed(state, action) {
  return updateObject(state, {
    message5: action.payload,
    dashboard_homepage_results_for_teacher_made_test_loading: false,
  });
}
//----Get teacher made test answer sheet-----------
function student_dashboard_homepage_get_teacher_made_test_answer_sheet_init(state, action) {
  return updateObject(state, {
    dashboard_homepage_get_teacher_made_test_answer_sheet_loading: true,
    dashboard_homepage_get_teacher_made_test_answer_sheet_test: []
  });
}
function student_dashboard_homepage_get_teacher_made_test_answer_sheet_success(state, action) {
  return updateObject(state, {
    dashboard_homepage_get_teacher_made_test_answer_sheet_test: action.payload,
    dashboard_homepage_get_teacher_made_test_answer_sheet_loading: false,
    message6: "success"
  });
}
function student_dashboard_homepage_get_teacher_made_test_answer_sheet_failed(state, action) {
  return updateObject(state, {
    message6: action.payload,
    dashboard_homepage_get_teacher_made_test_answer_sheet_loading: false,
  });
}
//----get_auto test answer sheet-----------
function student_dashboard_homepage_get_auto_test_answer_sheet_init(state, action) {
  return updateObject(state, {
    dashboard_homepage_get_auto_test_answer_sheet_loading: true,
    dashboard_homepage_get_auto_test_answer_sheet_test: []
  });
}
function student_dashboard_homepage_get_auto_test_answer_sheet_success(state, action) {
  return updateObject(state, {
    dashboard_homepage_get_auto_test_answer_sheet_test: action.payload,
    dashboard_homepage_get_auto_test_answer_sheet_loading: false,
    message7: "success"
  });
}
function student_dashboard_homepage_get_auto_test_answer_sheet_failed(state, action) {
  return updateObject(state, {
    message7: action.payload,
    dashboard_homepage_get_auto_test_answer_sheet_loading: false,
  });
}
//----create a challenge from existing teacher made test-----------
function student_dashboard_create_teacher_made_challenge_init(state, action) {
  return updateObject(state, {
    dashboard_create_teacher_made_challenge_loading: true,
    dashboard_create_teacher_made_challenge: []
  });
}
function student_dashboard_create_teacher_made_challenge_success(state, action) {
  return updateObject(state, {
    dashboard_create_teacher_made_challenge: action.payload,
    dashboard_create_teacher_made_challenge_loading: false,
    dashboard_create_teacher_made_challenge_launched_attempt: true,
    dashboard_create_teacher_made_challenge_launched: true,
    dashboard_create_teacher_made_challenge_launched_success: false,
    message8: "success"
  });
}
function student_dashboard_create_teacher_made_challenge_failed(state, action) {
  return updateObject(state, {
    message8: action.payload,
    dashboard_create_teacher_made_challenge_loading: false,
    dashboard_create_teacher_made_challenge_launched_attempt: true,
  });
}
//----create a challenge from existing auto made test-----------
function student_dashboard_create_auto_made_challenge_init(state, action) {
  return updateObject(state, {
    dashboard_create_auto_made_challenge_loading: true,
    dashboard_create_auto_made_challenge: [],
    dashboard_create_auto_made_challenge_launched_attempt: false,
    dashboard_create_auto_made_challenge_launched: false,
  });
}
function student_dashboard_create_auto_made_challenge_success(state, action) {
  return updateObject(state, {
    dashboard_create_auto_made_challenge: action.payload,
    dashboard_create_auto_made_challenge_loading: false,
    dashboard_create_auto_made_challenge_launched_attempt: true,
    dashboard_create_auto_made_challenge_launched: true,
    message9: "success"
  });
}
function student_dashboard_create_auto_made_challenge_failed(state, action) {
  return updateObject(state, {
    message9: action.payload,
    dashboard_create_auto_made_challenge_loading: false,
    dashboard_create_auto_made_challenge: [],
    dashboard_create_auto_made_challenge_launched_attempt: true,
    dashboard_create_auto_made_challenge_launched: false
  });
}
//----challenge stats-----------
function student_dashboard_challenges_stats_init(state, action) {
  return updateObject(state, {
    dashboard_challenges_stats_loading: true,
    dashboard_challenges_stats: [],
  });
}
function student_dashboard_challenges_stats_success(state, action) {
  return updateObject(state, {
    dashboard_challenges_stats: action.payload,
    dashboard_challenges_stats_loading: false,
    message10: "success"
  });
}
function student_dashboard_challenges_stats_failed(state, action) {
  return updateObject(state, {
    message10: action.payload,
    dashboard_challenges_stats_loading: false,
    dashboard_challenges_stats: [],
  });
}
//--Tests and assessments progress--
function student_dashboard_assessments_stats_init(state, action) {
  return updateObject(state, {
    dashboard_assessments_stats_loading: true,
    dashboard_assessments_stats: [],
  });
}
function student_dashboard_assessments_stats_success(state, action) {
  return updateObject(state, {
    dashboard_assessments_stats: action.payload,
    dashboard_assessments_stats_loading: false,
    message11: "success"
  });
}
function student_dashboard_assessments_stats_failed(state, action) {
  return updateObject(state, {
    message11: action.payload,
    dashboard_assessments_stats_loading: false,
    dashboard_assessments_stats: [],
  });
}
//--Tests and assessments progress item results--
function student_dashboard_assessments_progress_item_results_init(state, action) {
  return updateObject(state, {
    dashboard_assessments_progress_item_results_loading: true,
    dashboard_assessments_progress_item_results: [],
  });
}
function student_dashboard_assessments_progress_item_results_success(state, action) {
  return updateObject(state, {
    dashboard_assessments_progress_item_results: action.payload,
    dashboard_assessments_progress_item_results_loading: false,
    message12: "success"
  });
}
function student_dashboard_assessments_progress_item_results_failed(state, action) {
  return updateObject(state, {
    message12: action.payload,
    dashboard_assessments_progress_item_results_loading: false,
    dashboard_assessments_progress_item_results: [],
  });
}
//--Tests and assessments progress item answer sheet--
function student_dashboard_assessments_progress_item_answer_sheet_init(state, action) {
  return updateObject(state, {
    dashboard_assessments_progress_item_answer_sheet_loading: true,
    dashboard_assessments_progress_item_answer_sheet: [],
  });
}
function student_dashboard_assessments_progress_item_answer_sheet_success(state, action) {
  return updateObject(state, {
    dashboard_assessments_progress_item_answer_sheet: action.payload,
    dashboard_assessments_progress_item_answer_sheet_loading: false,
    message13: "success"
  });
}
function student_dashboard_assessments_progress_item_answer_sheet_failed(state, action) {
  return updateObject(state, {
    message13: action.payload,
    dashboard_assessments_progress_item_answer_sheet_loading: false,
    dashboard_assessments_progress_item_answer_sheet: [],
  });
}
const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case STUDENT_DASHBOARD_HOMEPAGE_TESTS_SUBJECTS_UNITS_INIT:
      return student_dashboard_homepage_tests_subjects_units_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_TESTS_SUBJECTS_UNITS_SUCCESS:
      return student_dashboard_homepage_tests_subjects_units_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_TESTS_SUBJECTS_UNITS_FAILED:
      return student_dashboard_homepage_tests_subjects_units_failed(state, action);
    //--Teacher tests
    case STUDENT_DASHBOARD_HOMEPAGE_TEACHER_MADE_TEST_INIT:
      return student_dashboard_homepage_teacher_made_test_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_TEACHER_MADE_TEST_SUCCESS:
      return student_dashboard_homepage_teacher_made_test_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_TEACHER_MADE_TEST_FAILED:
      return student_dashboard_homepage_teacher_made_test_failed(state, action);
    //--Autotests
    case STUDENT_DASHBOARD_HOMEPAGE_AUTO_TEST_INIT:
      return student_dashboard_homepage_auto_test_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_AUTO_TEST_SUCCESS:
      return student_dashboard_homepage_auto_test_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_AUTO_TEST_FAILED:
      return student_dashboard_homepage_auto_test_failed(state, action);
    //--Save teacher made test
    case STUDENT_DASHBOARD_HOMEPAGE_SAVE_TEACHER_MADE_TEST_INIT:
      return student_dashboard_save_teacher_made_test_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_SAVE_TEACHER_MADE_TEST_SUCCESS:
      return student_dashboard_save_teacher_made_test_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_SAVE_TEACHER_MADE_TEST_FAILED:
      return student_dashboard_save_teacher_made_test_failed(state, action);
    //--Save auto made test
    case STUDENT_DASHBOARD_HOMEPAGE_SAVE_AUTO_MADE_TEST_INIT:
      return student_dashboard_save_auto_made_test_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_SAVE_AUTO_MADE_TEST_SUCCESS:
      return student_dashboard_save_auto_made_test_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_SAVE_AUTO_MADE_TEST_FAILED:
      return student_dashboard_save_auto_made_test_failed(state, action);
    //--Get auto made test results and answers
    case STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_AUTO_MADE_TEST_INIT:
      return student_dashboard_homepage_results_for_auto_made_test_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_AUTO_MADE_TEST_SUCCESS:
      return student_dashboard_homepage_results_for_auto_made_test_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_AUTO_MADE_TEST_FAILED:
      return student_dashboard_homepage_results_for_auto_made_test_failed(state, action);

    //--Get teacher made test results and answers
    case STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_TEACHER_MADE_TEST_INIT:
      return student_dashboard_homepage_results_for_teacher_made_test_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_TEACHER_MADE_TEST_SUCCESS:
      return student_dashboard_homepage_results_for_teacher_made_test_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_RESULTS_FOR_TEACHER_MADE_TEST_FAILED:
      return student_dashboard_homepage_results_for_teacher_made_test_failed(state, action);
    //----Get teacher made test answer sheet-----------
    case STUDENT_DASHBOARD_HOMEPAGE_GET_TEACHER_MADE_TEST_ANSWER_SHEET_INIT:
      return student_dashboard_homepage_get_teacher_made_test_answer_sheet_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_GET_TEACHER_MADE_TEST_ANSWER_SHEET_SUCCESS:
      return student_dashboard_homepage_get_teacher_made_test_answer_sheet_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_TEACHER_MADE_TEST_ANSWER_SHEET_FAILED:
      return student_dashboard_homepage_get_teacher_made_test_answer_sheet_failed(state, action);
    //----get_auto test answer sheet-----------
    case STUDENT_DASHBOARD_HOMEPAGE_GET_AUTO_TEST_ANSWER_SHEET_INIT:
      return student_dashboard_homepage_get_auto_test_answer_sheet_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_GET_AUTO_TEST_ANSWER_SHEET_SUCCESS:
      return student_dashboard_homepage_get_auto_test_answer_sheet_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_AUTO_TEST_ANSWER_SHEET_FAILED:
      return student_dashboard_homepage_get_auto_test_answer_sheet_failed(state, action);
    //----create a challenge from existing teacher made test-----------
    case STUDENT_DASHBOARD_CREATE_TEACHER_MADE_CHALLENGE_INIT:
      return student_dashboard_create_teacher_made_challenge_init(state, action);
    case STUDENT_DASHBOARD_CREATE_TEACHER_MADE_CHALLENGE_SUCCESS:
      return student_dashboard_create_teacher_made_challenge_success(state, action);
    case STUDENT_DASHBOARD_CREATE_TEACHER_MADE_CHALLENGE_FAILED:
      return student_dashboard_create_teacher_made_challenge_failed(state, action);
    //----create a challenge from existing auto made test-----------
    case STUDENT_DASHBOARD_CREATE_AUTO_MADE_CHALLENGE_INIT:
      return student_dashboard_create_auto_made_challenge_init(state, action);
    case STUDENT_DASHBOARD_CREATE_AUTO_MADE_CHALLENGE_SUCCESS:
      return student_dashboard_create_auto_made_challenge_success(state, action);
    case STUDENT_DASHBOARD_CREATE_AUTO_MADE_CHALLENGE_FAILED:
      return student_dashboard_create_auto_made_challenge_failed(state, action);
    //----challenge stats-----------
    case STUDENT_DASHBOARD_CHALLENGES_STATS_INIT:
      return student_dashboard_challenges_stats_init(state, action);
    case STUDENT_DASHBOARD_CHALLENGES_STATS_SUCCESS:
      return student_dashboard_challenges_stats_success(state, action);
    case STUDENT_DASHBOARD_CHALLENGES_STATS_FAILED:
      return student_dashboard_challenges_stats_failed(state, action);
    //--Tests and assessments progress--
    case STUDENT_DASHBOARD_ASSESSMENTS_STATS_INIT:
      return student_dashboard_assessments_stats_init(state, action);
    case STUDENT_DASHBOARD_ASSESSMENTS_STATS_SUCCESS:
      return student_dashboard_assessments_stats_success(state, action);
    case STUDENT_DASHBOARD_ASSESSMENTS_STATS_FAILED:
      return student_dashboard_assessments_stats_failed(state, action);
    //--Tests and assessments progress item results--
    case STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_RESULTS_INIT:
      return student_dashboard_assessments_progress_item_results_init(state, action);
    case STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_RESULTS_SUCCESS:
      return student_dashboard_assessments_progress_item_results_success(state, action);
    case STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_RESULTS_FAILED:
      return student_dashboard_assessments_progress_item_results_failed(state, action);
    //--Tests and assessments progress item answer sheet--
    case STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_ANSWER_SHEET_INIT:
      return student_dashboard_assessments_progress_item_answer_sheet_init(state, action);
    case STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_ANSWER_SHEET_SUCCESS:
      return student_dashboard_assessments_progress_item_answer_sheet_success(state, action);
    case STUDENT_DASHBOARD_ASSESSMENTS_PROGRESS_ITEM_ANSWER_SHEET_FAILED:
      return student_dashboard_assessments_progress_item_answer_sheet_failed(state, action);
    default:
      return state;
  }
};
export default reducer;
