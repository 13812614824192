import NewAndReminder from "./notificationcards/new";
import Achievement from "./notificationcards/achievement";
import Question from "./notificationcards/question";
import Activity from "./notificationcards/activity";
import Now from "./notificationcards/now";
import Schedule from "./notificationcards/schedule";
import Default from "./notificationcards/default";

const NotificationCard = (props) => {
  const { notification, auth } = props;
  if (
    (notification.type === "new" || notification.type === "reminder") &&
    auth
  ) {
    return <NewAndReminder {...notification} />;
  } else if (notification.type === "achievement" && auth) {
    return <Achievement {...notification} />;
  } else if (notification.type === "question") {
    return <Question {...notification} />;
  } else if (notification.type === "activity") {
    return <Activity {...notification} />;
  } else if (notification.type === "now") {
    return <Now {...notification} />;
  } else if (notification.type === "schedule") {
    return <Schedule {...notification} />;
  } else {
    return <Default {...notification} />;
  }
};

export default NotificationCard;
