import { updateObject } from "../../shared/utility";
import {
  STUDENT_GENERATE_OFFLINE_TOKEN_INIT,
  STUDENT_GENERATE_OFFLINE_TOKEN_SUCCESS,
  STUDENT_GENERATE_OFFLINE_TOKEN_FAILED,
} from "../actions/student.dashboard.settings.offline";
const initialReducer = {
  offlinetoken: [],
  loading: true,
  message: "", 
};

function school_generate_offline_token_init(state, action) {
  return updateObject(state, {
    loading: true,
  });
}
function school_generate_offline_token_success(state, action) {
  return updateObject(state, {
    offlinetoken: action.payload,
    loading: false,
    message: "success",
  });
}
function school_generate_offline_token_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    loading: false,
  });
}

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case STUDENT_GENERATE_OFFLINE_TOKEN_INIT:
      return school_generate_offline_token_init(state, action);
    case STUDENT_GENERATE_OFFLINE_TOKEN_SUCCESS:
      return school_generate_offline_token_success(state, action);
    case STUDENT_GENERATE_OFFLINE_TOKEN_FAILED:
      return school_generate_offline_token_failed(state, action);
    default:
      return state;
  }
};
export default reducer;
