import { bring_subject_image_src } from "./programs";

export const getAllTheSubjects = (mapRes: any) => {

  const filteredSRecentSubjectUnits = [];
  if (mapRes?.length > 0) {
    for (var i = 0; i < mapRes[1]?.length; i++) {
      filteredSRecentSubjectUnits.push({ value: mapRes[1][i]?.subject, displayValue: mapRes[1][i]?.subject });
    }
  }

  return filteredSRecentSubjectUnits;

};

//----
function bringObjectsDataFromPool(keyToFind, Pool) {

  for (var i = 0; i < Pool.length; i++) {
    if (Pool[i].name === keyToFind) {
      return Pool[i];
    }

  }
  return [];


}
export const checkIfOpandaCreator = (one, arr) => {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].creator_id == one) {
      return true

    } else {

    }
  }
  return false;
}

const checkIfLoggedInIsCreator = (creatorId, userId) => {

  if (creatorId == userId) {
    return true
  } else {
    return false;
  }
}

export const GetUnitsFromSelectedSubjects = (mapRes: any) => {

  var filteredSRecentSubjectsUnits: any = [];
  var UnfilteredSRecentSubjectsUnits: any = [];
  var filteredSRecentSubjectsUnits_collect: any = [];
  if (mapRes?.length > 0) {
    for (var i = 0; i < mapRes[0]?.length; i++) {
      if (!filteredSRecentSubjectsUnits_collect.includes(mapRes[0][i]?.units) && (checkIfOpandaCreator(mapRes[0][i]?.creator_id, mapRes[4]))) {
        filteredSRecentSubjectsUnits.push({
          id: mapRes[0][i]?.id,
          name: mapRes[0][i]?.units,
          subject: mapRes[0][i]?.subject,
          viewed: (mapRes[5][i] === 1) ? true : false,//----from the livevisibility check the unit viewership
          notes: [
            {
              title: mapRes[0][i]?.subject,
              id: mapRes[0][i]?.id,
              creator_id: mapRes[0][i]?.creator_id,
              author: (checkIfOpandaCreator(mapRes[0][i]?.creator_id, mapRes[4])) ? `Prepared by O'Genius Panda` : `Prepared by ${mapRes[3]}`,
              type: (checkIfOpandaCreator(mapRes[0][i]?.creator_id, mapRes[4])) ? `opanda` : `community`,
              imgsrc: bring_subject_image_src(mapRes[0][i]?.subject).imgsrc//'/images/preview/bio.svg',//---Use the right image for subjects
            },
          ],
        });
      }

      UnfilteredSRecentSubjectsUnits.push({
        id: mapRes[0][i]?.id,
        name: mapRes[0][i]?.units,
        subject: mapRes[0][i]?.subject,
        viewed: (mapRes[5][i] === 1) ? true : false,//----from the livevisibility check the unit viewership
        notes: [
          {
            title: mapRes[0][i]?.subject,
            id: mapRes[0][i]?.id,
            creator_id: mapRes[0][i]?.creator_id,
            author: (checkIfOpandaCreator(mapRes[0][i]?.creator_id, mapRes[4])) ? `Prepared by O'Genius Panda` : `Prepared by ${mapRes[3]}`,
            type: (checkIfOpandaCreator(mapRes[0][i]?.creator_id, mapRes[4])) ? `opanda` : `community`,
            imgsrc: bring_subject_image_src(mapRes[0][i]?.subject).imgsrc//,//---Use the right image for subjects
          },
        ],
      });



      //---   
      filteredSRecentSubjectsUnits_collect.push(mapRes[0][i]?.units);
    }
    ///-------


  }

  return [filteredSRecentSubjectsUnits, UnfilteredSRecentSubjectsUnits];

};

export const GetUnitsFromSelectedSubjectsForTeacher = (mapRes: any, userId) => {
  var filteredSRecentSubjectsUnits: any = [];
  var UnfilteredSRecentSubjectsUnits: any = [];
  var filteredSRecentSubjectsUnits_collect: any = [];
  if (mapRes?.length > 0) {
    for (var i = 0; i < mapRes[0]?.length; i++) {
      if (!filteredSRecentSubjectsUnits_collect.includes(mapRes[0][i]?.units) && ((checkIfOpandaCreator(mapRes[0][i]?.creator_id, mapRes[4])) || (checkIfLoggedInIsCreator(mapRes[0][i]?.creator_id, userId)))) {
        filteredSRecentSubjectsUnits.push({
          id: mapRes[0][i]?.id,
          name: mapRes[0][i]?.units,
          subject: mapRes[0][i]?.subject,
          viewed: (mapRes[5][i] === 1) ? true : false,//----from the livevisibility check the unit viewership
          notes: [
            {
              title: mapRes[0][i]?.subject,
              id: mapRes[0][i]?.id,
              creator_id: mapRes[0][i]?.creator_id,
              author: (checkIfOpandaCreator(mapRes[0][i]?.creator_id, mapRes[4])) ? `Prepared by O'Genius Panda` : (checkIfLoggedInIsCreator(mapRes[0][i]?.creator_id, userId) ? "Prepared by Me" : `Prepared by ${mapRes[3]}`),
              type: (checkIfOpandaCreator(mapRes[0][i]?.creator_id, mapRes[4])) ? `opanda` : (checkIfLoggedInIsCreator(mapRes[0][i]?.creator_id, userId) ? `my notes` : `community`),
              imgsrc: bring_subject_image_src(mapRes[0][i]?.subject).imgsrc//'/images/preview/bio.svg',//---Use the right image for subjects
            },
          ],
        });
      }

      UnfilteredSRecentSubjectsUnits.push({
        id: mapRes[0][i]?.id,
        name: mapRes[0][i]?.units,
        subject: mapRes[0][i]?.subject,
        viewed: (mapRes[5][i] === 1) ? true : false,//----from the livevisibility check the unit viewership
        notes: [
          {
            title: mapRes[0][i]?.subject,
            id: mapRes[0][i]?.id,
            creator_id: mapRes[0][i]?.creator_id,
            author: (checkIfOpandaCreator(mapRes[0][i]?.creator_id, mapRes[4])) ? `Prepared by O'Genius Panda` : (checkIfLoggedInIsCreator(mapRes[0][i]?.creator_id, userId) ? "Prepared by Me" : `Prepared by ${mapRes[3]}`),
            type: (checkIfOpandaCreator(mapRes[0][i]?.creator_id, mapRes[4])) ? `opanda` : (checkIfLoggedInIsCreator(mapRes[0][i]?.creator_id, userId) ? `my notes` : `community`),
            imgsrc: bring_subject_image_src(mapRes[0][i]?.subject).imgsrc//,//---Use the right image for subjects
          },
        ],
      });



      //---   
      filteredSRecentSubjectsUnits_collect.push(mapRes[0][i]?.units);
    }
    ///-------


  }

  return [filteredSRecentSubjectsUnits, UnfilteredSRecentSubjectsUnits];

};

export const GetUnitsFromSelectedSubjectsFilterByUnit = (unitname: any, mapRes: any) => {
  var UnfilteredSRecentSubjectsUnits: any = [];
  for (var i = 0; i < mapRes?.length; i++) {
    if (unitname == mapRes[i]?.name) {
      UnfilteredSRecentSubjectsUnits.push({
        title: mapRes[i]?.notes[0]?.subject,
        id: mapRes[i]?.notes[0]?.id,
        author: mapRes[i]?.notes[0]?.author,
        type: mapRes[i]?.notes[0]?.type,
        imgsrc: mapRes[i]?.notes[0]?.imgsrc,
        unit: unitname,
        subject: mapRes[i]?.subject,
        creator_id: mapRes[i]?.notes[0]?.creator_id,
      });

    }

    //---   
  }
  ///-------



  return UnfilteredSRecentSubjectsUnits;
};


export const getCoursePages = (data: any) => {
  const pages = (data[4]) ? data[4] : [];
  const pages_data = [];
  for (var i = 0; i < pages.length; i++) {
    pages_data.push(pages[i].page_data)
  }


  return pages_data;

}
export const getCoursePagesOtherResource = (data: any) => {
  const pages_data = [];
  if (data[1] && data[2] && data[3] && data[5]) {
    for (var i = 0; i < data[1]?.length; i++) {
      pages_data.push(data[1][i])
    }
    for (var i = 0; i < data[2]?.length; i++) {
      pages_data.push(data[2][i])
    }
    for (var i = 0; i < data[3]?.length; i++) {
      pages_data.push(data[3][i])
    }
    for (var i = 0; i < data[5]?.length; i++) {
      pages_data.push(data[5][i])
    }


  }
  return pages_data;

}