import { Link } from 'react-router-dom';
import {
  CardBody,
  CardTitle,
  SectionTitle,
  SmallText,
} from '../../../elements/text';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import { Button, TextButton } from '../../../elements/button';
import { useState } from 'react';
import Modal from '../../../elements/modal/modal';
import { BsCaretDownSquareFill, BsCaretUpSquareFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { chengeChosenProgram } from '../../../../store/actions/payment';

export const PrevNextButton = (props) => {
  return (
    <Link className={`flex flex-col px-${props.px}`} to={props.link}>
      {props.prev ? (
        <div className="flex flex-row items-center">
          <GrFormPrevious />
          <CardBody name="Previous" color="blue" alignment="center" />
        </div>
      ) : (
        <div className="flex flex-row items-center">
          <CardBody name="Next" color="blue" alignment="center" />
          <GrFormNext />
        </div>
      )}
      <SmallText name={props.name} color="gray-500" alignment="center" />
    </Link>
  );
};

const ProgramItem = (props) => {
  const dispatch = useDispatch();
  return (
    <div className="">
      <div
        className="my-2 py-2 px-3 flex justify-between items-center bg-gray-200 rounded-sm"
        onClick={props.onClick}
      >
        <CardTitle name={props.name} color="red" />
        {props.opened ? (
          <BsCaretUpSquareFill color="#183F71" />
        ) : (
          <BsCaretDownSquareFill color="#183F71" />
        )}
      </div>
      {props.opened ? (
        <ul className="pl-5">
          {props.options.map((option, index) => (
            <li key={index} className="flex items-center space-x-4">
             {option.checked === true ? <input
                type="radio"
                name={props.name}
                value={option.name}
                checked
                onChange={(event) =>{
                  dispatch(chengeChosenProgram(event.target.value));
                  props.closeModal();
                }
                }
              /> : <input
                type="radio"
                name={props.name}
                value={option.name}
                
                onChange={(event) =>{
                  dispatch(chengeChosenProgram(event.target.value));
                  props.closeModal();
                }
                }
              />}
              
              <CardBody name={option.name} color="blue" />
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

const PrevNext = (props) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [programs, setPrograms] = useState([
    {
      name: 'Modules',
      options: [
        { name: 'CBC', checked: true },
        { name: 'CAMBRIDGE', checked: false },
        { name: 'OTHER', checked: false },
      ],
      opened: true,
    },
    {
      name: 'Extra-curricular',
      options: [
        { name: 'DAP', checked: false },
        { name: 'Savoir Plus', checked: false },
        { name: 'Public Speaking', checked: false },
        { name: 'Business Development', checked: false },
      ],
      opened: false,
    },
    {
      name: 'Learning resources',
      options: [
        { name: 'Content', checked: false },
        { name: 'Coaching', checked: false },
        { name: 'Partner Services', checked: false },
        { name: 'Workshops', checked: false },
        { name: 'Opportunity', checked: false },
        { name: 'Books', checked: false },
      ],
      opened: false,
    },
  ]);

  const chosenProgram = useSelector((state) => state.payment.chosenProgram);

  const openProgram = (index) => {
    const newPrograms = [...programs];
    newPrograms.forEach((program) => {
      program.opened = false;
    });
    newPrograms[index].opened = true;
    setPrograms(newPrograms);
  };

  return (
    <div className="flex flex-row md:flex-row py-5 justify-between items-center">
      <Modal small show={showModal} modalClosed={() => setShowModal(false)}>
        <div>
          <SectionTitle name="Change Program" color="blue" />
          {programs.map((program, index) => (
            <ProgramItem
              key={index}
              {...program}
              onClick={() => openProgram(index)}
              closeModal={() => setShowModal(false)}
            />
          ))}
          <div className="flex justify-center items-center space-x-4">
            <Button
              name="Cancel"
              outline="true"
              color="blue"
              clicked={() => {
                setShowModal(false);
                // props.closeModal();
                dispatch(chengeChosenProgram('CBC'));
              }}
            />
            <Button
              name="Continue"
              outline="false"
              color="blue"
              clicked={() => setShowModal(false)}
            />
          </div>
        </div>
      </Modal>
      {props.previousLink ? (
        <PrevNextButton
          px={props.px}
          link={props.previousLink}
          prev
          name={props.previousName}
        />
      ) : null}
      {props.pageName ? (
        <div className={`px-${props.px}`}>
          <SectionTitle
            name={props.pageName}
            color="blue"
            alignment="center"
            additional="capitalize"
          />
          {props.showProgram ? (
            <div className="flex justify-center items-center space-x-4">
              <CardBody name={chosenProgram} color="blue" />
              <TextButton
                color="red"
                name="Change"
                additional="font-bold"
                clicked={() => setShowModal(true)}
              />
            </div>
          ) : null}
          {props.showChooseProgram ? (
            <div className="flex justify-center items-center space-x-4">
              <CardBody name={chosenProgram} color="blue" />
            <Button
                name="Change"
                outline="true"
                color="blue"
                isSquare
                clicked={() => {
                  setShowModal(true);
              }}
              additional="bg-white"
              />
            </div>
          ) : null}
        </div>
      ) : null}
      <div className="hidden md:block">
        {props.nextLink ? (
          <PrevNextButton
            px={props.px}
            link={props.nextLink}
            name={props.nextName}
          />
        ) : null}
      </div>
    </div>
  );
};

export default PrevNext;
