import { Fragment } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import Backdrop from './backdrop';

const SideDrawer = (props) => {
  return (
    <Fragment>
      <Backdrop show={props.show} clicked={props.closed} />
      {props.show ? (
        <div
          className={`bg-white z-50 w-90% h-full fixed bg-yellow left-0 top-0 box-border transition-transform ${
            props.show ? 'translate-x-0' : '-translate-x-full'
          }`}
        >
          <MdOutlineClose size={24} className="absolute top-4 right-4 cursor-pointer z-50" onClick={props.closed} />
          {props.children}
        </div>
      ) : null}
    </Fragment>
  );
};

export default SideDrawer;
