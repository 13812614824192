import { useState } from "react";
import { ContentContainer } from "../../../common/content/container";
import { CurriculumCourseCard } from "../../../elements/card";
import Input from "../../../elements/input";
import { CardBody } from "../../../elements/text";
import { useDispatch } from "react-redux";
import PageContent from "../pageContent";
import { useHistory } from "react-router-dom";

const DAP = () => {
  const history = useHistory();
  const [curriculum, setCurriculum] = useState("cbc");
  const [selectedLevel, setSelectedLevel] = useState("Senior 1");
  const [searchterm, setSearchterm] = useState("");
  const courses = [
    { name: "MATHEMATICS", image: "/images/preview/math.svg" },
    { name: "PHYSICS", image: "/images/preview/bio.svg" },
    { name: "BIOLOGY", image: "/images/preview/bio.svg" },
    { name: "CHEMISTRY", image: "/images/preview/bio.svg" },
    { name: "ECONOMY", image: "/images/preview/math.svg" },
    { name: "GEOGRAPH", image: "/images/preview/bio.svg" },
  ];
  const modules = [
    { name: "MODULES 1", image: "/images/preview/math.svg" },
    { name: "MODULES 2", image: "/images/preview/bio.svg" },
    { name: "MODULES 3", image: "/images/preview/bio.svg" },
    { name: "MODULES 4", image: "/images/preview/bio.svg" },
    { name: "MODULES 5", image: "/images/preview/math.svg" },
    { name: "MODULES 6", image: "/images/preview/bio.svg" },
  ];

  let subjects = [
    {
      id: 1,
      subject: "Biology",
      imgsrc: "/images/preview/bio.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: "2 hours ago",
    },
    {
      id: 2,
      subject: "Physics",
      imgsrc: "/images/preview/math.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: "2 hours ago",
    },
    {
      id: 3,
      subject: "Math",
      imgsrc: "/images/preview/math.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: "2 hours ago",
    },
    {
      id: 4,
      subject: "Biology",
      imgsrc: "/images/preview/bio.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: "2 hours ago",
    },
    {
      id: 5,
      subject: "Biology",
      imgsrc: "/images/preview/bio.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: "2 hours ago",
    },
    {
      id: 6,
      subject: "Geography",
      imgsrc: "/images/preview/math.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: "2 hours ago",
    },
  ];

  const dispatch = useDispatch();

  return (
    <PageContent>
      <div className="p-3">
        <div className="md:w-20%">
          <Input
            label="Choose Curriculum"
            elementType="select"
            elementConfig={{
              startingValue: "SELECT",
              options: [
                { value: "cbc", displayValue: "CBC" },
                { value: "dap", displayValue: "DAP" },
                { value: "savoir", displayValue: "Savoir Plus" },
              ],
            }}
            value={curriculum}
            changed={setCurriculum}
            validation={{ required: true }}
            shouldValidate
            error="Curriculum is required"
          />
        </div>
        {curriculum === "cbc" ? (
          <>
            <div className="text-right">
              <CardBody name={selectedLevel} color="blue" />
            </div>
            <div className="w-full pt-3 grid grid-cols-2 gap-4 md:flex justify-start flex-wrap">
              {courses.map((course, index) => (
                <CurriculumCourseCard
                  key={index}
                  image={course.image}
                  name={course.name}
                  clicked={() => {
                    history.push(`/student/features/summarynotes`);
                  }}
                />
              ))}
            </div>
            <div className="w-full flex justify-end">
              <div className="md:w-20%">
                <Input
                  label="Filter"
                  elementType="select"
                  elementConfig={{
                    startingValue: "SELECT",
                    options: [
                      { value: "Senior 1", displayValue: "Senior 1" },
                      { value: "Senior 2", displayValue: "Senior 2" },
                      { value: "Senior 3", displayValue: "Senior 3" },
                      { value: "Senior 4", displayValue: "Senior 4" },
                      { value: "Senior 5", displayValue: "Senior 5" },
                      { value: "Senior 6", displayValue: "Senior 6" },
                    ],
                  }}
                  value={selectedLevel}
                  changed={setSelectedLevel}
                  validation={{ required: true }}
                  shouldValidate
                  error="Level is required"
                />
              </div>
            </div>
            {/* <ContentContainer
          name="Subject Specialization"
          data={subjects}
          slider={true}
          type="one"
        />
        <ContentContainer
          name="Trending"
          data={subjects}
          slider={true}
          type="one"
        />
        <ContentContainer
          name="New"
          data={subjects}
          slider={true}
          type="one"
        />
        <ContentContainer
          name="Recommended"
          data={subjects}
          slider={true}
          type="one"
        /> */}
          </>
        ) : (
          <>
            <div className="w-full pt-3 grid grid-cols-2 gap-4 md:flex justify-start flex-wrap">
              {modules.map((module, index) => (
                <CurriculumCourseCard
                  key={index}
                  image={module.image}
                  name={module.name}
                />
              ))}
            </div>
            <div className="w-full flex justify-end">
              <div className="md:w-20%">
                <Input
                  label="Search"
                  elementType="input"
                  elementConfig={{
                    type: "text",
                    placeholder: "search...",
                  }}
                  value={searchterm}
                  changed={setSearchterm}
                />
              </div>
            </div>
            {/* <ContentContainer
          name="Recent"
          data={subjects}
          slider={true}
          type="three"
        />
        <ContentContainer
          name="Not Completed"
          data={subjects}
          slider={true}
          type="three"
        /> */}
          </>
        )}
      </div>
    </PageContent>
  );
};

export default DAP;
