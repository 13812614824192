import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ContentContainer } from "../../../common/content/container";
import { ButtonLink } from "../../../elements/button";
import Spinner from "../../../elements/spinner";
import { PageBody, SectionTitle } from "../../../elements/text";

export const DAP = (props) => {
  const { t } = useTranslation();
  const extracurriculars = useSelector(
    (state) => state.previewpanda.extracurriculars
  );
  const extracurricularsLoading = useSelector(
    (state) => state.previewpanda.modulesLoading
  );
  return (
    <div  style={{ height: "100vh" }}
    className="container flex flex-col items-start relative  overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100 p-3 pt-5 py-5 bg-gray-200">
 
      {extracurricularsLoading ? (
        <div className="flex flex-row justify-center w-full">
          <Spinner size="20" color="blue" />
        </div>
      ) : (
        <>
          <SectionTitle
            name={extracurriculars[props.index]?.fullname}
            alignment="left"
            color="blue"
          /><div className="h-24 sm:h-40 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
            <PageBody
              name={extracurriculars[props.index]?.description}
              alignment="left"
              color="blue"
            /></div>
          {/*<ButtonLink
            page="enroll"
            name={t("Enroll")}
            outline="true"
            color="red"
      />*/}
          <ContentContainer
            name={t("Modules")}
            data={extracurriculars[props.index]?.modulecourses}
            slider={false}
            type="three"
          />
        </>
      )}
    </div>
  );
};
