import PageContent from "../pageContent";
import { CardBody, CardTitle, FeatureTitle } from "../../../elements/text";
import { Button, ButtonWithIcon, TextButton } from "../../../elements/button";
import { TabPanel, useTabs } from "react-headless-tabs";
import TabSelector from "./TabSelector";
import Input from "../../../elements/input";
import { useState, useCallback } from "react";
import Post from "../../../common/post/Posts";
import Modal from "../../../elements/modal/modal";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef } from "react";
import {
  changeAskQuestionModalStatus,
  changeCommentModalStatus,
  setComment,
  setQuestion,
} from "../../../../store/actions/community";
import RichTextEditor from "../../../elements/RichTextEditor";
import { MdMenu } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import {
  student_dashboard_homepage_get_n_community_create_a_group_start,
  student_dashboard_homepage_get_n_community_discover_groups_start,
  student_dashboard_homepage_get_n_community_posts_load_more_start,
  student_dashboard_homepage_get_n_community_posts_new_comment_start,
  student_dashboard_homepage_get_n_community_posts_new_post_start,
  student_dashboard_homepage_get_n_community_posts_start,
  student_dashboard_homepage_get_n_community_posts_with_tag_start,
} from "../../../../store/actions/student.dashboard.community";
import { newSubjects } from "../../../../functions/newSubjects";
import { beautifyOpandaPostsAndCommentsText } from "../../../../functions/commonfunctions";
import parse from "react-html-parser";
import { propTypes } from "react-bootstrap/esm/Image";
import Spinner from "../../../elements/spinner";
import { GiTargetPrize } from "react-icons/gi";
import NotFound from "../../../common/features/NotFound";
import axios from "../../../../axios-base";
import DiscoverGroupItem from "./groups/DiscoverGroupItem";
import GroupSettingsTab from "./groups/GroupSettingsTab";
import GroupsJoiningRequests from "./groups/GroupsJoiningRequests";
import GroupParticipants from "./groups/GroupParticipants";
import { BsPlusLg } from "react-icons/bs";
const Community = () => {
  const history = useHistory();
  const userAuth = useSelector((state) => state.auth);
  const studentDashboardCommunity = useSelector(
    (state) => state.studentDashboardCommunity
  );
  const myRef = useRef(null);
  const scrollPosRef = useRef(0);
  const [scrollTo, setScrollTo] = useState(0);
  const [group_id, setGroup_id] = useState(0);
  const [group_name, setGroup_name] = useState("");
  const [settingsMode, setSettingsMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chosePostId, setChosePostId] = useState(0);
  const [groupCreateIsLoading, setGroupCreateIsLoading] = useState(false);
  const [RequestsLoading, setRequestsLoading] = useState(false);
  const [RequestsPool, setRequestsPool] = useState([]);
  const [showGroupParticipantsModal, setShowGroupParticipantsModal] =
    useState(false);
  const [GroupParticipantsLoading, setGroupParticipantsLoading] =
    useState(false);
  const [GroupParticipantsPool, setGroupParticipantsPool] = useState([]);
  const [groupCreator, setGroupCreator] = useState(0);

  //--
  useEffect(() => {
    dispatch(
      student_dashboard_homepage_get_n_community_posts_start({
        token: userAuth.token,
        username: userAuth.username,
        userId: userAuth.userId,
        academic_level: userAuth.academic_level,
        limit: 10,
        lastId: 0,
        tag: tag,
        group_id: group_id,
      })
    );
    //--
    const div = myRef.current;
    if (div) {
      div.scrollTop = scrollPosRef?.current;
    }
    //--
  }, [scrollTo, groupCreateIsLoading]);

  const handleScroll = useCallback((event) => { }, []);
  const seeParticipants = () => {
    setShowGroupParticipantsModal(true);
    setGroupParticipantsLoading(true);
    axios
      .post(
        `/opanda/community/get_n_community_get_group_participants`,
        {
          username: userAuth.username,
          user_id: userAuth.userId,
          academic_level: userAuth.academic_level,
          group_id: group_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userAuth.token}`,
          },
        }
      )
      .then((response) => {
        setGroupParticipantsLoading(false);
        setGroupParticipantsPool(response?.data?.results);
      })
      .catch((err) => {
        setGroupParticipantsLoading(false);
      });
  };

  const entryLimitUpdated = (value) => {
    dispatch(
      student_dashboard_homepage_get_n_community_posts_start({
        token: userAuth.token,
        username: userAuth.username,
        userId: userAuth.userId,
        academic_level: userAuth.academic_level,
        limit: value,
        lastId: 0,
        tag: tag,
        group_id: group_id,
      })
    );
  };
  const searchTagFilterUpdated = (value) => {
    dispatch(
      student_dashboard_homepage_get_n_community_posts_with_tag_start({
        token: userAuth.token,
        username: userAuth.username,
        userId: userAuth.userId,
        academic_level: userAuth.academic_level,
        limit: limit,
        lastId: 0,
        tag: value,
        group_id: group_id,
      })
    );
  };

  const getPosts_Groups_Answers = (data) => {
    let response = [[], [], [], 0];

    if (data?.student_dashboard_homepage_get_n_community_posts?.data?.results) {
      const posts_data =
        data?.student_dashboard_homepage_get_n_community_posts?.data?.results;
      return posts_data;
    }

    return response;
  };

  const posts = [
    {
      content:
        "Deserunt nulla sit aliquip irure veniam sint culpa do incididunt quis. Dolore ea adipisicing veniam culpa eu dolor non adipisicing consectetur irure tempor velit sunt. Veniam deserunt adipisicing veniam fugiat ea veniam id quis id. Magna esse tempor aute enim cillum ad labore incididunt mollit ad.",
      related: ["Biology"],
      comments: [
        {
          content:
            "Excepteur aliqua ipsum Lorem et in quis irure quis minim quis minim cupidatat. Consequat ad mollit dolore ipsum deserunt velit voluptate magna minim consectetur deserunt amet nulla aliquip. Commodo ea dolore voluptate tempor mollit commodo consectetur deserunt ad occaecat non voluptate.",
          creator: "Mugabo Cedric",
          createdAt: "2022-05-05",
          isAnswer: true,
        },
        {
          content:
            "Excepteur aliqua ipsum Lorem et in quis irure quis minim quis minim cupidatat. Consequat ad mollit dolore ipsum deserunt velit voluptate magna minim consectetur deserunt amet nulla aliquip. Commodo ea dolore voluptate tempor mollit commodo consectetur deserunt ad occaecat non voluptate.",
          creator: "Mugabo Cedric",
          createdAt: "2022-05-05",
          isAnswer: false,
        },
        {
          content:
            "Excepteur aliqua ipsum Lorem et in quis irure quis minim quis minim cupidatat. Consequat ad mollit dolore ipsum deserunt velit voluptate magna minim consectetur deserunt amet nulla aliquip. Commodo ea dolore voluptate tempor mollit commodo consectetur deserunt ad occaecat non voluptate.",
          creator: "Mugabo Cedric",
          createdAt: "2022-05-05",
          isAnswer: false,
        },
      ],
      creator: "Mugabo Cedric",
      createdAt: "2022-05-05",
      answered: true,
    },
    {
      content:
        "Lorem sint excepteur ex enim culpa sint id nostrud proident. Velit consectetur elit pariatur minim qui excepteur esse. Exercitation quis ea officia minim adipisicing ullamco culpa Lorem. Nisi eu exercitation non irure laboris nisi minim deserunt laborum ipsum do amet nulla in. Occaecat adipisicing nisi ullamco eu cillum nisi consequat cillum cillum magna in amet. Duis labore sit dolor proident quis laboris eu sunt irure consectetur sunt cillum est. Officia amet magna culpa laborum proident qui consequat in reprehenderit nisi ad.",
      related: ["Biology", "chemistry"],
      comments: [],
      creator: "Mugabo Cedric",
      createdAt: "2022-05-05",
      answered: false,
    },
  ];
  const groups = [
    {
      name: "Public channel",
      unread: 0,
      createdBy: "",
    },
    {
      name: "Group 1",
      unread: 0,
      createdBy: "",
    },
    {
      name: "Another group",
      unread: 20,
      createdBy: "",
    },
    {
      name: "Group 3",
      unread: 2,
      createdBy: "",
    },
    {
      name: "Yet another group",
      unread: 20,
      createdBy: "",
    },
    {
      name: "My created group",
      unread: 0,
      createdBy: "me",
    },
  ];

  const tabs = getPosts_Groups_Answers(studentDashboardCommunity)[1]?.map(
    (group, index) => group.name
  );
  const [selectedTab, setSelectedTab] = useTabs(tabs, "Public channel");
  const [limit, setLimit] = useState(10);
  const [tag, setTag] = useState("");
  const [loadMore, setLoadMore] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [related, setRelated] = useState("");
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [showDiscoverGroupModal, setShowDiscoverGroupModal] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [groupType, setGroupType] = useState("private");
  const [postid, setPostid] = useState(0);
  const [showJoiningRequestsModal, setShowJoiningRequestsModal] =
    useState(false);

  const dispatch = useDispatch();
  const showCommentModal = useSelector(
    (state) => state.community.showCommentModal
  );
  const showAskQuestionModal = useSelector(
    (state) => state.community.showAskQuestionModal
  );
  const comment = useSelector((state) => state.community.comment);
  const question = useSelector((state) => state.community.question);

  const requests = [
    { name: "Oliver Karangwa", school: "LDK", added: true },
    { name: "Oliver Karangwa", school: "LDK", added: false },
    { name: "Oliver Karangwa", school: "LDK", added: true },
    { name: "Oliver Karangwa", school: "LDK", added: true },
    { name: "Oliver Karangwa", school: "LDK", added: false },
  ];
  const getPostid = (data) => {
    setPostid(data);
  };
  const setPostAndRelatedData = (post, related) => {
    setRelated(related);
    dispatch(setQuestion(post));
  };
  return (
    <PageContent>
      <Modal
        medium
        show={showAskQuestionModal}
        modalClosed={() => dispatch(changeAskQuestionModalStatus(false))}
      >
        <CardTitle
          name={!settingsMode ? "Ask your question" : "Edit/Delete this post"}
        />
        <RichTextEditor
          value={question}
          onChange={(text) => dispatch(setQuestion(text))}
          placeholder="Write your question here"
        />
        <Input
          label="Choose tag: Eg: Physics, Thermodynamics"
          elementType="input"
          elementConfig={{
            type: "text",
            placeholder: "What is your question related to?",
          }}
          value={related}
          changed={setRelated}
        />
        <div className="pt-3 flex justify-center space-x-5">
          <Button
            name="Cancel"
            outline="true"
            color="blue"
            clicked={() => dispatch(changeAskQuestionModalStatus(false))}
          />
          {!settingsMode ? (
            !studentDashboardCommunity.student_dashboard_homepage_get_n_community_posts_loading ? (
              <Button
                name="Post"
                outline="false"
                color="blue"
                clicked={() => {
                  if (question !== "" && related !== "") {
                    dispatch(
                      student_dashboard_homepage_get_n_community_posts_new_post_start(
                        {
                          token: userAuth.token,
                          username: userAuth.username,
                          userId: userAuth.userId,
                          academic_level: userAuth.academic_level,
                          limit: 10,
                          lastId: 0,
                          tag: related,
                          post: question + " ",
                          group_id: group_id,
                        }
                      )
                    );
                  } else {
                    alert("Empty fields!!.?");
                  }
                }}
              />
            ) : (
              <Spinner size="20" color="blue" />
            )
          ) : !isLoading ? (
            <>
              <Button
                name="Delete"
                outline="true"
                color="red"
                clicked={() => {
                  if (question !== "" && related !== "") {
                    if (
                      window.confirm(
                        "Are you sure, you want to delete this post?"
                      )
                    ) {
                      setIsLoading(true);
                      dispatch(changeAskQuestionModalStatus(true));
                      axios
                        .post(
                          `/opanda/community/get_n_community_posts_delete_post`,
                          {
                            academic_level: userAuth.academic_level,
                            user_id: userAuth.userId,
                            question: question,
                            related: related,
                            postid: postid,
                          },
                          {
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${userAuth.token}`,
                            },
                          }
                        )
                        .then((response) => {
                          setIsLoading(false);
                          setLoadMore(loadMore + 2);
                          dispatch(changeAskQuestionModalStatus(false));
                          dispatch(
                            student_dashboard_homepage_get_n_community_posts_load_more_start(
                              {
                                token: userAuth.token,
                                username: userAuth.username,
                                userId: userAuth.userId,
                                academic_level: userAuth.academic_level,
                                limit: loadMore + limit * 2,
                                lastId: getPosts_Groups_Answers(
                                  studentDashboardCommunity
                                )[3],
                                tag: tag,
                                group_id: group_id,
                              }
                            )
                          );
                        })
                        .catch((err) => {
                          setIsLoading(false);
                        });
                    }
                  } else {
                    alert("Empty Fields!!");
                  }
                }}
              />
              <Button
                name="Update"
                outline="true"
                color="blue"
                clicked={() => {
                  if (question !== "" && related !== "") {
                    if (
                      window.confirm(
                        "Are you sure, you want to update this post?"
                      )
                    ) {
                      setIsLoading(true);
                      setLoadMore(loadMore + 2);
                      axios
                        .post(
                          `/opanda/community/get_n_community_posts_update_post`,
                          {
                            academic_level: userAuth.academic_level,
                            user_id: userAuth.userId,
                            question: question,
                            related: related,
                            postid: postid,
                          },
                          {
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${userAuth.token}`,
                            },
                          }
                        )
                        .then((response) => {
                          setIsLoading(false);
                          dispatch(changeAskQuestionModalStatus(false));
                          dispatch(
                            student_dashboard_homepage_get_n_community_posts_load_more_start(
                              {
                                token: userAuth.token,
                                username: userAuth.username,
                                userId: userAuth.userId,
                                academic_level: userAuth.academic_level,
                                limit: loadMore + limit * 2,
                                lastId: getPosts_Groups_Answers(
                                  studentDashboardCommunity
                                )[3],
                                tag: tag,
                                group_id: group_id,
                              }
                            )
                          );
                        })
                        .catch((err) => {
                          setIsLoading(false);
                        });
                    }
                  } else {
                    alert("Empty Fields!!");
                  }
                }}
              />
            </>
          ) : (
            <Spinner size="20" color="blue" />
          )}
        </div>
      </Modal>
      <Modal
        medium
        show={showCreateGroupModal}
        modalClosed={() => setShowCreateGroupModal(false)}
      >
        <CardTitle name="Create Group" />
        <Input
          label="Name"
          elementType="input"
          elementConfig={{
            type: "text",
            placeholder: "Enter group name",
          }}
          value={groupName}
          changed={setGroupName}
          validation={{ required: true }}
          shouldValidate
          error="Group name is required"
        />
        <Input
          label="Description"
          elementType="textarea"
          elementConfig={{
            type: "text",
            placeholder: "Add description",
          }}
          value={groupDescription}
          changed={setGroupDescription}
        />
        <Input
          elementType="select"
          elementConfig={{
            startingValue: "SELECT",
            options: [
              { value: "private", displayValue: "Private" },
              { value: "public", displayValue: "Public" },
            ],
          }}
          value={groupType}
          changed={setGroupType}
          validation={{ required: true }}
          shouldValidate
          error="Invalid type"
        />
        <div className="pt-3 flex justify-center space-x-5">
          <Button
            name="Cancel"
            outline="true"
            color="blue"
            clicked={() => setShowCreateGroupModal(false)}
          />
          {!groupCreateIsLoading ? (
            <Button
              name="Save"
              outline="false"
              color="blue"
              clicked={() => {
                if (groupName === "" || groupDescription === "") {
                  alert("Empty Fields!!?");
                  return;
                }
                if (window.confirm("Are you sure, you want to save!?")) {
                  setGroupCreateIsLoading(true);
                  axios
                    .post(
                      `/opanda/community/get_n_community_create_a_group`,
                      {
                        username: userAuth.username,
                        user_id: userAuth.userId,
                        academic_level: userAuth.academic_level,
                        groupName: groupName,
                        groupDescription: groupDescription,
                        groupVisibilityStatus: groupType === "public" ? 0 : 1,
                      },
                      {
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${userAuth.token}`,
                        },
                      }
                    )
                    .then((response) => {
                      console.log("response", response);
                      setGroupDescription("");
                      setGroupName("");
                      setGroupCreateIsLoading(false);
                      dispatch(setShowCreateGroupModal(false));
                      dispatch(
                        student_dashboard_homepage_get_n_community_posts_start({
                          token: userAuth.token,
                          username: userAuth.username,
                          userId: userAuth.userId,
                          academic_level: userAuth.academic_level,
                          limit: 10,
                          lastId: 0,
                          tag: tag,
                          group_id: group_id,
                        })
                      );
                    })
                    .catch((err) => {
                      setIsLoading(false);
                    });
                }
              }}
            />
          ) : (
            <Spinner size="20" color="blue" />
          )}
        </div>
      </Modal>
      <Modal
        medium
        show={showGroupParticipantsModal}
        modalClosed={() => setShowGroupParticipantsModal(false)}
      >
        <CardTitle name="View Group Participants" />
        {!GroupParticipantsLoading ? (
          <GroupParticipants
            groupCreator={groupCreator}
            GroupParticipantsPool={GroupParticipantsPool}
            group_id={group_id}
          />
        ) : (
          <Spinner size="20" color="blue" />
        )}
        <div className="pt-3 flex justify-center space-x-5">
          <Button
            name="Cancel"
            outline="true"
            color="blue"
            clicked={() => setShowGroupParticipantsModal(false)}
          />
        </div>
      </Modal>
      <Modal
        medium
        show={showDiscoverGroupModal}
        modalClosed={() => setShowDiscoverGroupModal(false)}
      >
        <CardTitle name="Discover Groups" />
        {!studentDashboardCommunity.student_dashboard_homepage_get_n_community_discover_groups_loading ? (
          <div className=" h-80 overflow-y-auto p-5 scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
            {studentDashboardCommunity
              .student_dashboard_homepage_get_n_community_discover_groups?.data
              ?.results.length > 0 ? (
              studentDashboardCommunity.student_dashboard_homepage_get_n_community_discover_groups?.data?.results.map(
                (group, index) => {
                  return (
                    <DiscoverGroupItem
                      group_id={group_id}
                      key={index}
                      groupData={group}
                    />
                  );
                }
              )
            ) : (
              <NotFound feature={` new Groups`} />
            )}
          </div>
        ) : (
          <Spinner size="20" color="blue" />
        )}
      </Modal>
      <Modal
        medium
        show={showJoiningRequestsModal}
        modalClosed={() => setShowJoiningRequestsModal(false)}
      >
        <CardTitle
          name={`${selectedTab ? selectedTab.split("##")[0] : ""
            }'Joining requests`}
        />
        <GroupsJoiningRequests
          selectedTab={selectedTab}
          RequestsPool={RequestsPool}
          loading={RequestsLoading}
          group_id={group_id}
          key={group_id}
        />

        <div className="pt-3 flex justify-center space-x-5">
          <Button
            name="Cancel"
            outline="true"
            color="blue"
            clicked={() => setShowJoiningRequestsModal(false)}
          />
        </div>
      </Modal>
      {!studentDashboardCommunity.student_dashboard_homepage_get_n_community_posts_loading &&
        getPosts_Groups_Answers(studentDashboardCommunity)[0]?.length >= 0 ? (
        <div className="md:grid grid-cols-12 w-full min-h-screen-sec">
          <div className="p-3 bg-gray-200  md:h-full col-span-3">
            <div className="flex justify-center">
              <Button
                name="+ Create group"
                outline="false"
                color="blue"
                clicked={() => setShowCreateGroupModal(true)}
              />
            </div>
            <div className="flex justify-center mt-1">
              <Button
                name=" Discover groups"
                outline="false"
                color="blue"
                clicked={() => {
                  setShowDiscoverGroupModal(true);
                  dispatch(
                    student_dashboard_homepage_get_n_community_discover_groups_start(
                      {
                        token: userAuth.token,
                        username: userAuth.username,
                        userId: userAuth.userId,
                        academic_level: userAuth.academic_level,
                      }
                    )
                  );
                }}
              />
            </div>
            <div className="flex md:block py-5 md:h-100 scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
              {getPosts_Groups_Answers(studentDashboardCommunity)[1]?.map(
                (group, index) => (
                  <div
                    style={{
                      borderLeft:
                        selectedTab === group.name
                          ? "4px solid #183F71"
                          : "1px solid gray",
                      borderRadius: 5,
                    }}
                  >
                    <TabSelector
                      key={index}
                      isActive={selectedTab === group.name}
                      title={group.name.split("##")[0]}
                      new={group.unread}
                      onClick={() => {
                        setSelectedTab(group.name);
                        setGroup_id(group.id_);
                        setGroupCreator(group.createdBy);

                        dispatch(
                          student_dashboard_homepage_get_n_community_posts_start(
                            {
                              token: userAuth.token,
                              username: userAuth.username,
                              userId: userAuth.userId,
                              academic_level: userAuth.academic_level,
                              limit: 10,
                              lastId: 0,
                              tag: tag,
                              group_id: group.id_,
                            }
                          )
                        );
                      }}
                    />{" "}
                    {selectedTab === group.name &&
                      group.name !== "Public channel" ? (
                      <div
                        style={{
                          fontSize: 13,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div className="w-60"></div>
                        <div>
                          <GroupSettingsTab
                            seeParticipants={seeParticipants}
                            key={group_id}
                            group_id={group_id}
                            visibility={group.group_visibility}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )
              )}
            </div>
          </div>
          <div className="bg-white col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
            <div className="p-3 flex justify-between items-center">
              <FeatureTitle name="Community" />
              <div className="flex space-x-2">
                {getPosts_Groups_Answers(studentDashboardCommunity)[1]?.filter(
                  (group, index) =>
                    group.name === selectedTab &&
                    group.createdBy === userAuth.userId
                )[0] && (
                    <ButtonWithIcon
                      name="Joining Requests"
                      outline="true"
                      isSmall={true}
                      color="blue"
                      onClick={() => {
                        setShowJoiningRequestsModal(true);
                        axios
                          .post(
                            `/opanda/community/get_n_community_requests_to_join_a_group`,
                            {
                              academic_level: userAuth.academic_level,
                              user_id: userAuth.userId,
                              group_id: group_id,
                            },
                            {
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${userAuth.token}`,
                              },
                            }
                          )
                          .then((response) => {
                            setRequestsLoading(false);

                            setRequestsPool(response?.data?.results.map(result => ({
                              ...result,
                              school: result.school.names
                            })));
                          })
                          .catch((err) => {
                            setRequestsLoading(false);
                          });
                      }}
                    >
                    </ButtonWithIcon>
                  )}
                <ButtonWithIcon
                  name="New post"
                  outline="false"
                  isSmall={true}
                  color="blue"
                  onClick={() => {
                    dispatch(changeAskQuestionModalStatus(true));
                    setSettingsMode(false);
                  }}
                >
                </ButtonWithIcon>
              </div>
            </div>
            {getPosts_Groups_Answers(studentDashboardCommunity)[1]?.map(
              (group, index) => (
                <TabPanel key={index} hidden={selectedTab !== group.name}>
                  <div className="grid grid-cols-12">
                    <div className="col-span-12">
                      <div className="px-3 flex justify-between items-end">
                        <div className="w-24">
                          <Input
                            label="Show entries"
                            elementType="select"
                            additional="w-24 text-sm"
                            elementConfig={{
                              startingValue: "SELECT",
                              options: [
                                { value: 10, displayValue: "10" },
                                { value: 25, displayValue: "25" },
                                { value: 50, displayValue: "50" },
                                { value: 100, displayValue: "100" },
                              ],
                            }}
                            value={limit}
                            changed={setLimit}
                            entryLimitUpdated={entryLimitUpdated}
                          />
                        </div>
                        <Input
                          elementType="input"
                          additional="w-24 text-sm"
                          elementConfig={{
                            placeholder: "Search",
                            type: "text",
                          }}
                          value={searchTerm}
                          changed={setSearchTerm}
                        />
                      </div>
                      {getPosts_Groups_Answers(studentDashboardCommunity)[0]
                        ?.length != 0 ? (
                        <div
                          style={{ overflowX: "hidden" }}
                          ref={myRef}
                          onScroll={handleScroll}
                          className="overflow-y-auto p-5 scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100"
                        >
                          {getPosts_Groups_Answers(studentDashboardCommunity)[0]
                            ?.filter(
                              (post, index) =>
                                searchTerm === "" ||
                                post.content
                                  .toLocaleLowerCase()
                                  .includes(searchTerm.toLocaleLowerCase())
                            )
                            ?.map((post, index) => (
                              <Post
                                setSettingsMode={setSettingsMode}
                                setPostAndRelatedData={setPostAndRelatedData}
                                key={post.postid}
                                {...post}
                                user_id={userAuth.userId}
                                group_id={group_id}
                                postId={post.postid}
                                getPostid={getPostid}
                                limit={limit}
                                comments={post.comments}
                              />
                            ))}
                        </div>
                      ) : (
                        <NotFound
                          feature={`Community posts ${tag === "" || tag === "All Posts"
                              ? ""
                              : " of tag '" + tag + "'"
                            } `}
                        />
                      )}
                      {!studentDashboardCommunity.student_dashboard_homepage_get_n_community_posts_load_more_loading ? (
                        <span
                          className="p-3 text-center flex justify-center"
                          onClick={() => {
                            setLoadMore(loadMore + limit * 2);
                            dispatch(
                              student_dashboard_homepage_get_n_community_posts_load_more_start(
                                {
                                  token: userAuth.token,
                                  username: userAuth.username,
                                  userId: userAuth.userId,
                                  academic_level: userAuth.academic_level,
                                  limit: loadMore + limit * 2,
                                  lastId: getPosts_Groups_Answers(
                                    studentDashboardCommunity
                                  )[3],
                                  tag: tag,
                                  group_id: group_id,
                                }
                              )
                            );
                            //--
                          }}
                        >
                          {getPosts_Groups_Answers(studentDashboardCommunity)[0]
                            ?.length >= limit ? (
                            <Button
                              name="Load more..."
                              outline="true"
                              color="blue"
                              isSmall={true}
                              clicked={() => { }}
                            />
                          ) : (
                            ""
                          )}
                        </span>
                      ) : (
                        <Spinner size="10" color="blue" />
                      )}
                    </div>
                    {/* <div className="col-span-3 p-3 flex flex-col items-end">
                      <Input
                        elementType="select"
                        elementConfig={{
                          startingValue: "All Posts",
                          options: [
                            { value: "My Posts", displayValue: "My Posts" },
                            {
                              value: "Posts I replied to",
                              displayValue: "Posts I replied to",
                            },
                            ...getPosts_Groups_Answers(
                              studentDashboardCommunity
                            )[2],
                          ],
                        }}
                        value={tag}
                        changed={setTag}
                        searchTagFilterUpdated={searchTagFilterUpdated}
                      />
                    </div> */}
                  </div>
                </TabPanel>
              )
            )}
          </div>
        </div>
      ) : (
        <Spinner size="40" color="blue" />
      )}
    </PageContent>
  );
};

export default Community;
