import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FeaturesCard } from "../../../elements/card";
import * as actions from "../../../../store/actions/";
import Spinner from "../../../elements/spinner";

// Import Swiper React components

const PageContent = (props) => {
  const features = useSelector((state) => state.features.features.features);
  const featuresLoading = useSelector(
    (state) => state.features.features.featuresLoading
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getFeatures());
  }, []);
  return (
    <div className="bg-white my-8">
      {featuresLoading ? (
        <div className="flex flex-row justify-center w-full">
          <Spinner size="20" color="blue" />
        </div>
      ) : (
        <div className="max-w-7xl w-full mx-auto px-0">
          <div className="w-full grid grid-cols-1 align-start justify-center md:grid-cols-2 mx-auto">
            {features?.map((feature, key) => (
              <FeaturesCard
                key={key}
                imgsrc={`${process.env.REACT_APP_BACKEND_URL}/opanda/${feature?.thumbnail}`}
                feature={feature?.name}
                body={feature?.description}
              />
            ))}
          </div>
          <hr className="mt-12 mb-4 text-gray text-md" />
        </div>
      )}
    </div>
  );
};
export default PageContent;
