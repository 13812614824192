import Cambridge from '../../components/pages/student/cambridge/cambridge';
import Layout from '../../components/common/Layout';

const CambridgeCurriculum = () => {
  return (
    <Layout>
      <Cambridge />
    </Layout>
  );
};

export default CambridgeCurriculum;
