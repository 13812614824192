import Layout from '../../../components/common/Layout';
import UserInfo from '../../../components/pages/school/users/userinfo';

const UsersInfo = () => {
  return (
    <Layout>
      <UserInfo />
    </Layout>
  );
};
 
export default UsersInfo; 
