import { CgMoreVertical } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Actionlist from "../../../elements/table/actionlist";
import {
  school_dashboard_users_delete_start,
  school_dashboard_users_reset_otp_start,
} from "../../../../store/actions/school.dashboard.users";
import { school_add_registered_users_start, school_remove_registered_users_start } from "../../../../store/actions/school.dashboard.user.registered";

const RegisteredActionlist = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userAuth = useSelector((state) => state.auth);

  return (
    <Actionlist
      onClick={props.onClick}
      showinglist={props.showinglist}
      k={props.k}
      list={[
        {
          onClick:
          props.status
            ? null : () =>
            dispatch(
              school_add_registered_users_start({
                token: userAuth.token,
                id: props.row_id,
                school_id: userAuth.userId,
              })
            )
           ,
            classname:
            props.status
              ? "opacity-40 flex justify-between items-center space-x-2 p-3 cursor-pointer" : "flex justify-between items-center space-x-2 p-3 cursor-pointer"
              ,
        
          name: "Add user",
        },{
          onClick:
          props.status
            ? 
            () => dispatch(
              school_remove_registered_users_start({
                token: userAuth.token,
                id: props.row_id,
                school_id: userAuth.userId,
              })
            ) : null,
            classname:
            props.status
              ? "flex justify-between items-center space-x-2 p-3 cursor-pointer" : "opacity-40 flex justify-between items-center space-x-2 p-3 cursor-pointer"
              ,
        
          name: "Remove user",
        }
      ]}
    />
  );
};

export default RegisteredActionlist;
