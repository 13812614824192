import { useDispatch, useSelector } from 'react-redux';
import { motion } from "framer-motion/dist/framer-motion"
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from "react";
import { MdOutlineNavigateNext, MdOutlineArrowBackIosNew } from 'react-icons/md'

import { ParentPagesNavList } from '../../../../elements/sidebar/auth/Navlist';
import { ParentNavSubItem } from '../../../../elements/sidebar/auth/NavSubItem';
import { TabPanel, useTabs } from 'react-headless-tabs';
import {
  changeStudentDashboardMobileVisibility,
  changeStudentDashboardtab,
  changeStudentDashboardMenu,
} from '../../../../../store/actions/sidebaractiontypes';

import Spinner from '../../../../elements/spinner';
import { student_programs_start, student_student_programs_cbc_start } from '../../../../../store/actions/student.dashboard.programs';

export const tabs = [
  {
    name: 'Extra Curricular',
    tab: 'extracurricular',
    mainPanel: 'extracurricular',
    mainPath: '/parent/marketplace/extracurricular',
    subTabs: [
      {
        name: 'DAP',
        tabName: 'dap',
        path: '/parent/marketplace/extracurricular/dap',
      },
      {
        name: 'Savoir Plus',
        tabName: 'savoirplus',
        path: '/parent/marketplace/extracurricular/savoirplus',
      },
      {
        name: 'Public speaking',
        tabName: 'publicspeaking',
        path: '/parent/marketplace/extracurricular/publicspeaking',
      },
      {
        name: 'Business',
        tabName: 'business',
        path: '/parent/marketplace/extracurricular/business',
      },
    ],
  },
  {
    name: 'Learning Resources',
    tab: 'learningresources',
    icon: '',
    mainPanel: 'learningresources',
    mainPath: '/parent/marketplace/extracurricular/learningresources',
    subTabs: [
        {
            name: 'Lorem',
            tabName: 'lorem',
            path: '/parent/marketplace/extracurricular/learningresources/lorem',
          },
    ],
  },
 
];

const SideBar = function (props) {
  const [ toggle, setToggle ] = useState(false)
  const studentdashboardMenuselected = useSelector(
    (state) => state.reducer.studentdashboardMenuselected
  );
  const [selectedTab, setSelectedTab] = useTabs(
    ['extracurricular', 'learningresources'],
    studentdashboardMenuselected
  );
  const userAth = useSelector((state) => state.auth);
  const studentDashboardPrograms = useSelector(
    (state) => state.studentDashboardPrograms
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const changetab = (tab) => {
    dispatch(changeStudentDashboardtab(tab));
  };
  const isSidebarCollapsed = useSelector(
    (state) => state.reducer.sideBarCollapse
  );

  useEffect(() => {
    dispatch(
      student_programs_start({
        token: userAth.token,
        username: userAth.username,
        userId: userAth.userId,
        day: new Date().getTime(),
      })
    );
  }, []);
  const usertype = useSelector((state)=>state.auth.user.user_type);
  
  return (
    <>
    <div className="w-1/4 relative bg-gray-100 shadow h-full flex-col justify-between flex overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100 hidden lg:block">
      <div className={`mt-10 ${isSidebarCollapsed ? '' : 'px-8'}`}>
        {(!studentDashboardPrograms.programs_loading) ? tabs.map((item, index) => (
          <ParentPagesNavList
            key={index}
            name={item.name}
            color="blue"
            isActive={selectedTab === item.tab}
            subTabs={item.subTabs}
            onClick={() => {
              dispatch(changeStudentDashboardMenu(item.tab));
              dispatch(changeStudentDashboardMobileVisibility('hidden'));
              setSelectedTab(item.tab);
              changetab(item.mainPanel);
              if (item.subTabs.length === 0) {
                history.push(item.mainPath);
              }
            }}
          >
            <TabPanel
              hidden={selectedTab !== item.tab}
              className="transform transition duration-500 ease-in-out"
            >
              {item.subTabs.map((tab, index) => (
                <ParentNavSubItem
                  key={index}
                  name={tab.name}
                  tab={tab.tabName}
                  color="blue"
                  onClick={() => {
                    changetab(tab.tabName);
                    dispatch(changeStudentDashboardMobileVisibility('hidden'));
                    history.push(tab.path); 
                  }}
                />
              ))}
            </TabPanel>
          </ParentPagesNavList>
        )) : <Spinner size="20" color="blue" />}
      </div>
    </div>
    <div className='flex justify-items-center lg:hidden'>  
    {toggle ? '' : (
    <motion.div 
    whileInView={{ x: [-20 , 0] }} 
    whileHover={{ scale: 1.1 }}
    className="w-15 h-15 absolute bg-blue rounded-r-full"> 
    <MdOutlineNavigateNext color="white" size={30} onClick={() => setToggle(true)} />
    </motion.div>
    )}
      {toggle ? (
              <motion.div 
                whileInView={{ x: [-100, 0] }}
                transition={{ duration: 0.85, ease:'easeOut'}}
                className="w-80 absolute bg-gray-100 shadow h-full flex-col justify-between flex overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100"
              >
              <motion.div 
              whileInView={{ x: [-20 , 0] }}
              whileHover={{ scale: 1.1 }}
              className="w-15 h-15 absolute bg-blue rounded-r-full"> 
              <MdOutlineArrowBackIosNew color="white" size={30} onClick={() => setToggle(false)} className="p-2"/> 
              </motion.div>
              <div className={`mt-10 ${isSidebarCollapsed ? '' : 'px-8'} h-full`}>
              {(!studentDashboardPrograms.programs_loading) ? tabs.map((item, index) => (
                <ParentPagesNavList
                  key={index}
                  name={item.name}
                  color="blue"
                  isActive={selectedTab === item.tab}
                  subTabs={item.subTabs}
                  onClick={() => {
                    dispatch(changeStudentDashboardMenu(item.tab));
                    dispatch(changeStudentDashboardMobileVisibility('hidden'));
                    setSelectedTab(item.tab);
                    changetab(item.mainPanel);
                    if (item.subTabs.length === 0) {
                      history.push(item.mainPath);
                      setToggle(false);
                    }
                  }}
                >
                  <TabPanel
                    hidden={selectedTab !== item.tab}
                    className="transform transition duration-500 ease-in-out"
                  >
                    {item.subTabs.map((tab, index) => (
                      <ParentNavSubItem
                        key={index}
                        name={tab.name}
                        tab={tab.tabName}
                        color="blue"
                        onClick={() => {
                          setToggle(false);
                          changetab(tab.tabName);
                          dispatch(changeStudentDashboardMobileVisibility('hidden'));
                          history.push(tab.path); 
                        }}
                      />
                    ))}
                  </TabPanel>
                </ParentPagesNavList>
              )) : <Spinner size="20" color="blue" /> 
              }
              </div>
              </motion.div> 
              ): ''}

             
    </div>
</>
  );
};
export default SideBar;
