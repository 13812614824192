import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs, Outline } from 'react-pdf';
import { Button, GoBackButton } from '../../../elements/button';
import Spinner from '../../../elements/spinner';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PdfForLinkedDocs = (props) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [outlines, setOutlines] = useState(null);
    const [url, setUrl] = useState(null);
    const [pageList, setPageList] = useState([]);

    function onDocumentLoadSuccess({ numPages, outline }) {
        setNumPages(numPages);
        setOutlines(outline);
        setLoading(false);
        //---
        setPageList(numPages)
    }
    const generateEmptyArray = (length) => {
        var arr = [];
        for (var i = 0; i < length; i++) {
            arr.push(0)
        }
        return arr;
    }
    useEffect(() => {
        setUrl(props.url);

    }, []);

    function handlePreviousPage() {
        setPageNumber(pageNumber - 1);
    }

    function handleNextPage() {
        setPageNumber(pageNumber + 1);
    }

    return (
        <div style={{
            position: 'fixed', // Make it stay at the front
            zIndex: 1000, // Higher index to stay on top
            top: '50%', // Adjust these to control the vertical position
            left: '50%', // Adjust these to control the horizontal position
            transform: 'translate(-50%, -50%)', // Center the div
            width: '80%', // Responsive width
            height: '400px',
            overflowY: 'auto',
            backgroundColor: '#fff', // Add a background color
            border: '1px solid #ddd', // Add a border
            boxShadow: '0px 0px 10px rgba(0,0,0,0.5)', // Optional: Add a shadow for better visibility
            padding: '20px', // Add some padding inside the div
        }}>

            {props.url && (
                <div>
                    <button
                        className="top-0 right-0 m-4 text-lg"
                        style={{ color: "red", fontSize: "30px", marginLeft: "auto", marginRight: "auto", display: "block", cursor: "pointer" }}
                        onClick={() => props.setShowVideoModal(false)}
                    >
                        &times;
                    </button>
                    {loading ? (
                        <
                            Spinner size="10" color="blue" />
                    ) : (
                        <>{outlines && (
                            <div>
                                <h2>Table of Contents</h2>
                                <Outline items={outlines} />
                            </div>
                        )}
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ flex: 3 }}>
                                    <ul className="divide-y divide-blue h-100 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100 p-3 bg-gray-200" style={{ height: "60vh", overflowY: 'scroll' }}>
                                        {generateEmptyArray(numPages).map((item, index) => {
                                            return <li onClick={() => {
                                                setPageNumber(index + 1)

                                            }} className={`py-2 cursor-pointer ${(index + 1 == pageNumber) ? "font-bold " : ""} `} key={index} style={{ color: "blue" }}><span  >Page {index + 1}</span></li>
                                        })}
                                    </ul>
                                </div>
                                <div style={{ flex: 7 }}><Document
                                    file={url}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    onLoadError={(error) => {
                                        setError(error);
                                        console.log(error)
                                        setLoading(false);
                                    }}
                                >
                                    <Page pageNumber={pageNumber} />
                                </Document></div>
                            </div>
                            <div style={{ padding: 20 }}>
                                {pageNumber > 1 && (
                                    <Button
                                        name="Previous Page"
                                        isSmall={true}
                                        outline="true"
                                        color="red"
                                        clicked={() => { handlePreviousPage() }} />
                                )}
                                {pageNumber < numPages && (
                                    <div style={{ float: "right" }}><Button
                                        name="Next Page"
                                        isSmall={true}
                                        outline="true"
                                        color="red"
                                        clicked={() => { handleNextPage() }} /></div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}

        </div>
    );
};

export default PdfForLinkedDocs;

