import { useState } from 'react';
import { useDrop } from 'react-dnd';
import { useEffect } from 'react';
const DragAndDropItem = (props) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "anwers",
    item: { id: props.name },
    drop: (item) => dropSelectedWord(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));
  const [picked, setPicked] = useState("");
  useEffect(() => {
    const newArr = [];
    let dataIn = props.dataIn;
    for (var i = 0; i < newArr.length; i++) {
      if (parseInt(newArr[i]?.question_number) === parseInt(props?.currentQuestionNumber) && parseInt(newArr[i]?.questionId) === parseInt(props.questionId) && parseInt(newArr[i]?.spaceNber) === parseInt(props.spaceNber)) {
        dataIn = newArr[i].newEntry;
      }
    }
  }, []);
  const mapUserPick = (answer_by_user, spaceNber, dataIn) => {
    for (var i = 0; i < answer_by_user.length; i++) {
      if (parseInt(answer_by_user[i][0]) === spaceNber) {
        if (dataIn.toLowerCase().trim().includes((answer_by_user[i][1].toLowerCase().trim()).trim())) {
          return answer_by_user[i][1].trim();
        } else {
          return <span style={{ textDecoration: "line-through" }}>{answer_by_user[i][1].trim()}</span>;
        }

      } else {

      }

    }
    return "";
  };

  const dropSelectedWord = (item) => {
    props.callDragAndDrop(item.id, props.currentQuestionNumber, props.questionId, props.spaceNber);
    setPicked(item.id);
  };

  return (
    <span ref={drop} className={'bg-blue text-center px-1 py-1 text-white'}>{props.dataIn} | {mapUserPick(props.answer_by_user, props.spaceNber, props.dataIn)}</span>
  );
};

export default DragAndDropItem;
