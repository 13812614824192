import React, { useState } from 'react';
import {
  CardBody,
  CardSubText,
  CardTitle,
  SmallText,
} from '../../../elements/text';
import { calculateTimeFromDateFuture } from '../../../../shared/utility';

const MessageCard = (props) => {
  // State to track if the content is expanded
  const [isExpanded, setIsExpanded] = useState(false);

  // Handlers for mouse enter and leave events
  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    setIsExpanded(false);
  };

  return (
    <div
      className="bg-white shadow p-2 my-3 grid grid-cols-12 cursor-pointer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="mx-auto col-span-2 w-16 h-16 bg-gray-200 rounded-full"></div>
      <div className="col-span-8 relative">
        <CardTitle name={props.sender} />
        <CardBody
          name={
            isExpanded ? props.content : 
            props.content.length > 100
              ? props.content.substring(0, 100) + '...'
              : props.content
          }
        />
      </div>

      <div className="col-span-2 flex flex-col justify-between items-center">
        <SmallText name={calculateTimeFromDateFuture(props.time)} color="blue" />
        {props.new && (
          <CardSubText name="New" color="yellow" additional="font-bold" />
        )}
      </div>
    </div>
  );
};

export default MessageCard;
