import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
const MatchingTypeAutoQuestionDropTargetItem = (props) => {
    const { answers } = props;
    const dispatch = useDispatch();

    useEffect(() => {

    }, []);

    return (
        <div onClick={() => {
            props.callMatchingTypeAuto(props.data, props.currentQuestionNumber, props.questionId, props.i, props.metaClean, "yes");
            props.reloadAfterDone(props.data);

        }} className='cursor-default p-2 bg-blue text-center px-1 py-1 text-white m-1 text-sm space-y-1.5'>{props.data}</div>
    );

};
export default MatchingTypeAutoQuestionDropTargetItem;
