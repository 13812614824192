const Line = (props) => {
  return (
    <div
      className={`mx-auto my-5 w-${
        props.width || '80%'
      } border border-bottom border-gray-500`}
    ></div>
  );
};

export default Line;
