import SettingsPageContent from "./pageContent";
import PageContent from "../../pageContent";
const Settings = () => {
  return (
    <PageContent>
      <SettingsPageContent />
    </PageContent>
  );
};

export default Settings;
 