import { connect } from "react-redux";
import { NavList } from "../../../elements/sidebar/Navlist";
import { store } from "../../../../store/store";
import { TabPanel } from "../../../elements/tab/tabpanel";
import { ContentPanel } from "./contentpanel";

const PageContent = (props) => {
  const { changefaqtab } = props;
  const { faqtabselected } = props;

  return (
    <div className="bg-white h-full w-full grid grid-cols-3 md:grid-cols-4">
      <div className={`col-span-3 md:col-span-1 h-full`}>
        <div className="hidden md:block w-full sm:relative bg-gray-200 shadow sm:h-full flex-col justify-between hidden sm:flex">
          <div className="px-8 h-full">
            <div className="pt-6">
              <NavList
                name="Payment Method"
                isActive={faqtabselected === "paymentmethod"}
                onClick={() => {
                  changefaqtab("paymentmethod");
                }}
              ></NavList>
              <NavList
                name="Learning Resources"
                isActive={faqtabselected === "learningresources"}
                onClick={() => {
                  changefaqtab("learningresources");
                }}
              ></NavList>
              <NavList
                name="User Registration"
                isActive={faqtabselected === "userregistration"}
                onClick={() => {
                  changefaqtab("userregistration");
                }}
              ></NavList>
              <NavList
                name="Terms of Use"
                isActive={faqtabselected === "termsofuse"}
                onClick={() => {
                  changefaqtab("termsofuse");
                }}
              ></NavList>
            </div>
            <div className="flex justify-center border-t-4 mb-4 w-full">
              <div className="relative ">
                <div className="text-gray-500 absolute ml-4 inset-0 m-auto w-4 h-4"></div>
                <input
                  className=" bg-gray-700 focus:outline-none rounded w-full text-sm text-gray-500 bg-gray-100 pl-10 py-2"
                  type="text"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={`block md:hidden w-full shadow md:h-full flex-col transition duration-150 ease-in-out`}
          id="mobile-nav"
        >
          <div className="px-8">
            <div className="pt-6">
              <NavList
                name="Payment Method"
                isActive={faqtabselected === "paymentmethod"}
                onClick={() => {
                  changefaqtab("paymentmethod");
                }}
              >
                <TabPanel panel="paymentmethod" tabselected={faqtabselected}>
                  <ContentPanel
                    title="Payment method"
                    content={[
                      {
                        subtitle: "Mobile money",
                        bodyHtml:
                          "To pay using Mobile money, Tigo cash and Airtel money<br/>1.Enter your mobile money phone number,<br/>2.Enter amount and hit submit.<br/>3.A popup comes on your phone screen prompting you to confirm the payment. if doesn't pop up, Go to pending approvals/Ibyemezo bitaranozwa on MTN, dial *182*7#.<br/>4.After you have paid successfully, you will receive an email telling you to login and get started with our services.<br/>N.B: Make sure you have your phone with you when initiating payment!<br/>No transaction fees charges, Use a phone number registered in mobile money and Make sure you have your phone with you.",
                      },
                    ]}
                  />
                </TabPanel>
              </NavList>
              <NavList
                name="Learning Resources"
                isActive={faqtabselected === "learningresources"}
                onClick={() => {
                  changefaqtab("learningresources");
                }}
              >
                <TabPanel
                  panel="learningresources"
                  tabselected={faqtabselected}
                >
                  <ContentPanel title="Learning Resources" content={[
              {
                subtitle: "CBC",
                bodyHtml:
                  "The competency-based curriculum is a curriculum that emphasizes what learners are expected to do rather than mainly focusing on what they are expected to know. O'Genius Panda 3 is designed to accomodate content from the CBC presented in various learning forms like tests & Evaluation, simulations, books, community and many more",
              },
            ]} />
                </TabPanel>
              </NavList>
              <NavList
                name="User Registration"
                isActive={faqtabselected === "userregistration"}
                onClick={() => {
                  changefaqtab("userregistration");
                }}
              >
                <TabPanel panel="userregistration" tabselected={faqtabselected}>
                  <ContentPanel
                    title="User Registration"
                    content={[
                      {
                        subtitle: "First time",
                        bodyHtml:
                          "Go to the enroll page. 1. Fill the sign up form. You may also use your Gmail or Facebook account, 2. Enter the verification code sent to your email and phone number, 3. Choose and confirm payment package, 4. Choose your payment method and carry out the payment as intructed.",
                      },
                      {
                        subtitle: "Returning user",
                        bodyHtml:
                          "Go to the Login page. 1. Enter your email and password and click on login, 2. You may use the Gmail or Facebook account to login. 3. If you did not logout in the last session, click on your profile picture at the top-right and choose profile/Dashboard to enter your account",
                      },
                      {
                        subtitle: "Forgot password",
                        bodyHtml:
                          "Enter the email or phone number associated with your account and we will send the password recovery instruction to you. If you don't have phone with you check the box that asks to receive recovery password on alternate number",
                      },
                    ]}
                  />
                </TabPanel>
              </NavList>
              <NavList
                name="Terms of Use"
                isActive={faqtabselected === "termsofuse"}
                onClick={() => {
                  changefaqtab("termsofuse");
                }}
              >
                <TabPanel panel="termsofuse" tabselected={faqtabselected}>
                  <ContentPanel title="Terms of Use" content={[{subtitle: "Privacy policy", bodyHtml: "Check this link to read our privacy policy (https://www.opanda.rw/privacypolicy.php)"},{subtitle: "Terms of use", bodyHtml: "Check this link to read our terms of use (https://www.opanda.rw/privacypolicy.php)"}]}/>
                </TabPanel>
              </NavList>
            </div>
            <div className="flex justify-center mt-48 mb-4 w-full">
              <div className="relative ">
                <div className="text-gray-500 absolute ml-4 inset-0 m-auto w-4 h-4"></div>
                <input
                  className=" bg-gray-700 focus:outline-none rounded w-full text-sm text-gray-500 bg-gray-100 pl-10 py-2"
                  type="text"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden md:block bg-white col-span-3 md:col-span-3 py-10 px-6">
        <TabPanel panel="termsofuse" tabselected={faqtabselected}>
          <ContentPanel title="Terms of Use" content={[{subtitle: "Privacy policy", bodyHtml: "Check this link to read our privacy policy (https://www.opanda.rw/privacypolicy.php)"},{subtitle: "Terms of use", bodyHtml: "Check this link to read our terms of use (https://www.opanda.rw/privacypolicy.php)"}]} />
        </TabPanel>
        <TabPanel panel="userregistration" tabselected={faqtabselected}>
          <ContentPanel
            title="User Registration"
            content={[
              {
                subtitle: "First time",
                bodyHtml:
                  "Go to the enroll page. 1. Fill the sign up form. You may also use your Gmail or Facebook account, 2. Enter the verification code sent to your email and phone number, 3. Choose and confirm payment package, 4. Choose your payment method and carry out the payment as intructed.",
              },
              {
                subtitle: "Returning user",
                bodyHtml:
                  "Go to the Login page. 1. Enter your email and password and click on login, 2. You may use the Gmail or Facebook account to login. 3. If you did not logout in the last session, click on your profile picture at the top-right and choose profile/Dashboard to enter your account",
              },
              {
                subtitle: "Forgot password",
                bodyHtml:
                  "Enter the email or phone number associated with your account and we will send the password recovery instruction to you. If you don't have phone with you check the box that asks to receive recovery password on alternate number",
              },
            ]}
          />
        </TabPanel>
        <TabPanel panel="paymentmethod" tabselected={faqtabselected}>
          <ContentPanel
            title="Payment method"
            content={[
              {
                subtitle: "Mobile money",
                bodyHtml:
                  "To pay using Mobile money, Tigo cash and Airtel money: 1.Enter your mobile money phone number, 2.Enter amount and hit submit. 3. A popup comes on your phone screen prompting you to confirm the payment. if doesn't pop up, Go to pending approvals/Ibyemezo bitaranozwa on MTN, dial *182*7#. 4.After you have paid successfully, you will receive an email telling you to login and get started with our services. N.B: Make sure you have your phone with you when initiating payment! No transaction fees charges, Use a phone number registered in mobile money and Make sure you have your phone with you.",
              },
            ]}
          />
        </TabPanel>
        <TabPanel panel="deliverymethod" tabselected={faqtabselected}>
          <ContentPanel title="Delivery method" content={[]} />
        </TabPanel>
        <TabPanel panel="learningresources" tabselected={faqtabselected}>
          <ContentPanel
            title="Learning Resources"
            content={[
              {
                subtitle: "CBC",
                bodyHtml:
                  "The competency-based curriculum is a curriculum that emphasizes what learners are expected to do rather than mainly focusing on what they are expected to know. O'Genius Panda 3 is designed to accomodate content from the CBC presented in various learning forms like tests & Evaluation, simulations, books, community and many more",
              },
            ]}
          />
        </TabPanel>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    visibility: store.getState().reducer.mobilesidebar,
    tabselected: store.getState().reducer.tabselected,
    faqtabselected: store.getState().reducer.faqtabselected,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    showsidebar: () => store.dispatch({ type: "SHOW_SIDEBAR" }),
    hidesidebar: () => store.dispatch({ type: "HIDE_SIDEBAR" }),
    changetab: (tab) =>
      store.dispatch({ type: "CHANGE_FAQ_TAB", payload: tab }),
    changefaqtab: (tab) =>
      store.dispatch({ type: "CHANGE_FAQ_TAB", payload: tab }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PageContent);
