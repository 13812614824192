import Layout from '../../../components/common/Layout';
import Tests from '../../../components/pages/teacher/test/Tests';

const TestsContainer = () => {
  return (
    <Layout>
      <Tests />
    </Layout>
  );
};

export default TestsContainer;
