import { updateObject } from '../../../shared/utility';
import { ADD_PAGE_TO_COURSE, 
  CHANGE_CHOSEN_UNIT_SUMMARY_NOTES, 
  CHANGE_SUMMARY_NOTES_SUBJECT,
  SET_CURRENT_COURSE_PAGE,SET_COURSE_PAGE_INIT } from '../../actions/features/summarynotes';

const initialReducer = {
  chosenUnit: null,
  chosenSubject:null,
  currentPage:{},
  pages:[],
};

const changeChosenUnit = (state, action) => {
  return updateObject(state, {
    chosenUnit: action.payload,
  });
};

const changeChosenSubject = (state, action) => {
  return updateObject(state, {
    chosenSubject: action.payload,
  });
};  

const setCurrentCoursePage = (state, action) => {
  return updateObject(state, {
    currentPage: action.payload,
  });
};

const addPageToCourse = (state, action) => {
  return updateObject(state, {
    pages: state.pages.concat(action.payload),
  });
}; 

const setCoursePageInit = (state, action)=>{
  return updateObject(state, {
    pages: [],
  });
}

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case CHANGE_CHOSEN_UNIT_SUMMARY_NOTES:
      return changeChosenUnit(state, action);
    case CHANGE_SUMMARY_NOTES_SUBJECT:
      return changeChosenSubject(state, action);
    case ADD_PAGE_TO_COURSE:
      return addPageToCourse(state, action);
    case SET_CURRENT_COURSE_PAGE:
      return setCurrentCoursePage(state, action);
    case SET_COURSE_PAGE_INIT:
      return setCoursePageInit(state, action);
    default:
      return state;
  }
};

export default reducer;
