import { updateObject } from "../../shared/utility";
import {
  STUDENT_DASHBOARD_NOTIFICATION_COUNT_UPDATES_INIT,
  STUDENT_DASHBOARD_NOTIFICATION_COUNT_UPDATES_SUCCESS,
  STUDENT_DASHBOARD_NOTIFICATION_COUNT_UPDATES_FAILED,
} from "../actions/notificationsCountUpdates";
const initialReducer = {
  notificationsCount: 0,
  loading: false
};

function student_dashboard_notification_count_init(state, action) {
  return updateObject(state, {
    notificationsCount: 0,
    loading: true
  });
}
function student_dashboard_notification_count_success(state, action) {
  return updateObject(state, {
    notificationsCount: action.payload,
    loading: false
  });
}
function student_dashboard_notification_count_fail(state, action) {
  return updateObject(state, {
    message: action.payload,
    loading: false
  });
}

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case STUDENT_DASHBOARD_NOTIFICATION_COUNT_UPDATES_INIT:
      return student_dashboard_notification_count_init(state, action);
    case STUDENT_DASHBOARD_NOTIFICATION_COUNT_UPDATES_SUCCESS:
      return student_dashboard_notification_count_success(state, action);
    case STUDENT_DASHBOARD_NOTIFICATION_COUNT_UPDATES_FAILED:
      return student_dashboard_notification_count_fail(state, action);
    default:
      return state;
  }
};
export default reducer;
