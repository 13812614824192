import { updateObject } from "../../shared/utility";
import {
  SCHOOL_DASHBOARD_USERS_COUNT_INIT,
  SCHOOL_DASHBOARD_USERS_COUNT_SUCCESS,
  SCHOOL_DASHBOARD_USERS_COUNT_FAILED,
  SCHOOL_DASHBOARD_DOWNLOAD_TEMPLATE_INIT,
  SCHOOL_DASHBOARD_DOWNLOAD_TEMPLATE_SUCCESS,
  SCHOOL_DASHBOARD_DOWNLOAD_TEMPLATE_FAILED,
  SCHOOL_DASHBOARD_USERS_INIT,
  SCHOOL_DASHBOARD_USERS_SUCCESS,
  SCHOOL_DASHBOARD_USERS_FAILED,
  SCHOOL_DASHBOARD_USERS_RESET_OTP_INIT,
  SCHOOL_DASHBOARD_USERS_RESET_OTP_SUCCESS,
  SCHOOL_DASHBOARD_USERS_RESET_OTP_FAILED,
  SCHOOL_DASHBOARD_USERS_DELETE_INIT,
  SCHOOL_DASHBOARD_USERS_DELETE_SUCCESS,
  SCHOOL_DASHBOARD_USERS_DELETE_FAILED,
  SCHOOL_USERS_LASTWEEK_SESSIONDURATION_INIT,
  SCHOOL_USERS_LASTWEEK_SESSIONDURATION_SUCCESS,
  SCHOOL_USERS_LASTWEEK_SESSIONDURATION_FAILED,
  SCHOOL_DASHBOARD_CREATE_USER_INIT,
  SCHOOL_DASHBOARD_CREATE_USER_SUCCESS,
  SCHOOL_DASHBOARD_CREATE_USER_FAILED

} from "../actions/school.dashboard.users";
//--
const initialReducer = {
  school_dashboard_users_count: [
    { user_type: "student", number: 0 },
    { user_type: "teacher", number: 0 },
    { user_type: "parent", number: 0 },
  ],
  school_dashboard_users_count_loading: true,
  school_dashboard_users: [],
  school_dashboard_users_loading: true,
  school_user_data_loading: true,
  school_user_session_duration:[],
  message: "",
};

function school_dashboard_users_count_init(state, action) {
  return updateObject(state, {
    school_dashboard_users_count_loading: true,
  });
}
function school_dashboard_users_count_success(state, action) {
  return updateObject(state, {
    school_dashboard_users_count: action.payload,
    school_dashboard_users_count_loading: false,
    message: "success",
  });
}
function school_dashboard_users_count_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    school_dashboard_users_count_loading: false,
  });
}

function school_dashboard_download_template_init(state, action) {
  return updateObject(state, {
    school_dashboard_download_template_loading: true,
  });
}
function school_dashboard_download_template_success(state, action) {
  return updateObject(state, {
    school_dashboard_download_template: action.payload,
    school_dashboard_download_template_loading: false,
    message: "success",
  });
}
function school_dashboard_download_template_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    school_dashboard_download_template_loading: false,
  });
}

function school_dashboard_users_init(state, action) {
  return updateObject(state, {
    school_dashboard_users_loading: true,
  });
}
function school_dashboard_users_success(state, action) {
  return updateObject(state, {
    school_dashboard_users: action.payload,
    school_dashboard_users_loading: false,
    message: "success",
  });
}
function school_dashboard_users_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    school_dashboard_users_loading: false,
  });
}

function school_dashboard_users_reset_otp_init(state, action) {
  return updateObject(state, {
    school_dashboard_users_loading: true,
  });
}
function school_dashboard_users_reset_otp_success(state, action) {
  return updateObject(state, {
    school_dashboard_users_loading: false,
    message: "success",
  });
}
function school_dashboard_users_reset_otp_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    school_dashboard_users_loading: false,
  });
}


function school_dashboard_users_delete_init(state, action) {
  return updateObject(state, {
    school_dashboard_users_loading: true,
  });
}
function school_dashboard_users_delete_success(state, action) {
  return updateObject(state, {
    school_dashboard_users_loading: false,
    message: "success",
  });
}
function school_dashboard_users_delete_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    school_dashboard_users_loading: false,
  });
}


function school_users_lastweek_sessionduration_init(state, action) {
  return updateObject(state, {
    school_dashboard_users_loading: true,
  });
}
function school_users_lastweek_sessionduration_success(state, action) {
  return updateObject(state, {
    school_user_data_loading: false,
    school_user_session_duration: action.payload,
    message: "success",
  });
}
function school_users_lastweek_sessionduration_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    school_user_data_loading: false,
  });
}



function school_dashboard_create_user_init(state, action) {
  return updateObject(state, {
    school_dashboard_users_loading: true,
  });
}
function school_dashboard_create_user_success(state, action) {
  return updateObject(state, {
    school_dashboard_users_loading: false,
    message: "success",
  });
}
function school_dashboard_create_user_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    school_dashboard_users_loading: false,
  });
}

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case SCHOOL_DASHBOARD_USERS_COUNT_INIT:
      return school_dashboard_users_count_init(state, action);
    case SCHOOL_DASHBOARD_USERS_COUNT_SUCCESS:
      return school_dashboard_users_count_success(state, action);
    case SCHOOL_DASHBOARD_USERS_COUNT_FAILED:
      return school_dashboard_users_count_failed(state, action);

    case SCHOOL_DASHBOARD_DOWNLOAD_TEMPLATE_INIT:
      return school_dashboard_download_template_init(state, action);
    case SCHOOL_DASHBOARD_DOWNLOAD_TEMPLATE_SUCCESS:
      return school_dashboard_download_template_success(state, action);
    case SCHOOL_DASHBOARD_DOWNLOAD_TEMPLATE_FAILED:
      return school_dashboard_download_template_failed(state, action);

    case SCHOOL_DASHBOARD_USERS_INIT:
      return school_dashboard_users_init(state, action);
    case SCHOOL_DASHBOARD_USERS_SUCCESS:
      return school_dashboard_users_success(state, action);
    case SCHOOL_DASHBOARD_USERS_FAILED:
      return school_dashboard_users_failed(state, action);

      case SCHOOL_DASHBOARD_USERS_RESET_OTP_INIT:
      return school_dashboard_users_reset_otp_init(state, action);
    case SCHOOL_DASHBOARD_USERS_RESET_OTP_SUCCESS:
      return school_dashboard_users_reset_otp_success(state, action);
    case SCHOOL_DASHBOARD_USERS_RESET_OTP_FAILED:
      return school_dashboard_users_reset_otp_failed(state, action);  


      case SCHOOL_DASHBOARD_USERS_DELETE_INIT:
        return school_dashboard_users_delete_init(state, action);
      case SCHOOL_DASHBOARD_USERS_DELETE_SUCCESS:
        return school_dashboard_users_delete_success(state, action);
      case SCHOOL_DASHBOARD_USERS_DELETE_FAILED:
        return school_dashboard_users_delete_failed(state, action); 
        
        case SCHOOL_USERS_LASTWEEK_SESSIONDURATION_INIT:
          return school_users_lastweek_sessionduration_init(state, action);
        case SCHOOL_USERS_LASTWEEK_SESSIONDURATION_SUCCESS:
          return school_users_lastweek_sessionduration_success(state, action);
        case SCHOOL_USERS_LASTWEEK_SESSIONDURATION_FAILED:
          return school_users_lastweek_sessionduration_failed(state, action);   

          case SCHOOL_DASHBOARD_CREATE_USER_INIT:
            return school_dashboard_create_user_init(state, action);
          case SCHOOL_DASHBOARD_CREATE_USER_SUCCESS:
            return school_dashboard_create_user_success(state, action);
          case SCHOOL_DASHBOARD_CREATE_USER_FAILED:
            return school_dashboard_create_user_failed(state, action); 
           
    default:
      return state;
  }
};
export default reducer;
