import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";

export const getQuestionsStart = () => {
  return {
    type: actionTypes.GET_MULTIPLE_CHOICE_START,
  };
};

export const getQuestionsSuccess = (questions) => {
  return {
    type: actionTypes.GET_MULTIPLE_CHOICE_SUCCESS,
    questions: questions,
  };
};

export const getOneQuestionsSuccess = (onequestion) => {
  return {
    type: actionTypes.GET_ONE_MULTIPLE_CHOICE_SUCCESS,
    onequestion: onequestion,
  };
};

export const getQuestionsFail = (error) => {
  return {
    type: actionTypes.GET_MULTIPLE_CHOICE_FAIL,
    error: error,
  };
};

export const getQuestions = (token) => {
  return (dispatch) => {
    dispatch(getQuestionsStart());
    axios
      .get(`/opanda/panda-test-questions`, null, {
        headers:{authorization:token}
      })
      .then((response) => {
        dispatch(getQuestionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getQuestionsFail(err.message));
      });
  };
};

export const registerAutoQuestions = (object, token) => {
  return (dispatch) => {
    dispatch(getQuestionsStart());
    axios
      .post(`/opanda/panda-test-questions/register-multiple-choice`, object, {
        headers:{authorization:token}
      })
      .then((response) => {
        dispatch(getQuestionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getQuestionsFail(err.message));
      });
  };
};
