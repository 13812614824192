import { CgMoreVertical } from "react-icons/cg";

const Actionlist = (props) => {
  let listcomponent = props.list.map((value, key) => {
    return (
      <li key={key} className={value.classname} onClick={value.onClick}>
        {value.name}
      </li>
    );
  });
  return (
    <>
      <div
        onClick={props.onClick}
        additional="md:flex relative space-x-2 justify-center items-center cursor-pointer"
      >
        <CgMoreVertical color="blue" className="cursor-pointer" />
        <div
          className={`${
            props.showinglist === props.k
              ? "absolute z-50 left-11 md:left-8 bg-gray-200 text-main-color drop-shadow-lg"
              : "hidden"
          }`}
        >
          <ul className="divide-y divide-blue">{listcomponent}</ul>
        </div>
      </div>
    </>
  );
};

export default Actionlist;
