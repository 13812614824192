const YoutubeEmbed = (props) => {
  return (
    <div className="video-responsive relative overflow-hidden h-0 pb-1/2">
      <iframe
        width="560"
        height="315"
        className="h-full w-full absolute left-0 top-0"
        src={`https://www.youtube.com/embed/${props.embedId}`}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default YoutubeEmbed;
