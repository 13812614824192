import StudentIcon from "../../../images/icons/students.svg";
import TeacherIcon from "../../../images/icons/teachers.svg";
import ParentIcon from "../../../images/icons/parents.svg";
import SchoolIcon from "../../../images/icons/schools.svg";
import CreatorIcon from "../../../images/icons/creators.svg";
import { CardTitle } from "../text";

export function TabIcon({ isActive, children, redirecttabs }) {
  let imgsrc = "";
  let classes = "";
  const href = "#" + redirecttabs ?? "#";
  const changeTab = () => {};

  if (children === "Student") {
    classes =
      "bg-green rounded-full md:rounded-2xl md:w-2/3 p-8 mx-auto aspect-w-1 aspect-h-1";
    imgsrc = StudentIcon;
  } else if (children === "Teacher") {
    classes =
      "bg-yellow rounded-full md:rounded-2xl  md:w-2/3 p-8  mx-auto aspect-w-1 aspect-h-1";
    imgsrc = TeacherIcon;
  } else if (children === "Parent") {
    classes =
      "bg-gray-500 rounded-full md:rounded-2xl  md:w-2/3 p-8  mx-auto aspect-w-1 aspect-h-1";
    imgsrc = ParentIcon;
  } else if (children === "School") {
    classes =
      "bg-blue rounded-full md:rounded-2xl  md:w-2/3 p-8  mx-auto aspect-w-1 aspect-h-1";
    imgsrc = SchoolIcon;
  } else if (children === "Creator") {
    classes =
      "bg-red rounded-full md:rounded-2xl  md:w-2/3 p-8  mx-auto aspect-w-1 aspect-h-1";
    imgsrc = CreatorIcon;
  }

  return (
    <a
      href={href}
      className={`block rounded-md  cursor-pointer`}
      onClick={changeTab}
    >
      <div
        className={`flex flex-col  ${
          isActive ? "box-border border-b-4 border-blue" : ""
        } align-center justify-center md:mx-4 space-y-2`}
      >
        <div className={classes}>
          <img
            alt={children}
            className="h-24px md:h-36px lg:h-48px p-2 w-auto m-auto"
            src={imgsrc}
          />
        </div>
        <CardTitle name={children} alignment="center" color="blue" />
      </div>
    </a>
  );
}
