import { Button } from "../../../elements/button";
import { CardTitle, SectionTitle } from "../../../elements/text";
import { ImLab } from "react-icons/im";
import { BiBook } from "react-icons/bi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BsCardList } from "react-icons/bs";
import { useEffect, useState } from "react";
import axios from "../../../../axios-base";
import Item from "./item";
import Input from "../../../elements/input";
const SuggestClass = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchDataSuggestedFeatures = async () => {
      setLoading(true);
      try {
        const { data: response } = await axios.get(
          "/student/dashboard/homepage/proposedfeaturesorder"
        );
        console.log(response);
        setData(response);
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };

    fetchDataSuggestedFeatures();
  }, []);

  const features = [
    {
      name: "Prepare course",
      Icon: <BiBook color="#183F71" size="30" />,
      link: "/link",
    },

    {
      name: "Prepare a test",
      Icon: <BsCardList color="#183F71" size="30" />,
      link: "/link",
    },
  ];
  return (
    <>
      <div className="flex flex-row align-center justify-between">
        <SectionTitle name="Activities" color="blue" alignment="left" />
        <div>
          <Input
            label=""
            elementType="select"
            elementConfig={{
              startingValue: "SELECT",
              optionsType: "minimal",
              options: props.classes,
            }}
            value={props.classes}
            changed={props.setactiveClass}
            validation={{ required: false }}
          />
        </div>
      </div>
      <div className="text-center">
        <div className="md:mx-4">
          <SectionTitle
            name="You do not have any activities yet"
            color="blue"
            alignment="center"
          />
          <CardTitle name="Here's a few things you can start with" />
          <div className="flex flex-wrap justify-center items-center">
            {features.map((feature, index) => (
              <Item key={index} {...feature}>
                {feature.Icon}
              </Item>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SuggestClass;
