const giveNameRespect = (name: string) => {
  var string = name;
  if (string !== undefined || string !== null) {
    return string[0]?.toUpperCase() + string?.slice(1);
  } else {
    return "";
  }
};

export default giveNameRespect;
