import { SectionTitle } from '../../../elements/text';

const TabSelector = (props) => {
  return (
    <button
      className={`group inline-flex space-x-1 items-center justify-center px-1 py-2 border-b-2 font-medium text-sm leading-5 cursor-pointer whitespace-nowrap ${
        props.isActive
          ? 'border-yellow focus:outline-none focus:border-yellow'
          : 'border-transparent hover:border-yellow focus:border-yellow'
      }`}
      onClick={props.onClick}
    >
      <SectionTitle
        name={props.title}
        color={props.isActive ? 'blue' : 'gray-blue'}
      />
    </button>
  );
};

export default TabSelector;
