import { CardTitle } from "../text";

export function NavList({ isActive, children, onClick, name }) {
  return (
    <li className="flex flex-col group relative  w-full justify-between cursor-pointer items-center mb-4">
      <button className="w-full flex items-start justify-between" onClick={onClick}>
        <div className="flex items-center">
          <CardTitle name={name} color={`${isActive ? 'red':'blue'}`} alignment="left"/>
        </div>
        <div
          className={`py-1 px-3 rounded flex items-center justify-center text-xs  ${isActive ? 'focus:rotate-45':'focus:rotate-90'}`}
        >
          <svg className={`transform transition duration-500 ease-in-out ${isActive ? 'rotate-180':'rotate-0'}`}
            xmlns="http://www.w3.org/2000/svg"
            width="12.458"
            height="7.693"
            viewBox="0 0 12.458 7.693"
          >
            <path
              id="Path_388"
              data-name="Path 388"
              d="M16.994,8.59l-4.765,4.755L7.464,8.59,6,10.054l6.229,6.229,6.229-6.229Z"
              transform="translate(-6 -8.59)"
              fill="#173f71"
            />
          </svg>
        </div>
      </button>
      <ul className={`mt-2 py-2 md:w-48`}>{children}</ul>
    </li>
  );
}
