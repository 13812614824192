import Layout from '../../../components/common/Layout';
import NewTest from '../../../components/pages/teacher/test/NewTest';

const NewTestContainer = () => {
  return (
    <Layout>
      <NewTest />
    </Layout>
  );
};

export default NewTestContainer;
