import React from "react";
import { TextButton } from "../button";
import Input from "../input";
import { useEffect } from 'react';
import axios from "../../../axios-base";
import { useState } from "react";
import { useSelector } from 'react-redux';
const Tbodyv1WrappedCheckBox = (props) => {
  const [checked, setChecked] = React.useState(false);
  //---
  const userAuth = useSelector((state) => state?.auth)
  const [notifType, setNotifType] = React.useState("");
  const [notifTypeCheckboxInApp, setNotifTypeCheckboxInApp] = React.useState(false);
  const [notifTypeCheckboxEmail, setNotifTypeCheckboxEmail] = React.useState(false);


  useEffect(() => {
    axios
      .post(`/opanda/panda-users/findNotificationsMap`, { userId: userAuth.user.id }, {
        headers: {
          authorization: `Bearer ${userAuth.token}`
        }
      })
      .then((response) => {
        if (response.data.results) {
          //console.log("#dataIn", response.data.results)
          if (props.thead[0] === "Global setting") {
            if (props.data[0] == "td2") {
              setNotifType(`${props.thead[0]}--${props.thead[1]}--${props.thead[2]}--in_app`);
              setNotifTypeCheckboxInApp((response.data.results.in_app_globalsettings === 1) ? true : false)
            } else {
              setNotifType(`${props.thead[0]}--${props.thead[1]}--${props.thead[2]}--email`);
              setNotifTypeCheckboxEmail((response.data.results.email_globalsettings === 1) ? true : false);
            }
          } else {
            if (props.data[0] == "td2") {
              setNotifType(`${props.thead[0]}--${props.thead[1]}--${props.thead[2]}--in_app`);
              setNotifTypeCheckboxInApp((response.data.results.in_app_forumsettings === 1) ? true : false)
            } else {
              setNotifType(`${props.thead[0]}--${props.thead[1]}--${props.thead[2]}--email`);
              setNotifTypeCheckboxEmail((response.data.results.email_forumsettings === 1) ? true : false);
            }
          }

        } else {
          //console.log("#NodataIn", 1000)
          if (props.data[0] == "td2") {
            setNotifType(`${props.thead[0]}--${props.thead[1]}--${props.thead[2]}--in_app`);
            setNotifTypeCheckboxInApp(false)
          } else {
            setNotifType(`${props.thead[0]}--${props.thead[1]}--${props.thead[2]}--email`);
            setNotifTypeCheckboxEmail(false);
          }
        }
      })
      .catch((err) => {

      });

    //--
  }, []);

  const checkboxValuePick = (val) => {
    //console.log("#@##78", val)
    if (props.data[0] == "td2") {
      axios
        .post(`/opanda/panda-users/updateNotificationsMap`, {
          userId: userAuth.user.id,
          notifType: notifType, notifTypeCheckboxInApp: !notifTypeCheckboxInApp
        }, {
          headers: {
            authorization: `Bearer ${userAuth.token}`
          }
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.results) {
            setNotifTypeCheckboxInApp(!notifTypeCheckboxInApp)

          }
        })
        .catch((err) => {

        });


    } else {

      axios
        .post(`/opanda/panda-users/updateNotificationsMap`, {
          userId: userAuth.user.id,
          notifType: notifType, notifTypeCheckboxInApp: !notifTypeCheckboxEmail
        }, {
          headers: {
            authorization: `Bearer ${userAuth.token}`
          }
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.results) {
            setNotifTypeCheckboxEmail(!notifTypeCheckboxEmail);
          }
        })
        .catch((err) => {

        });



    }
  }

  return (
    <>
      <Input
        elementType="checkbox"
        value={((props.data[0] == "td2")) ? notifTypeCheckboxInApp : notifTypeCheckboxEmail}
        elementConfig={{
          type: "email",
          placeholder: "Email address",
        }}
        changed={setChecked}
        checkboxValuePick={checkboxValuePick}
        validation={{ required: false }}
        onChange={(val) => { }}
      />
    </>
  );
};

export default Tbodyv1WrappedCheckBox;
