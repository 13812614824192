import { useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import PageContent from "../pageContent";
import { CardBody, FeatureTitle, SectionTitle } from "../../../elements/text";
import Input from "../../../elements/input";
import GetStarted from "../../../common/features/GetStarted";
import NotFound from "../../../common/features/NotFound";
import { FeatureItemCard } from "../../../elements/card";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { changeChosenUnitSimulations } from "../../../../store/actions/features/simulations";
import { GrClose } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";
import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import {
  student_dashboard_homepage_simulations_get_subjects_with_select_units_start,
  student_dashboard_homepage_simulations_get_subjects_with_select_units_start_with_subject_param,
} from "../../../../store/actions/student.dashboard.simulations";
import { sortAlphaNum } from "../../../../functions/sortAlphaNumeric";
import Spinner from "../../../elements/spinner";
import { crypt } from "../../../../functions/cyphers";

const Simulations = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchterm, setSearchterm] = useState("");
  const userAuth = useSelector((state) => state.auth);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const courses_params = useParams();
  const [academic_level_pool, setAcademic_level_pool] = useState([
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
  ]);
  const [showUnits, setShowUnits] = useState(false);
  const [subject, setSubject] = useState("BIOLOGY");
  const [selectedLevel, setSelectedLevel] = useState(1);
  //-----
  const studentDashboardSimulations = useSelector(
    (state) => state.studentDashboardSimulations
  );
  useEffect(() => {
    if (params.subject && params.unitname) {
      setSubject(params.subject);
      dispatch(
        student_dashboard_homepage_simulations_get_subjects_with_select_units_start_with_subject_param(
          {
            token: userAuth.token,
            academic_level: selectedLevel,
            user_id: userAuth.userId,
            Subject: params.subject,
          }
        )
      );
      setSelectedUnit(params.unitname);
      dispatch(changeChosenUnitSimulations(params.unitname));
    } else {
      dispatch(
        student_dashboard_homepage_simulations_get_subjects_with_select_units_start(
          {
            token: userAuth.token,
            academic_level: selectedLevel,
            user_id: userAuth.userId,
          }
        )
      );
    }
  }, [selectedLevel]);
  const getFormattedSimulationsData = (sims) => {
    const newSims = sims.map((item) => {
      return {
        id: item.simid,
        title: item.name,
        imgsrc:
          process.env.REACT_APP_BACKEND_URL +
          "/simulation_thumbnails/" +
          item.thumbnail,
        createdAt: item.uploaded_at,
        url: !item.link.startsWith("htt")
          ? process.env.REACT_APP_BACKEND_URL +
          (item.link.startsWith("/") ? item.link : "/" + item.link)
          : item.link,
      };
    });

    return newSims;
  };
  const getUnitsFromSelectSubject = (unitsData) => {
    const units = [];
    if (unitsData?.data?.results.length > 0) {
      const data = unitsData?.data?.results[1];
      for (let unitdata in data) {
        const unitdata_ = data[unitdata];
        units.push({
          name: unitdata_.name,
          subject: unitdata_.subject,
          viewed: unitdata_.viewed,
          simulations: [...getFormattedSimulationsData(unitdata_.simulations)],
        });
      }
      return units;
    } else {
      return units;
    }
  };
  const getSubjects = (unitsData) => {
    const subjects = [];
    if (unitsData?.data?.results.length > 0) {
      const data = unitsData?.data?.results[0];
      for (let unitdata in data) {
        subjects.push({
          value: data[unitdata].subject,
          displayValue: data[unitdata].subject,
        });
      }
      return subjects;
    } else {
      return subjects;
    }
  };

  const tabs = getUnitsFromSelectSubject(
    studentDashboardSimulations.student_dashboard_homepage_simulations_get_subjects_with_select_units
  ).map((unit) => unit.name);
  const chosenUnit = useSelector(
    (state) => state.features.simulations.chosenUnit
  );
  tabs.unshift("initial page");
  const [selectedUnit, setSelectedUnit] = useTabs(
    tabs,
    chosenUnit ? chosenUnit : "initial page"
  );
  const chooseAnotherSubject = (data) => {
    dispatch(
      student_dashboard_homepage_simulations_get_subjects_with_select_units_start_with_subject_param(
        {
          token: userAuth.token,
          academic_level: selectedLevel,
          user_id: userAuth.userId,
          Subject: data,
        }
      )
    );
  };

  return (
    <PageContent>
      <div className={`md:grid grid-cols-12 w-full h-full`}>
        <div
          className={`px-4 pb-8 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100  ${showUnits ? "h-screen-sec" : ""
            } md:h-full col-span-3`}
        >
          <div className="flex justify-between items-center">
            <div className="">
              {!studentDashboardSimulations.student_dashboard_homepage_simulations_get_subjects_with_select_units_loading ? (
                <Input
                  elementType="select"
                  label="Choose Subject"
                  elementConfig={{
                    startingValue: "SELECT",
                    options: [
                      ...getSubjects(
                        studentDashboardSimulations.student_dashboard_homepage_simulations_get_subjects_with_select_units
                      ),
                    ],
                  }}
                  value={subject}
                  chooseAnotherSubject={chooseAnotherSubject}
                  changed={setSubject}
                  validation={{ required: true }}
                  shouldValidate
                  error="Subject is required"
                />
              ) : (
                <Spinner size="20" color="blue" />
              )}
            </div>
            {/* <CardBody
              name={`Senior ${academic_level_pool[parseInt(userAuth.academic_level) - 1]
                }`}
              color="blue"
            /> */}
          </div>
          <div><Input
            label="Choose class"
            elementType="select"
            elementConfig={{
              startingValue: "SELECT",
              // disabled: "disabled",
              options: [
                { value: "1", displayValue: "Senior 1" },
                { value: "2", displayValue: "Senior 2" },
                { value: "3", displayValue: "Senior 3" },
                { value: "4", displayValue: "Senior 4" },
                { value: "5", displayValue: "Senior 5" },
                { value: "6", displayValue: "Senior 6" },
              ],
            }}
            value={selectedLevel}
            changed={setSelectedLevel}
            validation={{ required: true }}
            shouldValidate
            error="Level is required"
          />
          </div>
          <div
            className={`${showUnits ? "" : "hidden"
              } md:block`}
          >
            <Input
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Search...",
              }}
              value={searchterm}
              changed={setSearchterm}
            />
            <ul className="py-5 h-100">
              {!studentDashboardSimulations.student_dashboard_homepage_simulations_get_subjects_with_select_units_loading ? (
                getUnitsFromSelectSubject(
                  studentDashboardSimulations.student_dashboard_homepage_simulations_get_subjects_with_select_units
                )
                  .filter(
                    (item) =>
                      item.name
                        .toLowerCase()
                        .includes(searchterm.toLowerCase()) || searchterm === ""
                  )
                  .sort(sortAlphaNum)
                  .map((unit, index) => (
                    <li
                      key={index}
                      className="flex items-center justify-between py-2 cursor-pointer"
                      onClick={() => {
                        setSelectedUnit(unit.name);
                        dispatch(changeChosenUnitSimulations(unit.name));
                        setShowUnits(false);
                      }}
                    >
                      <CardBody
                        name={`${unit.name}`}
                        color="blue"
                        additional={
                          unit.name === selectedUnit
                            ? "font-bold flex-1"
                            : "flex-1"
                        }
                      />
                      {unit.viewed ? (
                        <FaLockOpen
                          color="#183F71"
                          className="shrink-0 w-16px h-16px"
                        />
                      ) : (
                        <FaLock
                          color="#183F71"
                          className="shrink-0 w-16px h-16px"
                        />
                      )}
                    </li>
                  ))
              ) : (
                <Spinner size="20" color="blue" />
              )}
            </ul>
          </div>
        </div>
        <div
          className={`${showUnits ? "hidden md:block" : ""
            }  md:relative p-3 bg-white md:h-full max-h-myscreen-sec min-h-myscreen-sec-mobile md:max-h-myscreen md:min-h-myscreen col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
        >
          <TabPanel
            hidden={selectedUnit !== "initial page"}
            className="transform transition duration-500 ease-in-out"
          >
            <FeatureTitle name="Simulations" />
            <GetStarted type="a unit" feature="simulations" />
          </TabPanel>
          {getUnitsFromSelectSubject(
            studentDashboardSimulations.student_dashboard_homepage_simulations_get_subjects_with_select_units
          )
            .filter(
              (item) =>
                item.name.toLowerCase().includes(searchterm.toLowerCase()) ||
                searchterm === ""
            )
            .sort(sortAlphaNum)
            .map((unit, index) => (
              <TabPanel
                key={index}
                hidden={selectedUnit !== unit.name}
                className="transform transition duration-500 ease-in-out"
              >
                <div className="pb-3">
                  <SectionTitle name="Simulation" color="blue" />
                </div>
                {unit.simulations.length === 0 ? (
                  <NotFound feature="simulation" />
                ) : (
                  <div className="md:grid grid-cols-3">
                    {unit.simulations.map((simulation, index) => (
                      <FeatureItemCard
                        key={index}
                        {...simulation}
                        feature="simulation"
                        clicked={() =>
                          history.push(
                            `/teacher/features/simulations/${simulation.id
                            }?subject=${unit.subject}&unit=${unit.name}&id=${simulation.id
                            }&url=${crypt(
                              "O'Genius Panda Simulations",
                              simulation.url
                            )}`
                          )
                        }
                      />
                    ))}
                  </div>
                )}
              </TabPanel>
            ))}
        </div>
        <div
          style={{ zIndex: "1000" }}
          className={`${showUnits ? "block" : ""
            } md:hidden fixed w-full md:w-auto  md:relative p-3 bg-gray-200 bottom-0  flex flex-row md:flex-col justify-between items-center`}
        >
          <div className="flex w-full justify-around md:flex-col md:items-center md:space-y-10 z-auto">
            {showUnits ? (
              <GrClose
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowUnits(!showUnits)}
              />
            ) : (
              <GiHamburgerMenu
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowUnits(!showUnits)}
              />
            )}
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default Simulations;
