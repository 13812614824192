import { Button, ButtonLink } from "../../../elements/button";
import { CardSubText, CardTitle, PageTitle } from "../../../elements/text";
import { useSelector, useDispatch } from "react-redux";
import {
  changePaymentModalState,
  setChosenPackage,
} from "../../../../store/actions/payment";
import * as functions from "../../../../functions/commonfunctions";

export const PricingCard = function ({ props, children }) {
  const isAuthenticated = useSelector((state) => state.auth.token != null); // useSelector((state) => state.auth.token !== null);
  const dispatch = useDispatch();
  // console.log(children);
  return (
    <div className="flex flex-row  justify-start md:justify-around flex-no-wrap w-full overflow-x-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100 p-3 bg-gray-200">
      {children?.map((card, index) => (
        <div
          key={index}
          className="flex flex-col align-center justify-start space-y-4 mx-2 sm:mx-4 px-6 md:px-12 py-6 bg-white rounded-md filter drop-shadow-xl shadow-md"
        >
          <div className="flex-none align-center">
            <div className="flex justify-center">
              <CardTitle
                name={card?.pricingname}
                alignment="center"
                color="yellow"
              />
            </div>
            <div className="flex justify-center">
              <CardSubText
                name={card?.pricingcurrency?.toUpperCase()}
                alignment="center"
                color="blue"
              />
            </div>
            <div className="flex justify-center">
              <PageTitle
                name={functions.numberWithCommas(card?.pricingamount)}
                alignment="center"
                color="blue"
              />
            </div>
            <div className="flex justify-center">
              <CardSubText
                name={card?.pricingfrequency}
                alignment="center"
                color="blue"
              />
            </div>
            <div className="flex justify-center">
              {/* <CardSubText
                name={`${
                  card.termlyPlan
                } ${card.currency.toLowerCase()} per term`}
                alignment="center"
                color="blue"
              /> */}
            </div>
          </div>
          <ul className="flex-grow max-h-32 overflow-y-auto">
            {card?.pricingfeatures?.split(",").map((feature, key) => (
              <li key={key}>{feature}</li>
            ))}
          </ul>
          <div className="flex-none">
            {isAuthenticated ? (
              <Button
                name="Purchase"
                outline="true"
                color="red"
                clicked={() => {
                  dispatch(setChosenPackage({ ...card, number: index + 1 }));
                  dispatch(changePaymentModalState(true));
                }}
              />
            ) : (
              <ButtonLink
                page="enroll"
                name="Purchase"
                outline="true"
                color="red"
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
