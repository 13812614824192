import { useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  ButtonWithIcon,
  GoBackButton,
} from "../../../elements/button";
import {
  SectionTitle,
  CardSubText,
  FeatureTitle,
  CardTitle,
} from "../../../elements/text";
import PageContent from "../pageContent";
import { useDispatch, useSelector } from "react-redux";
import {
  school_users_lastweek_sessionduration_start,
  school_users_last_seen_date_start,
} from "../../../../store/actions/school.dashboard.user.details";
import { student_stats_of_last_week_start } from "../../../../store/actions/student.dashboard.homepage";

import SchoolUserFeatures from "./schooluserfeatures";
import SchoolUserActivity from "./schooluseractivity";
import axios from "../../../../axios-base";
import Spinner from "../../../elements/spinner";

const Details = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const userAuth = useSelector((state) => state.auth);
  const [usernames, setUsernames] = useState("");
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);

  const schoolUserDetails = useSelector((state) => state.schoolUserDetails);
  const { userId, rowId, names } = (location?.state) ? location?.state : {};
  useEffect(() => {
    if (location?.state) {

    } else {
      history.push("/")
    }
  }, []);

  // console.log("user detail", userId, rowId, user_names);
  const today = useRef(new Date().getTime());
  useEffect(() => {
    // Find linked Users
    setIsFetchingData(true);
    axios
      .post(`/opanda/manage-users/findUserLinkedToTokenAndSchool`, {
        academic_level: userAuth.academic_level,
        user_id: userAuth.userId,
        schoolUploadedUserId: location.state.rowId,

      },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userAuth.token}`,
          },
        })
      .then((response) => {
        if (response.data.message === "No linked user found") {
          setUsernames("");
          setFetchedData(response.data.results);


        } else if (response.data.message === "Linked user found") {
          setUsernames(response.data.results.names);
          setFetchedData(response.data.results);
          dispatch(
            school_users_lastweek_sessionduration_start({
              token: userAuth.token,
              userId: response.data.results.id,
              day: today.current,
              uploadedSchoolId: userAuth.rowId,
            })
          );
          dispatch(
            student_stats_of_last_week_start({
              token: userAuth.token,
              userId: response.data.results.id,
              day: today.current,
              uploadedSchoolId: userAuth.rowId,
            })
          );
          dispatch(
            school_users_last_seen_date_start({
              token: userAuth.token,
              userId: response.data.results.id,
              uploadedSchoolId: userAuth.rowId,
            })
          );

        }
        setIsFetchingData(false);
      })
      .catch((err) => {
        console.log(err);
        setIsFetchingData(false);
      });

  }, []);

  return (
    <PageContent>
      {(!isFetchingData) ? (fetchedData?.data && fetchedData?.data === true) ? <div className="p-3 bg-white-blue space-y-2 max-h-myscreen overflow-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
        <FeatureTitle name="User management" color="blue" />
        <div className="flex flex-col md:flex-row justify-between">
          <GoBackButton
            link="/school/features/users"
            action="Back"
            location={`User: ${usernames}`}
          />
          <div className="grid grid-cols-2 gap-2">
            <ButtonWithIcon
              name="View info"
              isSmall
              color="blue"
              outline="false"
              onClick={() => history.push("/school/features/users/info", {
                userId: userId, token: userAuth.token
              })}
            ></ButtonWithIcon>
            <ButtonWithIcon
              name="View class info"
              isSmall
              color="blue"
              outline="false"
              onClick={() => history.push("/school/features/users/info", {
                userId: userId, token: userAuth.token
              })}
            ></ButtonWithIcon>
          </div>
        </div>
        <SectionTitle name={`${usernames}`} color="blue" />
        <CardSubText
          name={`Last seen: ${schoolUserDetails.school_user_last_seen_date === null
            ? "No activity found"
            : schoolUserDetails.school_user_last_seen_date
            }`}
          color="blue"
        />
        <div className="pt-2 px-2 space-x-2 w-full grid grid-cols-1 md:grid-cols-3 gap-2">
          <SchoolUserFeatures userId={fetchedData.id} token={userAuth.token} />

          <SchoolUserActivity userId={fetchedData.id} token={userAuth.token} />
        </div>
        <div className="md:mx-4 my-4"></div>
      </div> : <div>
        <FeatureTitle name="User management" color="blue" />
        <div className="p-3 bg-white-blue space-y-2 max-h-myscreen overflow-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">

          <div className="flex flex-col md:flex-row justify-between">
            <GoBackButton
              link="/school/features/users"
              action="Back"
              location={``}
            /></div>
          {(fetchedData?.otp_code) ? <CardTitle name={`No user linked on the otp code ${fetchedData.otp_code}`} color="red" /> : fetchedData?.otp_code}
        </div>
      </div> : <Spinner size="20" />}
    </PageContent>
  );
};

export default Details;
