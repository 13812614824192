import { useState } from "react";
import { ContentContainer } from "../../../common/content/container";
import { CurriculumCourseCard } from "../../../elements/card";
import Input from "../../../elements/input";
import { CardBody } from "../../../elements/text";
import { useDispatch } from "react-redux";
import PageContent from "../pageContent";
import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import { student_load_extra_curricular_modules_start, student_student_programs_cbc_start } from "../../../../store/actions/student.dashboard.programs";
import { getModulesFormatted, getProgramsExtracurriculars, getProgramsScientificObjects } from "../../../../functions/programs";
import Spinner from "../../../elements/spinner";
import { student_new_subjects_start, student_recommended_subjects_start, student_subject_specialization_start, student_trending_subjects_start } from "../../../../store/actions/student.dashboard.homepage";
import { newSubjects, recommendedSubjects } from "../../../../functions/newSubjects";
import { subjectSpecialization } from "../../../../functions/subjectSpecializationUtils";
import { trendingSubjects } from "../../../../functions/trendingSubjects";

const CBC = () => {
  const history = useHistory();
  const userAth = useSelector((state) => state.auth);
  const [curriculum, setCurriculum] = useState("cbc");
  const [selectedLevel, setSelectedLevel] = useState(`Senior ${userAth.academic_level}`);
  const [searchterm, setSearchterm] = useState("");


  const dispatch = useDispatch();

  const studentDashboardPrograms = useSelector(
    (state) => state.studentDashboardPrograms
  );
  const studentDashboardhomePage = useSelector(
    (state) => state.studentDashboardhomePage
  );

  useEffect(() => {
    dispatch(
      student_student_programs_cbc_start({
        token: userAth.token,
        username: userAth.username,
        userId: userAth.userId,
        day: new Date().getTime(),
        academic_level: userAth.academic_level
      })
    );
    dispatch(
      student_subject_specialization_start({
        token: userAth.token,
        username: userAth.username,
        userId: userAth.userId,
        day: new Date().getTime(),
        academic_level: userAth.academic_level,
      })
    );
    dispatch(
      student_subject_specialization_start({
        token: userAth.token,
        username: userAth.username,
        userId: userAth.userId,
        day: new Date().getTime(),
        academic_level: userAth.academic_level,
      })
    );
    dispatch(
      student_trending_subjects_start({
        token: userAth.token,
        username: userAth.username,
        userId: userAth.userId,
        day: new Date().getTime(),
        academic_level: userAth.academic_level,
      })
    );
    dispatch(
      student_subject_specialization_start({
        token: userAth.token,
        username: userAth.username,
        userId: userAth.userId,
        day: new Date().getTime(),
        academic_level: userAth.academic_level,
      })
    );
    dispatch(
      student_trending_subjects_start({
        token: userAth.token,
        username: userAth.username,
        userId: userAth.userId,
        day: new Date().getTime(),
        academic_level: userAth.academic_level,
      })
    );
    dispatch(
      student_new_subjects_start({
        token: userAth.token,
        username: userAth.username,
        userId: userAth.userId,
        day: new Date().getTime(),
        academic_level: userAth.academic_level,
      })
    );
    dispatch(
      student_recommended_subjects_start({
        token: userAth.token,
        username: userAth.username,
        userId: userAth.userId,
        day: new Date().getTime(),
        academic_level: userAth.academic_level,
      })
    );
  }, []);
  let subjects = [
    {
      id: 1,
      subject: "Agriculture",
      imgsrc: "/images/preview/Agriculture.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-01T12:05:25.032Z").toString(),
    },
    {
      id: 2,
      subject: "ART AND CRAFTS",
      imgsrc:
        "/images/preview/Arts and Crafts.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 3,
      subject: "BIOLOGY",
      imgsrc: "/images/preview/biology.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 4,
      subject: "CHEMISTRY",
      imgsrc: "/images/preview/chem.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 5,
      subject: "COMPUTER SCIENCE",
      imgsrc: "/images/preview/Computer science.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 6,
      subject: "ECONOMICS",
      imgsrc:
        "/images/preview/Economics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 7,
      subject: "ELECTRICITY",
      imgsrc: "/images/preview/Electricity.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 8,
      subject: "ELECTRICITY FOR TVET",
      imgsrc: "/images/preview/Electricity for TVET.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 9,
      subject: "ELECTRONICS",
      imgsrc: "/images/preview/Electronics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 10,
      subject: "ELECTRONICS FOR TVET",
      imgsrc: "/images/preview/Electronics for TVET.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 11,
      subject: "English",
      imgsrc: "/images/preview/French.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 12,
      subject: "ENTREPRENEURSHIP",
      imgsrc: "/images/preview/enterpreneurship.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 13,
      subject: "FINE ART AND CRAFTS",
      imgsrc:
        "/images/preview/Arts and Crafts.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 14,
      subject: "FRANÇAIS",
      imgsrc: "/images/preview/French.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 15,
      subject: "GEOGRAPHY",
      imgsrc: "/images/preview/Geaography.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 16,
      subject: "Geography and Environment",
      imgsrc: "/images/preview/Geography and environment.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 17,
      subject: "HISTORY",
      imgsrc: "/images/preview/History.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 18,
      subject: "ICT",
      imgsrc:
        "/images/preview/French.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 19,
      subject: "Literature in English",
      imgsrc: "/images/preview/Literature in English.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 20,
      subject: "MACHINE ASSEMBLY",
      imgsrc: "/images/preview/Machine assembly.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 21,
      subject: "MATHEMATICS",
      imgsrc: "/images/preview/Mathematics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 22,
      subject: "PHYSICS",
      imgsrc: "/images/preview/Physics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 23,
      subject: "RELIGION AND ETHICS",
      imgsrc:
        "/images/preview/Religion and ethics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
  ];

  const courses = [
    { name: "MATHEMATICS", image: "/images/preview/math.svg" },
    { name: "PHYSICS", image: "/images/preview/bio.svg" },
    { name: "BIOLOGY", image: "/images/preview/bio.svg" },
    { name: "CHEMISTRY", image: "/images/preview/bio.svg" },
    { name: "ECONOMY", image: "/images/preview/math.svg" },
    { name: "GEOGRAPH", image: "/images/preview/bio.svg" },
  ];
  const modules = [
    { name: "MODULES 1", image: "/images/preview/art-maths.jpg" },
    { name: "MODULES 2", image: "/images/preview/art-maths.jpg" },
    { name: "MODULES 3", image: "/images/preview/art-maths.jpg" },
    { name: "MODULES 4", image: "/images/preview/art-maths.jpg" },
    { name: "MODULES 5", image: "/images/preview/art-maths.jpg" },
    { name: "MODULES 6", image: "/images/preview/art-maths.jpg" },
  ];


  const courses_in_my_level = getProgramsScientificObjects(studentDashboardPrograms.student_programs_cbc, subjects);
  const extracurriculars = getProgramsExtracurriculars(studentDashboardPrograms.student_programs_cbc, subjects);
  const modules_formatted = getModulesFormatted(studentDashboardPrograms.load_extra_curricular_modules, subjects);

  const load_modules = (value) => {
    //---Refresh the modules load
    dispatch(
      student_load_extra_curricular_modules_start({
        token: userAth.token,
        username: userAth.username,
        userId: userAth.userId,
        day: new Date().getTime(),
        academic_level: userAth.academic_level,
        module: value
      })
    );
  };
  const subjects_specialized_level = subjectSpecialization(
    studentDashboardhomePage.subjectSpecializationPool
      ? studentDashboardhomePage.subjectSpecializationPool
      : [],
    subjects
  );

  const trending_subjects = trendingSubjects(
    studentDashboardhomePage.trendingsSubjects
      ? studentDashboardhomePage.trendingsSubjects
      : [],
    subjects
  );

  const new_subjects = newSubjects(
    studentDashboardhomePage.newsSubjects
      ? studentDashboardhomePage.newsSubjects
      : [],
    subjects
  );

  const recommended_subjects = recommendedSubjects(
    studentDashboardhomePage.recommendedSubjects
      ? studentDashboardhomePage.recommendedSubjects
      : [],
    subjects
  );




  return (
    <PageContent>
      <div className="w-full p-3 h-full md:max-h-screen-sec overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
        <div className="md:w-20%">
          {(!studentDashboardPrograms.student_programs_cbc_loading && extracurriculars.length > 0) ? <Input
            label="Choose Curriculum"
            elementType="select"
            elementConfig={{
              startingValue: "",
              options: [
                { value: "cbc", displayValue: "CBC" }, //...extracurriculars
              ],
            }}
            value={curriculum}
            load_modules={load_modules}
            changed={setCurriculum}
            validation={{ required: true }}
            shouldValidate
            error="Curriculum is required"
          /> : <Spinner size="20" color="blue" />}
        </div>
        {curriculum === "cbc" ? (
          <>
            <div className="text-right">
              <CardBody name={selectedLevel} color="blue" />
            </div>
            <div className="w-full pt-3 grid grid-cols-2 gap-4 md:flex justify-start flex-wrap">
              {(!studentDashboardPrograms.student_programs_cbc_loading && courses_in_my_level.length > 0) ? courses_in_my_level.map((course, index) => (
                <CurriculumCourseCard
                  key={index}
                  image={course.image}
                  name={course.name}
                  clicked={() => {
                    history.push(`/student/features/summarynotes/0/?subject=${course.name}`);
                  }}
                />
              )) : <Spinner size="20" color="blue" />}
            </div>
            <div className="w-full flex justify-end">
              <div className="md:w-20%">
                <Input
                  label="Filter"
                  elementType="select"
                  elementConfig={{
                    startingValue: "SELECT",
                    disabled: "disabled",
                    options: [
                      { value: "Senior 1", displayValue: "Senior 1" },
                      { value: "Senior 2", displayValue: "Senior 2" },
                      { value: "Senior 3", displayValue: "Senior 3" },
                      { value: "Senior 4", displayValue: "Senior 4" },
                      { value: "Senior 5", displayValue: "Senior 5" },
                      { value: "Senior 6", displayValue: "Senior 6" },
                    ],
                  }}
                  value={selectedLevel}
                  changed={setSelectedLevel}
                  validation={{ required: true }}
                  shouldValidate
                  error="Level is required"
                />
              </div>
            </div>
            {curriculum === "cbc" ?<div className="w-full">
            {!studentDashboardhomePage.subjectSpecializationPool_loading ? (
              subjects_specialized_level.length > 0 ? (
                <ContentContainer
                  name="Subject Specialization"
                  data={subjects_specialized_level}
                  slider={true}
                  type="one"
                />
              ) : (
                <div></div>
              )
            ) : subjects_specialized_level.length > 0 ? (
              <Spinner size="20" color="blue" />
            ) : (
              <div></div>
            )}

            {!studentDashboardhomePage.trendingsSubjects_loading ? (
              trending_subjects.length > 0 ? (
                <ContentContainer
                  name="Trending"
                  data={trending_subjects}
                  slider={true}
                  type="one"
                />
              ) : (
                <div></div>
              )
            ) : trending_subjects.length > 0 ? (
              <Spinner size="20" color="blue" />
            ) : (
              <div></div>
            )}
            {!studentDashboardhomePage.newSubjects_loading ? (
              new_subjects.length > 0 ? (
                <ContentContainer
                  name="New"
                  data={new_subjects}
                  slider={true}
                  type="one"
                />
              ) : (
                <div></div>
              )
            ) : new_subjects.length > 0 ? (
              <Spinner size="20" color="blue" />
            ) : (
              <div></div>
            )}

            {!studentDashboardhomePage.recommendedSubjects_loading ? (
              recommended_subjects.length > 0 ? (
                <ContentContainer
                  name="Recommended"
                  data={recommended_subjects}
                  slider={true}
                  type="one"
                />
              ) : (
                <div></div>
              )
            ) : recommended_subjects.length > 0 ? (
              <Spinner size="20" color="blue" />
            ) : (
              <div></div>
            )}
          </div>:<div></div>}
          </>
        ) : (
          <>
            {(!studentDashboardPrograms.load_extra_curricular_modules_loading && modules_formatted.length > 0) ? <div className="w-full pt-3 grid grid-cols-2 gap-4 md:flex justify-start flex-wrap">
              {modules_formatted.map((module, index) => (
                ((searchterm === "") || (module.name.toLowerCase().includes(searchterm.toLowerCase()))) ?
                  <CurriculumCourseCard
                    key={index}
                    image={module.image}
                    name={module.name}
                    description={module.description}
                  />
                  : <div></div>

              ))}
            </div> : <Spinner size="20" color="blue" />}
            <div className="w-full flex justify-end">
              <div className="md:w-20%">
                <Input
                  label="Search"
                  elementType="input"
                  elementConfig={{
                    type: "text",
                    placeholder: "search...",
                  }}
                  value={searchterm}
                  changed={setSearchterm}
                />
              </div>
            </div>
            {/* <div className="w-full">
              <ContentContainer
                name="Recent"
                data={subjects}
                slider={true}
                type="three"
              />
              <ContentContainer
                name="Not Completed"
                data={subjects}
                slider={true}
                type="three"
              />
            </div> */}
          </>
        )}
      </div>
    </PageContent>
  );
};

export default CBC;