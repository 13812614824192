import { updateObject } from '../../shared/utility';
const initialReducer = {
  mobilesidebar: 'hidden',
  studentDashboardMobileSidebar: 'hidden',
  tabselected: 'cbc',
  selectedPanel: 'cbc',
  studentdashboardMenuselected: 'dashboard',
  studentdashboardtabselected: 'dashboard',
  studentdashboardselectedPanel: 'dashboard',
  sideBarCollapse: false,
  faqtabselected: 'paymentmethod',
  faqselectedPanel: 'paymentmethod',
  genericTab: '',
  previewpanda_search_keyword: "",
  previewpandaIsTest: false
};

const changeSideBarCollapseState = (state, action) => {
  return updateObject(state, { sideBarCollapse: action.status });
};

const changeStudentDashboardMobileVisibility = (state, action) => {
  return updateObject(state, { studentDashboardMobileSidebar: action.status });
};

const changeGenericTab = (state, action) => {
  return updateObject(state, { genericTab: action.tab });
};


const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case 'HIDE_SIDEBAR':
      return { ...state, mobilesidebar: 'hidden' };
    case 'SHOW_SIDEBAR':
      return { ...state, mobilesidebar: 'block' };
    case 'CHANGE_TAB':
      return {
        ...state,
        tabselected: action.payload,
        selectedPanel: action.payload,
      };
    case 'STUDENT_DASHBOARD_CHANGE_TAB':
      return {
        ...state,
        studentdashboardtabselected: action.payload,
        studentdashboardselectedPanel: action.payload,
      };
    case 'STUDENT_DASHBOARD_CHANGE_MENU':
      return {
        ...state,
        studentdashboardMenuselected: action.payload,
      };
    case 'CHANGE_FAQ_TAB':
      return {
        ...state,
        faqtabselected: action.payload,
        faqselectedPanel: action.payload,
      };
    case 'CHANGE_SIDEBAR_COLLAPSE_STATE':
      return changeSideBarCollapseState(state, action);
    case 'STUDENT_DASHBOARD_CHANGE_MOBILE_VISIBILITY':
      return changeStudentDashboardMobileVisibility(state, action);
    case 'CHANGE_GENERIC_TAB':
      return changeGenericTab(state, action);
    case 'PREVIEWPANDA_SEARCH_KEYWORD':
      return {
        ...state,
        previewpanda_search_keyword: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
