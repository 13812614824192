import { SectionTitle } from "../../../elements/text";
import BasicInformation from "./forms/basicInformation";
import ProgressBar from "./progressBar";
import { BiDotsVertical } from "react-icons/bi";
import { useState, useEffect } from "react";
import SideDrawer from "../../../elements/sideDrawer";
import SideBar from "./SideBar";
import AcademicInformation from "./forms/academicInformation";
import BaselineInformation from "./forms/baselineInformation";
import SalesInformation from "./forms/salesInformation";
import { useSelector, useDispatch } from "react-redux";
import RecoveryInformation from "./forms/recoveryInformation";
import { useHistory } from "react-router-dom";
import { authenticate } from "../../../../store/actions/auth";
import { useParams } from "react-router-dom";
import * as actions from "../../../../store/actions/";
import * as constants from "../../../../constants/constants";

const PageContent = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const completeSignUpItems = useSelector(
    (state) => state.completeSignup.completeSignUpItems
  );
  const selectedCompleteSignupItem = completeSignUpItems.filter(
    (item) => item.selected === true
  );
  const user = useSelector((state) => state.auth.user);
  const profile = useSelector((state) => state.profile.profile);

  const [showSideDrawer, setShowSideDrawer] = useState(false);
  let Tab = BasicInformation;
    switch (selectedCompleteSignupItem[0].title) {
      case "Personal Information":
        Tab = BasicInformation;
        break;
      case "Recovery Information":
        Tab = RecoveryInformation;
        break;
      case "Academic Information":
        Tab = AcademicInformation;
        break;
      case "Baseline Information":
        Tab = BaselineInformation;
        break;
      case "Sales Information":
        Tab = SalesInformation;
        break;
      default:
        Tab = BasicInformation;
        break;
    }

  const getPercentage = () => {
    const completed = completeSignUpItems.filter(
      (item) => item.completed === true
    );
    return completed.length * 100/completeSignUpItems.length;
  };

  const isAuthenticated = useSelector((state) => state.auth.token != null);

  useEffect(() => {
    if (user?.user_type === constants.studentusertype) {
      if (user?.isprofilecompleted === 1) {
        history.push("/student");
      }
    } else if (user?.user_type === constants.teacherusertype) {
      if (user?.isprofilecompleted === 1) {
        //history.push("/teacher");
        history.push("/");
      }
    }else if (user?.user_type === constants.schoolusertype) {
      if (user?.isprofilecompleted === 1) {
        history.push("/school");
      }
    }else if (user?.user_type === constants.parentusertype) {
      // dispatch(actions.completeSignup({
      //   isParent: true, 
      // }));
      if (user?.isprofilecompleted === 1) {
        history.push("/parent");
      }
    }

    // if (profile === null) {
    //   dispatch(actions.getUserProfile(user?.id));
    // }
  }, [profile, user]);

  
  return (
    <div className="bg-white-blue md:flex w-full h-full">
      <div className="block md:hidden">
        <SideDrawer
          show={showSideDrawer}
          closed={() => setShowSideDrawer(false)}
        >
          <SideBar
            selectedColor={props.selectedColor}
            closed={() => setShowSideDrawer(false)}
          />
        </SideDrawer>
      </div>
      <div className="w-25% bg-white mt-4 p-3 hidden md:block">
        <SideBar
          selectedColor={props.selectedColor}
          closed={() => setShowSideDrawer(false)}
        />
      </div>
      <div className="p-3 md:w-70% h-full overflow-y-auto">
        <div className="flex justify-between items-center">
          <SectionTitle name="Complete your profile" color="blue" />
          <BiDotsVertical
            className="cursor-pointer block md:hidden"
            color="#183F71"
            onClick={() => setShowSideDrawer(true)}
          />
        </div>
        <ProgressBar className="block md:hidden" progress={getPercentage()} />
        <Tab user={user?.user_type}/>
      </div>
    </div>
  );
};

export default PageContent;
