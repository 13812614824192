import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";

export const getOGeniusModulesStart = () => {
  return {
    type: actionTypes.GET_OGENIUS_MODULES_START,
  };
};

export const getOGeniusModulesSuccess = (modules) => {
  return {
    type: actionTypes.GET_OGENIUS_MODULES_SUCCESS,
    modules: modules,
  };
};

export const getOneOGeniusModulesSuccess = (module) => {
  return {
    type: actionTypes.GET_ONE_OGENIUS_MODULES_SUCCESS,
    module: module,
  };
};

export const getOGeniusModulesFail = (error) => {
  return {
    type: actionTypes.GET_OGENIUS_MODULES_FAIL,
    error: error,
  };
};

export const getOneOGeniusModules = (id) => {
  return (dispatch) => {
    dispatch(getOGeniusModulesStart());
    axios
      .get(`/opanda/ogenius-panda-modules/find/${id}`)
      .then((response) => {
        dispatch(getOneOGeniusModulesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getOGeniusModulesFail(err.message));
      });
  };
};

export const getOGeniusModules = () => {
  return (dispatch) => {
    dispatch(getOGeniusModulesStart());
    axios
      .get(`/opanda/ogenius-panda-modules`)
      .then((response) => {
        dispatch(getOGeniusModulesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getOGeniusModulesFail(err.message));
      });
  };
};

export const getExtraCurricularsStart = () => {
  return {
    type: actionTypes.GET_EXTRA_CURRICULARS_START,
  };
};

export const getExtraCurricularsSuccess = (extracurriculars) => {
  return {
    type: actionTypes.GET_EXTRA_CURRICULARS_SUCCESS,
    extracurriculars: extracurriculars,
  };
};

export const getOneExtraCurricularsSuccess = (extracurricular) => {
  return {
    type: actionTypes.GET_ONE_EXTRA_CURRICULARS_SUCCESS,
    extracurricular: extracurricular,
  };
};

export const getExtraCurricularsFail = (error) => {
  return {
    type: actionTypes.GET_EXTRA_CURRICULARS_FAIL,
    error: error,
  };
};

export const getExtraCurriculars = () => {
  return (dispatch) => {
    dispatch(getExtraCurricularsStart());
    axios
      .get(`/opanda/extra-curriculars`)
      .then((response) => {
        dispatch(getExtraCurricularsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getExtraCurricularsFail(err.message));
      });
  };
};

export const getLearningResourcesStart = () => {
  return {
    type: actionTypes.GET_LEARNING_RESOURCES_START,
  };
};

export const getLearningResourcesSuccess = (learningresources) => {
  return {
    type: actionTypes.GET_LEARNING_RESOURCES_SUCCESS,
    learningresources: learningresources,
  };
};

export const getOneLearningResourcesSuccess = (learningresource) => {
  return {
    type: actionTypes.GET_ONE_LEARNING_RESOURCES_SUCCESS,
    learningresource: learningresource,
  };
};

export const getLearningResourcesFail = (error) => {
  return {
    type: actionTypes.GET_LEARNING_RESOURCES_FAIL,
    error: error,
  };
};

export const getLearningResources = () => {
  return (dispatch) => {
    dispatch(getLearningResourcesStart());
    axios
      .get(`/opanda/learning-resources`)
      .then((response) => {
        dispatch(getLearningResourcesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLearningResourcesFail(err.message));
      });
  };
};

export const getBooksStart = () => {
  return {
    type: actionTypes.GET_OPANDA_BOOKS_START,
  };
};

export const getBooksSuccess = (books) => {
  return {
    type: actionTypes.GET_OPANDA_BOOKS_SUCCESS,
    books: books,
  };
};

export const getOneBooksSuccess = (book) => {
  return {
    type: actionTypes.GET_ONE_OPANDA_BOOKS_SUCCESS,
    book: book,
  };
};

export const getBooksFail = (error) => {
  return {
    type: actionTypes.GET_OPANDA_BOOKS_FAIL,
    error: error,
  };
};

export const getOpandaBooks = (id,abbreviatedname) => {
  return (dispatch) => {
    dispatch(getBooksStart());
    axios
      .get(`/opanda/panda-books/${id}`)
      .then((response) => {
        dispatch(getBooksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getBooksFail(err.message));
      });
  };
};

export const getCourseStructuresStart = () => {
  return {
    type: actionTypes.GET_COURSE_STRUCTURES_START,
  };
};

export const getCourseStructuresSuccess = (coursestructures) => {
  return {
    type: actionTypes.GET_COURSE_STRUCTURES_SUCCESS,
    coursestructures: coursestructures,
  };
};

export const getOneCourseStructuresSuccess = (coursestructure) => {
  return {
    type: actionTypes.GET_ONE_COURSE_STRUCTURES_SUCCESS,
    coursestructure: coursestructure,
  };
};

export const getCourseStructuresFail = (error) => {
  return {
    type: actionTypes.GET_COURSE_STRUCTURES_FAIL,
    error: error,
  };
};

export const getModuleCourseStructures = (level) => {
  return (dispatch) => {
    dispatch(getCourseStructuresStart());
    axios
      .get(`/opanda/course-structure/level-courses/1/3`)
      .then((response) => {
        dispatch(getCourseStructuresSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCourseStructuresFail(err.message));
      });
  };
};

export const getTestStart = () => {
  return {
    type: actionTypes.GET_TESTS_START,
  };
};

export const getTestSuccess = (tests) => {
  return {
    type: actionTypes.GET_TESTS_SUCCESS,
    tests: tests,
  };
};

export const getOneTestSuccess = (test) => {
  return {
    type: actionTypes.GET_ONE_TESTS_SUCCESS,
    test: test,
  };
};

export const getTestFail = (error) => {
  return {
    type: actionTypes.GET_TESTS_FAIL,
    error: error,
  };
};

export const getClassLevelAndSubjectTests = (classid, subjectid) => {
  return (dispatch) => {
    dispatch(getTestStart());
    axios
      .get(`/opanda/tests/${classid}/${subjectid}`)
      .then((response) => {
        dispatch(getTestSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestFail(err.message));
      });
  };
};

export const getClassLevelTests = (classid) => {
  return (dispatch) => {
    dispatch(getTestStart());
    axios
      .get(`/opanda/tests/${classid}`)
      .then((response) => {
        dispatch(getTestSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestFail(err.message));
      });
  };
};

export const getSubjectTests = (level, subjectid) => {
  return (dispatch) => {
    dispatch(getTestStart());
    axios
      .get(`/opanda/tests/subject/${subjectid}/level/${subjectid}`)
      .then((response) => {
        dispatch(getTestSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestFail(err.message));
      });
  };
};

export const getTests = () => {
  return (dispatch) => {
    dispatch(getTestStart());
    axios
      .get(`/opanda/panda-test-maps`)
      .then((response) => {
        dispatch(getTestSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestFail(err));
      });
  };
};

export const getClassCoursesStart = () => {
  return {
    type: actionTypes.GET_CLASS_COURSES_START,
  };
};

export const getClassCoursesSuccess = (classcourses) => {
  return {
    type: actionTypes.GET_CLASS_COURSES_SUCCESS,
    classcourses: classcourses,
  };
};

export const getOneClassCoursesSuccess = (classcourse) => {
  return {
    type: actionTypes.GET_ONE_CLASS_COURSES_SUCCESS,
    classcourse: classcourse,
  };
};

export const getClassCoursesFail = (error) => {
  return {
    type: actionTypes.GET_CLASS_COURSES_FAIL,
    error: error,
  };
};

// This function returns classes of a given level
export const getSubjectClassCourses = (levelid, subject) => {
  return (dispatch) => {
    dispatch(getClassCoursesStart());
    axios
      .get(`/opanda/classes/subject/${subject}/level/${levelid}`)
      .then((response) => {
        dispatch(getClassCoursesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getClassCoursesFail(err.message));
      });
  };
};

export const getModuleClassCourses = (moduleid) => {
  return (dispatch) => {
    dispatch(getClassCoursesStart());
    axios
      .get(`/opanda/classes/module/${moduleid}`)
      .then((response) => {
        dispatch(getClassCoursesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getClassCoursesFail(err.message));
      });
  };
};

export const getClassCourses = () => {
  return (dispatch) => {
    dispatch(getClassCoursesStart());
    axios
      .get(`/opanda/classes/preview-courses`)
      .then((response) => {
        dispatch(getClassCoursesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getClassCoursesFail(err.message));
      });
  };
};

export const getOneClassCourses = (id) => {
  return (dispatch) => {
    dispatch(getClassCoursesStart());
    axios
      .get(`/opanda/classes/find/${id}`)
      .then((response) => {
        dispatch(getOneClassCoursesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getClassCoursesFail(err.message));
      });
  };
};

export const getCoursePagesStart = () => {
  return {
    type: actionTypes.GET_COURSE_PAGES_START,
  };
};

export const getCoursePagesSuccess = (coursepages) => {
  return {
    type: actionTypes.GET_COURSE_PAGES_SUCCESS,
    coursepages: coursepages,
  };
};

export const getOneCoursePagesSuccess = (coursepage) => {
  return {
    type: actionTypes.GET_ONE_COURSE_PAGES_SUCCESS,
    coursepage: coursepage,
  };
};

export const getCoursePagesFail = (error) => {
  return {
    type: actionTypes.GET_COURSE_PAGES_FAIL,
    error: error,
  };
};

export const getClassCoursePages=(id)=>{
  return (dispatch) => {
    dispatch(getCoursePagesStart());
    axios
      .get(`/opanda/classes/course-pages/${id}`)
      .then((response) => {
        dispatch(getCoursePagesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCoursePagesFail(err.message));
      });
  };
}

export const getLevelClassCourses = (level) => {
  return (dispatch) => {
    dispatch(getClassCoursesStart());
    axios
      .get(`/opanda/classes/level/${level}`)
      .then((response) => {
        dispatch(getClassCoursesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getClassCoursesFail(err.message));
      });
  };
};
export const getLevelCourseStructures = (level) => {
  return (dispatch) => {
    dispatch(getCourseStructuresStart());
    axios
      .get(`/opanda/course-structure/level-courses/${level}`)
      .then((response) => {
        dispatch(getCourseStructuresSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCourseStructuresFail(err.message));
      });
  };
};

export const getClassSubjectsNamesStart = () => {
  return {
    type: actionTypes.GET_CLASS_SUBJECTS_NAMES_START,
  };
};

export const getClassSubjectsNamesSuccess = (subjectsnames) => {
  return {
    type: actionTypes.GET_CLASS_SUBJECTS_NAMES_SUCCESS,
    subjectsnames: subjectsnames,
  };
};

export const getOneClassSubjectsNamesSuccess = (subjectsname) => {
  return {
    type: actionTypes.GET_ONE_CLASS_SUBJECTS_NAMES_SUCCESS,
    subjectsname: subjectsname,
  };
};

export const getClassSubjectsNamesFail = (error) => {
  return {
    type: actionTypes.GET_CLASS_SUBJECTS_NAMES_FAIL,
    error: error,
  };
};

export const getSubjectNames = () => {
  return (dispatch) => {
    dispatch(getClassSubjectsNamesStart());
    axios
      .get(`/opanda/classes/subjects-names`)
      .then((response) => {
        dispatch(getClassSubjectsNamesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getClassSubjectsNamesFail(err.message));
      });
  };
};

export const getPaymentOptionsStart = () => {
  return {
    type: actionTypes.GET_PAYMENT_OPTIONS_START,
  };
};

export const getPaymentOptionsSuccess = (paymentoptions) => {
  return {
    type: actionTypes.GET_PAYMENT_OPTIONS_SUCCESS,
    paymentoptions: paymentoptions,
  };
};

export const getOnePaymentOptionsSuccess = (paymentoption) => {
  return {
    type: actionTypes.GET_ONE_PAYMENT_OPTIONS_SUCCESS,
    paymentoption: paymentoption,
  };
};

export const getPaymentOptionsFail = (error) => {
  return {
    type: actionTypes.GET_PAYMENT_OPTIONS_FAIL,
    error: error,
  };
};

export const getPaymentOptions=()=>{
  return (dispatch) => {
    dispatch(getPaymentOptionsStart());
    axios
      .get(`/opanda/payment-options/active`)
      .then((response) => {
        dispatch(getPaymentOptionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPaymentOptionsFail(err.message));
      });
  };
}

export const getPaymentMethodsStart = () => {
  return {
    type: actionTypes.GET_PAYMENT_METHODS_START,
  };
};

export const getPaymentMethodsSuccess = (paymentmethods) => {
  return {
    type: actionTypes.GET_PAYMENT_METHODS_SUCCESS,
    paymentmethods: paymentmethods,
  };
};

export const getOnePaymentMethodsSuccess = (paymentmethod) => {
  return {
    type: actionTypes.GET_ONE_PAYMENT_METHODS_SUCCESS,
    paymentmethod: paymentmethod,
  };
};

export const getPaymentMethodsFail = (error) => {
  return {
    type: actionTypes.GET_PAYMENT_METHODS_FAIL,
    error: error,
  };
};

export const getPaymentMethods=()=>{
  return (dispatch) => {
    dispatch(getPaymentMethodsStart());
    axios
      .get(`/opanda/payment-methods/active`)
      .then((response) => {
        dispatch(getOnePaymentMethodsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPaymentMethodsFail(err.message));
      });
  };
}
