import { useState } from "react";
import { ButtonLink, TextButton } from "../../../elements/button";
import Modal from "../../../elements/modal/modal";
import Table from "../../../elements/table/table";
import { PageBody, SectionTitle } from "../../../elements/text";
import shakeIcon from "../../../../images/icons/handshake.svg";
import { useHistory } from 'react-router-dom';
import {useSelector } from 'react-redux';
const ProgressTable = (props) => {
  const [showSuccessModal, setshowSuccessModal] = useState(false);
  const history = useHistory();
  const tableheader1 = [
    "Name",
    "Subject",
    "Status",
    "Creator",
    "Marks",
    "Action",
  ];
  const tablebody1 = [];
  const userAth = useSelector((state) => state.auth);
  Object.values(props.data).forEach((value) => {
    // convert object to key's array

    const keys = Object.values(value);

    // iterate over object


    // keys.forEach((key, index) => {
    const actionbutton = (
      <>
        <TextButton name="View" color="blue" clicked={() => {
          if (keys[6] === "Automated") {
            //history.push(`/student/features/testprogress/${keys[0]}&type=${keys[6]}`); //here must a test id
            //---
            history.push(`/student/features/testsprogresanswers/${keys[0]}?attempt=false&challengetype=0&unitname=${keys[7]}&testid=${keys[9]}&subject=${keys[2]}`);
          } else {
            //history.push(`/student/features/testprogress/${keys[0]}&type=${keys[6]}`); //here must a test id
            history.push(`/student/features/testsprogresanswers/${keys[0]}?attempt=false&unitname=${keys[1]}&challengetype=1&testid=${keys[7]}`);
          }
        }} /><hr /><hr />
        <TextButton name="Retake" clicked={() => {
          if (keys[6] === "Automated") {
            //history.push(`/student/features/testsprogresanswers/${keys[0]}&type=${keys[6]}`); //here must a test id
            history.push(`/student/features/tests/0?scheduled=false&autotest=true&subject=${keys[2]}&unit=${keys[7]}&difficulty=${keys[8]}&level=${userAth.academic_level}&redo=true&testid=${keys[9]}`);
          } else {
            //history.push(`/student/features/testprogress/${keys[0]}&type=${keys[6]}`); //here must a test id
            history.push(`/student/features/tests/${keys[7]}?scheduled=false`);
            
          }
        }} color="red" />{" "}
      </>
    );
    tablebody1.push({
      td1: { data: keys[1], type: "text" },
      td2: { data: keys[2], type: "text" },
      td3: { data: keys[3], type: "text" },
      td4: { data: keys[4], type: "text" },
      td5: { data: keys[5], type: "text" },
      td6: {
        data: <>{actionbutton}</>,
        type: "component",
      },
    });
    // });
  });

  return (
    <div
      className={`${props.show ? `block` : `hidden`
        } pt-2 px-2 md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100 w-full`}
    >
      <Modal
        show={showSuccessModal}
        modalClosed={() => setshowSuccessModal(false)}
        small
      >
        <div className="flex flex-col">
          <SectionTitle name="Success!!" color="blue" alignment="center" />
          <PageBody
            name="You've successfully activated this class. Click on manage class to continue with more actions"
            color="black"
            alignment="center"
          />
          <div className="m-4">
            <img
              alt={props.user}
              className="h-36 w-auto m-auto"
              src={shakeIcon}
            />
          </div>
          <div className="flex flex-row items-center justify-center">
            <ButtonLink
              name="Manage class"
              outline="true"
              color="blue"
              page="/teacher/features/classes/complete/1"
            />
          </div>
        </div>
      </Modal>

      <div className="relative overflow-x-auto sm:rounded-lg">
        <Table thead={tableheader1} tbody={tablebody1} />
      </div>
    </div>
  );
};

export default ProgressTable;
