import { useEffect, useState } from 'react';
import { Button } from '../../../../../elements/button';
import RichTextEditor from '../../../../../elements/RichTextEditor';
import { useDispatch } from 'react-redux';
import { changeMultipleQuestionOption } from '../../../../../../store/actions/features/tests';

const AnswerOption = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const [text, setText] = useState('');
  const [isAnswer, setIsAnswer] = useState(false);

  useEffect(() => {
    // dispatch(
    //   changeMultipleQuestionOption({
    //     id: id,
    //     value: text,
    //     isAnswer: isAnswer,
    //   })
    // );
  }, [text, isAnswer, id]);

  return (
    <div>
      <RichTextEditor
        value={props.option.value}
        onChange={(text) => {
          //setText(text);
          props.updateAnswersOptionsIn(id, text);
        }}
        placeholder="Write answer option here"
      />
      <div className="flex space-x-2 justify-between">
        <div>
          <input
            id={props.id}
            type={'checkbox'}
            value={props.option.isAnswer}
            checked={props.option.isAnswer}
            onChange={(e) => {
              //setIsAnswer(e.target.checked);
              props.updateAnswersOptionsInStatus(id, e.target.checked);
            }}
          />
          Mark As Correct answer
        </div>
        <Button
          name="Remove answer option"
          isSquare
          outline="false"
          color="red"
          clicked={props.removeAnswerOption}
        />
      </div>
    </div>
  );
};
export default AnswerOption;
