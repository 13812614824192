import Layout from '../components/common/Layout';
import { Helmet } from 'react-helmet';
import Dashboard from '../components/pages/school/dashboard/dashboard';

const SchoolHome = () => {
  return (
    <Layout>
      <Helmet>
        <title>OPANDA - SCHOOL</title>
      </Helmet>
      <Dashboard />
    </Layout>
  );
};

export default SchoolHome;
