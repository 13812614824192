import { useSelector } from "react-redux";
import Spinner from "../../../elements/spinner";
import { PageBody, PageTitle } from "../../../elements/text";
const Valueproposition = (props) => {
  const valueproposition = useSelector((state) => state.home.valueproposition);
  const valuepropositionLoading = useSelector(
    (state) => state.home.valuepropositionLoading
  );
  return (
    <div className="bg-valueproposition bg-center bg-cover bg-no-repeat">
      <div className="bg-blue bg-opacity-40 h-full w-full">
        <div className="max-w-7xl mx-auto px-8 h-full flex flex-col justify-end align-end">
          {valuepropositionLoading ? (
            <div className="flex flex-row justify-center">
              <Spinner size="20" color="white" />
            </div>
          ) : (
            <div className="mx-5 p-10 md:pt-72 lg:pt-80 md:pb-12">
              <PageTitle
                name={valueproposition?.bigtitle}
                alignment="right"
                color="white"
                mobileAlignment="left"
              />
              <PageBody
                name={valueproposition?.smalltitle}
                alignment="right"
                color="white"
                mobileAlignment="left"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Valueproposition;
