// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

// import Swiper core and required modules
import SwiperCore, { Navigation } from 'swiper';

// install Swiper modules
SwiperCore.use([Navigation]);

const Gallery = (props) => {
  return (
    <Swiper
      navigation={true}
      slidesPerView={1}
      spaceBetween={10}
      breakpoints={{
        640: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      }}
      className="w-full twoSwiper my-10"
    >
      <SwiperSlide>
        <img src="/images/features/20190626_115515.png" className="" alt="1" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="/images/features/20190808_144908.png" alt="2" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="/images/features/20190913_110540.png" alt="3" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="/images/features/20190913_110540.png" alt="3" />
      </SwiperSlide>
    </Swiper>
  );
};
export default Gallery;
