import axios from "../../axios-base";

export const STUDENT_DASHBOARD_HOMEPAGE_SUGGESTED_FEATURES_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_SUGGESTED_FEATURES_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_SUGGESTED_FEATURES_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_SUGGESTED_FEATURES_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_SUGGESTED_FEATURES_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_SUGGESTED_FEATURES_FAILED";

export function student_suggested_features_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SUGGESTED_FEATURES_INIT
  };
}
export function student_suggested_features_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SUGGESTED_FEATURES_SUCCESS,
    payload: data
  };
}
export function student_suggested_features_fail(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SUGGESTED_FEATURES_FAILED,
    payload: error
  };
}
const CACHE_KEY_SUGGESTED_FEATURES = 'studentSuggestedFeatures';
const invalidateCache_Suggested_features = () => {
  try {
    localStorage.removeItem(CACHE_KEY_SUGGESTED_FEATURES);
    localStorage.removeItem(`${CACHE_KEY_SUGGESTED_FEATURES}_timestamp`);
  } catch (error) {

  }
};
export function student_suggested_features_start(data) {
  return (dispatch) => {
    dispatch(student_suggested_features_init());
    dispatch(student_subject_specialization_init());
    const cachedData = localStorage.getItem(CACHE_KEY_SUGGESTED_FEATURES);
    const cachedTimestamp = localStorage.getItem(`${CACHE_KEY_SUGGESTED_FEATURES}_timestamp`);
    const currentTime = Date.now();
    //---
    if (cachedData && cachedTimestamp) {
      const expirationTime = parseInt(cachedTimestamp, 20) + 20 * 60 * 1000; // 20 minutes
      if (currentTime < expirationTime) {
        dispatch(student_suggested_features_success(JSON.parse(cachedData)));
      } else {
        invalidateCache_Suggested_features();
      }
    }

    axios
      .post(
        `/opanda/student/suggestedfeatures`,
        {
          username: data.username,
          userId: data.userId,
          day: data.day
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        const cachedData = localStorage.getItem(CACHE_KEY_SUGGESTED_FEATURES);
        const cachedTimestamp = localStorage.getItem(`${CACHE_KEY_SUGGESTED_FEATURES}_timestamp`);
        //----Save to cache data--
        localStorage.setItem(CACHE_KEY_SUGGESTED_FEATURES, JSON.stringify(response.data));
        localStorage.setItem(`${CACHE_KEY_SUGGESTED_FEATURES}_timestamp`, Date.now().toString());
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_suggested_features_success(response.data));
        }
      })
      .catch((err) => {
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_suggested_features_fail(err.message));
        }
      });
  };
}
export function teacher_suggested_features_start(data) {
  return (dispatch) => {
    dispatch(student_suggested_features_init());
    dispatch(student_subject_specialization_init());
    const cachedData = localStorage.getItem(CACHE_KEY_SUGGESTED_FEATURES);
    const cachedTimestamp = localStorage.getItem(`${CACHE_KEY_SUGGESTED_FEATURES}_timestamp`);
    const currentTime = Date.now();
    //---
    if (cachedData && cachedTimestamp) {
      const expirationTime = parseInt(cachedTimestamp, 20) + 20 * 60 * 1000; // 20 minutes
      if (currentTime < expirationTime) {
        dispatch(student_suggested_features_success(JSON.parse(cachedData)));
      } else {
        invalidateCache_Suggested_features();
      }
    }

    axios
      .post(
        `/opanda/student/suggestedfeatures`,
        {
          username: data.username,
          userId: data.userId,
          day: data.day
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        const cachedData = localStorage.getItem(CACHE_KEY_SUGGESTED_FEATURES);
        const cachedTimestamp = localStorage.getItem(`${CACHE_KEY_SUGGESTED_FEATURES}_timestamp`);
        //----Save to cache data--
        localStorage.setItem(CACHE_KEY_SUGGESTED_FEATURES, JSON.stringify(response.data));
        localStorage.setItem(`${CACHE_KEY_SUGGESTED_FEATURES}_timestamp`, Date.now().toString());
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_suggested_features_success(response.data));
        }
      })
      .catch((err) => {
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_suggested_features_fail(err.message));
        }
      });
  };
}
//---Subject specialization--
export const STUDENT_DASHBOARD_HOMEPAGE_SUBJECT_SPECIALIZATION_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_SUBJECT_SPECIALIZATION_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_SUBJECT_SPECIALIZATION_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_SUBJECT_SPECIALIZATION_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_SUBJECT_SPECIALIZATION_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_SUBJECT_SPECIALIZATION_FAILED";

export function student_subject_specialization_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SUBJECT_SPECIALIZATION_INIT
  };
}
export function student_subject_specialization_success(data) {


  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SUBJECT_SPECIALIZATION_SUCCESS,
    payload: data.results
  };
}
export function student_subject_specialization_fail(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_SUBJECT_SPECIALIZATION_FAILED,
    payload: error
  };
}
const CACHE_KEY_SUBJECT_SPECIALIZATION = 'studentSubjectSpecialization';
const invalidateCache_subject_specialization = () => {
  try {
    localStorage.removeItem(CACHE_KEY_SUBJECT_SPECIALIZATION);
    localStorage.removeItem(`${CACHE_KEY_SUBJECT_SPECIALIZATION}_timestamp`);
  } catch (error) {

  }
};
export function student_subject_specialization_start(data) {
  return (dispatch) => {
    dispatch(student_subject_specialization_init());
    const cachedData = localStorage.getItem(CACHE_KEY_SUBJECT_SPECIALIZATION);
    const cachedTimestamp = localStorage.getItem(`${CACHE_KEY_SUBJECT_SPECIALIZATION}_timestamp`);
    const currentTime = Date.now();
    //---
    if (cachedData && cachedTimestamp) {
      const expirationTime = parseInt(cachedTimestamp, 20) + 20 * 60 * 1000; // 20 minutes
      if (currentTime < expirationTime) {
        dispatch(student_subject_specialization_success(JSON.parse(cachedData)));
      } else {
        invalidateCache_subject_specialization();
      }
    }

    axios
      .post(
        `/opanda/student/subjectspecializationpool`,
        {
          username: data.username,
          userId: data.userId,
          day: data.day,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        const cachedData = localStorage.getItem(CACHE_KEY_SUBJECT_SPECIALIZATION);
        const cachedTimestamp = localStorage.getItem(`${CACHE_KEY_SUBJECT_SPECIALIZATION}_timestamp`);
        //----Save to cache data--
        localStorage.setItem(CACHE_KEY_SUBJECT_SPECIALIZATION, JSON.stringify(response.data));
        localStorage.setItem(`${CACHE_KEY_SUBJECT_SPECIALIZATION}_timestamp`, Date.now().toString());
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_subject_specialization_success(response.data));
        }

      })
      .catch((err) => {
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_subject_specialization_fail(err.message));
        }

      });
  };
}
export function teacher_subject_specialization_start(data) {
  return (dispatch) => {
    dispatch(student_subject_specialization_init());
    const cachedData = localStorage.getItem(CACHE_KEY_SUBJECT_SPECIALIZATION + "" + data.academic_level);
    const cachedTimestamp = localStorage.getItem(`${CACHE_KEY_SUBJECT_SPECIALIZATION + "" + data.academic_level}_timestamp`);
    const currentTime = Date.now();
    //---
    if (cachedData && cachedTimestamp) {
      const expirationTime = parseInt(cachedTimestamp, 20) + 20 * 60 * 1000; // 20 minutes
      if (currentTime < expirationTime) {
        dispatch(student_subject_specialization_success(JSON.parse(cachedData)));
      } else {
        invalidateCache_subject_specialization();
      }
    }

    axios
      .post(
        `/opanda/student/subjectspecializationpool`,
        {
          username: data.username,
          userId: data.userId,
          day: data.day,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        const cachedData = localStorage.getItem(CACHE_KEY_SUBJECT_SPECIALIZATION + "" + data.academic_level);
        const cachedTimestamp = localStorage.getItem(`${CACHE_KEY_SUBJECT_SPECIALIZATION + "" + data.academic_level}_timestamp`);
        //----Save to cache data--
        localStorage.setItem(CACHE_KEY_SUBJECT_SPECIALIZATION + "" + data.academic_level, JSON.stringify(response.data));
        localStorage.setItem(`${CACHE_KEY_SUBJECT_SPECIALIZATION + "" + data.academic_level}_timestamp`, Date.now().toString());
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_subject_specialization_success(response.data));
        }

      })
      .catch((err) => {
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_subject_specialization_fail(err.message));
        }

      });
  };
}
//---Trending subjects--
export const STUDENT_DASHBOARD_HOMEPAGE_TRENDING_SUBJECTS_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_TRENDING_SUBJECTS_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_TRENDING_SUBJECTS_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_TRENDING_SUBJECTS_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_TRENDING_SUBJECTS_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_TRENDING_SUBJECTS_FAILED";

export function student_trending_subjects_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_TRENDING_SUBJECTS_INIT
  };
}
export function student_trending_subjects_success(data) {


  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_TRENDING_SUBJECTS_SUCCESS,
    payload: data.results
  };
}
export function student_trending_subjects_fail(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_TRENDING_SUBJECTS_FAILED,
    payload: error
  };
}
const CACHE_KEY_TRENDING_SUBJECT = 'studentTrendingSubject';
const invalidateCache_trending_subject = () => {
  try {
    localStorage.removeItem(CACHE_KEY_TRENDING_SUBJECT);
    localStorage.removeItem(`${CACHE_KEY_TRENDING_SUBJECT}_timestamp`);
  } catch (error) {

  }
};
export function student_trending_subjects_start(data) {
  return (dispatch) => {
    dispatch(student_trending_subjects_init());
    const cachedData = localStorage.getItem(CACHE_KEY_TRENDING_SUBJECT);
    const cachedTimestamp = localStorage.getItem(`${CACHE_KEY_TRENDING_SUBJECT}_timestamp`);
    const currentTime = Date.now();
    //---
    if (cachedData && cachedTimestamp) {
      const expirationTime = parseInt(cachedTimestamp, 20) + 20 * 60 * 1000; // 20 minutes
      if (currentTime < expirationTime) {
        dispatch(student_trending_subjects_success(JSON.parse(cachedData)));
      } else {
        invalidateCache_trending_subject();
      }
    }

    axios
      .post(
        `/opanda/student/trendingsubjects`,
        {
          username: data.username,
          userId: data.userId,
          day: data.day,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        const cachedData = localStorage.getItem(CACHE_KEY_TRENDING_SUBJECT);
        const cachedTimestamp = localStorage.getItem(`${CACHE_KEY_TRENDING_SUBJECT}_timestamp`);

        //----Save to cache data--
        localStorage.setItem(CACHE_KEY_TRENDING_SUBJECT, JSON.stringify(response.data));
        localStorage.setItem(`${CACHE_KEY_TRENDING_SUBJECT}_timestamp`, Date.now().toString());
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_trending_subjects_success(response.data));
        }

      })
      .catch((err) => {
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_trending_subjects_fail(err.message));
        }

      });
  };
}
export function teacher_trending_subjects_start(data) {
  return (dispatch) => {
    dispatch(student_trending_subjects_init());
    const cachedData = localStorage.getItem(CACHE_KEY_TRENDING_SUBJECT + "" + data.academic_level);
    const cachedTimestamp = localStorage.getItem(`${CACHE_KEY_TRENDING_SUBJECT + "" + data.academic_level}_timestamp`);
    const currentTime = Date.now();
    //---
    if (cachedData && cachedTimestamp) {
      const expirationTime = parseInt(cachedTimestamp, 20) + 20 * 60 * 1000; // 20 minutes
      if (currentTime < expirationTime) {
        dispatch(student_trending_subjects_success(JSON.parse(cachedData)));
      } else {
        invalidateCache_trending_subject();
      }
    }

    axios
      .post(
        `/opanda/student/trendingsubjects`,
        {
          username: data.username,
          userId: data.userId,
          day: data.day,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        const cachedData = localStorage.getItem(CACHE_KEY_TRENDING_SUBJECT + "" + data.academic_level);
        const cachedTimestamp = localStorage.getItem(`${CACHE_KEY_TRENDING_SUBJECT + "" + data.academic_level}_timestamp`);

        //----Save to cache data--
        localStorage.setItem(CACHE_KEY_TRENDING_SUBJECT + "" + data.academic_level, JSON.stringify(response.data));
        localStorage.setItem(`${CACHE_KEY_TRENDING_SUBJECT + "" + data.academic_level}_timestamp`, Date.now().toString());
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_trending_subjects_success(response.data));
        }

      })
      .catch((err) => {
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_trending_subjects_fail(err.message));
        }

      });
  };
}
//---New subjects--
export const STUDENT_DASHBOARD_HOMEPAGE_NEW_SUBJECTS_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_NEW_SUBJECTS_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_NEW_SUBJECTS_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_NEW_SUBJECTS_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_NEW_SUBJECTS_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_NEW_SUBJECTS_FAILED";

export function student_new_subjects_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_NEW_SUBJECTS_INIT
  };
}
export function student_new_subjects_success(data) {


  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_NEW_SUBJECTS_SUCCESS,
    payload: data.results
  };
}
export function student_new_subjects_fail(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_NEW_SUBJECTS_FAILED,
    payload: error
  };
}
//--
const CACHE_NEW_SUBJECTS = 'studentNewSubject';
const invalidateCacheNewSubject = () => {
  try {
    localStorage.removeItem(CACHE_NEW_SUBJECTS);
    localStorage.removeItem(`${CACHE_NEW_SUBJECTS}_timestamp`);
  } catch (error) {

  }
};
export function student_new_subjects_start(data) {
  return (dispatch) => {
    dispatch(student_new_subjects_init());
    //--
    const cachedData = localStorage.getItem(CACHE_NEW_SUBJECTS);
    const cachedTimestamp = localStorage.getItem(`${CACHE_NEW_SUBJECTS}_timestamp`);
    const currentTime = Date.now();
    //---
    if (cachedData && cachedTimestamp) {
      const expirationTime = parseInt(cachedTimestamp, 20) + 20 * 60 * 1000; // 20 minutes
      if (currentTime < expirationTime) {
        dispatch(student_new_subjects_success(JSON.parse(cachedData)));
      } else {
        invalidateCacheNewSubject();
      }
    }

    axios
      .post(
        `/opanda/student/newlyaddedsubject`,
        {
          username: data.username,
          userId: data.userId,
          day: data.day,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        const cachedData = localStorage.getItem(CACHE_NEW_SUBJECTS);
        const cachedTimestamp = localStorage.getItem(`${CACHE_NEW_SUBJECTS}_timestamp`);

        //----Save to cache data--
        localStorage.setItem(CACHE_NEW_SUBJECTS, JSON.stringify(response.data));
        localStorage.setItem(`${CACHE_NEW_SUBJECTS}_timestamp`, Date.now().toString());
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_new_subjects_success(response.data));
        }


      })
      .catch((err) => {
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_new_subjects_fail(err.message));
        }
      });
  };
}
export function teacher_new_subjects_start(data) {
  return (dispatch) => {
    dispatch(student_new_subjects_init());
    //--
    const cachedData = localStorage.getItem(CACHE_NEW_SUBJECTS + "" + data.academic_level);
    const cachedTimestamp = localStorage.getItem(`${CACHE_NEW_SUBJECTS + "" + data.academic_level}_timestamp`);
    const currentTime = Date.now();
    //---
    if (cachedData && cachedTimestamp) {
      const expirationTime = parseInt(cachedTimestamp, 20) + 20 * 60 * 1000; // 20 minutes
      if (currentTime < expirationTime) {
        dispatch(student_new_subjects_success(JSON.parse(cachedData)));
      } else {
        invalidateCacheNewSubject();
      }
    }

    axios
      .post(
        `/opanda/student/newlyaddedsubject`,
        {
          username: data.username,
          userId: data.userId,
          day: data.day,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        const cachedData = localStorage.getItem(CACHE_NEW_SUBJECTS + "" + data.academic_level);
        const cachedTimestamp = localStorage.getItem(`${CACHE_NEW_SUBJECTS + "" + data.academic_level}_timestamp`);

        //----Save to cache data--
        localStorage.setItem(CACHE_NEW_SUBJECTS + "" + data.academic_level, JSON.stringify(response.data));
        localStorage.setItem(`${CACHE_NEW_SUBJECTS + "" + data.academic_level}_timestamp`, Date.now().toString());
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_new_subjects_success(response.data));
        }


      })
      .catch((err) => {
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_new_subjects_fail(err.message));
        }
      });
  };
}
//---RECOMMENDED subjects--
export const STUDENT_DASHBOARD_HOMEPAGE_RECOMMENDED_SUBJECTS_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_RECOMMENDED_SUBJECTS_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_RECOMMENDED_SUBJECTS_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_RECOMMENDED_SUBJECTS_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_RECOMMENDED_SUBJECTS_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_RECOMMENDED_SUBJECTS_FAILED";

export function student_recommended_subjects_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_RECOMMENDED_SUBJECTS_INIT
  };
}
export function student_recommended_subjects_success(data) {



  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_RECOMMENDED_SUBJECTS_SUCCESS,
    payload: data.results
  };
}
export function student_recommended_subjects_fail(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_RECOMMENDED_SUBJECTS_FAILED,
    payload: error
  };
}
const CACHE_RECOMMENDED_SUBJECT = 'studentRecommendedSubject';
const invalidateCacheRecommendedSubject = () => {
  try {
    localStorage.removeItem(CACHE_RECOMMENDED_SUBJECT);
    localStorage.removeItem(`${CACHE_RECOMMENDED_SUBJECT}_timestamp`);
  } catch (error) {

  }
};
export function student_recommended_subjects_start(data) {
  return (dispatch) => {
    dispatch(student_recommended_subjects_init());
    const cachedData = localStorage.getItem(CACHE_RECOMMENDED_SUBJECT);
    const cachedTimestamp = localStorage.getItem(`${CACHE_RECOMMENDED_SUBJECT}_timestamp`);
    const currentTime = Date.now();
    //---
    if (cachedData && cachedTimestamp) {
      const expirationTime = parseInt(cachedTimestamp, 20) + 20 * 60 * 1000; // 20 minutes
      if (currentTime < expirationTime) {
        dispatch(student_recommended_subjects_success(JSON.parse(cachedData)));
      } else {
        invalidateCacheRecommendedSubject();
      }
    }

    axios
      .post(
        `/opanda/student/recommendedsubject`,
        {
          username: data.username,
          userId: data.userId,
          day: data.day,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        const cachedData = localStorage.getItem(CACHE_RECOMMENDED_SUBJECT);
        const cachedTimestamp = localStorage.getItem(`${CACHE_RECOMMENDED_SUBJECT}_timestamp`);

        //----Save to cache data--
        localStorage.setItem(CACHE_RECOMMENDED_SUBJECT, JSON.stringify(response.data));
        localStorage.setItem(`${CACHE_RECOMMENDED_SUBJECT}_timestamp`, Date.now().toString());
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_recommended_subjects_success(response.data));
        }

      })
      .catch((err) => {
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_recommended_subjects_fail(err.message));
        }

      });
  };
}
export function teacher_recommended_subjects_start(data) {
  return (dispatch) => {
    dispatch(student_recommended_subjects_init());
    const cachedData = localStorage.getItem(CACHE_RECOMMENDED_SUBJECT + "" + data.academic_level);
    const cachedTimestamp = localStorage.getItem(`${CACHE_RECOMMENDED_SUBJECT + "" + data.academic_level}_timestamp`);
    const currentTime = Date.now();
    //---
    if (cachedData && cachedTimestamp) {
      const expirationTime = parseInt(cachedTimestamp, 20) + 20 * 60 * 1000; // 20 minutes
      if (currentTime < expirationTime) {
        dispatch(student_recommended_subjects_success(JSON.parse(cachedData)));
      } else {
        invalidateCacheRecommendedSubject();
      }
    }

    axios
      .post(
        `/opanda/student/recommendedsubject`,
        {
          username: data.username,
          userId: data.userId,
          day: data.day,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        const cachedData = localStorage.getItem(CACHE_RECOMMENDED_SUBJECT + "" + data.academic_level);
        const cachedTimestamp = localStorage.getItem(`${CACHE_RECOMMENDED_SUBJECT + "" + data.academic_level}_timestamp`);

        //----Save to cache data--
        localStorage.setItem(CACHE_RECOMMENDED_SUBJECT + "" + data.academic_level, JSON.stringify(response.data));
        localStorage.setItem(`${CACHE_RECOMMENDED_SUBJECT + "" + data.academic_level}_timestamp`, Date.now().toString());
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_recommended_subjects_success(response.data));
        }

      })
      .catch((err) => {
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_recommended_subjects_fail(err.message));
        }

      });
  };
}
//---QUESTION OF THE DAY--
export const STUDENT_DASHBOARD_HOMEPAGE_QUESTION_OF_THE_DAY_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_QUESTION_OF_THE_DAY_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_QUESTION_OF_THE_DAY_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_QUESTION_OF_THE_DAY_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_QUESTION_OF_THE_DAY_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_QUESTION_OF_THE_DAY_FAILED";

export function student_question_of_the_day_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_QUESTION_OF_THE_DAY_INIT
  };
}
export function student_question_of_the_day_success(data) {



  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_QUESTION_OF_THE_DAY_SUCCESS,
    payload: data.results
  };
}
export function student_question_of_the_day_fail(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_QUESTION_OF_THE_DAY_FAILED,
    payload: error
  };
}

export function student_question_of_the_day_start(data) {
  return (dispatch) => {
    dispatch(student_question_of_the_day_init());

    axios
      .post(
        `/opanda/student/getthequestionoftheday`,
        {
          username: data.username,
          userId: data.userId,
          day: data.day,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {

        dispatch(student_question_of_the_day_success(response.data));
      })
      .catch((err) => {
        dispatch(student_question_of_the_day_fail(err.message));
      });
  };
}

//---ANSWER QUESTION OF THE DAY--
export const STUDENT_DASHBOARD_HOMEPAGE_ANSWER_QUESTION_OF_THE_DAY_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_ANSWER_QUESTION_OF_THE_DAY_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_ANSWER_QUESTION_OF_THE_DAY_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_ANSWER_QUESTION_OF_THE_DAY_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_ANSWER_QUESTION_OF_THE_DAY_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_ANSWER_QUESTION_OF_THE_DAY_FAILED";

export function student_answer_question_of_the_day_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_ANSWER_QUESTION_OF_THE_DAY_INIT
  };
}
export function student_answer_question_of_the_day_success(data) {


  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_ANSWER_QUESTION_OF_THE_DAY_SUCCESS,
    payload: data
  };
}
export function student_answer_question_of_the_day_fail(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_ANSWER_QUESTION_OF_THE_DAY_FAILED,
    payload: error
  };
}

export function student_answer_question_of_the_day_start(data) {


  return (dispatch) => {
    dispatch(student_answer_question_of_the_day_init());

    axios
      .post(
        `/opanda/student/answerthequestionoftheday`,
        {
          username: data.username,
          userId: data.userId,
          day: data.day,
          academic_level: data.academic_level,
          question_id: data.question_id,
          answers_ids: data.answers_ids

        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {

        dispatch(student_answer_question_of_the_day_success(response.data));
      })
      .catch((err) => {
        dispatch(student_answer_question_of_the_day_fail(err.message));
      });
  };
}

//---STATS_OF_LAST_WEEK--
export const STUDENT_DASHBOARD_HOMEPAGE_STATS_OF_LAST_WEEK_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_STATS_OF_LAST_WEEK_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_STATS_OF_LAST_WEEK_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_STATS_OF_LAST_WEEK_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_STATS_OF_LAST_WEEK_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_STATS_OF_LAST_WEEK_FAILED";

export function student_stats_of_last_week_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_STATS_OF_LAST_WEEK_INIT
  };
}
export function student_stats_of_last_week_success(data) {


  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_STATS_OF_LAST_WEEK_SUCCESS,
    payload: data
  };
}
export function student_stats_of_last_week_fail(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_STATS_OF_LAST_WEEK_FAILED,
    payload: error
  };
}

export function student_stats_of_last_week_start(data) {


  return (dispatch) => {
    dispatch(student_stats_of_last_week_init());

    axios
      .post(
        `/opanda/student/lastweekactivities`,
        {
          username: data.username,
          userId: data.userId,
          day: data.day,

        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {

        dispatch(student_stats_of_last_week_success(response.data));
      })
      .catch((err) => {
        dispatch(student_stats_of_last_week_fail(err.message));
      });
  };
}

