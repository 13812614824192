import {useState} from 'react';
import { IoSettingsSharp } from 'react-icons/io5';
import { BsCheckLg } from 'react-icons/bs';
import { RiErrorWarningFill } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion/dist/framer-motion';

import PageContent from "../../pageContent";
import Sidebar  from '../Sidebar/Sidebar';
import { CardBody, CardTitle, 
         FeatureTitle, PageBody, 
         SectionTitle, PageTitle 
} from '../../../../elements/text';
import { BookInfoCard, PaymentOptionsCard } from "../../../../elements/card";
import { Button, ButtonLink } from "../../../../elements/button";
import Input from "../../../../elements/input";
import Modal from "../../../../elements/modal/modal";
import { DynamicModal } from "../../../../elements/modal/DynamicModal";
import { MdOutlineArrowBackIosNew, MdOutlineNavigateNext } from 'react-icons/md';

const suggestedBooks = [
  {id: 1, name: 'Trigonometry', author: 'Cedric Diego', datePublished: 'Book, 2005', category: 'Math', price: '5000', currency: 'Rwf'},
  {id: 2, name: 'Geography', author: 'John Doe', datePublished: 'Book, 2005', price: '5000', category: 'Geography', currency: 'Rwf'},
  {id: 3, name: 'Physics', author: 'John Doe', datePublished: 'Book, 2005', price: '5000', category: 'Physics', currency: 'Rwf'},
  {id: 4, name: 'Biology', author: 'Cedric Diego', datePublished: 'Book, 2005', price: '5000', category: 'Biology', currency: 'Rwf'},
  {id: 5, name: 'Chemistry', author: 'John Doe', datePublished: 'Book, 2005', price: '5000', category: 'Math', currency: 'Rwf'},
  // {id: 6, name: 'Solar System', author: 'Cedric Diego', datePublished: 'Book, 2005', category: 'Physics', price: '5000', currency: 'Rwf'},
  // {id: 7, name: 'Forces', author: 'John Doe', datePublished: 'Book, 2005', category: 'Physics', price: '5000', currency: 'Rwf'},
  // {id: 7, name: 'Equations', author: 'Cedric Diego', datePublished: 'Book, 2005', category: 'Math', price: '5000', currency: 'Rwf'},
]
const children = [
  {id: 1, name: "Mutabazi Aime"},
  {id: 2, name: "Nikuze Anne"}
]

const MainBooksMarket = () => {
  const history = useHistory();

  const [classes, setClasses]=useState()
  const [search, setSearch]=useState()
  const [showBuyModal, setshowBuyModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showFailModal, setShowFailModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [checked, setChecked] = useState([])
  const [loading, setLoading] = useState(false)
  const [ toggle, setToggle ] = useState(false)

  return (
    <PageContent>
      {/* SELECT WHO OWN A BOOK WHEN CLICKED ON BUY BUTTON */}
      <DynamicModal
        show={showBuyModal}
        modalClosed={() => setshowBuyModal(false)}
        top={'20%'}
        right={'30%'}
        width={'40%'}
      >
        <div className="flex flex-col items-center justify-between h-full w-full">
          <SectionTitle name="Buy book for:" alignment="center" />
          <div className="m-4 flex flex-col">
          {children.map((child)=>(
            <span className="py-2 flex" key={child.id}> 
               <input
             type="checkbox"
             value={checked}
             onChange={(event) => setChecked(event.target.checked)}
             additional="mx-4"
           /> <CardBody name={child.name} />
            </span>
          ))}
          </div>
          <div className="flex flex-row items-center justify-between w-80% md:w-60%">
            <Button
              name="Cancel"
              outline="true"
              color="blue"
              clicked={()=>{setshowBuyModal(false)}}
            />
            <Button
              name="Continue"
              outline="false"
              color="blue"
              clicked={()=>{
                history.push('/parent/marketplace/books/payment')
                // setShowPaymentModal(true);
                setshowBuyModal(false);
                // setLoading(true);
                // setShowLoadingModal(true);
              }}
            />
          </div>
        </div>
      </DynamicModal>
      {/* END SELECT CHOOSE BOOK OWNER */}
    <div className="flex w-full h-full">
      <div className="w-1/4 h-full bg-gray-100 p-4 hidden lg:block">
      <Input 
      elementType="select"
      elementConfig={{
        startingValue: "SELECT",
        options: [
          { value: "s1", displayValue: "S1"},
          { value: "s2", displayValue: "S2"},
          { value: "s3", displayValue: "S3"},
        ]
      }}
      value={classes}
      changed={setClasses}
      />
      <Input 
      elementType="text"
      elementConfig={{
        type: "text",
        placeholder: 'Search'
      }}
      value={search}
      changed={setSearch}
      additional="rounded-full h-30 my-4"
      />
      {suggestedBooks.map((book)=> (
        <div key={book.id} className="py-2">
          <CardBody name={book.category} color="blue" />
        </div>
      ))}
      </div>
    <div className="h-full bg-gray-100 relative shadow-md lg:hidden"> 
    {toggle ? '' : (
    <motion.div 
    whileInView={{ x: [-20 , 0] }} 
    whileHover={{ scale: 1.1 }}
    className="w-15 h-15 absolute bg-blue rounded-r-full"> 
    <MdOutlineNavigateNext color="white" size={30} onClick={() => setToggle(true)} />
    </motion.div>
    )}
    
    {toggle ? (
      <motion.div
      whileInView={{ x: [-100, 0] }}
      transition={{ duration: 0.85, ease:'easeOut'}}
      className="w-90% h-full"
      >
        <motion.div 
          whileInView={{ x: [-20 , 0] }}
          whileHover={{ scale: 1.1 }}
          className="w-15 h-15 absolute bg-blue rounded-r-full"> 
          <MdOutlineArrowBackIosNew color="white" size={30} onClick={() => setToggle(false)} className="p-2"/> 
        </motion.div>
      <div className='p-4'>
      <Input 
      elementType="select"
      elementConfig={{
        startingValue: "SELECT",
        options: [
          { value: "s1", displayValue: "S1"},
          { value: "s2", displayValue: "S2"},
          { value: "s3", displayValue: "S3"},
        ]
      }}
      value={classes}
      changed={setClasses}
      />
      <Input 
      elementType="text"
      elementConfig={{
        type: "text",
        placeholder: 'Search'
      }}
      value={search}
      changed={setSearch}
      additional="rounded-full h-30 my-4"
      />
      {suggestedBooks.map((book)=> (
        <div key={book.id} className="py-2">
          <CardBody name={book.category} color="blue" />
        </div>
      ))}
      </div>

      </motion.div>
    ) : ''}
    </div>
    <div className="w-full h-full p-4 lg:w-3/4 overflow-x-auto">
      <FeatureTitle name='Market place' />
      <div className="flex py-4 flex-col lg:flex-row">
      <div className="w-full lg:w-3/4">
      <CardBody name='Mollit aute officia sint et adipisicing veniam non excepteur sunt est aliqua id ut. Labore commodo elit ipsum consectetur sit dolore in do sunt. Ipsum sit exercitation officia sunt ut quis non occaecat. Commodo esse dolor cillum non ad voluptate eu. 
        Occaecat incididunt anim ut tempor sit. Proident adipisicing consequat adipisicing veniam eu dolore culpa eiusmod eu. 
        Nostrud cillum reprehenderit id aliqua sunt ut.' />
      </div>
      <div className='lg:w-1/4 flex items-start lg:justify-center p-2'>
          <Button
              name="My Packages"
              outline="false"
              color="blue"
              isSquare={true}
              clicked={()=>{
                history.push('/parent/marketplace/books/purchasedbooks');
              }}
            />
      </div>
      </div>
      <div className="w-full p-1">
      <CardTitle name="Suggested books" />
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {suggestedBooks.map((item) => (
        <BookInfoCard 
        key={item.id}
        color="white"
        additionalStyles="w-40"
        >
          <SectionTitle name={item.name} color="blue"/>
          <CardTitle name={item.author} color="gray-500"/>
          <CardBody name={item.datePublished} color="gray-500" />
          <span className="py-4" />
          <SectionTitle name={`${item.currency} ${item.price}`} color="blue"/>
          <Button
            name="Buy"
            outline="true"
            color="red"
            additional="w-30"
            clicked={() => {setshowBuyModal(true)}}
              />
        </BookInfoCard>
      ))}
      </div>
      </div>
    </div>
  </div>
    </PageContent>
  )
}

export default MainBooksMarket
