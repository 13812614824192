import { useEffect, useState } from "react";
import { AiFillCloseSquare } from "react-icons/ai";
import { MdAddBox } from "react-icons/md";
import { useDispatch } from "react-redux";
import constants from "../../../../../../constants/constants";
import { setCurrentQuestion } from "../../../../../../store/actions/features/tests";
import Input from "../../../../../elements/input";
import RichTextEditor from "../../../../../elements/RichTextEditor";
import { CardBody } from "../../../../../elements/text";

const AddCompleteSentenceQuestion = (props) => {
  const dispatch = useDispatch();
  const [marks, setMarks] = useState(2);
  const [duration, setDuration] = useState(2);
  const [question, setQuestion] = useState("");
  const [selectedWords, setSelectedWords] = useState([]);

  // useEffect(() => {
  //   dispatch(
  //     setCurrentQuestion({
  //       type: constants.complete_sentence,
  //       marks: marks,
  //       duration: duration,
  //       question: question,
  //       selectedWords: selectedWords,
  //     })
  //   );
  // }, [dispatch, marks, duration, question, selectedWords]);

  let selection;
  const onMouseUpCapture = () => {
    selection = window.getSelection().toString();
    if (selection && selection.trim().split(" ").length === 1) {
      selection = selection.trim();
      if (!props.question.selectedWords.some(element => element.word === selection)) {
        //setSelectedWords([...selectedWords, selection]);
        props.updateCompleteSentenceQuestionSelectedWord(props?.questionNber, [...props.question.selectedWords, { word: selection, index: props.questionNber }])
      }
    }
  };
  const removeWord = (val) => {
    if (window.confirm("Are you sure you want to remove this option?")) {
      //setSelectedWords(selectedWords.filter((option) => option !== val));
      props.updateCompleteSentenceQuestionSelectedWord(props?.questionNber, props.question.selectedWords.filter((option) => (option.word !== val)))
    }
  };

  return (
    <div className="w-70% border-t border-gray-500">
      <div className="flex space-x-2">
        <Input
          label="Marks"
          elementType="input"
          elementConfig={{
            type: "text",
            placeholder: "Enter question marks here",
          }}
          value={props.question.marks}
          changed={(mark) => {
            props.updateCompleteSentenceQuestionMarks(props.questionNber, mark);
          }}
        />
        <Input
          label="Duration"
          elementType="input"
          elementConfig={{
            type: "text",
            placeholder: "Enter test duration here",
          }}
          value={props.question.duration}
          changed={(time) => { props.updateCompleteSentenceQuestionDuration(props.questionNber, time) }}
        />
      </div>
      <div onMouseUpCapture={onMouseUpCapture}>
        <RichTextEditor
          value={props.question.question}
          onChange={(text) => {
            props.updateCompleteSentenceQuestionData(props.questionNber, text);
          }}
          placeholder="Write your question here"
        />
      </div>
      <div>
        <CardBody name="Marked Answers" additional="font-semibold" />
        <div className="flex flex-col space-y-2">
          {props.question.selectedWords.map((word, i) => (
            <div className="flex items-center space-x-2" key={i}>
              <span className="bg-gray-500 text-white text-center py-1 px-2 rounded-md">
                {word.word}
              </span>
              {/* <MdAddBox
                color="#183F71"
                size={40}
                className="cursor-pointer"
                onClick={() => { }}
              /> */}
              <AiFillCloseSquare
                color="#BE1E2D"
                size={40}
                className="cursor-pointer"
                onClick={() => removeWord(word.word)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddCompleteSentenceQuestion;
