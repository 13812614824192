import { useState, useEffect, Fragment } from "react";
import { BiChevronDown } from "react-icons/bi";
import NotificationCard from "./notificationCard";

const Notification = (props) => {
  let notifications = [
    //basic notification
    {
      name: "Mucyo Eric",
      subjects: ["Chemistry", "Math"],
      type: "schedule",
      schedules: [
        {
          subjects: "Chemistry",
          unit: "Lab materials",
          createdAt: "07/02/2022",
          date: "10/02/2022",
          time: "8:00 - 9:00",
        },
        {
          subjects: "Chemistry",
          unit: "Lab materials",
          createdAt: "07/02/2022",
          date: "10/02/2022",
          time: "8:00 - 9:00",
        },
      ],
    },
    {
      title: "GUEST",
      titleColor: "yellow",
      body: "Access your homework/quiz",
      bodyColor: "black",
      action: "Enter code",
      link: "/previewpanda/guest",
      requireAuth: false,
      guest: true,
    },
    {
      title: "NEW!",
      titleColor: "red",
      body: "A new lab has been added",
      bodyColor: "black",
      action: "Go for it",
      link: "/previewpanda/guest",
      image: "/images/preview/bio.svg",
      type: "new",
      requireAuth: false,
    },
    {
      title: "Reminder",
      titleColor: "red",
      body: "You have an online class Scheduled tomorrow",
      bodyColor: "black",
      action: "Details",
      link: "/previewpanda/guest",
      type: "reminder",
      image: "/images/preview/bio.svg",
      requireAuth: false,
    },
    {
      title: "Your Achievements",
      titleColor: "black",
      action: "More",
      link: "/previewpanda/guest",
      type: "achievement",
      list: [
        { name: "You are now Gold in Reader", color: "#E8AD26" },
        { name: "You completed a Maths course", color: "#BE1E2D" },
        { name: "You finished three tests", color: "#BE1E2D" },
      ],
      requireAuth: true,
    },
    {
      title: "Question of the day",
      titleColor: "black",
      question: "What is the name of the first computer?",
      answers: ["Abacus", "Hewlett-Packard", "Desktop"],
      action: "Submit",
      link: "/previewpanda/guest",
      type: "question",
      requireAuth: true,
    },
    {
      title: "Your Activity",
      titleColor: "black",
      time: "last week",
      activities: [
        {
          name: "Simulations",
          value: 3,
          color: "red",
          hexColor: "#BE1E2D",
        },
        {
          name: "Courses",
          value: 2,
          color: "blue",
          hexColor: "#183F71",
        },
        {
          name: "Test",
          value: 5,
          color: "yellow",
          hexColor: "#E8AD26",
        },
      ],
      type: "activity",
      requireAuth: true,
    },
    {
      title: "Right now",
      titleColor: "black",
      subTitle: "Your friend",
      action: "is viewing a lab:",
      actionName: "Bio Reproduction Lab ",
      link: "/lab",
      type: "now",
      requireAuth: true,
    },
  ];
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(2);
  useEffect(() => {
    if (props.auth) {
      setLimit(1);
    }
  }, [props.auth]);

  if (props.auth) {
    notifications = notifications?.filter(
      (notification) => notification.guest !== true
    );
  } else {
    notifications = notifications?.filter(
      (notification) => notification.requireAuth !== true
    );
  }
  let dispayedNotifications = notifications?.slice(start, start + limit);
  const handleScroll = () => {
    if (notifications?.length === start + limit) {
      setStart(0);
    } else {
      setStart((prevStart) => prevStart + 1);
    }
  };
  let classes = "top-0 right-0 md:absolute w-full md:w-64 lg:w-80 xl:w-96 z-20";
  if (props.auth) {
    classes = "flex flex-col space-y-4 items-end md:w-96 md:hidden";
  }

  return (
    <Fragment>
      <div className={classes}>
        <div className="w-full flex flex-col space-y-2">
          {dispayedNotifications.map((notification, index) => (
            <NotificationCard
              auth={props.auth}
              key={index}
              notification={notification}
            />
          ))}
        </div>
        <div className="flex justify-center w-full md:w-64 lg:w-80 xl:w-96 pt-2">
          <BiChevronDown
            className="rounded bg-white shadow cursor-pointer"
            size="24"
            color="#183F71"
            onClick={handleScroll}
          />
        </div>
      </div>
      <div className={props.auth ? "w-full md:block hidden" : "hidden"}>
        <div className="w-full flex flex-col space-y-2">
          {notifications.map((notification, index) => (
            <NotificationCard
              auth={props.auth}
              key={index}
              notification={notification}
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default Notification;
