import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ButtonLink } from "../../../elements/button";
import { HomeCard } from "../../../elements/card";
import Spinner from "../../../elements/spinner";
import { SectionTitle } from "../../../elements/text";
const Whoisitfor = (props) => {
  const whoisopandafor = useSelector((state) => state.home.whoisopandafor);
  const whoisopandaforLoading = useSelector(
    (state) => state.home.whoisopandaforLoading
  );
  const { t } = useTranslation();
  return (
    <div className="bg-white my-4">
      <div className="w-full h flex flex-col justify-center align-center pt-8 pb-4 space-y-16 max-w-7xl mx-auto px-8">
        <SectionTitle
          name={t("Who is O'Genius Panda for?")}
          color="blue"
          alignment="center"
        />
        {whoisopandaforLoading ? (
          <div className="flex flex-row justify-center">
            <Spinner size="20" color="blue" />
          </div>
        ) : (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-2 items-start">
            {whoisopandafor?.map((isfor, key) => (
              <HomeCard
                key={key}
                user={isfor.usertype}
                body={isfor.valuepropositiontotheuser}
              />
            ))}
          </div>
        )}

        <div className="mx-auto">
          <ButtonLink
            page="whoisitfor"
            name={t("Learn More")}
            outline="true"
            color="red"
          />
        </div>
      </div>
    </div>
  );
};
export default Whoisitfor;
