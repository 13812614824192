import React, { useState } from 'react';
import { TextButton } from './button';
import { CardBody, CardTitle } from './text';

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item divide-y">
      <div className="w-full flex flex-row justify-between align-start space-y-2" onClick={() => setIsActive(!isActive)}>
        <CardTitle name={title} color="blue" additional="cursor-pointer"/>

       <TextButton name={isActive ? '- Strategies' : '+ Strategies'} color="blue"/>
      </div>
      {isActive && <CardBody name={content} color="blue" additional="pt-2"/>}
    </div>
  );
};

export default Accordion;