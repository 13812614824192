import { useEffect, useRef, useState } from "react";
import parse from "react-html-parser";
import { FaComments, FaFlask, FaLock, FaLockOpen } from "react-icons/fa";
import { AiOutlineLeftCircle, AiOutlineRightCircle } from "react-icons/ai";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { MdEditNote, MdMovie } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  changeChosenSubjectSummarynotes,
  changeChosenUnitSummarynotes,
} from "../../../../store/actions/features/summarynotes";
import Comments from "../../../common/features/Comments";
import VideosAndSimulations from "../../../common/features/VideosAndSimulations";
import { Button } from "../../../elements/button";
import Input from "../../../elements/input";
import { CardBody, FeatureTitle } from "../../../elements/text";
import PageContent from "../pageContent";
import EndOfResources from "../../../common/features/EndOfResources";
import TextSelector from "../../../elements/TextSelector";
import { useParams } from "react-router-dom";
import {
  getAllTheSubjects,
  getCoursePages,
  getCoursePagesOtherResource,
  GetUnitsFromSelectedSubjects,
  GetUnitsFromSelectedSubjectsFilterByUnit,
  GetUnitsFromSelectedSubjectsFilterByUnitForTeacher,
  GetUnitsFromSelectedSubjectsForTeacher,
} from "../../../../functions/curriculumcbcsubjectsunits";
import { TabPanel, useTabs } from "react-headless-tabs";
import {
  student_get_selected_unit_with_labs_docs_videos_start,
  student_subjects_by_academic_units_start,
  teacher_get_selected_unit_with_labs_docs_videos_start,
  teacher_subjects_by_academic_units_start,
} from "../../../../store/actions/student.dashboard.curriculum.cbc";
import Spinner from "../../../elements/spinner";
import TabSelector from "../../../common/features/TabSelector";
import GetStarted from "../../../common/features/GetStarted";
import { FeatureItemCard } from "../../../elements/card";
import NotFound from "../../../common/features/NotFound";
import { GrClose } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";

const SummaryNote = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const courses_params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchterm, setSearchterm] = useState("");
  const units = [
    { name: "Deserunt laboris", viewed: true },
    { name: "Adipisicing ", viewed: false },
    { name: "Dolore minim", viewed: false },
    { name: "Dolore ex ", viewed: false },
    { name: "Sint eiusmod fugiat.", viewed: false },
    { name: "Adipisicing occaecat", viewed: false },
    { name: "Dolore minimum", viewed: false },
    { name: "Dolore ex enim", viewed: false },
  ];
  const chosenUnit = useSelector(
    (state) => state.features.summarynotes.chosenUnit
  );
  const [showComments, setShowComments] = useState(false);
  const [showvideos, setShowvideos] = useState(false);
  const [vidSimDoc, setVidSimDoc] = useState("Videos");
  const [showUnits, setShowUnits] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [subject, setSubject] = useState("BIOLOGY");

  const [course_id, setCourse_id] = useState("");
  const [page_loaded, setPage_loaded] = useState(true);
  const [pickedNewUnitAfterView, setPickedNewUnitAfterView] = useState(false);
  const [commentsCount, setcommentsCount] = useState(0);

  const userAth = useSelector((state) => state.auth);
  const studentDashboardCurriculumCbc = useSelector(
    (state) => state.studentDashboardCurriculumCbc
  );

  const userId = useSelector((state) => state.auth.user.id);
  const [selectedAcademiclevel, setSelectedAcademiclevel] = useState(1);
  const [selectedLevel, setSelectedLevel] = useState(`Senior ${userAth.academic_level}`);
  const selectedText = useSelector(
    (state) => state.languages.selectedCommentText
  );

  const panelDiv = useRef();

  const scrollTop = () => {
    panelDiv.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  const academic_level_data = ["one", "two", "three", "four", "five", "six"];
  // let getUnitsFromSelectedSubjects=[];
  // let getUnitsFromSelectedSubjects_Unfiltered=[];

  const getUnitsFromSelectedSubjects = GetUnitsFromSelectedSubjectsForTeacher(
    studentDashboardCurriculumCbc.cbc_subjects_units,
    userAth.userId
  )[0];
  const getUnitsFromSelectedSubjects_Unfiltered =
    GetUnitsFromSelectedSubjectsForTeacher(
      studentDashboardCurriculumCbc.cbc_subjects_units,
      userAth.userId
    )[1];
  const tabs = getUnitsFromSelectedSubjects.map((unit) => unit.name);
  tabs.unshift("initial page");

  const [selectedUnit, setSelectedUnit] = useTabs(
    tabs,
    chosenUnit ? chosenUnit : "initial page v1"
  );
  const [selectedTab, setSelectedTab] = useTabs(
    ["All", "O'Genius Panda", "Community", "My Notes"],
    "O'Genius Panda"
  );

  useEffect(() => {
    // Set academic level from url data
    if (params?.academic_level) {
      setSelectedAcademiclevel(params?.academic_level);
      setSelectedLevel(params?.academic_level);
    }
    if (params?.subject) {
      setShowUnits(true);
      dispatch(
        teacher_subjects_by_academic_units_start({
          token: userAth.token,
          subject: params.subject,
          academic_level: (params?.academic_level) ? params?.academic_level : selectedAcademiclevel,
          user_id: userAth.userId,
        })
      );
    } else {
      setShowUnits(true);
      dispatch(
        teacher_subjects_by_academic_units_start({
          token: userAth.token,
          subject: "BIOLOGY",
          academic_level: (params?.academic_level) ? params?.academic_level : selectedAcademiclevel,
          user_id: userAth.userId,
        })
      );
    }
    //--
    //----Get The Content for Summary notes--
    if (courses_params?.bookId && parseInt(courses_params?.bookId) > 0) {
      setCourse_id(courses_params.bookId);
    } else {
      setShowUnits(true);
      setSelectedUnit("initial page");
      dispatch(changeChosenUnitSummarynotes("initial page"));
    }
    //-------Get units data--------
    if (params?.subject && params?.unit) {
      dispatch(
        teacher_get_selected_unit_with_labs_docs_videos_start({
          unit_id: courses_params.bookId,
          subject: params.subject,
          academic_level: (params?.academic_level) ? params?.academic_level : selectedAcademiclevel,
          user_type: userAth?.user.user_type,
          user_id: userAth.userId,
          token: userAth.token,
        })
      );
      setPage_loaded(false);
    }else{
      setPickedNewUnitAfterView(true)

    }
    //--
    if (params?.unit) {
      if (page_loaded) {
        setPickedNewUnitAfterView(false);
        setPageNumber(0);
        setPage_loaded(false);
        setSelectedUnit(params.unit);
        setCourse_id(courses_params.bookId);
        dispatch(changeChosenUnitSummarynotes(params.unit));
      }
    } else if (params?.subject) {
      if (page_loaded) {
        setSubject(params.subject);
        setPickedNewUnitAfterView(true);
        setPage_loaded(false);
        setSelectedUnit("initial page");
        dispatch(
          teacher_subjects_by_academic_units_start({
            token: userAth.token,
            subject: params.subject,
            academic_level: (params?.academic_level) ? params?.academic_level : selectedAcademiclevel,
            user_id: userAth.userId,
          })
        );
      }
    }
    //-------
    //scrollTop();
  }, [selectedAcademiclevel]);
  //----
  const getAllSubjects = getAllTheSubjects(
    studentDashboardCurriculumCbc.cbc_subjects_units
  );

  const loadNewSubjectunits = (thesubject) => {
    setSubject(thesubject);
    setSelectedUnit("initial page");
    dispatch(changeChosenUnitSummarynotes("initial page"));
    history.push(`/teacher/features/summarynotes/0?subject=${thesubject}&academic_level=${selectedAcademiclevel}`);
    dispatch(
      teacher_subjects_by_academic_units_start({
        token: userAth.token,
        subject: thesubject,
        academic_level: selectedAcademiclevel,
        user_id: userAth.userId,
      })
    );
    setPickedNewUnitAfterView(true);
  };
  ///--
  const comments_counter_pull = (newComments) => {
    setcommentsCount(newComments);
  };

  const getUnitsFromSelectedSubjectsFilterByUnit =
    GetUnitsFromSelectedSubjectsFilterByUnit(
      params.unit ? params.unit : selectedUnit,
      GetUnitsFromSelectedSubjectsForTeacher(
        studentDashboardCurriculumCbc.cbc_subjects_units,
        userAth.userId
      )[1]
    );

  const pages = getCoursePages(
    studentDashboardCurriculumCbc?.get_selected_unit_with_labs_docs_videos ?? []
  );

  const pages_resources = getCoursePagesOtherResource(
    studentDashboardCurriculumCbc?.get_selected_unit_with_labs_docs_videos
  );
 
  return (
    <PageContent>
      <div
        className={`${showComments || showvideos ? "relative" : ""
          } md:grid grid-cols-12 w-full h-full`}
      >
        <div
          className={`px-4 bg-gray-200 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100 p-3 bg-gray-200 ${showUnits ? "h-screen-sec" : ""
            } md:h-full col-span-3`}
        >
          <div className="flex justify-between items-center">
            <div className="w-50%">
              {!studentDashboardCurriculumCbc.cbc_subjects_units_loading &&
                getAllSubjects.length > 0 ? (
                <Input
                  elementType="select"
                  elementConfig={{
                    startingValue: "SELECT",
                    options: [...getAllSubjects],
                  }}
                  value={
                    studentDashboardCurriculumCbc.cbc_subjects_units[0][0]
                      .subject
                  }
                  changed={setSubject}
                  loadNewSubjectunits={loadNewSubjectunits}

                  validation={{ required: true }}
                  shouldValidate
                  error="Subject is required"
                />
              ) : studentDashboardCurriculumCbc.cbc_subjects_units_loading ? (
                <Spinner size="10" color="blue" />
              ) : (
                <div></div>
              )}
            </div>
            {/* <CardBody
              name={`Senior ${
                academic_level_data[parseInt(userAth.academic_level) - 1]
              }`}
              color="blue"
            /> */}
            <Input
              label=""
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                // disabled: "disabled",
                options: [
                  { value: "1", displayValue: "Senior 1" },
                  { value: "2", displayValue: "Senior 2" },
                  { value: "3", displayValue: "Senior 3" },
                  { value: "4", displayValue: "Senior 4" },
                  { value: "5", displayValue: "Senior 5" },
                  { value: "6", displayValue: "Senior 6" },
                ],
              }}
              value={selectedLevel}
              changed={setSelectedLevel}
              setSelectedAcademiclevel={(pickedlevel) => {
                setSelectedAcademiclevel(pickedlevel)
                setSelectedUnit("initial page");
                dispatch(changeChosenUnitSummarynotes("initial page"));
                history.push(`/teacher/features/summarynotes/0?subject=${subject}&academic_level=${pickedlevel}`);
              }}
              validation={{ required: true }}
              shouldValidate
              error="Level is required"
            />
            {showUnits ? (
              <BiChevronUp
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowUnits(!showUnits)}
              />
            ) : (
              <BiChevronDown
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowUnits(!showUnits)}
              />
            )}
          </div>
          <div className={`${showUnits ? "" : "hidden"} md:block`}>
            <Input
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Search...",
              }}
              value={searchterm}
              changed={setSearchterm}
            />
            <ul className="py-5 h-100">
              {!studentDashboardCurriculumCbc.cbc_subjects_units_loading &&
                getUnitsFromSelectedSubjects.length > 0 ? (
                getUnitsFromSelectedSubjects.map((unit, index) =>
                  searchterm === "" ||
                    unit.name.toLowerCase().includes(searchterm.toLowerCase()) ? (
                    <li
                      key={index}
                      className="flex items-center justify-between py-2 cursor-pointer"
                      onClick={() => {
                        history.push(
                          `/teacher/features/summarynotes/${0}?subject=${unit.subject
                          }&academic_level=${selectedAcademiclevel}`
                        );
                        dispatch(changeChosenUnitSummarynotes(unit.name));
                        setSelectedUnit(unit.name);
                        setShowUnits(false);
                        //---load the units For O'Genius Panda--
                        setPickedNewUnitAfterView(true);
                        setcommentsCount(0);

                        //---
                      }}
                    >
                      <CardBody
                        size={14}
                        name={`${unit.name}`}
                        color="blue"
                        additional={unit.name === chosenUnit ? "font-bold flex-1"
                          : "flex-1"}
                      />
                      {unit.viewed ? (
                        <FaLockOpen color="#183F71" className="shrink-0 w-16px h-16px" />
                      ) : (
                        <FaLock color="#183F71" className="shrink-0 w-16px h-16px" />
                      )}
                    </li>
                  ) : (
                    <li></li>
                  )
                )
              ) : studentDashboardCurriculumCbc.cbc_subjects_units_loading ? (
                <Spinner size="10" color="blue" />
              ) : (
                <div></div>
              )}
            </ul>
          </div>
        </div>
        <div className="col-span-9 bg-white md:grid h-full grid-cols-12">
          {!pickedNewUnitAfterView ? (
            <div
              ref={panelDiv}
              className={`${showUnits ? "hidden md:block" : ""
                } md:relative p-3 bg-white col-span-11 h-screen-second md:h-full max-h-myscreen-sec min-h-myscreen-sec-mobile md:max-h-myscreen md:min-h-myscreen overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
            >
              {!studentDashboardCurriculumCbc.get_selected_unit_with_labs_docs_videos_loading &&
                getUnitsFromSelectedSubjectsFilterByUnit.length > 0 ? (
                <div>
                  <CardBody
                    name={`${chosenUnit}`}
                    color="blue"
                    additional="font-bold"
                  />
                  <TextSelector onSelection={() => setShowComments(true)}>
                    <div className="py-3">
                      {parse(pages[pageNumber] || "")}
                      {pages.length - 1 === pageNumber ? (
                        <EndOfResources
                          otherresources={
                            pages_resources.length > 0 ? pages_resources : []
                          }
                        />
                      ) : null}
                    </div>
                  </TextSelector>

                  <div className="flex justify-between items-start mb-2">
                    <Button
                      name="Previous"
                      outline="true"
                      color="blue"
                      clicked={() => {
                        if (pageNumber > 0) {
                          setPageNumber((number) => number - 1);
                        }
                      }}
                    />
                    <CardBody
                      name={`${pageNumber + 1}/${pages.length}`}
                      color="blue"
                      additional="col-span-5 text-center"
                    />
                    <div className="flex flex-col">
                      {pageNumber + 1 < pages.length ? (
                        <Button
                          name="Next"
                          outline="true"
                          color="blue"
                          clicked={() => {
                            if (pageNumber < pages.length - 1) {
                              setPageNumber((number) => number + 1);
                            }
                            if (pageNumber === pages.length - 1) {
                              setPageNumber(0);
                              dispatch(
                                changeChosenUnitSummarynotes(
                                  units[
                                    units.findIndex(
                                      (unit) => unit.name === chosenUnit
                                    ) + 1
                                  ].name
                                )
                              );
                              history.push("/teacher/features/summarynotes");
                            }
                          }}
                        />
                      ) : (
                        <div></div>
                      )}
                      {pageNumber === pages.length - 1 &&
                        units[
                        units.findIndex((unit) => unit.name === chosenUnit) +
                        1
                        ] && (
                          <CardBody
                            name={`${chosenUnit}`}
                            color="blue"
                            additional="opacity-60"
                          />
                        )}
                    </div>
                  </div>
                </div>
              ) : studentDashboardCurriculumCbc.get_selected_unit_with_labs_docs_videos_loading ? (
                <Spinner size="10" color="blue" />
              ) : (
                <div></div>
              )}
            </div>
          ) : (
            <div
              className={`${showUnits ? "hidden md:block" : ""
                } md:relative p-3 bg-white  ${!pickedNewUnitAfterView ? "col-span-11" : "col-span-12"} mb-16 pb-16 h-full max-h-myscreen-sec min-h-myscreen-sec-mobile md:max-h-myscreen md:min-h-myscreen overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}>
              {selectedUnit === "initial page" ? (
                <TabPanel
                  hidden={selectedUnit !== "initial page"}
                  className="transform transition duration-500 ease-in-out"
                >
                  <FeatureTitle name="Summary notes" />
                  <>
                    <nav className="flex justify-between md:justify-start space-x-4">
                      <TabSelector
                        isActive={selectedTab === "All"}
                        title="All"
                        onClick={() => setSelectedTab("All")}
                      />
                      <TabSelector
                        isActive={selectedTab === "O'Genius Panda"}
                        title="O'Genius Panda"
                        onClick={() => setSelectedTab("O'Genius Panda")}
                      />
                      <TabSelector
                        isActive={selectedTab === "Community"}
                        title="Community"
                        onClick={() => setSelectedTab("Community")}
                      />
                      <TabSelector
                        isActive={selectedTab === "My Notes"}
                        title="My Notes"
                        onClick={() => setSelectedTab("My Notes")}
                      />
                    </nav>
                    <TabPanel hidden={false}>
                      <GetStarted type="a unit" feature="summary notes" />
                    </TabPanel>
                  </>
                </TabPanel>
              ) : (
                <div></div>
              )}
              {selectedUnit !== "initial page" &&
                getUnitsFromSelectedSubjects_Unfiltered &&
                getUnitsFromSelectedSubjects_Unfiltered.length > 0 ? (
                <TabPanel className="transform transition duration-500 ease-in-out">
                  <FeatureTitle name="Summary notes" />
                  <>
                    <nav className="flex justify-between md:justify-start space-x-4">
                      <TabSelector
                        isActive={selectedTab === "All"}
                        title="All"
                        onClick={() => setSelectedTab("All")}
                      />
                      <TabSelector
                        isActive={selectedTab === "O'Genius Panda"}
                        title="O'Genius Panda"
                        onClick={() => setSelectedTab("O'Genius Panda")}
                      />
                      <TabSelector
                        isActive={selectedTab === "Community"}
                        title="Community"
                        onClick={() => setSelectedTab("Community")}
                      />
                      <TabSelector
                        isActive={selectedTab === "My Notes"}
                        title="My Notes"
                        onClick={() => setSelectedTab("My Notes")}
                      />
                    </nav>
                    <TabPanel hidden={selectedTab !== "All"}>
                      {getUnitsFromSelectedSubjectsFilterByUnit &&
                        getUnitsFromSelectedSubjectsFilterByUnit.length === 0 ? (
                        <NotFound feature="summary note" />
                      ) : (
                        <div className="md:grid grid-cols-3">
                          {getUnitsFromSelectedSubjectsFilterByUnit &&
                            getUnitsFromSelectedSubjectsFilterByUnit.map(
                              (note, index) => (
                                <FeatureItemCard
                                  key={index}
                                  {...note}
                                  unit={note.unit}
                                  unitNumber={note.id}
                                  feature="summary note"
                                  clicked={() => {
                                    //----MArk the unit as viewed(Push to the server livevisibility)
                                    if (note.subject) {
                                      setPickedNewUnitAfterView(false);
                                      setPageNumber(0);
                                      dispatch(
                                        teacher_get_selected_unit_with_labs_docs_videos_start(
                                          {
                                            unit_id: note.id, //courses_params.noteId,
                                            subject: note.subject,
                                            academic_level:
                                              selectedAcademiclevel,
                                            user_type: "student",
                                            user_id: userAth.userId,
                                            token: userAth.token,
                                          }
                                        )
                                      );
                                    }
                                    setSelectedUnit(note.unit);
                                    setCourse_id(note.id);
                                    history.push(
                                      `/teacher/features/summarynotes/${note.id}?subject=${note.subject}&unit=${note.unit}&academic_level=${selectedAcademiclevel}`
                                    );
                                  }}
                                />
                              )
                            )}
                        </div>
                      )}
                    </TabPanel>
                    <TabPanel hidden={selectedTab !== "O'Genius Panda"}>
                      {getUnitsFromSelectedSubjectsFilterByUnit &&
                        getUnitsFromSelectedSubjectsFilterByUnit.filter(
                          (note) => note.type === "opanda" && note.creator_id !== userId
                        ).length === 0 ? (
                        <NotFound feature="summary note" />
                      ) : (
                        <div className="md:grid grid-cols-3">
                          {getUnitsFromSelectedSubjectsFilterByUnit &&
                            getUnitsFromSelectedSubjectsFilterByUnit
                              .filter((note) => note.type === "opanda" && note.creator_id !== userId)
                              .map((note, index) => (
                                <FeatureItemCard
                                  key={index}
                                  {...note}
                                  unit={note.unit}
                                  unitNumber={note.id}
                                  feature="summary note"
                                  clicked={() => {
                                    //----MArk the unit as viewed(Push to the server livevisibility)
                                    if (note.subject) {
                                      setPickedNewUnitAfterView(false);
                                      setPageNumber(0);
                                      dispatch(
                                        teacher_get_selected_unit_with_labs_docs_videos_start(
                                          {
                                            unit_id: note.id, // courses_params.noteId,
                                            subject: note.subject,
                                            academic_level:
                                              selectedAcademiclevel,
                                            user_type: "student",
                                            user_id: userAth.userId,
                                            token: userAth.token,
                                          }
                                        )
                                      );
                                    }
                                    setSelectedUnit(note.unit);
                                    setCourse_id(note.id);
                                    history.push(
                                      `/teacher/features/summarynotes/${note.id}?subject=${note.subject}&unit=${note.unit}&academic_level=${selectedAcademiclevel}`
                                    );
                                  }}
                                />
                              ))}
                        </div>
                      )}
                    </TabPanel>
                    <TabPanel hidden={selectedTab !== "Community"}>
                      {getUnitsFromSelectedSubjectsFilterByUnit &&
                        getUnitsFromSelectedSubjectsFilterByUnit.filter(
                          (note) => note.type === "community" && note.creator_id !== userId
                        ).length === 0 ? (
                        <NotFound feature="summary note" />
                      ) : (
                        <div className="md:grid grid-cols-3">
                          {getUnitsFromSelectedSubjectsFilterByUnit &&
                            getUnitsFromSelectedSubjectsFilterByUnit
                              .filter((note) => note.type === "community" && note.creator_id !== userId)
                              .map((note, index) => (
                                <FeatureItemCard
                                  key={index}
                                  {...note}
                                  unit={note.unit}
                                  unitNumber={note.id}
                                  feature="summary note"
                                  clicked={() => {
                                    //----MArk the unit as viewed(Push to the server livevisibility)
                                    if (note.subject) {
                                      setPickedNewUnitAfterView(false);
                                      setPageNumber(0);
                                      dispatch(
                                        teacher_get_selected_unit_with_labs_docs_videos_start(
                                          {
                                            unit_id: note.id, // courses_params.noteId,
                                            subject: note.subject,
                                            academic_level:
                                              selectedAcademiclevel,
                                            user_type: "student",
                                            user_id: userAth.userId,
                                            token: userAth.token,
                                          }
                                        )
                                      );
                                    }
                                    setSelectedUnit(note.unit);
                                    setCourse_id(note.id);
                                    history.push(
                                      `/teacher/features/summarynotes/${note.id}?subject=${note.subject}&unit=${note.unit}&academic_level=${selectedAcademiclevel}`
                                    );
                                  }}
                                />
                              ))}
                        </div>
                      )}
                    </TabPanel>
                    <TabPanel hidden={selectedTab !== "My Notes"}>
                      {getUnitsFromSelectedSubjectsFilterByUnit &&
                        getUnitsFromSelectedSubjectsFilterByUnit.filter(
                          (note) => note.author === "Prepared by Me"
                        ).length === 0 ? (
                        <NotFound
                          feature="summary note or published  one(Unpublished draft)"
                          isCreate
                          create={() => {
                            dispatch(
                              changeChosenSubjectSummarynotes(params.subject)
                            );
                            history.push(`/teacher/features/summarynotes/new?subject=${params.subject}&academic_level=${selectedAcademiclevel}`);
                          }}
                        />
                      ) : (
                        <>
                          <div className="flex justify-end">
                            <Button
                              name="+ Update The Course Below"
                              outline="true"
                              color="blue"
                              clicked={() => {
                                dispatch(
                                  changeChosenSubjectSummarynotes(params.subject)
                                );
                                history.push(`/teacher/features/summarynotes/new?subject=${params.subject}&academic_level=${selectedAcademiclevel}`);

                              }}
                            />
                          </div>
                          <div className="md:grid grid-cols-3">
                            {getUnitsFromSelectedSubjectsFilterByUnit &&
                              getUnitsFromSelectedSubjectsFilterByUnit
                                .filter((note) => note.author === "Prepared by Me")
                                .map((note, index) => (
                                  <FeatureItemCard
                                    key={index}
                                    {...note}
                                    unit={note.unit}
                                    unitNumber={note.id}
                                    feature="summary note"
                                    clicked={() => {
                                      //----MArk the unit as viewed(Push to the server livevisibility)
                                      if (note.subject) {
                                        setPickedNewUnitAfterView(false);
                                        setPageNumber(0);
                                        dispatch(
                                          teacher_get_selected_unit_with_labs_docs_videos_start(
                                            {
                                              unit_id: note.id, // courses_params.noteId,
                                              subject: note.subject,
                                              academic_level:
                                                selectedAcademiclevel,
                                              user_type: "student",
                                              user_id: userAth.userId,
                                              token: userAth.token,
                                            }
                                          )
                                        );
                                      }
                                      setSelectedUnit(note.unit);
                                      setCourse_id(note.id);
                                      history.push(
                                        `/teacher/features/summarynotes/${note.id}?subject=${note.subject}&unit=${note.unit}&academic_level=${selectedAcademiclevel}`
                                      );
                                    }}
                                  />
                                ))}
                          </div>
                        </>
                      )}
                    </TabPanel>
                  </>
                </TabPanel>
              ) : (
                <div></div>
              )}
            </div>
          )}
          {!pickedNewUnitAfterView ? (
            <div
              style={{ zIndex: "10" }}
              className={`${showUnits ? "block" : ""
                } fixed w-full md:w-auto md:relative p-3 bg-gray-200 bottom-0 flex flex-row md:flex-col col-span-1 justify-between items-center`}
            >
              <div className="flex w-full justify-around md:flex-col md:items-center md:space-y-10 z-auto">
                {showUnits ? (
                  <GrClose
                    className="md:hidden"
                    size={28}
                    color="#183F71"
                    onClick={() => setShowUnits(!showUnits)}
                  />
                ) : (
                  <GiHamburgerMenu
                    className="md:hidden"
                    size={28}
                    color="#183F71"
                    onClick={() => setShowUnits(!showUnits)}
                  />
                )}
                <div className="relative cursor-pointer">
                  <FaComments
                    color="#183F71"
                    size={36}
                    onClick={() => {
                      setShowComments(!showComments);
                      setShowvideos(false);
                    }}
                  />
                  {commentsCount > 0 ? (
                    <span
                      style={{ fontWeight: "bold !important" }}
                      className="absolute top-1 left-5 inline-flex items-center justify-center px-1 py-px text-xs font-bold leading-none bg-white rounded-full"
                    >
                      {commentsCount}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
                <MdMovie
                  className="cursor-pointer"
                  size={36}
                  color="#183F71"
                  onClick={() => {
                    setVidSimDoc("Videos");
                    setShowvideos(!showvideos);
                    setShowComments(false);                    
                  }}
                />
                <MdEditNote
                  className="cursor-pointer"
                  size={36}
                  color="#183F71"
                  onClick={() => {
                    setVidSimDoc("Documents");
                    setShowvideos(!showvideos);
                    setShowComments(false);
                  }}
                />
                <FaFlask
                  className="cursor-pointer"
                  size={36}
                  color="#183F71"
                  onClick={() => {
                    setVidSimDoc("Simulations");
                    setShowvideos(!showvideos);
                    setShowComments(false);
                  }}
                />
              </div>
              {showComments || showvideos ? (
                <AiOutlineRightCircle
                  className="cursor-pointer hidden md:block"
                  size={36}
                  color="#183F71"
                  onClick={() => {
                    setShowComments(false);
                    setShowvideos(false);
                  }}
                />
              ) : (
                <AiOutlineLeftCircle
                  className="cursor-pointer hidden md:block"
                  size={36}
                  color="#183F71"
                  onClick={() => setShowComments(true)}
                />
              )}
              {showComments ? (
                <Comments
                  getcomments={comments_counter_pull}
                  selected={selectedText}
                />
              ) : null}
              {showvideos ? (
                <VideosAndSimulations
                  tabs={["Videos", "Simulations", "Documents"]}
                  tab={vidSimDoc}
                  otherresources={
                    pages_resources.length > 0 ? pages_resources : []
                  }
                />
              ) : null}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </PageContent>
  );
};

export default SummaryNote;
