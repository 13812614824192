import axios from "../../../axios-base";

export const GET_FEATURES_START = "GET_FEATURES_START";
export const GET_FEATURES_SUCCESS = "GET_FEATURES_SUCCESS";
export const GET_ONE_FEATURES_SUCCESS = "GET_ONE_FEATURES_SUCCESS";
export const GET_FEATURES_FAIL = "GET_FEATURES_FAIL";

export const GET_FEATURES_CAROUSEL_START = "GET_FEATURES_CAROUSEL_START";
export const GET_FEATURES_CAROUSEL_SUCCESS = "GET_FEATURES_CAROUSEL_SUCCESS";
export const GET_ONE_FEATURES_CAROUSEL_SUCCESS =
  "GET_ONE_FEATURES_CAROUSEL_SUCCESS";
export const GET_FEATURES_CAROUSEL_FAIL = "GET_FEATURES_CAROUSEL_FAIL";

export const getFeaturesStart = () => {
  return {
    type: GET_FEATURES_START,
  };
};

export const getFeaturesSuccess = (features) => {
  return {
    type: GET_FEATURES_SUCCESS,
    features: features,
  };
};

export const getOneFeaturesSuccess = (feature) => {
  return {
    type: GET_ONE_FEATURES_SUCCESS,
    feature: feature,
  };
};

export const getFeaturesFail = (error) => {
  return {
    type: GET_FEATURES_FAIL,
    error: error,
  };
};

export const getFeatures = () => {
  return (dispatch) => {
    dispatch(getFeaturesStart());
    axios
      .get(`/opanda/features`)
      .then((response) => {
        dispatch(getFeaturesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getFeaturesFail(err.message));
      });
  };
};
