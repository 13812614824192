import { useState } from "react";
import { BsCheckCircleFill, BsThreeDots } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { beautifyOpandaPostsAndCommentsText } from "../../../functions/commonfunctions";
import {
  calculateTimeFromDate,
  calculateTimeFromDateFuture,
} from "../../../shared/utility";
import { changeCommentModalStatus } from "../../../store/actions/community";
import { Button, ButtonWithIcon, TextButton } from "../../elements/button";
import { useSelector } from "react-redux";
import {
  CardBody,
  CardSubText,
  CardTitle,
  SmallText,
} from "../../elements/text";
import parse from "react-html-parser";
import React, { useEffect, useRef } from "react";
const PostComment = (props) => {
  const dispatch = useDispatch();
  const [comments, setComments] = useState(props?.comments.slice(0, 3) || []);
  const [showMoreComments, setShowMoreComments] = useState(false);
  const userAuth = useSelector((state) => state.auth);
  useEffect(() => {}, [comments]);
  return (
    <>
      {comments.map((comment, index) => (
        <div key={index} className="grid grid-cols-12 pt-4 px-3 hover:bg-white">
          {comment.isAnswer && (
            <div className="col-span-1">
              <BsCheckCircleFill
                className="mx-auto my-5"
                size={26}
                color="#E8AD26"
              />
            </div>
          )}

          <div
            className={`${
              comment.isAnswer ? "col-span-11" : "col-span-12"
            } px-2 flex flex-row justify-between border-b border-gray-300`}
          >
            <CardBody
              name={parse(
                "" +
                  beautifyOpandaPostsAndCommentsText(comment.content) +
                  " </p>"
              )}
            />

            <div className="flex-1 flex flex-row justify-end space-x-4 pb-2">
              <div className="flex flex-row space-x-4">
                <div className="flex flex-col text-right">
                  {userAuth.userId === comment.userid ? (
                    <CardSubText name={"me"} />
                  ) : (
                    <CardSubText name={comment.creator} />
                  )}
                  <SmallText
                    additional="italic"
                    name={
                      calculateTimeFromDateFuture(new Date(comment.createdAt)) +
                      " "
                    }
                  />
                </div>
                {userAuth.userId === comment.userid || props.commentorStatus ? (
                  <TextButton
                    isSmall={true}
                    name={<BsThreeDots />}
                    outline="true"
                    color="blue"
                    additional="p-2 inline-block hover:bg-white hover:color-blue rounded-full float-right"
                    clicked={() => {
                      props.triggerTheModal(
                        comment.content,
                        comment.answerid,
                        comment.userid
                      );
                    }}
                  ></TextButton>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
      {props.comments.length > 3 && (
        <TextButton
          name={`${showMoreComments ? "less comments" : "more comments"}`}
          color="blue"
          additional="px-3 py-2"
          clicked={() => {
            showMoreComments
              ? setComments(props.comments.slice(0, 3))
              : setComments(props.comments);
            setShowMoreComments(!showMoreComments);
          }}
        />
      )}
    </>
  );
};

export default PostComment;
