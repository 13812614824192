import { CardBody, CardTitle } from '../../../elements/text';
import { FeatureItemCard } from '../../../elements/card';
import { useHistory } from 'react-router-dom';
import { groupBy } from '../../../../shared/utility';
import { useEffect, useState } from 'react';
import { Button } from '../../../elements/button';
import Modal from '../../../elements/modal/modal';
import Spinner from '../../../elements/spinner';
import axios from "../../../../axios-base";
import { useSelector } from 'react-redux';
import Tests_users from './Tests_users';

const TestsGroupItemsForTeachers = (props) => {
  const history = useHistory();
  const [transformedTests, setTransformedTests] = useState({});
  const userAuth = useSelector((state) => state.auth);
  const { tests, filterBy } = props;
  useEffect(() => {
    groupBy(tests, filterBy)
      .then((res) => setTransformedTests(res))
      .catch((err) => console.log(err));
  }, [tests, filterBy]);

  const groups = Object.keys(transformedTests);
  const [showModal, setShowModal] = useState(false);
  const [selectedTestId, setSelectedTestId] = useState(0);
  const [userAnswersLoading, setUserAnswersLoading] = useState(false);
  const [usersAnswers, setUsersAnswers] = useState([]);

  const getAnswers = (id) => {
    setUserAnswersLoading(true);
    axios
      .post(
        `/opanda/tests/getTeacherTestStats`,
        {
          creator_id: userAuth.user.id,
          test_id: id
        },
        {
          headers: {
            authorization: `Bearer ${userAuth.token}`,
          },
        }
      )
      .then((response) => {
        //console.log(response)
        setUserAnswersLoading(false);
        setUsersAnswers(response.data.studentsMarks)

      })
      .catch((err) => {
        console.log(err);
        setUserAnswersLoading(false);
      });
  }




  return (
    <>
      <Modal medium show={showModal} modalClosed={() => setShowModal(false)}>
        <CardTitle
          name=""
        />

        <CardBody
          name=""
          additional="w-full pb-3 border-b border-gray-500"
        />
        <div style={{ height: "500px", overflowY: "scroll" }}>
          {(!userAnswersLoading) ? <div className="pt-3 flex justify-center space-x-5">
            <Tests_users setUserAnswersLoading={setUserAnswersLoading} usersAnswers={usersAnswers} selectedTestId={selectedTestId} />
          </div> : <Spinner size="30" color="blue" />}
        </div>
      </Modal>
      {groups.map((sort) => (
        <>
          <CardTitle name={sort} color="blue" />
          <div className="md:grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3" >
            {transformedTests[sort].map((test, index) => (
              <div className='m-2' style={{ borderRadius: 6, borderBottom: "3px solid #cdcdcd", borderRight: "3px solid #cdcdcd" }}><FeatureItemCard
                key={index}
                {...test}
                feature="test"
                clicked={() => {
                  //history.push(`/teacher/features/tests/${test.id}?scheduled=false`); //here must a test id
                }}
              /><div className='p-3' style={{ float: "right" }}>
                  <Button
                    name="View tests stats"
                    outline="true"
                    isSmall={true}
                    color="blue"
                    clicked={() => {
                      setShowModal(true);
                      setSelectedTestId(test.id);
                      getAnswers(test.id);
                    }}
                  />
                </div></div>
            ))}
          </div>
        </>
      ))}
    </>
  );
};

export default TestsGroupItemsForTeachers;
