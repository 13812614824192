import PageContent from '../pageContent';
import { FeatureTitle } from '../../../elements/text';
import { Button, ButtonWithIcon, GoBackButton } from '../../../elements/button';
import { useTabs } from 'react-headless-tabs';
import TabSelector from './TabSelector';
import Input from '../../../elements/input';
import { useState } from 'react';
import { BsPencilSquare } from 'react-icons/bs';
import Notification from '../../../elements/notification/notification';
import { MdAttachFile, MdFormatAlignLeft, MdFormatAlignRight, MdFormatUnderlined, MdImage, MdOutlineFormatAlignJustify, MdOutlineFormatColorText } from 'react-icons/md';
import MultipleSelector from './MultipleSelector';
import axios from "../../../../axios-base";
import { useSelector } from 'react-redux';


const ComposeMessage = () => {
  const groups = [
    { name: 'All messages' },
    { name: 'Parents' },
    { name: 'Teachers' },
    { name: 'Students' },
    { name: 'Announcements' },
    // { name: 'SMS' },
  ];

  const tabs = groups.map((group) => group.name);
  const [selectedTab, setSelectedTab] = useTabs(tabs, '');
  const [recipient, setRecipient] = useState('');
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const userAuth = useSelector((state) => state.auth);
  const token = useSelector((state) => state.auth.token);

  const handleSelectionChange = (newSelectedOptions) => {
    //console.log(newSelectedOptions);
    setSelectedOptions(newSelectedOptions);
  };


  const validateSelectedOptions = (items) => {

    for (var i = 0; i < items.length; i++) {
      if (items[i] === "announcement") {
        return ["student", "teacher", "parent"];
      }

    }
    //console.log("selectedOptions", items);

    return items;

  }

  const sendMessage = () => {
    if(selectedOptions.length === 0){ return alert("Please select a recipient") }
    if(subject === ""){ return alert("Please add a subject") }
    if(text === ""){ return alert("Please add a message") }
    axios
      .post(
        `/opanda/notifications/schoolBroadCastMessage`,
        {
          school_id: userAuth.user.id, recipients: validateSelectedOptions(selectedOptions?.map((item) => {
            if (item === "Parents") {
              return "parent"

            } else if (item === "Teachers") {
              return "teacher"
            } else if (item === "Students") {
              return "student"
            } else if (item === "Announcements") {
              return "announcement"
            }
          })), subject, message: text
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        //console.log(response.data);
        if (response?.data?.message?.includes("Notifications sent to users")) {
          setSelectedOptions([]);
          setSubject("");
          setText("");
          alert("Message sent successfully");

        }

      })
      .catch((err) => { 
        alert("An error occured, please try again later")
      });
  }

  return (
    <PageContent>
      <div className="p-3 bg-white-blue  h-screen overflow-auto">
        <FeatureTitle name="Communication" />
        <div className="md:grid grid-cols-12 w-full min-h-screen-sec">
          <div className="md:h-full col-span-2">
            {/* <div className="flex justify-center">
              <ButtonWithIcon
                name="Compose"
                outline="true"
                color="blue"
                isSquare
                onClick={() => { }}
              >
                <BsPencilSquare color="#183F71" size={26} />
              </ButtonWithIcon>
            </div> */}
            {/* <div className="py-5 bg-white h-full m-2 rounded">
              {groups.map((group, index) => (
                <TabSelector
                  key={index}
                  isActive={selectedTab === group.name}
                  title={group.name}
                  new={group.unread}
                  onClick={() => setSelectedTab(group.name)}
                />
              ))}
            </div> */}
            <GoBackButton
              link="/school/features/communication"
              action="Back"
              location=""

            />
          </div>
          <div className="bg-white p-2 m-2 md:h-full max-h-myscreen-sec col-span-7">
            <div className="w-50%">
              <MultipleSelector onSelectionChange={handleSelectionChange} />

              {/* <Input
                elementType="input"
                elementConfig={{
                  type: 'text',
                  placeholder: 'Add recipient',
                }}
                value={recipient}
                changed={setRecipient}
              /> */}
            </div>
            <div className="w-50%">
              <Input
                elementType="input"
                elementConfig={{
                  type: 'text',
                  placeholder: 'Add subject',
                }}
                value={subject}
                changed={setSubject}
              />
            </div>
            <Input
              elementType="textarea"
              elementConfig={{
                type: 'text',
                placeholder: 'Add text',
              }}
              value={text}
              changed={setText}
              additional="h-screen-third"
            />
            <div className="py-2 flex space-x-2 items-center">
              <Button
                name="Send"
                outline="false"
                color="blue"
                clicked={() => { sendMessage() }}
              />
              <MdImage color="#839098" />
              <MdAttachFile color="#839098" />
              <MdFormatUnderlined color="#839098" />
              <MdOutlineFormatAlignJustify color="#839098" />
              <MdFormatAlignRight color="#839098" />
              <MdFormatAlignLeft color="#839098" />
              <MdOutlineFormatColorText color="#839098" />
            </div>
          </div>
          <div className="py-2 md:h-full max-h-myscreen-sec col-span-3 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
            {/* <Notification auth /> */}
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default ComposeMessage;
