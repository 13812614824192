import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Input from "../../../elements/input";
import { useEffect, useRef } from "react";
import countryOptions from "../../../../utils/countries";
import { Provinces, Districts, Sectors } from "rwanda";
import { completeSignup } from "../../../../store/actions";
import {
  completeprofileinformation,
  createSchoolByStudent,
  getCBCCombinations,
  getDistrictSchools,
  getOGeniusModules,
  updateUserAccountInfo,
} from "../../../../store/actions";
import { CardBody, CardTitle } from "../../../elements/text";
import { updateSchoolInfo } from "../../../../store/actions/auth";

const SchoolSelect = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.completeSignup.loading);
  const userId = useSelector((state) => state.auth.userId);
  const [country, setCountry] = useState("Rwanda");
  const [curriculum, setCurriculum] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [sector, setSector] = useState("");
  const [school, setSchool] = useState("");
  const [locationInputsDisabled, setLocationInputsDisabled] = useState(false);
  const [schoolId, setSchoolId] = useState("");
  const [districts, setDistricts] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [dataerror, setdataerror] = useState("");
  const [schoolStatus, setSchoolStatus] = useState("");

  if (
    country !== "" &&
    country !== "Rwanda" &&
    locationInputsDisabled !== true
  ) {
    setLocationInputsDisabled(true);
  }
  if (locationInputsDisabled === true && country === "Rwanda") {
    setLocationInputsDisabled(false);
  }
  const interestRef = useRef();
  const schools = useSelector((state) => state.auth.schools);
  const modules = useSelector((state) => state.previewpanda.modules);
  const schoolsLoading = useSelector((state) => state.auth.schoolsLoading);
  const token = useSelector((state) => state.auth.token);
  const cbccombinations = useSelector((state) => state.profile.cbccombinations);

  const [isSubmitted, setIsSubmitted] = useState(false);
  //SELECTION BUTTON HANDLER
  const [selectedSchoolValue, setSelectedSchoolValue] = useState("");
  const handleSchoolChange = (value) => {
    setSchoolId(value);
    props.setSchoolId(value);
    // submitacademicinfo();
    // console.log(token);

  };
  useEffect(() => {
    const userObject = {
      school_linked_id: school?.id,
      section: school?.id,
      school_affiliated: school?.id,
      academic_info_country: country,
      academic_info_curriculum: curriculum,
      academic_info_school_province: province,
      academic_info_school_disctrict: district,
      academic_info_school_sector: sector,
      userId,
    };
    if (
      userObject?.school_affiliated !== undefined &&
      userObject?.school_affiliated !== "" &&
      userObject?.academic_info_school_province !== "" &&
      userObject?.academic_info_school_disctrict !== "" &&
      userObject?.academic_info_country !== "" &&
      userObject?.userId !== ""
    ) {
      // dispatch(completeprofileinformation(userObject));
      dispatch(updateSchoolInfo(userObject, token));
      // console.log(token);
    } else {
      setIsSubmitted(true);
      setdataerror("Some data are missing");
    }

  }, [country, curriculum, dispatch, district, province, school?.id, sector, token, userId]);
  // const submitacademicinfo = () => {
  //   const userObject = {
  //     school_linked_id: school?.id,
  //     section: school?.id,
  //     school_affiliated: school?.id,
  //     academic_info_country: country,
  //     academic_info_curriculum: curriculum,
  //     academic_info_school_province: province,
  //     academic_info_school_disctrict: district,
  //     academic_info_school_sector: sector,
  //     userId,
  //   };
  //   if (
  //     userObject?.school_affiliated !== undefined &&
  //     userObject?.school_affiliated !== "" &&
  //     userObject?.academic_info_school_province !== "" &&
  //     userObject?.academic_info_school_disctrict !== "" &&
  //     userObject?.academic_info_country !== "" &&
  //     userObject?.userId !== ""
  //   ) {
  //     // dispatch(completeprofileinformation(userObject));
  //     dispatch(updateUserAccountInfo(userObject, token));
  //     console.log(token);
  //   } else {
  //     setIsSubmitted(true);
  //     setdataerror("Some data are missing");
  //   }
  // };
  const setSchoolChange = (value) => {
    setSchool(value);
    // console.log(value, "school value");
    handleSchoolChange(value);
  };

  const createNewSchool = (value) => {
    if (schoolStatus !== "" && value !== "") {
      if (province !== "" && district !== "" && sector !== "") {
        const obj = {
          province,
          district,
          name: value,
          sector,
          level: "Secondary",
          status: schoolStatus,
        };
        dispatch(createSchoolByStudent(obj, token));
      } else {
        alert("Fill School location");
      }
    } else {
      alert("School status is required");
    }
  };

  const arrayToOptionsHandler = (arr = []) => {
    return arr.map((element) => {
      const item = {};
      item.value = element;
      item.displayValue = element;
      return item;
    });
  };

  const setTheProvince = (value) => {
    setProvince(value);
    setDistricts(arrayToOptionsHandler(Districts(value)));
    setSectors([]);
  };

  const setTheDistrict = (value) => {
    setDistrict(value);
    setSectors(arrayToOptionsHandler(Sectors(province, value)));
  };

  useEffect(() => {
    dispatch(getOGeniusModules());
    dispatch(getCBCCombinations(token));
    if (district !== null) {
      dispatch(getDistrictSchools(district, token));
    }
  }, [district]);
  return (
    <div className="flex flex-col py-5 justify-center items-start">
      <div className="flex flex-col justify-start items-center py-3">
        <CardBody name="Specify the region to select your school:" />
      </div>
      <div className="flex space-x-2">
        <Input
          label="Country"
          elementType="select"
          elementConfig={{
            startingValue: "SELECT",
            options: countryOptions,
          }}
          value={country}
          changed={setCountry}
          validation={{ required: true }}
          shouldValidate
          error="Country is required"
        />
        <Input
          label="Curriculum"
          elementType="select"
          elementConfig={{
            startingValue: "SELECT",
            options: modules,
          }}
          value={curriculum}
          changed={setCurriculum}
          validation={{ required: true }}
          shouldValidate
          error="Curriculum is required"
        />
      </div>
      <div className="flex space-x-2">
        <CardTitle name="School Information" color="black" />
      </div>
      <div className="w-full flex space-x-2">
        <Input
          label="Province"
          elementType="select"
          elementConfig={{
            startingValue: "SELECT",
            options: arrayToOptionsHandler(Provinces()),
            disabled: locationInputsDisabled,
          }}
          value={province}
          changed={setTheProvince}
          validation={{ required: true }}
          shouldValidate
          error="Province is required"
        />
        <Input
          label="District"
          elementType="select"
          elementConfig={{
            startingValue: "SELECT",
            options: districts,
            disabled: locationInputsDisabled,
          }}
          value={district}
          changed={setTheDistrict}
          validation={{ required: true }}
          shouldValidate
          error="District is required"
        />
      </div>
      <div className="w-full flex space-x-2">
        <Input
          label="Sector"
          elementType="select"
          elementConfig={{
            startingValue: "SELECT",
            options: sectors,
            disabled: locationInputsDisabled,
          }}
          value={sector}
          changed={setSector}
          validation={{ required: true }}
          shouldValidate
          error="Sector is required"
        />
        <Input
          label="School Status"
          elementType="select"
          elementConfig={{
            startingValue: "SELECT",
            options: [
              { value: "Public", displayValue: "Public" },
              { value: "Private", displayValue: "Private" },
            ],
          }}
          value={schoolStatus}
          changed={setSchoolStatus}
          validation={{ required: true }}
          shouldValidate
          error="School Status is required"
        />
      </div>
      <div className="w-full flex space-x-2">
        <Input
          label="School"
          elementType="selectschool"
          elementConfig={{
            startingValue: "SELECT",
            options: schools,
            disabled: locationInputsDisabled,
          }}
          value={school}
          changed={setSchoolChange}
          validation={{ required: true }}
          shouldValidate
          error="School is required"
        />
      </div>
    </div>
  );
};

export default SchoolSelect;
