// PackagePayment
import Layout from '../../../components/common/Layout';
import { BookPayment } from '../../../components/pages/parent/marketplace';

const ParentBookPaymentContainer = () => {
  return (
    <Layout>
      <BookPayment />
    </Layout>
  );
};

export default ParentBookPaymentContainer;
