import { CardTitle } from "../../elements/text";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { useHistory } from 'react-router-dom';
// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";
import ContentCard from "./contentcard";
import { useSelector } from "react-redux";
import NotFound from "../features/NotFound";
import eventBus from "../../../utils/eventBus";
import { TRIGGER_OPEN_FEATURES_TAB } from "../../pages/teacher/sidebar";

// install Swiper modules
SwiperCore.use([Navigation]);

export const ContentContainer = function (props) {
  const history = useHistory();
  const datajson = props.data;
  const user_type = useSelector((state) => state?.auth?.user?.user_type ?? "student")
  const previewpanda = useSelector((state) => state?.reducer)
  if (props.slider) {
    return (
      <div className="w-full flex flex-col align-start justify-center my-4 space-y-4">
        <CardTitle name={props.name} color="blue" />
        <Swiper
          navigation={true}
          slidesPerView={1}
          spaceBetween={5}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
          className="w-full threeSwiper my-10"
        >
          {datajson?.map((carddetails) => (
            (parseInt(carddetails?.id) > 0) ? <SwiperSlide key={carddetails.id}>
              <div onClick={() => {
                if (carddetails?.id) {
                  if (!props?.selectedAcademiclevel) {
                    eventBus.emit(TRIGGER_OPEN_FEATURES_TAB);
                    history.push(
                      `/${user_type}/features/summarynotes/${carddetails.id}?subject=${carddetails.subject}&unit=${carddetails.unit}`
                    )
                  } else {
                    eventBus.emit(TRIGGER_OPEN_FEATURES_TAB);
                    history.push(
                      `/${user_type}/features/summarynotes/${carddetails.id}?subject=${carddetails.subject}&unit=${carddetails.unit}&academic_level=${props?.selectedAcademiclevel}`
                    )

                  }

                }
              }}>
                <ContentCard
                  page={props.page}
                  guest={props.guest}
                  data={carddetails}
                  type={props.type}
                  key={carddetails.id}
                />
              </div>
            </SwiperSlide> : ""
          ))}
        </Swiper>
      </div>
    );
  } else {
    return (
      <div className="w-full my-4 space-y-4 p-5 h-80 sm:h-100 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
        <CardTitle name={props.name} color="blue" nosubtitle={false} />
        <div className="grid sm:grid-cols-4  sm:gap-4 grid-cols-1 gap-1">
          {(props.type === "two") ? (datajson?.filter((carddetails, index) => carddetails?.panda_books_subject && carddetails?.panda_books_id > 0 && (previewpanda?.previewpanda_search_keyword === "" || carddetails?.panda_books_subject?.toLowerCase()?.includes(previewpanda?.previewpanda_search_keyword?.toLowerCase()) || carddetails?.panda_books_summary?.toLowerCase().includes(previewpanda?.previewpanda_search_keyword?.toLowerCase()))).length) ? datajson?.filter((carddetails, index) => carddetails?.panda_books_subject && carddetails?.panda_books_id > 0 && (previewpanda?.previewpanda_search_keyword === "" || carddetails?.panda_books_subject?.toLowerCase()?.includes(previewpanda?.previewpanda_search_keyword?.toLowerCase()) || carddetails?.panda_books_summary?.toLowerCase().includes(previewpanda?.previewpanda_search_keyword?.toLowerCase()))).map((carddetails, index) => (
            <>
              {(carddetails?.panda_books_subject && carddetails?.panda_books_id > 0 && (previewpanda?.previewpanda_search_keyword === "" || carddetails?.panda_books_subject?.toLowerCase()?.includes(previewpanda?.previewpanda_search_keyword?.toLowerCase()) || carddetails?.panda_books_summary?.toLowerCase().includes(previewpanda?.previewpanda_search_keyword?.toLowerCase()))) ? <div><ContentCard
                key={index}
                data={carddetails}
                type={props.type}
                page={props.page}
                book={true}
              /> </div> : <NotFound feature="Extracurricular resources matching your search" />}
            </>
          )) : <NotFound feature="Extracurricular resources  matching your search" /> : (props.type === "three") ? (datajson?.filter((carddetails, index) => carddetails?.name && carddetails?.id > 0 && (previewpanda?.previewpanda_search_keyword === "" || carddetails?.name?.toLowerCase()?.includes(previewpanda?.previewpanda_search_keyword?.toLowerCase()) || carddetails?.description?.toLowerCase().includes(previewpanda?.previewpanda_search_keyword?.toLowerCase()) || carddetails?.summary?.toLowerCase().includes(previewpanda?.previewpanda_search_keyword?.toLowerCase()))).length) ? datajson?.filter((carddetails, index) => carddetails?.name && carddetails?.id > 0 && (previewpanda?.previewpanda_search_keyword === "" || carddetails?.name?.toLowerCase()?.includes(previewpanda?.previewpanda_search_keyword?.toLowerCase()) || carddetails?.description?.toLowerCase().includes(previewpanda?.previewpanda_search_keyword?.toLowerCase()) || carddetails?.summary?.toLowerCase().includes(previewpanda?.previewpanda_search_keyword?.toLowerCase()))).map((carddetails, index) => (
            <>
              {(carddetails?.name && carddetails?.id > 0 && (previewpanda?.previewpanda_search_keyword === "" || carddetails?.name?.toLowerCase()?.includes(previewpanda?.previewpanda_search_keyword?.toLowerCase()) || carddetails?.description?.toLowerCase().includes(previewpanda?.previewpanda_search_keyword?.toLowerCase()) || carddetails?.summary?.toLowerCase().includes(previewpanda?.previewpanda_search_keyword?.toLowerCase()))) ? <div><ContentCard
                key={index}
                data={carddetails}
                type={props.type}
                page={props.page}
                book={true}
              /> </div> : <NotFound feature="Learning resources  matching your search" />}
            </>
          )) : <NotFound feature="Learning resources  matching your search" /> : ""}
        </div>
      </div>
    );
  }
};

export const BookContentContainer = function (props) {
  const history = useHistory();
  const datajson = props.data;
  const user_type = useSelector((state) => state?.auth?.user?.user_type ?? "parent")
  if (props.slider) {
    return (
      <div className="w-full flex flex-col align-start justify-center my-4 space-y-4">
        <CardTitle name={props.name} color="blue" />
        <Swiper
          navigation={true}
          slidesPerView={1}
          spaceBetween={5}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
          className="w-full threeSwiper my-10"
        >
          {datajson?.map((carddetails) => (
            <SwiperSlide key={carddetails.id}>
              <div onClick={() => {
                history.push(
                  `/${user_type}/marketplace/books/purchasedbooks/${carddetails.id}?subject=${carddetails.name}`
                )
              }}>
                <ContentCard
                  page={props.page}
                  guest={props.guest}
                  data={carddetails}
                  type={props.type}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  } else {
    return (
      <div className="w-full my-4 space-y-4">
        <CardTitle name={props.name} color="blue" nosubtitle={false} />
        <div className="grid grid-cols-4 gap-4">
          {datajson?.map((carddetails, index) => (
            <div onClick={() => {
              if (carddetails?.id) {
                history.push(
                  `/${user_type}/marketplace/books/purchasedbooks/${carddetails.id}?subject=${carddetails.name}`
                )
              }
            }}>
              <ContentCard
                key={index}
                data={carddetails}
                type={props.type}
                page={props.page}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
};