import "react-phone-number-input/style.css";
import { MdWarning } from "react-icons/md";
import { CardSubText } from "./text";


const Checkbox = (props) => {
  let inputElement = null;
  let inputClasses =
    "w-full outline-none border-px border-solid border-blue rounded focus:border-blue focus:ring-transparent focus:outline-0 focus:outline-transparent ";
  let errorClasses = "hidden";

  inputClasses.concat(
    "elementConfig" in props ? props?.elementConfig?.classes : ""
  );
  inputElement = (
    <input
      className={`${inputClasses} w-24px`}
      {...props.elementConfig}
      type="checkbox"
      checked={props.value}
      onChange={props.onChange}
    />
  );

  return (
    <div
      className={`w-full box-border mt-2 flex flex-col justify-center align-start`}
    >
      <CardSubText name={props.label} color="black" />
      {inputElement}
      <span className={errorClasses}>
        <MdWarning size="14" color="#BE1E2D" />
        <CardSubText name={props.error} color="red" alignment="center" />
      </span>
    </div>
  );
};

export default Checkbox;
