import { CardBody } from '../../../elements/text';
import { Link } from 'react-router-dom';


const Item = (props) => {
  return (
    <Link to={props.link} className="flex flex-col space-y-2 items-center m-5">
      <div className="w-16 h-16 rounded-full bg-yellow flex justify-center items-center">
        {props.children}
      </div>
      <CardBody name={props.name} />
    </Link>
  );
};

export default Item;
