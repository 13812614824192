export const newResponse = (res, headerFn) => {
  const cloneHeaders = () => {
    const headers = new Headers();
    for (let kv of res.headers.entries()) {
      headers.append(kv[0], kv[1]);
    }
    return headers;
  };

  const headers = headerFn ? headerFn(cloneHeaders()) : res.headers;

  return new Promise((resolve) => {
    return res.blob().then((blob) => {
      resolve(
        new Response(blob, {
          status: res.status,
          statusText: res.statusText,
          headers: headers,
        })
      );
    });
  });
};

export const getCachedData = async (
  cacheName,
  url,
  method,
  expirationTime = 86400, //seconds
  headers,
  body
) => {
  //   const initTime = new Date().getTime();
  const { day, ...newBody } = body;
  cacheName = JSON.stringify([cacheName, method, newBody, headers, url])
  const req = new Request(process.env.REACT_APP_BACKEND_URL + url, {
    method: method,
    headers: headers,
    body: JSON.stringify(body),
  });

  const cache = await caches.open(cacheName);
  const cacheResponse = await cache.match(new Request(url, { method: 'GET', headers: headers }));
  if (cacheResponse) {
    const expirationDate = new Date(cacheResponse.headers.get('Expires'));
    if (new Date() >= expirationDate) {
      console.log("FROM SERVER  CACHE EXPIRED")
      const response = await fetch(req);
      const updatedResponse = await newResponse(response, (headers) => {
        headers.set(
          'Expires',
          new Date(new Date().getTime() + expirationTime * 1000)
        );
        headers.set('Cache-control', 'private');
        return headers;
      });
      cache.put(new Request(url, { method: 'GET', headers: headers }), updatedResponse.clone());
      const data = await updatedResponse.json();
      //   const finalTime = new Date().getTime();
      //   console.log('TIME FROM FETCH: ', finalTime - initTime, 'ms');
      //   return { data: data, from: 'SERVER' };
      return data;
    }
    const data = await cacheResponse.json();
    console.log("FROM CACHE STORAGE")
    // const finalTime = new Date().getTime();
    //   console.log('TIME FROM CACHE: ', finalTime - initTime, 'ms');
    //   return { data: data, from: 'CACHE' };
    return data;
  } else {
    console.log("FROM SERVER NO CACHE")
    const response = await fetch(req);
    const updatedResponse = await newResponse(response, (headers) => {
      headers.set(
        'Expires',
        new Date(new Date().getTime() + expirationTime * 1000)
      );
      return headers;
    });
    cache.put(new Request(url, { method: 'GET', headers: headers }), updatedResponse.clone());
    const data = await updatedResponse.json();
    // const finalTime = new Date().getTime();
    //   console.log('TIME FROM FETCH: ', finalTime - initTime, 'ms');
    //   return { data: data, from: 'SERVER' };
    return data;
  }
};

export const ignoreCachedData = async (
  cacheName,
  url,
  method,
  expirationTime = 86400, //seconds
  headers,
  body
) => {
  //   const initTime = new Date().getTime();
  const { day, ...newBody } = body;
  cacheName = JSON.stringify([cacheName, method, newBody, headers, url])
  const req = new Request(process.env.REACT_APP_BACKEND_URL + url, {
    method: method,
    headers: headers,
    body: JSON.stringify(body),
  });

  const cache = await caches.open(cacheName);
  const cacheResponse = await cache.match(new Request(url, { method: 'GET', headers: headers }));
  // Delete this cache
  if (cacheResponse) {
    cache.delete(new Request(url, { method: 'GET', headers: headers }));
  }
 
  
};

export const getCachedData_mine = async (
  cacheName,
  url,
  method,
  expirationTime = 86400, //seconds
  headers,
  body
) => {
  //   const initTime = new Date().getTime();
  const { day, ...newBody } = body;
  cacheName = JSON.stringify([cacheName, method, newBody, headers, url])
  const req = new Request(process.env.REACT_APP_BACKEND_URL + url, {
    method: method,
    headers: headers,
    body: JSON.stringify(body),
  });

  console.log("FROM SERVER NO CACHE")
  const response = await fetch(req);
  const updatedResponse = await newResponse(response, (headers) => {
    headers.set(
      'Expires',
      new Date(new Date().getTime() + expirationTime * 1000)
    );
    return headers;
  });
  const data = await updatedResponse.json();
  // const finalTime = new Date().getTime();
  //   console.log('TIME FROM FETCH: ', finalTime - initTime, 'ms');
  //   return { data: data, from: 'SERVER' };
  return data;

};
