import { updateObject } from "../../shared/utility";
import { AUTHENTICATE } from "../actions/auth";
import * as actionsTypes from "../actions/actionTypes";

const initialReducer = {
  token: null,
  userId: null,
  username: null,
  academic_level: null,
  displayName: null,
  error: null,
  loading: false,
  path: "/",
  users: [],
  usersError: null,
  usersLoading: false,
  user: null,
  telephone: null,
  usertype: null,
  recoveryquestions: [],
  recoveryquestion: null,
  recoveryquestionsError: null,
  recoveryquestionsLoading: false,
  schools: [],
  school: null,
  schoolsError: null,
  schoolsLoading: false,
};


const authenticate = (state, action) => {
  return updateObject(state, {
    token: "token",
  });
};

const authStart = (state, action) => {
  return updateObject(state, {
    token: null,
    userId: null,
    username: null,
    displayName: null,
    error: null,
    loading: true,
    user: null,
    telephone: null,
    usertype: null,
    academic_level: null,
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    userId: action.userId,
    username: action.username,
    displayName: action.displayName,
    telephone: null,
    error: null,
    loading: false,
    user: action.user,
    usertype: action.usertype,
    academic_level: action.academic_level,
    passwordReset: action.passwordReset
  });
};

const userCreatedSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    userId: null,
    username: action.username,
    displayName: null,
    error: null,
    loading: false,
    user: action.user,
    telephone: action.telephone,
    usertype: null,
    academic_level: null,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    token: null,
    userId: null,
    username: null,
    displayName: null,
    error: action.error,
    loading: false,
    user: null,
    telephone: null,
    usertype: null,
    academic_level: null,
  });
};

const logout = (state, action) => {
  return updateObject(state, {
    token: null,
    userId: null,
    name: null,
    displayName: null,
    error: null,
    user: null,
  });
};

const getUsersStart = (state, action) => {
  return updateObject(state, {
    users: [],
    usersError: null,
    usersLoading: true,
    user: null,
  });
};

const getUsersSuccess = (state, action) => {
  return updateObject(state, {
    users: action.users,
    usersError: null,
    usersLoading: false,
    user: null,
  });
};

const getUsersFail = (state, action) => {
  return updateObject(state, {
    users: [],
    usersError: action.error,
    usersLoading: false,
    user: null,
  });
};

const getOneUsersSuccess = (state, action) => {
  return updateObject(state, {
    users: [],
    usersError: null,
    usersLoading: false,
    user: action.user,
  });
};

const getRecoveryQuestionsStart = (state, action) => {
  return updateObject(state, {
    recoveryquestions: [],
    recoveryquestionsError: null,
    recoveryquestionsLoading: true,
    recoveryquestion: null,
  });
};

const getRecoveryQuestionsSuccess = (state, action) => {
  return updateObject(state, {
    recoveryquestions: action.recoveryquestions,
    recoveryquestionsError: null,
    recoveryquestionsLoading: false,
    recoveryquestion: null,
  });
};

const getRecoveryQuestionsFail = (state, action) => {
  return updateObject(state, {
    recoveryquestions: [],
    recoveryquestionsError: action.error,
    recoveryquestionsLoading: false,
    recoveryquestion: null,
  });
};

const getOneRecoveryQuestionsSuccess = (state, action) => {
  return updateObject(state, {
    recoveryquestions: [],
    recoveryquestionsError: null,
    recoveryquestionsLoading: false,
    recoveryquestion: action.recoveryquestion,
  });
};

const getSchoolsStart = (state, action) => {
  return updateObject(state, {
    schools: [],
    schoolsError: null,
    schoolsLoading: true,
    school: null,
  });
};

const getSchoolsSuccess = (state, action) => {
  return updateObject(state, {
    schools: action.schools,
    schoolsError: null,
    schoolsLoading: false,
    school: null,
  });
};

const getSchoolsFail = (state, action) => {
  return updateObject(state, {
    schools: [],
    schoolsError: action.error,
    schoolsLoading: false,
    school: null,
  });
};

const getOneSchoolsSuccess = (state, action) => {
  return updateObject(state, {
    schools: [],
    schoolsError: null,
    schoolsLoading: false,
    school: action.school,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      return authenticate(state, action);
    case actionsTypes.AUTHENTICATION_START:
      return authStart(state, action);
    case actionsTypes.AUTHENTICATION_SUCCESS:
      return authSuccess(state, action);
    case actionsTypes.AUTHENTICATION_FAIL:
      return authFail(state, action);
    case actionsTypes.AUTHENTICATION_LOGOUT:
      return logout(state, action);
    case actionsTypes.GET_USERS_START:
      return getUsersStart(state, action);
    case actionsTypes.GET_USERS_SUCCESS:
      return getUsersSuccess(state, action);
    case actionsTypes.GET_ONE_USERS_SUCCESS:
      return getOneUsersSuccess(state, action);
    case actionsTypes.GET_USERS_FAIL:
      return getUsersFail(state, action);
    case actionsTypes.CREATE_USER_SUCCESS:
      return userCreatedSuccess(state, action);
    case actionsTypes.GET_RECOVERY_QUESTIONS_START:
      return getRecoveryQuestionsStart(state, action);
    case actionsTypes.GET_RECOVERY_QUESTIONS_SUCCESS:
      return getRecoveryQuestionsSuccess(state, action);
    case actionsTypes.GET_ONE_RECOVERY_QUESTIONS_SUCCESS:
      return getOneRecoveryQuestionsSuccess(state, action);
    case actionsTypes.GET_RECOVERY_QUESTIONS_FAIL:
      return getRecoveryQuestionsFail(state, action);
    case actionsTypes.GET_SCHOOLS_START:
      return getSchoolsStart(state, action);
    case actionsTypes.GET_SCHOOLS_SUCCESS:
      return getSchoolsSuccess(state, action);
    case actionsTypes.GET_ONE_SCHOOLS_SUCCESS:
      return getOneSchoolsSuccess(state, action);
    case actionsTypes.GET_SCHOOLS_FAIL:
      return getSchoolsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
