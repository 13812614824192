

export const auto_test_questions_data = (data) => {

  const questions = {
    name: "Automated tests",
    questionsmap: []
  };
  //----
  let the_total_time = 0;
  let total_number_of_questions = 0;
  let total_marks = 0;
  //--
  const algov2_question_ids_fillin: any = [];
  const algov2_question_ids_matching: any = [];
  let auto_question_ids = [];
  if (data[0] && data[1] && data[2]) {
    //----Matching type questions
    if (data[0] && data[0].length > 0) {
      const innerData = data[0];

      for (var n = 0; n < innerData.length; n++) {

        questions.questionsmap.push({
          questiondata: {
            panda_auto_test_algo_v2_academic_level: innerData[n].panda_auto_test_algo_v2_academic_level,
            panda_auto_test_algo_v2_academic_subject: innerData[n].panda_auto_test_algo_v2_academic_subject,
            panda_auto_test_algo_v2_academic_unit: innerData[n].panda_auto_test_algo_v2_academic_unit,
            panda_auto_test_algo_v2_auto_creator: innerData[n].panda_auto_test_algo_v2_auto_creator,
            panda_auto_test_algo_v2_creator_id: innerData[n].panda_auto_test_algo_v2_creator_id,
            panda_auto_test_algo_v2_id: innerData[n].panda_auto_test_algo_v2_id,
            panda_auto_test_algo_v2_question_data: innerData[n].panda_auto_test_algo_v2_question_data,
            panda_auto_test_algo_v2_regdate: innerData[n].panda_auto_test_algo_v2_regdate,
            panda_auto_test_algo_v2_time_assigned_in_minutes: parseInt(innerData[n].panda_auto_test_algo_v2_time_assigned_in_minutes),
            panda_auto_test_algo_v2_total_marks: parseInt(innerData[n].panda_auto_test_algo_v2_total_marks),
            uuid: innerData[n].uuid
          },
          questiontype: 'matching',
        });
        the_total_time = the_total_time + parseInt(innerData[n].panda_auto_test_algo_v2_time_assigned_in_minutes);
        total_number_of_questions = total_number_of_questions + 1;
        total_marks = total_marks + parseInt(innerData[n].panda_auto_test_algo_v2_total_marks);
        //---
        algov2_question_ids_matching.push(innerData[n].panda_auto_test_algo_v2_id);
      }
      //----Fillin type questions
    } if (data[1] && data[1].length > 0) {
      const innerData = data[1];
      for (var n = 0; n < innerData.length; n++) {
        questions.questionsmap.push({
          questiondata: {
            panda_auto_test_algo_v2_academic_level: innerData[n].panda_auto_test_algo_v2_academic_level,
            panda_auto_test_algo_v2_academic_subject: innerData[n].panda_auto_test_algo_v2_academic_subject,
            panda_auto_test_algo_v2_academic_unit: innerData[n].panda_auto_test_algo_v2_academic_unit,
            panda_auto_test_algo_v2_auto_creator: innerData[n].panda_auto_test_algo_v2_auto_creator,
            panda_auto_test_algo_v2_creator_id: innerData[n].panda_auto_test_algo_v2_creator_id,
            panda_auto_test_algo_v2_id: innerData[n].panda_auto_test_algo_v2_id,
            panda_auto_test_algo_v2_question_data: innerData[n].panda_auto_test_algo_v2_question_data,
            panda_auto_test_algo_v2_regdate: innerData[n].panda_auto_test_algo_v2_regdate,
            panda_auto_test_algo_v2_time_assigned_in_minutes: parseInt(innerData[n].panda_auto_test_algo_v2_time_assigned_in_minutes),
            panda_auto_test_algo_v2_total_marks: parseInt(innerData[n].panda_auto_test_algo_v2_total_marks),
            uuid: innerData[n].uuid
          },
          questiontype: 'fillin',
        });
        the_total_time = the_total_time + parseInt(innerData[n].panda_auto_test_algo_v2_time_assigned_in_minutes);
        total_number_of_questions = total_number_of_questions + 1;
        total_marks = total_marks + parseInt(innerData[n].panda_auto_test_algo_v2_total_marks);
        //---
        algov2_question_ids_fillin.push(innerData[n].panda_auto_test_algo_v2_id);
      }
      //----Multiple choice type questions
    } if (data[2] && data[2].length > 0) {
      const innerData = data[2];

      for (var n = 0; n < innerData.length; n++) {
        const question = innerData[n];
        const questionData = question[0];
        const answerData = question[1];



        questions.questionsmap.push({
          questiondata: {
            panda_answer_explanation: questionData.panda_answer_explanation,
            panda_questions_auto_academic_level: questionData.panda_questions_auto_academic_level,
            panda_questions_auto_academic_units: questionData.panda_questions_auto_academic_units,
            panda_questions_auto_id: questionData.panda_questions_auto_id,
            panda_questions_auto_question_data: questionData.panda_questions_auto_question_data,
            panda_questions_auto_regdate: questionData.panda_questions_auto_regdate,
            panda_questions_auto_subject: questionData.panda_questions_auto_subject,
            panda_questions_auto_time_assigned_in_seconds: parseInt(questionData.panda_questions_auto_time_assigned_in_seconds),
            panda_questions_auto_total_marks: questionData.panda_questions_auto_total_marks,
            panda_questions_auto_auto_answering_technique: questionData.panda_questions_auto_auto_answering_technique,
            uuid: questionData.uuid,
            answerData: answerData
          },
          questiontype: 'multiple_choice',
        })
        the_total_time = the_total_time + parseInt(questionData.panda_questions_auto_time_assigned_in_seconds);
        total_number_of_questions = total_number_of_questions + 1;
        total_marks = total_marks + parseInt(questionData.panda_questions_auto_total_marks);
        //----
        auto_question_ids.push(questionData.panda_questions_auto_id + "~~" + 1);

      }

    }



  }
  return [questions, parseInt(the_total_time + "") * 60, total_number_of_questions, total_marks, [auto_question_ids, algov2_question_ids_matching, algov2_question_ids_fillin]];

};

export const auto_test_questions_data_answer_sheet = (data) => {
  //console.log("data", data);
  const extra_data = (data.length > 0) ? data[3] : [];
  const matching_type_data = (extra_data.length > 0 && extra_data[0].length > 0) ? extra_data[0][0] : [];
  const fillin_type_data = (extra_data.length > 0 && extra_data[1].length > 0) ? extra_data[1][0] : [];
  const radio_choice = (extra_data.length > 0 && extra_data[2].length > 0 && extra_data[2][0].length > 0) ? extra_data[2][0][0].multiple_choice_single : [];
  const checkbox_choice = (extra_data.length > 0 && extra_data[2].length > 0 && extra_data[2][1].length > 0) ? extra_data[2][1][0].multiple_choice_multiple : [];
  const multiple_choice_user_answer = [...radio_choice, ...checkbox_choice]

  

  const questions = {
    name: "Automated tests",
    questionsmap: []
  };
  //----
  let the_total_time = 0;
  let total_number_of_questions = 0;
  let total_marks = 0;
  //--
  const algov2_question_ids_fillin: any = [];
  const algov2_question_ids_matching: any = [];
  let auto_question_ids = "";
  if (data[0] && data[1] && data[2]) {
    //----Matching type questions
    if (data[0] && data[0].length > 0) {
      const innerData = data[0];

      for (var n = 0; n < innerData.length; n++) {

        questions.questionsmap.push({
          questiondata: {
            panda_auto_test_algo_v2_academic_level: innerData[n].panda_auto_test_algo_v2_academic_level,
            panda_auto_test_algo_v2_academic_subject: innerData[n].panda_auto_test_algo_v2_academic_subject,
            panda_auto_test_algo_v2_academic_unit: innerData[n].panda_auto_test_algo_v2_academic_unit,
            panda_auto_test_algo_v2_auto_creator: innerData[n].panda_auto_test_algo_v2_auto_creator,
            panda_auto_test_algo_v2_creator_id: innerData[n].panda_auto_test_algo_v2_creator_id,
            panda_auto_test_algo_v2_id: innerData[n].panda_auto_test_algo_v2_id,
            panda_auto_test_algo_v2_question_data: innerData[n].panda_auto_test_algo_v2_question_data,
            panda_auto_test_algo_v2_regdate: innerData[n].panda_auto_test_algo_v2_regdate,
            panda_auto_test_algo_v2_time_assigned_in_minutes: parseInt(innerData[n].panda_auto_test_algo_v2_time_assigned_in_minutes),
            panda_auto_test_algo_v2_total_marks: parseInt(innerData[n].panda_auto_test_algo_v2_total_marks),
            uuid: innerData[n].uuid,
            matching_type_user_data: matching_type_data.matching
          },
          questiontype: 'matching',
        });
        the_total_time = the_total_time + parseInt(innerData[n].panda_auto_test_algo_v2_time_assigned_in_minutes);
        total_number_of_questions = total_number_of_questions + 1;
        total_marks = total_marks + parseInt(innerData[n].panda_auto_test_algo_v2_total_marks);
        //---
        algov2_question_ids_matching.push(innerData[n].panda_auto_test_algo_v2_id);
      }
      //----Fillin type questions
    } if (data[1] && data[1].length > 0) {
      const innerData = data[1];
      for (var n = 0; n < innerData.length; n++) {
        questions.questionsmap.push({
          questiondata: {
            panda_auto_test_algo_v2_academic_level: innerData[n].panda_auto_test_algo_v2_academic_level,
            panda_auto_test_algo_v2_academic_subject: innerData[n].panda_auto_test_algo_v2_academic_subject,
            panda_auto_test_algo_v2_academic_unit: innerData[n].panda_auto_test_algo_v2_academic_unit,
            panda_auto_test_algo_v2_auto_creator: innerData[n].panda_auto_test_algo_v2_auto_creator,
            panda_auto_test_algo_v2_creator_id: innerData[n].panda_auto_test_algo_v2_creator_id,
            panda_auto_test_algo_v2_id: innerData[n].panda_auto_test_algo_v2_id,
            panda_auto_test_algo_v2_question_data: innerData[n].panda_auto_test_algo_v2_question_data,
            panda_auto_test_algo_v2_regdate: innerData[n].panda_auto_test_algo_v2_regdate,
            panda_auto_test_algo_v2_time_assigned_in_minutes: parseInt(innerData[n].panda_auto_test_algo_v2_time_assigned_in_minutes),
            panda_auto_test_algo_v2_total_marks: parseInt(innerData[n].panda_auto_test_algo_v2_total_marks),
            uuid: innerData[n].uuid,
            fillin_type_user_data: fillin_type_data.fillins
          },
          questiontype: 'fillin',
        });
        the_total_time = the_total_time + parseInt(innerData[n].panda_auto_test_algo_v2_time_assigned_in_minutes);
        total_number_of_questions = total_number_of_questions + 1;
        total_marks = total_marks + parseInt(innerData[n].panda_auto_test_algo_v2_total_marks);
        //---
        algov2_question_ids_fillin.push(innerData[n].panda_auto_test_algo_v2_id);
      }
      //----Multiple choice type questions
    } if (data[2] && data[2].length > 0) {
      const innerData = data[2];

      for (var n = 0; n < innerData.length; n++) {
        const question = innerData[n];
        const questionData = question[0];
        const answerData = question[1];



        questions.questionsmap.push({
          questiondata: {
            panda_answer_explanation: questionData.panda_answer_explanation,
            panda_questions_auto_academic_level: questionData.panda_questions_auto_academic_level,
            panda_questions_auto_academic_units: questionData.panda_questions_auto_academic_units,
            panda_questions_auto_id: questionData.panda_questions_auto_id,
            panda_questions_auto_question_data: questionData.panda_questions_auto_question_data,
            panda_questions_auto_regdate: questionData.panda_questions_auto_regdate,
            panda_questions_auto_subject: questionData.panda_questions_auto_subject,
            panda_questions_auto_time_assigned_in_seconds: parseInt(questionData.panda_questions_auto_time_assigned_in_seconds),
            panda_questions_auto_total_marks: questionData.panda_questions_auto_total_marks,
            panda_questions_auto_auto_answering_technique: questionData.panda_questions_auto_auto_answering_technique,
            uuid: questionData.uuid,
            answerData: answerData,
            useranswer: multiple_choice_user_answer

          },
          questiontype: 'multiple_choice',

        })
        the_total_time = the_total_time + parseInt(questionData.panda_questions_auto_time_assigned_in_seconds);
        total_number_of_questions = total_number_of_questions + 1;
        total_marks = total_marks + parseInt(questionData.panda_questions_auto_total_marks);
        //----
        if (auto_question_ids.split("##").length > 0) {
          auto_question_ids = questionData.panda_questions_auto_id + "~~" + 1;
        } else {
          auto_question_ids = auto_question_ids + "##" + questionData.panda_questions_auto_id + "~~" + 1;
        }
      }

    }



  }
  return [questions, parseInt(the_total_time + "") * 60, total_number_of_questions, total_marks, [auto_question_ids, algov2_question_ids_matching, algov2_question_ids_fillin]];

};

