import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PaymentRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.token !== null);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated)
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        return <Component {...props} />;
      }}
    />
  );
};

export default PaymentRoute;
