import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocation } from "react-router-dom";
import { getOneUserbyId } from "../../../../store/actions/users";
import { GoBackButton } from "../../../elements/button";
import { ImageIcon } from "../../../elements/imageicon";
import Input from "../../../elements/input";
import { FeatureTitle } from "../../../elements/text";
import PageContent from "../pageContent";

const UserInfo = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userInfo = useSelector((state) => state.usersInformation);

  // const userAuth = useSelector((state) => state.auth);
  const { usersError, usersLoading, user } = userInfo;

  const { userId } = location.state;
  useEffect(() => {
    setTimeout(() => {
      dispatch(getOneUserbyId(userId));
    }, 200);
  }, []);
  if (usersLoading) {
    return (
      <PageContent>
        <div className="p-3 bg-white-blue space-y-2">
          <FeatureTitle name="User management" color="blue" />
          <div className="flex flex-col md:flex-row justify-between">
            <GoBackButton
              link="/school/features/users"
              action="Back"
              location={`Users: Manage users`}
            />
          </div>
          <div className="md:grid grid-cols-3 gap-2">
            <div>Loading...</div>
          </div>
        </div>
      </PageContent>
    );
  }

  if (usersError) {
    return (
      <PageContent>
        <div className="p-3 bg-white-blue space-y-2 max-h-myscreen overflow-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
          <FeatureTitle name="User management" color="blue" />
          <div className="flex flex-col md:flex-row justify-between">
            <GoBackButton
              link="/school/features/users"
              action="Back"
              location={`Users: Manage users`}
            />
          </div>
          <div className="md:grid grid-cols-3 gap-2">
            <div>Error: {usersError}</div>
          </div>
        </div>
      </PageContent>
    );
  }
  if (user !== null) {
    return (
      <PageContent>
        <div className="p-3 bg-white-blue space-y-2 max-h-myscreen overflow-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
          <FeatureTitle name="User management" color="blue" />
          <div className="flex flex-col md:flex-row justify-between">
            <GoBackButton
              link="/school/features/users"
              action="Back"
              location={`Users: Manage users`}
            />
          </div>
          <div className="md:grid grid-cols-3 gap-2">
            <Input
              label="First Name"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.names}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Last Name"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.lastname}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Email"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.email}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Date of birth"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.dateofbirth}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Gender"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.gender}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Section"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.section}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Combination"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.combination}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Academic level"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.academic_level}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="User type"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.user_type}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Guardian name"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.guardian_name}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Guardian telephone"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.guardian_tel}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Activation code"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.activation_code}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Province"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.province}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="District"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.district}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Sector"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.sector}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Joined on"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.created_at}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Guardian on"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.guardian_tel_guardian}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Country"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.country}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Reference ID"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.country}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Login method"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.login_method}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Telephone"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.telephone}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <Input
              label="Profile Completed"
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Kevin",
                disabled: true,
              }}
              value={user.isprofilecompleted ? 'yes' : 'no'}
              validation={{ required: true, notEmpty: true }}
              shouldValidate
              error="First Name is required"
            />
            <img
              alt="User profile"
              className="h-96px w-auto m-auto"
              src={user.avatarimage}
            />
          </div>
        </div>
      </PageContent>
    );
  }
  return (
    <PageContent>
      <div>No data...</div>
    </PageContent>
  );
};

export default UserInfo;
