import {
  PageBody,
  SectionTitle,
} from "../../../../elements/text";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Input from "../../../../elements/input";
import Spinner from "../../../../elements/spinner";
import { Button, GoBackButton, ButtonLink } from "../../../../elements/button";
import PageContent from "../../pageContent";
import Notification from "../../../../elements/notification/notification";
import Modal from "../../../../elements/modal/modal";
import SuccessIcon from "../../../../../images/icons/success.svg";
import axios from "../../../../../axios-base";
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const CreateForm = (props) => {
  const history = useHistory();
  const loading = useSelector((state) => state.programs.programsLoading);
  const programs = useSelector((state) => state.programs.programs);
  const [program, setProgram] = useState();
  const [section, setSection] = useState();
  const [classlevel, setClasslevel] = useState();
  const [combination, setCombinations] = useState();
  const [stream, setStreams] = useState("");
  const [streamList, setStreamList] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [showSuccessModal, setshowSuccessModal] = useState(false);
  const [teacher, setTeacher] = useState("");
  const teachers = [{ value: 1, label: "Eric" }];
  const programName = getProgramNameList(programs);
  // const sections = getSectionListByProgram(programs, program);
  // const classlevels = getclassesList(programs, program, section);
  // Initialize states for sections and classlevels
  const [sections, setSections] = useState([]);
  const [classlevels, setClassLevels] = useState([]);
  //const combinations = getcombinationsList(programs, program, section);
  const [combinationsList, setCombinationsList] = useState();
  const subjectlist = getsubjectsList(programs, program, section, combination);
  const userAuth = useSelector((state) => state.auth);
  const [isCombinationsLoading, setIsCombinationsLoading] = useState(false);
  // TeachersList
  const [teachersList, setTeachersList] = useState([]);
  // SubjectsList
  const [subjectsList, setSubjectsList] = useState([]);
  const [saveClassIsLoading, setSaveClassIsLoading] = useState(false);
  const { classId } = useParams(); // Extract the userId from the URL



  useEffect(() => {
    // Get cbc Combinations and streams
    setIsCombinationsLoading(true);

    const fetchCombinationsStreams = axios.put(
      `/opanda/cbc-combinations-streams/`,
      { user_id: userAuth.user.id },
      {
        headers: {
          Authorization: `Bearer ${userAuth.token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const fetchActivatedTeachers = axios.post(
      `/opanda/manage-users/registration-activated-teachers`,
      { user_id: userAuth.user.id },
      {
        headers: {
          Authorization: `Bearer ${userAuth.token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const fetchSubjects = axios.put(
      `/opanda/cbc-combinations-streams/getallsubjects`,
      { user_id: userAuth.user.id },
      {
        headers: {
          Authorization: `Bearer ${userAuth.token}`,
          "Content-Type": "application/json",
        },
      }
    );

    Promise.all([fetchCombinationsStreams, fetchActivatedTeachers, fetchSubjects])
      .then(([combinationsStreamsResponse, activatedTeachersResponse, subjectsResponse]) => {
        setIsCombinationsLoading(false);

        const combinationsList = combinationsStreamsResponse.data.results[0].map((item) => item.abbreviation);
        const streamList = combinationsStreamsResponse.data.results[1].map((item) => item.name);
        setCombinationsList(combinationsList);
        setStreamList(streamList);
        // Teachers
        setTeachersList(activatedTeachersResponse.data.results.map((item) => {
          return { label: item.email, value: item.email }
        }))
        // Subjects
        setSubjectsList(subjectsResponse.data.results.map((item) => {
          return { label: item.subject, value: item.subject }
        }))

        /*console.log("Activated Teachers:", activatedTeachersResponse.data.results.map((item) => {
          return item.email 
        }));
        console.log("All Subjects:", subjectsResponse.data.results.map((item) => {
          return item.subject 
        }));*/
      })
      .catch((err) => {
        setIsCombinationsLoading(false);
        console.log(err);
      });

  }, [])
  useEffect(() => {
    try {
      const sectionList_ = getSectionListByProgram(programs, program);
      setSections(sectionList_);

      const classLevelList_ = getclassesList(programs, program, section);
      setClassLevels(classLevelList_);
    } catch (err) {

    }
  }, [programs, program, section]);

  useEffect(() => {
    setSaveClassIsLoading(true)

    axios.put(
      `/opanda/cbc-combinations-streams/getClassInformation`,
      { user_id: userAuth.user.id, classId: classId },
      {
        headers: {
          Authorization: `Bearer ${userAuth.token}`,
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      setSaveClassIsLoading(false)

      // Set program
      setProgram((res?.data?.results[0]?.program == 0) ? "CBC" : null)
      // Set section
      setSection(res?.data?.results[0]?.section)
      setCombinations(res?.data?.results[0]?.combination)
      // Set class level
      setClasslevel(res?.data?.results[0]?.level)
      // Set stream name
      setStreams(res?.data?.results[0]?.stream)
      // Set subjects
      //setSubjects(res?.data?.results?.stream)
      // Set class teacher
      setTeacher(res?.data?.results[0]?.classteacher)


    }).catch((err) => {
      console.log(err.code)
      alert(err)
      setSaveClassIsLoading(false)
    })

  }, []);

  const UpdateAclass = () => {
    let data;
    if (section === "Advanced level") {
      data = {
        program: program,
        section: section,
        classlevel: classlevel,
        combination: combination,
        stream: stream,
        teacher: teacher.value,
        subjects: subjects.map((item) => { return item.value })
      }

    } else if (section === "Ordinary level") {
      data = {
        program: program,
        section: section,
        classlevel: classlevel,
        stream: stream,
        teacher: teacher.value,
        subjects: subjects.map((item) => { return item.value })
      }

    }


    if (!data) {
      alert("Empty fields!!?")
      return
    }
    if (data && (!data.classlevel || data.classlevel === "SELECT")) {
      alert("Please choose a class level!!?")
      return
    }
    if (data && (!data.stream || data.stream === "SELECT")) {
      alert("Please choose a class stream!!?")
      return
    }
    if (data && (!data.teacher || data.teacher === "SELECT")) {
      alert("Please choose a class teacher!!?")
      return
    }
    if (data && (!data.subjects || !data.subjects.length > 0)) {
      alert("Please choose a subject!!?")
      return
    }
    if (data && (!data.combination || data.combination === "SELECT") && parseInt(data.classlevel) > 3) {
      alert("Please choose a combination!!?")
      return
    }
    setSaveClassIsLoading(true)

    axios.put(
      `/opanda/cbc-combinations-streams/updateclassinformarion`,
      { user_id: userAuth.user.id, ...data, teacher: teacher.value.match(/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i)[0], classId: classId },
      {
        headers: {
          Authorization: `Bearer ${userAuth.token}`,
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      setSaveClassIsLoading(false)
      if (res.data.message === "cbc combinations CREATED SUCCESSFULLY") {
        setshowSuccessModal(true)
      }

    }).catch((err) => {
      console.log(err.code)
      alert(err)
      setSaveClassIsLoading(false)
    })

  }
  return (
    <PageContent>
      <Modal
        show={showSuccessModal}
        modalClosed={() => {
          setshowSuccessModal(false)
          history.push("/school/features/classes")
        }}
        small
      >
        <div className="flex flex-col">
          <SectionTitle name="Created!!" color="blue" alignment="center" />
          <PageBody
            name="You've successfully updated the class, Click on manage to continue with more actions"
            color="black"
            alignment="center"
          />
          <div className="m-4">
            <img
              alt={props.user}
              className="h-18 w-auto m-auto"
              src={SuccessIcon}
            />
          </div>
          <div className="flex flex-row items-center justify-center">
            <ButtonLink
              name="Manage class"
              outline="true"
              color="blue"
              page="/school/features/classes"
            />
          </div>
        </div>
      </Modal>
      {(!isCombinationsLoading) ? <div
        className={`relative w-75%  p-3 col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
      >
        <GoBackButton
          link="/school/features/classes"
          action="Back"
          location="Classes"
        />
        {loading ? (
          <Spinner size="32" color="blue" />
        ) : (
          <div className="bg-white drop-shadow-md mt-5 p-2 md:px-10 md:h-tab-screen md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
            <SectionTitle name="Update class" color="blue" />
            <>
              <Input
                label="Program"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  optionsType: "minimal",
                  options: programName,
                }}
                value={program}
                changed={setProgram}
                validation={{ required: true }}
                shouldValidate
                error="Program is required"
              />
              <div className="flex space-x-2">
                <Input
                  label="Section"
                  elementType="select"
                  elementConfig={{
                    startingValue: "SELECT",
                    optionsType: "minimal",
                    options: sections,
                  }}
                  value={section}
                  changed={setSection}
                  validation={{ required: true }}
                  shouldValidate
                  error="Section is required"
                />
                <Input
                  label="Class level"
                  elementType="select"
                  elementConfig={{
                    startingValue: "SELECT",
                    optionsType: "minimal",
                    options: classlevels,
                  }}
                  value={classlevel}
                  changed={setClasslevel}
                  validation={{ required: true }}
                  shouldValidate
                  error="Class level is required"
                />
                {(classlevel && classlevel > 3) ? <Input
                  label="Combination"
                  elementType="select"
                  elementConfig={{
                    startingValue: "SELECT",
                    optionsType: "minimal",
                    options: combinationsList,
                  }}
                  value={combination}
                  changed={setCombinations}
                  validation={{ required: true }}
                  shouldValidate
                  error="Combinations is required"
                /> : ""}
                <Input
                  label="Stream Name"
                  elementType="select"
                  elementConfig={{
                    startingValue: "SELECT",
                    optionsType: "minimal",
                    options: streamList,
                  }}
                  value={stream}
                  changed={setStreams}
                  validation={{ required: true }}
                  shouldValidate
                  error="Streams is required"
                />

                {/* <Input
                  label="Stream"
                  elementType="input"
                  elementConfig={{
                    type: "text",
                    placeholder: "A, B, C or 1, 2, 3",
                  }}
                  value={stream}
                  changed={setStream}
                  validation={{ required: false }}
                  shouldValidate
                  error="Aggregate is required"
                /> */}
              </div>
              <div className="flex space-x-2">
                <Input
                  label="Subjects"
                  elementType="multipleselect"
                  elementConfig={{
                    startingValue: "SELECT",
                    optionsType: "minimal",
                    options: subjectsList,
                  }}
                  value={subjects}
                  changed={setSubjects}
                  validation={{ notEmpty: true }}
                  shouldValidate
                  error="Subjects is required"
                />
              </div>
              <div className="flex space-x-2 w-48">
                <Input
                  label="Class teacher"
                  elementType="singleselect"
                  elementConfig={{
                    startingValue: "SELECT",
                    options: teachersList,
                  }}
                  value={teacher}
                  changed={setTeacher}
                  validation={{ notEmpty: true }}
                  shouldValidate
                  error="Class teacher is required"
                />
              </div>
              {(!saveClassIsLoading) ? <div className="flex space-x-2 justify-center py-5">
                {/* <Button name="Cancel" outline="true" color="blue" /> */}
                <Button
                  name="Update"
                  outline="false"
                  color="blue"
                  clicked={() => {
                    UpdateAclass();
                  }}
                />
              </div> : <Spinner size="10" color="blue" />}
            </>
          </div>
        )}
      </div> : <Spinner size="32" color="blue" />}
      <div className="w-25% mt-5">
        {/* <Notification auth /> */}
      </div>
    </PageContent>
  );
};

const getProgramNameList = (programObject) => {
  let names = Object.keys(programObject);
  return names;
};
const getSectionListByProgram = (programObject, program = "CBC") => {
  let sections = Object.keys(programObject[program].structure);
  return sections;
};
const getclassesList = (
  programObject,
  program = "CBC",
  section = "Ordinary level"
) => {
  let classes = programObject[program]["structure"][section]["Classes"];
  return classes;
};
const getcombinationsList = (
  programObject,
  program = "CBC",
  section = "Advanced level"
) => {
  let combinations = [];
  if (section.includes("Ordinary level")) {
  } else {
    combinations = Object.keys(
      programObject[program]["structure"][section].Combinations
    );
  }
  return combinations;
};

const getsubjectsList = (
  programObject,
  program = "CBC",
  section = "Ordinary level",
  combination = ""
) => {
  let subjects = [];
  if (section.includes("Ordinary level")) {
    // subjects = programObject[program]["structure"][section].Subjects;
  } else {
    // if (combination !== undefined && combination !== "") {
    //   console.log(combination);
    //   subjects =
    //     programObject[program]["structure"][section]["Combinations"][
    //     combination
    //     ]["default"];
    //   subjects.push(programObject[program]["structure"][section]["Subjects"]);
    // } else {
    //   subjects.push(programObject[program]["structure"][section].Subjects);
    // }
  }

  return subjects;
};

export default CreateForm;
