import axios from "../../axios-base";

export const VERIFY_SCHOOL_OTP_INIT = "VERIFY_SCHOOL_OTP_INIT";
export const VERIFY_SCHOOL_OTP_SUCCESS = "VERIFY_SCHOOL_OTP_SUCCESS";
export const VERIFY_SCHOOL_OTP_FAIL = "VERIFY_SCHOOL_OTP_FAIL";


export const verifySchoolOTPInit = () => {
  return {
    type: VERIFY_SCHOOL_OTP_INIT,
  };
};

export const verifySchoolOTPSuccess = (status) => {
  return {
    type: VERIFY_SCHOOL_OTP_SUCCESS,
    status: status,
  };
};


export const verifySchoolOTPFail = (error) => {
  return {
    type: VERIFY_SCHOOL_OTP_FAIL,
    error: error,
  };
};

export const verifySchoolOTPStart = (data) => {
  console.log(data, "data");
  return (dispatch) => {
    dispatch(verifySchoolOTPInit());
    axios
    .post(`/opanda/panda-users/verify-token`, data, {
      headers: { authorization: data.token },
    })
    .then((response) => {
      dispatch(verifySchoolOTPSuccess(response.data.results.status));
    })
    .catch((err) => {
      dispatch(verifySchoolOTPFail(err.message));
    });
   
  };
};
