import React, { useEffect, useState, useCallback } from 'react';
import { CardSubText, CardTitle } from '../../../elements/text';
import { Button } from '../../../elements/button';
import eventBus from '../../../../utils/eventBus';
import _ from 'lodash';
import axios from "../../../../axios-base";
import { useDispatch, useSelector } from 'react-redux';
import { school_dashboard_users_count_start, school_dashboard_users_start } from '../../../../store/actions/school.dashboard.users';


const FileInput = (props) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const [message, setMessage] = useState("");
  const userAuth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      const validTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ];
      if (validTypes.includes(fileType)) {
        setFile(selectedFile);
        setFileName(selectedFile.name);
      } else {
        alert('Please upload a valid Excel file (.xls or .xlsx).');
        event.target.value = null; // Reset the file input
      }
    }
  };

  const handleFileReset = () => {
    setFile(null);
    setFileName('');
    setUploadProgress(0);
    setIsUploading(false);
    document.getElementById('fileInput').value = null; // Reset the file input
  };

  const handleFileUpload = async () => {
    if (file && !isUploading) {
      setIsUploading(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('schoolId', user.id);
      formData.append("isForStudentsOnly", props.isForStudentsOnly ? "true" : "false");

      props.setIsProcessing(true);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/opanda/uploadSchoolUsers`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
              if (progressEvent.lengthComputable) {
                const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                if (percentCompleted === 100) {
                  setMessage("Processing... Please wait...");
                }
                setUploadProgress(percentCompleted);
              }
            },
          }
        );

        setIsUploading(false);
        if (response.status === 200) {
          setMessage("Users saved!!!");
          eventBus.emit('clearUploadExcelListOfUsersFileEvent');
          props.onCancel();
          props.setIsProcessing(false);
          dispatch(
            school_dashboard_users_count_start({
              token: userAuth.token,
              school_id: userAuth.userId,
            })
          );
          dispatch(
            school_dashboard_users_start({
              token: userAuth.token,
              school_id: userAuth.userId,
            })
          );
          setTimeout(() => {
            alert("Users saved!!!")
          }, 2000)

        } else {
        }
      } catch (error) {
        setIsUploading(false);
        console.error('Error during file upload:', error);
      }
    } else {
      // alert('Please select a file to upload');
    }
  };

  const debouncedHandleFileUpload = useCallback(_.debounce(handleFileUpload, 300), [file, isUploading]);

  useEffect(() => {
    const handleuploadExcelListOfUsersEventEvent = () => {
      debouncedHandleFileUpload();
    };

    const handleclearUploadExcelListOfUsersFileEventEvent = () => {
      handleFileReset();
      setMessage("")
    };

    eventBus.on('uploadExcelListOfUsersEvent', handleuploadExcelListOfUsersEventEvent);
    eventBus.on('clearUploadExcelListOfUsersFileEvent', handleclearUploadExcelListOfUsersFileEventEvent);

    // Clean up the event listener on component unmount
    return () => {
      eventBus.off('clearUploadExcelListOfUsersFileEvent', handleclearUploadExcelListOfUsersFileEventEvent);
      eventBus.off('uploadExcelListOfUsersEvent', handleuploadExcelListOfUsersEventEvent);
    };
  }, [debouncedHandleFileUpload]);

  return (
    <div>
      <CardTitle name="Upload excel file" color="black" />
      <input type="file" id="fileInput" onChange={handleFileChange} />
      {uploadProgress > 0 && (
        <div>
          <progress value={uploadProgress} max="100">{uploadProgress}%</progress>
          <p>Upload Progress: {uploadProgress}%</p>
          <p>{message}</p>
        </div>
      )}
      {/* <button onClick={handleFileReset} disabled={!file}>Clear</button> */}
      {/* <button onClick={handleFileUpload}>Upload</button> */}
    </div>
  );
};

export default FileInput;
