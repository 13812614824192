import Layout from '../../../components/common/Layout';
import Library from '../../../components/pages/student/library/Library';

const LibraryContainer = () => {
  return (
    <Layout>
      <Library />
    </Layout>
  );
};

export default LibraryContainer;
