import axios from "../../../axios-base";

export const GET_PROGRAMS_START = "GET_PROGRAMS_START";
export const GET_PROGRAMS_SUCCESS = "GET__PROGRAMS_SUCCESS";
export const GET_PROGRAMS_FAIL = "GET__PROGRAMS_FAIL";


export const getProgramsStart = () => {
  return {
    type: GET_PROGRAMS_START,
  };
};

export const getProgramsSuccess = (programs) => {
  return {
    type: GET_PROGRAMS_SUCCESS,
    programs: programs,
  };
};

export const getProgramsFail = (error) => {
  return {
    type: GET_PROGRAMS_FAIL,
    error: error,
  };
};



export const getPrograms = (url) => {
  return (dispatch) => {
    dispatch(getProgramsStart());
    // get programs structure
    axios
      .get(url)
      .then((response) => {
        dispatch(getProgramsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getProgramsFail(err.message));
      });
  };
};
