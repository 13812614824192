import { updateObject } from "../../shared/utility";
import {
  SCHOOL_REGISTERED_USERS_COUNT_INIT,
  SCHOOL_REGISTERED_USERS_COUNT_SUCCESS,
  SCHOOL_REGISTERED_USERS_COUNT_FAILED,

  SCHOOL_REGISTERED_USERS_INIT,
  SCHOOL_REGISTERED_USERS_SUCCESS,
  SCHOOL_REGISTERED_USERS_FAILED,

  SCHOOL_ADD_REGISTERED_USERS_INIT,
  SCHOOL_ADD_REGISTERED_USERS_SUCCESS,
  SCHOOL_ADD_REGISTERED_USERS_FAILED,

  SCHOOL_REMOVE_REGISTERED_USERS_INIT,
  SCHOOL_REMOVE_REGISTERED_USERS_SUCCESS,
  SCHOOL_REMOVE_REGISTERED_USERS_FAILED,
} from "../actions/school.dashboard.user.registered";
//--
const initialReducer = {
  school_registered_users_count: [
    { user_type: "student", number: 0 },
    { user_type: "teacher", number: 0 },
    { user_type: "parent", number: 0 },
  ],
  school_registered_users_count_loading: true,
  school_registered_users: [],
  school_registered_users_loading: true,
  school_add_registered_user_loading: true,
  school_remove_registered_user_loading: true,
  message: "",
};

function school_registered_users_count_init(state, action) {
  return updateObject(state, {
    school_registered_users_count_loading: true,
  });
}
function school_registered_users_count_success(state, action) {
  return updateObject(state, {
    school_registered_users_count: action.payload,
    school_registered_users_count_loading: false,
    message: "success",
  });
}
function school_registered_users_count_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    school_registered_users_count_loading: false,
  });
}

function school_registered_users_init(state, action) {
  return updateObject(state, {
    school_registered_users_loading: true,
  });
}
function school_registered_users_success(state, action) {
  return updateObject(state, {
    school_registered_users: action.payload,
    school_registered_users_loading: false,
    message: "success",
  });
}
function school_registered_users_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    school_registered_users_loading: false,
  });
}

function school_add_registered_users_init(state, action) {
  return updateObject(state, {
    school_add_registered_user_loading: true,
  });
}
function school_add_registered_users_success(state, action) {
  return updateObject(state, {
    school_add_registered_user_loading: false,
    message: "success",
  });
}
function school_add_registered_users_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    school_add_registered_user_loading: false,
  });
}

function school_remove_registered_users_init(state, action) {
  return updateObject(state, {
    school_remove_registered_user_loading: true,
  });
}
function school_remove_registered_users_success(state, action) {
  return updateObject(state, {
    school_remove_registered_user_loading: false,
    message: "success",
  });
}
function school_remove_registered_users_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    school_remove_registered_user_loading: false,
  });
}

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case SCHOOL_REGISTERED_USERS_COUNT_INIT:
      return school_registered_users_count_init(state, action);
    case SCHOOL_REGISTERED_USERS_COUNT_SUCCESS:
      return school_registered_users_count_success(state, action);
    case SCHOOL_REGISTERED_USERS_COUNT_FAILED:
      return school_registered_users_count_failed(state, action);

    case SCHOOL_REGISTERED_USERS_INIT:
      return school_registered_users_init(state, action);
    case SCHOOL_REGISTERED_USERS_SUCCESS:
      return school_registered_users_success(state, action);
    case SCHOOL_REGISTERED_USERS_FAILED:
      return school_registered_users_failed(state, action);

    case SCHOOL_ADD_REGISTERED_USERS_INIT:
      return school_add_registered_users_init(state, action);
    case SCHOOL_ADD_REGISTERED_USERS_SUCCESS:
      return school_add_registered_users_success(state, action);
    case SCHOOL_ADD_REGISTERED_USERS_FAILED:
      return school_add_registered_users_failed(state, action);

    case SCHOOL_REMOVE_REGISTERED_USERS_INIT:
      return school_remove_registered_users_init(state, action);
    case SCHOOL_REMOVE_REGISTERED_USERS_SUCCESS:
      return school_remove_registered_users_success(state, action);
    case SCHOOL_REMOVE_REGISTERED_USERS_FAILED:
      return school_remove_registered_users_failed(state, action);

    default:
      return state;
  }
};
export default reducer;
