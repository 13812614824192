import StudentRecoveryInformation from "./student/recoveryInformation";
import TeacherRecoveryInformation from "./teacher/recoveryInformation";
import SchoolRecoveryInformation from "./school/recoveryInformation";
import ParentRecoveryInformation from "./parent/recoveryInformation";

const RecoveryInformation = (props) => {
  
  let userType = props.user;
  let Tab = StudentRecoveryInformation;
  switch (userType) {
    case "student":
      Tab = StudentRecoveryInformation;
      break;
    case "teacher":
      Tab = TeacherRecoveryInformation;
      break;
    case "school":
      Tab = SchoolRecoveryInformation;
      break;
    case "parent":
      Tab = ParentRecoveryInformation;
      break;
    default:
      Tab = StudentRecoveryInformation;
      break;
  }
  return (
    <div>
      <Tab />
    </div>
  );
}
export default RecoveryInformation;
