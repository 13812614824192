import axios from "../../axios-base";

export const STUDENT_DASHBOARD_HOMEPAGE_PROGRAMS_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_PROGRAMS_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_PROGRAMS_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_PROGRAMS_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_PROGRAMS_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_PROGRAMS_FAILED";

export function student_programs_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_PROGRAMS_INIT
  };
}
export function student_programs_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_PROGRAMS_SUCCESS,
    payload: data
  };
}
export function student_programs_fail(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_PROGRAMS_FAILED,
    payload: error
  };
}

export function student_programs_start(data) {
  return (dispatch) => {
    dispatch(student_programs_init());

    axios
      .post(
        `/opanda/programs`,
        {
          username: data.username,
          userId: data.userId
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_programs_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_programs_fail(err.message));
      });
  };
}

//----Student programs cbc
export const STUDENT_DASHBOARD_HOMEPAGE_STUDENT_PROGRAMS_CBC_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_STUDENT_PROGRAMS_CBC_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_STUDENT_PROGRAMS_CBC_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_STUDENT_PROGRAMS_CBC_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_STUDENT_PROGRAMS_CBC_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_STUDENT_PROGRAMS_CBC_FAILED";

export function student_student_programs_cbc_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_STUDENT_PROGRAMS_CBC_INIT
  };
}
export function student_student_programs_cbc_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_STUDENT_PROGRAMS_CBC_SUCCESS,
    payload: data
  };
}
export function student_student_programs_cbc_fail(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_STUDENT_PROGRAMS_CBC_FAILED,
    payload: error
  };
}
//---
const CACHE_KEY_PROGRAMS = 'studentPrograms';
const invalidateCache = () => {
  try {
    localStorage.removeItem(CACHE_KEY_PROGRAMS);
    localStorage.removeItem(`${CACHE_KEY_PROGRAMS}_timestamp`);
  } catch (error) {

  }
};

export function student_student_programs_cbc_start(data) {
  return (dispatch) => {
    dispatch(student_student_programs_cbc_init());
    //---
    const cachedData = localStorage.getItem(CACHE_KEY_PROGRAMS);
    const cachedTimestamp = localStorage.getItem(`${CACHE_KEY_PROGRAMS}_timestamp`);
    const currentTime = Date.now();
    //---
    if (cachedData && cachedTimestamp) {
      const expirationTime = parseInt(cachedTimestamp, 20) + 20 * 60 * 1000; // 20 minutes
      if (currentTime < expirationTime) {
        dispatch(student_student_programs_cbc_success(JSON.parse(cachedData)));
      } else {
        invalidateCache();
      }
    }
    axios
      .post(
        `/opanda/programs/studentprogramscbc`,
        {
          username: data.username,
          userId: data.userId,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        const cachedData = localStorage.getItem(CACHE_KEY_PROGRAMS);
        const cachedTimestamp = localStorage.getItem(`${CACHE_KEY_PROGRAMS}_timestamp`);

        //----Save to cache data--
        localStorage.setItem(CACHE_KEY_PROGRAMS, JSON.stringify(response.data.results));
        localStorage.setItem(`${CACHE_KEY_PROGRAMS}_timestamp`, Date.now().toString());
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_student_programs_cbc_success(response.data.results));
        }
      })
      .catch((err) => {
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_student_programs_cbc_fail(err.message));
        }
      });
  };
}
export function teacher_student_programs_cbc_start(data) {
  return (dispatch) => {
    dispatch(student_student_programs_cbc_init());
    //---
    const cachedData = localStorage.getItem(CACHE_KEY_PROGRAMS + data.academic_level);
    const cachedTimestamp = localStorage.getItem(`${CACHE_KEY_PROGRAMS + data.academic_level}_timestamp`);
    const currentTime = Date.now();
    //---
    if (cachedData && cachedTimestamp) {
      const expirationTime = parseInt(cachedTimestamp, 20) + 20 * 60 * 1000; // 20 minutes
      if (currentTime < expirationTime) {
        dispatch(student_student_programs_cbc_success(JSON.parse(cachedData)));
      } else {
        invalidateCache();
      }
    }
    axios
      .post(
        `/opanda/programs/studentprogramscbc`,
        {
          username: data.username,
          userId: data.userId,
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        const cachedData = localStorage.getItem(CACHE_KEY_PROGRAMS + data.academic_level);
        const cachedTimestamp = localStorage.getItem(`${CACHE_KEY_PROGRAMS + data.academic_level}_timestamp`);

        //----Save to cache data--
        localStorage.setItem(CACHE_KEY_PROGRAMS + data.academic_level, JSON.stringify(response.data.results));
        localStorage.setItem(`${CACHE_KEY_PROGRAMS + data.academic_level}_timestamp`, Date.now().toString());
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_student_programs_cbc_success(response.data.results));
        }
      })
      .catch((err) => {
        if (cachedData && cachedTimestamp) {

        } else {
          dispatch(student_student_programs_cbc_fail(err.message));
        }
      });
  };
}
//----Load extra curricular modules---
export const STUDENT_DASHBOARD_HOMEPAGE_LOAD_EXTRA_CURRICULAR_MODULES_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_LOAD_EXTRA_CURRICULAR_MODULES_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_LOAD_EXTRA_CURRICULAR_MODULES_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_STUDENT_LOAD_EXTRA_CURRICULAR_MODULES_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_LOAD_EXTRA_CURRICULAR_MODULES_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_STUDENT_LOAD_EXTRA_CURRICULAR_MODULES_FAILED";

export function student_load_extra_curricular_modules_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_LOAD_EXTRA_CURRICULAR_MODULES_INIT
  };
}
export function student_load_extra_curricular_modules_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_LOAD_EXTRA_CURRICULAR_MODULES_SUCCESS,
    payload: data
  };
}
export function student_load_extra_curricular_modules_fail(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_LOAD_EXTRA_CURRICULAR_MODULES_FAILED,
    payload: error
  };
}

export function student_load_extra_curricular_modules_start(data) {
  return (dispatch) => {
    dispatch(student_load_extra_curricular_modules_init());

    axios
      .post(
        `/opanda/programs/extra_curricular_modules`,
        {
          username: data.username,
          userId: data.userId,
          academic_level: data.academic_level,
          module: data.module
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_load_extra_curricular_modules_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_load_extra_curricular_modules_fail(err.message));
      });
  };
}


export function teacher_load_extra_curricular_modules_start(data) {
  return (dispatch) => {
    dispatch(student_load_extra_curricular_modules_init());

    axios
      .post(
        `/opanda/programs/extra_curricular_modules`,
        {
          username: data.username,
          userId: data.userId,
          academic_level: data.academic_level,
          module: data.module
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_load_extra_curricular_modules_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_load_extra_curricular_modules_fail(err.message));
      });
  };
}
