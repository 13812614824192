export default function TableHead (props) {
  return (
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        <th scope="col" className="py-3 px-6"></th>
     
         {props.thead.map((item1, index) => (
            <th scope="col" key={index} className="px-4 py-3 text-left ">
            {item1}
          </th>
          ))}
      
    
      </tr>
    </thead>
  );
};

export function TableHeader (props){
  return (
    <thead className="text-xs text-gray-700 capitalize bg-white dark:text-gray-400 dark:border-gray-700 border-gray-200">
      <tr>
        <th scope="col" className="py-3 px-6"></th>
     
         {props.thead.map((item1, index) => (
            <th scope="col" key={index} className="px-4 py-3 text-left border-gray-200 border">
            {item1}
          </th>
          ))}
      </tr>
    </thead>
  );
};

