import LinkNewAccount from '../../../components/pages/parent/user/linkaccount/LinkNewAccount';
import Layout from '../../../components/common/Layout';

const ParentLinkNewAccountContainer = () => {
  return (
    <Layout>
      <LinkNewAccount />
    </Layout>
  );
};

export default ParentLinkNewAccountContainer;