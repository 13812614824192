import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../elements/input";
import { CardSubText } from "../../../elements/text";
import * as actions from "../../../../store/actions/";
import { scientificSubject } from "../../../../functions/combinationsAndSubjects";

const SubjectOfInterestItem = forwardRef((props, ref) => {
  const [numberOdSubjects, setNumberOdSubjects] = useState(1);
  const [subject, setSubject] = useState("");
  const [interest, setinterest] = useState("");
  const [interestedSubjects, setInterestedSubjects] = useState([]);
  const subjectsArray = new Array(numberOdSubjects).fill("");//--
  // subjectsArray = scientificSubject;
  const subjectsnames = useSelector(
    (state) => state.previewpanda.subjectsnames
  );
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    getInterest() {
      return interestedSubjects.join(",");
    },
  }));

  const selectSubjectOfInterestItem = (value) => {
    if (interest === "") {
      setinterest(value);
    } else {
      setinterest(interest + "," + value);
    }
  };

  useEffect(() => {
    dispatch(actions.getSubjectNames());
  }, []);

  const getFilteredSubjects = (data) => {
    return data.filter((subject) => { return !interestedSubjects.includes(subject.subject) });
  }


  return (
    <div>
      <CardSubText name="Subject of interest" color="black" />
      <div className="flex items-end md:grid grid-cols-3 gap-2">
        <div className="w-full">
          <Input
            elementType="select"
            elementConfig={{
              startingValue: "SELECT",
              options: getFilteredSubjects(subjectsnames),
            }}
            value={subject}
            changed={setSubject}
            shouldValidate
            error="Subject of interest required"
          />
          <div style={{ padding: 5 }}><ul>{interestedSubjects?.map((subject) => {
            return <li style={{ cursor: "hand" }}>{subject}<span onClick={() => {
              setInterestedSubjects(interestedSubjects.filter((s) => s !== subject))
            }} style={{ color: "red", fontWeight: "bolder", cursor: "pointer",  }}>x</span></li>
          })}</ul></div>
        </div>
        <MdOutlineAddCircleOutline
          className="pt-2"
          size={50}
          onClick={() => {
            selectSubjectOfInterestItem(subject);
            setInterestedSubjects([...interestedSubjects, subject])
          }}
        />
      </div>
    </div>
  );
});

export default SubjectOfInterestItem;
