import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = ({ handleContentChange, content }) => {
  const [editorHtml, setEditorHtml] = useState(content);
  const editorRef = useRef();

  const handleEditorChange = (html) => {
    setEditorHtml(html);
    handleContentChange(html);
  };

  const handleColorChange = (value, attribute) => {
    if (editorRef && editorRef.current) {
      const quill = editorRef.current.getEditor();
      if (quill) {
        const range = quill.getSelection();
        if (range) {
          const format = {};
          format[attribute] = value;
          quill.format(format);
        }
      }
    }
  };

  useEffect(() => {
    if (editorRef.current) {
      const quill = editorRef.current.getEditor();
      quill.root.style.minHeight = '300px';
      quill.root.style.maxHeight = '600px';
    }
  }, []);

  useEffect(() => {
    // Synchronize state with props when content prop changes
    setEditorHtml(content);
  }, [content]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['link', 'image', 'video'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['clean'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'table': [] }], // Include table module
    ],
  };

  return (
    <div>
      <ReactQuill
        theme="snow"
        value={editorHtml || ''} // Ensure value is not null or undefined
        onChange={handleEditorChange}
        ref={editorRef}
        style={{ minHeight: '300px' }}
        modules={modules}
      />
      <input
        type="color"
        onChange={(e) => handleColorChange(e.target.value, 'color')}
        style={{ marginRight: '10px', marginTop: '10px', display: 'none' }}
      />
      <input
        type="color"
        onChange={(e) => handleColorChange(e.target.value, 'background')}
        style={{ marginTop: '10px', display: 'none' }}
      />
    </div>
  );
};

export default RichTextEditor;
