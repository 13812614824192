import { Route, Switch } from "react-router-dom";
import { Home } from "../landingpages/home";
import { Algorithms } from "../testingalgorithms/Algorithms";
import {
  TeacherTestAlgorithms,
  TeacherTestsList,
  ViewTest,
} from "../testingalgorithms/TeacherTestAlgorithms";
import { TeacherTestMultiplechoice } from "../testingalgorithms/TeacherTestMatching";
import { TeacherTestFillin } from "../testingalgorithms/TeacherTestFillin";
import { TeacherTestOpenended } from "../testingalgorithms/TeacherTestOpenended";
import { StudentViewTest } from "../testingalgorithms/StudentTestAlgorithms";
import {
  Login,
  SocialLoginFailed,
  SocialPlatfomsLogin,
} from "../landingpages/login";
import SummaryNotes from "../components/pages/landing/preview/summarynotes/SummaryNotes";
import SummaryNote from "../components/pages/landing/preview/summarynotes/SummaryNote";
import { Whoisitfor } from "../landingpages/whoisitfor";
import { Previewpanda } from "../landingpages/previewpanda";
import { Features } from "../landingpages/features";
import { Pricing } from "../landingpages/pricing";
import { FAQ } from "../landingpages/faq";
import Guestpreview from "../guestpages/guestpreview";
import Guesttest from "../guestpages/guesttest";
import { Enroll, Verify } from "../landingpages/enroll";
import { Donate } from "../landingpages/donate";
import Sponsorship from "../landingpages/sponsorship";
import PrivacyPolicy from "../landingpages/privacypolicy";
import Forgotpassword from "../landingpages/forgotpassword";
import Forgotpasswordverify from "../landingpages/forgotpasswordverify";
import Resetpassword from "../landingpages/resetpassword";
import Forgotpasswordquestions from "../landingpages/forgotpasswordquestions";
import ProtectedRoutes from "./protectedRoutes";
import Test from "../components/pages/landing/preview/tests/firstcoursetest";
import UnAuthorizedPage from "../components/common/unauthorized";
import { Teacherquiz } from "../landingpages/Teacherquiz";

const Routes = (props) => {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/testing">
        <Algorithms />
      </Route>
      <Route exact path="/teacher-test">
        <TeacherTestAlgorithms />
      </Route>
      <Route exact path="/teacher-test-matching">
        <TeacherTestMultiplechoice />
      </Route>
      <Route exact path="/teacher-test-fillin">
        <TeacherTestFillin />
      </Route>
      <Route exact path="/teacher-test-openended">
        <TeacherTestOpenended />
      </Route>
      <Route exact path="/teacher-test-list">
        <TeacherTestsList />
      </Route>
      <Route exact path="/view-test/:testmapId?">
        <ViewTest />
      </Route>
      <Route exact path="/student-view-test/:testmapId?">
        <StudentViewTest />
      </Route>
      <Route exact path="/social-platfoms-login/:token?">
        <SocialPlatfomsLogin />
      </Route>
      <Route exact path="/previewpanda/course">
        <SummaryNotes />
      </Route>
      <Route exact path="/previewpanda/summarynotes/:courseId?">
        <SummaryNote />
      </Route>
      <Route exact path="/whoisitfor">
        <Whoisitfor />
      </Route>
      <Route exact path="/previewpanda">
        <Previewpanda />
      </Route>
      <Route exact path="/previewpanda/guest">
        <Guestpreview />
      </Route>
      <Route exact path="/previewpanda/guest/:testId">
        <Guesttest />
      </Route>
      {/* <Route exact path="/cbc" component={CBC} /> */}
      <Route exact path="/features">
        <Features />
      </Route>
      <Route exact path="/pricing">
        <Pricing />
      </Route>
      <Route exact path="/faq">
        <FAQ />
      </Route>
      <Route exact path="/teacherquiz">
        <Teacherquiz />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/enroll">
        <Enroll />
      </Route>
      <Route exact path="/donate">
        <Donate />
      </Route>
      <Route exact path="/forgot">
        <Forgotpassword />
      </Route>
      <Route exact path="/forgot/question">
        <Forgotpasswordquestions />
      </Route>
      <Route exact path="/forgot/verify">
        <Forgotpasswordverify />
      </Route>
      <Route exact path="/reset">
        <Resetpassword />
      </Route>
      <Route exact path="/social-login-failed">
        <SocialLoginFailed />
      </Route>
      <Route exact path="/verify">
        <Verify />
      </Route>
      <Route exact path="/sponsorship">
        <Sponsorship />
      </Route>
      <Route exact path="/previewpanda/tests/:testId?">
        <Test />
      </Route>
      <Route exact path="/privacypolicy">
        <PrivacyPolicy />
      </Route>
      <Route exact path="/termsofservice">
        <PrivacyPolicy />
      </Route>
      <Route exact path="/unauthorized">
        <UnAuthorizedPage />
      </Route>
      {/* PROTECTED ROUTES */}
      <ProtectedRoutes />
    </Switch>
  );
};

export default Routes;
