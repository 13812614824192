import React from "react";

const Spinner = (props) => {
  return (
    <div className=" flex justify-center items-center">
      <div
        className={`animate-spin rounded-full h-${props.size} w-${props.size} border-b-2 border-${props.color}`}
      ></div>
    </div>
  );
};

export default Spinner;
