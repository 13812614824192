import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import parse from 'react-html-parser';
import FillinTypeAutoQuestionItem from './FillinTypeAutoQuestionItem';
import { ConsoleView } from 'react-device-detect';

const FillinTypeAutoQuestion = (props) => {
    const { answers } = props;
    const dispatch = useDispatch();
    const [text, setText] = useState('');
    useEffect(() => {

    }, []);

    const getTheQuestion = (questionMetadata) => {
        let questionData = questionMetadata.split("#O.G.S")[1];
        const answersMap = questionMetadata.split("#O.G.S")[2];
        const Exlanations = questionMetadata.split("#O.G.S")[3];
        questionData = questionData;
        //--Each answer piece--
        const wordsCol = [];
        const answers = answersMap.split("#O.G");
        for (var i = 1; i < answers.length; i++) {
            const wordsPool = answers[i].split("#.#.#");
            const wordsPool_ = [];
            for (var n = 1; n < wordsPool.length; n++) {
                if (wordsPool[n].includes("#A.N.S")) {
                    const theWord = wordsPool[n].split("#A.N.S")[0].trim();
                    wordsPool_.push(theWord);
                    questionData = questionData.replace(theWord, "------------");
                } else {
                    wordsPool_.push(wordsPool[n].trim());
                }
            }
            wordsCol.push(wordsPool_)
        }
        //----Word snippets-
        const words = questionData.split(" ");        
        let wordsCleaned = [];
        let m = 0;
        for (var i = 0; i < words.length; i++) {
            if (words[i].includes("------------")) {
                wordsCleaned.push(["------------", wordsCol[m]]);
                m++;
            } else {
                wordsCleaned.push([words[i], 0]);
            }
        }        
        const wordsCleanedNew = [];
        for (var b = 0; b < wordsCleaned.length; b++) {
            if (wordsCleaned[b][0] === "------------") {
                wordsCleanedNew.push(<FillinTypeAutoQuestionItem b={b} wordsCleaned={wordsCleaned} fillinTypeAuto={props.fillinTypeAuto} questionPlace={b} questionId={props.questionId} currentQuestionNumber={props.currentQuestionNumber} callFillinTypeAuto={props.callFillinTypeAuto} key={b} wordsPool={wordsCleaned[b][1]} />);
            } else {
                wordsCleanedNew.push(" " + wordsCleaned[b][0] + " ")
            }
        }
        return wordsCleanedNew;
    }

    return (
        <div>
            {getTheQuestion(props.questionMetadata)}
        </div>
    );
};
export default FillinTypeAutoQuestion;
