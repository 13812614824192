

export const teacher_tests_questions_data = (data) => {


  const questions = {
    name: "Test made by a teacher",
    questionsmap: []
  };
  //----
  let the_total_time = 0;
  let teacher_made_test_id = 0;

  if (data.length > 0 && data[0].length > 0) {
    for (var i = 0; i < data[0].length; i++) {
      if (data[0][i].question_type === "multiple_choice") {

        questions.questionsmap.push({
          questiondata: {
            panda_teacher_test_2020_03_id: data[0][i].question.panda_teacher_test_2020_03_id,
            uuid: data[0][i].question.uuid,
            panda_teacher_test_2020_03_regdate: data[0][i].question.panda_teacher_test_2020_03_regdate,
            answers:
              data[0][i].answers,
            panda_teacher_test_2020_03_question_data:
              data[0][i].question.panda_teacher_test_2020_03_question_data,
            panda_teacher_test_2020_03_question_duration: data[0][i].total_time,
            panda_teacher_test_2020_03_answering_guidelines: data[0][i].question.panda_teacher_test_2020_03_answering_guidelines,
            panda_teacher_test_2020_03_question_test_id: data[0][i].question.panda_teacher_test_2020_03_question_test_id,
            panda_teacher_test_2020_03_marks: data[0][i].total_marks,
          },
          questiontype: 'multiple_choice',
        });
        the_total_time = the_total_time + data[0][i].total_time;
        teacher_made_test_id = data[0][i].question.panda_teacher_test_2020_03_question_test_id;
      } else if (data[0][i].question_type === "drag_and_drop") {

        questions.questionsmap.push({
          questiondata: {
            panda_drag_and_drop_2020_03_id: data[0][i].question.panda_drag_and_drop_2020_03_id,
            uuid: data[0][i].question.uuid,
            panda_drag_and_drop_2020_03_access_code: data[0][i].question.panda_drag_and_drop_2020_03_access_code,
            panda_drag_and_drop_2020_03_creator: data[0][i].question.panda_drag_and_drop_2020_03_creator,
            panda_drag_and_drop_2020_03_regdate: data[0][i].question.panda_drag_and_drop_2020_03_regdate,
            panda_drag_and_drop_2020_03_question_data_answers:
              data[0][i].question.panda_drag_and_drop_2020_03_question_data_answers,
            panda_drag_and_drop_2020_03_question_data:
              data[0][i].question.panda_drag_and_drop_2020_03_question_data,
            panda_drag_and_drop_2020_03_duration_of_test: data[0][i].total_time,
            panda_drag_and_drop_2020_03_test_difficulty: data[0][i].question.panda_drag_and_drop_2020_03_test_difficulty,
            panda_drag_and_drop_2020_03_unit: data[0][i].question.panda_drag_and_drop_2020_03_unit,
            panda_drag_and_drop_2020_03_marks: data[0][i].total_marks,
            panda_drag_and_drop_2020_03_duration: data[0][i].total_time,
            panda_drag_and_drop_2020_03_test_id: data[0][i].question.panda_drag_and_drop_2020_03_test_id,
          },
          questiontype: 'drag_and_drop',
        });
        the_total_time = the_total_time + data[0][i].total_time;
        teacher_made_test_id = data[0][i].question.panda_drag_and_drop_2020_03_test_id;

      } else if (data[0][i].question_type === "complete_sentence") {
        questions.questionsmap.push({
          questiondata: {
            panda_fill_in_2020_03_id: data[0][i].question.panda_fill_in_2020_03_id,
            uuid: data[0][i].question.uuid,
            panda_fill_in_2020_03_access_code: data[0][i].question.panda_fill_in_2020_03_access_code,
            panda_fill_in_2020_03_creator: data[0][i].question.panda_fill_in_2020_03_creator,
            panda_fill_in_2020_03_regdate: data[0][i].question.panda_fill_in_2020_03_regdate,
            panda_fill_in_2020_03_question_data_answers:
              data[0][i].question.panda_fill_in_2020_03_question_data_answers,
            panda_fill_in_2020_03_question_data:
              data[0][i].question.panda_fill_in_2020_03_question_data,
            panda_fill_in_2020_03_duration_of_test: data[0][i].question.panda_fill_in_2020_03_duration_of_test,
            panda_fill_in_2020_03_test_difficulty: data[0][i].question.panda_fill_in_2020_03_test_difficulty,
            panda_fill_in_2020_03_unit: data[0][i].question.panda_fill_in_2020_03_unit,
            panda_fill_in_2020_03_marks: data[0][i].total_marks,
            panda_fill_in_2020_03_duration: data[0][i].total_time,
            panda_fill_in_2020_03_test_id: data[0][i].question.panda_fill_in_2020_03_test_id,
          },
          questiontype: 'complete_sentence',
        })
        the_total_time = the_total_time + data[0][i].total_time;
        teacher_made_test_id = data[0][i].question.panda_fill_in_2020_03_test_id;

      } else if (data[0][i].question_type === "open_ended") {
        questions.questionsmap.push({
          questiondata: {
            panda_open_ended_2020_03_id: data[0][i].question.panda_open_ended_2020_03_id,
            uuid: data[0][i].question.uuid,
            panda_open_ended_2020_03_marked: data[0][i].question.panda_open_ended_2020_03_marked,
            panda_open_ended_2020_03_regdate: data[0][i].question.panda_open_ended_2020_03_regdate,
            panda_open_ended_2020_03_test_id: data[0][i].question.panda_open_ended_2020_03_test_id,
            panda_open_ended_2020_03_teacher_answer:
              data[0][i].question.panda_open_ended_2020_03_teacher_answer,
            panda_open_ended_2020_03_time_in_minutes: data[0][i].total_time,
            panda_open_ended_2020_03_marks: data[0][i].total_marks,
            panda_open_ended_2020_03_question_data:
              data[0][i].question.panda_open_ended_2020_03_question_data,
          },
          questiontype: 'open_ended',
        });
        the_total_time = the_total_time + data[0][i].total_time;
        teacher_made_test_id = data[0][i].question.panda_open_ended_2020_03_test_id;
      }

    }

  }
  return [questions, parseInt(the_total_time + "") * 60, teacher_made_test_id];

};

export const teacher_tests_questions_data_answersheet = (data) => {


  const questions = {
    name: "Test made by a teacher",
    questionsmap: []
  };
  //----
  let the_total_time = 0;
  let teacher_made_test_id = 0;

  if (data.length > 0 && data[0].length > 0) {
    for (var i = 0; i < data[0].length; i++) {
      if (data[0][i].question_type === "multiple_choice") {

        questions.questionsmap.push({
          questiondata: {
            panda_teacher_test_2020_03_id: data[0][i].question.panda_teacher_test_2020_03_id,
            uuid: data[0][i].question.uuid,
            panda_teacher_test_2020_03_regdate: data[0][i].question.panda_teacher_test_2020_03_regdate,
            answers:
              data[0][i].answers,
            panda_teacher_test_2020_03_question_data:
              data[0][i].question.panda_teacher_test_2020_03_question_data,
            panda_teacher_test_2020_03_question_duration: data[0][i].total_time,
            panda_teacher_test_2020_03_answering_guidelines: data[0][i].question.panda_teacher_test_2020_03_answering_guidelines,
            panda_teacher_test_2020_03_question_test_id: data[0][i].question.panda_teacher_test_2020_03_question_test_id,
            panda_teacher_test_2020_03_marks: data[0][i].total_marks,
            answer_by_user: data[0][i].answer_by_user
          },
          questiontype: 'multiple_choice',
        });
        the_total_time = the_total_time + data[0][i].total_time;
        teacher_made_test_id = data[0][i].question.panda_teacher_test_2020_03_question_test_id;
      } else if (data[0][i].question_type === "drag_and_drop") {

        questions.questionsmap.push({
          questiondata: {
            panda_drag_and_drop_2020_03_id: data[0][i].question.panda_drag_and_drop_2020_03_id,
            uuid: data[0][i].question.uuid,
            panda_drag_and_drop_2020_03_access_code: data[0][i].question.panda_drag_and_drop_2020_03_access_code,
            panda_drag_and_drop_2020_03_creator: data[0][i].question.panda_drag_and_drop_2020_03_creator,
            panda_drag_and_drop_2020_03_regdate: data[0][i].question.panda_drag_and_drop_2020_03_regdate,
            panda_drag_and_drop_2020_03_question_data_answers:
              data[0][i].question.panda_drag_and_drop_2020_03_question_data_answers,
            panda_drag_and_drop_2020_03_question_data:
              data[0][i].question.panda_drag_and_drop_2020_03_question_data,
            panda_drag_and_drop_2020_03_duration_of_test: data[0][i].total_time,
            panda_drag_and_drop_2020_03_test_difficulty: data[0][i].question.panda_drag_and_drop_2020_03_test_difficulty,
            panda_drag_and_drop_2020_03_unit: data[0][i].question.panda_drag_and_drop_2020_03_unit,
            panda_drag_and_drop_2020_03_marks: data[0][i].total_marks,
            panda_drag_and_drop_2020_03_duration: data[0][i].total_time,
            panda_drag_and_drop_2020_03_test_id: data[0][i].question.panda_drag_and_drop_2020_03_test_id,
            answer_by_user: data[0][i].answer_by_user
          },
          questiontype: 'drag_and_drop',
        });
        the_total_time = the_total_time + data[0][i].total_time;
        teacher_made_test_id = data[0][i].question.panda_drag_and_drop_2020_03_test_id;

      } else if (data[0][i].question_type === "complete_sentence") {
        questions.questionsmap.push({
          questiondata: {
            panda_fill_in_2020_03_id: data[0][i].question.panda_fill_in_2020_03_id,
            uuid: data[0][i].question.uuid,
            panda_fill_in_2020_03_access_code: data[0][i].question.panda_fill_in_2020_03_access_code,
            panda_fill_in_2020_03_creator: data[0][i].question.panda_fill_in_2020_03_creator,
            panda_fill_in_2020_03_regdate: data[0][i].question.panda_fill_in_2020_03_regdate,
            panda_fill_in_2020_03_question_data_answers:
              data[0][i].question.panda_fill_in_2020_03_question_data_answers,
            panda_fill_in_2020_03_question_data:
              data[0][i].question.panda_fill_in_2020_03_question_data,
            panda_fill_in_2020_03_duration_of_test: data[0][i].question.panda_fill_in_2020_03_duration_of_test,
            panda_fill_in_2020_03_test_difficulty: data[0][i].question.panda_fill_in_2020_03_test_difficulty,
            panda_fill_in_2020_03_unit: data[0][i].question.panda_fill_in_2020_03_unit,
            panda_fill_in_2020_03_marks: data[0][i].total_marks,
            panda_fill_in_2020_03_duration: data[0][i].total_time,
            panda_fill_in_2020_03_test_id: data[0][i].question.panda_fill_in_2020_03_test_id,
            answer_by_user: data[0][i].answer_by_user
          },
          questiontype: 'complete_sentence',
        })
        the_total_time = the_total_time + data[0][i].total_time;
        teacher_made_test_id = data[0][i].question.panda_fill_in_2020_03_test_id;

      } else if (data[0][i].question_type === "open_ended") {
        questions.questionsmap.push({
          questiondata: {
            panda_open_ended_2020_03_id: data[0][i].question.panda_open_ended_2020_03_id,
            uuid: data[0][i].question.uuid,
            panda_open_ended_2020_03_marked: data[0][i].question.panda_open_ended_2020_03_marked,
            panda_open_ended_2020_03_regdate: data[0][i].question.panda_open_ended_2020_03_regdate,
            panda_open_ended_2020_03_test_id: data[0][i].question.panda_open_ended_2020_03_test_id,
            panda_open_ended_2020_03_teacher_answer:
              data[0][i].question.panda_open_ended_2020_03_teacher_answer,
            panda_open_ended_2020_03_time_in_minutes: data[0][i].total_time,
            panda_open_ended_2020_03_marks: data[0][i].total_marks,
            panda_open_ended_2020_03_question_data:
              data[0][i].question.panda_open_ended_2020_03_question_data,
            answer_by_user: data[0][i].answer_by_user
          },
          questiontype: 'open_ended',
        });
        the_total_time = the_total_time + data[0][i].total_time;
        teacher_made_test_id = data[0][i].question.panda_open_ended_2020_03_test_id;
      }

    }

  }
  return [questions, parseInt(the_total_time + "") * 60, teacher_made_test_id];

};


