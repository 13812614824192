import { updateObject } from "../../../shared/utility";
import * as actionsTypes from "../../actions/features/features";

const initialReducer = {
  features: [],
  featuresLoading: null,
  featuresError: null,
  feature: null,
  featurescarousels: [],
  featurescarouselsLoading: null,
  featurescarouselsError: null,
  featurescarousel: null,
};

const getFeaturesStart = (state, action) => {
  return updateObject(state, {
    features: [],
    featuresError: null,
    featuresLoading: true,
    feature: null,
  });
};

const getFeaturesSuccess = (state, action) => {
  return updateObject(state, {
    features: action.features,
    featuresError: null,
    featuresLoading: false,
    feature: null,
  });
};

const getFeaturesFail = (state, action) => {
  return updateObject(state, {
    features: [],
    featuresError: action.error,
    featuresLoading: false,
    feature: null,
  });
};

const getOneFeaturesSuccess = (state, action) => {
  return updateObject(state, {
    features: [],
    featuresError: null,
    featuresLoading: false,
    feature: action.feature,
  });
};

const getFeaturesCarouselsStart = (state, action) => {
  return updateObject(state, {
    features: [],
    featuresError: null,
    featuresLoading: true,
    feature: null,
  });
};

const getFeaturesCarouselsSuccess = (state, action) => {
  return updateObject(state, {
    features: action.features,
    featuresError: null,
    featuresLoading: false,
    feature: null,
  });
};

const getFeaturesCarouselsFail = (state, action) => {
  return updateObject(state, {
    features: [],
    featuresError: action.error,
    featuresLoading: false,
    feature: null,
  });
};

const getOneFeaturesCarouselsSuccess = (state, action) => {
  return updateObject(state, {
    features: [],
    featuresError: null,
    featuresLoading: false,
    feature: action.feature,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case actionsTypes.GET_FEATURES_START:
      return getFeaturesStart(state, action);
    case actionsTypes.GET_FEATURES_SUCCESS:
      return getFeaturesSuccess(state, action);
    case actionsTypes.GET_ONE_FEATURES_SUCCESS:
      return getOneFeaturesSuccess(state, action);
    case actionsTypes.GET_FEATURES_FAIL:
      return getFeaturesFail(state, action);
    case actionsTypes.GET_FEATURES_CAROUSEL_START:
      return getFeaturesCarouselsStart(state, action);
    case actionsTypes.GET_FEATURES_CAROUSEL_SUCCESS:
      return getFeaturesCarouselsSuccess(state, action);
    case actionsTypes.GET_ONE_FEATURES_CAROUSEL_SUCCESS:
      return getOneFeaturesCarouselsSuccess(state, action);
    case actionsTypes.GET_FEATURES_CAROUSEL_FAIL:
      return getFeaturesCarouselsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
