import { CardBody, CardTitle } from "../../text";
import { useCallback, useEffect, useMemo, useState } from "react";
import { statsOfLastWeek } from "../../../../functions/statsOfLastWeek";
import { useDispatch, useSelector } from "react-redux";
import { Cell, Pie, PieChart } from "recharts";
import { student_stats_of_last_week_start } from "../../../../store/actions/student.dashboard.homepage";

const Activity = (props) => {
  const dispatch = useDispatch();
  const userAth = useSelector((state) => state.auth);
  const memoizedUserAth = useMemo(() => userAth, [userAth]);
  const [loadedOnce, setLoadedOnce] = useState(true);
  const studentDashboardhomePage = useSelector(
    (state) => state.studentDashboardhomePage
  );
  const stats_of_last_week = useMemo(() => {
    // Calculation based on myStateValue...
    return statsOfLastWeek(
      studentDashboardhomePage.stats_of_last_week
        ? studentDashboardhomePage.stats_of_last_week
        : []
    );
  }, [studentDashboardhomePage.stats_of_last_week]);

  const fetchActivityData = useCallback(async () => {
    const data = {
      token: memoizedUserAth.token,
      username: memoizedUserAth.username,
      userId: memoizedUserAth.userId,
      day: new Date().getTime(),
      academic_level: memoizedUserAth.academic_level,
    };

    try {
      await dispatch(student_stats_of_last_week_start(data));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, memoizedUserAth]);

  useEffect(() => {
    if (loadedOnce) {
      fetchActivityData();
    }
  }, [loadedOnce, fetchActivityData]);

  return (
    <div>
      {stats_of_last_week.length > 0 &&
      !studentDashboardhomePage.subjectSpecializationPool_loading ? (
        <div className="bg-white px-6 py-3 filter drop-shadow-md rounded-2xl w-full">
          <div className="flex justify-between">
            <CardTitle name={props.title} color={props.titleColor} />
            <CardBody name={props.time} />
          </div>
          <div className="flex justify-between items-center flex-wrap">
            <div>
              {stats_of_last_week.length > 0 &&
              !studentDashboardhomePage.subjectSpecializationPool_loading ? (
                stats_of_last_week.map((activity, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <div
                      className={`h-5 w-5 rounded-full bg-${activity.color}`}
                    ></div>
                    <CardBody name={`${activity.value} ${activity.name}`} />
                  </div>
                ))
              ) : (
                <div></div>
              )}
            </div>
            <PieChart width={100} height={100}>
              <Pie
                data={
                  stats_of_last_week.length > 0 &&
                  !studentDashboardhomePage.subjectSpecializationPool_loading
                    ? stats_of_last_week
                    : []
                }
                cx={45}
                cy={45}
                innerRadius={30}
                outerRadius={50}
                fill="#8884d8"
                paddingAngle={2}
                dataKey="value"
              >
                {stats_of_last_week.length > 0 &&
                !studentDashboardhomePage.subjectSpecializationPool_loading ? (
                  stats_of_last_week.map((activity, index) => (
                    <Cell key={`cell-${index}`} fill={activity.hexColor} />
                  ))
                ) : (
                  <div></div>
                )}
              </Pie>
            </PieChart>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Activity;
