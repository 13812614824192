import Dashboard from "../components/pages/student/dashboard/dashboard";
import Layout from "../components/common/Layout";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import constants from "../constants/constants";

const StudentHome = () => {

  const history = useHistory();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    //---
    if (user !== null) {
      if (user?.paymentvalid !== true) {
           history.push("/");
      } else if (user?.paymentvalid === true) {
        if (user?.user_type === constants.studentusertype) {
          if (user?.isprofilecompleted === 1) {
          } else {
            history.push("/");
          }
        }
      }
    }else{
      history.push("/");
    }

  }, []);

  return (
    <Layout>
      <Helmet>
        <title>OPANDA - STUDENT</title>
      </Helmet>
      <Dashboard />
    </Layout>
  );
};

export default StudentHome;
