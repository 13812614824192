import Payment from '../../../components/pages/student/user/payment/create';
import Layout from '../../../components/common/Layout';

const StudentCreatePaymentContainer = () => {
  return (
    <Layout>
      <Payment />
    </Layout>
  );
};

export default StudentCreatePaymentContainer;