

export const subjectSpecialization = (mapRes: any, mapComponents: any) => {
  
  const filteredSRecentSubjectSpecialization = [];

  for (var i = 0; i < mapRes.length; i++) {
    if(mapRes[i].length>0){
      if(mapRes[i][0].subject==="Agriculture"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[0].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })

      }else if(mapRes[i][0].subject==="ART AND CRAFTS"){

        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[1].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="BIOLOGY"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[2].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="CHEMISTRY"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[3].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="COMPUTER SCIENCE"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[4].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="ECONOMICS"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[5].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="ELECTRICITY"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[6].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="ELECTRICITY FOT TVET"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[7].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="ELECTRONICS"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[8].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="ELECTRONICS FOR TVET"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[9].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="English"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[10].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="ENTREPRENEURSHIP"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[11].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="FINE ART AND CRAFTS"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[12].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="FRANÇAIS"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[13].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="GEOGRAPHY"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[14].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="Geography and Environment"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[15].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="HISTORY"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[16].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="Literature in English"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[17].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="MACHINE ASSEMBLY"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[18].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="MATHEMATICS"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[20].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="PHYSICS"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[21].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }else if(mapRes[i][0].subject==="RELIGION AND ETHICS"){
        filteredSRecentSubjectSpecialization.push({
          id: mapRes[i][mapRes[i].length-1].id,
          subject: mapRes[i][0].subject,
          imgsrc: mapComponents[21].imgsrc,
          tag: "Notes",
          unit:mapRes[i][mapRes[i].length-1].unit,
          created_at: mapRes[i][mapRes[i].length-1].live_visibility_regdate,
        })
      }

    }   
    
  }

  return filteredSRecentSubjectSpecialization;

};

export function removeItemFromArray<T>(arr: Array<T>, value: T): Array<T> { 
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}


