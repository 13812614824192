import PageContent from "../pageContent";
import Notification from "../../../elements/notification/notification";
import { TabPanel, useTabs } from "react-headless-tabs";
import { useState } from "react";
import TabSelector from "../../../elements/tab/TabSelector";
import { FeatureItemCard } from "../../../elements/card";
import { CardBody, CardTitle, FeatureTitle } from "../../../elements/text";
import Input from "../../../elements/input";
import { Cell, Pie, PieChart } from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { BsStar } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import ChallengesGroupItem from "./ChallengesGroupItem";
import { useEffect, useRef } from "react";
import {
  student_dashboard_attempted_challenges_start,
  student_dashboard_my_launched_challenges_start,
  student_dashboard_ongoing_challenges_start,
} from "../../../../store/actions/student.attempt.challenges";
import NotFound from "../../../common/features/NotFound";
import Spinner from "../../../elements/spinner";
import { bring_subject_image_src } from "../../../../functions/programs";
import { student_dashboard_challenges_stats_start } from "../../../../store/actions/student.dashboard.tests.subjects.units";

const Challenges = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth);
  const [selectedTab, setSelectedTab] = useTabs(
    ["Ongoing", "My challenges"],
    "Ongoing"
  );
  const studentAttemptAchallenges = useSelector(
    (state) => state.studentAttemptAchallenges
  );
  const studentDashboardTestsSubjectsUnits = useSelector(
    (state) => state.studentDashboardTestsSubjectsUnits
  );

  const [filterBy, setFilterBy] = useState("all");
  useEffect(() => {
    dispatch(
      student_dashboard_ongoing_challenges_start({
        token: userAuth.token,
        user_id: userAuth.userId,
        academic_level: userAuth.academic_level,
      })
    );
    //-------
    dispatch(
      student_dashboard_my_launched_challenges_start({
        token: userAuth.token,
        user_id: userAuth.userId,
        academic_level: userAuth.academic_level,
      })
    );
    //-----
    dispatch(
      student_dashboard_attempted_challenges_start({
        token: userAuth.token,
        user_id: userAuth.userId,
        academic_level: userAuth.academic_level,
      })
    );
    //challenges stats-
    dispatch(
      student_dashboard_challenges_stats_start({
        token: userAuth.token,
        user_id: userAuth.userId,
        academic_level: userAuth.academic_level,
      })
    );
  }, []);
  const activities = [
    {
      name: "Challenges",
      value: 3,
      color: "red",
      hexColor: "#BE1E2D",
    },
    {
      name: "Attempted",
      value: 1,
      color: "blue",
      hexColor: "#183F71",
    },
    {
      name: "Participants",
      value: 10,
      color: "yellow",
      hexColor: "#E8AD26",
    },
  ];
  const getActivities = (data) => {
    return [
      {
        name: "Challenges",
        value: data[0].launchedChallenges,
        color: "red",
        hexColor: "#BE1E2D",
      },
      {
        name: "Attempted",
        value: data[1].total_challenges_i_attempted,
        color: "blue",
        hexColor: "#183F71",
      },
      {
        name: "Participants",
        value: data[2].attempts_on_my_challenges,
        color: "yellow",
        hexColor: "#E8AD26",
      },
    ];
  };

  const topPerformers = ["Mugabo Cedric", "Mugabo Cedric", "Mugabo Cedric"];
  const getTopPerfomers = (data) => {
    if (data?.length > 0) {
      return data[3].sort((a, b) => b.total_marks - a.total_marks);
    } else {
      return [];
    }
  };
  //---Ongoing challenges
  const getOngoingChallenges = (challenges_pool) => {
    const challenges = [];
    if (challenges_pool.length > 0) {
      for (var i = 0; i < challenges_pool.length; i++) {
        challenges.push({
          subject: challenges_pool[i].subject,
          level: challenges_pool[i].level,
          unit:
            challenges_pool[i].units.split(",").length > 1
              ? challenges_pool[i].units.split(",")[0] + "..."
              : challenges_pool[i].units,
          //type: 'launched',
          unitname: challenges_pool[i].units,
          imgsrc: bring_subject_image_src(challenges_pool[i].subject).imgsrc,
          creator: challenges_pool[i].names,
          createdAt: challenges_pool[i].created_at,
          challengetype: challenges_pool[i].panda_challenge_test_type,
          challengeid: challenges_pool[i].ch_id,
        });
      }
      return challenges;
    } else {
      return [];
    }
  };
  //---Ongoing challenges
  const getMyChallenges = (challenges_pool) => {
    const challenges = [];
    if (challenges_pool.length > 0) {
      for (var i = 0; i < challenges_pool.length; i++) {
        challenges.push({
          subject: challenges_pool[i].subject,
          level: challenges_pool[i].level,
          unit:
            challenges_pool[i].units.split(",").length > 1
              ? challenges_pool[i].units.split(",")[0] + "..."
              : challenges_pool[i].units,
          type: "launched",
          imgsrc: bring_subject_image_src(challenges_pool[i].subject).imgsrc,
          creator: challenges_pool[i].names,
          unitname: challenges_pool[i].units,
          createdAt: challenges_pool[i].created_at,
          challengetype: challenges_pool[i].panda_challenge_test_type,
          challengeid: challenges_pool[i].ch_id,
        });
      }
      return challenges;
    } else {
      return [];
    }
  };
  //---Attempted challenges
  const getAttemptedChallenges = (challenges_pool) => {
    const challenges = [];
    if (challenges_pool.length > 0) {
      for (var i = 0; i < challenges_pool.length; i++) {
        challenges.push({
          subject: challenges_pool[i].subject,
          level: challenges_pool[i].level,
          unit:
            challenges_pool[i].units.split(",").length > 1
              ? challenges_pool[i].units.split(",")[0] + "..."
              : challenges_pool[i].units,
          unitname: challenges_pool[i].units,
          type: "attempted",
          imgsrc: bring_subject_image_src(challenges_pool[i].subject).imgsrc,
          creator: challenges_pool[i].names,
          createdAt: challenges_pool[i].created_at,
          challengetype: challenges_pool[i].panda_challenge_test_type,
          challengeid: challenges_pool[i].ch_id,
        });
      }
      return challenges;
    } else {
      return [];
    }
  };
  //---Collect subjects from launched & attempted
  const getSubjectsFromChallenges = (
    challenges_pool,
    challenges_pool_other
  ) => {
    const subjects = [];
    const subjects_check = [];
    if (challenges_pool.length > 0) {
      for (var i = 0; i < challenges_pool.length; i++) {
        if (subjects_check.includes(challenges_pool[i].subject)) {
          continue;
        } else {
          subjects.push({
            value: challenges_pool[i].subject,
            displayValue: challenges_pool[i].subject,
          });
          subjects_check.push(challenges_pool[i].subject);
        }
      }
      for (var i = 0; i < challenges_pool_other.length; i++) {
        if (subjects_check.includes(challenges_pool_other[i].subject)) {
          continue;
        } else {
          subjects.push({
            value: challenges_pool_other[i].subject,
            displayValue: challenges_pool_other[i].subject,
          });
          subjects_check.push(challenges_pool_other[i].subject);
        }
      }
      return subjects;
    } else {
      return [];
    }
  };
  const mostPassed = {
    subject: "Math",
    level: "1",
    unit: "Unit 1",
    type: "",
    imgsrc: "/images/preview/bio.svg",
    creator: "Mugabo Cedric",
    createdAt: "2022-05-03 10:00",
  };

  const mychallenges = [
    {
      subject: "Math",
      level: "1",
      unit: "Unit 1",
      type: "launched",
      imgsrc: "/images/preview/bio.svg",
      creator: "Mugabo Cedric",
      createdAt: "2022-05-03 10:00",
    },
    {
      subject: "Biology",
      level: "1",
      unit: "Unit 1",
      type: "attempted",
      imgsrc: "/images/preview/bio.svg",
      creator: "Mugabo Cedric",
      createdAt: "2022-05-03 10:00",
    },
    {
      subject: "Biology",
      level: "1",
      unit: "Unit 1",
      type: "launched",
      imgsrc: "/images/preview/bio.svg",
      creator: "Mugabo Cedric",
      createdAt: "2022-05-03 10:00",
    },
    {
      subject: "Physics",
      level: "1",
      unit: "Unit 1",
      type: "launched",
      imgsrc: "/images/preview/bio.svg",
      creator: "Mugabo Cedric",
      createdAt: "2022-05-03 10:00",
    },
    {
      subject: "Biology",
      level: "1",
      unit: "Unit 1",
      type: "attempted",
      imgsrc: "/images/preview/bio.svg",
      creator: "Mugabo Cedric",
      createdAt: "2022-05-03 10:00",
    },
    {
      subject: "Physics",
      level: "1",
      unit: "Unit 1",
      type: "attempted",
      imgsrc: "/images/preview/bio.svg",
      creator: "Mugabo Cedric",
      createdAt: "2022-05-03 10:00",
    },
  ];
  console.log(
    "dashboard_challenges_stats",
    studentDashboardTestsSubjectsUnits.dashboard_challenges_stats
  );

  return (
    <PageContent>
      <div
        className={`md:grid bg-gray-200 grid-cols-12 w-full min-h-screen-sec max-h-myscreen-sec md:max-h-screen-sec overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
      >
        <div className={`p-3 md:h-full col-span-8`}>
          <FeatureTitle name="Challenges" />
          <div className="md:flex justify-between items-center">
            <nav className="flex justify-between md:justify-start space-x-4">
              <TabSelector
                isActive={selectedTab === "Ongoing"}
                title="Ongoing"
                onClick={() => setSelectedTab("Ongoing")}
              />
              <TabSelector
                isActive={selectedTab === "My challenges"}
                title="My challenges"
                onClick={() => setSelectedTab("My challenges")}
              />
            </nav>
            {selectedTab !== "Ongoing" && (
              <div className="md:w-1/4">
                <Input
                  elementType="select"
                  elementConfig={{
                    startingValue: "SELECT",
                    options: [
                      { value: "all", displayValue: "All" },
                      ...getSubjectsFromChallenges(
                        studentAttemptAchallenges.dashboard_my_launched_challenges,
                        studentAttemptAchallenges.dashboard_attempted_challenges
                      ),
                      // { value: 'Math', displayValue: 'Math' },
                      // { value: 'Biology', displayValue: 'Biology' },
                      // { value: 'Physics', displayValue: 'Physics' },
                      // { value: 'Economics', displayValue: 'Economics' },
                      // { value: 'Geography', displayValue: 'Geography' },
                    ],
                  }}
                  value={filterBy}
                  changed={setFilterBy}
                  validation={{ required: true }}
                  shouldValidate
                  error="Invalid filter"
                />
              </div>
            )}
          </div>
          <TabPanel hidden={selectedTab !== "Ongoing"}>
            <div className="py-3 flex space-x-4">
              <div className="bg-white w-40% px-6 py-3 my-3 filter drop-shadow-md rounded-2xl">
                <div className="flex justify-between">
                  <CardTitle name="Your charts" />
                  <CardBody name="Last week" />
                </div>
                {!studentDashboardTestsSubjectsUnits.dashboard_challenges_stats_loading &&
                studentDashboardTestsSubjectsUnits.dashboard_challenges_stats
                  .length > 0 ? (
                  <div className="flex justify-between items-center">
                    <div>
                      {getActivities(
                        studentDashboardTestsSubjectsUnits.dashboard_challenges_stats
                      ).map((activity, index) => (
                        <div
                          key={index}
                          className="flex items-center space-x-2"
                        >
                          <div
                            className={`h-5 w-5 rounded-full bg-${activity.color}`}
                          ></div>
                          <CardBody
                            name={`${activity.value} ${activity.name}`}
                          />
                        </div>
                      ))}
                    </div>
                    <PieChart width={100} height={100}>
                      <Pie
                        data={getActivities(
                          studentDashboardTestsSubjectsUnits.dashboard_challenges_stats
                        )}
                        cx={45}
                        cy={45}
                        innerRadius={30}
                        outerRadius={50}
                        fill="#8884d8"
                        paddingAngle={2}
                        dataKey="value"
                      >
                        {getActivities(
                          studentDashboardTestsSubjectsUnits.dashboard_challenges_stats
                        ).map((activity, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={activity.hexColor}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                  </div>
                ) : studentDashboardTestsSubjectsUnits.dashboard_challenges_stats_loading ? (
                  <Spinner size="20" color="blue" />
                ) : (
                  <NotFound feature="No Attempted Challenges" />
                )}
              </div>

              <div className="bg-white w-40% px-6 py-3 my-3 filter drop-shadow-md rounded-2xl">
                <div className="flex justify-between">
                  <CardTitle name="Top performers" />
                  <CardBody name="Last week" />
                </div>
                <div className="">
                  {!studentDashboardTestsSubjectsUnits.dashboard_challenges_stats_loading &&
                  studentDashboardTestsSubjectsUnits.dashboard_challenges_stats
                    .length > 0 ? (
                    getTopPerfomers(
                      studentDashboardTestsSubjectsUnits.dashboard_challenges_stats
                    ).map((performer, index) => (
                      <div key={index} className="">
                        <CardBody
                          name={
                            index +
                            1 +
                            ") " +
                            performer.names +
                            ":" +
                            performer.total_marks.toFixed(1) +
                            "%"
                          }
                        />
                        <div className="flex space-x-2">
                          {performer.total_marks > 85 ? (
                            <>
                              <BsStar color="#E8AD26" />
                              <BsStar color="#E8AD26" />
                              <BsStar color="#E8AD26" />
                              <BsStar color="#E8AD26" />
                              <BsStar color="#E8AD26" />
                            </>
                          ) : performer.total_marks > 75 ? (
                            <>
                              <BsStar color="#E8AD26" />
                              <BsStar color="#E8AD26" />
                              <BsStar color="#E8AD26" />
                              <BsStar color="#E8AD26" />
                            </>
                          ) : performer.total_marks >= 50 ? (
                            <>
                              <BsStar color="#E8AD26" />
                              <BsStar color="#E8AD26" />
                              <BsStar color="#E8AD26" />
                            </>
                          ) : performer.total_marks >= 40 ? (
                            <>
                              <BsStar color="#E8AD26" />
                              <BsStar color="#E8AD26" />
                            </>
                          ) : performer.total_marks >= 20 ? (
                            <>
                              <BsStar color="#E8AD26" />
                            </>
                          ) : (
                            <BsStar color="#333333" />
                          )}
                        </div>
                      </div>
                    ))
                  ) : studentDashboardTestsSubjectsUnits.dashboard_challenges_stats_loading ? (
                    <Spinner size="20" color="blue" />
                  ) : (
                    <NotFound feature="No Attempted Challenges" />
                  )}
                </div>
              </div>
            </div>
            <CardTitle
              name="Open Challenges(You are free to attempt one.)"
              color="blue"
            />
            {!studentAttemptAchallenges.dashboard_ongoing_challenges_loading &&
            studentAttemptAchallenges.dashboard_ongoing_challenges.length >
              0 ? (
              <div className="grid grid-cols-3">
                {getOngoingChallenges(
                  studentAttemptAchallenges.dashboard_ongoing_challenges
                ).map((challenge_item) => {
                  return (
                    <FeatureItemCard
                      {...challenge_item}
                      feature="challenge"
                      clicked={() =>
                        history.push(
                          `/student/features/challenges/${challenge_item.challengeid}?attempt=true&challengetype=${challenge_item.challengetype}&unitname=${challenge_item.unitname}`
                        )
                      }
                    />
                  );
                })}
              </div>
            ) : studentAttemptAchallenges.dashboard_ongoing_challenges_loading ? (
              <Spinner size="20" color="blue" />
            ) : (
              <NotFound feature="Challenges" />
            )}
          </TabPanel>
          <TabPanel hidden={selectedTab !== "My challenges"}>
            {!studentAttemptAchallenges.dashboard_my_launched_challenges_loading &&
            studentAttemptAchallenges.dashboard_my_launched_challenges.length >
              0 ? (
              <ChallengesGroupItem
                type="Launched"
                challenges={getMyChallenges(
                  studentAttemptAchallenges.dashboard_my_launched_challenges
                )
                  .filter((challenge) => challenge.type === "launched")
                  .filter((challenge) =>
                    filterBy === "all" ? true : challenge.subject === filterBy
                  )}
                filterBy={filterBy}
              />
            ) : studentAttemptAchallenges.dashboard_my_launched_challenges_loading ? (
              <Spinner size="20" color="blue" />
            ) : (
              <NotFound feature="Challenges" />
            )}
            {!studentAttemptAchallenges.dashboard_attempted_challenges_loading &&
            studentAttemptAchallenges.dashboard_attempted_challenges.length >
              0 ? (
              <div className="mt-5">
                <ChallengesGroupItem
                  type="Attempted"
                  challenges={getAttemptedChallenges(
                    studentAttemptAchallenges.dashboard_attempted_challenges
                  )
                    .filter((challenge) => challenge.type === "attempted")
                    .filter((challenge) =>
                      filterBy === "all" ? true : challenge.subject === filterBy
                    )}
                  filterBy={filterBy}
                />
              </div>
            ) : studentAttemptAchallenges.dashboard_attempted_challenges_loading ? (
              <Spinner size="20" color="blue" />
            ) : (
              <NotFound feature="Attempted Challenges" />
            )}
          </TabPanel>
        </div>
        <div className={`p-3 col-span-4`}>
          <Notification auth />
        </div>
      </div>
    </PageContent>
  );
};

export default Challenges;
