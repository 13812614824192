import React, { useEffect, useState } from 'react';
import parse from "react-html-parser";
import axios from "../../../../axios-base";
import { useSelector } from 'react-redux';
import { Button } from '../../../elements/button';
import Spinner from '../../../elements/spinner';

const Tests_users_item = (props) => {
  const userAuth = useSelector((state) => state.auth);
  const [marksData, setMarksData] = useState([]);
  const [mark, setMark] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [isMarked, setIsMarked] = useState(false);


  const findOpenEndedCorrectionStatusOrProvideCorrectionUi = (question_id, test_id, user_id, test_signature) => {
    setIsSaving(true);

    axios
      .post(
        `/opanda/tests/checkOpenEndedCorrectionStatus`,
        {
          creator_id: userAuth.user.id,
          test_id: test_id,
          test_signature: test_signature,
          user_id: user_id,
          question_id: question_id

        },
        {
          headers: {
            authorization: `Bearer ${userAuth.token}`,
          },
        }
      )
      .then((response) => {
        //console.log(response.data)
        setMarksData({ ...response.data });
        setIsMarked(response.data.marked);
        setIsSaving(false);
        setMark(parseInt(response.data.marks));
      })
      .catch((err) => {
        console.log(err);
        setIsSaving(false);

      });

  }
  useEffect(() => {
    findOpenEndedCorrectionStatusOrProvideCorrectionUi(props.question_id, props.test_id, props.user_id, props.test_signature)
  }, [props.question_id, props.test_id, props.user_id, props.test_signature]);

  function validateNumber(value, marks) {
    // if value greater than marks, set it to marks, if below 0, set it to marks
    if (parseInt(value) > marks) {
      value = marks;
    } else if (parseInt(value) < 0) {
      value = 0;
    }
    setMark(value);
  }

  // setUserAnswersLoading
  const markAnswer = () => {
    setIsSaving(true);
    axios
      .post(
        `/opanda/tests/markTheTestAnswer`,
        {
          creator_id: userAuth.user.id,
          question_id: props.question_id, test_id: props.test_id, user_id: props.user_id, test_signature: props.test_signature, marks: mark
        },
        {
          headers: {
            authorization: `Bearer ${userAuth.token}`,
          },
        }
      )
      .then((response) => {
        alert("Answer marked successfully");
        findOpenEndedCorrectionStatusOrProvideCorrectionUi(props.question_id, props.test_id, props.user_id, props.test_signature)
      })
      .catch((err) => {
        setIsSaving(false);

      });
  }






  return (
    <>{(!isSaving) ?
      (isMarked) ? `Marks: ${parseInt(mark)}/${props.marks} `: <><label style={{ color: "red", fontStyle: "italic", fontSize: "9px" }}>{marksData.message} by you.</label>
        <input type='number' value={mark} onChange={(e) => {
          validateNumber(e.target.value, props.marks)
        }} max={props.marks} min="0" style={{ width: "90px", borderRadius: 6 }} />/{props.marks}
        <Button
          name="Mark"
          outline="true"
          color="blue"
          clicked={() => {
            markAnswer();
          }}
        />
      </>
      : <Spinner size="5" color="blue" />}
    </>
  )
}
export default Tests_users_item;
