import {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { replaceReact } from "replace-react";
import { FcAnswers } from "react-icons/fc";
import parse from "react-html-parser";
import "./test.css";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import * as constants from "./constants";
import * as qconstants from "./qconstants";
import { hidesidebar } from "../store/actions/sidebaractiontypes";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../store/actions/";
import { InputText, InputTextArea } from "./Algorithms";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

export const TeacherTestAlgorithms = forwardRef((props, ref) => {
  const [questiondata, setquestiondata] = useState("");
  const [explanation, setexplanation] = useState("");
  const [teacheranswer, setteacheranswer] = useState("");
  const [selectedwords, setselectedwords] = useState([]);
  const [options, setoptions] = useState([1]);
  const [testmapdata, settestmapdata] = useState([]);

  let answers = [];
  // const testmap = useSelector((state) => state.testmaps.testmap);
  const [testmap, settestmap] = useState(null);
  const [title, settitle] = useState("");
  const [subject, setsubject] = useState("");
  const [unit, setunit] = useState("");
  const [classlevel, setclasslevel] = useState("");
  const [numberofquestions, setnumberofquestions] = useState("");
  const [academiclevel, setacademiclevel] = useState("");
  const [instructions, setinstructions] = useState("");

  const [questionmarks, setquestionmarks] = useState(constants.TWO);
  const [questionduration, setquestionduration] = useState(constants.TWO);

  const [counter, setcounter] = useState(constants.ONE);
  // let counter = 0;
  let flowofquestions = "";
  let flowofquestionsanswerstrack = "";
  // let flowofquestionsArray = [];
  // let flowofquestionsanswerstrackArray = [];

  const [flowofquestionsArray, setflowofquestionsArray] = useState([]);
  const [
    flowofquestionsanswerstrackArray,
    setflowofquestionsanswerstrackArray,
  ] = useState([]);

  const dispatch = useDispatch();
  const testmapobject = {
    panda_test_map_2020_03_flow_of_questions_answers_other_answers:
      "ANSWERS_OTHR ASNWERS",
    panda_test_map_2020_03_flow_of_questions_answers_track: "TRACK",
    panda_test_map_2020_03_flow_of_questions: "flow of questions",
    panda_test_map_2020_03_access_code: "ACCESS CODE",
    panda_test_map_2020_03_creator: 11,
    panda_test_map_2020_03_instructions: "INSTUCTIONS",
    panda_test_map_2020_03_nber_of_questions: 3,
    panda_test_map_2020_03_title: title,
    panda_test_map_2020_03_class: "class",
    panda_test_map_2020_03_unit: unit,
    panda_test_map_2020_03_academic_level: "1",
    panda_test_map_2020_03_subject: subject,
    panda_test_map_2020_03_visibility: 0,
    panda_test_map_2020_03_regdate: "2021-01-20T11:14:18.000Z",
    panda_test_map_2020_03_test_id: 0,
    panda_test_map_2020_03_test_type: "quiz",
  };
  const onSubmit = () => {
    settestmap({
      panda_test_map_2020_03_flow_of_questions_answers_other_answers:
        "llllllll",
      panda_test_map_2020_03_access_code: "ACCESS CODE",
      panda_test_map_2020_03_creator: 11,
      panda_test_map_2020_03_instructions: instructions,
      panda_test_map_2020_03_nber_of_questions: numberofquestions,
      panda_test_map_2020_03_title: title,
      panda_test_map_2020_03_class: classlevel,
      panda_test_map_2020_03_unit: unit,
      panda_test_map_2020_03_academic_level: academiclevel,
      panda_test_map_2020_03_subject: subject,
      panda_test_map_2020_03_visibility: 0,
      panda_test_map_2020_03_test_id: 0,
    });
    //  dispatch(actions.createTestMaps(testmapobject));
  };
  // SELECT BUTTON IMPLEMENTATION
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const handleInputChange = (value) => {
    setValue(value);
  };
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  let selection;

  const onmouseupcapture = () => {
    selection = window.getSelection().toString();
    if (selection) {
      if (!selectedwords.includes(selection)) {
        setselectedwords([...selectedwords, selection]);
      }
    }
  };

  const addOption = () => {
    setoptions([...options, 1]);
  };

  const optionsRef = useRef([]);
  optionsRef.current = [];

  const addToRefs = (qRef) => {
    if (qRef && !optionsRef.current.includes(qRef)) {
      optionsRef.current.push(qRef);
    }
  };

  // ADDING A QUESTION IN TEST MAP
  const addquestion = (questiontype) => {
    let flow = counter + "~#AllCyumaBases~#" + questiontype + "~#$";
    let track = questiontype + "~~" + counter + "~~" + counter;
    flowofquestionsArray.push(flow);
    flowofquestionsanswerstrackArray.push(track);
    flowofquestions = flowofquestionsArray.join(
      "~#$" + numberofquestions + "#~"
    );
    flowofquestionsanswerstrack = flowofquestionsanswerstrackArray.join(
      "2020CyumaIsTheBest2020"
    );
    let question;
    if (questiontype == constants.drag_and_drop) {
      question = qconstants.getDragAndDropQuestionData(
        selectedwords,
        questionmarks,
        questionduration
      );
    } else if (questiontype == constants.complete_sentence) {
      question = qconstants.getCompleteSentenceQuestionData(
        selectedwords,
        questionmarks,
        questionduration
      );
    } else if (questiontype == constants.open_ended) {
      question = qconstants.getOpenEndedQuestionData(
        questionmarks,
        questionduration
      );
    } else if (questiontype == constants.multiple_choice) {
      for (var i = 0; i < optionsRef.current.length; i++) {
        const found = answers.some(
          (text) => text.id === optionsRef.current[i].text.id
        );
        if (found) {
          // removing duplicate on the last option bcs it was taking each added character
          answers.pop();
          answers.push(optionsRef.current[i].text);
        } else {
          answers.push(optionsRef.current[i].text);
        }
      }
      question = qconstants.getMultipleChoiceData(
        questiondata,
        explanation,
        answers,
        questionmarks,
        questionduration
      );
    }
    settestmap({
      ...testmap,
      panda_test_map_2020_03_flow_of_questions_answers_track:
        flowofquestionsanswerstrack,
      panda_test_map_2020_03_flow_of_questions: flowofquestions,
    });
    testmapdata.push(question);
    setquestiondata("");
    setteacheranswer("");
    setselectedwords([]);
    setcounter(counter + 1);
  };

  // ADDING A TEST TO BACKEND
  const submitTest = (testtype) => {
    if (window.confirm("Are you sure you want to submit this test?")) {
      const object = {
        testmap,
        testquestions: testmapdata,
        testtype,
      };
      dispatch(actions.createTestMaps(object));
    } else {
    }
  };

  // CODE THAT REMOVES ANSWER OPTION
  const removeAnswerOption = (val, i) => {
    if (window.confirm("Are you sure you want to remove this option?")) {
      options.splice(i, 1);
      setoptions(options.filter((option) => option !== val));
    } else {
    }
  };

  const removeWord = (val) => {
    if (window.confirm("Are you sure you want to remove this option?")) {
      console.log(`${val} `);
      console.log(selectedwords);
      setselectedwords(selectedwords.filter((option) => option !== val));
      console.log(selectedwords);
    } else {
    }
  };
  // const [addFromOpanda, setaddFromOpanda] = useState(selectedValue?.type);
  let addFromOpanda = selectedValue?.type;
  if (addFromOpanda === constants.multiple_choice) {
    if (
      window.confirm(
        "Click ok of you want to add qustion from OGenius Panda Test?"
      )
    ) {
      console.log("added");
      //   setaddFromOpanda("");
    } else {
      console.log("not added");
      //   setaddFromOpanda("");
    }
  }

  return (
    <div className="px-8 py-5 flex flex-col" onLoad={() => alert("Hello")}>
      <h5>Teacher Creates Test</h5>
      {testmap == null ? (
        <div className="w-4/5 flex flex-col space-y-3">
          <div className="space-x-10 flex">
            <div className="flex flex-col w-1/2">
              <InputText
                title="Title"
                value={title}
                name="title"
                onChange={(e) => settitle(e.target.value)}
              />
              <InputText
                title="Subject"
                value={subject}
                name="subject"
                onChange={(e) => setsubject(e.target.value)}
              />
              <InputText
                title="Unit"
                value={unit}
                name="unit"
                onChange={(e) => setunit(e.target.value)}
              />
            </div>
            <div className="flex flex-col w-1/2">
              <InputText
                title="Specify Class"
                value={classlevel}
                name="classlevel"
                onChange={(e) => setclasslevel(e.target.value)}
              />
              <InputText
                title="Academic Level"
                value={academiclevel}
                name="academiclevel"
                onChange={(e) => setacademiclevel(e.target.value)}
              />
              <InputText
                title="Number of Questions"
                value={numberofquestions}
                name="numberofquestions"
                onChange={(e) => setnumberofquestions(e.target.value)}
              />
            </div>
          </div>
          <div>
            Test Instructions
            <InputTextArea
              value={instructions}
              onChange={(event) => setinstructions(event)}
            />
          </div>
          <input
            type={"submit"}
            value="Submiti"
            onClick={onSubmit}
            className="w-24 text-center"
          />
        </div>
      ) : (
        <div className="pt-5 flex flex-col space-y-5">
          <h6>
            Add Questions in Test titled:{" "}
            {testmap?.panda_test_map_2020_03_title}
          </h6>
          <div className="flex w-2/5">
            <Select
              placeholder="Select Question Type"
              className={"mb-2 w-90%"}
              options={constants.questiontypes}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.type}
              value={selectedValue}
              onChange={handleChange}
              onInputChange={handleInputChange}
              isSearchable
            />
          </div>
          <div className={selectedValue == "" ? "hidden" : "flex"}>
            <div
              className={
                selectedValue?.type == constants.multiple_choice
                  ? "flex flex-col w-4/5"
                  : "hidden"
              }
            >
              Multiple Choice
              <div className="flex flex-col space-y-3">
                <div
                  className="w-4/5 flex flex-col space-y-2"
                  id="drag-and-drop-data"
                >
                  <div>
                    <div className="mb-3 mt-3 flex space-x-5">
                      <InputText
                        title="Marks"
                        value={questionmarks}
                        name="questionmarks"
                        onChange={(e) => setquestionmarks(e.target.value)}
                      />
                      <InputText
                        title="Duration"
                        value={questionduration}
                        name="questionduration"
                        onChange={(e) => setquestionduration(e.target.value)}
                      />
                    </div>
                    Question Data
                    <InputTextArea
                      value={questiondata}
                      onChange={(event) => {
                        setquestiondata(event);
                      }}
                    />
                  </div>
                  <div>
                    Explanation
                    <InputTextArea
                      value={explanation}
                      onChange={(event) => {
                        setexplanation(event);
                      }}
                    />
                  </div>
                </div>
                List Of Answers
                {options.map((option, i) => (
                  <AnswerOption
                    key={i}
                    onClick={addOption}
                    ref={addToRefs}
                    id={i}
                    option={option}
                    removeAnswerOption={() => {
                      removeAnswerOption(option, i);
                    }}
                  />
                ))}
              </div>
              <input
                className="w-32 mt-3 mb-3 px-1 rounded-sm"
                type={"submit"}
                value="add question"
                onClick={() => {
                  addquestion(constants.multiple_choice);
                }}
              />
            </div>
            <div
              className={
                selectedValue?.type == constants.drag_and_drop
                  ? "flex flex-col w-4/5"
                  : "hidden"
              }
            >
              DRag and drop
              <div className="flex space-x-3">
                <div className="w-3/5" id="drag-and-drop-data">
                  <div className="mb-3 mt-3 flex space-x-3">
                    <InputText
                      title="Marks"
                      value={questionmarks}
                      name="questionmarks"
                      onChange={(e) => setquestionmarks(e.target.value)}
                    />
                    <InputText
                      title="Duration"
                      value={questionduration}
                      name="questionduration"
                      onChange={(e) => setquestionduration(e.target.value)}
                    />
                  </div>
                  <InputTextArea
                    onMouseUpCapture={onmouseupcapture}
                    value={questiondata}
                    onChange={(event) => {
                      setquestiondata(event);
                    }}
                  />
                </div>
                <div className="w-2/5">
                  {" "}
                  Marked Answers
                  <div className="flex flex-col space-y-2">
                    {selectedwords.map((word, i) => (
                      <div className="space-x-2 justify-around " key={i}>
                        <span className="bg-gray-500 text-white text-center py-1 px-2 rounded-md">
                          {word}
                        </span>{" "}
                        <button
                          onClick={() => {
                            removeWord(word);
                          }}
                          className="text-white py-1  bg-red px-2 rounded-md"
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <input
                className="w-32 mt-3 mb-3 px-1 rounded-sm"
                type={"submit"}
                value="add question"
                onClick={() => {
                  addquestion(constants.drag_and_drop);
                }}
              />
            </div>
            <div
              className={
                selectedValue?.type == constants.complete_sentence
                  ? "flex flex-col w-4/5"
                  : "hidden"
              }
            >
              Ccomplete Sentence
              <div className="flex space-x-3">
                <div className="w-3/5" id="complete-sentence-data">
                  <div className="mb-3 mt-3 flex">
                    <InputText
                      title="Marks"
                      value={questionmarks}
                      name="questionmarks"
                      onChange={(e) => setquestionmarks(e.target.value)}
                    />
                    <InputText
                      title="Duration"
                      value={questionduration}
                      name="questionduration"
                      onChange={(e) => setquestionduration(e.target.value)}
                    />
                  </div>
                  <InputTextArea
                    onMouseUpCapture={onmouseupcapture}
                    value={questiondata}
                    onChange={(event) => {
                      setquestiondata(event);
                    }}
                  />
                </div>
                <div className="w-2/5">
                  {" "}
                  Marked Answers
                  <div className="flex flex-col space-y-2">
                    {selectedwords.map((word, i) => (
                      <div className="space-x-2 justify-around " key={i}>
                        <span className="bg-gray-500 text-white text-center py-1 px-2 rounded-md">
                          {word}
                        </span>{" "}
                        <span className="text-white py-1  bg-blue px-2 rounded-md">
                          +
                        </span>
                        <button
                          onClick={() => {
                            removeWord(word);
                          }}
                          className="text-white py-1  bg-red px-2 rounded-md"
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <input
                className="w-32 mt-3 mb-3 px-1 rounded-sm"
                type={"submit"}
                value="add question"
                onClick={() => {
                  addquestion(constants.complete_sentence);
                }}
              />
            </div>
            <div
              className={
                selectedValue?.type == constants.open_ended
                  ? "flex flex-col w-4/5"
                  : "hidden"
              }
            >
              OPen Ended
              <div className="flex flex-col space-y-5 pt-4">
                <div className="w-4/5" id="open-ended-data">
                  <div className="mb-3 mt-3 flex space-x-5">
                    <InputText
                      title="Marks"
                      value={questionmarks}
                      name="questionmarks"
                      onChange={(e) => setquestionmarks(e.target.value)}
                    />
                    <InputText
                      title="Duration"
                      value={questionduration}
                      name="questionduration"
                      onChange={(e) => setquestionduration(e.target.value)}
                    />
                  </div>
                  Question
                  <InputTextArea
                    value={questiondata}
                    onChange={(event) => {
                      setquestiondata(event);
                    }}
                  />
                </div>
                <div className="w-4/5" id="open-ended-teacher-answer">
                  Answer
                  <InputTextArea
                    value={teacheranswer}
                    onChange={(event) => {
                      setteacheranswer(event);
                    }}
                  />
                </div>
              </div>
              <input
                className="w-32 mt-3 mb-3 px-1 rounded-sm"
                type={"submit"}
                value="add question"
                onClick={() => {
                  addquestion(constants.open_ended);
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div
        className={
          numberofquestions != "" && counter > numberofquestions
            ? " flex space-x-10 mt-5"
            : "hidden"
        }
      >
        <input
          onClick={() => {
            submitTest("quiz");
          }}
          type={"submit"}
          value="Submit As Quiz"
          className="bg-facebook py-1 px-2 rounded-md text-center text-white"
        />
        <input
          onClick={() => {
            submitTest("test");
          }}
          type={"submit"}
          value="Submit As Test "
          className="bg-green py-1 px-2 rounded-md text-center text-white"
        />
      </div>
    </div>
  );
});

{
  /* export const FillinReserveCode = forwardRef((props, ref) => {
  let answers = [];
  let question = "";
  let oquestionId = "";
  let questId = "";
  if (props.questiondata !== undefined) {
    question = props.questiondata.question;
    oquestionId = props.questiondata.oquestionId;
    questId = props.questiondata.questId;
  }
  let questiondata;
  let questionparts = [];
  let questionword = `<p></p>`;
  let listofanswers = [];
  // Calling a function that gives fillin question parts
  questiondata = qconstants.getFillInQuestionParts(question);
  answers = questiondata.regexArray;
  question = questiondata.questiondata;
  listofanswers = questiondata.listofanswers;
  // breaking the question data into an array of strings
  if (question != undefined) {
    questionparts = question.split(" ");
  }
  let regWord = {};
  // making regex that will help replacing words that will be filled in
  if (answers != undefined) {
    regWord = new RegExp(answers.join("|"), "i");
  }
  // let tester = question.split(regWord, "#REPLACED#");
  // console.log(tester);
  // traversing the array to replace words
  const allquestions = questionparts.map((part) => {
    return part.match(regWord)
      ? `<input placeholder="......................" className="fill-in" class="answers"/>`
      : part;
  });
  // final result to be rendered on the screen: joining all the other parts of tha array after relacing words
  questionword = `<p id=${oquestionId}>${allquestions.join(" ")}</p>`;
  let instancesCount = 0;
  const [instance, setinstance] = useState(0);
  const [instant, setinstant] = useState(0);
  const fillincardsref = useRef([]);
  fillincardsref.current = [];
  const addToRefs = (qRef) => {
    if (qRef && !fillincardsref.current.includes(qRef)) {
      fillincardsref.current.push(qRef);
      // setinstance(instance + 1);
    }
  };
  let fillinresponse = [];
  useImperativeHandle(ref, () => ({
    submitingAnswer() {
      console.log(fillinresponse);
      fillincardsref.current.forEach((element) => {
        fillinresponse.push(element.result);
      });
      console.log(fillinresponse);
      let uniqueresponse = [...new Set(fillinresponse)];
      let fillinresponsedata = uniqueresponse.join("~~#$!~~");
      return {
        responsedata: fillinresponsedata,
        questiontype: constants.fillin,
      };
    },
  }));
  useEffect(() => {
    qconstants.getFillInQuestionSpanned();
  });
  const alerting = (e) => {
    // alert(theinput.current.value);
    // alert(e);
    console.log(e);
  };

  const theinput = useRef();
  // const [ttt, setttt] = useState(0);
  let ttt = -1;
  //const [ttt, setttt] = useState(0);
  const [count, setcount] = useState(0);
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex-col">
        <h5>Fill In Question</h5>
        {parse(questionword)}
        <h4>New Fillin Format</h4>
        <span className="flex">
          {questionparts.map((part, i) => {
            if (part.match(regWord)) {
              ttt = ttt + 1;
            }
            return part.match(regWord) ? (
              <FillInResponceCard
                listofanswers={listofanswers[ttt]}
                index={ttt}
                ref={addToRefs}
                questId={questId}
              />
            ) : (
              part + constants.oneEmptySpace
            );
          })}
        </span>
      </div>
    </DndProvider>
  );
}); */
}

export const AnswerOption = forwardRef((props, ref) => {
  const [text, settext] = useState("");
  const [isanswer, setisanswer] = useState(false);
  const setanswer = (val) => {
    setisanswer(val);
  };
  useImperativeHandle(ref, () => ({
    // passing options index as id to know if the option is selected
    text: {
      isanswer: qconstants.getSelectedOption(props.id),
      text,
      answer_data: text,
      id: props.id,
    },
  }));
  return (
    <div className="w-4/5 flex flex-col" id="drag-and-drop-data">
      <InputTextArea
        value={text}
        onChange={(event) => {
          settext(event);
        }}
      />
      <div className="flex space-x-2 justify-between">
        <div>
          <input
            id={props.id}
            type={"checkbox"}
            value={props.option}
            checked={isanswer}
            onChange={(e) => {
              setisanswer(e.target.value);
            }}
          />
          Mark As Correct answer
        </div>
        <input
          type={"button"}
          className="bg-red text-white px-2 text-center"
          value={"Remove answer option"}
          onClick={props.removeAnswerOption}
        />
      </div>
      <div>
        <input
          onClick={props.onClick}
          type={"button"}
          value="Add Answer Option"
          className="mt-3 mb-2 bg-green px-2 py-1 text-white text-center"
        />
      </div>
    </div>
  );
});

export const TeacherTestsList = (props) => {
  const testmaps = useSelector((state) => state.testmaps.testmaps);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getTestMaps());
  }, [dispatch]);
  return (
    <div className="flex flex-col px-10 py-5">
      <label>TEST LIST</label>
      <div>
        <table className="table-auto border-collapse border-2 border-gray-300">
          <thead>
            <th className="border border-blue-400 px-4 py-2">ID</th>
            <th className="border border-blue-400 px-4 py-2">Title</th>
          </thead>
          <tbody>
            {testmaps.map((test, i) => (
              <tr key={i} className="text-lg font-semibold">
                <td className="border px-4 py-2">
                  <Link to={"/view-test/" + test.panda_test_map_2020_03_id}>
                    <span className="bg-gray-500 text-center text-white px-1">
                      {test.panda_test_map_2020_03_id}
                    </span>
                  </Link>
                </td>
                <td className="border px-4 py-2">
                  {test.panda_test_map_2020_03_title}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const ViewTest = (props) => {
  const testmap = useSelector((state) => state.testmaps.testmap);
  const dispatch = useDispatch();
  const params = useParams();
  const questions = useEffect(() => {
    dispatch(actions.getOneTestMaps(params.testmapId));
  }, [dispatch]);
  return (
    <div className="flex flex-col px-10 py-5">
      <label>TEST TITLE: {testmap?.panda_test_map_2020_03_title}</label>
      {testmap?.questionsmap?.map((question, i) => (
        <ViewQuestionInTest
          questionnumber={i + constants.ONE}
          question={question}
        />
      ))}
      <div></div>
    </div>
  );
};

export const ViewQuestionInTest = (props) => {
  const question = props.question;
  return (
    <div className="flex flex-col px-10 py-5">
      <div className="flex justify-between bg-gray-200 px-10">
        <label className="">Question Number: {props.questionnumber}</label>
        <label className=""></label>
      </div>
      <div
        className={
          question.questiontype == constants.complete_sentence
            ? "block"
            : "hidden"
        }
      >
        <TeacherDisplayCompleteSentence questiondata={question.questiondata} />
      </div>
      <div
        className={
          question.questiontype == constants.drag_and_drop ? "block" : "hidden"
        }
      >
        <TeacherDisplayDragAndDrop questiondata={question.questiondata} />
      </div>
      <div
        className={
          question.questiontype == constants.open_ended ? "block" : "hidden"
        }
      >
        <TeacherDisplayOpenEnded questiondata={question.questiondata} />
      </div>
    </div>
  );
};

export const TeacherDisplayCompleteSentence = (props) => {
  const questiondata = props?.questiondata?.panda_fill_in_2020_03_question_data;
  const answers =
    props?.questiondata?.panda_fill_in_2020_03_question_data_answers;
  let answersArray = [];
  let regWord;
  if (answers != undefined) {
    answersArray = answers.split("~----~");
    regWord = new RegExp(answersArray.join("|"), "i");
  }

  let displayabledata;
  const data =
    questiondata != undefined
      ? parse(questiondata)
      : parse("<p></p>").toString();
  if (data.props != undefined) {
    const quest = data.props.children;
    displayabledata =
      "<p>" +
      quest.replace(
        regWord,
        `<span className="font-bold bg-gray-200 px-2 rounded-md mr-1 ml-1">${answersArray[0]}</span>`
      ) +
      "</p>";
  }

  return (
    <div className="flex px-5 py-3">
      <label>
        {displayabledata != undefined
          ? parse(displayabledata)
          : parse("<p></p>")}
      </label>
    </div>
  );
};

export const TeacherDisplayDragAndDrop = (props) => {
  const questiondata =
    props?.questiondata?.panda_drag_and_drop_2020_03_question_data;
  const answers =
    props?.questiondata?.panda_drag_and_drop_2020_03_question_data_answers;
  let answersArray = [];
  let regWord;
  if (answers != undefined) {
    answersArray = answers.split("~----~");
    regWord = new RegExp(answersArray.join("|"), "i");
  }

  let displayabledata;
  const data =
    questiondata != undefined
      ? parse(questiondata)
      : parse("<p></p>").toString();
  if (data.props != undefined) {
    const quest = data.props.children;
    displayabledata =
      "<p>" +
      quest.replace(
        regWord,
        `<span className="font-bold bg-gray-200 px-2 rounded-md mr-1 ml-1">${answersArray[0]}</span>`
      ) +
      "</p>";
  }

  return (
    <div className="flex px-5 py-3">
      <label>
        {displayabledata != undefined
          ? parse(displayabledata)
          : parse("<p></p>")}
      </label>
    </div>
  );
};

export const TeacherDisplayOpenEnded = (props) => {
  const answerdata =
    props?.questiondata?.panda_open_ended_2020_03_teacher_answer;
  const question = props?.questiondata?.panda_open_ended_2020_03_question_data;

  return (
    <div className="flex flex-col px-5 py-3 space-y-5">
      <div>
        Question
        {question != undefined ? parse(question) : parse("<p></p>")}
      </div>

      <div>
        Front End
        {question != undefined ? parse(question) : parse("<p></p>")}
      </div>
    </div>
  );
};

export const Testing = (props) => {
  return (
    <div className="flex px-5 py-3">
      <label>DONE</label>
    </div>
  );
};
