import Layout from '../../../components/common/Layout';
import Pastpapers from '../../../components/pages/teacher/pastpaper/Pastpapers';

const PastpapersContainer = () => {
  return (
    <Layout>
      <Pastpapers />
    </Layout>
  );
};

export default PastpapersContainer;
