import {
  CardBody,
  CardSubText,
  CardTitle,
  SmallText,
} from '../../../elements/text';
import { useHistory } from 'react-router-dom';
import { groupBy } from '../../../../shared/utility';
import { useEffect, useState } from 'react';
import { FaFileAlt, FaRegClock } from 'react-icons/fa';

const MyclassesGroupItems = (props) => {
  const history = useHistory();
  const [transformedClasses, setTransformedClasses] = useState({});
  const { classes, filterBy } = props;
  useEffect(() => {
    groupBy(classes, filterBy)
      .then((res) => setTransformedClasses(res))
      .catch((err) => console.log(err));
  }, [classes, filterBy]);

  const groups = Object.keys(transformedClasses);

  return (
    <>
      {groups.map((sort) => (
        <>
          <CardTitle name={sort} color="blue" />
          <div className="md:grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
            {transformedClasses[sort].map((test, index) => (
              <div
                className="m-2 p-3 h-40 flex flex-col filter drop-shadow-md rounded-2xl cursor-pointer bg-white"
                onClick={() => {
                  history.push(`/student/features/myclasses/${index + 1}`);
                }}
              >
                <div className="flex-1 flex flex-col justify-between pb-2 border-b-2 border-gray-500">
                  <CardTitle name={test.title} additional="font-bold" />
                  <CardBody name={`S${test.level} - Unit ${test.unit}`} />
                  <div className="flex justify-between items-center">
                    <div className="flex space-x-2 items-center">
                      <FaFileAlt />
                      <CardBody name="1 Assgniment" />
                    </div>
                    <div className="flex space-x-2 items-center">
                      <FaRegClock />
                      <CardBody name="9:00 - 10:00" />
                    </div>
                  </div>
                  <SmallText name={`Happening ${test.happening}`} color="red" />
                </div>
                <CardSubText
                  name={new Date(test.due).toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                  color="blue"
                  additional="font-semibold"
                />
              </div>
            ))}
          </div>
        </>
      ))}
    </>
  );
};

export default MyclassesGroupItems;
