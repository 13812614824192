import { SET_SHOW_MAIN_NOTIFICATIONS } from '../actions/notifications';
import { updateObject } from '../../shared/utility';

const initialReducer = {
  notifications: [
    //basic notification
    {
      name: 'Mucyo Eric',
      subjects: ['Chemistry', 'Math'],
      type: 'schedule',
      schedules: [
        {
          subjects: 'Chemistry',
          unit: 'Lab materials',
          createdAt: '07/02/2022',
          date: '10/02/2022',
          time: '8:00 - 9:00',
        },
        {
          subjects: 'Chemistry',
          unit: 'Lab materials',
          createdAt: '07/02/2022',
          date: '10/02/2022',
          time: '8:00 - 9:00',
        },
       
      ],
    },
    {
      title: 'GUEST',
      titleColor: 'yellow',
      body: 'Access your homework/quiz',
      bodyColor: 'black',
      action: 'Enter code',
      link: '/previewpanda/guest',
      requireAuth: false,
      guest: true,
    },
    {
      title: 'NEW!',
      titleColor: 'red',
      body: 'A new lab has been added',
      bodyColor: 'black',
      action: 'Go for it',
      link: '/previewpanda/guest',
      image: '/images/preview/bio.svg',
      type: 'new',
      requireAuth: false,
    },
    {
      title: 'Reminder',
      titleColor: 'red',
      body: 'You have an online class Scheduled tomorrow',
      bodyColor: 'black',
      action: 'Details',
      link: '/previewpanda/guest',
      type: 'reminder',
      image: '/images/preview/bio.svg',
      requireAuth: false,
    },
    {
      title: 'Your Achievements',
      titleColor: 'black',
      action: 'More',
      link: '/previewpanda/guest',
      type: 'achievement',
      list: [
        { name: 'You are now Gold in Reader', color: '#E8AD26' },
        { name: 'You completed a Maths course', color: '#BE1E2D' },
        { name: 'You finished three tests', color: '#BE1E2D' },
      ],
      requireAuth: true,
    },
    {
      title: 'Question of the day',
      titleColor: 'black',
      question: 'What is the name of the first computer?',
      answers: ['Abacus', 'Hewlett-Packard', 'Desktop'],
      action: 'Submit',
      link: '/previewpanda/guest',
      type: 'question',
      requireAuth: true,
    },
    {
      title: 'Your Activity',
      titleColor: 'black',
      time: 'last week',
      activities: [
        {
          name: 'Simulations',
          value: 3,
          color: 'red',
          hexColor: '#BE1E2D',
        },
        {
          name: 'Courses',
          value: 2,
          color: 'blue',
          hexColor: '#183F71',
        },
        {
          name: 'Test',
          value: 5,
          color: 'yellow',
          hexColor: '#E8AD26',
        },
      ],
      type: 'activity',
      requireAuth: true,
    },
    {
      title: 'Right now',
      titleColor: 'black',
      subTitle: 'Your friend',
      action: 'is viewing a lab:',
      actionName: 'Bio Reproduction Lab ',
      link: '/lab',
      type: 'now',
      requireAuth: true,
    },
  ],
  mainNotifications: [
    {
      title: 'Past Paper',
      body: 'Biology Nation exam 2017',
      creator: 'Mugabo Cedric',
      createdAt: '2022-03-24 12:00',
      action: 'Open',
      viewed: false,
      type: 'primary',
    },
    {
      title: 'Past Paper',
      body: 'Biology Nation exam 2017',
      creator: 'Mugabo Cedric',
      createdAt: '2022-03-23 12:00',
      action: 'Open',
      viewed: false,
      type: 'primary',
    },
    {
      title: 'Past Paper',
      body: 'Biology Nation exam 2017',
      creator: 'Mugabo Cedric',
      createdAt: '2022-03-24 12:00',
      action: 'Open',
      viewed: true,
      type: 'primary',
    },
    {
      title: 'Past Paper',
      body: 'Biology Nation exam 2017',
      creator: 'Mugabo Cedric',
      createdAt: '2022-03-24 12:00',
      action: 'Open',
      viewed: true,
      type: 'primary',
    },
    {
      title: 'My Question',
      body: 'Your question "What is biology?" was answered',
      creator: 'Mugabo Cedric',
      createdAt: '2022-03-20 12:00',
      action: 'View Answer',
      viewed: false,
      type: 'myactions',
    },
    {
      title: 'My answer',
      body: 'Your answer was upvoted',
      creator: 'Mugabo Cedric',
      createdAt: '2022-03-24 12:00',
      action: 'View',
      viewed: true,
      type: 'myactions',
    },
    {
      title: 'Asked question',
      body: 'The question of title "What is biology?" was asked on our platform',
      creator: 'Mugabo Cedric',
      createdAt: '2022-02-24 12:00',
      action: 'View question',
      viewed: false,
      type: 'others',
    },
    {
      title: 'Answered question',
      body: 'The question "What is biology" was answered.',
      creator: 'Mugabo Cedric',
      createdAt: '2022-01-24 12:00',
      action: 'View Answer',
      viewed: true,
      type: 'others',
    },
    {
      title: 'Asked question',
      body: 'What is biology?',
      creator: 'Mugabo Cedric',
      createdAt: '2022-03-24 12:00',
      action: 'View Question',
      viewed: false,
      type: 'others',
    },
  ],
  error: null,
  loading: false,
  language: null,
  showMainnotifications: false,
};

const setMainShowMainNotifications = (state, action) => {
  return updateObject(state, {
    showMainnotifications: action.status,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case SET_SHOW_MAIN_NOTIFICATIONS:
      return setMainShowMainNotifications(state, action);
    default:
      return state;
  }
};

export default reducer;
