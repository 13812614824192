import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import parse from 'react-html-parser';
import { CardBody } from '../../../../../../elements/text';
import MatchingTypeAutoQuestionItem from './MatchingTypeAutoQuestionItem';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend'
import { isMobile } from 'react-device-detect';
import { useDrag } from 'react-dnd';
import MatchingTypeAutoQuestionDropTarget from './MatchingTypeAutoQuestionDropTarget';
const MatchingTypeAutoQuestion = (props) => {
    const { answers } = props;
    const dispatch = useDispatch();
    const [text, setText] = useState('');
    const [words, setWords] = useState([]);
    const [reload, setReload] = useState(false);

    useEffect(() => {

    }, []);
    const hasNative =
        document && (document.elementsFromPoint || document.msElementsFromPoint)

    function getDropTargetElementsAtPoint(x, y, dropTargets) {
        return dropTargets.filter((t) => {
            const rect = t.getBoundingClientRect()
            return (
                x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
            )
        })
    }

    // use custom function only if elementsFromPoint is not supported
    const backendOptions = {
        getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint
    }
    const getColumns = (questionMetadata) => {
        const META1 = questionMetadata?.split("#O.G.S")[2];
        const meta = META1.split("#O.G.C");
        const metaClean = [];
        for (var i = 0; i < meta.length; i++) {
            meta[i] = meta[i]?.trim();
            if (meta[i] !== "") {
                metaClean.push(meta[i]);
            };
        }        
        return <table className='table-auto border-separate border-spacing-4 border border-slate-400'><tbody><tr>{metaClean.map((item, i) => {
            return <td key={i} className="bg-white p-1"><div>{item}</div>
                <MatchingTypeAutoQuestionDropTarget metaClean={metaClean}  reloadAfterDone={reloadAfterDone} matchingTypeAuto={props.matchingTypeAuto} questionId={props.questionId} currentQuestionNumber={props.currentQuestionNumber} callMatchingTypeAuto={props.callMatchingTypeAuto} name={item} item={item} i={i} /></td>
        })}</tr></tbody></table>
    }
    const getFilteredWordFromUI = (data, oneData) => {
        for (var i = 0; i < data.length; i++) {
            if (data[i].newEntry === oneData && parseInt(props.currentQuestionNumber) === parseInt(data[i].question_number)) {
                return false;
            }
        }
        return true;
    }
    const reloadAfterDone = (datain) => {
        setReload(datain);
    }

    const getWordOptions = (questionMetadata) => {
        const META1 = questionMetadata?.split("#O.G.S")[3];
        const META2 = META1.split("#O.G")[1];
        const META3 = META2.split("#.#.#");
        const words = [];
        for (var i = 0; i < META3.length; i++) {
            words.push(META3[i].split("#A.N.S")[0])
        }
        const wordsCleaned = [];
        for (var i = 0; i < words.length; i++) {
            const newWord = words[i].trim();
            if (newWord !== "") {
                wordsCleaned.push(newWord);
            } else {

            }
        }
        return wordsCleaned.map((item, i) => {

            if (getFilteredWordFromUI(props.matchingTypeAuto, item)) {
                if (isMobile) {
                    if (i % 4 === 0) {
                        return <span><MatchingTypeAutoQuestionItem  i={i} reloadAfterDone={reloadAfterDone} questionId={props.questionId} currentQuestionNumber={props.currentQuestionNumber} matchingTypeAuto={props.matchingTypeAuto} name={item} item={item} key={i} /><br /></span>
                    } else {
                        return <MatchingTypeAutoQuestionItem i={i} reloadAfterDone={reloadAfterDone} questionId={props.questionId} currentQuestionNumber={props.currentQuestionNumber} matchingTypeAuto={props.matchingTypeAuto} name={item} item={item} key={i} />
                    }
                } else {
                    return <MatchingTypeAutoQuestionItem  i={i} reloadAfterDone={reloadAfterDone} questionId={props.questionId} currentQuestionNumber={props.currentQuestionNumber} matchingTypeAuto={props.matchingTypeAuto} name={item} item={item} key={i} />
                }
            }
        });
    }

    if (isMobile) {
        return (
            <DndProvider backend={TouchBackend} options={backendOptions}>
                <div>
                    <CardBody name="Drag words below to their corresponding  columns basing on their respective categories." />
                    <div>
                        <div>{getWordOptions(props.questionMetadata)}</div>
                    </div>
                    {getColumns(props.questionMetadata)}
                </div>
            </DndProvider>
        );
    } else {
        return (
            <DndProvider backend={HTML5Backend}>
                <div>
                    <CardBody name="Drag words below to their corresponding  columns basing on their respective categories." />
                    <div>
                        <div>{getWordOptions(props.questionMetadata)}</div>
                    </div>
                    {getColumns(props.questionMetadata)}
                </div>
            </DndProvider>
        );
    }
};
export default MatchingTypeAutoQuestion;
