import { updateObject } from "../../utils/updateObject";

import * as actionsTypes from "../actions/actionTypes";

const initialReducer = {
  testmaps: [],
  testmapsError: null,
  testmapsLoading: false,
  testmap: null,
};

const getTestMapsStart = (state, action) => {
  return updateObject(state, {
    testmaps: [],
    testmapsError: null,
    testmapsLoading: true,
    testmap: null,
  });
};

const getTestMapsSuccess = (state, action) => {
  return updateObject(state, {
    testmaps: action.testmaps,
    testmapsError: null,
    testmapsLoading: false,
    testmap: null,
  });
};

const getTestMapsFail = (state, action) => {
  return updateObject(state, {
    testmaps: [],
    testmapsError: action.error,
    testmapsLoading: false,
    testmap: null,
  });
};

const getOneTestMapsSuccess = (state, action) => {
  return updateObject(state, {
    testmaps: [],
    testmapsError: null,
    testmapsLoading: false,
    testmap: action.testmap,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case actionsTypes.GET_PANDA_TEST_MAPS_START:
      return getTestMapsStart(state, action);
    case actionsTypes.GET_PANDA_TEST_MAPS_SUCCESS:
      return getTestMapsSuccess(state, action);
    case actionsTypes.GET_ONE_PANDA_TEST_MAPS_SUCCESS:
      return getOneTestMapsSuccess(state, action);
    case actionsTypes.GET_PANDA_TEST_MAPS_FAIL:
      return getTestMapsFail(state, action);
    default:
      return state;
  }
};
export default reducer;
