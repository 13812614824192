import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa'; // Import FontAwesome icon

const UserCard = ({ userData }) => {
  const [showTooltip, setShowTooltip] = useState(false);


  const iconStyle = {
    fontSize: '12px',
    cursor: 'pointer',
  };

  const tooltipStyle = {
    visibility: showTooltip ? 'visible' : 'hidden',
    zIndex: 100000000,
    background: '#333',
    color: '#fff',
    textAlign: 'left',
    borderRadius: '6px',
    padding: '10px',
    position: 'absolute',
    zIndex: 10, // Increased zIndex to ensure it's on top
    bottom: '70%',
    left: '22%',
    transform: 'translateX(-50%)',
    opacity: showTooltip ? 1 : 0,
    transition: 'opacity 0.3s',
    width: '280px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };

  const tooltipArrowStyle = {
    position: 'absolute',
    top: '100%',
    left: '50%',
    marginLeft: '-5px',
    borderWidth: '5px',
    borderStyle: 'solid',
    borderColor: '#333 transparent transparent transparent',
    zIndex: 10, // Match the zIndex of the tooltip
  };

  return (
    <div ><span style={{ fontWeight: "bold", color: "green" }}>OTP is used</span>
      <FaInfoCircle
        style={iconStyle}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      />
      <div style={tooltipStyle}>
        <div>Otp used By: {userData.names} | Role: {userData.user_type}</div>
        <div>Email: {userData.email}</div>
        <div style={tooltipArrowStyle}></div>
      </div>
    </div>
  );
};

export default UserCard;
