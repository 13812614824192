import StudentAcademicInformation from "./student/academicInformation";
import TeacherAcademicInformation from "./teacher/academicInformation";
import SchoolAcademicInformation from "./school/academicInformation";

const AcademicInformation = (props) => {
  let userType = props.user;
  let Tab = StudentAcademicInformation;
  switch (userType) {
    case "student":
      Tab = StudentAcademicInformation;
      break;
    case "teacher":
      Tab = TeacherAcademicInformation;
      break;
    case "school":
      Tab = SchoolAcademicInformation;
      break;
    default:
      Tab = SchoolAcademicInformation;
      break;
  }
  return (
    <div>
      <Tab />
    </div>
  );
};

export default AcademicInformation;
