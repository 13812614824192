import { useEffect } from "react";
import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GoBackButton } from "../../../elements/button";
import { SectionTitle, FeatureTitle } from "../../../elements/text";
import PageContent from "../pageContent";
import Datatable from "../../../elements/table/datatable";
import RegisteredActionlist from "./registeredactionlist";
import {
  school_registered_users_count_start,
  school_registered_users_start,
} from "../../../../store/actions/school.dashboard.user.registered";

const ExistingAccounts = () => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth);
  const schoolRegisteredUser = useSelector(
    (state) => state.schoolRegisteredUser
  );

  const [showinglist, setShowinglist] = useState();
  let toggle_key = 1;
  const toggleActionList = (k) => {
    if (toggle_key) {
      setShowinglist(k);
      toggle_key = 0;
    } else {
      setShowinglist(-1);
      toggle_key = 1;
    }
  };

  const tableheader1 = [
    {
      id: 1,
      name: "Names",
      selector: (row) => row.names,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
    },
    // {
    //   id: 3,
    //   name: "Telephone",
    //   selector: (row) => row.telephone,
    //   sortable: true,
    //   reorder: true,
    // },
    // {
    //   id: 4,
    //   name: "Class",
    //   selector: (row) => row.class,
    //   sortable: true,
    //   reorder: true,
    // },
    {
      id: 5,
      name: "User type",
      selector: (row) => row.usertype,
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Created at",
      selector: (row) => row.createdat,
      sortable: true,
      reorder: true,
    }
  ];
  let tablebody1 = [];

  useEffect(() => {
    // dispatch(
    //   school_registered_users_count_start({
    //     token: userAuth.token,
    //     school_id: userAuth.userId,
    //   })
    // );
    dispatch(
      school_registered_users_start({
        token: userAuth.token,
        school_id: userAuth.userId,
      })
    );
  }, [dispatch, userAuth.token, userAuth.userId]);

  schoolRegisteredUser?.school_registered_users?.map((value, key) => {
    tablebody1.push({
      names: value.names,
      email: value.email,
     // telephone: value.telephone,
      //class: value.class,
      usertype: value.usertype,
      createdat: value.created_at,
      status: value.status ? "Registered" : "Not registered",
      action: (
        <RegisteredActionlist
          k={key}
          showinglist={showinglist}
          onClick={() => toggleActionList(key)}
          user_id={value.id}
          names={value.names}
          row_id={value.id}
          status={value.status}
        />
      ),
    });
  });
  const memoizedData = useMemo(() => tablebody1, [tablebody1]);

  return (
    <PageContent>
      <div className=" h-full p-3 bg-white-blue space-y-2 overflow-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
        <FeatureTitle name="User management" color="blue" />
        <div className="flex flex-col md:flex-row justify-between">
          <GoBackButton
            link="/school/features/users"
            action="Back"
            location={`User: Manage users`}
          />
        </div>
        <SectionTitle
          name={`Users registered under "${userAuth.displayName}" and are not linked to the active package`}
          color="blue"
        />

        <div className="w-full min-h-full relative overflow-x-auto sm:rounded-lg">
          {tablebody1.length === 0 ? (
            <></>
          ) : (
            <>
              <Datatable columns={tableheader1} data={memoizedData} />
            </>
          )}
        </div>
      </div>
    </PageContent>
  );
};

export default ExistingAccounts;
