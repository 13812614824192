import StudentBasicInformation from "./student/basicInformation";
import TeacherBasicInformation from "./teacher/basicInformation";
import SchoolBasicInformation from "./school/basicInformation";
import ParentBasicInformation from "./parent/basicInformation";
const BasicInformation = (props) => {
  let userType = props.user;
  let Tab = StudentBasicInformation;
  switch (userType) {
    case "student":
      Tab = StudentBasicInformation;
      break;
    case "teacher":
      Tab = TeacherBasicInformation;
      break;
    case "school":
      Tab = SchoolBasicInformation;
      break;
    case "parent":
      Tab = ParentBasicInformation;
      break;
    default:
      Tab = StudentBasicInformation;
      break;
  }

  return (
    <div>
      <Tab />
    </div>
  );
};

export default BasicInformation;
