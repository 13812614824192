import { updateObject } from "../../shared/utility";
import {
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_FAILED,
  //-----------
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_LOAD_MORE_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_LOAD_MORE_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_LOAD_MORE_FAILED,
  //---Comments Updates--
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_ADD_COMMENT_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_ADD_COMMENT_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_ADD_COMMENT_FAILED,
  //---Create a new group
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_CREATE_A_GROUP_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_CREATE_A_GROUP_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_CREATE_A_GROUP_FAILED,
  //--Discover groups
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_DISCOVER_GROUPS_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_DISCOVER_GROUPS_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_DISCOVER_GROUPS_FAILED,
  //--Request to join a group
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_JOIN_A_GROUP_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_JOIN_A_GROUP_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_JOIN_A_GROUP_FAILED,
  //--See requests on my groups
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_SEE_REQUESTS_ON_MY_GROUP_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_SEE_REQUESTS_ON_MY_GROUP_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_SEE_REQUESTS_ON_MY_GROUP_FAILED,
  //--Accept or deny invitation to group
  //--See members of a group
  //--Remove a member from a group
  //--Delete a group
} from "../actions/student.dashboard.community";
//--
const initialReducer = {
  student_dashboard_homepage_get_n_community_posts: [],
  student_dashboard_homepage_get_n_community_posts_loading: true,
  message0: "",
  //--
  student_dashboard_homepage_get_n_community_posts_load_more: [],
  student_dashboard_homepage_get_n_community_posts_load_more_loading: false,
  message1: "",
  //---Comments Updates--
  student_dashboard_homepage_get_n_community_posts_add_comment: [],
  student_dashboard_homepage_get_n_community_posts_add_comment_loading: false,
  message2: "",
  //---Create a new group
  student_dashboard_homepage_get_n_community_create_a_group: [],
  student_dashboard_homepage_get_n_community_create_a_group_loading: false,
  message3: "",
  //--Discover groups 
  student_dashboard_homepage_get_n_community_discover_groups: [],
  student_dashboard_homepage_get_n_community_discover_groups_loading: false,
  message4: "",
  //--Request to join a group
  student_dashboard_homepage_get_n_community_join_a_group: [],
  student_dashboard_homepage_get_n_community_join_a_group_loading: false,
  message5: "",
  //--See requests on my groups
  student_dashboard_homepage_get_n_community_see_requests_on_my_group: [],
  student_dashboard_homepage_get_n_community_see_requests_on_my_group_loading: false,
  message6: "",
  //--Accept or deny invitation to group
  //--See members of a group
  //--Remove a member from a group
  //--Delete a group
};

function student_dashboard_homepage_get_n_community_posts_init(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_n_community_posts_loading: true,
  });
}
function student_dashboard_homepage_get_n_community_posts_success(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_n_community_posts: action.payload,
    student_dashboard_homepage_get_n_community_posts_loading: false,
    message0: "success"
  });
}
function student_dashboard_homepage_get_n_community_posts_failed(state, action) {
  return updateObject(state, {
    message0: action.payload,
    student_dashboard_homepage_get_n_community_posts_loading: false,
  });
}
//--

function student_dashboard_homepage_get_n_community_posts_load_more_init(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_n_community_posts_load_more_loading: true,
  });
}
function student_dashboard_homepage_get_n_community_posts_load_more_success(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_n_community_posts: action.payload,
    student_dashboard_homepage_get_n_community_posts_load_more_loading: false,
    student_dashboard_homepage_get_n_community_posts_loading: false,
    message0: "success"
  });
}
function student_dashboard_homepage_get_n_community_posts_load_more_failed(state, action) {
  return updateObject(state, {
    message1: action.payload,
    student_dashboard_homepage_get_n_community_posts_load_more_loading: false,
    student_dashboard_homepage_get_n_community_posts_loading: false,
  });
}
//--Comments updates
function student_dashboard_homepage_get_n_community_posts_add_comment_init(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_n_community_posts_add_comment_loading: true,
  });
}
function student_dashboard_homepage_get_n_community_posts_add_comment_success(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_n_community_posts_add_comment: action.payload,
    student_dashboard_homepage_get_n_community_posts_add_comment_loading: false,
    message1: "success"
  });
}
function student_dashboard_homepage_get_n_community_posts_add_comment_failed(state, action) {
  return updateObject(state, {
    message1: action.payload,
    student_dashboard_homepage_get_n_community_posts_add_comment_loading: false,
  });
}
//---Create a new group
function student_dashboard_homepage_get_n_community_create_a_group_init(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_n_community_create_a_group_loading: true,
  });
}
function student_dashboard_homepage_get_n_community_create_a_group_success(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_n_community_create_a_group: action.payload,
    student_dashboard_homepage_get_n_community_create_a_group_loading: false,
    message2: "success"
  });
}
function student_dashboard_homepage_get_n_community_create_a_group_failed(state, action) {
  return updateObject(state, {
    message2: action.payload,
    student_dashboard_homepage_get_n_community_create_a_group_loading: false,
  });
}
//--Discover groups 
function student_dashboard_homepage_get_n_community_discover_groups_init(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_n_community_discover_groups_loading: true,
  });
}
function student_dashboard_homepage_get_n_community_discover_groups_success(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_n_community_discover_groups: action.payload,
    student_dashboard_homepage_get_n_community_discover_groups_loading: false,
    message3: "success"
  });
}
function student_dashboard_homepage_get_n_community_discover_groups_failed(state, action) {
  return updateObject(state, {
    message3: action.payload,
    student_dashboard_homepage_get_n_community_discover_groups_loading: false,
  });
}
//--Request to join a group
function student_dashboard_homepage_get_n_community_join_a_group_init(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_n_community_join_a_group_loading: true,
  });
}
function student_dashboard_homepage_get_n_community_join_a_group_success(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_n_community_join_a_group: action.payload,
    student_dashboard_homepage_get_n_community_join_a_group_loading: false,
    message4: "success"
  });
}
function student_dashboard_homepage_get_n_community_join_a_group_failed(state, action) {
  return updateObject(state, {
    message4: action.payload,
    student_dashboard_homepage_get_n_community_join_a_group_loading: false,
  });
}
//--See requests on my groups
function student_dashboard_homepage_get_n_community_see_requests_on_my_group_init(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_n_community_see_requests_on_my_group_loading: true,
  });
}
function student_dashboard_homepage_get_n_community_see_requests_on_my_group_success(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_n_community_see_requests_on_my_group: action.payload,
    student_dashboard_homepage_get_n_community_see_requests_on_my_group_loading: false,
    message6: "success"
  });
}
function student_dashboard_homepage_get_n_community_see_requests_on_my_group_failed(state, action) {
  return updateObject(state, {
    message6: action.payload,
    student_dashboard_homepage_get_n_community_see_requests_on_my_group_loading: false,
  });
}
//--Accept or deny invitation to group 
//--See members of a group
//--Remove a member from a group
//--Delete a group
const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_INIT:
      return student_dashboard_homepage_get_n_community_posts_init(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_SUCCESS:
      return student_dashboard_homepage_get_n_community_posts_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_FAILED:
      return student_dashboard_homepage_get_n_community_posts_failed(state, action);
    //---
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_LOAD_MORE_INIT:
      return student_dashboard_homepage_get_n_community_posts_load_more_init(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_LOAD_MORE_SUCCESS:
      return student_dashboard_homepage_get_n_community_posts_load_more_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_LOAD_MORE_FAILED:
      return student_dashboard_homepage_get_n_community_posts_load_more_failed(state, action);
    //--Comments updates
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_ADD_COMMENT_INIT:
      return student_dashboard_homepage_get_n_community_posts_add_comment_init(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_ADD_COMMENT_SUCCESS:
      return student_dashboard_homepage_get_n_community_posts_add_comment_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_POSTS_ADD_COMMENT_FAILED:
      return student_dashboard_homepage_get_n_community_posts_add_comment_failed(state, action);
    //---Create a new group    
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_CREATE_A_GROUP_INIT:
      return student_dashboard_homepage_get_n_community_create_a_group_init(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_CREATE_A_GROUP_SUCCESS:
      return student_dashboard_homepage_get_n_community_create_a_group_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_CREATE_A_GROUP_FAILED:
      return student_dashboard_homepage_get_n_community_create_a_group_failed(state, action);
    //--Discover groups      
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_DISCOVER_GROUPS_INIT:
      return student_dashboard_homepage_get_n_community_discover_groups_init(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_DISCOVER_GROUPS_SUCCESS:
      return student_dashboard_homepage_get_n_community_discover_groups_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_DISCOVER_GROUPS_FAILED:
      return student_dashboard_homepage_get_n_community_discover_groups_failed(state, action);
    //--Request to join a group
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_JOIN_A_GROUP_INIT:
      return student_dashboard_homepage_get_n_community_join_a_group_init(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_JOIN_A_GROUP_SUCCESS:
      return student_dashboard_homepage_get_n_community_join_a_group_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_JOIN_A_GROUP_FAILED:
      return student_dashboard_homepage_get_n_community_join_a_group_failed(state, action);
    //--See requests on my groups
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_SEE_REQUESTS_ON_MY_GROUP_INIT:
      return student_dashboard_homepage_get_n_community_see_requests_on_my_group_init(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_SEE_REQUESTS_ON_MY_GROUP_SUCCESS:
      return student_dashboard_homepage_get_n_community_see_requests_on_my_group_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_N_COMMUNITY_SEE_REQUESTS_ON_MY_GROUP_FAILED:
      return student_dashboard_homepage_get_n_community_see_requests_on_my_group_failed(state, action);
    //--Accept or deny invitation to group
    //--See members of a group
    //--Remove a member from a group
    //--Delete a group
    default:
      return state;
  }
};
export default reducer;
