import axios from "../../../../axios-base";

export const GET_TEACHERS_PASTPAPERS_START =
  "GET_TEACHERS_PASTPAPERS_START";
export const GET_TEACHERS_PASTPAPERS_SUCCESS =
  "GET_TEACHERS_PASTPAPERS_SUCCESS";
export const GET_ONE_TEACHERS_PASTPAPERS_SUCCESS =
  "GET_ONE_TEACHERS_PASTPAPERS_SUCCESS";
export const GET_TEACHERS_PASTPAPERS_FAIL = "GET_TEACHERS_PASTPAPERS_FAIL";

export const SET_PAPER_QUESTIONS_INIT = "SET_PAPER_QUESTIONS_INIT";
export const ADD_QUESTION_TO_PAPER="ADD_QUESTION_TO_PAPER";
export const REMOVE_QUESTION_TO_PAPER="REMOVE_QUESTION_TO_PAPER";
export const SAVE_PAST_PAPER_AS_DRAFT="SAVE_PAST_PAPER_AS_DRAFT";
export const SET_QUESTIONS_FROM="SET_QUESTIONS_FROM";

export const getTeachersPaspapersStart = () => {
    return {
      type: GET_TEACHERS_PASTPAPERS_START,
    };
  };
  
  export const getTeachersPaspapersSuccess = (pastpapers) => {
    return {
      type: GET_TEACHERS_PASTPAPERS_SUCCESS,
      payload: pastpapers,
    };
  };
  
  export const getOneTeachersPaspapersSuccess = (pastpaper) => {
    return {
      type: GET_ONE_TEACHERS_PASTPAPERS_SUCCESS,
      payload: pastpaper,
    };
  };
  
  export const getTeachersPaspapersFail = (error) => {
    return {
      type: GET_TEACHERS_PASTPAPERS_FAIL,
      error: error,
    };
  };

  export const addQuestionToPastPaper = (question) => {
    return {
      type: ADD_QUESTION_TO_PAPER,
      payload: question,
    };
  };

  export const removeQuestionToPastPaper = (questions,index) => {
    var newQuestions = questions.filter(data=>data.index !== index);
    for(var i=0; i<newQuestions.length ; i++){
      newQuestions[i].index =i;
  }
    return {
      type: REMOVE_QUESTION_TO_PAPER,
      payload: newQuestions,
    };
  };

  export const setQuestionsInit = () => {
    return {
      type: SET_PAPER_QUESTIONS_INIT,
    };
  };

  export const setQuestionsFromDB = (questions) => {
    console.log(`FROM DB: `,questions);
    return {
      type: SET_QUESTIONS_FROM,
      payload:questions,
    };
  };

  export const savePastPaperAsDraft = (questions, token) => {
    return (dispatch) => {
      dispatch(getTeachersPaspapersStart());
      axios
        .post(`/opanda/past-papers/create`, questions, {headers:{
          authorization:`Bearer ${token}`
        }})
        .then((response) => {
          dispatch(getOneTeachersPaspapersSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getTeachersPaspapersFail(err.message));
        });
    };
  };

  export const getTeacherPastPapers = (data) => {
    return (dispatch) => {
      dispatch(getTeachersPaspapersStart());
      axios
        .post(`/opanda/past-papers`, {user_id: data.user_id}, 
        {headers:{
          authorization:`Bearer ${data.token}`
        }})
        .then((response) => {
          dispatch(getTeachersPaspapersSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getTeachersPaspapersFail(err.message));
        });
    };
  };

  