import { useState } from 'react';
import { MdOutlineCelebration } from 'react-icons/md';
import { AnchorButton, Button } from '../../../elements/button';
import { CardBody, SectionTitle } from '../../../elements/text';
import Line from '../../../elements/line';
import { ContentContainer } from '../../../common/content/container';
import { SmallText } from '../../../elements/text';

const CBC = () => {
  const questions = [
    {
      question: '1. What is a section of DNA that codes for a protein called?',
      answers: ['Gene', 'Chromosome', 'Allele', 'Plasmid'],
    },
    {
      question: '2. Which of these has not undergone selective breeding?',
      answers: [
        'Highland cows',
        'Dolly the sheep',
        'Broccoli',
        'German shepherd dogs	',
      ],
    },
  ];
  let testdata = [
    {
      id: 2,
      subject: 'Physics',
      imgsrc: '/images/preview/math.svg',
      tag: 'Test',
      unit: 'unit 2',
      created_at: '2 hours ago',
    },
  ];
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const handleNext = () => {
    setCurrentQuestionNumber((prevCurrentNumber) => {
      if (prevCurrentNumber >= questions.length - 1) {
        setShowResults(true);
        return 0;
      }
      return prevCurrentNumber + 1;
    });
  };
  const { question, answers } = questions[currentQuestionNumber] || {};
  const Tab = () => {
    if (showResults) {
      return (
        <>
          <div className="flex space-x-5 py-5">
            <div className="bg-yellow p-5 rounded shadow">
              <MdOutlineCelebration size="50" color="white" />
            </div>
            <div>
              <SectionTitle name="Fantastic" color="blue" />
              <CardBody name="you got 70% on this test" />
            </div>
          </div>
          <div className="flex items-center flex-wrap">
            <CardBody
              name="To access your marks and the answer sheet, first"
              color="blue"
            />
            &nbsp;
            <AnchorButton
              to="/enroll"
              name="Sign up"
              color="red"
              alignment="center"
            />
          </div>
          <div className="flex items-center flex-wrap">
            <SmallText name="Already a member?" color="blue" />
            &nbsp;
            <AnchorButton
              to="/login"
              name="Login here"
              color="red"
              alignment="center"
            />
          </div>
          <Line width="full" />
          <SectionTitle name="Other work" color="blue" />
          <CardBody name="Lorem ipsum dolor sit amet, consectetur adipiscing elit" />
          <ContentContainer
            guest
            name="Tests"
            data={testdata}
            slider={true}
            type="one"
          />
        </>
      );
    }
    return (
      <>
        <div>
          <CardBody name={question} />
          <ul>
            {answers?.map((answer, index) => (
              <li key={index} className="flex space-x-4 items-center">
                <input type="radio" name="answer" />
                <label>{answer}</label>
              </li>
            ))}
          </ul>
        </div>
        <div className="md:absolute bottom-10 right-10 py-3">
          <div className="py-1 bg-yellow rounded-full mb-5 text-center">
            <SectionTitle name="15:00" color="white" />
          </div>
          <div className="flex space-x-2 justify-between">
            <Button
              name="Next"
              outline="true"
              color="red"
              clicked={handleNext}
            />
            <Button
              name="Submit"
              outline="true"
              color="blue"
              clicked={() => setShowResults(true)}
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="h-tab-screen-recovery relative p-5">
      <SectionTitle
        name="Biology - Unit 3: introduction to genetics"
        color="blue"
      />
      <CardBody name="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus aliquet lectus sit amet ex finibus, at eleifend enim lobortis." />
      <Tab />
    </div>
  );
};

export default CBC;
