// eventBus.js
import EventEmitter from 'eventemitter3';

function createEventBus() {
  const eventBus = new EventEmitter();

  return {
    emit: eventBus.emit.bind(eventBus),
    on: eventBus.on.bind(eventBus),
    off: eventBus.off.bind(eventBus),
    once: eventBus.once.bind(eventBus),
    removeAllListeners: eventBus.removeAllListeners.bind(eventBus),
  };
}

const eventBus = createEventBus();

export default eventBus;
