import { CardBody } from '../../../elements/text';

const TabSelector = (props) => {
  return (
    <CardBody
      name={props.title}
      color="blue"
      additional={
        props.isActive
          ? 'bg-gray-200 w-full my-3 px-5 cursor-pointer'
          : 'w-full my-3 px-5 cursor-pointer'
      }
      onClick={props.onClick}
    />
  );
};

export default TabSelector;
