import Layout from '../../../components/common/Layout';
import Challenge from '../../../components/pages/student/challenge/ChallengeComponentReuse';

const ChallengeContainer = () => {
  return (
    <Layout>
      <Challenge />
    </Layout>
  );
};

export default ChallengeContainer;
