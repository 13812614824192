import { useTranslation } from 'react-i18next';
import Footer from '../components/common/footer';
import Header from '../components/common/header/header';
import Hero from '../components/common/hero';
import ScrollToTop from '../components/common/ScrollToTop';
import PageContent from '../components/pages/landing/whoisitfor/pagecontent';
import { NotificationBarCompetition } from '../components/pages/guest/previewguest/notificationbar';
export const Whoisitfor = (props) => {
  const { t } = useTranslation();
  return (
    <div className="font-poppins  scrollbar-hidden  h-screen">
      <NotificationBarCompetition />
      <Header />
      <Hero
        page="whoisitfor"
        title={t('Who can use O’Genius Panda?')}
        body={t('O’Genius Panda provides quality education for all. Our courses help students learn key skills for the job market and life in the real world by use of technology.')}
      />
      <PageContent />
      <Footer />
      <ScrollToTop />
    </div>
  );
};
