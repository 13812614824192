import { BsFillCheckCircleFill } from 'react-icons/bs';
import { FaRegCircle } from 'react-icons/fa';
import { CardBody, CardTitle } from '../../../elements/text';

const SideBarItem = (props) => {
  return (
    <div
      className={`w-full flex items-center p-2 cursor-pointer rounded space-x-3 bg-${
        props.selected
          ? 'tiffany-blue'
          : ''
      }`} 
      onClick={props.clicked}
    >
      {props.complete ? (
        <BsFillCheckCircleFill color="#E8AD26" size={30} />
      ) : (
        props.selected ? <span style={{backgroundColor: 'white', borderRadius: '50%'}}>
          <FaRegCircle size={30} color={props.selected ? "white" : ""} />
          </span> 
          : (
            <span style={{borderRadius: '50%'}}><FaRegCircle size={30} color={props.selected ? "white" : ""} /></span>
      ))}
      <div className="flex flex-col overflow-hidden">
        <CardTitle name={props.title} color={props.selected ? "white" : "blue"} />
        <CardBody name={props.subTitle} color={props.selected ? "white" : "blue"} />
      </div>
    </div>
  );
};

export default SideBarItem;
