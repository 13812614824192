import { useDispatch, useSelector } from 'react-redux';
import { NavList } from '../../elements/sidebar/auth/Navlist';
import { NavSubItem } from '../../elements/sidebar/auth/NavSubItem';
import { TabPanel, useTabs } from 'react-headless-tabs';
import { BiBook } from 'react-icons/bi';
import { FaDesktop, FaRegUser } from 'react-icons/fa';
import { MdOutlineForum, MdOutlineSpaceDashboard } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import {
  changeStudentDashboardMobileVisibility,
  changeStudentDashboardtab,
  changeStudentDashboardMenu,
  changeSideBarCollapseState,
} from '../../../store/actions/sidebaractiontypes';
import Spinner from '../../elements/spinner';
import { getPrograms } from '../../../functions/programs';
import { student_programs_start, student_student_programs_cbc_start } from '../../../store/actions/student.dashboard.programs';
import eventBus from '../../../utils/eventBus';
import { TRIGGER_OPEN_FEATURES_TAB } from '../teacher/sidebar';

export const tabs = [
  {
    name: 'Dashboard',
    tab: 'dashboard',
    icon: <MdOutlineSpaceDashboard color="#E8AD26" size="20" />,
    mainPanel: 'dashboard',
    subTabs: [],
    mainPath: '/student/',
  },
  {
    name: 'Programs',
    tab: 'programs',
    icon: <BiBook color="#E8AD26" size="20" />,
    mainPanel: 'cbc',
    mainPath: '/student/curriculum/cbc',
    subTabs: [
      //{ name: 'CBC', tabName: 'cbc', path: '/student/curriculum/cbc' },
      // {
      //   name: 'Cambridge',
      //   tabName: '/student/curriculum/cambridge',
      //   path: '/student/curriculum/cambridge',
      // },
    ],
  },
  {
    name: 'Features',
    tab: 'features',
    icon: <FaDesktop color="#E8AD26" size="20" />,
    mainPanel: 'myclasses',
    mainPath: '/student/features/myclasses',
    subTabs: [
      // {
      //   name: 'My Classes',
      //   tabName: 'myclasses',
      //   path: '/student/features/myclasses',
      // },
      {
        name: 'Summary Notes',
        tabName: 'summarynotes',
        path: '/student/features/summarynotes',
      },
      {
        name: 'Simulations',
        tabName: 'simulations',
        path: '/student/features/simulations',
      },
      { name: 'Tests', tabName: 'tests', path: '/student/features/tests' },
      {
        name: 'Past Papers',
        tabName: 'pastpapers',
        path: '/student/features/pastpapers',
      },
      {
        name: 'Library',
        tabName: 'library',
        path: '/student/features/library',
      },
      // {
      //   name: 'More Practice Questions',
      //   tabName: 'morepracticequestions',
      //   path: '/student/features/practice',
      // },
      {
        name: 'Challenges',
        tabName: 'challenges',
        path: '/student/features/challenges',
      },
    ],
  },
  {
    name: 'Community',
    tab: 'community',
    icon: <MdOutlineForum color="#E8AD26" size="20" />,
    mainPanel: 'community',
    mainPath: '/student/community',
    subTabs: [],
  },
  // {
  //   name: 'Extra Curricular',
  //   tab: 'extracurricular',
  //   icon: <MdOutlineForum color="#E8AD26" size="24" />,
  //   mainPanel: 'digitalliteracy',
  //   mainPath: '/student/extracurricular/digitalliteracy',
  //   subTabs: [
  //     {
  //       name: 'Digital Literacy',
  //       tabName: 'digitalliteracy',
  //       path: '/student/extracurricular/digitalliteracy',
  //     },
  //     {
  //       name: 'Savoir Plus',
  //       tabName: 'savoirplus',
  //       path: '/student/extracurricular/savoirplus',
  //     },
  //     {
  //       name: 'Competition',
  //       tabName: 'competition',
  //       path: '/student/extracurricular/competition',
  //     },
  //     { name: 'Dap', tabName: 'dap', path: '/student/extracurricular/dap' },
  //   ],
  // },
  {
    name: 'User',
    tab: 'user',
    icon: <FaRegUser color="#E8AD26" size="20" />,
    mainPanel: 'profile',
    mainPath: '/student/user/profile',
    subTabs: [
      { name: 'Profile', tabName: 'profile', path: '/student/user/profile' },
      { name: 'Settings', tabName: 'settings', path: '/student/user/settings' },
      {
        name: 'Link a parent',
        tabName: 'linkaparent',
        path: '/student/user/linkaccount',
      },
      // { name: 'Feedback', tabName: 'feedback', path: '/student/user/feedback' },
      {
        name: 'Help Center',
        tabName: 'helpcenter',
        path: '/student/user/helpcenter',
      },
      {
        name: 'Payment',
        tabName: 'payment',
        path: '/student/user/payment',
      },
    ],
  },
];

const SideBar = function (props) {
  const studentdashboardMenuselected = useSelector(
    (state) => state.reducer.studentdashboardMenuselected
  );
  const [selectedTab, setSelectedTab] = useTabs(
    ['dashboard', 'programs', 'features', 'forum', 'extracurricular', 'user'],
    studentdashboardMenuselected
  );
  const userAth = useSelector((state) => state.auth);
  const studentDashboardPrograms = useSelector(
    (state) => state.studentDashboardPrograms
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const changetab = (tab) => {
    dispatch(changeStudentDashboardtab(tab));
  };
  const isSidebarCollapsed = useSelector(
    (state) => state.reducer.sideBarCollapse
  );

  useEffect(() => {
    dispatch(
      student_programs_start({
        token: userAth.token,
        username: userAth.username,
        userId: userAth.userId,
        day: new Date().getTime(),
      })
    );
    eventBus.on(TRIGGER_OPEN_FEATURES_TAB, () => {
      dispatch(changeStudentDashboardMenu("features"));
      setSelectedTab("features");
      changetab('summarynotes');
    });

    return () => {
      // Unsubscribe from the event when the component unmounts
      eventBus.off(TRIGGER_OPEN_FEATURES_TAB, () => { });
    };
  }, []);
  //---Update the data of programs from the backend---
  const usertype = useSelector((state) => state.auth.user.user_type);
  tabs[1].subTabs = [...getPrograms(studentDashboardPrograms.programs, usertype)];
  return (
    <div className="w-full relative bg-yellow shadow h-full flex-col justify-between flex overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
      <div className={`mt-10 ${isSidebarCollapsed ? '' : 'px-1 md:px-2 lg:px-8'} h-full`}>
        {(!studentDashboardPrograms.programs_loading) ? tabs.map((item, index) => (
          <NavList
            key={index}
            name={item.name}
            isActive={selectedTab === item.tab}
            icon={item.icon}
            subTabs={item.subTabs}
            onClick={() => {
              dispatch(changeStudentDashboardMenu(item.tab));
              dispatch(changeSideBarCollapseState(false));
              setSelectedTab(item.tab);
              changetab(item.mainPanel);
              if (item.subTabs.length === 0) {
                dispatch(changeStudentDashboardMobileVisibility('hidden'));
                history.push(item.mainPath);
              }
            }}
          >
            <TabPanel
              hidden={selectedTab !== item.tab}
              className="transform transition duration-500 ease-in-out"
            >
              {item.subTabs.map((tab, index) => (
                <NavSubItem
                  key={index}
                  name={tab.name}
                  tab={tab.tabName}
                  onClick={() => {
                    changetab(tab.tabName);
                    dispatch(changeStudentDashboardMobileVisibility('hidden'));
                    if (tab.tabName === "cbc") {
                      dispatch(
                        student_student_programs_cbc_start({
                          token: userAth.token,
                          username: userAth.username,
                          userId: userAth.userId,
                          day: new Date().getTime(),
                          academic_level: userAth.academic_level
                        })
                      )
                    }
                    history.push(tab.path);
                  }}
                />
              ))}
            </TabPanel>
          </NavList>
        )) : <Spinner size="20" color="blue" />}
      </div>
    </div>
  );
};
export default SideBar;
