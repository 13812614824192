import { FeatureTitle, SectionTitle } from "../../../../elements/text";
import PackageInformation from "./packageinformation";
import RenewPackage from "./renewpackage";

import { BiDotsVertical } from "react-icons/bi";
import { useState,useEffect } from "react";
import SideDrawer from "../../../../elements/sideDrawer";
import SideBar from "./sidebar";
import { useSelector,  useDispatch } from "react-redux";
import { paymentStart } from "../../../../../store/actions";
const PageContent = () => {
  const dispatch = useDispatch();
  const paymentItems = useSelector((state) => state.paymentmenu.paymentItems);
  const selectedSettingsItem = paymentItems.filter(
    (item) => item.selected === true
  );
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  let Tab = PackageInformation;
  switch (selectedSettingsItem[0].title) {
    case "Package information":
      Tab = PackageInformation;
      break;
    case "Renew package":
      Tab = RenewPackage;
      break;

    default:
      Tab = PackageInformation;
      break;
  }

  // useEffect(() => {

  //     dispatch(paymentStart());

  // }, [paymentItems, history, dispatch]);

  return (
    <div className="bg-white-blue md:flex w-full">
      <div className="block md:hidden">
        <SideDrawer
          show={showSideDrawer}
          closed={() => setShowSideDrawer(false)}
        >
          <SideBar items={paymentItems} closed={() => setShowSideDrawer(false)} name="Payment"  />
        </SideDrawer>
      </div>
      <div className="w-25% bg-gray-100 p-3 hidden md:block">
        <FeatureTitle name="Payment" alignment="right" />
        <SideBar name="Payment" items={paymentItems} closed={() => setShowSideDrawer(false)} />
      </div>
      <div className="md:w-75% bg-white-blue">
        <div className="flex justify-between items-center">
          <BiDotsVertical
            className="cursor-pointer block md:hidden"
            color="#183F71"
            onClick={() => setShowSideDrawer(true)}
          />
        </div>
        <Tab />
      </div>
    </div>
  );
};

export default PageContent;
