import Layout from "./layout";
import SummaryWrapper from "./summarywrapper";
import UsersTable from "./userstable";
const Dashboard = () => {
  return (
    <Layout
      userstable={<UsersTable />}
      usersummary={<SummaryWrapper />}
    />
  );
};

export default Dashboard;
