import { updateObject } from "../../utils/updateObject";

import * as actionsTypes from "../actions/actionTypes";

const initialReducer = {
  testaianswers: [],
  testaianswersError: null,
  testaianswersLoading: false,
  testaianswer: null,
};

const getTestAIAnswersStart = (state, action) => {
  return updateObject(state, {
    testaianswers: [],
    testaianswersError: null,
    testaianswersLoading: true,
    testaianswer: null,
  });
};

const getTestAIAnswersSuccess = (state, action) => {
  return updateObject(state, {
    testaianswers: action.testaianswers,
    testaianswersError: null,
    testaianswersLoading: false,
    testaianswer: null,
  });
};

const getTestAIAnswersFail = (state, action) => {
  return updateObject(state, {
    testaianswers: [],
    testaianswersError: action.error,
    testaianswersLoading: false,
    testaianswer: null,
  });
};

const getOneTestAIAnswersSuccess = (state, action) => {
  return updateObject(state, {
    testaianswers: [],
    testaianswersError: null,
    testaianswersLoading: false,
    testaianswer: action.testaianswer,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case actionsTypes.GET_TEST_AI_USER_ANSWERS_START:
      return getTestAIAnswersStart(state, action);
    case actionsTypes.GET_TEST_AI_USER_ANSWERS_SUCCESS:
      return getTestAIAnswersSuccess(state, action);
    case actionsTypes.GET_ONE_TEST_AI_USER_ANSWERS_SUCCESS:
      return getOneTestAIAnswersSuccess(state, action);
    case actionsTypes.GET_TEST_AI_USER_ANSWERS_FAIL:
      return getTestAIAnswersFail(state, action);
    default:
      return state;
  }
};
export default reducer;
