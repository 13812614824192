export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};
export const getDayFromDate = (date) => {
  let days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  let d = new Date(date);
  return days[d.getDay()];
};

export const calculateTimeFromDate = (date) => {
  const then = new Date(date);
  const now = new Date();
  const diff = now - then;
  const sec = Math.floor(diff / 1000);
  const min = Math.floor(sec / 60);
  const hours = Math.floor(min / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);
  if (years > 0) {
    return years + ` year${years === 1 ? '' : 's'}`;
  } else if (months > 0) {
    return months + ` month${months === 1 ? '' : 's'}`;
  } else if (weeks > 0) {
    return weeks + ` week${weeks === 1 ? '' : 's'}`;
  } else if (days > 0) {
    return days + ` day${days === 1 ? '' : 's'}`;
  } else if (hours > 0) {
    return hours + ` hour${hours === 1 ? '' : 's'}`;
  } else if (min > 0) {
    return min + ` minute${min === 1 ? '' : 's'}`;
  } else if (sec > 0) {
    return sec + ` second${sec === 1 ? '' : 's'}`;
  } else {
    return '0 seconds';
  }
};
export const calculateTimeFromDateFuture = (date) => {
  const then = new Date(date);
  const now = new Date();
  // Calculate the total difference in milliseconds
  const diff = Math.abs(then - now);
  const sec = Math.floor(diff / 1000);
  const minTotal = Math.floor(sec / 60);
  const hoursTotal = Math.floor(minTotal / 60);
  const days = Math.floor(hoursTotal / 24);

  const months = Math.abs(then.getMonth() - now.getMonth() + (12 * (then.getFullYear() - now.getFullYear())));
  const years = Math.abs(then.getFullYear() - now.getFullYear());

  const sign = now > then ? ` ago` : ' from now';

  // Calculate remainder hours and minutes for more detail
  const hours = hoursTotal % 24;
  const min = minTotal % 60;

  // Construct return string with detailed breakdown when days are more than zero
  if (years > 0) {
    return years + ` yr${years === 1 ? '' : 's'} ${sign}`;
  } else if (months > 0) {
    return months + ` mon${months === 1 ? '' : 's'} ${sign}`;
  } else if (days > 0) {
    let result = days + ` day${days === 1 ? '' : 's'}`;
    if (hours > 0) { // include hours if non-zero
      result += `, ${hours} hr${hours === 1 ? '' : 's'}`;
    }
    if (min > 0 && hours === 0) { // include minutes only if hours are zero
      result += `, ${min} min${min === 1 ? '' : 's'}`;
    }
    return result + ` ${sign}`;
  } else if (hours > 0) {
    return hours + ` hr${hours === 1 ? '' : 's'} ${sign}`;
  } else if (min > 0) {
    return min + ` min${min === 1 ? '' : 's'} ${sign}`;
  } else if (sec > 0) {
    return sec + ` sec${sec === 1 ? '' : 's'} ${sign}`;
  } else {
    return '0 seconds';
  }
};

export const groupBy = async (array, key) => {
  // if (key === 'all') return { all: array };
  // Return the end result
  return await array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

export const convertToRoman = (number) => {
  var roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };
  let str = '';
  for (var i of Object.keys(roman)) {
    let q = Math.floor(number / roman[i]);
    number -= q * roman[i];
    str += i.repeat(q);
  }
  return str;
};
