import {
  newSubjects,
  recommendedSubjects,
} from "../../../../functions/newSubjects";
import { ContentContainer } from "../../../common/content/container";
import {
  student_suggested_features_start,
  student_subject_specialization_start,
  student_trending_subjects_start,
  student_new_subjects_start,
  student_recommended_subjects_start,
} from "../../../../store/actions/student.dashboard.homepage";

import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../elements/spinner";
import { trendingSubjects } from "../../../../functions/trendingSubjects";
import { subjectSpecialization } from "../../../../functions/subjectSpecializationUtils";
const Recommendations = ({ userAth }) => {
  const memoizedUserAth = useMemo(() => userAth, [userAth]);
  const dispatch = useDispatch();
  const isAuthenticated = memoizedUserAth.token != null ? true : false;
  const studentDashboardhomePage = useSelector(
    (state) => state.studentDashboardhomePage
  );
  const fetchStudentData = useCallback(async () => {
    const data = {
      token: memoizedUserAth.token,
      username: memoizedUserAth.username,
      userId: memoizedUserAth.userId,
      day: new Date().getTime(),
      academic_level: memoizedUserAth.academic_level,
    };

    try {
      await Promise.allSettled([
        dispatch(student_suggested_features_start(data)),
        dispatch(student_subject_specialization_start(data)),
        dispatch(student_trending_subjects_start(data)),
        dispatch(student_new_subjects_start(data)),
        dispatch(student_recommended_subjects_start(data)),
      ]);
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, memoizedUserAth]);

  useEffect(() => {
    if (!isAuthenticated) {
     return;
    }
    fetchStudentData();
  }, [isAuthenticated, fetchStudentData]);

  let subjects = [
    {
      id: 1,
      subject: "Agriculture",
      imgsrc: "/images/preview/Agriculture.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-01T12:05:25.032Z").toString(),
    },
    {
      id: 2,
      subject: "ART AND CRAFTS",
      imgsrc: "/images/preview/Arts and Crafts.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 3,
      subject: "BIOLOGY",
      imgsrc: "/images/preview/biology.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 4,
      subject: "CHEMISTRY",
      imgsrc: "/images/preview/chem.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 5,
      subject: "COMPUTER SCIENCE",
      imgsrc: "/images/preview/Computer science.svg",
      tag: "Test",
      unit: "unit 2",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 6,
      subject: "ECONOMICS",
      imgsrc: "/images/preview/Economics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 7,
      subject: "ELECTRICITY",
      imgsrc: "/images/preview/Electricity.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 8,
      subject: "ELECTRICITY FOR TVET",
      imgsrc: "/images/preview/Electricity for TVET.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 9,
      subject: "ELECTRONICS",
      imgsrc: "/images/preview/Electronics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 10,
      subject: "ELECTRONICS FOR TVET",
      imgsrc: "/images/preview/Electronics for TVET.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 11,
      subject: "English",
      imgsrc: "/images/preview/French.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 12,
      subject: "ENTREPRENEURSHIP",
      imgsrc: "/images/preview/enterpreneurship.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 13,
      subject: "FINE ART AND CRAFTS",
      imgsrc: "/images/preview/Arts and Crafts.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 14,
      subject: "FRANÇAIS",
      imgsrc: "/images/preview/French.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 15,
      subject: "GEOGRAPHY",
      imgsrc: "/images/preview/Geaography.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 16,
      subject: "Geography and Environment",
      imgsrc: "/images/preview/Geography and environment.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 17,
      subject: "HISTORY",
      imgsrc: "/images/preview/History.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 18,
      subject: "ICT",
      imgsrc: "/images/preview/French.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 19,
      subject: "Literature in English",
      imgsrc: "/images/preview/Literature in English.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 20,
      subject: "MACHINE ASSEMBLY",
      imgsrc: "/images/preview/Machine assembly.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 21,
      subject: "MATHEMATICS",
      imgsrc: "/images/preview/Mathematics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 22,
      subject: "PHYSICS",
      imgsrc: "/images/preview/Physics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
    {
      id: 23,
      subject: "RELIGION AND ETHICS",
      imgsrc: "/images/preview/Religion and ethics.svg",
      tag: "Test",
      unit: "unit 2: mountains and hills",
      created_at: new Date("2022-05-04T12:05:25.032Z").toString(),
    },
  ];

  const subjects_specialized_level = subjectSpecialization(
    studentDashboardhomePage.subjectSpecializationPool
      ? studentDashboardhomePage.subjectSpecializationPool
      : [],
    subjects
  );

  const trending_subjects = trendingSubjects(
    studentDashboardhomePage.trendingsSubjects
      ? studentDashboardhomePage.trendingsSubjects
      : [],
    subjects
  );

  const new_subjects = newSubjects(
    studentDashboardhomePage.newsSubjects
      ? studentDashboardhomePage.newsSubjects
      : [],
    subjects
  );

  const recommended_subjects = recommendedSubjects(
    studentDashboardhomePage.recommendedSubjects
      ? studentDashboardhomePage.recommendedSubjects
      : [],
    subjects
  );
  return (
    <>
      {!studentDashboardhomePage.subjectSpecializationPool_loading ? (
        subjects_specialized_level.length > 0 ? (
          <ContentContainer
            name="Subject Specialization"
            data={subjects_specialized_level}
            slider={true}
            type="one"
            key={0}
          />
        ) : (
          <div></div>
        )
      ) : subjects_specialized_level.length > 0 ? (
        <Spinner size="20" color="blue" />
      ) : (
        <div></div>
      )}

      {!studentDashboardhomePage.trendingsSubjects_loading ? (
        trending_subjects.length > 0 ? (
          <ContentContainer
            name="Trending"
            data={trending_subjects}
            slider={true}
            type="one"
            key={1}
          />
        ) : (
          <div></div>
        )
      ) : trending_subjects.length > 0 ? (
        <Spinner size="20" color="blue" />
      ) : (
        <div></div>
      )}
      {!studentDashboardhomePage.newSubjects_loading ? (
        new_subjects.length > 0 ? (
          <ContentContainer
            name="New"
            data={new_subjects}
            slider={true}
            type="one"
            key={3}
          />
        ) : (
          <div></div>
        )
      ) : new_subjects.length > 0 ? (
        <Spinner size="20" color="blue" />
      ) : (
        <div></div>
      )}

      {!studentDashboardhomePage.recommendedSubjects_loading ? (
        recommended_subjects.length > 0 ? (
          <ContentContainer
            name="Recommended"
            data={recommended_subjects}
            slider={true}
            type="one"
            key={4}
          />
        ) : (
          <div></div>
        )
      ) : recommended_subjects.length > 0 ? (
        <Spinner size="20" color="blue" />
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Recommendations;
