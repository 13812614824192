import { useState } from 'react';
import { useDrop } from 'react-dnd';
import { useEffect } from 'react';
const CompleteSentenceItem = (props) => {

  const [data, setData] = useState("");
  useEffect(() => {
    const newArr = props.fillin;
    let dataIn = "";
    for (var i = 0; i < newArr.length; i++) {
      if (parseInt(newArr[i]?.question_number) === parseInt(props?.currentQuestionNumber) && parseInt(newArr[i]?.questionId) === parseInt(props.questionId) && parseInt(newArr[i]?.spaceNber) === parseInt(props.spaceNber)) {
        dataIn = newArr[i].newEntry;
      }
    }
    setData(dataIn);
  }, []);
  const getTypedInData = (e) => {
    props.callFillIn(e.target.value, props.currentQuestionNumber, props.questionId, props.spaceNber);
    setData(e.target.value);
  };

  return (
    <input placeholder='Type your answer here!!' className={'bg-white text-center px-1 py-1 text-blue'} value={data} onChange={getTypedInData} />
  );
};

export default CompleteSentenceItem;
