import { CardTitle, FeatureTitle } from "../../../../elements/text";

import { Button } from "../../../../elements/button";
import Table from "../../../../elements/table/table";

const Notification = () => {
  const tableheader1 = ["Global setting", "In-app", "Email", "SMS"];
  const tablebody1 = [{td1:{data:"Don\’t send me anything (aside from vital account emails such as password reset notifications)",type:"text"},td2:{data:true,type:"checkbox"},td3:{data:true,type:"checkbox"},td4:{data:true,type:"checkbox"}},{td1:{data:"Don\’t send me anything (aside from vital account emails such as password reset notifications)",type:"text"},td2:{data:true,type:"checkbox"},td3:{data:true,type:"checkbox"},td4:{data:true,type:"checkbox"}}];
  const tableheader2 = ["Forum setting","In-app", "Email","SMS"];
  const tablebody2 = [{td1:{data:"Don\’t send me anything (aside from vital account emails such as password reset notifications)",type:"text"},td2:{data:true,type:"checkbox"},td3:{data:true,type:"checkbox"},td4:{data:true,type:"checkbox"}},{td1:{data:"Don\’t send me anything (aside from vital account emails such as password reset notifications)",type:"text"},td2:{data:true,type:"checkbox"},td3:{data:true,type:"checkbox"},td4:{data:true,type:"checkbox"}}];
  
  return (
    <div className="pt-2 px-2 bg-white md:h-tab-screen md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
      <FeatureTitle name="Notifications" color="black" />
      
        <div className="relative overflow-x-auto sm:rounded-lg">
         <Table thead={tableheader1} tbody={tablebody1}/>
         <Table thead={tableheader2} tbody={tablebody2}/>
        </div>
   
    </div>
  );
};

export default Notification;
