import { updateObject } from "../../../../shared/utility";
import {
  GET_CLASSES_ACTIVE_TAB,
  SET_CLASSES_ACTIVE_TAB,
  GET_TEACHER_SUBJECT_NAMES_START,
  GET_ONE_TEACHER_SUBJECT_NAMES_SUCCESS,
  GET_TEACHER_SUBJECT_NAMES_FAIL,
  GET_TEACHER_SUBJECT_NAMES_SUCCESS,GET_ONE_SUMMARY_NOTES_SUCCESS, GET_SUMMARY_NOTES_FAIL,
  GET_SUMMARY_NOTES_START, GET_SUMMARY_NOTES_SUCCESS
} from "../../../actions/features/teacher/classes";

const initialReducer = {
  activetab: "Class",
  tabs: ["Class", "Subjects"],
  subjectnames: [],
  subjectnamesLoading: null,
  subjectnamesError: null,
  subjectname: null,
  classecourses: [],
  classecoursesLoading: null,
  classecoursesError: null,
  classecourse: null,
};

const getactiveclasstab = (state, action) => {
  return updateObject(state, {
    activetab: action.payload,
  });
};

const setactiveclasstab = (state, action) => {
  return updateObject(state, {
    activetab: action.payload,
  });
};

const getTeacherSubjectNamesStart = (state, action) => {
  return updateObject(state, {
    subjectnames: [],
    subjectnamesError: null,
    subjectnamesLoading: true,
    subjectname: null,
  });
};

const getTeacherSubjectNamesSuccess = (state, action) => {
  return updateObject(state, {
    subjectnames: action.payload,
    subjectnamesError: null,
    subjectnamesLoading: false,
    subjectname: null,
  });
};

const getOneTeacherSubjectNamesSuccess = (state, action) => {
  return updateObject(state, {
    subjectnames: [],
    subjectnamesError: null,
    subjectnamesLoading: false,
    subjectname: action.payload,
  });
};

const getTeacherSubjectNamesFail = (state, action) => {
  return updateObject(state, {
    subjectnames: [],
    subjectnamesError: action.error,
    subjectnamesLoading: false,
    subjectname: null,
  });
};

const getSummaryNotesStart = (state, action) => {
  return updateObject(state, {
    classecourses: [],
    classecoursesError: null,
    classecoursesLoading: true,
    classecourse: null,
  });
};

const getSummaryNotesSuccess = (state, action) => {
  return updateObject(state, {
    classecourses: action.payload,
    classecoursesError: null,
    classecoursesLoading: false,
    classecourse: null,
  });
};

const getOneSummaryNotesSuccess = (state, action) => {
  return updateObject(state, {
    classecourses: [],
    classecoursesError: null,
    classecoursesLoading: false,
    classecourse: action.payload,
  });
};

const getSummaryNotesFail = (state, action) => {
  return updateObject(state, {
    classecourses: [],
    classecoursesError: action.error,
    classecoursesLoading: false,
    classecourse: null,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case GET_CLASSES_ACTIVE_TAB:
      return getactiveclasstab(state, action);
    case SET_CLASSES_ACTIVE_TAB:
      return setactiveclasstab(state, action);
    case GET_TEACHER_SUBJECT_NAMES_START:
      return getTeacherSubjectNamesStart(state, action);
    case GET_TEACHER_SUBJECT_NAMES_SUCCESS:
      return getTeacherSubjectNamesSuccess(state, action);
    case GET_ONE_TEACHER_SUBJECT_NAMES_SUCCESS:
      return getOneTeacherSubjectNamesSuccess(state, action);
    case GET_TEACHER_SUBJECT_NAMES_FAIL:
      return getTeacherSubjectNamesFail(state, action);
      case GET_SUMMARY_NOTES_START:
        return getSummaryNotesStart(state, action);
      case GET_SUMMARY_NOTES_SUCCESS:
        return getSummaryNotesSuccess(state, action);
      case GET_ONE_SUMMARY_NOTES_SUCCESS:
        return getOneSummaryNotesSuccess(state, action);
      case GET_SUMMARY_NOTES_FAIL:
        return getSummaryNotesFail(state, action);
    default:
      return state;
  }
};

export default reducer;
