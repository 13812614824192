import { updateObject } from "../../utils/updateObject";

import * as actionsTypes from "../actions/actionTypes";

const initialReducer = {
  homecarousels: [],
  homecarouselsError: null,
  homecarouselsLoading: false,
  homecarousel: null,
  whoisopandafor: [],
  whoisopandaforsError: null,
  whoisopandaforLoading: false,
  whoisopanda: null,
  howitworks: [],
  howitworksError: null,
  howitworksLoading: false,
  howitwork: null,
  partners: [],
  partnersError: null,
  partnersLoading: false,
  partner: null,
  impacts: [],
  impactsError: null,
  impactsLoading: false,
  impact: null,
  valuepropositions: [],
  valuepropositionsError: null,
  valuepropositionsLoading: false,
  valueproposition: null,
  testimonies: [],
  testimoniesError: null,
  testimoniesLoading: false,
  testimon: null,
};

const getHomeCarouselsStart = (state, action) => {
  return updateObject(state, {
    homecarousels: [],
    homecarouselsError: null,
    homecarouselsLoading: true,
    homecarousel: null,
  });
};

const getHomeCarouselsSuccess = (state, action) => {
  return updateObject(state, {
    homecarousels: action.homecarousels,
    homecarouselsError: null,
    homecarouselsLoading: false,
    homecarousel: null,
  });
};

const getHomeCarouselsFail = (state, action) => {
  return updateObject(state, {
    homecarousels: [],
    homecarouselsError: action.error,
    homecarouselsLoading: false,
    homecarousel: null,
  });
};

const getOneHomeCarouselsSuccess = (state, action) => {
  return updateObject(state, {
    homecarousels: [],
    homecarouselsError: null,
    homecarouselsLoading: false,
    homecarousel: action.homecarousel,
  });
};

const getHomeWhoIsOpandaForStart = (state, action) => {
  return updateObject(state, {
    whoisopandafor: [],
    whoisopandaforError: null,
    whoisopandaforLoading: true,
    whoisopanda: null,
  });
};

const getHomeWhoIsOpandaForSuccess = (state, action) => {
  return updateObject(state, {
    whoisopandafor: action.whoisopandafor,
    whoisopandaforError: null,
    whoisopandaforLoading: false,
    whoisopanda: null,
  });
};

const getHomeWhoIsOpandaForFail = (state, action) => {
  return updateObject(state, {
    whoisopandafor: [],
    whoisopandaforError: action.error,
    whoisopandaforLoading: false,
    whoisopanda: null,
  });
};

const getOneHomeWhoIsOpandaForSuccess = (state, action) => {
  return updateObject(state, {
    whoisopandafor: [],
    whoisopandaforError: null,
    whoisopandaforLoading: false,
    whoisopanda: action.whoisopanda,
  });
};

const getHomeHowItWorksStart = (state, action) => {
  return updateObject(state, {
    howitworks: [],
    howitworksError: null,
    howitworksLoading: true,
    howitwork: null,
  });
};

const getHomeHowItWorksSuccess = (state, action) => {
  return updateObject(state, {
    howitworks: action.howitworks,
    howitworksError: null,
    howitworksLoading: false,
    howitwork: null,
  });
};

const getHomeHowItWorksFail = (state, action) => {
  return updateObject(state, {
    howitworks: [],
    howitworksError: action.error,
    howitworksLoading: false,
    howitwork: null,
  });
};

const getOneHomeHowItWorksSuccess = (state, action) => {
  return updateObject(state, {
    howitworks: [],
    howitworksError: null,
    howitworksLoading: false,
    howitwork: action.howitwork,
  });
};

const getHomePartnersStart = (state, action) => {
  return updateObject(state, {
    partners: [],
    partnersError: null,
    partnersLoading: true,
    partner: null,
  });
};

const getHomePartnersSuccess = (state, action) => {
  return updateObject(state, {
    partners: action.partners,
    partnersError: null,
    partnersLoading: false,
    partner: null,
  });
};

const getHomePartnersFail = (state, action) => {
  return updateObject(state, {
    partners: [],
    partnersError: action.error,
    partnersLoading: false,
    partner: null,
  });
};

const getOneHomePartnersSuccess = (state, action) => {
  return updateObject(state, {
    partners: [],
    partnersError: null,
    partnersLoading: false,
    partner: action.partner,
  });
};

const getHomeValuePropositionsStart = (state, action) => {
  return updateObject(state, {
    valuepropositions: [],
    valuepropositionsError: null,
    valuepropositionsLoading: true,
    valueproposition: null,
  });
};

const getHomeValuePropositionsSuccess = (state, action) => {
  return updateObject(state, {
    valuepropositions: action.valuepropositions,
    valuepropositionsError: null,
    valuepropositionsLoading: false,
    valueproposition: null,
  });
};

const getHomeValuePropositionsFail = (state, action) => {
  return updateObject(state, {
    valuepropositions: [],
    valuepropositionsError: action.error,
    valuepropositionsLoading: false,
    valueproposition: null,
  });
};

const getOneHomeValuePropositionsSuccess = (state, action) => {
  return updateObject(state, {
    valuepropositions: [],
    valuepropositionsError: null,
    valuepropositionsLoading: false,
    valueproposition: action.valueproposition,
  });
};

const getHomeImpactsStart = (state, action) => {
  return updateObject(state, {
    impacts: [],
    impactsError: null,
    impactsLoading: true,
    impact: null,
  });
};

const getHomeImpactsSuccess = (state, action) => {
  return updateObject(state, {
    impacts: action.impacts,
    impactsError: null,
    impactsLoading: false,
    impact: null,
  });
};

const getHomeImpactsFail = (state, action) => {
  return updateObject(state, {
    impacts: [],
    impactsError: action.error,
    impactsLoading: false,
    impact: null,
  });
};

const getOneHomeImpactsSuccess = (state, action) => {
  return updateObject(state, {
    impacts: [],
    impactsError: null,
    impactsLoading: false,
    impact: action.impact,
  });
};

const getHomeTestimoniesStart = (state, action) => {
  return updateObject(state, {
    testimonies: [],
    testimoniesError: null,
    testimoniesLoading: true,
    testimony: null,
  });
};

const getHomeTestimoniesSuccess = (state, action) => {
  return updateObject(state, {
    testimonies: action.testimonies,
    testimoniesError: null,
    testimoniesLoading: false,
    testimony: null,
  });
};

const getHomeTestimoniesFail = (state, action) => {
  return updateObject(state, {
    testimonies: [],
    testimoniesError: action.error,
    testimoniesLoading: false,
    testimony: null,
  });
};

const getOneHomeTestimoniesSuccess = (state, action) => {
  return updateObject(state, {
    testimonies: [],
    testimoniesError: null,
    testimoniesLoading: false,
    testimony: action.testimony,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case actionsTypes.GET_HOME_CAROUSEL_START:
      return getHomeCarouselsStart(state, action);
    case actionsTypes.GET_HOME_CAROUSEL_SUCCESS:
      return getHomeCarouselsSuccess(state, action);
    case actionsTypes.GET_ONE_HOME_CAROUSEL_SUCCESS:
      return getOneHomeCarouselsSuccess(state, action);
    case actionsTypes.GET_HOME_CAROUSEL_FAIL:
      return getHomeCarouselsFail(state, action);
    case actionsTypes.GET_HOME_WHO_IS_OPANDA_FOR_START:
      return getHomeWhoIsOpandaForStart(state, action);
    case actionsTypes.GET_HOME_WHO_IS_OPANDA_FOR_SUCCESS:
      return getHomeWhoIsOpandaForSuccess(state, action);
    case actionsTypes.GET_ONE_HOME_WHO_IS_OPANDA_FOR_SUCCESS:
      return getOneHomeWhoIsOpandaForSuccess(state, action);
    case actionsTypes.GET_HOME_WHO_IS_OPANDA_FOR_FAIL:
      return getHomeWhoIsOpandaForFail(state, action);
    case actionsTypes.GET_HOME_HOW_IT_WORKS_START:
      return getHomeHowItWorksStart(state, action);
    case actionsTypes.GET_HOME_HOW_IT_WORKS_SUCCESS:
      return getHomeHowItWorksSuccess(state, action);
    case actionsTypes.GET_ONE_HOME_HOW_IT_WORKS_SUCCESS:
      return getOneHomeHowItWorksSuccess(state, action);
    case actionsTypes.GET_HOME_HOW_IT_WORKS_FAIL:
      return getHomeHowItWorksFail(state, action);
    case actionsTypes.GET_HOME_PARTNERS_START:
      return getHomePartnersStart(state, action);
    case actionsTypes.GET_HOME_PARTNERS_SUCCESS:
      return getHomePartnersSuccess(state, action);
    case actionsTypes.GET_ONE_HOME_PARTNERS_SUCCESS:
      return getOneHomePartnersSuccess(state, action);
    case actionsTypes.GET_HOME_PARTNERS_FAIL:
      return getHomePartnersFail(state, action);
    case actionsTypes.GET_HOME_IMPACTS_START:
      return getHomeImpactsStart(state, action);
    case actionsTypes.GET_HOME_IMPACTS_SUCCESS:
      return getHomeImpactsSuccess(state, action);
    case actionsTypes.GET_ONE_HOME_IMPACTS_SUCCESS:
      return getOneHomeImpactsSuccess(state, action);
    case actionsTypes.GET_HOME_IMPACTS_FAIL:
      return getHomeImpactsFail(state, action);
    case actionsTypes.GET_HOME_VALUE_PROPOSITIONS_START:
      return getHomeValuePropositionsStart(state, action);
    case actionsTypes.GET_HOME_VALUE_PROPOSITIONS_SUCCESS:
      return getHomeValuePropositionsSuccess(state, action);
    case actionsTypes.GET_ONE_HOME_VALUE_PROPOSITIONS_SUCCESS:
      return getOneHomeValuePropositionsSuccess(state, action);
    case actionsTypes.GET_HOME_VALUE_PROPOSITIONS_FAIL:
      return getHomeValuePropositionsFail(state, action);
    case actionsTypes.GET_HOME_USERS_TESTIMONIES_START:
      return getHomeTestimoniesStart(state, action);
    case actionsTypes.GET_HOME_USERS_TESTIMONIES_SUCCESS:
      return getHomeTestimoniesSuccess(state, action);
    case actionsTypes.GET_ONE_HOME_USERS_TESTIMONIES_SUCCESS:
      return getOneHomeTestimoniesSuccess(state, action);
    case actionsTypes.GET_HOME_USERS_TESTIMONIES_FAIL:
      return getHomeTestimoniesFail(state, action);
    default:
      return state;
  }
};
export default reducer;
