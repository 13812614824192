import { useEffect, useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import Input from "../../../elements/input";
import PageContent from "../pageContent";
import { useTabs } from "react-headless-tabs";
import { CardBody, CardTitle, FeatureTitle } from "../../../elements/text";
import { TabPanel } from "react-headless-tabs";
import GetStarted from "../../../common/features/GetStarted";
import NotFound from "../../../common/features/NotFound";
import TabSelector from "../../../elements/tab/TabSelector";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../elements/modal/modal";
import { Button } from "../../../elements/button";
import { useHistory } from "react-router-dom";
import { student_pastpapers_subjects_start, teacher_pastpapers_subjects_start } from "../../../../store/actions/student.dashboard.pastpapers";
import Spinner from "../../../elements/spinner";
import * as actions from "../../../../store/actions/";
import {
  getOneTeachersPaspapersSuccess,
  getstudentDashboardPastpaperss,
  setQuestionsInit,
} from "../../../../store/actions/features/teacher/pastpapers";
import { Message } from "../../../common/messages";
import constants from "../../../../constants/constants";
import PastpapersGroupItem from "./PastpapersGroupItem";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";
import axios from "../../../../axios-base";
import { use } from "i18next";

const Pastpapers = () => {
  const [showSubjects, setShowSubjects] = useState(false);
  const [level, setLevel] = useState("S1");
  const [searchterm, setSearchterm] = useState("");
  const userAuth = useSelector((state) => state.auth);
  const history = useHistory();

  const [showCreatePastPaperModal, setShowCreatePastPaperModal] =
    useState(false);
  const [type, setType] = useState("National Exam");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("BIOLOGY");
  const [createLevel, setCreateLevel] = useState("1");
  const [academicYear, setAcademicYear] = useState("");
  const [term, setTerm] = useState("");
  const [description, setDescription] = useState("");

  const [dataerror, setdataerror] = useState("");

  const [mypastpapers, setMyPastpapers] = useState([]);
  const [subjects, setSubjects] = useState([]);



  const pastpaperinit = () => {
    const pastpp = {
      level,
      classlevel: createLevel,
      name,
      subject,
      type,
      createLevel,
      academicYear,
      description,
    };


    if (
      type === "" ||
      name === "" ||
      academicYear === "" ||
      description === "" ||
      level === ""
    ) {
      setdataerror("Fill all pastpaper information");
    } else {
      dispatch(getOneTeachersPaspapersSuccess(pastpp));
      history.push("/teacher/features/pastpapers/new");
    }
  };

  const dispatch = useDispatch();
  const studentDashboardPastpapers = useSelector(
    (state) => state.studentDashboardPastpapers
  );

  const subjectnames = useSelector(
    (state) => state.features.classes.subjectnames
  );

  useEffect(() => {
    dispatch(
      teacher_pastpapers_subjects_start({
        user_id: userAuth.userId,
        academic_level: userAuth.academic_level,
        token: userAuth.token,
      })
    );

    dispatch(actions.getTeacherSubjectNames(userAuth?.token));
    dispatch(setQuestionsInit());
  }, []);

  const chosenSubject = useSelector(
    (state) => state.features.pastpapers.chosenSubject
  );

  const [selectedTab, setSelectedTab] = useTabs(
    ["O'Genius Panda", "Community", "My Papers"],
    "O'Genius Panda"
  );

  const subjects_pool_check = (data) => {
    const subjects_data = [];
    const chosen_pastpaper = [];
    if (data?.length > 0 && data[0] && data[0].length > 0) {
      for (var i = 0; i < data[0].length; i++) {
        subjects_data.push({
          name: data[0][i].pastpapers_subject,
          pastpapers_academic_level: data[0][i].pastpapers_academic_level,
          pastpapers: [],
        });
        //-----
        if (data[1] && data[1].length > 0) {
          for (var n = 0; n < data[1].length; n++) {
            if (data[1][n].name === data[0][i].pastpapers_subject) {
              //---issue here
              for (var b = 0; b < data[1][n].pastpapers.length; b++) {
                if (!chosen_pastpaper.includes(data[1][n].pastpapers[b].id)) {
                  subjects_data[i].pastpapers.push(data[1][n].pastpapers[b]);
                  chosen_pastpaper.push(data[1][n].pastpapers[b].id);
                }
              }
            }
          }
        }
      }
      return subjects_data;
    }
    return subjects_data;
  };
  const subjects_pool = subjects_pool_check(studentDashboardPastpapers.pastpapers_subjects);
  const tabs = subjects_pool.map((subject) => subject.name);
  tabs.unshift("initial page");
  const [selectedSubject, setSelectedSubject] = useTabs(
    tabs,
    chosenSubject || "initial page"
  );
  useEffect(() => {
    axios
      .put(
        `/opanda/past-papers/getSubjects`,
        { userId: userAuth.user.id },
        {
          headers: {
            authorization: `Bearer ${userAuth.token}`,
          },
        }
      )
      .then((response) => {
        const subjectData = response.data.results
          .map(pool => ({ subject: pool.subject }))
          .sort((a, b) => a.subject.localeCompare(b.subject));

        setSubjects(subjectData);


      })
      .catch((err) => { });
  }, []);





  return (
    <PageContent>
      <Modal
        medium
        show={showCreatePastPaperModal}
        modalClosed={() => setShowCreatePastPaperModal(false)}
      >
        <CardTitle name="Add paper" />
        <div className="flex space-x-2">
          {/* <Input
            elementType="select"
            elementConfig={{
              startingValue: "SELECT PASTPAPER FROM",
              options: [
                { value: "National exam", displayValue: "National exam" },
                {
                  value: "Mock National Exam",
                  displayValue: "Mock National Exam",
                },
                {
                  value: "District Level Exam",
                  displayValue: "District Level Exam",
                },
              ],
            }}
            value={type}
            changed={setType}
            validation={{ required: true }}
            shouldValidate
            error="Invalid type"
          /> */}
          <Input
            elementType="select"
            elementConfig={{
              startingValue: "SELECT LEVEL",
              options: [
                { value: "olevel", displayValue: "Ordinary Level" },
                { value: "alevel", displayValue: "Advanced Level" },
              ],
            }}
            value={level}
            changed={setLevel}
            validation={{ required: true }}
            shouldValidate
            error="Level is required"
          />
        </div>
        <div className="flex space-x-2">
          <Input
            label="Name"
            elementType="input"
            elementConfig={{
              type: "text",
              placeholder: "Enter title",
            }}
            value={name}
            changed={setName}
          />

        </div>
        <div className="flex space-x-2">
          <Input
            label="Subject"
            elementType="select"
            elementConfig={{
              startingValue: "SELECT",
              options: subjects,
            }}
            value={subject}
            changed={setSubject}
            validation={{ required: true }}
            shouldValidate
            error="subject is required"
          />
          <Input
            label="Academic Year"
            elementType="select"
            elementConfig={{
              startingValue: "SELECT",
              options: constants.lastYearsListPastPapers(),
            }}
            value={academicYear}
            changed={setAcademicYear}
            validation={{ required: true }}
            shouldValidate
            error="Academic year is required"
          />
        </div>

        <Input
          label="Description"
          elementType="textarea"
          elementConfig={{
            type: "text",
            placeholder: "Add description",
          }}
          value={description}
          changed={setDescription}
        />
        <Message type={"error"} viewable={true} message={dataerror} />
        <div className="pt-3 flex justify-center space-x-5">
          <Button
            name="Cancel"
            outline="true"
            color="blue"
            clicked={() => setShowCreatePastPaperModal(false)}
          />
          <Button
            name="Save"
            outline="false"
            color="blue"
            clicked={pastpaperinit}
          />
        </div>
      </Modal>
      <div className={`md:grid grid-cols-12 w-full min-h-screen-sec`}>
        <div
          className={`p-3 bg-gray-200 ${showSubjects ? "h-screen-sec" : ""
            } md:h-full col-span-3`}
        >
          <div className="flex justify-between items-center">
            <Input
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: [
                  { value: "olevel", displayValue: "Ordinary Level" },
                  { value: "alevel", displayValue: "Advanced Level" },
                ],
              }}
              value={level}
              changed={setLevel}
              validation={{ required: true }}
              shouldValidate
              error="Level is required"
            />
            {/* {showSubjects ? (
              <BiChevronUp
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSubjects(!showSubjects)}
              />
            ) : (
              <BiChevronDown
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSubjects(!showSubjects)}
              />
            )} */}
          </div>
          <div className={`${showSubjects ? "" : "hidden"} md:block`}>
            <Input
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Search...",
              }}
              value={searchterm}
              changed={setSearchterm}
            />
            <ul className="py-5">
              {!studentDashboardPastpapers.pastpapers_subjects_loading &&
                studentDashboardPastpapers.pastpapers_subjects.length > 0 ? (
                subjects_pool.map((subject, index) =>
                  searchterm === "" ||
                    subject.name
                      .toLowerCase()
                      .includes(searchterm.toLowerCase()) ? (
                    <li key={index}>
                      <div
                        className="py-2 cursor-pointer"
                        onClick={() => {
                          setSelectedSubject(subject.name);
                          setShowSubjects(false);
                        }}
                      >
                        <CardBody
                          name={subject.name}
                          color="blue"
                          additional={
                            subject.name === selectedSubject ? "font-bold" : ""
                          }
                        />
                      </div>
                    </li>
                  ) : (
                    <></>
                  )
                )
              ) : (
                <Spinner size="20" color="blue" />
              )}
            </ul>
          </div>
        </div>
        <div
          className={`${showSubjects ? "hidden md:block" : ""
            } md:relative p-3 bg-white md:h-full max-h-myscreen-sec min-h-myscreen-sec-mobile md:max-h-myscreen md:min-h-myscreen col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
        >
          <TabPanel
            hidden={selectedSubject !== "initial page"}
            className="transform transition duration-500 ease-in-out"
          >
            <FeatureTitle name="Past Paper" />
            <>
              <nav className="flex justify-between md:justify-start space-x-4">
                <TabSelector
                  isActive={selectedTab === "O'Genius Panda"}
                  title="O'Genius Panda"
                  onClick={() => setSelectedTab("O'Genius Panda")}
                />
                <TabSelector
                  isActive={selectedTab === "Community"}
                  title="Community"
                  onClick={() => setSelectedTab("Community")}
                />
                <TabSelector
                  isActive={selectedTab === "My Papers"}
                  title="My Papers"
                  onClick={() => setSelectedTab("My Papers")}
                />
              </nav>
              <TabPanel hidden={false}>
                <GetStarted type="a subject" feature="past papers" />
              </TabPanel>
            </>
          </TabPanel>
          {!studentDashboardPastpapers.pastpapersLoading &&
            studentDashboardPastpapers.pastpapers_subjects.length > 0 ? (
            subjects_pool.map((subject, index) => (
              <TabPanel
                key={index}
                hidden={selectedSubject !== subject.name}
                className="transform transition duration-500 ease-in-out"
              >
                <FeatureTitle name="Past Paper" />
                <>
                  <nav className="flex justify-between md:justify-start space-x-4">
                    <TabSelector
                      isActive={selectedTab === "O'Genius Panda"}
                      title="O'Genius Panda"
                      onClick={() => setSelectedTab("O'Genius Panda")}
                    />
                    <TabSelector
                      isActive={selectedTab === "Community"}
                      title="Community"
                      onClick={() => setSelectedTab("Community")}
                    />
                    <TabSelector
                      isActive={selectedTab === "My Papers"}
                      title="My Papers"
                      onClick={() => setSelectedTab("My Papers")}
                    />
                  </nav>
                  <TabPanel hidden={selectedTab !== "O'Genius Panda"}>
                    {subject.pastpapers.filter(
                      (pastpaper) => pastpaper.type === "opanda" && pastpaper.pastpapers_published === 0
                    ).length === 0 ? (
                      <NotFound feature="past paper" />
                    ) : level === subject.pastpapers_academic_level ||
                      level === "S1" ? (
                      <>
                        <PastpapersGroupItem
                          subject={subject}
                          level={level}
                          type="opanda"
                          from="National Exams"
                        />
                        <PastpapersGroupItem
                          subject={subject}
                          level={level}
                          type="opanda"
                          from="District Exams"
                        />
                        <PastpapersGroupItem
                          subject={subject}
                          level={level}
                          type="opanda"
                          from="Mock National Exams"
                        />
                      </>
                    ) : (
                      <NotFound feature="past paper" />
                    )}
                  </TabPanel>
                  <TabPanel hidden={selectedTab !== "Community"}>
                    {!studentDashboardPastpapers.pastpapersLoading &&
                      studentDashboardPastpapers.pastpapers_subjects.length > 0 ? (
                      subject.pastpapers.filter(
                        ((pastpaper) => (pastpaper.type === "community" && pastpaper.pastpapers_published === 0))
                      ).length === 0 ? (
                        <NotFound feature="past paper" />
                      ) : level === subject.pastpapers_academic_level ||
                        level === "S1" ? (
                        <>
                          <PastpapersGroupItem
                            subject={subject}
                            level={level}
                            type="community"
                            from="National Exams"
                          />
                          <PastpapersGroupItem
                            subject={subject}
                            level={level}
                            type="community"
                            from="District Exams"
                          />
                          <PastpapersGroupItem
                            subject={subject}
                            level={level}
                            type="community"
                            from="Mock National Exams"
                          />
                        </>
                      ) : (
                        <NotFound feature="past paper" />
                      )
                    ) : (
                      <Spinner size="20" color="blue" />
                    )}
                  </TabPanel>
                  <TabPanel hidden={selectedTab !== "My Papers"}>
                    {subject.pastpapers.filter(
                      (pastpaper) => pastpaper.type === "mypapers"
                    ).length === 0 ? (
                      <NotFound
                        feature="past paper"
                        isCreate
                        create={() => {
                          setShowCreatePastPaperModal(true)
                          setSubject(selectedSubject)
                        }}
                      />
                    ) : (
                      <>
                        <div className="flex justify-end">
                          {<Button
                            name="+ Create"
                            outline="true"
                            color="blue"
                            clicked={() => {
                              setShowCreatePastPaperModal(true)
                              setSubject(selectedSubject)
                            }}
                          />}
                        </div>
                        <PastpapersGroupItem
                          subject={subject}
                          type="mypapers"
                          from="National Exams"
                        />
                        <PastpapersGroupItem
                          subject={subject}
                          type="mypapers"
                          from="District Exams"
                        />
                        <PastpapersGroupItem
                          subject={subject}
                          type="mypapers"
                          from="Mock National Exams"
                        />
                      </>
                    )}
                  </TabPanel>
                </>
              </TabPanel>
            ))
          ) : (
            <></>
          )}
        </div>
        <div
          style={{ zIndex: "1000" }}
          className={`${showSubjects ? "block" : ""
            } md:hidden fixed w-full md:w-auto  md:relative p-3 bg-gray-200 bottom-0  flex flex-row md:flex-col justify-between items-center`}
        >
          <div className="flex w-full justify-around md:flex-col md:items-center md:space-y-10 z-auto">
            {showSubjects ? (
              <GrClose
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSubjects(!showSubjects)}
              />
            ) : (
              <GiHamburgerMenu
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSubjects(!showSubjects)}
              />
            )}
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default Pastpapers;
