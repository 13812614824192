import { useState } from "react";
import { BsCheckCircleFill, BsThreeDots } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { beautifyOpandaPostsAndCommentsText } from "../../../functions/commonfunctions";
import {
  calculateTimeFromDate,
  calculateTimeFromDateFuture,
} from "../../../shared/utility";
import {
  changeAskQuestionModalStatus,
  changeCommentModalStatus,
  setComment,
} from "../../../store/actions/community";
import { Button, TextButton } from "../../elements/button";
import {
  CardBody,
  CardSubText,
  CardTitle,
  SmallText,
} from "../../elements/text";
import PostComment from "./PostComment";
import React, { useEffect, useRef } from "react";
import parse from "react-html-parser";
import {
  student_dashboard_homepage_get_n_community_posts_add_comment_init,
  student_dashboard_homepage_get_n_community_posts_add_comment_start,
  student_dashboard_homepage_get_n_community_posts_new_comment_start,
} from "../../../store/actions/student.dashboard.community";
import Modal from "../../elements/modal/modal";
import RichTextEditor from "../../elements/RichTextEditor";
import Spinner from "../../elements/spinner";
import axios from "../../../axios-base";
import AlertModal from "../../elements/modal/AlertModal";
const Post = (props) => {
  const dispatch = useDispatch();
  const [comments, setComments] = useState(props?.comments || []);
  const [postId, setPostId] = useState(props.postId);
  const [posterId, setPosterId] = useState(0);
  const [showMoreComments, setShowMoreComments] = useState(false);
  const userAuth = useSelector((state) => state.auth);
  const showCommentModal = useSelector(
    (state) => state.community.showCommentModal
  );
  const comment = useSelector((state) => state.community.comment);
  const studentDashboardCommunity = useSelector(
    (state) => state.studentDashboardCommunity
  );
  const [showModal, setShowModal] = useState(false);
  const [oneComment, setOneComment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [settingsMode, setSettingsMode] = useState(false);
  const [choseCommentId, setChoseCommentId] = useState(0);
  const [commentorCommentId, setCommentorCommentId] = useState(0);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);



  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  useEffect(() => {
    //---
    axios
      .post(
        `/opanda/community/get_n_community_mark_post_as_viewed`,
        {
          academic_level: userAuth.academic_level,
          user_id: userAuth.userId,
          group_id: props.group_id,
          postid: props.postId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userAuth.token}`,
          },
        }
      )
      .then((response) => { })
      .catch((err) => { });
  }, [comments]);

  const triggerTheModal = (data, id, commentorId) => {
    setShowModal(true);
    setSettingsMode(true);
    setOneComment(data);
    setChoseCommentId(id);
    setCommentorCommentId(commentorId);
  };
  return (
    <div>
      <Modal medium show={showModal} modalClosed={() => setShowModal(false)}>
        <CardTitle
          name={!settingsMode ? "Provide an answer" : "Update/Delete Comment"}
        />
        <RichTextEditor
          value={oneComment}
          onChange={(text) => setOneComment(text)}
          placeholder="Write your answer here"
        />
        <CardBody
          name="The code: answer politely!"
          additional="w-full pb-3 border-b border-gray-500"
        />
        <div className="pt-3 flex justify-center space-x-5">
          <Button
            name="Cancel"
            outline="true"
            color="blue"
            clicked={() => setShowModal(false)}
          />
          {!settingsMode ? (
            !isLoading ? (
              <Button
                name="Post"
                outline="false"
                color="blue"
                clicked={() => {
                  if (oneComment !== "") {
                    setIsLoading(true);
                    axios
                      .post(
                        `/opanda/community/get_n_community_posts_add_comment`,
                        {
                          academic_level: userAuth.academic_level,
                          user_id: userAuth.userId,
                          comment: oneComment + " ",
                          postid: postId,
                        },
                        {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${userAuth.token}`,
                          },
                        }
                      )
                      .then((response) => {
                        setShowModal(false);
                        setOneComment("");
                        setIsLoading(false);
                        setComments(response.data.results);
                      })
                      .catch((err) => {
                        setIsLoading(false);
                      });
                  } else {
                    //alert("Empty Fields!!");
                    setShowAlert(true);
                    setAlertMessage(`"Empty Fields!!"`);
                  }
                }}
              />
            ) : (
              <Spinner size="20" color="blue" />
            )
          ) : !isLoading ? (
            <>
              {commentorCommentId === userAuth.userId ? (
                <>
                  <Button
                    name="Delete"
                    outline="true"
                    color="red"
                    clicked={() => {
                      if (oneComment !== "") {
                        if (
                          window.confirm(
                            "Are you sure, you want to delete this comment?"
                          )
                        ) {
                          setIsLoading(true);
                          axios
                            .post(
                              `/opanda/community/get_n_community_posts_delete_comment`,
                              {
                                academic_level: userAuth.academic_level,
                                user_id: userAuth.userId,
                                comment: oneComment + " ",
                                postid: postId,
                                choseCommentId: choseCommentId,
                              },
                              {
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: `Bearer ${userAuth.token}`,
                                },
                              }
                            )
                            .then((response) => {
                              setShowModal(false);
                              setOneComment("");
                              setIsLoading(false);
                              setComments(response.data.results);
                            })
                            .catch((err) => {
                              setIsLoading(false);
                            });
                        }
                      } else {
                        //alert("Empty Fields!!");
                        setShowAlert(true);
                        setAlertMessage(`"Empty Fields!!"`);
                      }
                    }}
                  />
                  <Button
                    name="Update"
                    outline="true"
                    color="blue"
                    clicked={() => {
                      if (oneComment !== "") {
                        if (
                          window.confirm(
                            "Are you sure, you want to update this comment?"
                          )
                        ) {
                          setIsLoading(true);
                          axios
                            .post(
                              `/opanda/community/get_n_community_posts_update_comment`,
                              {
                                academic_level: userAuth.academic_level,
                                user_id: userAuth.userId,
                                comment: oneComment + " ",
                                choseCommentId: choseCommentId,
                                postid: postId,
                              },
                              {
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: `Bearer ${userAuth.token}`,
                                },
                              }
                            )
                            .then((response) => {
                              setShowModal(false);
                              setOneComment("");
                              setIsLoading(false);
                              setComments(response.data.results);
                            })
                            .catch((err) => {
                              setIsLoading(false);
                            });
                        }
                      } else {
                        //alert("Empty Fields!!");
                        setShowAlert(true);
                        setAlertMessage(`"Empty Fields!!"`);
                      }
                    }}
                  />
                </>
              ) : (
                ""
              )}
              {props.userid === userAuth.userId ? (
                <Button
                  name="Approve"
                  outline="true"
                  color="green"
                  clicked={() => {
                    if (oneComment !== "") {
                      if (
                        window.confirm(
                          "Are you sure, you want to approve this comment?"
                        )
                      ) {
                        setIsLoading(true);
                        axios
                          .post(
                            `/opanda/community/get_n_community_posts_mark_comment_as_right_answer`,
                            {
                              academic_level: userAuth.academic_level,
                              user_id: userAuth.userId,
                              choseCommentId: choseCommentId,
                              postid: postId,
                            },
                            {
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${userAuth.token}`,
                              },
                            }
                          )
                          .then((response) => {
                            setShowModal(false);
                            setIsLoading(false);
                            setOneComment("");
                            setIsLoading(false);
                            setComments(response.data.results);
                          })
                          .catch((err) => {
                            setIsLoading(false);
                          });
                      }
                    } else {
                      //alert("Empty Fields!!");
                      setShowAlert(true);
                      setAlertMessage(`"Empty Fields!!"`);
                    }
                  }}
                />
              ) : (
                ""
              )}
            </>
          ) : (
            <Spinner size="20" color="blue" />
          )}
        </div>
      </Modal>
      {showAlert && (
        <AlertModal message={alertMessage} onClose={handleCloseAlert} />
      )}
      <div
        className="w-full my-5 hover:bg-white-blue rounded-2xl hover:border-none border border-gray-500 forumcontent"
        key={postId}
      >
        <div className="pt-3">
          <CardBody
            additional="px-3"
            name={parse(
              "" + beautifyOpandaPostsAndCommentsText(props.content) + " "
            )}
          />
          <div className="px-3 pb-2 border-b border-gray-400 pt-3">
            <CardSubText name="Tags: " additional="italic font-light" />
            {props?.related?.map((subject, index) => (
              <CardSubText
                key={index}
                name={subject}
                additional="italic font-light"
              />
            ))}
          </div>
          <div className="divide-y divide-gray-400">
            <PostComment
              commentorStatus={props.userid === userAuth.userId}
              triggerTheModal={triggerTheModal}
              length={comments.length}
              isLoading={isLoading}
              key={
                postId + "" + settingsMode
                  ? comments.length * Math.random()
                  : comments.length
              }
              comments={comments}
            />
          </div>
          <div className="w-full p-3">
            <TextButton
              name="Add comment"
              color="gray-500"
              additional="w-full hover:bg-blue bg-white hover:text-white text-xs rounded-lg py-2"
              clicked={() => {
                setSettingsMode(false);
                setShowModal(true);
                setOneComment("");
              }}
            />
          </div>
        </div>
        <div className="bg-white-blue p-3 border-gray-300 flex justify-between items-center rounded-b-2xl">
          <div className="flex space-x-5">
            <CardBody name={`${comments.length} comments`} />
            {comments.filter((comment) => comment.isAnswer).length > 0 && (
              <div className="flex items-center space-x-1">
                <BsCheckCircleFill color="#E8AD26" />
                <CardBody name="Answered" />
              </div>
            )}
          </div>
          <div className="flex flex-row space-x-4">
            <div className="flex flex-col text-right">
              {userAuth.userId === props.userid ? (
                <CardTitle name={"me"} color="blue" />
              ) : (
                <CardTitle name={props.creator} color="blue" />
              )}
              <SmallText
                name={
                  calculateTimeFromDateFuture(new Date(props.createdAt)) +
                  " "
                }
                color="blue"
              />
            </div>
            {userAuth.userId === props.userid ? (
              <TextButton
                isSmall={true}
                name={<BsThreeDots />}
                outline="true"
                color="blue"
                additional="p-2 inline-block border-blue border-1 hover:bg-white hover:color-blue rounded-full float-right"
                clicked={() => {
                  dispatch(changeAskQuestionModalStatus(true));
                  props.setSettingsMode(true);
                  props.getPostid(props.postId);
                  props.setPostAndRelatedData(props.content, props?.related);
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;
