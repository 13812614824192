import Footer from '../components/common/footer';
import Header from '../components/common/header/header';
import Hero from '../components/common/hero';
import PageContent from '../components/pages/landing/features/pagecontent';
import Gallery from '../components/pages/landing/features/gallery';
import ScrollToTop from '../components/common/ScrollToTop';
import { useTranslation } from 'react-i18next';
import { NotificationBarCompetition } from '../components/pages/guest/previewguest/notificationbar';
export const Features = (props) => {
  const { t } = useTranslation();
  return (
    <div className="scrollbar-hidden font-poppins h-screen">
      <NotificationBarCompetition />
      <Header />
      <Hero
        page="features"
        title={t('Features of O’Genius Panda?')}
        body={t('We understand that visual learners and hand-on learners need to have an environment that enable them to maximize knowledge acquired from school.')}
      />
      <PageContent />
      <Gallery />
      <Footer />
      <ScrollToTop />
    </div>
  );
};
