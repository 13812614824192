import { useTabs, TabPanel } from 'react-headless-tabs';
import { CardBody, SmallText } from '../../elements/text';
import TabSelector from './TabSelector';
import { calculateTimeFromDate } from '../../../shared/utility';
import { useState } from 'react';
import { FaRegComments } from 'react-icons/fa';
import { MdOutlineComment } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { changeSelectedCommentText } from '../../../store/actions/languages';
import { useParams } from "react-router-dom";
import { student_get_selected_unit_with_labs_docs_videos_comment_first_load_start, student_get_selected_unit_with_labs_docs_videos_comment_start } from '../../../store/actions/student.dashboard.curriculum.cbc';
import Spinner from '../../elements/spinner';
import { useEffect } from 'react';
const Comment = (props) => {

  return (
    <div className="rounded-lg shadow my-2">
      {props.part === '""' ? null : (
        <div className="bg-white rounded-t-lg p-2">
          <CardBody name={props.part} />
        </div>
      )}
      <div
        className={`bg-white-blue ${props.part === '""' ? 'rounded-lg' : 'rounded-b-lg'
          } p-2`}
      >
        <CardBody name={props.comment} />
        <div className="flex justify-between">
          <SmallText name={props.createdBy} color="red" />
          <SmallText
            name={`${calculateTimeFromDate(props.createdAt)} ago`}
            color="gray-500"
          />
        </div>
      </div>
    </div>
  );
};

const Comments = (props) => {
  const dispatch = useDispatch();
  const courses_params = useParams();
  const userAth = useSelector((state) => state.auth);
  const [selectedTab, setSelectedTab] = useTabs(['All', 'My Comments'], 'My Comments');
  // const [allComments] = useState([
  //   {
  //     part: '"Cupidatat incididunt eiusmod adipisicing sunt minim ipsum eiusmod laboris."',
  //     comment:
  //       'A comment made to the people for the purpose of testing whatever was put here',
  //     createdBy: 'Mugaba Shyaka',
  //     createdAt: '2022-03-26',
  //   },
  //   {
  //     part: '"Officia nostrud eu pariatur Lorem ea incididunt labore consequat anim officia do incididunt veniam ipsum."',
  //     comment:
  //       'A comment made to the people for the purpose of testing whatever was put here',
  //     createdBy: 'Mugaba Shyaka',
  //     createdAt: '2022-03-29 9:10',
  //   },
  //   // {
  //   //   part: '"Nostrud in ea tempor dolor sit laboris cillum ipsum nostrud et qui."',
  //   //   comment:
  //   //     'A comment made to the people for the purpose of testing whatever was put here',
  //   //   createdBy: 'Mugaba Shyaka',
  //   //   createdAt: '2022-03-20',
  //   // },
  // ]);
  // const [myComments, setMyComments] = useState([]);
  const [comment, setComment] = useState('');
  //---
  const studentDashboardCurriculumCbc = useSelector((state) => state.studentDashboardCurriculumCbc);
  //---
  useEffect(() => { 
    dispatch(
      student_get_selected_unit_with_labs_docs_videos_comment_first_load_start({
        commentor_id: userAth.userId,
        token: userAth.token,
        commentor_names: userAth.username,
        comment: comment,
        comment_header: props.selected,
        summary_note_commend_id: courses_params.noteId ?? courses_params.bookId
      })
    );
  },[]);

  const commentHandler = () => {
    //---
    dispatch(
      student_get_selected_unit_with_labs_docs_videos_comment_start({
        commentor_id: userAth.userId,
        token: userAth.token,
        commentor_names: userAth.username,
        comment: comment,
        comment_header: props.selected,
        summary_note_commend_id: courses_params.noteId ?? courses_params.bookId
      })
    );
    setComment('');
    dispatch(changeSelectedCommentText(''));
  };
  
  function showTheComments(datain) {
    const newComments = [];
    const MyCommentsOnly = [];
    if (datain.data && datain.data.results.length > 0) {

      const reslts = datain.data.results[1];
      for (var i = 0; i < reslts.length; i++) {
        newComments.push({
          part: reslts[i].comment_header,
          comment: reslts[i].comment,
          createdBy: reslts[i].commentor_names,
          createdAt: reslts[i].createdAt,
        });
        if(reslts[i].commentor_names===userAth.username){
          MyCommentsOnly.push({
            part: reslts[i].comment_header,
            comment: reslts[i].comment,
            createdBy: reslts[i].commentor_names,
            createdAt: reslts[i].createdAt,
          });
        }
      }
      //setMyComments(newComments);
      props.getcomments(newComments.length);
      return [newComments,MyCommentsOnly];

    }
    props.getcomments(0);
    return [[],[]];
  }
  //--
  const allTheComments = showTheComments(studentDashboardCurriculumCbc.get_selected_unit_with_labs_docs_videos_comment);
  return (
    <div className="flex flex-col p-3 mb-8 bg-white absolute z-50 top-0 bottom-14 md:bottom-0 left-0 md:-left-72 w-full h-screen-second md:w-72 bg-gray-200 border-r border-l border-gray-500 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
      <nav className="flex justify-between md:justify-start border-b border-gray-500">
        <TabSelector
          isActive={selectedTab === 'All'}
          title="All"
          new={allTheComments[0].length}
          onClick={() => setSelectedTab('All')}
        />
        <TabSelector
          isActive={selectedTab === 'My Comments'}
          title="My Comments"
          new={allTheComments[1].length}
          onClick={() => setSelectedTab('My Comments')}
        />
      </nav>
      <div className="flex-1 flex flex-col justify-between items-between">
        <div className="">
          <TabPanel hidden={selectedTab !== 'All'}>
            {allTheComments[0].map((comment, index) => (
              <Comment key={index} {...comment} />
            ))}
          </TabPanel>
          <TabPanel hidden={selectedTab !== 'My Comments'}>
            {allTheComments[1].length === 0 ? (
              <div className="flex flex-col items-center">
                <FaRegComments size={100} />
                <div className="text-center flex flex-col">
                  <SmallText name="You haven't made any comment yet!" />
                  <SmallText name="Start here" color="red" />
                </div>
              </div>
            ) : (
              allTheComments[1].map((comment, index) => (
                (comment.createdBy === userAth.username) ? <Comment key={index} {...comment} /> : <></>

              ))
            )}
          </TabPanel>
        </div>
        <div>
          {props.selected === '' ? null : (
            <div className="w-full rounded-t-lg bg-white px-3 py-1.5 border border-solid border-gray-500">
              <CardBody name={props.selected} color="gray-500" />
            </div>
          )}
          <div className="relative">
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              {(studentDashboardCurriculumCbc.get_selected_unit_with_labs_docs_videos_comment_loading) ? <Spinner size="10" color="blue" /> : <MdOutlineComment color="#839098" onClick={commentHandler} />}
            </div>
            {(studentDashboardCurriculumCbc.get_selected_unit_with_labs_docs_videos_comment_loading) ? <Spinner size="10" color="blue" /> : <input
              className={`w-full outline-none border-px border-solid border-blue ${props.selected === '' ? 'rounded-lg' : 'rounded-b-lg'
                } focus:border-blue focus:ring-transparent focus:outline-0 focus:outline-transparent`}
              type="text"
              placeholder="Comment here"
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  commentHandler();
                }
              }}
            />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comments;
