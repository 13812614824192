import Profile from '../../../components/pages/parent/user/profile/index';
import Layout from '../../../components/common/Layout';

const ParentProfileContainer = () => {
  return (
    <Layout>
      <Profile />
    </Layout>
  );
};

export default ParentProfileContainer;