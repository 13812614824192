import LinkAccount from '../../../components/pages/student/user/linkaccount/index';
import Layout from '../../../components/common/Layout';

const StudentLinkAccountContainer = () => {
  return (
    <Layout>
      <LinkAccount />
    </Layout>
  );
};

export default StudentLinkAccountContainer;