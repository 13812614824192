import { useEffect, useRef } from "react";
import { ActivitySummary } from "./detailchart";

import { school_users_lastweek_sessionduration_start } from "../../../../store/actions/school.dashboard.user.details";
import { useDispatch, useSelector } from "react-redux";
import { SectionTitle } from "../../../elements/text";
const SchoolUserActivity = (props) => {
  const today = useRef(new Date().getTime());
  const dispatch = useDispatch();
  const schoolUserDetails = useSelector((state) => state.schoolUserDetails);

  useEffect(() => {
    dispatch(
      school_users_lastweek_sessionduration_start({
        token: props.token,
        userId: props.userId,
        day: today.current,
      })
    );
  }, []);

  return (
    <div className="col-span-2">
      {schoolUserDetails.school_user_session_loading ? (
        <div className="bg-white px-3 py-3 filter drop-shadow-md rounded-2xl w-full h-full flex justify-center align-center">
          <div className="w-full h-full my-2 overflow-auto flex justify-center align-center">
            <SectionTitle name={`No activity found`} color="blue" />
          </div>
        </div>
      ) : (
        <ActivitySummary
          title="weekly Session"
          color="blue"
          titleColor="blue"
          height={250}
          data={schoolUserDetails.school_user_session_duration}
          time="This week"
        />
      )}
    </div>
  );
};

export default SchoolUserActivity;
