import { ButtonLink } from "../../../elements/button";
import { PageTitle, PageBody } from "../../../elements/text";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// import Swiper core and required modules
import SwiperCore, { Autoplay, EffectCoverflow, Pagination } from "swiper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Spinner from "../../../elements/spinner";

// install Swiper modules
SwiperCore.use([Autoplay, EffectCoverflow, Pagination]);

const Carousel = (props) => {
  const { t } = useTranslation();
  const homecarousel = useSelector((state) => state.home.homecarousel);
  const homecarouselsLoading = useSelector(
    (state) => state.home.homecarouselsLoading
  );
  return (
    <>
      {homecarouselsLoading ? (
        <div className="flex flex-row justify-center">
          <Spinner size="20" color="blue" />
        </div>
      ) : (
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          effect={"coverflow"}
          grabCursor={true}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={{
            clickable: true,
          }}
          className="w-full h-screen mySwiper"
        >
          <SwiperSlide>
            <div className="bg-carousel bg-center bg-cover bg-no-repeat h-full">
              <div className="bg-blue bg-opacity-25 h-full w-full flex flex-col align-start  justify-center">
                <div className="max-w-full md:max-w-7xl max-h-7xl mx-auto px-8">
                  <div className="w-full mx-5 md:mx-10 md:w-1/2 lg-1/3">
                    <div className="w-full mb-8">
                      <PageTitle
                        name={t("Education reimagined.")}
                        alignment="left"
                        color="white"
                      />
                      <PageBody
                        color="white"
                        name={t(
                          "We understand that visual learners and hands-on learners need to have an environment that enables them to maximise knowledge acquired from school."
                        )}
                        alignment="left"
                      />
                    </div>
                    <ButtonLink
                      page="previewpanda"
                      name={t("Preview panda")}
                      outline="true"
                      color="white"
                    />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          {/* <SwiperSlide>
            <div className="bg-carousel bg-center bg-cover bg-no-repeat  h-full">
              <div className="bg-blue bg-opacity-25 h-full w-full flex flex-col align-start  justify-center">
                <div className="max-w-7xl max-h-7xl mx-auto px-8">
                  <div className="w-full mx-5 md:mx-10 md:w-1/2 lg-1/3">
                    <div className="w-full mb-8">
                      <PageTitle
                        name={t("Education reimagined.")}
                        alignment="left"
                        color="white"
                      />
                      <PageBody
                        color="white"
                        name={t(
                          "We understand that visual learners and hands-on learners need to have an environment that enables them to maximise knowledge acquired from school."
                        )}
                        alignment="left"
                      />
                    </div>
                    <ButtonLink
                      page="previewpanda"
                      name={t("Preview panda")}
                      outline="true"
                      color="white"
                    />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-carousel bg-center bg-cover bg-no-repeat h-full">
              <div className="bg-blue bg-opacity-25 h-full w-full flex flex-col align-start  justify-center">
                <div className="max-w-7xl max-h-7xl mx-auto px-8">
                  <div className="w-full mx-5 md:mx-10 md:w-1/2 lg-1/3">
                    <div className="w-full mb-8">
                      <PageTitle
                        name={t("Education reimagined.")}
                        alignment="left"
                        color="white"
                      />
                      <PageBody
                        color="white"
                        name={t(
                          "We understand that visual learners and hands-on learners need to have an environment that enables them to maximise knowledge acquired from school."
                        )}
                        alignment="left"
                      />
                    </div>
                    <ButtonLink
                      page="previewpanda"
                      name={t("Preview panda")}
                      outline="true"
                      color="white"
                    />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide> */}
        </Swiper>
      )}
    </>
  );
};
export default Carousel;
