import { useState } from 'react';
import { CardTitle, SmallText } from '../../../elements/text';
import parse from 'react-html-parser';
import { CgChevronDownR, CgChevronUpR } from 'react-icons/cg';

const QuestionItem = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="flex flex-col space-x-2 p-2 pastpaperquestion">
      <CardTitle name={`Question ${props.number}`} />
      {(props.question)}
      <div className="w-full flex justify-between items-center border-b pt-2 border-gray-500">
        <SmallText name="Answer" additional="font-semibold" />
        {isOpen ? (
          <CgChevronUpR
            size={18}
            color="#183F71"
            onClick={() => setIsOpen((isOpen) => !isOpen)}
          />
        ) : ( 
          <CgChevronDownR
            size={18}
            color="#183F71"
            onClick={() => setIsOpen((isOpen) => !isOpen)}
          />
        )}
      </div>
      <div className={isOpen ? '' : 'hidden'}>{(props.answer)}</div>
    </div>
  ); 
};

export default QuestionItem;
