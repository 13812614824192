import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PartnerCard } from "../../../elements/card";
import Spinner from "../../../elements/spinner";
import { SectionTitle } from "../../../elements/text";

const Partners = (props) => {
  const { t } = useTranslation();
  const partners = useSelector((state) => state.home.partners);
  const partnersLoading = useSelector((state) => state.home.partnersLoading);
  return (
    <div className="bg-white">
      <div className="w-full flex flex-col align-center md:flex-row max-w-7xl mx-auto py-10 space-y-10 md:space-y-0 space-x-4 px-8 mx-5 md:py-16">
        {partnersLoading ? (
          <div className="flex flex-row justify-center">
            <Spinner size="20" color="blue" />
          </div>
        ) : (
          <div className="mx-auto w-full space-y-6">
            <SectionTitle
              name={t("Partners")}
              color="blue"
              alignment="center"
            />
            <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-6 gap-4 justify-items-center">
              {partners?.map((partner, key) => (
                <PartnerCard
                  key={key}
                  partner={partner?.name}
                  logo_src={`${process.env.REACT_APP_BACKEND_URL}/opanda/${partner?.thumbnail}`}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Partners;
