import CommunicationLayout from './CommunicationLayout/CommunicationLayout'
import {
  CardTitle,
  SectionTitle,
} from '../../../elements/text';
import { Button, GoBackButton } from '../../../elements/button';
import { useTabs } from 'react-headless-tabs';
import Input from '../../../elements/input';
import { useState } from 'react';
import Notification from '../../../elements/notification/notification';

const NewRecipient = () => {
      const createdReciptients = [
        {id: 1, name: 'School announcement' },
        {id: 2, name: 'School' },
        {id: 3, name: 'Teachers' },
      ];
  const currentRecipient = createdReciptients.map((item) => item.name);
  const [searchTerm, setSearchTerm] = useState('');
  const [newRecipient, setNewRecipient] = useState(false);
  const [recipientData, setRecipientData] = useState(false);
  const [recipient, setRecipient] = useState(['teacher', 'school']);
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [medium, setMedium] = useState(['email', 'inapp', 'sms']);
  const [communicationGroups, setCommunicationGroups ] = useState([
  { name: 'Teacher'}, 
  { name: 'Parents'}, 
  { name: 'School'}, 
  { name: 'Student'}, 
    ]);
  const [communicationSubGroups, setCommunicationSubGroups ] = useState([
  { name: 'All'}, 
  { name: 'Individual'}, 
  { name: 'Class'}, 
  { name: 'Section'}, 
    ]);

  return (
    <CommunicationLayout>
    <div className="w-full h-full flex">
        <div
            className={`bg-gray-200 w-100 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
          >
            <div className="flex justify-between items-end">
            <div className='flex flex-col flex-col-reverse w-full items-end justify-center p-2 mb-2 border-b-2 border-gray-500'>
            <div className='w-full my-2'>
            <Input
                elementType="input"
                additional="shadow-md rounded-full w-full"
                elementConfig={{
                type: "text",
                placeholder: "Search",
                }}
                value={searchTerm}
                changed={setSearchTerm}
            />
            </div> 
            <div className='w-full'>
              <Button
                name="NEW"
                outline="false"
                color="blue"
                additional="w-full"
                isSquare={true}
                clicked={() =>  {
                    setNewRecipient(true);
                    setRecipientData(false);
                }}
              />
              </div>
          </div>
            </div>
            {createdReciptients.map((item, index) => (
              <div className=''>
                <div className='mt-2'>
                    <div key={index} className="px-2">
                        <Button 
                            name={item.name}
                            color="gray-500"
                            clicked={() => {
                                setRecipientData(true);
                                setNewRecipient(false);
                              }}  
                              /> 
                    </div>
                </div>
              </div>
            ))}
        </div>

        {newRecipient ? (
               <div className={`w-full h-full flex flex-col bg-white p-2 shadow absolute md:relative overflow-x-auto`}>
                <span className='md:hidden' 
                      onClick={() => { 
                        setNewRecipient(false);
                        }}>
                    <GoBackButton
                      action="Back"
                      location="Recipient"
                    />
                </span> 
               <SectionTitle name="New Recipient" color="gray-700"/>
               <div className="w-50% p-2">
              <Input
                  label="Name"
                  elementType="input"
                  elementConfig={{
                    type: 'text',
                    placeholder: 'Add name',
                  }}
                  value={subject}
                  changed={setSubject}
                />
              </div>
               <div className='my-1'>
                  <CardTitle name="Group" />
                   <div className="w-50% p-2 flex">
                   {communicationGroups.map((item, index) => (
                   <span className="mr-4" key={index}>
                       <Input 
                       label={item.name} 
                       elementType="checkbox" 
                       elementConfig={{}} 
                       validation={{ required: false }} 
                       shouldValidate />
                   </span>
                   ))}  
                   </div>
               </div>
               <div className='my-1'>
                   <CardTitle name="Sub group" />
                   <div className="w-50% p-2 flex">
                   {communicationSubGroups.map((item, index) => (
                   <span className="mr-4" key={index}>
                       <Input 
                       label={item.name} 
                       elementType="checkbox" 
                       elementConfig={{}} 
                       validation={{ required: false }} 
                       shouldValidate />
                   </span>
                   ))}  
                   </div>
               </div>
       
               <div className="p-2">
               <Input
                label="Description"
                elementType="textarea"
                elementConfig={{
                  type: 'text',
                  placeholder: 'Add Description',
                  rows: 3,
                }}
                value={text}
                changed={setText}
              />
               </div>
              <div className="py-2 flex space-x-2 items-center">
                <Button
                  name="Save"
                  outline="false"
                  color="blue"
                  isSquare
                  clicked={() => {}}
                />
       
              </div>
               </div>
        ) : (
            <div className={`${newRecipient ? 'lg:hidden' : ''} w-full h-full hidden md:flex flex-col items-center justify-center p-3 bg-white right-0 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100 shadow`}>
            <div className="w-full flex flex-col items-center justify-center">
          <SectionTitle name="Registered Recipient data" color="blue" />
            </div>
         </div>
        )}
     
    </div>
    </CommunicationLayout>
  );
};

export default NewRecipient;
