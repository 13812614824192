//---Check time of the day--
const today = () => {
  var today_ = new Date();
  var curHr = today_.getHours();
  let msg = "";
  if (curHr < 12) {
    msg = "Good Morning";
  } else if (curHr < 18) {
    msg = "Good Afternoon";
  } else {
    msg = "Good Evening";
  }
  return msg;
};

export default today;
