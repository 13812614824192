import axios from "../../axios-base";

export const PACY_INIT = "PACY_INIT";
export const PACY_SUCCESS = "PACY_SUCCESS";
export const PACY_FAIL = "PACY_FAIL";

export const PACY_PRINT = "PACY_PRINT";

export function pacy_start() {
  return {
    type: PACY_INIT
  };
}
export function pacy_success(data) {
  return {
    type: PACY_SUCCESS,
    payload: data
  };
}
export function pacy_fail(error) {
  return {
    type: PACY_FAIL,
    payload: error
  };
}

export function pacy_print(message) {
  return {
    type: PACY_PRINT,
    payload: message
  };
}

export function pacy(id) {
  return (dispatch) => {
    dispatch(pacy_start());
    axios
      .get(`/opanda/learning-resources`)
      .then((response) => {
        dispatch(pacy_success(response.data));
      })
      .catch((err) => {
        dispatch(pacy_fail(id));
      });
  };
}
