import { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { BsCheckLg } from 'react-icons/bs';
import { IoSettingsSharp } from 'react-icons/io5';
import { RiErrorWarningFill } from 'react-icons/ri';
import { Button, GoBackButton} from '../../../../elements/button'
import { PaymentOptionsCard } from '../../../../elements/card';
import { DynamicModal } from '../../../../elements/modal/DynamicModal';
import { CardBody, FeatureTitle, SectionTitle } from '../../../../elements/text'
import PageContent from '../../pageContent'

const PackagePayment = () => {
    const history = useHistory();
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [showFailModal, setShowFailModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

  return (
    <PageContent>
          {/* LOADING WHEN CONFIRMED PAYMENT  */}
          <DynamicModal
        show={showLoadingModal}
        modalClosed={() => setShowLoadingModal(false)}
        top={'20%'}
        right={'30%'}
        width={'40%'}
      >
        <div className="flex flex-col items-center justify-between h-full w-full">
          <SectionTitle name="Confirming payment"/>
          <div className="m-4 flex flex-col">
            {showLoadingModal ? "Loading..." : ""}
          </div>
          <div className="">
              <IoSettingsSharp color='#183F71' size={90} />
          </div>
        </div>
      </DynamicModal>
      {/* WHEN PAYMENT FAILED */}
      <DynamicModal
        show={showFailModal}
        modalClosed={() => setShowFailModal(false)}
        top={'20%'}
        right={'30%'}
        width={'40%'}
      >
        <div className="flex flex-col items-center justify-between h-full w-full">
          <SectionTitle name="Your payment failed" />
          <div className="m-4 flex flex-col items-center justify-center w-70%">
            <CardBody name='Your card has been declined. Please contact your bank or choose another payment option' />
          </div>
          <div>
              <RiErrorWarningFill color='#BE1E2D' size={90} />
          </div>
        </div>
      </DynamicModal>
      {/* WHEN PAYMENT SUCCEEDED */}
      <DynamicModal
        show={showSuccessModal}
        modalClosed={() => setShowSuccessModal(false)}
        top={'20%'}
        right={'30%'}
        width={'40%'}
      >
        <div className="flex flex-col items-center justify-between h-full w-full">
          <SectionTitle name="Your payment was successfull!" />
          <div className="m-4 flex flex-col items-center justify-center w-70%">
            <CardBody name='You will be redirected to your dashboard' />
          </div>
          <div>
              <BsCheckLg color='#E8AD26' size={90} />
          </div>
          <div>
          <Button
              name="Proceed"
              outline="true"
              color="blue"
              clicked={()=>{
                setShowSuccessModal(true);
                setShowLoadingModal(false);
                history.push('/parent/marketplace/books/purchasedbooks');
              }}
            />
          </div>
        </div>
      </DynamicModal>
      {/* --- */}
    <div className='w-full h-full p-4'>
    <FeatureTitle name="Renew payments" />
    <GoBackButton
          link="/parent/renewpayment/addpackage"
          action="Back"
          location="Select package to purchase"

        />
    <div className='py-4'>
        <PaymentOptionsCard />
    </div>
    </div>
    </PageContent>
  )
}

export default PackagePayment
