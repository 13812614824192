import { updateObject } from "../../shared/utility";
import {
  STUDENT_DASHBOARD_HOMEPAGE_GET_BOOKS_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_GET_BOOKS_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_GET_BOOKS_FAILED,
  //--------------------------
  STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_FAILED,
  //-----Save book Page views-
  STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_MARK_AS_VIEWED_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_MARK_AS_VIEWED_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_MARK_AS_VIEWED_FAILED,
} from "../actions/student.dashboard.library";
//--
const initialReducer = {
  student_dashboard_homepage_get_books: [],
  student_dashboard_homepage_get_books_loading: true,
  message: "",
  //--------------------------
  student_dashboard_homepage_get_book_page: [],
  student_dashboard_homepage_get_book_page_loading: true,
  message1: "",
  //-----Save book Page views-
  student_dashboard_homepage_get_book_page_mark_as_viewed: [],
  student_dashboard_homepage_get_book_page_mark_as_viewed_loading: true,
  message2: "",
};

function student_dashboard_homepage_get_books_init(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_books_loading: true,
  });
}
function student_dashboard_homepage_get_books_success(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_books: action.payload,
    student_dashboard_homepage_get_books_loading: false,
    message: "success"
  });
}
function student_dashboard_homepage_get_books_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    student_dashboard_homepage_get_books_loading: false,
  });
}
//--------------------------
function student_dashboard_homepage_get_book_page_init(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_book_page_loading: true,
  });
}
function student_dashboard_homepage_get_book_page_success(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_book_page: action.payload,
    student_dashboard_homepage_get_book_page_loading: false,
    message1: "success"
  });
}
function student_dashboard_homepage_get_book_page_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    student_dashboard_homepage_get_books_loading: false,
  });
}
//-----Save book Page views-
function student_dashboard_homepage_get_book_page_mark_as_viewed_init(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_book_page_mark_as_viewed_loading: true,
  });
}
function student_dashboard_homepage_get_book_page_mark_as_viewed_success(state, action) {
  return updateObject(state, {
    student_dashboard_homepage_get_book_page_mark_as_viewed: action.payload,
    student_dashboard_homepage_get_book_page_mark_as_viewed_loading: false,
    message2: "success"
  });
}
function student_dashboard_homepage_get_book_page_mark_as_viewed_failed(state, action) {
  return updateObject(state, {
    message2: action.payload,
    student_dashboard_homepage_get_book_page_mark_as_viewed_loading: false,
  });
}
const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case STUDENT_DASHBOARD_HOMEPAGE_GET_BOOKS_INIT:
      return student_dashboard_homepage_get_books_init(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_BOOKS_SUCCESS:
      return student_dashboard_homepage_get_books_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_BOOKS_FAILED:
      return student_dashboard_homepage_get_books_failed(state, action);
    //--------------------------
    case STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_INIT:
      return student_dashboard_homepage_get_book_page_init(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_SUCCESS:
      return student_dashboard_homepage_get_book_page_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_FAILED:
      return student_dashboard_homepage_get_book_page_failed(state, action);
    //-----Save book Page views-
    case STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_MARK_AS_VIEWED_INIT:
      return student_dashboard_homepage_get_book_page_mark_as_viewed_init(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_MARK_AS_VIEWED_SUCCESS:
      return student_dashboard_homepage_get_book_page_mark_as_viewed_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_GET_BOOK_PAGE_MARK_AS_VIEWED_FAILED:
      return student_dashboard_homepage_get_book_page_mark_as_viewed_failed(state, action);
    default:
      return state;
  }
};
export default reducer;
