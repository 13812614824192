import { calculateTimeFromDate } from "../../../shared/utility";
import { CardBody, CardTitle, SmallText } from "../../elements/text";
import { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Modal from "../../elements/modal/modal";
import PdfForLinkedDocs from "../../pages/student/library/pdfForLinkedDocs";

const Card = (props) => {
  const history = useHistory();
  const { type } = props;
  const [showVideoModal, setShowVideoModal] = useState(false);

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Adjust iframe dimensions when window is resized
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = useCallback(() => {
    switch (type) {
      case "Video":
        setShowVideoModal(true);
        break;
      case "Document":
        setShowVideoModal(true);
        break;
      case "Simulation":
        setShowVideoModal(true);
        break;
      default:
        break;
    }
  }, [history, type]);




  return showVideoModal ? (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="bg-white p-6 rounded-lg shadow-lg z-50">
        <button
          className="top-0 right-0 m-4 text-lg"
          style={{ color: "red", fontSize: "30px", marginLeft: "auto", marginRight: "auto", display: "block", cursor: "pointer" }}
          onClick={() => setShowVideoModal(false)}
        >
          &times;
        </button>

        <CardTitle name="" />
        <div className="responsive-iframe">

          {(type !== "Document") ? <iframe
            className="w-full h-full"
            style={{ width: parseInt(dimensions.width * 2 / 3), height: parseInt(dimensions.height * 2 / 3) }}
            src={props.metadata.url}
            title="video"
            allowfullscreen
            sandbox="allow-same-origin allow-scripts"
            frameBorder="0"
          ></iframe> : <PdfForLinkedDocs setShowVideoModal={setShowVideoModal} url={props.metadata.url} />}
        </div>
      </div>
    </div>
  ) : (
    <div
      className="my-2 flex flex-row-reverse items-start justify-between bg-white filter drop-shadow-md rounded-2xl cursor-pointer"
      onClick={handleClick}
    >
      <div className="w-20 h-28 relative rounded-2xl">
        <img
          src={props.imgsrc}
          alt=""
          className="absolute inset-0 w-full h-full object-cover rounded-2xl"
        />
      </div>
      <form className="p-2 flex flex-col justify-between">
        <div className="flex flex-col flex-wrap">
          <CardTitle name={props.title} alignment="left" />
          <CardBody name={props.type} color="red" alignment="left" />
        </div>
        <SmallText
          name={`${calculateTimeFromDate(props.createdAt)} ago`}
          color="gray-500"
          alignment="left"
        />
      </form>
    </div>
  )

};

export default Card;
