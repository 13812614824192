import { bring_subject_image_src } from "./programs";


export const subjectsFormatted = (data1: any) => {
  const subjects = []

  if (data1.length > 0 && data1[0]) {
    const data = data1[0];
    for (var i = 0; i < data.length; i++) {
      subjects.push({ value: data[i][0], displayValue: data[i][0] });

    }

  }
  return subjects;

};

export const subjectsFormattedWithUnits = (data1: any, subject) => {
  const units = []

  if (data1.length > 0 && data1[0]) {
    const data = data1[0];
    for (var i = 0; i < data.length; i++) {
      if (data[i][0] === subject) {
        for (var n = 0; n < data[i][1].length; n++) {
          units.push({ value: data[i][1][n].units, displayValue: data[i][1][n].units });
        }
      }
    }
  }
  return units;

};
export const subjectsFormattedWithUnits_Multisel = (data1: any, subject) => {
  const units = [];

  if (data1.length > 0 && data1[0]) {
    const data = data1[0];
    const unitSet = new Set(); // Use a Set to store unique units

    for (var i = 0; i < data.length; i++) {
      if (data[i][0] === subject) {
        for (var n = 0; n < data[i][1].length; n++) {
          const unit = data[i][1][n].units;
          if (!unitSet.has(unit)) {
            // If unit is not in the set, add it to the result and the set
            units.push({ value: unit, label: unit });
            unitSet.add(unit);
          }
        }
      }
    }
  }
  return units;
};



