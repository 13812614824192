import { updateObject } from "../../shared/utility";
import {
  STUDENT_DASHBOARD_HOMEPAGE_PROGRAMS_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_PROGRAMS_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_PROGRAMS_FAILED,
  //---student programs cbc
  STUDENT_DASHBOARD_HOMEPAGE_STUDENT_PROGRAMS_CBC_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_STUDENT_PROGRAMS_CBC_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_STUDENT_PROGRAMS_CBC_FAILED,

  //---Load extra curricular modules
  STUDENT_DASHBOARD_HOMEPAGE_LOAD_EXTRA_CURRICULAR_MODULES_INIT,
  STUDENT_DASHBOARD_HOMEPAGE_LOAD_EXTRA_CURRICULAR_MODULES_SUCCESS,
  STUDENT_DASHBOARD_HOMEPAGE_LOAD_EXTRA_CURRICULAR_MODULES_FAILED,
  
  
} from "../actions/student.dashboard.programs";
const initialReducer = {
  programs: [],
  programs_loading: true,
  message: "",
  
  student_programs_cbc: [],
  student_programs_cbc_loading: true,
  message0: "",

  load_extra_curricular_modules: [],
  load_extra_curricular_modules_loading: true,
  message1: ""
};

function student_programs_init(state, action) {
  return updateObject(state);
}
function student_programs_success(state, action) {
  return updateObject(state, {
    programs: action.payload,
    programs_loading: false,
    message: "success"
  });
}
function student_programs_fail(state, action) {
  return updateObject(state, {
    message: action.payload,
    programs_loading: true,
  });
}

//----students programs CBC
function student_student_programs_cbc_init(state, action) {
  return updateObject(state);
}
function student_student_programs_cbc_success(state, action) {
  
  return updateObject(state, {
    student_programs_cbc: action.payload,
    student_programs_cbc_loading: false,
    message0: "success"
  });
}
function student_student_programs_cbc_fail(state, action) {
  return updateObject(state, {
    message0: action.payload,
    student_programs_cbc_loading: true,
  });
}


//----Load extra curricular modules
function student_load_extra_curricular_modules_init(state, action) {
  return updateObject(state, {
    load_extra_curricular_modules_loading: true,
  });
}
function student_load_extra_curricular_modules_success(state, action) {
  
  return updateObject(state, {
    load_extra_curricular_modules: action.payload,
    load_extra_curricular_modules_loading: false,
    message1: "success"
  });
}
function student_load_extra_curricular_modules_fail(state, action) {
  return updateObject(state, {
    message1: action.payload,
    load_extra_curricular_modules_loading: false,
  });
}



const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case STUDENT_DASHBOARD_HOMEPAGE_PROGRAMS_INIT:
      return student_programs_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_PROGRAMS_SUCCESS:
      return student_programs_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_PROGRAMS_FAILED:
      return student_programs_fail(state, action);  
    //--Student programs CBC
    case STUDENT_DASHBOARD_HOMEPAGE_STUDENT_PROGRAMS_CBC_INIT:
      return student_student_programs_cbc_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_STUDENT_PROGRAMS_CBC_SUCCESS:
      return student_student_programs_cbc_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_STUDENT_PROGRAMS_CBC_FAILED:
      return student_student_programs_cbc_fail(state, action); 
    //--Load extra curricular modules
    case STUDENT_DASHBOARD_HOMEPAGE_LOAD_EXTRA_CURRICULAR_MODULES_INIT:
      return student_load_extra_curricular_modules_init(state, action);

    case STUDENT_DASHBOARD_HOMEPAGE_LOAD_EXTRA_CURRICULAR_MODULES_SUCCESS:
      return student_load_extra_curricular_modules_success(state, action);
    case STUDENT_DASHBOARD_HOMEPAGE_LOAD_EXTRA_CURRICULAR_MODULES_FAILED:
      return student_load_extra_curricular_modules_fail(state, action);    
    default:
      return state;
  }
};
export default reducer;
