import { CardBody, CardTitle, FeatureTitle } from "../../../../elements/text";
import Input from "../../../../elements/input";
import { Button, ButtonLink } from "../../../../elements/button";
import { useState } from "react";
import { useSelector } from "react-redux";

const RenewPackage = () => {
  const userEmail = useSelector((state) => state.auth.user);
  const [email, setEmail] = useState(userEmail);

  return (
    <div className="pt-2 px-2 bg-white md:h-tab-screen md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
      <FeatureTitle name="Renew Subcription" color="black" />

      <div className="relative overflow-x-auto sm:rounded-lg">
        <CardTitle name="Contact O'Genius" color="black" />
        <CardBody
          name="To upgrade your package, contact our sales team Email us at sales@ogeniuspriority.com or call us at +250786010169 or +250780792008"
          color="black"
        />
      
      </div>
    </div>
  );
};

export default RenewPackage;
