import { useEffect, useRef, useState } from "react";
import parse from "react-html-parser";
import { FaComments, FaFlask, FaLock, FaLockOpen } from "react-icons/fa";
import { AiOutlineLeftCircle, AiOutlineRightCircle } from "react-icons/ai";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { MdEditNote, MdMovie } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { changeChosenUnitSummarynotes } from "../../../../../store/actions/features/summarynotes";
import Comments from "../../../../common/features/Comments";
import VideosAndSimulations from "../../../../common/features/VideosAndSimulations";
import { Button, GoBackButton } from "../../../../elements/button";
import Input from "../../../../elements/input";
import { CardBody } from "../../../../elements/text";
// import pagecontent from '../../../guest/previewguest/pagecontent';
import EndOfResources from "../../../../common/features/EndOfResources";
import TextSelector from "../../../../elements/TextSelector";
import PageContent from "../../../guest/guesttest/pageContent";
import * as actions from "../../../../../store/actions/";
import ScrollToTop from "../../../../common/ScrollToTop";
import Topnavbar from "../topnavbar";
import Sidebar from "../sidebar";
import Header from "../../../../common/header/header";
import Spinner from "../../../../elements/spinner";

const SummaryNote = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const classcourse = useSelector((state) => state.previewpanda.classcourse);
  const [searchterm, setSearchterm] = useState("");
  const [data, setdata] = useState([]);
  const units = [
    { name: "Deserunt laboris", viewed: false },
    { name: "Adipisicing ", viewed: false },
    { name: "Dolore minim", viewed: false },
    { name: "Dolore ex ", viewed: false },
    { name: "Sint eiusmod fugiat.", viewed: false },
    { name: "Adipisicing occaecat", viewed: false },
    { name: "Dolore minimum", viewed: false },
    { name: "Dolore ex enim", viewed: false },
  ];
  const chosenUnit = useSelector(
    (state) => state.features.summarynotes.chosenUnit
  );
  const [showComments, setShowComments] = useState(false);
  const [showvideos, setShowvideos] = useState(false);
  const [vidSimDoc, setVidSimDoc] = useState("Videos");
  const [showUnits, setShowUnits] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [subject, setSubject] = useState("Biology");

  const selectedText = useSelector(
    (state) => state.languages.selectedCommentText
  );

  const panelDiv = useRef();

  const scrollTop = () => {
    panelDiv.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (params.courseId) {
      dispatch(actions.getOneClassCourses(params?.courseId));
      dispatch(actions.getClassCoursePages(params?.courseId));
    }
    scrollTop();
  }, []);
  // setdata(classcourse?.html_content);
  const pages = useSelector((state) => state.previewpanda.coursepages);
  const pagesLoading = useSelector(
    (state) => state.previewpanda.coursepagesLoading
  );
  const [index, setindex] = useState(0);
  return (
    <div className="scrollbar-hidden font-poppins h-screen">
      <Header />
      <div className="h-full w-full sm:flex">
        <div className="w-2/6">
          <Sidebar setindex={setindex} />
          <Topnavbar />
        </div>
        {pagesLoading ? (
          <div className="w-full flex justify-center">
            <Spinner color={"blue"} size={32} />
          </div>
        ) : (
          <div
            className={`${
              showComments || showvideos ? "relative" : ""
            } md:grid grid-cols-12 w-full min-h-screen-sec h-full coursecontent`}
          >
            <div
              ref={panelDiv}
              className={`${
                showUnits ? "hidden md:block" : ""
              } md:relative py-3 pl-10 pr-3 bg-white md:h-full max-h-myscreen-sec md:max-h-screen-sec col-span-12 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
            >
              <div>
                <div classname="w-full justify-end my-5">
                  <GoBackButton
                    link="/previewpanda"
                    action="Back"
                    location="Course Preview"
                  />
                </div>
                <CardBody
                  name={classcourse?.units}
                  color="blue"
                  additional="font-bold"
                />
                <TextSelector onSelection={() => setShowComments(true)}>
                  <div className="py-3">
                    {parse(pages[pageNumber]?.course_pages_data ?? "<p></p>")}
                    {pages.length - 1 === pageNumber ? (
                      <EndOfResources otherresources={[]} />
                    ) : null}
                  </div>
                </TextSelector>

                <div className="flex justify-between items-start mb-2">
                  <Button
                    name="Previous"
                    outline="true"
                    color="blue"
                    clicked={() => {
                      if (pageNumber > 0) {
                        setPageNumber((number) => number - 1);
                      }
                    }}
                  />

                  <CardBody
                    name={
                      pages.length > 0
                        ? `${pageNumber + 1}/${pages.length}`
                        : `${pageNumber}/${pages.length}`
                    }
                    color="blue"
                    additional="col-span-5 text-center"
                  />
                  <div className="flex flex-col">
                    <Button
                      name="Next"
                      outline="true"
                      color="blue"
                      clicked={() => {
                        if (pageNumber < pages.length - 1) {
                          scrollTop();
                          setPageNumber((number) => number + 1);
                        }
                        if (pageNumber === pages.length - 1) {
                          scrollTop();
                          setPageNumber(0);
                        }
                      }}
                    />
                    {pageNumber === pages.length - 1 ? (
                      <GoBackButton
                        link="/previewpanda"
                        action="Back"
                        location="Course Preview"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SummaryNote;
