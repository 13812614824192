/* eslint-disable react/style-prop-object */
import {
  CardTitle,
  CardBody,
  SectionTitle,
  CardSubText,
  SmallText,
  PageBody,
  PageTitle,
} from "./text";
import StudentIcon from "../../images/icons/students.svg";
import TeacherIcon from "../../images/icons/teachers.svg";
import ParentIcon from "../../images/icons/parents.svg";
import SchoolIcon from "../../images/icons/schools.svg";
import { ButtonLink, LoginButtonLink } from "./button";
import { HashLink } from "react-router-hash-link";
import { ImageIcon } from "./imageicon";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import mastercardImage from "../../images/Mastercard-logo.png";
import visaImage from "../../images/visa.png";
import paypalImage from "../../images/PayPal-Logo.png";
import mobileMoneyImage from "../../images/mtn-mobile-money-logo.png";
import airtelImage from "../../images/airtel.png";
import { useSelector } from "react-redux";
import { useState } from "react";
import { setChosenPackage } from "../../store/actions/payment";
import { useDispatch } from "react-redux";
import { useEffect, Component } from "react";
import Creditcard from "../common/paymentoptions/Card";
import Paypal from "../common/paymentoptions/Paypal";
import MobileMoney, {
  MobileMoneyExtra,
} from "../common/paymentoptions/MobileMoney";
import Momopay from "../common/paymentoptions/Momopay";
import {
  calculateTimeFromDate,
  calculateTimeFromDateFuture,
} from "../../shared/utility";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdDelete, MdEdit, MdPresentToAll } from "react-icons/md";
import { useTranslation } from "react-i18next";
import * as functions from "../../functions/commonfunctions";
import * as actions from "../../store/actions/";
import { bring_subject_image_src } from "../../functions/programs";
import Calendar from "short-react-calendar";
import Modal from "./modal/modal";

export const HomeCard = function (props) {
  return (
    <div className="flex flex-col align-center justify-center mx-4 space-y-4 min-w-36">
      <ImageIcon user={props.user} subtitle={true} />
      <CardBody name={props.body} alignment="center" />
    </div>
  );
};

export const LoginCard = function (props) {
  let homeCardUser = props.user.toLowerCase();

  let color = "";
  if (homeCardUser === "student") {
    color = "green";
  } else if (homeCardUser === "teacher") {
    color = "yellow";
  } else if (homeCardUser === "parent") {
    color = "gray-500";
  } else if (homeCardUser === "school") {
    color = "blue";
  } else if (homeCardUser === "creators") {
    color = "red";
  }
  return (
    <HashLink
      to="#login"
      className="flex flex-col align-center justify-center md:mx-6 space-y-4 cursor-pointer"
      onClick={() => props.clicked(props.user)}
    >
      <ImageIcon user={props.user} subtitle={false} />
      <LoginButtonLink
        name={props.buttonText}
        outline="false"
        color={color}
        alignment="center"
      />
    </HashLink>
  );
};
export const PricingCard = function (props) {
  let imgsrc = props.imgsrc;

  let homeCardUser = props.title.toLowerCase();
  let classes = "";
  if (homeCardUser === "student") {
    classes = "bg-green rounded-xl mx-auto p-6";
    imgsrc = StudentIcon;
  } else if (homeCardUser === "teacher") {
    classes = "bg-yellow rounded-xl mx-auto p-6";
    imgsrc = TeacherIcon;
  } else if (homeCardUser === "parent") {
    classes = "bg-gray-500 rounded-xl mx-auto p-6";
    imgsrc = ParentIcon;
  } else if (homeCardUser === "school") {
    classes = "bg-blue rounded-xl mx-auto p-6";
    imgsrc = SchoolIcon;
  } else {
    classes =
      "bg-transparent border-solid border-4 border-blue rounded-xl mx-auto p-6";
  }
  return (
    <div
      className="flex flex-col align-center justify-start mx-auto space-y-4 my-4"
      onClick={props.onClick}
    >
      <div className={classes}>
        <div className="w-full h-auto">
          <img
            alt={props.title}
            className="h-72px w-auto m-auto"
            src={imgsrc}
          />
        </div>
      </div>
      <div className="w-2/3 mx-auto flex flex-col align-start space-y-6">
        <CardTitle name={props.title} alignment="center" />
      </div>
    </div>
  );
};

export const TestimonyCard = function (props) {
  let imgsrc = props.src;

  return (
    <div className="flex flex-col md:flex-row align-center justify-around space-x-6 mx-auto">
      <div
        className="flex-shrink-0 rounded-full h-52 w-52 mx-auto mb-6 md:mb-0 bg-no-repeat bg-cover bg-center"
        style={{ backgroundImage: `url("${imgsrc}")` }}
      ></div>
      <div className="my-auto">
        <SectionTitle name={props.testimony} alignment="left" color="blue" />
        <CardBody name={props.person} alignment="left" color="white" />
        <CardBody name={props.position} alignment="left" color="white" />
      </div>
    </div>
  );
};

export const PartnerCard = function (props) {
  let imgsrc = props.logo_src;

  return (
    <div className="flex flex-col align-center justify-start my-4 space-y-4 mx-6">
      <div
        className="flex-shrink-0 h-28 w-28 mx-auto mb-6 md:mb-0 bg-no-repeat bg-contain bg-center"
        style={{ backgroundImage: `url("${imgsrc}")` }}
      ></div>
      <div className="mx-auto w-36">
        <CardBody name={props.partner} alignment="center" color="blue" />
      </div>
    </div>
  );
};

export const WhoisitforCard = function (props) {
  const { t } = useTranslation();
  let homeCardUser = props.user.toLowerCase();
  const capitalizedHomeCardUser =
    props?.user?.charAt(0).toUpperCase() + props?.user?.slice(1);
  let imgsrc = "";
  let classes = "";
  if (homeCardUser === "student") {
    classes = "bg-green rounded-xl w-2/3 mx-auto p-6";
    imgsrc = StudentIcon;
  } else if (homeCardUser === "teacher") {
    classes = "bg-yellow rounded-xl  w-2/3 mx-auto p-6";
    imgsrc = TeacherIcon;
  } else if (homeCardUser === "parent") {
    classes = "bg-gray-500 rounded-xl  w-2/3 mx-auto p-6";
    imgsrc = ParentIcon;
  } else if (homeCardUser === "school") {
    classes = "bg-blue rounded-xl  w-2/3 mx-auto p-6";
    imgsrc = SchoolIcon;
  }

  return (
    <div className="flex flex-col align-start justify-center w-full mx-auto space-y-4 my-4">
      <div className={classes}>
        <div className="w-full h-auto">
          <img
            alt={homeCardUser}
            className="h-72px w-auto m-auto"
            src={imgsrc}
          />
        </div>
      </div>
      <div className="w-2/3 mx-auto flex flex-col align-start space-y-6">
        <CardTitle
          name={capitalizedHomeCardUser}
          alignment="left"
          nosubtitle={false}
        />
        <CardSubText name={props.body} alignment="left" />
        <CardTitle
          name={t("What are the benefits to me?")}
          alignment="left"
          nosubtitle={false}
        />
        <ul className="list-disc list-inside">
          {props?.benefitlist?.map((recipe) => (
            <li>
              {" "}
              <CardSubText name={recipe} alignment="left" />
            </li>
          ))}
        </ul>
        <div>
          <ButtonLink
            page="enroll"
            name={t("Enroll")}
            outline="true"
            color="red"
          />
        </div>
      </div>
    </div>
  );
};

export const FeaturesCard = function (props) {
  const { t } = useTranslation();
  let imgsrc = props.imgsrc;
  let classes =
    "bg-transparent border-solid border-4 border-blue rounded-xl w-2/3 mx-auto p-6";

  return (
    <div className="flex flex-col align-center justify-start w-full mx-auto space-y-4 my-4">
      <div className={classes}>
        <div className="w-full h-auto">
          <img
            alt={props.feature}
            className="h-72px w-auto m-auto"
            src={imgsrc}
          />
        </div>
      </div>
      <div className="w-2/3 mx-auto flex flex-col align-start space-y-6">
        <CardTitle name={props.feature} alignment="left" />
        <CardSubText name={props.body} alignment="left" />

        <div>
          <ButtonLink
            page="enroll"
            name={t("Enroll")}
            outline="true"
            color="red"
          />
        </div>
      </div>
    </div>
  );
};

export const CurriculumCourseCard = (props) => {
  return (
    <div
      className="relative rounded-xl w-full mx-auto md:w-52 h-24 cursor-pointer"
      onClick={props.clicked}
    >
      <img
        className="w-full rounded-xl h-full object-cover object-center"
        src={props.image}
        alt=""
      />
      <div className="absolute inset-0 flex justify-center items-center">
        <SectionTitle name={props.name} color="white" />
      </div>
    </div>
  );
};

const PaymentOptionItem = (props) => {
  const _dashboard = props._dashboard;
  let item = null;
  switch (props.name) {
    case "Mobile Money":
      if (!_dashboard)
        item = <MobileMoney chosenPackage={props.chosenPackage} />;
      else item = <MobileMoneyExtra chosenPackage={props.chosenPackage} />;
      break;
    case "PayPal":
      item = <Paypal />;
      break;
    case "Credit/Debit Card":
      item = <Creditcard />;
      break;
    case "MoMoPay":
      item = <Momopay />;
      break;
    default:
      item = null;
  }
  return (
    <>
      <Modal></Modal>
      <div
        className={`flex justify-between items-center py-1 cursor-pointer hover:bg-gray-100 ${props.opened ? "bg-gray-100" : ""
          }`}
        onClick={() => {
          props.optionClicked();
        }}
      >
        <div className="flex space-x-2 items-center">
          <input type="radio" checked={props.opened} name="paymentoption" />
          <CardBody name={props.name} />
        </div>
        {props.opened ? <BiChevronUp /> : <BiChevronDown />}
      </div>
      {props.opened ? item : null}
    </>
  );
};

const AddMoreToPaymentItem = (props) => {
  return (
    <div className="flex justify-between items-center space-x-2">
      <div className="flex items-center space-x-2">
        <input
          type="checkbox"
          name="addmore"
          onChange={(event) => props.onChange(event.target.checked)}
        />
        <div>
          <CardTitle name={props.name} />
          <SmallText name={props.description} />
        </div>
      </div>
      <CardTitle name={`${props.amount} rwf`} />
    </div>
  );
};

export const PaymentOptionsCard = (props) => {
  const { _dashboard } = props ? props : false;
  const chosenPackage = useSelector((state) => state.payment.chosenPackage);
  const chosenProgram = useSelector((state) => state.payment.chosenProgram);
  const extrapackages = useSelector((state) => state.payment.extrapackages);
  const extrapackagesLoading = useSelector(
    (state) => state.payment.extrapackagesLoading
  );
  const dispatch = useDispatch();
  const [options, setOptions] = useState([
    { name: "Mobile Money", opened: false },
    { name: "MoMoPay", opened: false },
  ]);
  const [addMoreOptions, setAddMoreOptions] = useState(extrapackages);

  let amtToInt = parseInt(chosenPackage?.pricingamount, 10);
  const [amounttobepaid, setamounttobepaid] = useState(amtToInt);
  useEffect(() => {
    let newTotalMonthlyPlan = +amtToInt;
    dispatch(actions.getExtraPackages());
    addMoreOptions.forEach((option) => {
      if (option.checked) {
        newTotalMonthlyPlan += +option.amount;
      }
    });
    (props._dashboard === "true")
      ? dispatch(
        setChosenPackage({
          ...chosenPackage,
        })
      )
      : dispatch(
        setChosenPackage({
          ...chosenPackage,
          totalMonthlyPlan: newTotalMonthlyPlan,
        })
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addMoreOptions, dispatch]);

  const choseOptionHandler = (index) => {
    const newOptions = [...options];
    options.forEach((option) => {
      option.opened = false;
    });
    newOptions[index].opened = true;
    setOptions(newOptions);
  };

  // const momopayment = () => {
  //   const pyobject = {};
  //   console.log(chosenPackage);
  // };

  return (
    <div className="rounded-xl shadow p-5 w-100% lg:w-60% bg-white m-auto">
      <SectionTitle name="Payment Options" color="blue" />
      <CardTitle
        name={`${chosenPackage?.usertype ?? " "} Subscription - ${chosenPackage?.pricingname
          }`}
        color="red"
      />
      <div className="flex justify-between py-5">
        <div className="w-100% sm:w-100%">
          <div>
            <CardTitle name={chosenPackage?.name} />
            <div className="flex items-end">
              <PageTitle
                name={functions.numberWithCommas(
                  chosenPackage?.totalMonthlyPlan
                )}
                color="yellow"
              />
              <div className="ml-3">
                <CardTitle
                  name={`${chosenPackage?.pricingcurrency} ${chosenPackage?.pricingfrequency
                    ? chosenPackage?.pricingfrequency
                    : chosenPackage?.frequency
                    }`}
                  color="blue"
                />
              </div>
            </div>
          </div>
          <div className="py-3">
            <CardBody name="Please choose a payment option" />
            <div className="divide-y divide-gray-500">
              {options.map((option, index) => (
                <PaymentOptionItem
                  key={index}
                  {...option}
                  chosenPackage={chosenPackage}
                  optionClicked={() => choseOptionHandler(index)}
                  _dashboard={_dashboard}
                />
              ))}
            </div>
          </div>
        </div>
        <div style={{ display: "none" }}>
          <CardBody name="Add more to your package" />
          <div className="py-3 border-b border-gray-500">
            {addMoreOptions.map((option, index) => (
              <AddMoreToPaymentItem
                key={index}
                {...option}
                onChange={(value) => {
                  const newAddMoreOptions = [...addMoreOptions];
                  newAddMoreOptions[index].checked = value;
                  setAddMoreOptions(newAddMoreOptions);
                }}
              />
            ))}
          </div>
          <div className="flex justify-between">
            <CardTitle name="Total" />
            <CardTitle name={`${chosenPackage?.totalMonthlyPlan} rwf`} />
          </div>
        </div>
      </div>
      <div>
        <div className="flex">
          {/* <div className="w-15 h-7 m-1">
            <img
              className="w-full h-full object-cover object-center"
              src={mastercardImage}
              alt="img"
            />
          </div>
          <div className="w-15 h-7 m-1">
            <img
              className="w-full h-full object-cover object-center"
              src={visaImage}
              alt="img"
            />
          </div>
          <div className="w-15 h-7 m-1">
            <img
              className="w-full h-full object-cover object-center"
              src={paypalImage}
              alt="img"
            />
          </div> */}
          <div className="w-15 h-7 m-1">
            <img
              className="w-full h-full object-cover object-center"
              src={mobileMoneyImage}
              alt="img"
            />
          </div>
          <div className="w-15 h-7 m-1">
            <img
              className="w-full h-full object-cover object-center"
              src={airtelImage}
              alt="img"
            />
          </div>
        </div>
        <div className="flex justify-between space-x-4">
          <SmallText name="Refund policy" />
          <SmallText name="For any query please contact: 0786010169 / 0780792008" />
        </div>
      </div>
    </div>
  );
};

export const FeatureItemCard = (props) => {
  const [showButtons, setShowButtons] = useState(false);
  return (
    <div
      className={`z-10 m-2 h-28 flex align-start justify-between filter drop-shadow-md rounded-2xl cursor-pointer bg-white ${props.feature === "simulation" || props.feature === "summary note"
        ? "relative"
        : ""
        }`}
      onClick={() => props.clicked()}
    >
      <div className="p-2 flex flex-col justify-between">
        {props.feature === "summary note" && (
          <div>
            <CardTitle
              name={`${props.unit}`}
              additional="w-20 md:w-28 xl:w-40"
            />
            <CardTitle name={props.title} additional="w-20 md:w-28 xl:w-40" />
            <CardBody name={props.author} color="red" />
          </div>
        )}
        {props.feature === "simulation" && (
          <>
            <div>
              <CardTitle name={props.title} additional="w-20 md:w-28 xl:w-40" />
              <CardBody name="LAB" color="red" />
            </div>
            <SmallText
              name={`${calculateTimeFromDate(props.createdAt)} ago`}
              color="red"
            />
          </>
        )}
        {props.feature === "library" && (
          <div>
            <CardTitle name={props.title} additional="w-20" />
            <CardBody name={props.author} color="red" />
          </div>
        )}
        {props.feature === "past paper" && (
          <>
            <div>
              <CardTitle name={props.title.toUpperCase()} additional="w-20" />
              <CardBody name={props.time} />
              <CardBody name="NESA" color="blue" />
            </div>
            <SmallText
              name={`${calculateTimeFromDate(props.createdAt)} ago`}
              color="red"
            />
          </>
        )}
        {props.feature === "test" && (
          <>
            <div style={{ borderRadius: "5px" }} >
              <CardTitle name={props.title} additional="w-20 md:w-28 xl:w-40" />
              <CardBody name={`Senior ${props.level} - ${props.unit}`} />
              <CardBody name={props.type} color="red" />
              <div style={{
                marginTop: "-15px",
                marginLeft: "-20px",
                fontSize: "9px",
                padding: "5px",
                transform: "rotate(-90deg)",
                fontWeight: "bold",
                transformOrigin: "left top",
                whiteSpace: "nowrap"
              }}>
                Due {calculateTimeFromDateFuture(props.dateSince)}
              </div>
            </div>
          </>
        )}
        {props.feature === "challenge" && (
          <>
            <div>
              <CardTitle
                name={props.subject}
                additional="text-xs w-16 md:w-28 xl:w-40"
              />
              <CardBody
                name={`S${props.level} - ${props.unit}`}
                additional="text-sm"
              />
              <CardBody
                name={props.creator}
                color="red"
                additional="text-xs w-20 md:w-28 xl:w-40"
              />
            </div>
            {props.type === "launched" && (
              <SmallText
                name={`${calculateTimeFromDate(props.createdAt)} ago`}
                color="blue"
              />
            )}
          </>
        )}
      </div>
      <div
        className={`rounded-2xl w-20 md:w-28 ${props.feature === "simulation" ? "relative" : ""
          }`}
      >
        <img
          className="w-full h-full object-cover object-center rounded-2xl"
          src={
            bring_subject_image_src(props?.panda_test_map_2020_03_subject)
              ?.imgsrc ?? props.imgsrc
          }
          alt={
            props?.panda_test_map_2020_03_subject + "imgii and " + props?.imgsrc
          }
        />
        {props.feature === "simulation" && !showButtons && (
          <BsThreeDotsVertical
            className="z-50 absolute top-2 right-2"
            color="white"
            size="24"
            onClick={(e) => {
              e.stopPropagation();
              setShowButtons(true);
            }}
          />
        )}
        {/* {props.feature === "summary note" && props.type === "my notes" && (
          <BsThreeDotsVertical
            className="z-50 absolute top-2 right-2"
            color="white"
            size="24"
            onClick={(e) => {
              e.stopPropagation();
              setShowButtons(true);
            }}
          />
        )} */}
      </div>
      {props.feature === "simulation" && showButtons && (
        <div
          className="absolute right-4 top-8 shadow-xl bg-white mx-auto p-2 rounded-lg flex justify-center items-center space-x-4"
          onClick={props.clicked}
        >
          <MdPresentToAll size="24" />
          <CardBody name="Present" color="blue" />
        </div>
      )}
      {props.feature === "summary note" && showButtons && (
        <div className="absolute top-4 right-4 shadow-xl bg-white  mx-auto px-2 py-1 rounded-lg divide-y divide-gray-500">
          <div
            className="flex items-center space-x-4 p-1"
            onClick={(e) => {
              e.stopPropagation();
              props.edit();
            }}
          >
            <MdEdit size="24" color="#183F71" />
            <CardBody name="Edit" color="blue" />
          </div>
          <div className="flex items-center space-x-4 p-1">
            <MdDelete size="24" color="#BE1E2D" />
            <CardBody name="Delete" color="red" />
          </div>
        </div>
      )}
    </div>
  );
};

export const EventCard = (props) => {
  let { subjects, unit, createdAt, date, time, onClick } = props;
  return (
    <div
      onClick={onClick}
      className="bg-gray-200 px-4 py-3 filter rounded-md w-full"
    >
      <SmallText name={date} color="black" additional="float-right" />
      <CardTitle name={subjects} color="blue" />
      <CardSubText name={unit} color="blue" />
      <CardBody name={time} color="blue" />
    </div>
  );
};
export const SchoolEventCard = (props) => {
  let { title, unit, createdAt, date, time, onClick } = props;
  const x = new Date(date);
  const y = new Date();
  return (
    <div
      onClick={onClick}
      className={`bg-gray-200 px-4 py-2 filter rounded-md w-full border-l-4 border-${x <= y ? "blue" : "red"
        }`}
    >
      <CardSubText name={title} color="blue" additional="font-bold" />
      <br></br>
      <div className="w-full flex flex-row justify-between">
        <SmallText name={date} color="black" />
        <SmallText name={time} color="blue" />
      </div>
    </div>
  );
};

export const ClassDataCard = (props) => {
  return (
    <div className="bg-blue rounded-lg h-36 w-36 cursor-pointer flex flex-col items-center justify-center">
      <PageTitle name={props.number} color="white" />
      <PageBody name={props.label} color="white" />
    </div>
  );
};

export const DataCard = (props) => {
  return (
    <div
      className={`bg-${props.color} rounded-lg h-36 w-full cursor-pointer flex flex-col items-center justify-center shadow-lg`}
    >
      <PageTitle name={props.number} color="white" />
      <PageBody
        name={props.label}
        color="white"
        alignment="center"
        mobileAlignment="center"
      />
    </div>
  );
};

export const DynamicCard = (props) => {
  return (
    <div
      className={`bg-${props.color} rounded-lg cursor-pointer flex flex-col shadow-md p-4 m-2 ${props.additionalStyles}`}
    >
      {props.children}
    </div>
  );
};
export const BookInfoCard = (props) => {
  return (
    <div
      className={`bg-${props.color} rounded-lg cursor-pointer flex flex-col shadow-md p-4 m-2 ${props.additionalStyles}`}
    >
      {props.children}
    </div>
  );
};
export const BookInPurchaseCard = (props) => {
  return (
    <div
      className={`bg-${props.color} w-30% rounded-lg cursor-pointer flex flex-row shadow-md m-2 ${props.additionalStyles}`}
    >
      {props.children}
    </div>
  );
};
export const SubgroupCard = (props) => {
  return (
    <div
      className={`bg-${props.bgColor} cursor-pointer flex flex-row items-center justify-start p-1 m-1 ${props.additionalStyles}`}
    >
      {props.children}
    </div>
  );
};

export const ParentInfoCard = (props) => {
  const displayName = useSelector((state) => state.auth.displayName);
  const user = useSelector((state) => state.auth.user);
  const [value, onChange] = useState(new Date());
  // const state = {
  //   date: new Date(),
  // }

  return (
    <div className="flex flex-col items-center bg-white px-2 py-4 filter drop-shadow-md rounded-2xl w-full hidden sm:grid">
      <div className="flex flex-col items-center w-full divide-y mb-2">
        <div className="flex flex-row items-center justify-start w-90% border-gray-600 border-b-2 mb-2">
          <img
            className="inline object-cover rounded-full mr-5 shadow-md w-16 h-16 lg:w-20 lg:h-20 border-1 border-gray-600 mb-2"
            src={
              user?.avatarimage !== null
                ? user?.avatarimage
                : "/images/avatars/man1.png"
            }
            alt={"Profile"}
          />
          <div>
            <CardBody name={displayName ?? ""} color="gray-500" />
          </div>
        </div>
      </div>
      <div className="w-full overflow-x-hidden">
        <Calendar
          className="w-full flex flex-col items-start justify-start overflow-x-auto my-2 bg-white px-1 py-2 rounded-xl shadow text-blue text-sm font-semibold"
          onChange={onChange}
          value={value}
          calendarType="US"
          oneWeekCalendar={true}
        />
      </div>
    </div>
  );
};

export const ParentMessageCard = (props) => {
  return (
    <div
      className={`${props.bgColor} shadow p-2 flex flex-col cursor-pointer hover:shadow-md border-b-2 border-gray-100 z-10`}
      onClick={props.onClick}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center justify-center">
          <div className="flex items-center justify-center mr-2 col-span-2 w-10 h-10 bg-white rounded-full">
            Icon
          </div>
          <CardTitle name={props.sender} />
        </div>
        <div>
          <SmallText name={props.time} color="blue" />
        </div>
      </div>
      <div className="col-span-8">
        <CardBody name={props.content.substring(0, 100) + "..."} />
      </div>
      <div className="flex justify-end items-end right-0">
        {props.new && (
          <CardSubText name="New" color="yellow" additional="font-bold" />
        )}
      </div>
    </div>
  );
};

export const ParentPurchasedBookCard = (props) => {
  return (
    <div
      className="w-60 flex justify-between bg-white rounded-2xl m-2 cursor-pointer"
      onClick={() => props.clicked()}
    >
      <div className="flex flex-col p-2 w-2/4">
        <CardTitle name={props.name} />
        <CardBody name={props.author} />
        <CardBody name={props.datePublished} />
      </div>
      <div className="w-2/4">
        <img
          src={props.image}
          alt={props.name}
          className="w-full h-full object-cover object-center rounded-2xl"
        />
      </div>
    </div>
  );
};
