import React, { useEffect, useState } from 'react';
import Spinner from '../../../elements/spinner';
import axios from "../../../../axios-base";
import { useSelector } from 'react-redux';
import { Button } from '../../../elements/button';

const CustomModal = ({ isOpen, onClose, onRemove, currentData }) => {
    const [search, setSearch] = useState('');
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [pendingSelectedStudents, setPendingSelectedStudents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const userAuth = useSelector((state) => state.auth);


    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearch(value);
        //setFilteredStudents(students.filter((student) => student.toLowerCase().includes(value)));
    };

    const handleSelectStudent = (student) => {
        if (!selectedStudents.includes(student)) {
            const updatedSelected = [...selectedStudents, student];
            setSelectedStudents(updatedSelected);
            //onSelect(updatedSelected); // Call the onSelect callback
        }
    };

    const handleRemoveStudent = (student) => {
        if(window.confirm("Are you sure you want to remove this student?"))
        {
            
        }else{
            return;
        }
        setIsLoading(true);
        const dataIn = currentData;
        if (dataIn[0]) {

        } else {
            setFilteredStudents([])
            return;
        }
        const email = dataIn[3].match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/)[0];
        const academic_level = dataIn[0];
        const stream = dataIn[1];
        axios.put(
            `/opanda/cbc-combinations-streams/removeOneStudentFromClassEnrollment`,
            {
                school_id: userAuth.user.id,
                email: email,
                academic_level: academic_level,
                stream: stream,
                student: student
            },
            {
                headers: {
                    Authorization: `Bearer ${userAuth.token}`,
                    "Content-Type": "application/json",
                },
            }
        ).then((res) => {
            setIsLoading(false);
            const updatedSelected = selectedStudents.filter((s) => s !== student);
            setSelectedStudents(updatedSelected);
            setFilteredStudents([...filteredStudents, student]);
            alert("Student removed successfully");


        }).catch((err) => {
            setIsLoading(false);
            console.log(err.code)
        })

        // onRemove(updatedSelected); // Call the onRemove callback
    };

    const handleRemoveAll = () => {
        // Loop again to remove all students in selectedStudents
        if(window.confirm("Are you sure you want to remove all students?"))
        {
            setIsLoading(true);
            const dataIn = currentData;
            if (dataIn[0]) {
    
            } else {
                setFilteredStudents([])
                return;
            }
            const email = dataIn[3].match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/)[0];
            const academic_level = dataIn[0];
            const stream = dataIn[1];
            axios.put(
                `/opanda/cbc-combinations-streams/removeAllStudentFromClassEnrollment`,
                {
                    school_id: userAuth.user.id,
                    email: email,
                    academic_level: academic_level,
                    stream: stream,
                    selectedStudents: selectedStudents
                },
                {
                    headers: {
                        Authorization: `Bearer ${userAuth.token}`,
                        "Content-Type": "application/json",
                    },
                }
            ).then((res) => {
                setIsLoading(false);
                setSelectedStudents([]);
                setFilteredStudents([...filteredStudents, ...selectedStudents]);
                alert("Students removed successfully");
    
    
            }).catch((err) => {
                setIsLoading(false);
                console.log(err.code)
            })

        }
    };
    function validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    useEffect(() => {
        // Extract data        
        const dataIn = currentData;
        if (dataIn[0]) {

        } else {
            setFilteredStudents([])
            return;
        }
        setIsLoading(true);
        const email = dataIn[3].match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/)[0];
        const academic_level = dataIn[0];
        const stream = dataIn[1];
        axios.put(
            `/opanda/cbc-combinations-streams/getAllStudentsInSchoolAcademicLevel`,
            {
                school_id: userAuth.user.id,
                email: email,
                academic_level: academic_level,
                stream: stream
            },
            {
                headers: {
                    Authorization: `Bearer ${userAuth.token}`,
                    "Content-Type": "application/json",
                },
            }
        ).then((res) => {
            setIsLoading(false);
            setFilteredStudents(res.data.results.map((item) => {
                return `${item.names} ${(validateEmail(item.email)) ? `Email:(${item.email})` : `FB Id:(${item.email})`}`;
            }))


        }).catch((err) => {
            setIsLoading(false);
            console.log(err.code)
        })
        axios.put(
            `/opanda/cbc-combinations-streams/getAllStudentsInSchoolAcademicLevelLinked`,
            {
                school_id: userAuth.user.id,
                email: email,
                academic_level: academic_level,
                stream: stream
            },
            {
                headers: {
                    Authorization: `Bearer ${userAuth.token}`,
                    "Content-Type": "application/json",
                },
            }
        ).then((res) => {
            setIsLoading(false);
            setSelectedStudents(res.data.results.map((item) => {
                return `${item.names} ${(validateEmail(item.email)) ? `Email:(${item.email})` : `FB Id:(${item.email})`}`;
            }))


        }).catch((err) => {
            setIsLoading(false);
            console.log(err.code)
        })

    }, [isOpen]);

    const addStudentsToClass = (studentsList) => {
        const dataIn = currentData;
        if (dataIn[0]) {

        } else {
            return;
        }
        setIsLoading(true);
        const email = dataIn[3].match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/)[0];
        const academic_level = dataIn[0];
        const stream = dataIn[1];
        axios.put(
            `/opanda/cbc-combinations-streams/AddStudentsToClassEnrollment`,
            {
                school_id: userAuth.user.id,
                email: email,
                academic_level: academic_level,
                stream: stream,
                studentsList: studentsList
            },
            {
                headers: {
                    Authorization: `Bearer ${userAuth.token}`,
                    "Content-Type": "application/json",
                },
            }
        ).then((res) => {
            setIsLoading(false);
            alert("Students added successfully");
            setFilteredStudents(filteredStudents.filter((item) => !studentsList.includes(item)));
            setSelectedStudents([...selectedStudents, ...res.data.results]);



        }).catch((err) => {
            setIsLoading(false);
            console.log(err.code)
        })
        return [];
    }



    if (!isOpen) return null;



    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            {(!isLoading) ? <div className="relative w-full max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6">
                {/* Modal Header */}
                <div className="flex justify-between items-center border-b pb-2">
                    <h2 className="text-xl font-semibold">Select Students/View students</h2>
                    <button className="text-red-500" onClick={onClose}>X</button>
                </div>
                <div className="flex justify-between items-center">
                    <div >{`Class Teacher: ${currentData[3]}`}</div>
                    <div>{`Academic level: ${currentData[0]}`}</div>
                    <div>{`${currentData[1]}`}</div>
                </div>

                {/* Search and Select */}
                <div className="mt-4">
                    <input
                        type="text"
                        placeholder="Search for students"
                        className="border p-2 rounded w-full focus:ring-2 focus:ring-blue-400 outline-none"
                        value={search}
                        onChange={handleSearch}
                    />
                    <div className="mt-2 h-48 overflow-y-auto border rounded p-2">
                        <div className="rounded p-2 flex justify-end w-full">

                            <Button
                                name="Add all selected to class"
                                outline="true"
                                color="blue"
                                clicked={async () => {
                                    if (pendingSelectedStudents.length === 0) return [];
                                    setSelectedStudents(await addStudentsToClass([...pendingSelectedStudents]));
                                    setPendingSelectedStudents([]);
                                }}
                            />
                            <div style={{ width: "10px" }}></div>
                            <Button
                                name="Add the whole list to class"
                                outline="true"
                                color="blue"
                                clicked={async () => {
                                    if ([...filteredStudents.filter((student) => (student.toLowerCase().includes(search) && search !== "") || search == "").filter((item) => !selectedStudents.includes(item))].length === 0) return [];
                                    setSelectedStudents(await addStudentsToClass([...filteredStudents.filter((student) => (student.toLowerCase().includes(search) && search !== "") || search == "").filter((item) => !selectedStudents.includes(item))]));
                                    setPendingSelectedStudents([]);
                                    setFilteredStudents([]);
                                }}
                            />
                        </div>


                        {filteredStudents.length ? (
                            filteredStudents.filter((student) => (student.toLowerCase().includes(search) && search !== "") || search == "").filter((item) => !selectedStudents.includes(item)).map((student) => (
                                <div key={student} className="flex justify-between items-center p-1 hover:bg-gray">
                                    <div style={{ width: "230px" }} className='items-center'><span>{student}</span></div>
                                    <div className='items-center'><button
                                        className="text-blue hover:text-blue"
                                        onClick={async () => {
                                            if ([student].length === 0) return [];
                                            await addStudentsToClass([student])
                                        }
                                        }
                                    >
                                        Add
                                    </button></div>
                                    <div className='items-center'> <input
                                        type="checkbox"
                                        className="form-checkbox h-5 w-5 text-blue"
                                        onChange={(e) => {
                                            if (!pendingSelectedStudents.includes(student) && e.target.checked) {
                                                setPendingSelectedStudents([...pendingSelectedStudents, student])
                                            } else {
                                                setPendingSelectedStudents(pendingSelectedStudents.filter((item) => item !== student))
                                            }
                                        }}
                                    /></div>
                                </div>
                            ))
                        ) : (
                            <p>No students found</p>
                        )}
                    </div>
                </div>

                {/* Selected Students */}
                <div className="mt-4">
                    <h3 className="text-lg font-semibold mb-2"> Students in this class</h3>
                    <div className="h-48 overflow-y-auto border rounded p-2">
                        {selectedStudents.length ? (
                            selectedStudents.map((student) => (
                                <div key={student} className="flex justify-between items-center p-1 hover:bg-gray-100">
                                    <span>{student}</span>
                                    <button
                                        className="text-red-500 hover:text-red-700"
                                        onClick={() => handleRemoveStudent(student)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            ))
                        ) : (
                            <p>No students selected</p>
                        )}
                    </div>

                    {/* Remove All Button */}
                    {selectedStudents.length > 0 && (
                        <button
                            className="text-red-500 mt-2 hover:text-red-700"
                            onClick={handleRemoveAll}
                        >
                            Remove All
                        </button>
                    )}
                </div>
            </div> : <Spinner size="20" color="blue" />}
        </div>
    );
};

export default CustomModal;
