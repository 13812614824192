import { CardBody, CardTitle } from '../text';

const DayItem = (props) => {
  return (
    <div
      className="flex flex-col justify-center items-center cursor-pointer"
      onClick={props.clicked}
    >
      <div
        className={`flex justify-center items-center h-8 w-8 rounded-full border-red ${
          props.selected
            ? 'bg-red text-white font-bold'
            : 'bg-transparent text-red border border-red'
        }`}
      >
        {props.date}
      </div>
      {props.selected ? (
        <CardTitle name={props.day} />
      ) : (
        <CardBody name={props.day} />
      )}
    </div>
  );
};

export default DayItem;
