import axios from "../../axios-base";
import ValidatingToken from "../../functions/validatingtoken.js";

export const STUDENT_GENERATE_OFFLINE_TOKEN_INIT =
  "STUDENT_GENERATE_OFFLINE_TOKEN_INIT";
export const STUDENT_GENERATE_OFFLINE_TOKEN_SUCCESS =
  "STUDENT_GENERATE_OFFLINE_TOKEN_SUCCESS";
export const STUDENT_GENERATE_OFFLINE_TOKEN_FAILED =
  "STUDENT_GENERATE_OFFLINE_TOKEN_FAILED";

export function school_generate_offline_token_init() {
  return {
    type: STUDENT_GENERATE_OFFLINE_TOKEN_INIT,
  };
}
export function school_generate_offline_token_success(data) {
  return {
    type: STUDENT_GENERATE_OFFLINE_TOKEN_SUCCESS,
    payload: data,
  };
}
export function school_generate_offline_token_failed(error) {
  return {
    type: STUDENT_GENERATE_OFFLINE_TOKEN_FAILED,
    payload: error,
  };
}

export function generateOfflineToken(data) {
  return (dispatch) => {
    dispatch(school_generate_offline_token_init());
    axios
      .post(
        `/opanda/user-token/generateOfflineToken`,
        {user_id: data.user_id},
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(school_generate_offline_token_success(response.data.results));
      })
      .catch((err) => {
        dispatch(school_generate_offline_token_failed(err.message));
      });
  };
}