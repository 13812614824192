import Help from '../../../components/pages/teacher/user/help/';
import Layout from '../../../components/common/Layout';

const TeacherHelpContainer = () => {
  return (
    <Layout>
      <Help />
    </Layout>
  );
};

export default TeacherHelpContainer;