import { connect } from "react-redux";

import { CardBody, CardSubText, CardTitle } from "../../elements/text";

import { store } from "../../../store/store";
import { useHistory } from "react-router-dom";
import { calculateTimeFromDate } from "./../../../shared/utility";
import { BsCheckCircleFill, BsQuestionCircleFill } from "react-icons/bs";
import { SmallText } from "./../../elements/text";
import { useState } from "react";

export const ContentCard = function (props) {
  const history = useHistory();  
  if (props.type === "one") {
    let { id, unit, subject, imgsrc, tag, created_at } = props.data;
    const { showcontentmodal } = props;
    const handleClick = () => {
      if (props.guest) {
        history.push(`/previewpanda/guest/${id}`);
      } else {
        showcontentmodal("block");
      }
    };

    return (
      <div
        className="flex flex-row-reverse align-start justify-between bg-gray-100  filter drop-shadow-md rounded-2xl cursor-pointer"
        onClick={handleClick}
      >
        <div className={`w-96px relative rounded-2xl`}>
          <img
            src={imgsrc}
            alt=""
            className="absolute inset-0 w-full h-full object-cover rounded-2xl"
          />
        </div>
        <form className="p-6 flex flex-col justify-between">
          <div className="flex flex-col flex-wrap">
            <CardTitle
              name={unit}
              color="blue"
              alignment="left"
              additional="w-32"
            />
            <CardBody name={subject} color="black" alignment="left" />
            <CardSubText name={tag} color="red" alignment="left" />
          </div>

          {/* <p className="text-sm text-gray-500">{`${calculateTimeFromDate(created_at)} ago`}</p> */}
        </form>
      </div>
    );
  } else if (props.type === "two") {
    let {
      panda_books_name,
      panda_books_author,
      price,
      panda_books_book_type,
      panda_books_date,
    } = props.data;
    let bookdata = props.data;
    const { showcontentmodal } = props;
    return (
      <div
        className="bg-gray-100 filter drop-shadow-md rounded-2xl cursor-pointer"
        onClick={() => {
          bookdata.panda_books_name=(bookdata.panda_books_name.length > 30) ? bookdata.panda_books_name?.substring(0, 30) + "..." : bookdata.panda_books_name
          showcontentmodal("block", bookdata);
        }}
      >
        {" "}
        <div className="flex flex-col flex-wrap m-3 space-y-3">
          <div className="flex flex-col flex-wrap">
            {<CardTitle
              name={(bookdata.panda_books_name.length > 10) ? bookdata.panda_books_name?.substring(0, 10) + "..." : bookdata.panda_books_name}
              color="blue"
              alignment="left"
            />}
            <CardSubText
              name={bookdata.panda_books_author}
              color="black"
              alignment="left"
            />
            {/*<CardSubText
              name={
                bookdata.panda_books_book_type + " " + bookdata.panda_books_date
              }
              color="gray-500"
              alignment="left"
            />*/}
            <CardSubText
              name={
                "Book"
              }
              color="gray-500"
              alignment="left"
            />
          </div>
          {/*<CardTitle name={"Rwf 5,000"} color="blue" alignment="left" />*/}
        </div>
      </div>
    );
  } else if (props.type === "three") {
    let moduledata = props.data;
    const { showcontentmodal } = props;
    return (
      <div
        className="flex flex-col items-center justify-center flex-wrap bg-gray-100 h-36 filter drop-shadow-md rounded-2xl overflow-ellipsis overflow-hidden cursor-pointer"
        onClick={() => {
          showcontentmodal("block", moduledata);
        }}
      >
        <CardBody
          name={moduledata?.name}
          color="black"
          alignment="center"
          additional="overflow-ellipsis overflow-hidden"
        />
      </div>
    );
  } else if (props.type === "endofrsc") {
    let { title, unit, imgsrc, createdAt, type, watched } = props.data;
    return (
      <div className="m-2 h-28 flex align-start justify-between filter drop-shadow-md rounded-2xl cursor-pointer bg-white">
        <div className="p-2 flex flex-col justify-between">
          <div>
            <CardTitle name={title} additional="w-20" />
            <CardBody name={`${unit}`} />
            <CardBody name={type} color="red" />
          </div>
          {type !== "Test" && (
            <SmallText
              name={`${calculateTimeFromDate(createdAt)} ago`}
              color="red"
            />
          )}
        </div>
        <div className="relative rounded-2xl w-20">
          <img
            className="w-full h-full object-cover object-center rounded-2xl"
            src={imgsrc}
            alt="img"
          />
          {watched ? (
            <BsCheckCircleFill
              color="#BE1E2D"
              className="absolute top-2 right-2 z-10 bg-white rounded-full"
            />
          ) : (
            <BsQuestionCircleFill
              color="#183F71"
              className="absolute top-2 right-2 z-10 bg-white rounded-full"
            />
          )}
        </div>
      </div>
    );
  } else if (props.type === "purchasedbooks") {
    let { id, title, author, imageSource, datePublished, tag, created_at } = props.data;
    const { showcontentmodal } = props;
    const handleClick = () => {
      if (props.guest) {
        history.push(`/previewpanda/guest/${id}`);
      } else {
        showcontentmodal("block");
      }
    };

    return (
      <div
        className="flex flex-row-reverse align-start justify-between bg-gray-100  filter drop-shadow-md rounded-2xl cursor-pointer"
        onClick={handleClick}
      >
        <div className={`w-96px relative rounded-2xl`}>
          <img
            src={imageSource}
            alt=""
            className="absolute inset-0 w-full h-full object-cover rounded-2xl"
          />
        </div>
        <form className="p-2 flex flex-col justify-between">
          <div className="flex flex-col flex-wrap">
            <CardTitle
              name={title}
              color="blue"
              alignment="left"
              additional="w-32"
            />
            <CardBody name={author} color="black" alignment="left" />
            <CardSubText name={datePublished} color="red" alignment="left" />
          </div>

          {/* <p className="text-sm text-gray-500">{`${calculateTimeFromDate(created_at)} ago`}</p> */}
        </form>
      </div>
    );
  }
};

// export default SideBar;
const mapStateToProps = (state) => {
  return {
    visibility: state.mobilesidebar,
    tabselected: state.tabselected,
    contentmodalstatus: state.contentmodalstatus,
    modalcontent: state.modalcontent,
  };
};
const mapDispatchToProps = () => {
  return {
    showcontentmodal: (status, modalcontent) =>
      store.dispatch({
        type: "SHOW_CONTENT_MODAL",
        payload: status,
        modalcontent: modalcontent,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContentCard);