import { FeatureTitle, SectionTitle } from "../../../../elements/text";
import Notifications from "./notifications";
import AccountInformation from "./accountinformation";

import { BiDotsVertical } from "react-icons/bi";
import { useState } from "react";
import SideDrawer from "../../../../elements/sideDrawer";
import SideBar from "./sidebar";
import { useSelector } from "react-redux";

const PageContent = () => {
  const settingsItems = useSelector((state) => state.Settings.settingsItems);
  const selectedSettingsItem = settingsItems.filter(
    (item) => item.selected === true
  );
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  let Tab = Notifications;
  switch (selectedSettingsItem[0].title) {
    case "Notifications":
      Tab = Notifications;
      break;
    case "Account information":
      Tab = AccountInformation;
      break;

    default:
      Tab = Notifications;
      break;
  }

  return (
    <div className="bg-white-blue md:flex w-full">
      <div className="block md:hidden">
        <SideDrawer
          show={showSideDrawer}
          closed={() => setShowSideDrawer(false)}
        >
          <SideBar
            name="Settings"
            items={settingsItems}
            closed={() => setShowSideDrawer(false)}
          />
        </SideDrawer>
      </div>
      <div className="w-25% bg-gray-100 p-3 hidden md:block">
        <FeatureTitle name="Settings" alignment="right" />
        <SideBar
          name="Settings"
          items={settingsItems}
          closed={() => setShowSideDrawer(false)}
        />
      </div>
      <div className="md:w-75% bg-white-blue">
        <div className="flex justify-between items-center">
          <BiDotsVertical
            className="cursor-pointer block md:hidden"
            color="#183F71"
            onClick={() => setShowSideDrawer(true)}
          />
        </div>
        <Tab />
      </div>
    </div>
  );
};

export default PageContent;
