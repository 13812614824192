import Header from '../components/common/header/header';
import Recoverpasswordform from '../components/pages/landing/forgotpassword/recoverpasswordform';
import Footer from '../components/common/footer';
import ScrollToTop from '../components/common/ScrollToTop';

const Forgotpassword = () => {
  return (
    <div>
      <Header />
      <Recoverpasswordform />
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Forgotpassword;
