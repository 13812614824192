import { updateObject } from "../../utils/updateObject";
import { GET_FILES_FAIL, 
    GET_FILES_START, 
    GET_FILES_SUCCESS, 
    GET_ONE_FILES_SUCCESS } from "../actions/files";

const initialReducer = {
  files: [],
  filesError: null,
  filesLoading: false,
  file: null,
};

const getFilesStart = (state, action) => {
  return updateObject(state, {
    files: [],
    filesError: null,
    filesLoading: true,
    file: null,
  });
};

const getFilesSuccess = (state, action) => {
  return updateObject(state, {
    files: action.files,
    filesError: null,
    filesLoading: false,
    file: null,
  });
};

const getFilesFail = (state, action) => {
  return updateObject(state, {
    files: [],
    filesError: action.error,
    filesLoading: false,
    file: null,
  });
};

const getOneFilesSuccess = (state, action) => {
  return updateObject(state, {
    files: [],
    filesError: null,
    filesLoading: false,
    file: action.file,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case GET_FILES_START:
      return getFilesStart(state, action);
    case GET_FILES_SUCCESS:
      return getFilesSuccess(state, action);
    case GET_ONE_FILES_SUCCESS:
      return getOneFilesSuccess(state, action);
    case GET_FILES_FAIL:
      return getFilesFail(state, action);
    default:
      return state;
  }
};

export default reducer;
