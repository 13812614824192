

export const getQuestionOfTheDay = (mapRes: any) => {
  if (mapRes.length > 0 && mapRes[1]) {
    //---
    let question = [mapRes[0].panda_questions_auto_id, mapRes[0].panda_questions_auto_question_data];
    let ans_pool = [];
    try {
      for (var i = 0; i < mapRes[1][0].length; i++) {
        ans_pool.push([mapRes[1][0][i].panda_auto_questions_answers_id, mapRes[1][0][i].panda_auto_questions_answers_ans_data]);

      }
    } catch (err) {

    }

    return [question, ans_pool, mapRes[1][1]]
  }

  //console.log(JSON.stringify(mapRes[1]));

  return [];

};

export function decodeHTMLEntities(text) {
  // var textArea = document.createElement('textarea');
  // textArea.innerHTML = text;
  // return textArea.value;
  return "";
}

