import axios from "../../axios-base";

export const GET_FILES_START = "GET_FILES_START";
export const GET_FILES_SUCCESS = "GET_FILES_SUCCESS";
export const GET_ONE_FILES_SUCCESS =
  "GET_ONE_FILES_SUCCESS";
export const GET_FILES_FAIL = "GET_FILES_FAIL";

export const getFilesStart = () => {
    return {
      type: GET_FILES_START,
    };
  };
  
  export const getFilesSuccess = (files) => {
    return {
      type: GET_FILES_SUCCESS,
      files: files,
    };
  };
  
  export const getOneFilesSuccess = (file) => {
    return {
      type: GET_ONE_FILES_SUCCESS,
      file: file,
    };
  };
  
  export const getFilesFail = (error) => {
    return {
      type: GET_FILES_FAIL,
      error: error,
    };
  };

  export const uploadFilesInText = (object) => {
    return (dispatch) => {
      dispatch(getFilesStart());
      axios
        .post(`/opanda/files/upload-in-text`, object)
        .then((response) => {
          dispatch(getFilesSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getFilesFail(err.message));
        });
    };
  };