
import { PageTitle, PageBody } from "../elements/text";

// Import Swiper React components

const Hero = (props) => {
  let classes = "bg-"+props.page+" bg-center bg-cover bg-no-repeat h-full";
  
  return (
        <div className={classes}>
          <div className="bg-blue bg-opacity-25 h-full w-full flex flex-col align-start  justify-center">
            <div className="max-w-7xl max-h-7xl mx-auto px-8">
              <div className="w-full mx-auto">
                {/* <div className="w-full mx-auto mb-8"> */}
                  <PageTitle
                    name={props.title}
                    alignment="center"
                    color="white" mobileAlignment="center"
                  />
                  <PageBody
                    color="white"
                    name={props.body}
                    alignment="center"  mobileAlignment="center"
                  />
                {/* </div> */}
              
              </div>
            </div>
          </div>
        </div>
     
   
  );
};
export default Hero;
