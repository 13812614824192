import { updateObject } from "../../shared/utility";
import {
  //----Ongoing  challenges
  STUDENT_DASHBOARD_ONGOING_CHALLENGES_INIT,
  STUDENT_DASHBOARD_ONGOING_CHALLENGES_SUCCESS,
  STUDENT_DASHBOARD_ONGOING_CHALLENGES_FAILED,
  //----my launched challenges-----------
  STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_INIT,
  STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_SUCCESS,
  STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_FAILED,
  //----my attempted challenges-----------
  STUDENT_DASHBOARD_ATTEMPTED_CHALLENGES_INIT,
  STUDENT_DASHBOARD_ATTEMPTED_CHALLENGES_SUCCESS,
  STUDENT_DASHBOARD_ATTEMPTED_CHALLENGES_FAILED,
  //----Attempt a challenge-----------
  STUDENT_DASHBOARD_ATTEMPT_A_CHALLENGE_INIT,
  STUDENT_DASHBOARD_ATTEMPT_A_CHALLENGE_SUCCESS,
  STUDENT_DASHBOARD_ATTEMPT_A_CHALLENGE_FAILED,
  //----Get My launched attempt answersheet
  STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_ANSWER_SHEET_INIT,
  STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_ANSWER_SHEET_SUCCESS,
  STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_ANSWER_SHEET_FAILED,
  //----Get challenge attempt results
  STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGES_RESULTS_INIT,
  STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGES_RESULTS_SUCCESS,
  STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGES_RESULTS_FAILED,
  //----Get challenge attempt answer sheet
  STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGE_ANSWER_SHEET_INIT,
  STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGE_ANSWER_SHEET_SUCCESS,
  STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGE_ANSWER_SHEET_FAILED,

} from "../actions/student.attempt.challenges";
const initialReducer = {
  //----ongoing challenges-----------
  dashboard_ongoing_challenges: [],
  dashboard_ongoing_challenges_loading: true,
  message: "",
  //----my launched challenges-----------
  dashboard_my_launched_challenges: [],
  dashboard_my_launched_challenges_loading: true,
  message0: "",
  //----my attempted challenges-----------
  dashboard_attempted_challenges: [],
  dashboard_attempted_challenges_loading: true,
  message1: "",
  //----Attempt a challenge-----------
  dashboard_attempt_a_challenge: [],
  dashboard_attempt_a_challenge_loading: true,
  message2: "",
  //----Get My launched attempt answersheet--MY_LAUNCHED_CHALLENGES_ANSWER_SHEET
  dashboard_my_launched_challenges_answer_sheet: [],
  dashboard_my_launched_challenges_answer_sheet_loading: true,
  message3: "",
  //----Get challenge attempt results
  dashboard_my_attempted_challenges_results: [],
  dashboard_my_attempted_challenges_results_loading: true,
  message4: "",
  //----Get challenge attempt answer sheet
  dashboard_my_attempted_challenge_answer_sheet: [],
  dashboard_my_attempted_challenge_answer_sheet_loading: true,
  message5: "",
};
//----ongoing challenges-----------
function student_dashboard_ongoing_challenges_init(state, action) {
  return updateObject(state, {
    dashboard_ongoing_challenges_loading: true,
    dashboard_ongoing_challenges: []
  });
}
function student_dashboard_ongoing_challenges_success(state, action) {
  return updateObject(state, {
    dashboard_ongoing_challenges: action.payload,
    dashboard_ongoing_challenges_loading: false,
    message: "success"
  });
}
function student_dashboard_ongoing_challenges_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
    dashboard_ongoing_challenges_loading: false,
  });
}
//----my launched challenges-----------
function student_dashboard_my_launched_challenges_init(state, action) {
  return updateObject(state, {
    dashboard_my_launched_challenges_loading: true,
    dashboard_my_launched_challenges: []
  });
}
function student_dashboard_my_launched_challenges_success(state, action) {
  return updateObject(state, {
    dashboard_my_launched_challenges: action.payload,
    dashboard_my_launched_challenges_loading: false,
    message0: "success"
  });
}
function student_dashboard_my_launched_challenges_failed(state, action) {
  return updateObject(state, {
    message0: action.payload,
    dashboard_my_launched_challenges_loading: false,
  });
}
//----my attempted challenges-----------
function student_dashboard_attempted_challenges_init(state, action) {
  return updateObject(state, {
    dashboard_attempted_challenges_loading: true,
    dashboard_attempted_challenges: []
  });
}
function student_dashboard_attempted_challenges_success(state, action) {
  return updateObject(state, {
    dashboard_attempted_challenges: action.payload,
    dashboard_attempted_challenges_loading: false,
    message1: "success",
  });
}
function student_dashboard_attempted_challenges_failed(state, action) {
  return updateObject(state, {
    message1: action.payload,
    dashboard_attempted_challenges_loading: false,
  });
}
//----Attempt a challenge-----------
function student_dashboard_attempt_a_challenge_init(state, action) {
  return updateObject(state, {
    dashboard_attempt_a_challenge_loading: true,
    dashboard_attempt_a_challenge: []
  });
}
function student_dashboard_attempt_a_challenge_success(state, action) {
  return updateObject(state, {
    dashboard_attempt_a_challenge: action.payload,
    dashboard_attempt_a_challenge_loading: false,
    message2: "success",
  });
}
function student_dashboard_attempt_a_challenge_failed(state, action) {
  return updateObject(state, {
    message2: action.payload,
    dashboard_attempt_a_challenge_loading: false,
  });
}
//----Get My launched attempt answersheet
function student_dashboard_my_launched_challenges_answer_sheet_init(state, action) {
  return updateObject(state, {
    dashboard_my_launched_challenges_answer_sheet_loading: true,
    dashboard_my_launched_challenges_answer_sheet: []
  });
}
function student_dashboard_my_launched_challenges_answer_sheet_success(state, action) {
  return updateObject(state, {
    dashboard_my_launched_challenges_answer_sheet: action.payload,
    dashboard_my_launched_challenges_answer_sheet_loading: false,
    message3: "success",
  });
}
function student_dashboard_my_launched_challenges_answer_sheet_failed(state, action) {
  return updateObject(state, {
    message3: action.payload,
    dashboard_my_launched_challenges_answer_sheet_loading: false,
  });
}
//----Get challenge attempt results
function student_dashboard_my_attempted_challenges_results_init(state, action) {
  return updateObject(state, {
    dashboard_my_attempted_challenges_results_loading: true,
    dashboard_my_attempted_challenges_results: []
  });
}
function student_dashboard_my_attempted_challenges_results_success(state, action) {
  return updateObject(state, {
    dashboard_my_attempted_challenges_resultst: action.payload,
    dashboard_my_attempted_challenges_results_loading: false,
    message4: "success",
  });
}
function student_dashboard_my_attempted_challenges_results_failed(state, action) {
  return updateObject(state, {
    message4: action.payload,
    dashboard_my_attempted_challenges_results_loading: false,
  });
}
//----Get challenge attempt answer sheet
function student_dashboard_my_attempted_challenge_answer_sheet_init(state, action) {
  return updateObject(state, {
    dashboard_my_attempted_challenge_answer_sheet_loading: true,
    dashboard_my_attempted_challenge_answer_sheet: []
  });
}
function student_dashboard_my_attempted_challenge_answer_sheet_success(state, action) {
  return updateObject(state, {
    dashboard_my_attempted_challenge_answer_sheet: action.payload,
    dashboard_my_attempted_challenge_answer_sheet_loading: false,
    message5: "success",
  });
}
function student_dashboard_my_attempted_challenge_answer_sheet_failed(state, action) {
  return updateObject(state, {
    message5: action.payload,
    dashboard_my_attempted_challenge_answer_sheet_loading: false,
  });
}
const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    //----ongoing challenges-----------
    case STUDENT_DASHBOARD_ONGOING_CHALLENGES_INIT:
      return student_dashboard_ongoing_challenges_init(state, action);

    case STUDENT_DASHBOARD_ONGOING_CHALLENGES_SUCCESS:
      return student_dashboard_ongoing_challenges_success(state, action);
    case STUDENT_DASHBOARD_ONGOING_CHALLENGES_FAILED:
      return student_dashboard_ongoing_challenges_failed(state, action);
    //----my launched challenges-----------
    case STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_INIT:
      return student_dashboard_my_launched_challenges_init(state, action);

    case STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_SUCCESS:
      return student_dashboard_my_launched_challenges_success(state, action);
    case STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_FAILED:
      return student_dashboard_my_launched_challenges_failed(state, action);
    //----my attempted challenges-----------
    case STUDENT_DASHBOARD_ATTEMPTED_CHALLENGES_INIT:
      return student_dashboard_attempted_challenges_init(state, action);

    case STUDENT_DASHBOARD_ATTEMPTED_CHALLENGES_SUCCESS:
      return student_dashboard_attempted_challenges_success(state, action);
    case STUDENT_DASHBOARD_ATTEMPTED_CHALLENGES_FAILED:
      return student_dashboard_attempted_challenges_failed(state, action);
    //----Attempt a challenge-----------
    case STUDENT_DASHBOARD_ATTEMPT_A_CHALLENGE_INIT:
      return student_dashboard_attempt_a_challenge_init(state, action);

    case STUDENT_DASHBOARD_ATTEMPT_A_CHALLENGE_SUCCESS:
      return student_dashboard_attempt_a_challenge_success(state, action);
    case STUDENT_DASHBOARD_ATTEMPT_A_CHALLENGE_FAILED:
      return student_dashboard_attempt_a_challenge_failed(state, action);
    //----Get My launched attempt answersheet
    case STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_ANSWER_SHEET_INIT:
      return student_dashboard_my_launched_challenges_answer_sheet_init(state, action);

    case STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_ANSWER_SHEET_SUCCESS:
      return student_dashboard_my_launched_challenges_answer_sheet_success(state, action);
    case STUDENT_DASHBOARD_MY_LAUNCHED_CHALLENGES_ANSWER_SHEET_FAILED:
      return student_dashboard_my_launched_challenges_answer_sheet_failed(state, action);
    //----Get challenge attempt results
    case STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGES_RESULTS_INIT:
      return student_dashboard_my_attempted_challenges_results_init(state, action);

    case STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGES_RESULTS_SUCCESS:
      return student_dashboard_my_attempted_challenges_results_success(state, action);
    case STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGES_RESULTS_FAILED:
      return student_dashboard_my_attempted_challenges_results_failed(state, action);
    //----Get challenge attempt answer sheet
    case STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGE_ANSWER_SHEET_INIT:
      return student_dashboard_my_attempted_challenge_answer_sheet_init(state, action);

    case STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGE_ANSWER_SHEET_SUCCESS:
      return student_dashboard_my_attempted_challenge_answer_sheet_success(state, action);
    case STUDENT_DASHBOARD_MY_ATTEMPTED_CHALLENGE_ANSWER_SHEET_FAILED:
      return student_dashboard_my_attempted_challenge_answer_sheet_failed(state, action);
    default:
      return state;
  }
};
export default reducer;
