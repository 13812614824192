import axios from "../../axios-base";

export const STUDENT_DASHBOARD_HOMEPAGE_TESTS_NOTIFS_INIT =
  "STUDENT_DASHBOARD_HOMEPAGE_TESTS_NOTIFS_INIT";
export const STUDENT_DASHBOARD_HOMEPAGE_TESTS_NOTIFS_SUCCESS =
  "STUDENT_DASHBOARD_HOMEPAGE_TESTS_NOTIFS_SUCCESS";
export const STUDENT_DASHBOARD_HOMEPAGE_TESTS_NOTIFS_FAILED =
  "STUDENT_DASHBOARD_HOMEPAGE_TESTS_NOTIFS_FAILED";

export function student_dashboard_homepage_tests_notifs_init() {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_TESTS_NOTIFS_INIT
  };
}
export function student_dashboard_homepage_tests_notifs_success(data) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_TESTS_NOTIFS_SUCCESS,
    payload: data
  };
}
export function student_dashboard_homepage_tests_notifs_fail(error) {
  return {
    type: STUDENT_DASHBOARD_HOMEPAGE_TESTS_NOTIFS_FAILED,
    payload: error
  };
}

export function student_dashboard_homepage_tests_notifs_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_tests_notifs_init());

    axios
      .post(
        `/opanda/tests/getsystemactivitiesnotifications`,
        {
          academic_level: data.academic_level
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_tests_notifs_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_tests_notifs_fail(err.message));
      });
  };
}

export function teacher_dashboard_homepage_tests_notifs_start(data) {
  return (dispatch) => {
    dispatch(student_dashboard_homepage_tests_notifs_init());

    axios
      .post(
        `/opanda/tests/getsystemactivitiesnotifications_for_a_teacher`,
        {
          academic_level: data.academic_level,
          teacher_id: data.myId
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data.token}`
          }
        }
      )
      .then((response) => {
        dispatch(student_dashboard_homepage_tests_notifs_success(response.data.results));
      })
      .catch((err) => {
        dispatch(student_dashboard_homepage_tests_notifs_fail(err.message));
      });
  };
}
