import React, { useCallback, useEffect, useState } from 'react';
import { IoIosArrowDropupCircle } from 'react-icons/io';
import './ScrollToTop.css';
const ScrollToTop = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = useCallback(() => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  }, [showScroll]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [checkScrollTop]);

  return (
    <IoIosArrowDropupCircle
      className="scrollTop"
      onClick={scrollTop}
      size={40}
      style={{ height: 40, display: showScroll ? 'flex' : 'none' }}
    />
  );
};
export default ScrollToTop;
