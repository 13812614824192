import { updateObject } from "../../utils/updateObject";

import * as actionsTypes from "../actions/actionTypes";

const initialReducer = {
  questions: [],
  questionsError: null,
  questionsLoading: false,
  onequestion: null,
};

const getQuestionsStart = (state, action) => {
  return updateObject(state, {
    questions: [],
    questionsError: null,
    questionsLoading: true,
    onequestion: null,
  });
};

const getQuestionsSuccess = (state, action) => {
  return updateObject(state, {
    questions: action.questions,
    questionsError: null,
    questionsLoading: false,
    onequestion: null,
  });
};

const getQuestionsFail = (state, action) => {
  return updateObject(state, {
    questions: [],
    questionsError: action.error,
    questionsLoading: false,
    onequestion: null,
  });
};

const getOneQuestionsSuccess = (state, action) => {
  return updateObject(state, {
    questions: [],
    questionsError: null,
    questionsLoading: false,
    onequestion: action.onequestion,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case actionsTypes.GET_MULTIPLE_CHOICE_START:
      return getQuestionsStart(state, action);
    case actionsTypes.GET_MULTIPLE_CHOICE_SUCCESS:
      return getQuestionsSuccess(state, action);
    case actionsTypes.GET_ONE_MULTIPLE_CHOICE_SUCCESS:
      return getOneQuestionsSuccess(state, action);
    case actionsTypes.GET_MULTIPLE_CHOICE_FAIL:
      return getQuestionsFail(state, action);
    default:
      return state;
  }
};
export default reducer;
