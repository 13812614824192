import { connect, useSelector } from "react-redux";
import { store } from "../../../store/store";
import { ButtonLink } from "../button";
import { CardSubText, CardTitle, SectionTitle } from "../text";

const MorePurchaseModal = function (props) {
  const { morepurchasemodalstatus, showmorepurchasemodal, modalcontent } =
    props;
    const paymentoptions = useSelector((state)=>state.previewpanda.paymentoptions)
    const paymentmethod = useSelector((state)=>state.previewpanda.paymentmethod)
  return (
    <div
      className={`${morepurchasemodalstatus} fixed z-10 inset-0 overflow-y-auto`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start relative">
              <button
                className="absolute right-0 flex items-center justify-center h-12 w-12 rounded-full text-red text-xl bg-gray-100 hover:bg-red hover:text-white sm:h-10 sm:w-10"
                onClick={() => {
                  showmorepurchasemodal("hidden");
                }}
              >
                &times;
              </button>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center space-y-4">
                <SectionTitle
                  name="More Information"
                  alignment="center"
                  color="blue"
                />
                <CardSubText
                  name="Here are some information you might ask yourself"
                  alignment="center"
                  color="black"
                />
                <div className="flex flex-col items-start space-y-3">
                  <CardTitle
                    name="How often do I pay?"
                    alignment="left"
                    color="blue"
                  />
                  <CardSubText
                    name={paymentmethod?.smalldescription}
                    alignment="left"
                    color="gray-500"
                  />
                  <CardTitle
                    name="What methods do I use?"
                    alignment="left"
                    color="blue"
                  />
                  <CardSubText
                    name={paymentmethod?.description}
                    alignment="left"
                    color="gray-500"
                  />
                  <div>
                  {paymentoptions?.map((option, index)=>(
                <>
                <CardTitle name={option?.name+"?"} 
                  alignment="left" color="blue" key={index}/>
                  <CardSubText key={index}
                    name={option?.description}
                    alignment="left"
                    color="gray-500"
                  />
                </>
                  ))}
                  </div>
                </div>
                <div className="w-100 mx-auto my-4 space-x-2 flex items-center justify-center">
                  <ButtonLink
                    page="enroll"
                    name="Proceed to Signup"
                    outline="true"
                    color="red"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// export default SideBar;
const mapStateToProps = () => {
  return {
    morepurchasemodalstatus:
      store.getState().contentmodalreducer.morepurchasemodalstatus,
    modalcontent: store.getState().contentmodalreducer.modalcontent,
  };
};
const mapDispatchToProps = () => {
  return {
    showmorepurchasemodal: (status) =>
      store.dispatch({ type: "SHOW_MORE_PURCHASE_MODAL", payload: status }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MorePurchaseModal);
