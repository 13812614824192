import React from 'react';
import Simulations from '../../../components/pages/teacher/simulations/Simulations';
import Layout from '../../../components/common/Layout';

const SimulationsContainer = () => {
  return (
    <Layout>
      <Simulations />
    </Layout>
  );
};

export default SimulationsContainer;
