import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";

export const getTeacherDragAndDropQuestionsStart = () => {
  return {
    type: actionTypes.GET_DRAG_AND_DROP_TEACHER_QUESTIONS_START,
  };
};

export const getTeacherDragAndDropQuestionsSuccess = (questions) => {
  return {
    type: actionTypes.GET_DRAG_AND_DROP_TEACHER_QUESTIONS_SUCCESS,
    questions: questions,
  };
};

export const getOneTeacherDragAndDropQuestionsSuccess = (onequestion) => {
  return {
    type: actionTypes.GET_DRAG_AND_DROP_TEACHER_QUESTIONS_SUCCESS,
    onequestion: onequestion,
  };
};

export const getTeacherDragAndDropQuestionsFail = (error) => {
  return {
    type: actionTypes.GET_DRAG_AND_DROP_TEACHER_QUESTIONS_FAIL,
    error: error,
  };
};

export const getTeacherDragAndDropQuestions = () => {
  return (dispatch) => {
    dispatch(getTeacherDragAndDropQuestionsStart());
    axios
      .get(`/opanda/panda-teacher-drag-and-drop-questions`)
      .then((response) => {
        dispatch(getTeacherDragAndDropQuestionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTeacherDragAndDropQuestionsFail(err.message));
      });
  };
};

export const registerTeacherDragAndDropQuestions = (object) => {
  return (dispatch) => {
    dispatch(getTeacherDragAndDropQuestionsStart());
    axios
      .post(`/opanda/panda-teacher-drag-and-drop-questions/register-questions`, object)
      .then((response) => {
        dispatch(getTeacherDragAndDropQuestionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTeacherDragAndDropQuestionsFail(err.message));
      });
  };
};
