import Layout from '../../../components/common/Layout';
import ProgressContainer from '../../../components/pages/student/test/Progress';

const TestProgressContainer = () => {
  return (
    <Layout>
      <ProgressContainer />
    </Layout>
  );
};

export default TestProgressContainer;
