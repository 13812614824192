import { Button } from '../elements/button';
import { CardTitle, PageTitle } from '../elements/text';
import Header from './header/header';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from "../../store/actions/"

const UnAuthorizedPage = () => {
  const history = useHistory();
  const dispatch= useDispatch()
  useEffect(()=>{
  dispatch(actions.logout());
  },[])
  return (
    <div>
      <Header />
      <div className="w-60% m-auto flex flex-col items-center justify-center">
        <img className="m-auto" src="/images/404.svg" alt="" />
        <PageTitle
          name="Token Expired, Log in again"
          color="blue"
          additional="font-bold text-center"
        />
        <CardTitle
          name="Navigate back to login page"
          color="blue"
          additional="font-bold text-center"
        />
        <Button
          name="To Login"
          outline="true"
          color="red"
          clicked={() => history.replace('/login')}
        />
      </div>
    </div>
  );
};

export default UnAuthorizedPage;
