import { useEffect, useState } from "react";
import PageContent from "../pageContent";
import { CardBody, CardTitle, FeatureTitle } from "../../../elements/text";
import { Button, GoBackButton } from "../../../elements/button";
import Input from "../../../elements/input";
import parse from 'react-html-parser';
import AddMultipleQuestion from "../../../common/features/tests/testtypes/multipleChoice/AddMultipleQuestion";
import { useDispatch, useSelector } from "react-redux";
import {
  addQuestion,
  setCurrentQuestion,
  setMulitpleQuestionOptions,
  submitQuestions,
} from "../../../../store/actions/features/tests";
import AddOpenEndedQuestion from "../../../common/features/tests/testtypes/openended/AddOpenEndedQuestion";
import AddDragAndDropQuestion from "../../../common/features/tests/testtypes/draganddrop/AddDragAndDropQuestion";
import AddCompleteSentenceQuestion from "../../../common/features/tests/testtypes/completesentence/AddCompleteSentenceQuestion";
import constants from "../../../../constants/constants";
import { useHistory } from "react-router-dom";
import * as questionformat from "./formattingquestions";
import * as actions from "../../../../store/actions/";
import { errorNotification } from "../../../common/messagenotifications";
import { GrClose } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";
import axios from "../../../../axios-base";
import Spinner from "../../../elements/spinner";
import TestEditor from "./TestEditor";
import DatePicker from 'react-datepicker';
import TimePicker from "./TimePicker";

const NewTest = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [questionType, setQuestionType] = useState("");
  const [level, setLevel] = useState("1");
  const [testType, setTestType] = useState("quiz");
  const [subject, setSubject] = useState("BIOLOGY");
  const [unit, setUnit] = useState("UNIT");
  const [counter, setCounter] = useState(1);
  const [testtype, settesttype] = useState("");

  const [showSelectBox, setShowSelectBox] = useState(true);
  const [flowofquestionsArray, setflowofquestionsArray] = useState([]);
  const [
    flowofquestionsanswerstrackArray,
    setflowofquestionsanswerstrackArray,
  ] = useState([]);
  const [flowofquestions, setflowofquestions] = useState("");
  const [flowofquestionsanswerstrack, setflowofquestionsanswerstrack] =
    useState("");

  const currentQuestion = useSelector(
    (state) => state.features.tests.currentQuestion
  );
  const questions__ = useSelector((state) => state.features.tests.questions);
  const [questions, setQuestions] = useState([]);
  const testmap = useSelector((state) => state.features.tests.testmap);
  const testmapError = useSelector(
    (state) => state.features.tests.testmapError
  );
  const token = useSelector((state) => state.auth.token);
  const userAuth = useSelector((state) => state.auth);
  const [subjects, setSubjects] = useState([{}]);
  const [units, setUnits] = useState([{}]);
  const [subjectIsLoading, setSubjectIsLoading] = useState(false);

  const [questionNber, setQuestionNber] = useState(0);
  const [dateOfTest, setDateOfTest] = useState(new Date().getTime());
  const [beginTime, setBeginTime] = useState(null);
  const [endingTime, setEndingTime] = useState(null);
  const [className, setClassName] = useState("");
  const [isMyTestSaving, setIsMyTestSaving] = useState(false);

  const addingQuestion = () => {
    if (Object.keys(currentQuestion).length === 0) {
      alert(`don't submit an empty question ${counter}`);
    } else {
      let flow = counter + "~#AllCyumaBases~#" + questionType + "~#$";
      let track = questionType + "~~" + counter + "~~" + counter;
      flowofquestionsArray.push(flow);
      flowofquestionsanswerstrackArray.push(track);
      setflowofquestions(
        flowofquestionsArray.join(
          "~#$" + testmap?.panda_test_map_2020_03_nber_of_questions + "#~"
        )
      );
      setflowofquestionsanswerstrack(
        flowofquestionsanswerstrackArray.join("2020CyumaIsTheBest2020")
      );
      let questiondata = {};
      if (questionType === constants.complete_sentence) {
        questiondata =
          questionformat.getCompleteSentenceQuestionData(currentQuestion);
      } else if (questionType === constants.drag_and_drop) {
        questiondata =
          questionformat.getDragAndDropQuestionData(currentQuestion);
      } else if (questionType === constants.open_ended) {
        questiondata = questionformat.getOpenEndedQuestionData(currentQuestion);
      } else if (questionType === constants.multiple_choice) {
        questiondata = questionformat.getMultipleChoiceData(currentQuestion);
      }
      setQuestionType("");
      setCounter(counter + 1);
      dispatch(setCurrentQuestion({}));
      dispatch(setMulitpleQuestionOptions([]));
      dispatch(addQuestion(questiondata));
    }
  };

  const createTest = () => {
    const testdata = {
      ...testmap,
      panda_test_map_2020_03_flow_of_questions_answers_track:
        flowofquestionsanswerstrack,
      panda_test_map_2020_03_test_type: "test",
      panda_test_map_2020_03_flow_of_questions: flowofquestions,
      testquestions: questions,
    };
    dispatch(submitQuestions());
    dispatch(actions.createTeacherTestMaps(testdata, token));
  };

  const createQuiz = () => {
    const testdata = {
      ...testmap,
      panda_test_map_2020_03_flow_of_questions_answers_track:
        flowofquestionsanswerstrack,
      panda_test_map_2020_03_test_type: "quiz",
      panda_test_map_2020_03_flow_of_questions: flowofquestions,
      testquestions: questions,
    };
    dispatch(submitQuestions());
    dispatch(actions.createTeacherTestMaps(testdata, token));
  };

  // useEffect(() => {
  //   if (testmap === null) {
  //     history.push("/teacher/features/tests");
  //   }
  //   if (testmapError) {
  //     errorNotification(testmapError);
  //     history.push("/login");
  //   }
  // }, [testmapError]);

  useEffect(() => {
    setSubjectIsLoading(true);
    axios
      .put(
        `/opanda/past-papers/getSubjects`,
        { userId: userAuth.user.id },
        {
          headers: {
            authorization: `Bearer ${userAuth.token}`,
          },
        }
      )
      .then((response) => {
        const subjectData = response.data.results
          .map(pool => ({ subject: pool.subject, displayValue: pool.subject }))
          .sort((a, b) => a.subject.localeCompare(b.subject));

        setSubjects(subjectData.map((subject) => {
          return { value: subject.subject, displayValue: subject.subject }
        }
        ));

        //-----
        axios
          .put(
            `/opanda/past-papers/getUnitWithSubject`,
            {
              userId: userAuth.user.id,
              level: level,
              subject: subject
            },
            {
              headers: {
                authorization: `Bearer ${userAuth.token}`,
              },
            }
          )
          .then((response) => {
            const subjectData = response.data.results.sort((a, b) => {
              // Extract numbers from the strings. Assuming the format is always "unit X".
              const numberA = parseInt(a.units.replace(/^\D+/g, ''), 10);
              const numberB = parseInt(b.units.replace(/^\D+/g, ''), 10);

              // Compare the extracted numbers.
              return numberA - numberB;
            });

            setUnits(subjectData.map((subject) => {
              return { value: subject.units, displayValue: subject.units }
            }
            ));
            setSubjectIsLoading(false);
          })
          .catch((err) => { setSubjectIsLoading(false); });
      })
      .catch((err) => { setSubjectIsLoading(false); });
  }, [level, subject]);

  useEffect(() => {
  }, [questions]);


  const getTheQuestionStream = (qw) => {
    const q_u = qw.answers.map((q, i) => { return (q.isAnswer) ? "#O.G " + q.value + "#A.N.S" : "#O.G " + q.value })

    return q_u.join("\n");
  }

  const findQuestionWithMultiplAnswers = (qw) => {

    return (qw.answers.filter((q, i) => { return q.isAnswer }).length > 1) ? true : false;
  }
  function containsNonSpaceChars(str) {
    return /\S/.test(str);
  }

  function stripStrongTags(inputString) {
    const strongTagRegex = /<strong>(.*?)<\/strong>/gi;
    // Replace each <strong>...</strong> with just the content inside the tags
    try {
      let outputString = inputString.replace(strongTagRegex, '$1');

      // Replace all instances of &nbsp; with a normal space
      outputString = outputString.replace(/&nbsp;/gi, ' ');

      return outputString;
    } catch (e) {
      return inputString;

    }
  }

  function orderWordsByTextPosition(text, words) {
    // Map to store the words and their first positions
    const wordPositions = words.reduce((acc, word) => {
      const pos = text.indexOf(word);
      if (pos !== -1) {
        acc.push({ word, pos });
      }
      return acc;
    }, []);

    // Sort the array by position
    wordPositions.sort((a, b) => a.pos - b.pos);

    // Map the sorted array back to a string of words separated by "~----~"
    const orderedWords = wordPositions.map(entry => entry.word).join("~----~");

    return orderedWords;
  }

  // Crate a function to turn a react object into html string, if not object keep it as it is
  function reactToHtmlString(reactObject) {
    if (typeof reactObject === 'object' && reactObject["props"] && reactObject["props"]["children"]) {
      return (reactObject["props"]["children"].length > 0 ? reactObject["props"]["children"] : "");
    } else {
      return reactObject;
    }
  }
  // Function to convert base64 to Blob
  function base64ToBlob(base64Data) {
    const byteString = atob(base64Data.split(',')[1]);
    const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }
  // Function to upload base64 images as JPGs with enumerated names
  async function uploadImagesWithNames(images) {
    const uploadPromises = images.map(async (base64Data, index) => {
      const blob = base64ToBlob(base64Data);

      // Create a unique filename with timestamp and index
      const timestamp = Date.now();
      const fileName = `${timestamp}-teacher_made_tests_${Date.now()}_${index}.jpg`;

      // Create a FormData object and append the blob
      const formData = new FormData();
      formData.append('image', blob, fileName);

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/opanda/uploadImages`, {
          method: 'POST',
          body: formData
          // Add headers if needed, like authorization or content-type
        });

        if (response.ok) {
          // Once the image is uploaded, return an object with base64 data and filename
          return { base64Data, fileName };
        } else {
          throw new Error('Upload failed');
        }
      } catch (error) {
        console.error('Error:', error);
        return null;
      }
    });

    return Promise.all(uploadPromises);
  }
  function reactToHtmlString(reactObject) {
    if (typeof reactObject === 'object' && reactObject["props"] && reactObject["props"]["children"]) {
      return (reactObject["props"]["children"].length > 0 ? reactObject["props"]["children"] : "");
    } else {
      return reactObject;
    }
  }
  const uploadMyImages = async (images_data, content) => {
    // Call the function to upload images and save key-value pairs after upload               
    const results = await uploadImagesWithNames(images_data)
    if (results.length <= 0) return content;
    const imageKeyValuePairs = {}; // Object to store image base64 and filenames

    results.forEach(result => {
      // Process each result from the backend
      if (result) {
        imageKeyValuePairs[result.fileName] = [result.base64Data, result.fileName];

      }
    });
    //--Replace all images
    let newPageContent = content
    Object.keys(imageKeyValuePairs).forEach(key => {
      newPageContent = newPageContent.replaceAll(imageKeyValuePairs[key][0], `${process.env.REACT_APP_BACKEND_URL}/simulation_thumbnails/${key}`);
    });
    return newPageContent;



  }
  const getAllImages = (html) => {
    const regex = /<img[^>]+src="([^">]+)"/g;
    const images = [];
    let match;

    while ((match = regex.exec(html))) {
      const imageUrl = match[1];
      if (imageUrl.startsWith('data:image')) {
        images.push(imageUrl);
      }
    }
    return images;
  };
  async function processQuestions(data) {

    // Using Promise.all to handle an array of promises generated by map
    const questionImage = await uploadMyImages(getAllImages(data), data);
    return questionImage;
  }

  function combineDateTime(dateOfTest, beginTime) {
     // Assume beginTime is in the format "HH:mm"
    const [hours, minutes] = beginTime.split(':');
    const date = new Date(dateOfTest);
    // Add hours and minutes to the date
    date.setHours(hours, minutes);
    console.log(date);
    return date;
  }
  const saveTheTest = async () => {
    setIsMyTestSaving(true);
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    if (dateOfTest >= today) {

      if (className === "") {
        alert("Enter class name to save the test.");
        setIsMyTestSaving(false);
        return;
      }

      var testmetadata = '';
      var questions_order = '';

      for (var i = 0; i < questions.length; i++) {
        var question = questions[i];


        question.question = reactToHtmlString(question.question);

        if (question.type === "multiple_choice") {
          if (i > 0) {
            questions_order += "2020CyumaIsTheBest2020";
            testmetadata += "#~###~";
          }
          testmetadata += `${i + 1}~#~multiple_choice~#~${question.duration}~#~${question.marks}~#~#O.G.Q#O.G.S ${stripStrongTags(question.question)} ${(findQuestionWithMultiplAnswers(question)) ? "#M.U.L" : ""} \n  
          #O.G.S ${(containsNonSpaceChars(question.explanation)) ? stripStrongTags(question.explanation) : "<p></p>"}  \n
          #O.G.S${getTheQuestionStream(question)} \n`;
          questions_order += `multiple_choice~~${i + 1}~~${i + 1} `;
        } else if (question.type === "drag_and_drop") {
          if (i > 0) {
            questions_order += "2020CyumaIsTheBest2020";
            testmetadata += "#~###~";
          }
          testmetadata += `${i + 1}~#~drag_and_drop~#~${question.duration}~#~${question.marks}~#~${stripStrongTags(question.question)}  \n ~~##
          ${orderWordsByTextPosition(question.question, question.selectedWords.map((q) => { return q.word }))}\n`;
          questions_order += `drag_and_drop~~${i + 1}~~${i + 1} `;

        } else if (question.type === "complete_sentence") {
          if (i > 0) {
            questions_order += "2020CyumaIsTheBest2020";
            testmetadata += "#~###~";
          }
          testmetadata += `${i + 1}~#~complete_sentence~#~${question.duration}~#~${question.marks}~#~${stripStrongTags(question.question)} \n ~~##
          ${orderWordsByTextPosition(question.question, question.selectedWords.map((q) => { return q.word }))}\n`;
          questions_order += `complete_sentence~~${i + 1}~~${i + 1} `;
        } else if (question.type === "open_ended") {
          if (i > 0) {
            questions_order += "2020CyumaIsTheBest2020";
            testmetadata += "#~###~";
          }
          testmetadata += `${i + 1}~#~open_ended~#~${question.duration}~#~${question.marks}~#~${stripStrongTags(question.question)}  \n`;
          questions_order += `open_ended~~${i + 1}~~${i + 1} `;
        }
      }
      // Treat images before saving the test
      await processQuestions(testmetadata).then(testmetadataImagesFiltered => {
        testmetadata = testmetadataImagesFiltered;
      }).catch(error => {
        console.error('Error processing questions:', error);
      });

      axios
        .post(
          `/opanda/tests/createatest`,
          {
            "userId": userAuth.user.id,
            "names": userAuth.user.names,
            "schedule": combineDateTime(dateOfTest, beginTime),
            "endtime": endingTime,
            "begintime": beginTime,
            "feature_name": "scheduled-quiz",
            "subject": subject,
            "academic_level": level,
            "unit": unit,
            "test_name": "Opandav3 Powered Test",
            "about_test": "This is a test created by OpandaV3.0.0",
            "class_name": className,
            "nber_of_questions": questions.length,
            "test_metadata": testmetadata,
            "questions_order": questions_order,
            "test_type": testType
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userAuth.token} `,
            },
          }
        )
        .then((response) => {
          setIsMyTestSaving(false);
          alert("The test was successfully saved!!!");
          history.push("/teacher/features/tests");
        })
        .catch((err) => {
          console.log(err)
          alert("The test was not saved!!! Errors were found in formatting your questions!!");
          setIsMyTestSaving(false);
        });
    } else {
      alert("Please select a valid date for the test release date.");
      setIsMyTestSaving(false);
    }
  }


  return (
    <PageContent>
      <div className={`md:grid grid-cols-12 w-full md:h-full min-h-screen-sec`}>
        {showSelectBox ? (
          (!isMyTestSaving) ? <>
            <div className="p-3 bg-gray-200 md:h-full col-span-4 border-l border-gray-500">
              <div>
                <CardBody
                  name="Select a subject, academic level and unit to start browser through question bank"
                  alignment="center"
                />
                {(!subjectIsLoading) ? <div className="flex flex-col md:flex-row md:justify-between md:items-center md:space-x-2">
                  <Input
                    label="Subject"
                    elementType="select"
                    elementConfig={{
                      startingValue: "SELECT",
                      options: [...subjects],
                    }}
                    value={subject}
                    changed={setSubject}
                    validation={{ required: true }}
                    shouldValidate
                    error="Subject is required"
                  />
                  <Input
                    label="Level"
                    elementType="select"
                    elementConfig={{
                      startingValue: "SELECT",
                      options: [
                        { value: "1", displayValue: "Senior 1" },
                        { value: "2", displayValue: "Senior 2" },
                        { value: "3", displayValue: "Senior 3" },
                        { value: "4", displayValue: "Senior 4" },
                        { value: "5", displayValue: "Senior 5" },
                        { value: "6", displayValue: "Senior 6" },
                      ],
                    }}
                    value={level}
                    changed={setLevel}
                    validation={{ required: true }}
                    shouldValidate
                    error="Level is required"
                  />
                </div> : <Spinner size="16" color="blue" />}
              </div>
              <Input
                label="Unit"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: [
                    ...units
                  ],
                }}
                value={unit}
                changed={setUnit}
                validation={{ required: true }}
                shouldValidate
                error="Unit is required"
              />
              <Input
                label="Save test as:"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: [
                    { value: "quiz", displayValue: "quiz" },
                    { value: "test", displayValue: "test" },
                  ],
                }}
                value={testType}
                changed={setTestType}
                validation={{ required: true }}
                shouldValidate
                error="Test type is required"
              />
              <Input
                label="Class name:"
                elementType="input"
                elementConfig={{
                  type: "text",
                  placeholder: "S1C",
                }}
                value={className}
                changed={setClassName}
                validation={{ required: true }}
                shouldValidate
                error="Last Name is required"
              />
              <CardTitle
                name="Set test release date"
                color="blue"
                additional="font-bold text-center"
              />
              <DatePicker
                selected={dateOfTest}
                onChange={setDateOfTest}
                dateFormat="dd/MM/yyyy"
                placeholderText="Select test release date"
                isClearable
              />
              <div ><CardTitle
                name="Begin time:"
                color="blue"
                additional="font-bold text-center"
              />
                <TimePicker callBack={setBeginTime} />
                <CardTitle
                  name="Ending time:"
                  color="blue"
                  additional="font-bold text-center"
                />
                <TimePicker callBack={setEndingTime} />
              </div>

              <div className="p-2">
                <Button
                  name="Save the test (Questions on the right)"
                  outline="true"
                  color="blue"
                  clicked={() => {
                    if (questions.length > 0) {
                      saveTheTest();

                    } else {
                      alert("Please add questions to the test before saving.");
                    }

                  }}
                /></div>
            </div>
          </> : <Spinner size="32" color="blue" />
        ) : (
          ""
        )}
        <div
          className={`${showSelectBox ? "hidden md:block" : ""
            } md:relative p-3 bg-white md:h-full h-screen-second mb-16 pb-16 md:max-h-screen-sec col-span-8 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
        >
          <FeatureTitle name="Tests & Assessment" />
          <GoBackButton
            link="/teacher/features/tests"
            action="Back"
            location="Tests & Assessments"
          />

          {((!isMyTestSaving)) ? <TestEditor unit={unit} setQuestions={setQuestions} level={level} subject={subject} /> : <Spinner size="24" color="blue" />}


        </div>
        <div
          style={{ zIndex: "1000" }}
          className={`${showSelectBox ? "block" : ""
            } md:hidden fixed w-full md:w-auto  md:relative p-3 bg-gray-200 bottom-0  flex flex-row md:flex-col justify-between items-center`}
        >
          <div className="flex w-full justify-around md:flex-col md:items-center md:space-y-10 z-auto">
            {showSelectBox ? (
              <GrClose
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSelectBox(!showSelectBox)}
              />
            ) : (
              <GiHamburgerMenu
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSelectBox(!showSelectBox)}
              />
            )}
          </div>
        </div>
      </div>
    </PageContent >
  );
};

export default NewTest;
