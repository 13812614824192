import axios from "../../axios-base";

import { getCachedData } from "../../shared/caching";

export const SCHOOL_REGISTERED_USERS_COUNT_INIT =
  "SCHOOL_REGISTERED_USERS_COUNT_INIT";
export const SCHOOL_REGISTERED_USERS_COUNT_SUCCESS =
  "SCHOOL_REGISTERED_USERS_COUNT_SUCCESS";
export const SCHOOL_REGISTERED_USERS_COUNT_FAILED =
  "SCHOOL_REGISTERED_USERS_COUNT_FAILED";

export const SCHOOL_REGISTERED_USERS_INIT = "SCHOOL_REGISTERED_USERS_INIT";
export const SCHOOL_REGISTERED_USERS_SUCCESS =
  "SCHOOL_REGISTERED_USERS_SUCCESS";
export const SCHOOL_REGISTERED_USERS_FAILED = "SCHOOL_REGISTERED_USERS_FAILED";

export const SCHOOL_ADD_REGISTERED_USERS_INIT =
  "SCHOOL_ADD_REGISTERED_USERS_INIT";
export const SCHOOL_ADD_REGISTERED_USERS_SUCCESS =
  "SCHOOL_ADD_REGISTERED_USERS_SUCCESS";
export const SCHOOL_ADD_REGISTERED_USERS_FAILED =
  "SCHOOL_ADD_REGISTERED_USERS_FAILED";

export const SCHOOL_REMOVE_REGISTERED_USERS_INIT =
  "SCHOOL_REMOVE_REGISTERED_USERS_INIT";
export const SCHOOL_REMOVE_REGISTERED_USERS_SUCCESS =
  "SCHOOL_REMOVE_REGISTERED_USERS_SUCCESS";
export const SCHOOL_REMOVE_REGISTERED_USERS_FAILED =
  "SCHOOL_REMOVE_REGISTERED_USERS_FAILED";

export function school_registered_users_count_init() {
  return {
    type: SCHOOL_REGISTERED_USERS_COUNT_INIT,
  };
}
export function school_registered_users_count_success(data) {
  return {
    type: SCHOOL_REGISTERED_USERS_COUNT_SUCCESS,
    payload: data,
  };
}
export function school_registered_users_count_failed(error) {
  return {
    type: SCHOOL_REGISTERED_USERS_COUNT_FAILED,
    payload: error,
  };
}

export function school_registered_users_count_start(data) {
  return (dispatch) => {
    dispatch(school_registered_users_count_init());
    axios
      .get(
        `/opanda/manage-users/findregisteredusercount?id=${data.school_id}&status=active`,

        {
          headers: {
            Authorization: `Bearer ${data.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(school_registered_users_count_success(response.data.results));
      })
      .catch((err) => {
        dispatch(school_registered_users_count_failed(err.message));
      });
  };
}

export function school_registered_users_init() {
  return {
    type: SCHOOL_REGISTERED_USERS_INIT,
  };
}
export function school_registered_users_success(data) {
  return {
    type: SCHOOL_REGISTERED_USERS_SUCCESS,
    payload: data,
  };
}
export function school_registered_users_failed(error) {
  return {
    type: SCHOOL_REGISTERED_USERS_FAILED,
    payload: error,
  };
}

export function school_registered_users_start(data) {
  return (dispatch) => {
    dispatch(school_registered_users_init());
    axios
      .get(
        `/opanda/manage-users/findbyregistered/${data.school_id}`,

        {
          headers: {
            Authorization: `Bearer ${data.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(school_registered_users_success(response.data.results));
      })
      .catch((err) => {
        dispatch(school_registered_users_failed(err.message));
      });
  };
}

export function school_add_registered_users_init() {
  return {
    type: SCHOOL_ADD_REGISTERED_USERS_INIT,
  };
}
export function school_add_registered_users_success(data) {
  return {
    type: SCHOOL_ADD_REGISTERED_USERS_SUCCESS,
    payload: data,
  };
}
export function school_add_registered_users_failed(error) {
  return {
    type: SCHOOL_ADD_REGISTERED_USERS_FAILED,
    payload: error,
  };
}

export function school_add_registered_users_start(data) {
  return (dispatch) => {
    dispatch(school_add_registered_users_init());
    axios
      .post(
        `/opanda/manage-users/addregistered/${data.id}`,
        { school_id: data.school_id },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(school_add_registered_users_success(response.data.results));
        // dispatch(
        //   school_registered_users_count_start({
        //     school_id: data.school_id,
        //     token: data.token,
        //   })
        // );
        dispatch(
          school_registered_users_start({
            school_id: data.school_id,
            token: data.token,
          })
        );
      })
      .catch((err) => {
        dispatch(school_add_registered_users_failed(err.message));
      });
  };
}

export function school_remove_registered_users_init() {
  return {
    type: SCHOOL_REMOVE_REGISTERED_USERS_INIT,
  };
}
export function school_remove_registered_users_success(data) {
  return {
    type: SCHOOL_REMOVE_REGISTERED_USERS_SUCCESS,
    payload: data,
  };
}
export function school_remove_registered_users_failed(error) {
  return {
    type: SCHOOL_REMOVE_REGISTERED_USERS_FAILED,
    payload: error,
  };
}

export function school_remove_registered_users_start(data) {
  return (dispatch) => {
    dispatch(school_remove_registered_users_init());
    axios
      .delete(`/opanda/manage-users/removeregistered/${data.id}`, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        dispatch(school_remove_registered_users_success(response.data.results));
        // dispatch(
        //   school_registered_users_count_start({
        //     school_id: data.school_id,
        //     token: data.token,
        //   })
        // );
        dispatch(
          school_registered_users_start({
            school_id: data.school_id,
            token: data.token,
          })
        );
      })
      .catch((err) => {
        dispatch(school_remove_registered_users_failed(err.message));
      });
  };
}
