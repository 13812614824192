import { connect } from "react-redux";

import HamburgerMenu from "../../../../images/icons/hamburger.svg";
import { ButtonNav, ButtonWithIcon } from "../../../elements/button";
import { store } from "../../../../store/store";

const TopNavBar = function (props) {
  const { showsidebar, changetab } = props;
  return (
    <div className="w-screen min-h-24 h-12 overflow-x-auto md:hidden">
      <div className="w-max space-x-1 flex items-center flex-no-wrap my-2">
        <ButtonWithIcon
          name="Menu"
          src={HamburgerMenu}
          outline="true"
          isSmall={true}
          color="blue"
          onClick={showsidebar}
        />
        <ButtonNav onClick={() => {
                changetab("cbc");
              }} page="cbc" name="CBC" outline="true" isSmall={true} color="red" />
        <ButtonNav  onClick={() => {
                changetab("cambridge");
              }} name="Cambridge"
              page="cambridge"
          isSmall={true}
          outline="true"
          color="blue"
        />
        <ButtonNav  onClick={() => {
                changetab("loremipsum");
              }}
          name="Lorem Ipsum"
          page="cbc"
          isSmall={true}
          outline="true"
          color="blue"
        />
        <ButtonNav  onClick={() => {
                changetab("loremipsum");
              }}
          name="Lorem Ipsum"
          page="loremipsum"
          isSmall={true}
          outline="true"
          color="blue"
        />
      </div>
    </div>
  );
};

// export default SideBar;
const mapStateToProps = (state) => {
  return {
    visibility: store.getState().reducer.mobilesidebar,
    tabselected: store.getState().reducer.tabselected,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    showsidebar: () => store.dispatch({ type: "SHOW_SIDEBAR" }),
    hidesidebar: () => store.dispatch({ type: "HIDE_SIDEBAR" }),
    changetab: (tab) => store.dispatch({ type: "CHANGE_TAB", payload: tab }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNavBar);
