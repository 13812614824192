import React from "react";
import { TextButton } from "../button";
import Input from "../input";

const NewTableBody = (props) => {

  const [checked, setChecked] = React.useState(false);
  const [startoffset, setStartoffset] = React.useState(0);
  const [endoffset, setEndoffset] = React.useState(3);
  const handleChange = () => {
    setChecked(!checked);
  };
  const data = props.tbody;

  const data_ = data.slice(startoffset, endoffset).map((item, index) => (
    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
      <td className="bg-gray-200 px-4 py-2 border border-gray-200 text-center text-black" width="10">{index+1}</td>
      {Object.entries(item)?.map((value, key) => (
        <td className="px-4 py-2 border border-gray-200 text-left text-black table-cell" key={key} width="20">
          {value[1].type === "text" ? (
            value[1].data
          ) : (value[1].type === "component" ? value[1].data : <Input elementType="checkbox" value={checked} elementConfig={{
            type: "email",
            placeholder: "Email address",
          }} changed={setChecked} validation={{ required: false }} onChange={handleChange}/>)}
        </td>
      ))}
      </tr>
  ));

  const data__ = <tr className="py-2 px-4 border border-gray-200"><td colSpan={`${props.colspan + 1}`}></td></tr>;


  return <><tbody>{data_}{data__}</tbody></>;
};

export default NewTableBody;
