import { CardTitle } from "../../text";
import { useHistory } from "react-router-dom";
import Calendar from "short-react-calendar";
import { EventCard } from "../../card";
import { useCallback, useEffect, useMemo, useState } from "react";
import { scheduledNotifications } from "../../../../functions/scheduledNotifications";
import { useDispatch, useSelector } from "react-redux";
import { student_dashboard_homepage_tests_notifs_start } from "../../../../store/actions/student.dashboard.tests";

const Schedule = () => {
  const dispatch = useDispatch();
  const userAth = useSelector((state) => state.auth);
  const memoizedUserAth = useMemo(() => userAth, [userAth]);
  const history = useHistory();
  const [date, setDate] = useState(new Date());
  const studentDashboardTests = useSelector(
    (state) => state.studentDashboardTests
  );
  const instant_schedule = useMemo(() => {
    // Calculation based on myStateValue...
    return scheduledNotifications(
      studentDashboardTests.dashboard_homepage_tests_notifs
    );
  }, [studentDashboardTests.dashboard_homepage_tests_notifs]);

  const fetchScheduleData = useCallback(async () => {
    try {
      await dispatch(
        student_dashboard_homepage_tests_notifs_start({
          academic_level: memoizedUserAth.academic_level,
        })
      );
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, memoizedUserAth]);

  useEffect(() => {
    fetchScheduleData();
  }, [fetchScheduleData]);

  return instant_schedule.length > 0 ? (
    instant_schedule.map((schedule, index) => (
      <div className="bg-white px-6 py-3 filter drop-shadow-md rounded-2xl w-full">
        <div className="w-full overflow-x-hidden">
          <Calendar
            className="w-full overflow-x-auto my-2 bg-white px-1 py-2 rounded-xl shadow text-blue text-sm font-semibold"
            activeStartDate={new Date()}
            value={new Date()}
            onChange={setDate}
            calendarType="US"
            oneWeekCalendar={true}
          />
        </div>
        <div className="flex flex-col  p-2 w-full">
          <CardTitle name={schedule.type} color="blue" />
          {schedule.schedules.map((oneschedule, oneindex) => (
            <div
              key={oneindex}
              className="flex items-center space-x-2 py-2 max-h-28 overflow-auto cursor-pointer"
            >
              <EventCard
                {...oneschedule}
                onClick={() => {
                  history.push(
                    `/student/features/tests/${oneschedule.test_id}?scheduled=true`
                  );
                }}
              />
            </div>
          ))}
          {/*<OtherLinkButton color="blue" to="/schedule" name={t('More')} />*/}
        </div>
      </div>
    ))
  ) : (
    <div></div>
  );
};

export default Schedule;
