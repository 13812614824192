import { useEffect, useRef, useState } from "react";
import Input from "../../../../../elements/input";
import { CardSubText, CardTitle } from "../../../../../elements/text";
import { Button } from "../../../../../elements/button";
import { useDispatch, useSelector } from "react-redux";
import { completeSignup } from "../../../../../../store/actions";
import Spinner from "../../../../../elements/spinner";
import * as actions from "../../../../../../store/actions";
import { Message } from "../../../../../common/messages";

const AcademicInformation = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.completeSignup.loading);
  const userId = useSelector((state) => state.auth.userId);
  const programs = useSelector((state) => state.programs.programs);
  const [curriculum, setCurriculum] = useState("");
  const [combination, setCombination] = useState("");
  const [isAdvancedLevelAvailable, setisAdvancedLevelAvailable] =
    useState(false);
  const [dataerror, setdataerror] = useState("");
  const cbccombinations=[{value: 'MCB', label: 'MCB'},{value: 'BCG', label: 'BCG'},{value: 'MPC', label: 'MPC'}];
 
  // const combinations = getcombinationsList(programs);
  // console.log(combinations);
  // useEffect(() => {
  //   dispatch(actions.getOGeniusModules());
  //   dispatch(actions.getCBCCombinations(token));
  // });

  const interestRef = useRef();
  const modules = useSelector((state) => state.previewpanda.modules);
  const token = useSelector((state) => state.auth.token);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const getMultiselectedCombinations = (data) => {
    const combination_pulled = [];
    for (var n = 0; n < data.length; n++) {
      combination_pulled.push(data[n].value);
    }
    setCombination(combination_pulled.join(","));
  };

  const submitacademicinfo = () => {
    return false;
    // const userObject = {
    //   combination: combination,
    //   academic_info_curriculum: curriculum,
    //   academic_info_sujectofinterest: interestRef.current.getInterest(),
    //   userId,
    // };
    // if (
    //   userObject?.school_affiliated !== undefined &&
    //   userObject?.school_affiliated !== "" &&
    //   userObject?.academic_info_school_province !== "" &&
    //   userObject?.academic_info_school_disctrict !== "" &&
    //   userObject?.combination !== "" &&
    //   userObject?.academic_level !== "" &&
    //   userObject?.academic_info_country !== "" &&
    //   userObject?.userId !== ""
    // ) {
    //   dispatch(actions.completeprofileinformation(userObject));
    //   dispatch(actions.updateUserAccountInfo(userObject));
    //   dispatch(completeSignup("Academic Information"));
    // } else {
    //   setIsSubmitted(true);
    //   setdataerror("Some data are missing");
    // }
  };

  return (
    <>
      {loading ? (
        <Spinner size="32" color="blue" />
      ) : (
        <div className="bg-white drop-shadow-md mt-5 p-2 md:px-10 md:h-tab-screen md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
          <CardTitle name="Academic Information" color="black" />
          <div className="flex space-x-2 md:grid grid-cols-3 gap-2">
            <Input
              label="Curriculum"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: modules,
              }}
              value={curriculum}
              changed={setCurriculum}
              validation={{ required: true }}
              shouldValidate
              error="Curriculum is required"
            />
          </div>
          <CardTitle name="Class levels" color="black" />
          <CardSubText
            name="Choose which class levels present in the school"
            alignment="left"
            color="black"
          />
          <div className="flex space-x-2 grid grid-cols-2 gap-2">
            <div className="flex space-x-2 ">
              <Input
                label="S1"
                elementType="checkbox"
                elementConfig={{}}
                validation={{ required: false }}
                shouldValidate
              />
              <Input
                label="S2"
                elementType="checkbox"
                elementConfig={{}}
                validation={{ required: false }}
                shouldValidate
              />
              <Input
                label="S3"
                elementType="checkbox"
                elementConfig={{}}
                validation={{ required: false }}
                shouldValidate
              />
              <Input
                label="S4"
                elementType="checkbox"
                elementConfig={{}}
                validation={{ required: false }}
                shouldValidate
              />
              <Input
                label="S5"
                elementType="checkbox"
                elementConfig={{}}
                validation={{ required: false }}
                shouldValidate
              />
              <Input
                label="S6"
                elementType="checkbox"
                elementConfig={{}}
                validation={{ required: false }}
                shouldValidate
              />
            </div>
          </div>
          <CardTitle name="Combinations" color="black" />
          <CardSubText
            name="If you have an advanced level, check yes!"
            alignment="left"
            color="black"
          />
          <div className="flex flex-col">
            <Input
              label="Yes"
              elementType="checkbox"
              elementConfig={{
                type: "checkbox",
              }}
              validation={{ required: false }}
              value={isAdvancedLevelAvailable}
              changed={setisAdvancedLevelAvailable}
              shouldValidate
            />

            <Input
              elementType="multipleselect"
              elementConfig={{
                defaultOptions: [],
                options: cbccombinations,
                disabled: !isAdvancedLevelAvailable
              }}
              name="combinations"
              getMultiselectedCombinations={getMultiselectedCombinations}
              changed={setCombination}
              validation={{ required: true }}
              shouldValidate
              error="combinations are required"
            />
          </div>

          <div className="md:hidden">
            <div className="flex space-x-2"></div>
          </div>
          <div className="hidden md:grid grid-cols-3 gap-2"></div>

          <Message type={"error"} viewable={true} message={dataerror} />
          <div className="flex space-x-2 justify-center py-5">
            <Button name="Previous" outline="true" color="blue" />
            <Button
              name="Next"
              outline="true"
              color="blue"
              clicked={submitacademicinfo}
            />
          </div>
        </div>
      )}
    </>
  );
};

const getcombinationsList = (
  programObject,
  program = "CBC",
  section = "Advanced level"
) => {
  let combinations = [];
  if (section.includes("Ordinary level")) {
  } else {
    combinations = Object.keys(
      programObject[program]["structure"][section].Combinations
    );
  }
  return combinations;
};

export default AcademicInformation;
