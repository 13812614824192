import { updateObject } from "../../shared/utility";
import {
  GET_EXTRA_PACKAGE_START,
  GET_EXTRA_PACKAGE_FAIL,
  GET_EXTRA_PACKAGE_SUCCESS,
} from "../actions/extrapackage";

const initialReducer = {
  extras: [
    {
      id: "1",
      usertype: "student",
      name: "Academic level",
      amount: "1000",
      features:
        "Get Access to content of one additional class levels of your choice",
      currency: "RWF",
      frequency: "PER LEVEL",
      status: "2022-05-01",
      discount: .5
    },
  ],
  extrasLoading: null,
  extrasError: null,
};

const getExtraPackageStart = (state, action) => {
  return updateObject(state, {
    extras: [],
    extrasError: null,
    extrasLoading: true,
  });
};

const getExtraPackageSuccess = (state, action) => {
  return updateObject(state, {
    extras: action.payload,
    extrasError: null,
    extrasLoading: false,
  });
};

const getExtraPackageFail = (state, action) => {
  return updateObject(state, {
    extras: [],
    extrasError: action.error,
    extrasLoading: false,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case GET_EXTRA_PACKAGE_START:
      return getExtraPackageStart(state, action);
    case GET_EXTRA_PACKAGE_SUCCESS:
      return getExtraPackageSuccess(state, action);
    case GET_EXTRA_PACKAGE_FAIL:
      return getExtraPackageFail(state, action);
    default:
      return state;
  }
};

export default reducer;
