import { updateObject } from '../../../shared/utility';
import { CHANGE_CHOSEN_SUBJECT } from '../../actions/features/pastpaper';

const initialReducer = {
  chosenSubject: null,
};

const changeChosenSubject = (state, action) => {
  return updateObject(state, {
    chosenSubject: action.payload,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case CHANGE_CHOSEN_SUBJECT:
      return changeChosenSubject(state, action);
    default:
      return state;
  }
};

export default reducer;
