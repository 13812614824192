import Layout from '../../../components/common/Layout';
import Dashboard from '../../../components/pages/school/schedule/dashboard';

const Users = () => {
  return (
    <Layout>
      <Dashboard />
    </Layout>
  );
};
 
export default Users; 
