
import { TabPanel, useTabs } from 'react-headless-tabs';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef } from "react";
import { MdMenu } from 'react-icons/md';
import { IoSettingsOutline } from 'react-icons/io5';
import { useHistory } from "react-router-dom";
import parse from 'react-html-parser';
import { propTypes } from 'react-bootstrap/esm/Image';
import { CardBody } from '../../../../elements/text';
import { Button } from '../../../../elements/button';
import Spinner from '../../../../elements/spinner';
import axios from "../../../../../axios-base";
const DiscoverGroupItem = (props) => {
    const [memberShipRequested, setMemberShipRequested] = useState(false);
    const [loading, setLoading] = useState(false);
    const userAuth = useSelector((state) => state.auth);

    useEffect(() => {   

    }, []);
    const customClassFlex = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: 100
    };
    const customClassFlexItem = {
        width: "30%",
        height: 50,
        backgroundColor: "lightblue",
        textAlign: "center",
        lineHeight: 50
    };
    return (
        <div key={props.groupData.id} className="pt-3 flex  justify-left space-x-5 w-full my-5 filter drop-shadow rounded-2xl"  >
            <div className="flex flex-col w-60">
                <CardBody
                    name={props.groupData.groupName.split("##")[0]}
                    color="blue"
                    additional="font-bold"
                />
                <CardBody
                    name={props.groupData.groupDescription}
                    color="gray-500"
                    additional="font-bold"
                />
            </div>
            <div className="flex divide-x divide-gray-500">
                {(!memberShipRequested) ? (!loading) ? <Button
                    isSmall={true}
                    name="Join the group"
                    outline="true"
                    color="blue"
                    clicked={() => {
                        setLoading(true)
                        axios
                            .post(
                                `/opanda/community/get_n_community_join_a_group`,
                                {
                                    academic_level: userAuth.academic_level,
                                    user_id: userAuth.userId,
                                    group_id: props.groupData.id,
                                    visibility: props.groupData.groupVisibilityStatus
                                },
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                        "Authorization": `Bearer ${userAuth.token}`
                                    }
                                }
                            )
                            .then((response) => {
                                setMemberShipRequested(true)
                                setLoading(false)

                            })
                            .catch((err) => {
                                setLoading(false)
                            });
                    }}
                /> : <Spinner size="20" color="blue" /> : <span style={{ color: "green", fontWeight: "bold" }}>Request sent</span>}
            </div></div>
    );
};

export default DiscoverGroupItem;
