import {
  CardBody,
  CardTitle,
  FeatureTitle,
  PageTitle,
} from "../../../../elements/text";
import Input from "../../../../elements/input";
import { Button, ButtonLink } from "../../../../elements/button";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  updateUserAccountPassword,
} from "../../../../../store/actions/auth";
import { useEffect } from "react";
import axios from "../../../../../axios-base";
import { authLogout } from "../../../../../store/actions/auth";
import { store } from "../../../../../store/store";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaRegCopy } from "react-icons/fa";
import { generateOfflineToken } from "../../../../../store/actions/student.dashboard.settings.offline";
const Notification = () => {
  const dispatch = useDispatch();
  const { email, id, user_type } = useSelector((state) => state.auth.user);
  const offlinetoken  = useSelector((state) => state.studentDashboardOffline.offlinetoken);
  const { token } = useSelector((state) => state.auth);
  const passwordReset = useSelector((state) => state.auth.passwordReset);
  const memoizedUserEmail = useMemo(() => email, [email]);
  const memoizedId = useMemo(() => id, [id]);
  const [emailInput, setEmail] = useState(memoizedUserEmail);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [deletionPassword, setDeletionPassword] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    setPassword("");
    setConfirmPassword("");
  }, [passwordReset]);

  useEffect(() => {
    dispatch(
      generateOfflineToken({
        user_id: memoizedId,
        token: token
      })
    );
  }, [dispatch, memoizedId, token]);
console.log(offlinetoken)
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  return (
    <div className="pt-2 px-2 bg-white md:h-tab-screen md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
      <FeatureTitle name="Account information" color="black" />
      <div className="relative overflow-x-auto sm:rounded-lg">
        <CardTitle name="Account settings" color="black" />
        {true ? (
          <>
            <div class="grid md:grid-cols-4 gap-4 mb-2">
              {true ? (
                <>
                  <Input
                    label="Email"
                    elementType="input"
                    disabled={true}
                    elementConfig={{
                      type: "email",
                      placeholder: "Email address",
                      disabled: true,
                    }}
                    value={emailInput}
                    changed={setEmail}
                    validation={{ required: true }}
                    shouldValidate
                    error="Email address is required"
                  />
                  <div
                    style={{ display: "none" }}
                    className="flex align-end flex-end mt-auto"
                  >
                    <ButtonLink
                      page="whoisitfor"
                      name="Save"
                      outline="false"
                      color="red"
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <CardTitle name="Change password" color="black" />
            <div class="grid md:grid-cols-4 gap-4 mb-2">
              <div>
                <Input
                  label="Password"
                  elementType="password"
                  elementConfig={{
                    type: "password",
                    placeholder: "Password",
                  }}
                  value={password}
                  changed={setPassword}
                  validation={{ required: true }}
                  shouldValidate
                  error="Password field is required!"
                />
              </div>
              <div>
                <Input
                  label="Confirm password"
                  elementType="password"
                  elementConfig={{
                    type: "password",
                    placeholder: "Password",
                  }}
                  value={confirmPassword}
                  changed={setConfirmPassword}
                  validation={{ required: true }}
                  shouldValidate
                  error="Password field is required!"
                />
              </div>

              <div className="flex align-end flex-end mt-auto">
                <Button
                  name="Save"
                  outline="true"
                  color="blue"
                  clicked={() => {
                    if (password !== confirmPassword || password === "") {
                      setConfirmPassword("");
                      setPassword("");
                      alert("Passwords must match and not be empty!?!!");
                      return;
                    }
                    if (
                      window.confirm(
                        "Are you sure you want to update your password!?!!"
                      )
                    ) {
                    } else {
                      return;
                    }
                    dispatch(
                      updateUserAccountPassword({
                        userId: memoizedId,
                        password: password,
                      })
                    );
                  }}
                />
              </div>
            </div>
            <CardTitle
              name={
                passwordReset
                  ? "The password has been reset successfully!?!!"
                  : ""
              }
              color="green"
            />
          </>
        ) : (
          <></>
        )}
        {true ? (
          <>
            <div className="p-3 space-y-4">
              <span className="flex flex-row justify-items-center items-center">
                <CardTitle name="Offline token" color="black" />
                <AiOutlineInfoCircle
                  color="#183F71"
                  size={18}
                ></AiOutlineInfoCircle>
              </span>
              <span className="flex flex-row justify-items-center items-center space-x-4">
                <PageTitle
                  name={
                    offlinetoken === 0 ||
                    offlinetoken === "" ||
                    offlinetoken === null ||
                    offlinetoken === undefined
                      ? "No token found"
                      : offlinetoken.token
                  }
                  color="blue"
                />{" "}
                <CopyToClipboard
                  text={
                    offlinetoken === 0 ||
                    offlinetoken === "" ||
                    offlinetoken === null ||
                    offlinetoken === undefined
                      ? ""
                      : offlinetoken.token
                  }
                  onCopy={onCopyText}
                >
                  <div className="copy-area span-x-2">
                    <FaRegCopy className="cursor-pointer transition ease-in duration-200 hover:text-blue focus:outline-none" />
                    <span
                      className={`copy-feedback text-sm font-normal text-center text-blue ${
                        isCopied ? "active" : "hidden"
                      }`}
                    >
                      Copied!
                    </span>
                  </div>
                </CopyToClipboard>
                <Button
                  name="Generate"
                  outline="true"
                  color="blue"
                  clicked={() => {
                    if (
                      offlinetoken === 0 ||
                      offlinetoken === "" ||
                      offlinetoken === null ||
                      offlinetoken === undefined
                    ) {
                      dispatch(
                        generateOfflineToken({
                          user_id: memoizedId,
                          token: token
                        })
                      );
                      return;
                    } else {
                      alert("Token already exists");
                    }
                  }}
                />
              </span>
            </div>

            <CardTitle
              name={
                passwordReset
                  ? "The password has been reset successfully!?!!"
                  : ""
              }
              color="green"
            />
          </>
        ) : (
          <></>
        )}
        <CardTitle name="Account deletion" color="black" />
        <CardBody
          name="By consenting to delete your account, you consent to the following:"
          color="black"
        />
        <ol>
          <li>
            1. You will lose access to all content associated with your account.
          </li>
          <li>2. You will not be able to reactivate your account.</li>
          <li>
            3. Your account information and settings will be permanently
            deleted.
          </li>
          <li>
            4. You will lose any benefits or privileges associated with your
            account.
          </li>
        </ol>
        <div class="grid md:grid-cols-2 gap-2">
          <div>
            <Input
              label="If you agree, type your password here below"
              elementType="password"
              elementConfig={{
                type: "password",
                placeholder: "Password",
              }}
              value={deletionPassword}
              changed={setDeletionPassword}
              validation={{ required: true }}
              shouldValidate
              error="Password is required"
            />
          </div>
          <div className="flex align-end flex-end  overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
            <div
              onClick={() => {
                if (deletionPassword !== "") {
                } else {
                  alert("Provide your password, please!?!");
                  return;
                }
                if (
                  window.confirm(
                    "Are you sure, you want to delete the account!?!"
                  )
                ) {
                  //---
                  axios
                    .put(
                      `/opanda/panda-users/delete-user-account`,
                      {
                        userId: id,
                        user_type: user_type,
                        deletionPassword: deletionPassword,
                        email: email,
                      },
                      {
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${token}`,
                        },
                      }
                    )
                    .then((response) => {
                      if (response?.data?.account_status === true) {
                        alert(
                          "Deletion was successful!?!, You are about to lose access now!?!!"
                        );
                        store.dispatch(authLogout());
                      } else {
                        alert("Deletion failed!! Password Incorrect!?!");
                      }
                    })
                    .catch((err) => {});
                }
              }}
            >
              <ButtonLink page="#" name="Delete" outline="false" color="red" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
