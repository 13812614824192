import { CardTitle } from "./text";
import StudentIcon from "../../images/icons/students.svg";
import TeacherIcon from "../../images/icons/teachers.svg";
import ParentIcon from "../../images/icons/parents.svg";
import SchoolIcon from "../../images/icons/schools.svg";
import CreatorIcon from "../../images/icons/book-solid.svg";

export const ImageIcon = function (props) {
  let homeCardUser = props.user.toLowerCase();
  let imgsrc = "";
  let classes = "";
  if (homeCardUser === "student") {
    classes = "bg-green rounded-xl w-full md:w-2/3 p-6 mx-auto";
    imgsrc = StudentIcon;
  } else if (homeCardUser === "teacher") {
    classes = "bg-yellow rounded-xl  w-full md:w-2/3 p-6   mx-auto";
    imgsrc = TeacherIcon;
  } else if (homeCardUser === "parent") {
    classes = "bg-gray-500 rounded-xl  w-full md:w-2/3 p-6  mx-auto";
    imgsrc = ParentIcon;
  } else if (homeCardUser === "school") {
    classes = "bg-blue rounded-xl  w-full md:w-2/3 p-6  mx-auto";
    imgsrc = SchoolIcon;
  } else if (homeCardUser === "creators") {
    classes = "bg-red rounded-xl  w-full md:w-2/3 p-6  mx-auto";
    imgsrc = CreatorIcon;
  }

  return (
    <div>
      <div className={classes}>
        <div className="aspect-w-1 aspect-h-1">
          <img alt={props.user} className="h-96px w-auto m-auto" src={imgsrc} />
        </div>
      </div>
      {props.subtitle ? <CardTitle name={props.user} alignment="center" /> : ""}
    </div>
  );
};
