import Layout from '../../components/common/Layout';
import Community from '../../components/pages/teacher/community/Community';

const TeacherCommunityContainer = () => {
  return (
    <Layout>
      <Community />
    </Layout>
  );
};

export default TeacherCommunityContainer;
