import { useEffect, useState } from 'react';
import { getDayFromDate } from '../../../shared/utility';
import { student_suggested_features_start,student_subject_specialization_start,student_trending_subjects_start, student_new_subjects_start,student_recommended_subjects_start } from '../../../store/actions/student.dashboard.homepage';
import DayItem from './dayItem';
import { useSelector, useDispatch } from "react-redux";
const Days = () => {
  const dispatch = useDispatch();
  const userAth = useSelector((state) => state.auth);;
  const [dates, setDates] = useState([]);
  useEffect(() => {
    let recentDates = [];
    const today = new Date();
    for (let i = 0; i < 4; i++) {
      let date = new Date();
      const newDate = date.setDate(today.getDate() - i);
      let day = new Date(date);
      recentDates.unshift({
        date: day.getDate(),
        day: getDayFromDate(newDate),
        datetime:date.getTime(),
        selected: i === 0,
      });
      setDates(recentDates);
    }
  }, []);
  const handleClick = (index) => {
    let newDates = dates.map((date) => {      
      return { ...date, selected: false };
    });
    newDates[index].selected = true;
    dispatch(
      student_suggested_features_start({
        token: userAth.token,
        username: userAth.username,
        userId: userAth.userId,
        day: newDates[index].datetime
      })
    );
    dispatch(student_subject_specialization_start({
      token: userAth.token,
      username: userAth.username,
      userId: userAth.userId,
      day: new Date().getTime(),
      academic_level: userAth.academic_level
    }));
    dispatch(student_trending_subjects_start({
      token: userAth.token,
      username: userAth.username,
      userId: userAth.userId,
      day: new Date().getTime(),
      academic_level: userAth.academic_level
    }))
    dispatch(student_new_subjects_start({
      token: userAth.token,
      username: userAth.username,
      userId: userAth.userId,
      day: new Date().getTime(),
      academic_level: userAth.academic_level
    }))
    dispatch(student_recommended_subjects_start({
      token: userAth.token,
      username: userAth.username,
      userId: userAth.userId,
      day: new Date().getTime(),
      academic_level: userAth.academic_level
    }))
    
   
    setDates(newDates);
  };

  return (
    <div className="flex space-x-4 justify-between md:justify-start my-3 md:mx-4">
      {dates.map((date, index) => (
        <DayItem key={index} {...date} clicked={() => handleClick(index)} />
      ))}
    </div>
  );
};

export default Days;
