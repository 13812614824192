import { useDispatch, useSelector } from 'react-redux';
import { NavList } from '../../elements/sidebar/auth/Navlist';
import { NavSubItem } from '../../elements/sidebar/auth/NavSubItem';
import { TabPanel, useTabs } from 'react-headless-tabs';
import { GiGears } from 'react-icons/gi';
import { FaDesktop, FaRegUser, FaUsers } from 'react-icons/fa';
import { MdMessage, MdOutlineSpaceDashboard } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import {
  changeStudentDashboardMobileVisibility,
  changeStudentDashboardtab,
  changeStudentDashboardMenu,
} from '../../../store/actions/sidebaractiontypes';
import { getPrograms } from '../../../functions/programs';
import { useEffect } from 'react';
import * as actions from '../../../store/actions/';
import eventBus from '../../../utils/eventBus';

export const tabs = [
  {
    name: 'Dashboard',
    tab: 'dashboard',
    icon: <MdOutlineSpaceDashboard color="#5CA9AC" size="24" />,
    mainPanel: 'dashboard',
    subTabs: [],
    mainPath: '/teacher/',
  },
  {
    name: 'Programs',
    tab: 'programs',
    icon: <FaDesktop color="#5CA9AC" size="24" />,
    mainPanel: 'cbc',
    mainPath: '/teacher/curriculum/cbc',
    subTabs: [
      { name: 'CBC', tabName: 'cbc', path: '/teacher/curriculum/cbc' },
      {
        name: 'DAP',
        tabName: 'dap',
        path: '/teacher/curriculum/dap',
      },
    ],
  },
  {
    name: 'Features',
    tab: 'features',
    icon: <GiGears color="#5CA9AC" size="24" />,
    mainPanel: 'summarynotes',
    mainPath: '/teacher/features/summarynotes',
    subTabs: [
      // {
      //   name: 'Classes',
      //   tabName: 'classes',
      //   path: '/teacher/features/classes',
      // },
      // {
      //   name: 'Schedule',
      //   tabName: 'schedule',
      //   path: '/teacher/features/schedule',
      // },
      {
        name: 'Summary Notes',
        tabName: 'summarynote',
        path: '/teacher/features/summarynotes/0',
      },
      {
        name: 'Simulations',
        tabName: 'simulations',
        path: '/teacher/features/simulations',
      },
      { name: 'Tests', tabName: 'tests', path: '/teacher/features/tests' },
      {
        name: 'Past Papers',
        tabName: 'pastpapers',
        path: '/teacher/features/pastpapers',
      },
      {
        name: 'Library',
        tabName: 'library',
        path: '/teacher/features/library',
      },
    ],
  },
  {
    name: 'Community',
    tab: 'community',
    icon: <FaUsers color="#5CA9AC" size="24" />,
    mainPanel: 'community',
    mainPath: '/teacher/community/',
    subTabs: [],
  },
  // {
  //   name: 'Communication',
  //   tab: 'comunication',
  //   icon: <MdMessage color="#5CA9AC" size="24" />,
  //   mainPanel: 'communication',
  //   mainPath: '/teacher/communication/',
  //   subTabs: [],
  // },
  {
    name: 'User',
    tab: 'user',
    icon: <FaRegUser color="#5CA9AC" size="24" />,
    mainPanel: 'profile',
    mainPath: '/teacher/user/profile',
    subTabs: [
      { name: 'Profile', tabName: 'profile', path: '/teacher/' },
      { name: 'Settings', tabName: 'settings', path: '/teacher/user/settings' },
      // { name: 'Feedback', tabName: 'feedback', path: '/teacher/user/feedback' },
      {
        name: 'Help Center',
        tabName: 'helpcenter',
        path: '/teacher/user/helpcenter',
      },
      //  {
      //   name: 'Payment',
      //   tabName: 'payment',
      //   path: '/teacher/user/payment',
      // },
    ],
  },
];
export const TRIGGER_OPEN_FEATURES_TAB = "TRIGGER_OPEN_FEATURES_TAB";
const SideBar = function (props) {
  const studentdashboardMenuselected = useSelector(
    (state) => state.reducer.studentdashboardMenuselected
  );
  const [selectedTab, setSelectedTab] = useTabs(
    ['dashboard', 'programs', 'features', 'forum', 'extracurricular', 'user'],
    studentdashboardMenuselected
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const changetab = (tab) => {
    dispatch(changeStudentDashboardtab(tab));
  };
  const isSidebarCollapsed = useSelector(
    (state) => state.reducer.sideBarCollapse
  );
  const usertype = useSelector((state) => state.auth.user.user_type);
  const techerprograms = useSelector((state) => state.features.programs.programs)
  tabs[1].subTabs = [...getPrograms(techerprograms, usertype)];
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    dispatch(actions.getTeacherPrograms(token));

    eventBus.on(TRIGGER_OPEN_FEATURES_TAB, () => {
      dispatch(changeStudentDashboardMenu("features"));
      setSelectedTab("features");
      changetab('summarynotes');
    });

    return () => {
      // Unsubscribe from the event when the component unmounts
      eventBus.off(TRIGGER_OPEN_FEATURES_TAB, () => { });
    };
  }, [])
  return (
    <div className="w-full relative bg-tiffany-blue shadow h-full flex-col justify-between flex overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
      <div className={`mt-10 ${isSidebarCollapsed ? '' : 'px-8'} h-full`}>
        {tabs.map((item, index) => (
          <NavList
            key={index}
            name={item.name}
            isActive={selectedTab === item.tab}
            icon={item.icon}
            subTabs={item.subTabs}
            onClick={() => {
              dispatch(changeStudentDashboardMenu(item.tab));
              dispatch(changeStudentDashboardMobileVisibility('hidden'));
              setSelectedTab(item.tab);
              changetab(item.mainPanel);
              if (item.subTabs.length === 0) {
                history.push(item.mainPath);
              }
            }}
          >
            <TabPanel
              hidden={selectedTab !== item.tab}
              className="transform transition duration-500 ease-in-out"
            >
              {item.subTabs.map((tab, index) => (
                <NavSubItem
                  key={index}
                  name={tab.name}
                  tab={tab.tabName}
                  onClick={() => {
                    changetab(tab.tabName);
                    dispatch(changeStudentDashboardMobileVisibility('hidden'));

                    history.push(tab.path);
                  }}
                />
              ))}
            </TabPanel>
          </NavList>
        ))}
      </div>
    </div>
  );
};
export default SideBar;
