import { ContentContainer } from "../../../common/content/container2";
import { PageBody, SectionTitle } from "../../../elements/text";
import Notification from "../../../elements/notification/notification";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import * as actions from "../../../../store/actions/";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import * as constants from "../../../../constants/constants";
import Spinner from "../../../elements/spinner";
import Input from "../../../elements/input";
import AlertModal from "../../../elements/modal/AlertModal";
import ConfirmModal from "../../../elements/modal/ConfirmModal";

export const CBC = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const subjectinputRef = useRef(null);
  const [level, setlevel] = useState();
  const [subject, setsubject] = useState("");
  const modules = useSelector((state) => state.previewpanda.modules);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);

 

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

 
  const modulesLoading = useSelector(
    (state) => state.previewpanda.modulesLoading
  );

  const testmaps = useSelector((state) => state.testmaps.testmaps);
  const testmapsLoading = useSelector(
    (state) => state.testmaps.testmapsLoading
  );
  const classcourses = useSelector((state) => state.previewpanda.classcourses);
  const classcoursesLoading = useSelector(
    (state) => state.previewpanda.classcoursesLoading
  );
  const subjectsnames = useSelector(
    (state) => state.previewpanda.subjectsnames
  );

  const handleSubjectChange = (value) => {
    if (level) {
      dispatch(actions.getSubjectClassCourses(level, value));
      dispatch(actions.getSubjectLevelTestMaps(value, level));
      setsubject(value);
    } else {
      //alert(`Select level first`);
      setShowAlert(true);
      setAlertMessage(`Select level first`);
    }
  };

  const handleLevelChange = (value) => {
    setlevel(value);
    if(subject !== ""){
      dispatch(actions.getSubjectClassCourses(value, subject));
      dispatch(actions.getSubjectLevelTestMaps(subject, value));
    } else {
      setShowAlert(true);
      setAlertMessage(`Select subject first`);
    }

  };

  useEffect(() => {
    dispatch(actions.getSubjectNames());
  }, []);

  return (
    <div
      style={{ height: "100vh" }}
      className="container flex flex-col items-start relative  overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100 p-3 pt-5 py-5 bg-gray-200"
    >
      
      
      {showAlert && (
        <AlertModal message={alertMessage} onClose={handleCloseAlert} />
      )}

     
      {modulesLoading ? (
        <div className="flex flex-row justify-center w-full">
          <Spinner size="20" color="blue" />
        </div>
      ) : (
        <>
          <div className="md:w-50% lg:w-60% xl:w-65%">
            <SectionTitle
              name={modules[props.index]?.fullname}
              alignment="left"
              color="blue"
            />
            <PageBody
              name={modules[props.index]?.description}
              alignment="left"
              color="blue"
            />
          </div>

          <form className="w-full max-w-lg my-4 ">
            <div className="flex flex-wrap mb-2 space-x-4 ">
              <div className="w-1/4">
                <Input
                  elementType="select"
                  elementConfig={{
                    startingValue: "LEVEL",
                    options: constants.cbclevels,
                  }}
                  value={level}
                  changed={handleLevelChange}
                  ref={subjectinputRef}
                />
              </div>
              <div className="w-2/4">
                <Input
                  elementType="select"
                  elementConfig={{
                    startingValue: "SELECT SUBJECT",
                    options: subjectsnames,
                  }}
                  value={subject}
                  changed={handleSubjectChange}
                />
              </div>
            </div>
          </form>
          <>
            {classcoursesLoading ? (
              <Spinner color="blue" size={32} />
            ) : (
              <ContentContainer
                name={t("Courses")}
                kind={"Courses"}
                data={classcourses}
                slider={true}
                type="one"
                page="previewpanda"
                isCourse="true"
              />
            )}
          </>
          <>
            {testmapsLoading ? (
              <Spinner color="blue" size={32} />
            ) : (
              <ContentContainer
                name={t("Tests")}
                data={testmaps}
                kind={"Tests"}
                slider={true}
                type="one"
                page="previewpanda"
                isCourse="false"
              />
            )}
          </>
        </>
      )}
    </div>
  );
};
