import Layout from '../../../components/common/Layout';
import ClassesContainer from './../../../components/pages/teacher/classes/dashboard';

const Classes = () => {
  return (
    <Layout>
      <ClassesContainer />
    </Layout>
  );
};

export default Classes; 
