import React, { useEffect, useState } from 'react';
import AddMultipleQuestion from '../../../common/features/tests/testtypes/multipleChoice/AddMultipleQuestion';
import AddOpenEndedQuestion from '../../../common/features/tests/testtypes/openended/AddOpenEndedQuestion';
import AddCompleteSentenceQuestion from '../../../common/features/tests/testtypes/completesentence/AddCompleteSentenceQuestion';
import AddDragAndDropQuestion from '../../../common/features/tests/testtypes/draganddrop/AddDragAndDropQuestion';
import Input from '../../../elements/input';
import { Button } from '../../../elements/button';
import { CardBody, CardSubText, CardTitle } from '../../../elements/text';
import PageNavigation from './PageNavigation';
import Modal from '../../../elements/modal/modal';
import TestEditorMultipleChoiceQuestionBank from './TestEditorMultipleChoiceQuestionBank';
import ReactDOMServer from 'react-dom/server';

function TestEditor(props) {
    const [questions, setQuestions] = useState([]);
    const [questionNber, setQuestionNber] = useState(-1);
    const [question_type, setQuestionType] = useState("");
    const [isShowMultipleChoiceModalShowing, setisShowMultipleChoiceModalShowing] = useState(false);
    const [isQuestionsFromStorageEnabled, setQuestionsFromStorageEnabled] = useState(false);


    const addQuestion = (type) => {
        if (type === 'multiple_choice') {
            if (props.unit == "UNIT" || props.level == "SELECT" || props.subject == "UNIT") {
                alert("Please select a unit, level, and subject before adding a question.")
                return;
            }
            setisShowMultipleChoiceModalShowing(true)
            return;
            /*const newQuestion = {
                id: questionNber + 1,
                answers: [],
                marks: 2,
                duration: 2,
                question: "",
                explanation: "",
                type: 'multiple_choice'
            };
            setQuestions([...questions, newQuestion]);
            setQuestionNber(questionNber + 1)*/

        } else if (type === 'open_ended') {
            const newQuestion = {
                id: questionNber + 1,
                marks: 2,
                duration: 2,
                question: '',
                explanation: "",
                type: 'open_ended'
            };
            setQuestions([...questions, newQuestion]);
            setQuestionNber(questionNber + 1)
        } else if (type === 'complete_sentence') {
            const newQuestion = {
                id: questionNber + 1,
                selectedWords: [],
                marks: 2,
                duration: 2,
                question: '',
                type: 'complete_sentence'
            };
            setQuestions([...questions, newQuestion]);
            setQuestionNber(questionNber + 1)
        } else if (type === 'drag_and_drop') {
            const newQuestion = {
                id: questionNber + 1,
                selectedWords: [],
                marks: 2,
                duration: 2,
                question: '',
                type: 'drag_and_drop'
            };
            setQuestions([...questions, newQuestion]);
            setQuestionNber(questionNber + 1)
        }

    };

    const addMultipleChoiceQuestion = () => {
    }

    const updateQuestion = (id, updatedQuestion) => {
        setQuestions(questions.map((q) => (q.id === id ? updatedQuestion : q)));
    };

    const deleteQuestion = (id) => {
        setQuestions(questions.filter((q) => q.id !== id));
        props.setQuestions(questions);
    };

    const saveTest = () => {
        console.log('Saving test:', JSON.stringify(questions, null, 2));
        // Here you would typically send the questions to a backend server
    };
    function getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const deleteCurrentPage = (currentPageToDelete) => {
        if (window.confirm("Are you sure you want to delete this page?")) {
            setQuestions((prevContent) => {
                const updatedContent = [...prevContent];
                updatedContent.splice(currentPageToDelete, 1);
                if (currentPageToDelete > -1) {
                    setQuestionNber((prevPage) => prevPage - 1);
                    props.setQuestions(questions);
                }
                return updatedContent;
            });
        }
    }

    const handleNext = () => {
        if (questionNber < questions.length - 1) {
            setQuestionNber(questionNber + 1);
            props.setQuestions(questions);
        }
    };

    const handlePrevious = () => {
        if (questionNber > 0) {
            setQuestionNber(questionNber - 1);
            props.setQuestions(questions);
        }
    };

    const addNewPage = () => {
        addQuestion(question_type)
        props.setQuestions(questions);
    };

    // Update the multiple_choice question data
    const updateMultipleChoiceQuestion = (id, updatedQuestion) => {
        const newData = questions;
        newData[id].question = updatedQuestion;
        setQuestionNber(id);
        setQuestions([...newData]);
        props.setQuestions(questions);
    };

    // Update the multiple_choice question total marks data
    const updateMultipleChoiceQuestionTotalmarks = (id, marks_) => {
        const newData = questions;
        var marks = marks_.replace(/[^0-9]/g, '');
        newData[id].marks = marks;
        setQuestionNber(id);
        setQuestions([...newData]);
        props.setQuestions(questions);
    };

    // Update the multiple_choice question total marks data
    const updateMultipleChoiceQuestionTotaltime = (id, duration_) => {
        const newData = questions;
        // Force duration to be a number between 1 and 10
        var duration = duration_.replace(/[^0-9]/g, '');
        newData[id].duration = duration;
        setQuestionNber(id);
        setQuestions([...newData]);
        props.setQuestions(questions);
    };

    // Update the multiple_choice question explanation data
    const updateMultipleChoiceQuestionExplanation = (id, explanation) => {
        const newData = questions;
        newData[id].explanation = explanation;
        setQuestionNber(id);
        setQuestions([...newData]);
        props.setQuestions(questions);
    };

    // Update the multiple_choice question explanation data
    const updateMultipleChoiceQuestionAnswers = (id, answers) => {
        const newData = questions;
        newData[id].answers = answers;
        setQuestions([...newData]);
        props.setQuestions(questions);
    };
    // Update drag and drop marks data
    const updateDragAndDropQuestionMarks = (id, mark) => {
        const newData = questions;
        var mark_ = mark.replace(/[^0-9]/g, '');
        newData[id].marks = mark_;
        setQuestions([...newData]);
        props.setQuestions(questions);
    };

    // Update drag and drop duration data
    const updateDragAndDropQuestionDuration = (id, duration_) => {
        const newData = questions;
        var duration = duration_.replace(/[^0-9]/g, '');
        newData[id].duration = duration;
        setQuestions([...newData]);
        props.setQuestions(questions);
    };

    // Update drag and drop question data
    const updateDragAndDropQuestionData = (id, question) => {
        const newData = questions;
        newData[id].question = question;
        setQuestions([...newData]);
        props.setQuestions(questions);
    };

    // Update drag and drop selected word data
    const updateDragAndDropQuestionSelectedWord = (id, answers) => {
        const newData = questions;
        newData[id].selectedWords = answers;
        setQuestions([...newData]);
        props.setQuestions(questions);
    };

    // Update CompleteSentence marks data
    const updateCompleteSentenceQuestionMarks = (id, marks_) => {
        const newData = questions;
        var mark = marks_.replace(/[^0-9]/g, '');
        newData[id].marks = mark;
        setQuestions([...newData]);
        props.setQuestions(questions);
    };
    // Update CompleteSentence duration data
    const updateCompleteSentenceQuestionDuration = (id, duration_) => {
        const newData = questions;
        var duration = duration_.replace(/[^0-9]/g, '');
        newData[id].duration = duration;
        setQuestions([...newData]);
        props.setQuestions(questions);
    };

    // Update CompleteSentence question data
    const updateCompleteSentenceQuestionData = (id, question) => {
        const newData = questions;
        newData[id].question = question;
        setQuestions([...newData]);
        props.setQuestions(questions);
    };

    // Update CompleteSentence selected word data
    const updateCompleteSentenceQuestionSelectedWord = (id, answers) => {
        const newData = questions;
        newData[id].selectedWords = answers;
        setQuestions([...newData]);
        props.setQuestions(questions);
    };

    // Update OpenEnded marks data
    const updateOpenEndedQuestionMarks = (id, marks_) => {
        const newData = questions;
        var mark = marks_.replace(/[^0-9]/g, '');
        newData[id].marks = mark;
        setQuestions([...newData]);
        props.setQuestions(questions);
    };

    // Update OpenEnded Duration data
    const updateOpenEndedQuestionDuration = (id, duration_) => {
        const newData = questions;
        var duration = duration_.replace(/[^0-9]/g, '');
        newData[id].duration = duration;
        setQuestions([...newData]);
        props.setQuestions(questions);
    };

    // Update OpenEnded question data
    const updateOpenEndedQuestionData = (id, question) => {
        const newData = questions;
        newData[id].question = question;
        setQuestions([...newData]);
        props.setQuestions(questions);
    };
    // Update OpenEnded Explanation data
    const updateOpenEndedExplanationData = (id, explanation) => {
        const newData = questions;
        newData[id].explanation = explanation;
        setQuestionNber(id);
        setQuestions([...newData]);
        props.setQuestions(questions);
    };

    const addQuestionsToUi = (questionsM) => {
        const newQuestions = questionsM.map((q, index) => {
            // render the question and answers using react-dom/server
            const question = ReactDOMServer.renderToString(q.question);
            const answers = q.answers.map((a) => {
                return { value: ReactDOMServer.renderToString(a.value), isAnswer: a.isAnswer }
            });
            return {
                id: questionNber + index + 1,
                answers: answers,
                marks: 2,
                duration: 2,
                question: question,
                explanation: "",
                type: 'multiple_choice'
            };

        })

        setisShowMultipleChoiceModalShowing(false);
        setQuestionsFromStorageEnabled(false);
        const data = [...questions, ...newQuestions];
        const data_ = data.map((q, index) => {
            q.id = questionNber + index + 1;
            return q;
        });
        setQuestions(data_);
        setQuestionNber(data_.length - 1)
        props.setQuestions(questions);
    }

    useEffect(() => {
        props.setQuestions(questions);
    }, [questions, questionNber]);



    return (
        <div className="p-4">
            {(questions.length > 0 && questionNber > -1) ? <PageNavigation pageCount={questions.length} currentPage={questionNber} deleteCurrentPage={deleteCurrentPage} addNewPage={addNewPage} onNext={handleNext} onPrevious={handlePrevious} /> : ""}
            <div >
                <Modal
                    medium
                    show={isShowMultipleChoiceModalShowing}
                    modalClosed={() => {
                        setisShowMultipleChoiceModalShowing(false)
                        setQuestionsFromStorageEnabled(false);
                    }}
                >
                    <CardTitle name="Find questions from the O'Genius Panda storage" />
                    {(!isQuestionsFromStorageEnabled) ? <div className="pt-3 flex justify-center space-x-5">
                        <Button
                            name="From O'Genius Panda storage"
                            outline="true"
                            color="blue"
                            clicked={() => {
                                //setisShowMultipleChoiceModalShowing(false);
                                setQuestionsFromStorageEnabled(true);
                            }}
                        />
                        <Button
                            name="Create the question manually"
                            outline="true"
                            color="blue"
                            clicked={() => {
                                setQuestionsFromStorageEnabled(false);
                                setisShowMultipleChoiceModalShowing(false);
                                const newQuestion = {
                                    id: questionNber + 1,
                                    answers: [],
                                    marks: 2,
                                    duration: 2,
                                    question: "",
                                    explanation: "",
                                    type: 'multiple_choice'
                                };
                                setQuestions([...questions, newQuestion]);
                                setQuestionNber(questionNber + 1)
                            }}
                        />
                        <Button
                            name="Cancel"
                            outline="true"
                            color="red"
                            clicked={() => {
                                setisShowMultipleChoiceModalShowing(false);
                                setQuestionsFromStorageEnabled(false);
                            }}
                        />
                    </div> : ""}
                    {(isQuestionsFromStorageEnabled) ? <TestEditorMultipleChoiceQuestionBank addQuestionsToUi={addQuestionsToUi} addMultipleChoiceQuestion={addMultipleChoiceQuestion} unit={props.unit} level={props.level} subject={props.subject} /> : ""}
                </Modal></div>
            <Input
                elementType="select"
                elementConfig={{
                    startingValue: "Choose question type",
                    options: [
                        { value: "multiple_choice", displayValue: "Multiple choice" },
                        { value: "drag_and_drop", displayValue: "Drag and Drop" },
                        { value: "complete_sentence", displayValue: "Complete sentence" },
                        { value: "open_ended", displayValue: "Open ended" },
                    ],
                }}
                value={question_type}
                changed={setQuestionType}
                validation={{ required: true }}
                shouldValidate
                error="Invalid filter"
            />
            {(true) ? <div className='m-4'><Button
                name="Add Question"
                outline="true"
                color="blue"
                clicked={() => {
                    addQuestion(question_type);
                }}
            /></div> : ""}
            <div className='mt-10' style={{ borderRadius: 5, borderTop: " 3px solid #cdcdcd" }}>
                {(questions.length > 0 && questionNber > -1) ? <CardTitle name={`Question type: ${questions[questionNber]?.type}`} color="black" /> : ""}
                {(questions.length > 0 && questions[questionNber]?.type == "multiple_choice")}
                {(questions.length > 0 && questions[questionNber]?.type == "open_ended") ? <AddOpenEndedQuestion updateOpenEndedExplanationData={updateOpenEndedExplanationData} updateOpenEndedQuestionData={updateOpenEndedQuestionData} updateOpenEndedQuestionDuration={updateOpenEndedQuestionDuration} updateOpenEndedQuestionMarks={updateOpenEndedQuestionMarks} questionNber={questionNber} key={questions[questionNber].id} question={questions[questionNber]} onDelete={() => deleteQuestion(questions[questionNber].id)} /> : ""}
                {(questions.length > 0 && questions[questionNber]?.type == "multiple_choice") ? <AddMultipleQuestion updateMultipleChoiceQuestionAnswers={updateMultipleChoiceQuestionAnswers} updateMultipleChoiceQuestionTotaltime={updateMultipleChoiceQuestionTotaltime} updateMultipleChoiceQuestionTotalmarks={updateMultipleChoiceQuestionTotalmarks} updateMultipleChoiceQuestionExplanation={updateMultipleChoiceQuestionExplanation} updateMultipleChoiceQuestion={updateMultipleChoiceQuestion} questionNber={questionNber} key={questions[questionNber].id} question={questions[questionNber]} onDelete={() => deleteQuestion(questions[questionNber].id)} /> : ""}
                {(questions.length > 0 && questions[questionNber]?.type == "complete_sentence") ? <AddCompleteSentenceQuestion updateCompleteSentenceQuestionSelectedWord={updateCompleteSentenceQuestionSelectedWord} updateCompleteSentenceQuestionData={updateCompleteSentenceQuestionData} updateCompleteSentenceQuestionDuration={updateCompleteSentenceQuestionDuration} updateCompleteSentenceQuestionMarks={updateCompleteSentenceQuestionMarks} questionNber={questionNber} key={questions[questionNber].id} question={questions[questionNber]} onDelete={() => deleteQuestion(questions[questionNber].id)} /> : ""}
                {(questions.length > 0 && questions[questionNber]?.type == "drag_and_drop") ? <AddDragAndDropQuestion updateDragAndDropQuestionSelectedWord={updateDragAndDropQuestionSelectedWord} updateDragAndDropQuestionData={updateDragAndDropQuestionData} updateDragAndDropQuestionDuration={updateDragAndDropQuestionDuration} updateDragAndDropQuestionMarks={updateDragAndDropQuestionMarks} questionNber={questionNber} key={questions[questionNber].id} question={questions[questionNber]} onDelete={() => deleteQuestion(questions[questionNber].id)} /> : ""}
            </div>

        </div>
    );
}

export default TestEditor;
