import StudentIcon from "../../../../images/icons/students.svg";
import TeacherIcon from "../../../../images/icons/teachers.svg";
import ClassesIcon from "../../../../images/icons/classes.svg";
import ParentsIcon from "../../../../images/icons/parents.svg";
import {
  CardBody,
  CardTitle,
  PageBody,
  PageTitle,
  SectionTitle,
  SmallText,
} from "../../../elements/text";
import Spinner from "../../../elements/spinner";

const UserSummary = (props) => {

  let content = <></>;
  if (props.user === "student") {
    content = (
      <div className="bg-green rounded-lg h-36 p-4 cursor-pointer flex flex-col items-start justify-center">
        <CardTitle name="Students" color="white" />
        <div className="grid grid-cols-2 gap-2 justify-items-center items-center">
          {props.loading ? <Spinner size="20" color="blue" /> : 
          <PageTitle name={props.number} color="white" /> }
          <div className="rounded-full mx-auto">
            <img
              src={StudentIcon}
              alt="student"
              className="h-36px w-auto m-auto"
            />
          </div>
        </div>
      </div>
    );
  } else if (props.user === "teacher") {
    content = (
      <div className="bg-yellow rounded-lg h-36 p-4 cursor-pointer flex flex-col items-start justify-center">
        <CardTitle name="Teachers" color="white" />
        <div className="grid grid-cols-2 gap-2 justify-items-center items-center">
        {props.loading ? <Spinner size="20" color="blue" /> : 
          <PageTitle name={props.number} color="white" /> }
          <div className="rounded-full mx-auto">
            <img
              src={TeacherIcon}
              alt="teacher"
              className="h-36px w-auto m-auto"
            />
          </div>
        </div>
      </div>
    );
  } else if (props.user === "school") {
    content = (
      <div className="bg-blue rounded-lg h-36 p-4 cursor-pointer flex flex-col items-start justify-center">
        <CardTitle name="Classes" color="white" />
        <div className="grid grid-cols-2 gap-2 justify-items-center items-center">
        {props.loading ? <Spinner size="20" color="blue" /> : 
          <PageTitle name={props.number} color="white" /> }
          <div className="bg-white rounded-full mx-auto">
            <img
              src={ClassesIcon}
              alt="classes"
              className="h-36px w-auto m-auto "
            />
          </div>
        </div>
      </div>
    );
  } else if (props.user === "parent") {
    content = (
      <div className="bg-blue rounded-lg h-36 p-4 cursor-pointer flex flex-col items-start justify-center">
        <CardTitle name="Parents" color="white" />
        <div className="grid grid-cols-2 gap-2 justify-items-center items-center">
        {props.loading ? <Spinner size="20" color="blue" /> : 
          <PageTitle name={props.number} color="white" /> }
          <div className="bg-blue rounded-full mx-auto">
            <img
              src={ParentsIcon}
              alt="parents"
              className="h-36px w-auto m-auto "
            />
          </div>
        </div>
      </div>
    );
  }
  return content;
};

export default UserSummary;
