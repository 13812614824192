import { Button, TextButton } from "../../../../elements/button";
import Table from "../../../../elements/table/table";
import { CardBody, CardTitle, FeatureTitle, SectionTitle, SmallText } from "../../../../elements/text";
import PageContent from "../../pageContent";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import PrevNext from "../../../landing/enroll/PrevNext";
import Modal from "../../../../elements/modal/modal";
import { useHistory } from "react-router-dom";
import { PaymentOptionsCard } from "../../../../elements/card";
import { setChosenPackage, changePaymentModalState } from "../../../../../store/actions/payment";
import { getHomeWhoIsOpandaFor } from "../../../../../store/actions";

const Payment = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const tableheader1 = ["Name", "Amount", "Description", "Action"];
  useEffect(() => {
    dispatch(getHomeWhoIsOpandaFor());
  }, []);
  const chosenProgram = useSelector((state) => state.payment.chosenProgram);
  const [showPayment, setShowPayment] = useState(false);
  const usertype = useSelector((state) => state.auth.user.user_type);
  
  const whoisitfor = useSelector((state) => state.home.whoisopandafor);
  let studentspackages;
  for (var i = 0; i < whoisitfor?.length; i++) {
    if (whoisitfor[i]?.usertype.toLowerCase() === usertype) {
      studentspackages = whoisitfor[i]?.userspricings;
    }
  }
  const [description, setDescription] = useState(studentspackages);
  useEffect(() => {
    setDescription(changedescriptiontable(chosenProgram, whoisitfor));
  }, [chosenProgram]);
  
  const showPaymentModal = useSelector(
    (state) => state.payment.showPaymentModal
  );
  const chosenPackage = useSelector((state) => state.payment.chosenPackage);

  const tablebody1 = [];
  Object.values(description)?.forEach((value, index) => {
    // convert object to key's array
    // iterate over object
    const actionbutton = (
      <TextButton
        name="Purchase"
        color="blue"
        clicked={() => {
          dispatch(setChosenPackage({ ...value, number: index + 1 }));
          dispatch(changePaymentModalState(true));
        }}
      />
    );
    tablebody1.push({
      td1: { data: value.pricingname, type: "text" },
      td2: {
        data:
          value.pricingamount +
          "" +
          value.pricingcurrency +
          " " +
          value.pricingfrequency,
        type: "text",
      },
      td4: { data: value.pricingfeatures, type: "text" },
      td6: {
        data: <>{actionbutton}</>,
        type: "component",
      },
    });
  });

  const changedescriptiontable = (program, whoisitfor) => {
    let data;
    switch (program) {
      case "CBC":
        for (var i = 0; i < whoisitfor?.length; i++) {
          if (whoisitfor[i]?.usertype.toLowerCase() === usertype) {
            data = whoisitfor[i]?.userspricings;
            break;
          }
        }

        break;
      case "CAMBRIDGE":
        data = [
          {
            id: "1",
            pricingname: "Daily",
            pricingamount: "500",
            pricingfeatures:
              "- Access to content of 1 class level of choice - Quizzes & tests, Lab simulations, Past papers and much more",
            pricingcurrency: "RWF",
            pricingfrequency: "PER DAY",
            status: "2022-05-01",
          },
          {
            id: "2",
            pricingname: "Daily",
            pricingamount: "500",
            pricingfeatures:
              "- Access to content of 1 class level of choice - Quizzes & tests, Lab simulations, Past papers and much more",
            pricingcurrency: "RWF",
            pricingfrequency: "PER DAY",
            status: "2022-05-01",
          },
          {
            id: "3",
            pricingname: "Daily",
            pricingamount: "500",
            pricingfeatures:
              "- Access to content of 1 class level of choice - Quizzes & tests, Lab simulations, Past papers and much more",
            pricingcurrency: "RWF",
            pricingfrequency: "PER DAY",
            status: "2022-05-01",
          },
          {
            id: "4",
            pricingname: "Daily",
            pricingamount: "500",
            pricingfeatures:
              "- Access to content of 1 class level of choice - Quizzes & tests, Lab simulations, Past papers and much more",
            pricingcurrency: "RWF",
            pricingfrequency: "PER DAY",
            status: "2022-05-01",
          },
        ];
        break;
      case "OTHER":
        data = [
          {
            id: "1",
            pricingname: "Daily",
            pricingamount: "500",
            pricingfeatures:
              "- Access to content of 1 class level of choice - Quizzes & tests, Lab simulations, Past papers and much more",
            pricingcurrency: "RWF",
            pricingfrequency: "PER DAY",
            status: "2022-05-01",
          },
          {
            id: "2",
            pricingname: "Daily",
            pricingamount: "500",
            pricingfeatures:
              "- Access to content of 1 class level of choice - Quizzes & tests, Lab simulations, Past papers and much more",
            pricingcurrency: "RWF",
            pricingfrequency: "PER DAY",
            status: "2022-05-01",
          },
        ];
        break;
      default:
        data = [
          {
            id: "1",
            name: "Daily",
            amount: "500",
            description:
              "- Access to content of 1 class level of choice - Quizzes & tests, Lab simulations, Past papers and much more",
            duration: "1",
            status: "2022-05-01",
          },
        ];
    }

    return data;
  };
  return (
    <PageContent>
      <Modal
        medium
        show={showPaymentModal}
        modalClosed={() => {
          dispatch(setChosenPackage(null));
          dispatch(changePaymentModalState(false));
        }}
      >
        <div className="w-80% m-auto py-5">
          <SectionTitle name="Here's what you selected" alignment="center" />
          <div className="flex justify-between items-center py-3">
            <div className="text-center">
              <CardTitle name={chosenProgram} color="blue" />
              <div className="flex space-x-2">
                <CardTitle name={`Package ${chosenPackage?.number}`} />
                <CardBody name={chosenPackage?.pricingname} />
              </div>
              <div className="flex space-x-2">
                <CardTitle
                  name={`${
                    chosenPackage?.pricingamount
                  } ${chosenPackage?.pricingcurrency?.toUpperCase()}`}
                />
                <CardBody name={chosenPackage?.pricingfrequency} />
              </div>
            </div>
            <div className="flex flex-col">
              {chosenPackage?.pricingfeatures
                ?.split(",")
                .map((feature, index) => (
                  <SmallText name={feature} color="blue" key={index} />
                ))}
            </div>
          </div>
          <div className="flex justify-around">
            <Button
              name={"Cancel"}
              outline="true"
              color="blue"
              clicked={() => {
                dispatch(setChosenPackage(null));
                dispatch(changePaymentModalState(false));
              }}
            />
            <Button
              name={"Continue"}
              outline="false"
              color="blue"
              clicked={() => history.push("/student/user/payment/options")}
            />
          </div>
        </div>
      </Modal>

      <div className="bg-white-blue md:flex w-full">
        <div className="w-full p-3 bg-white-blue">
          <FeatureTitle name="Payment" alignment="right" />
          <div className="relative overflow-x-auto sm:rounded-lg my-4">
            <div className="flex flex-row justify-between items-center">
              <CardTitle name="Package subscription" color="blue" />{" "}
              <PrevNext px="5" pageName=" " showProgram="false" />
            </div>
            <Table thead={tableheader1} tbody={tablebody1} />
          </div>
        </div>
      </div>
    </PageContent>
  );
};

export default Payment;
