import axios from "../../axios-base";
import ValidatingToken from "../../functions/validatingtoken";
import * as actionTypes from "./actionTypes.js";
import { authSuccess } from "./auth";

export const getProfilesStart = () => {
  return {
    type: actionTypes.GET_PROFILE_INFORMATION_START,
  };
};

export const getProfilesSuccess = (profiles) => {
  return {
    type: actionTypes.GET_PROFILE_INFORMATION_SUCCESS,
    profiles: profiles,
  };
};

export const getOneProfilesSuccess = (profile) => {
  return {
    type: actionTypes.GET_ONE_PROFILE_INFORMATION_SUCCESS,
    profile: profile,
  };
};

export const getProfilesFail = (error) => {
  return {
    type: actionTypes.GET_PROFILE_INFORMATION_FAIL,
    error: error,
  };
};

export const completeprofileinformation = (object) => {
  const token = localStorage.getItem("jwt");
  ValidatingToken(token);
  return (dispatch) => {
    dispatch(getProfilesStart());
    axios
      .put(`/opanda/profile-information/complete-infomation`, object, {
        headers:{authorization:token}
      })
      .then((response) => {
        dispatch(getOneProfilesSuccess(response.data.results));
        const user = response.data.results?.user;
        const authData = { token, user };
        dispatch(authSuccess(authData));
      })
      .catch((err) => {
        dispatch(getProfilesFail(err.message));
      });
  };
};

export const getUserProfile = (userid, token) => {
  return (dispatch) => {
    dispatch(getProfilesStart());
    axios
      .get(`/opanda/profile-information/user-profile/${userid}`,null, {
        headers:{authorization:token}
      })
      .then((response) => {
        dispatch(getOneProfilesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getProfilesFail(err.message));
      });
  };
};

export const getCBCCombinationsStart = () => {
  return {
    type: actionTypes.GET_CBC_COMBINATIONS_START,
  };
};

export const getCBCCombinationsSuccess = (cbccombinations) => {
  return {
    type: actionTypes.GET_CBC_COMBINATIONS_SUCCESS,
    cbccombinations: cbccombinations,
  };
};

export const getOneCBCCombinationsSuccess = (cbccombination) => {
  return {
    type: actionTypes.GET_ONE_CBC_COMBINATIONS_SUCCESS,
    cbccombination: cbccombination,
  };
};

export const getCBCCombinationsFail = (error) => {
  return {
    type: actionTypes.GET_CBC_COMBINATIONS_FAIL,
    error: error,
  };
};

export const getCBCCombinations = (token) => {
  return (dispatch) => {
    dispatch(getCBCCombinationsStart());
    axios
      .get(`/opanda/cbc-combinations`, {headers:{Authorization:token}
      })
      .then((response) => {
        dispatch(getCBCCombinationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCBCCombinationsFail(err.message));
      });
  };
};
