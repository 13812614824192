import { OtherLinkButton } from "../../button";
import { CardBody,CardTitle } from "../../text";

const Now = (props) => {
  const instant_now = [];
    return instant_now.length > 0 ? (
      <div className="bg-white px-6 py-3 filter drop-shadow-md rounded-2xl w-full">
        <CardTitle name={props.title} color={props.titleColor} />
        <div className="flex">
          <OtherLinkButton
            color="blue"
            to="/termsofservice"
            name={props.subTitle}
          />
          &nbsp;
          <CardBody name={props.action} />
        </div>
        <OtherLinkButton
          color="blue"
          to="/termsofservice"
          name={props.actionName}
        />
      </div>
    ) : (
      <div></div>
    );
};

export default Now;
