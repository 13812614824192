import axios from "../../axios-base";

export const GET_EXTRA_PACKAGE_START =
  "GET_EXTRA_PACKAGE_START";
export const GET_EXTRA_PACKAGE_FAIL =
  "GET_EXTRA_PACKAGE_FAIL";
export const GET_EXTRA_PACKAGE_SUCCESS =
  "GET_EXTRA_PACKAGE_SUCCESS";


export function school_dashboard_extra_package_init() {
  return {
    type: GET_EXTRA_PACKAGE_START,
  };
}
export function school_dashboard_extra_package_success(data) {
  return {
    type: GET_EXTRA_PACKAGE_SUCCESS,
    payload: data,
  };
}
export function school_dashboard_extra_package_failed(error) {
  return {
    type: GET_EXTRA_PACKAGE_FAIL,
    payload: error,
  };
}
export function school_dashboard_extra_package_start(data) {
  return (dispatch) => {
    dispatch(school_dashboard_extra_package_init());
    axios 
      .get(`/opanda/whois-ogenius-panda-for/extras/active`) 
      .then((response) => {
        dispatch(school_dashboard_extra_package_success(response.data.results));
      })
      .catch((err) => {
        dispatch(school_dashboard_extra_package_failed(err.message));
      });
  };
}
