import axios from "../../../../axios-base";

export const GET_TEACHER_PROGRAMS_START =
  "GET_TEACHER_PROGRAMS_START";
export const GET_TEACHER_PROGRAMS_SUCCESS =
  "GET_TEACHER_PROGRAMS_SUCCESS";
export const GET_ONE_TEACHER_PROGRAMS_SUCCESS =
  "GET_ONE_TEACHER_PROGRAMS_SUCCESS";
export const GET_TEACHER_PROGRAMS_FAIL = "GET_TEACHER_PROGRAMS_FAIL";


export const getTeacherProgramsStart = () => {
  return {
    type: GET_TEACHER_PROGRAMS_START,
  };
};

export const getTeacherProgramsSuccess = (programs) => {
  return {
    type: GET_TEACHER_PROGRAMS_SUCCESS,
    payload: programs,
  };
};

export const getOneTeacherProgramsSuccess = (program) => {
  return {
    type: GET_ONE_TEACHER_PROGRAMS_SUCCESS,
    payload: program,
  };
};

export const getTeacherProgramsFail = (error) => {
  return {
    type: GET_TEACHER_PROGRAMS_FAIL,
    error: error,
  };
};

export const getTeacherPrograms = (token) => {
  return (dispatch) => {
    dispatch(getTeacherProgramsStart());
    axios
      .post(`/opanda/programs/teacher-programs`,null, {headers:{Authorization:`Bearer ${token}`}})
      .then((response) => {
        dispatch(getTeacherProgramsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTeacherProgramsFail(err.message));
      });
  };
};
