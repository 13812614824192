
import parse from 'react-html-parser';
import {
  CardBody,
  CardTitle,
  FeatureTitle,
  SectionTitle,
} from '../../../../../../elements/text';
import { Button, GoBackButton } from '../../../../../../elements/button';
import { useState } from 'react';
import celebration from '../../../../../../../images/celebration.png';
import failed from '../../../../../../../images/test-failed-svgrepo-com.svg';
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { student_dashboard_create_auto_made_challenge_start, student_dashboard_create_teacher_made_challenge_start, student_dashboard_homepage_auto_test_start, student_dashboard_homepage_get_auto_test_answer_sheet_start, student_dashboard_homepage_get_teacher_made_test_answer_sheet_start, student_dashboard_homepage_results_for_auto_made_test_start, student_dashboard_homepage_save_auto_made_test_start, student_dashboard_homepage_save_teacher_made_test_start, student_dashboard_homepage_teacher_made_test_start } from '../../../../../../../store/actions/student.dashboard.tests.subjects.units';

import Spinner from '../../../../../../elements/spinner';
import Uiautotestanswersheet from './autotestanswersheet/uiautotestanswersheet';
import { auto_test_questions_data_answer_sheet } from '../../../../../../../functions/autoTest';


const TestResultsAuto = (props) => {
  const dispatch = useDispatch();
  const userAth = useSelector((state) => state.auth);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const test_params = useParams();
  //----
  const studentDashboardTestsSubjectsUnits = useSelector(
    (state) => state.studentDashboardTestsSubjectsUnits
  );
  const history = useHistory();
  const autoTestProgressList = useSelector(
    (state) => state.autoTestProgressList
  );
  const [checkAnswerSheetView, setCheckAnswerSheetView] = useState(false);
  useEffect(() => {
    dispatch(
      student_dashboard_homepage_results_for_auto_made_test_start({
        token: userAth.token,
        test_id: props.data,
        user_id: userAth.userId,
      })
    );
  }, []);
  //---
  const dataAfterSave = studentDashboardTestsSubjectsUnits.dashboard_homepage_results_for_auto_made_test;
  //---Answer sheet data
  const auto = auto_test_questions_data_answer_sheet(studentDashboardTestsSubjectsUnits.dashboard_homepage_get_auto_test_answer_sheet_test);


  return (
    (!checkAnswerSheetView) ? <>
      <SectionTitle name={(dataAfterSave[3])} color="blue" />
      {/*<CardBody name={(dataAfterSave[1])} />*/}
      <div className="w-full flex flex-col md:flex-row py-5">
        <div className="md:w-50%">
          <div className="flex space-x-2 md:space-x-10">
            <div className="w-28 h-28 my-3 mr-3 p-2 rounded-xl bg-yellow">
              {(!studentDashboardTestsSubjectsUnits.dashboard_homepage_results_for_auto_made_test_loading) ? ((dataAfterSave[2] >= 50) ? <img className="w-full h-full" src={celebration} alt="" /> : <img className="w-full h-full" src={failed} alt="" />) : ""}
            </div>
            <div>
              {(!studentDashboardTestsSubjectsUnits.dashboard_homepage_results_for_auto_made_test_loading) ? ((dataAfterSave[2] >= 50) ? <CardTitle name="Fantastic" color="blue" /> : "") : ""}

              {(!studentDashboardTestsSubjectsUnits.dashboard_homepage_results_for_auto_made_test_loading) ? <CardBody name={`You got ${dataAfterSave[2].toFixed(2)} % on this test`} /> : ""}
              <div className="py-3 flex space-x-2">
                <Button
                  name="Redo"
                  outline="true"
                  color="blue"
                  clicked={() => {
                    const queryString = Object.keys(params).map(function (key) {
                      return key + '=' + params[key]
                    }).join('&');
                    history.replace(`/`);
                    setTimeout(() => history.replace(`/student/features/tests/${test_params.testId}?${queryString}&redo=true&testid=${dataAfterSave[4]}`), 10);


                  }}
                />
                {/* <Button
                  name="Share"
                  outline="true"
                  color="blue"
                  clicked={() => { }}
                /> */}
              </div>
              {(!studentDashboardTestsSubjectsUnits.dashboard_create_auto_made_challenge_launched && !studentDashboardTestsSubjectsUnits.dashboard_create_auto_made_challenge_loading && !studentDashboardTestsSubjectsUnits.dashboard_create_auto_made_challenge_launched_attempt) ? <Button
                name="Challenge Others"
                outline="true"
                color="blue"
                clicked={() => {
                  dispatch(
                    student_dashboard_create_auto_made_challenge_start({
                      token: userAth.token,
                      test_id: dataAfterSave[4],
                      user_id: userAth.userId,
                      userMarks: dataAfterSave[2].toFixed(2),
                      level: userAth.academic_level,
                      subject: params.subject,
                      units: params.unit
                    })
                  );
                }}
              /> : (!studentDashboardTestsSubjectsUnits.dashboard_create_auto_made_challenge_launched && studentDashboardTestsSubjectsUnits.dashboard_create_auto_made_challenge_loading) ? <Spinner size="20" color="blue" /> : <div>{(studentDashboardTestsSubjectsUnits.dashboard_create_auto_made_challenge.length > 0) ? <span style={{ color: "green" }}>Challenge launched successfully! Hooray!!</span> : <span style={{ color: "red" }}>This challenge cannot be launched!! Try again later!!</span>}</div>}
            </div>
          </div>
          <Button
            name="Check answers"
            outline="false"
            color="red"
            clicked={() => {

              const subject = params.subject;
              const level = userAth.academic_level;
              const unit = params.unit;
              const difficulty = params.difficulty;
              dispatch(
                student_dashboard_homepage_get_auto_test_answer_sheet_start({
                  token: userAth.token,
                  user_id: userAth.userId,
                  academic_level: level,
                  units: unit,
                  subject: subject,
                  difficulty: difficulty
                })
              );
              setCheckAnswerSheetView(true);
            }}
          />
        </div>
        <div className="md:w-50%">
          {/*<CardTitle name="Other work" color="blue" />*/}
          {/*<CardBody name="Tests" color="blue" />*/}
          <div className="md:grid grid-cols-2">
            {(!studentDashboardTestsSubjectsUnits.dashboard_homepage_results_for_auto_made_test_loading) ? <div>
              {(dataAfterSave[1]) ? parse(dataAfterSave[1]) : ""}

            </div> : <Spinner size="20" color="blue" />}
            {/*<FeatureItemCard
                    feature="test"
                    title="Test title"
                    level={6}
                    unit="Unit 1"
                    imgsrc="/images/preview/bio.svg"
                    type="Test"
                    due="Today"
                    isMyTest={false}
                    subject="Biology"
                    clicked={() => {
                      history.push(`/student/features/tests/${1}`); //here must a test id
                    }}
                  />
                  <FeatureItemCard
                    feature="test"
                    title="Test title"
                    level={6}
                    unit="Unit 1"
                    imgsrc="/images/preview/bio.svg"
                    type="Quiz"
                    due="Today"
                    isMyTest={false}
                    subject="Biology"
                    clicked={() => {
                      history.push(`/student/features/tests/${1}`); //here must a test id
                    }}
                  />*/}
          </div>
        </div>
      </div>
    </> : (checkAnswerSheetView && studentDashboardTestsSubjectsUnits.dashboard_homepage_get_auto_test_answer_sheet_loading) ? <Spinner size="20" color="blue" /> : checkAnswerSheetView && <Uiautotestanswersheet auto={auto} />
  );
};

export default TestResultsAuto;
