import { useState, useEffect } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import Input from '../../../elements/input';
import PageContent from '../pageContent';
import { CardBody, FeatureTitle, SectionTitle } from '../../../elements/text';
import { useSelector } from 'react-redux';
import { GoBackButton } from '../../../elements/button';
import QuestionItem from './QuestionItem';
import { changeChosenSubject } from '../../../../store/actions/features/pastpaper';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTabs } from 'react-headless-tabs';
import {
  student_pastpapers_subjects_start,
  student_pastpapers_viewership_start,
} from '../../../../store/actions/student.dashboard.pastpapers';
import { useParams } from 'react-router-dom';
import parse from 'react-html-parser';
import { replaceAllLInksWithRealSource } from '../../../../functions/commonfunctions';
import { GiHamburgerMenu } from 'react-icons/gi';
import { GrClose } from 'react-icons/gr';
const Pastpaper = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showSubjects, setShowSubjects] = useState(false);
  const [level, setLevel] = useState('S1');
  const [searchterm, setSearchterm] = useState('');
  const userAth = useSelector((state) => state.auth);
  const studentDashboardPastpapers = useSelector(
    (state) => state.studentDashboardPastpapers
  );
  const chosenSubject = useSelector(
    (state) => state.features.pastpapers.chosenSubject
  );
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const courses_params = useParams();
  let question_number=1;
  useEffect(() => {
    dispatch(
      student_pastpapers_subjects_start({
        user_id: userAth.userId,
        academic_level: userAth.academic_level,
        token: userAth.token,
      })
    );
    //---
    setSelectedSubject(params.subject);
    //--Mark past pastpaper as viewed--
    dispatch(
      student_pastpapers_viewership_start({
        userId: userAth.userId,
        username: userAth.username,
        user_type: 'student',
        academic_level: userAth.academic_level,
        item_id: courses_params.paperId,
        subject: params.subject,
        token: userAth.token,
      })
    );
  }, []);
  // const subjects = [
  //   'Biology',
  //   'Math',
  //   'History',
  //   'Geography',
  //   'Physics',
  //   'English',
  // ];

  // const pastpaperQuestions = [
  //   {
  //     section: 'A',
  //     questions: [
  //       {
  //         number: 1,
  //         question: `<p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>`,
  //         answer: `<p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>`,
  //       },
  //       {
  //         number: 2,
  //         question: `<p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>`,
  //         answer: `<p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>`,
  //       },
  //       {
  //         number: 3,
  //         question: `<p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>`,
  //         answer: `<p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>`,
  //       },
  //     ],
  //   },
  //   {
  //     section: 'B',
  //     questions: [
  //       {
  //         number: 4,
  //         question: `<p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>`,
  //         answer: `<p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>`,
  //       },
  //       {
  //         number: 5,
  //         question: `<p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>`,
  //         answer: `<p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>`,
  //       },
  //     ],
  //   },
  //   {
  //     section: 'C',
  //     questions: [
  //       {
  //         number: 6,
  //         question: `<p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>`,
  //         answer: `<p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>`,
  //       },
  //     ],
  //   },
  // ];
  //---
  const getDataFromBlob = (data) => {
    return parse(replaceAllLInksWithRealSource(data));
  };
  const pastpaperQuestionsPool = [];
  const examTitle = [];
  const subjects_pool_check = (data) => {
    const subjects_data = [];
    const chosen_pastpaper = [];
    if (data && data[0] && data[0].length > 0) {
      for (var i = 0; i < data[0].length; i++) {
        subjects_data.push({
          name: data[0][i].pastpapers_subject,
          pastpapers_academic_level: data[0][i].pastpapers_academic_level,
          pastpapers: [],
        });
        //-----
      }
      //----Collect the pages--
      for (var i = 0; i < data[1].length; i++) {
        if (
          data[1][i].name === params.subject &&
          data[1][i].id + '' === courses_params.paperId
        ) {
          for (var n = 0; n < data[1][i].pastpapers.length; n++) {
            let h = n + 1;
            pastpaperQuestionsPool.push({
              section: 'C',
              questions: [
                {
                  number: h,
                  question: getDataFromBlob(
                    data[1][i].pastpapers[n].past_papers_questions_data
                  ),
                  answer: getDataFromBlob(
                    data[1][i].pastpapers[n].past_papers_questions_answer
                  ),
                },
              ],
            });
          }
          //--
          examTitle.push(data[1][i].pastpapers_summary);
        }
      }

      return subjects_data;
    }
    return subjects_data;
  };
  const subjects_pool = subjects_pool_check(
    studentDashboardPastpapers.pastpapers_subjects
  );

  //---
  const tabs = subjects_pool.map((subject) => subject.name);
  tabs.unshift('initial page');
  const [selectedSubject, setSelectedSubject] = useTabs(
    tabs,
    chosenSubject || 'initial page'
  );

  const [isVisible, setIsVisible] = useState(true);
  const [height, setHeight] = useState(0);
  const listenToScroll = () => {
    let heightToHideFrom = 50;

    const myDiv = document.getElementById("filterDiv");
    const scrollHeight = myDiv.scrollTop;
    setHeight(scrollHeight);

    if (scrollHeight > heightToHideFrom) {
      isVisible && setIsVisible(false);
    } else {
      setIsVisible(true);
    }    
  };
  useEffect(() => {
    const myDiv = document.getElementById("filterDiv");
    myDiv.addEventListener("scroll", listenToScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      myDiv.removeEventListener("scroll", listenToScroll);
    };

  }, []);

  return (
    <PageContent>
      <div className={`md:grid grid-cols-12 w-full min-h-screen-sec`}>
        <div
          className={`p-3 bg-gray-200 ${
            showSubjects ? 'h-screen-sec' : ''
          } transition ease-in-out ${!isVisible && !showSubjects ? "hidden md:block":""}  md:h-full col-span-3`}
        >
          <div className="flex justify-between items-center">
            <Input
              elementType="select"
              elementConfig={{
                startingValue: 'SELECT',
                options: [
                  { value: 'olevel', displayValue: 'Ordinary Level' },
                  { value: 'alevel', displayValue: 'Advanced Level' },
                ],
              }}
              value={level}
              changed={setLevel}
              validation={{ required: true }}
              shouldValidate
              error="Level is required"
            />
            {showSubjects ? (
              <BiChevronUp
                className="md:hidden mx-4"
                size={28}
                color="#183F71"
                onClick={() => setShowSubjects(!showSubjects)}
              />
            ) : (
              <BiChevronDown
                className="md:hidden mx-4"
                size={28}
                color="#183F71"
                onClick={() => setShowSubjects(!showSubjects)}
              />
            )}
          </div>
          <div className={`${showSubjects ? '' : 'hidden'} md:block`}>
            <Input
              elementType="input"
              elementConfig={{
                type: 'text',
                placeholder: 'Search...',
              }}
              value={searchterm}
              changed={setSearchterm}
            />
            <ul className="py-5">
              {subjects_pool.map((subject, index) =>
                searchterm === '' ||
                subject.name
                  .toLowerCase()
                  .includes(searchterm.toLowerCase()) ? (
                  <li key={index}>
                    <div
                      className="py-2 cursor-pointer"
                      onClick={() => {
                        dispatch(changeChosenSubject(subject.name));
                        history.push(`/student/features/pastpapers/`);
                      }}
                    >
                      <CardBody
                        name={subject.name}
                        color="blue"
                        additional={
                          subject.name === selectedSubject ? 'font-bold' : ''
                        }
                      />
                    </div>
                  </li>
                ) : (
                  <></>
                )
              )}
            </ul>
          </div>
        </div>
        <div id="filterDiv"
          className={` ${
            showSubjects ? 'hidden md:block' : ''
          } h-100 md:relative p-3 bg-white md:mb-16 pb-16  md:h-full min-h-myscreen-sec-mobile md:max-h-myscreen md:min-h-myscreen col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
        >
          {/* <FeatureTitle name="Past Paper" /> */}
          <GoBackButton
            link="/student/features/pastpapers"
            action="Back"
            location={parse(
              replaceAllLInksWithRealSource(
                examTitle.length > 0 ? examTitle[0] : ''
              )
            )}
          />
          {pastpaperQuestionsPool.map((section, index) => (
            <div className="py-3" key={index}>
              {/*<SectionTitle  name={`Section ${section.section}`} />*/}

              {section.questions.map((question, index1) => (
                <QuestionItem key={question_number} {...question} number={question_number++} />
              ))}
            </div>
          ))}
        </div>
        <div
          className={`${
            showSubjects ? "block" : ""
          } fixed w-full md:w-auto md:relative p-3 bg-gray-200 bottom-0 flex flex-row md:flex-col justify-between items-center`}
        >
          <div className="flex w-full justify-around md:flex-col md:items-center md:space-y-10">
            {showSubjects ? (
              <GrClose
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowSubjects(!showSubjects)}
              />
            ) : (
              <GiHamburgerMenu
                className="md:hidden animate-pulse"
                size={28}
                color="#183F71"
                onClick={() => setShowSubjects(!showSubjects)}
              />
            )}
          
    
          </div>

        </div>
      </div>
    </PageContent>
  );
};

export default Pastpaper;
