import { useEffect, useRef, useState } from "react";
import Input from "../../../../../elements/input";
import { CardTitle } from "../../../../../elements/text";
import countryOptions from "../../../../../../utils/countries";
import { Provinces, Districts, Sectors } from "rwanda";
import { Button } from "../../../../../elements/button";
import SubjectOfInterestItem from "../../subjectOfInterestItem";
import { useDispatch, useSelector } from "react-redux";
import { completeSignup } from "../../../../../../store/actions";
import Spinner from "../../../../../elements/spinner";
import * as actions from "../../../../../../store/actions";
import constants from "../../../../../../constants/constants";
import { Message } from "../../../../../common/messages";
import { combinations } from "../../../../../../functions/combinationsAndSubjects";

const AcademicInformation = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.completeSignup.loading);
  const userId = useSelector((state) => state.auth.userId);
  const [curriculum, setCurriculum] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [sector, setSector] = useState("");
  const [school, setSchool] = useState("");
  const [academicLevel, setAcademicLevel] = useState("");
  const [yearOfStudy, setYearOfStudy] = useState("");
  const [combination, setCombination] = useState("");
  const [locationInputsDisabled, setLocationInputsDisabled] = useState(false);
  const [schoolId, setSchoolId] = useState("");
  const [districts, setDistricts] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [dataerror, setdataerror] = useState("");
  const [sujectofinterest, setSujectofinterest] = useState("");
  const [schoolStatus, setSchoolStatus] = useState("Public");
  //---
  const user = useSelector((state) => state.auth.user);
  const [country, setCountry] = useState((user?.country) ? user?.country : "");

  if (
    country !== "" &&
    country !== "Rwanda" &&
    locationInputsDisabled !== true
  ) {
    setLocationInputsDisabled(true);
  }
  if (locationInputsDisabled === true && country === "Rwanda") {
    setLocationInputsDisabled(false);
  }
  const interestRef = useRef();
  const schools = useSelector((state) => state.auth.schools);
  const modules = useSelector((state) => state.previewpanda.modules);
  const schoolsLoading = useSelector((state) => state.auth.schoolsLoading);
  const token = useSelector((state) => state.auth.token);
  //const cbccombinations = useSelector((state) => state.profile.cbccombinations);
  const cbccombinations = combinations;
  const subjectsnames = useSelector(
    (state) => state.previewpanda.subjectsnames
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  //SELECTION BUTTON HANDLER
  const [selectedSchoolValue, setSelectedSchoolValue] = useState("");
  const handleSchoolChange = (value) => {
    setSchoolId(value);
  };
  const submitacademicinfo = () => {
    const userObject = {
      // school_linked_id: school?.id,
      // section: school?.id,
      // school_affiliated: school?.id,
      // combination: (academicLevel > 3) ? combination : "N/A",
      // academic_level: academicLevel,
      academic_info_country: country,
      academic_info_curriculum: curriculum,
      // academic_info_school_province: province,
      // academic_info_school_disctrict: district,
      // academic_info_school_sector: sector,
      // academic_info_yearofstudy: yearOfStudy,
      //academic_info_sujectofinterest: interestRef.current.getInterest(),
      userId,
    };
    if (
      // (userObject?.school_affiliated !== undefined && userObject?.school_affiliated !== "") &&
      // userObject?.academic_info_school_province !== "" &&
      // userObject?.academic_info_school_disctrict !== "" &&
      // userObject?.combination !== "" &&
      // userObject?.academic_level !== "" &&
      userObject?.academic_info_country !== "" &&
      userObject?.userId !== ""
    ) {
      dispatch(actions.completeprofileinformation(userObject));
      dispatch(actions.updateUserAccountInfo(userObject));
      dispatch(completeSignup("Academic Information"));
    } else {
      setIsSubmitted(true);
      setdataerror("Some data are missing");
    }
  };
  const setSchoolChange = (value) => {
    setSchool(value);
  };

  const createNewSchool = (value) => {
    if (schoolStatus !== "" && value !== "") {
      if (province !== "" && district !== "" && sector !== "") {
        const obj = {
          province,
          district,
          name: value,
          sector,
          level: "Secondary",
          status: schoolStatus,
        };
        dispatch(actions.createSchoolByStudent(obj, token));
      } else {
        alert("Fill School location");
      }
    } else {
      alert("School status is required");
    }
  };

  const arrayToOptionsHandler = (arr = []) => {
    return arr.map((element) => {
      const item = {};
      item.value = element;
      item.displayValue = element;
      return item;
    });
  };

  const setTheProvince = (value) => {
    setProvince(value);
    setDistricts(arrayToOptionsHandler(Districts(value)));
    setSectors([]);
  };

  const setTheDistrict = (value) => {
    setDistrict(value);
    setSectors(arrayToOptionsHandler(Sectors(province, value)));
  };

  useEffect(() => {
    dispatch(actions.getOGeniusModules());
    dispatch(actions.getCBCCombinations(token));
    if (district !== null) {
      dispatch(actions.getDistrictSchools(district, token, schoolStatus));
    }
  }, [district, schoolStatus]);

  return (
    <>
      {loading ? (
        <Spinner size="32" color="blue" />
      ) : (
        <div className="bg-white drop-shadow-md mt-5 p-2 md:px-10 md:h-tab-screen md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
          <CardTitle name="Academic Information" color="black" />
          <div className="flex space-x-2 md:grid grid-cols-3 gap-2">
            <Input
              label="Country"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: countryOptions,
              }}
              value={country}
              changed={setCountry}
              validation={{ required: true }}
              shouldValidate
              error="Country is required"
            />
            <Input
              label="Curriculum"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: modules,
              }}
              value={curriculum}
              changed={setCurriculum}
              validation={{ required: true }}
              shouldValidate
              error="Curriculum is required"
            />
          </div>
          <div style={{ display: "none" }}><CardTitle name="School Information" color="black" />
            <div className="md:hidden">
              <div className="flex space-x-2">
                <Input
                  label="Province"
                  elementType="select"
                  elementConfig={{
                    startingValue: "SELECT",
                    options: arrayToOptionsHandler(Provinces()),
                    disabled: locationInputsDisabled,
                  }}
                  value={province}
                  changed={setTheProvince}
                  validation={{ required: true }}
                  shouldValidate
                  error="Province is required"
                />
                <Input
                  label="District"
                  elementType="select"
                  elementConfig={{
                    startingValue: "SELECT",
                    options: districts,
                    disabled: locationInputsDisabled,
                  }}
                  value={district}
                  changed={setTheDistrict}
                  validation={{ required: true }}
                  shouldValidate
                  error="District is required"
                />
              </div>
              <div className="flex space-x-2">
                <Input
                  label="Sector"
                  elementType="select"
                  elementConfig={{
                    startingValue: "SELECT",
                    options: sectors,
                    disabled: locationInputsDisabled,
                  }}
                  value={sector}
                  changed={setSector}
                  validation={{ required: true }}
                  shouldValidate
                  error="Sector is required"
                />
                <Input
                  label="School Status"
                  elementType="select"
                  elementConfig={{
                    startingValue: "SELECT",
                    options: [
                      { value: "Public", displayValue: "Public" },
                      { value: "Private", displayValue: "Private" }
                    ],
                  }}
                  value={schoolStatus}
                  changed={setSchoolStatus}
                  validation={{ required: true }}
                  shouldValidate
                  error="School Status is required"
                />
              </div>
              <div className="flex space-x-2">
                {(!schoolsLoading) ? <Input
                  label="School"
                  elementType="selectschool"
                  elementConfig={{
                    startingValue: "SELECT",
                    options: schools,
                    disabled: locationInputsDisabled,
                  }}
                  value={school}
                  changed={setSchool}
                  validation={{ required: true }}
                  shouldValidate
                  error="School is required"
                /> : ""}
                <Input
                  label="Academic Level"
                  elementType="select"
                  elementConfig={{
                    startingValue: "SELECT",
                    options: constants.cbclevels,
                  }}
                  value={academicLevel}
                  changed={setAcademicLevel}
                  validation={{ required: true }}
                  shouldValidate
                  error="Your academic level is required"
                />
              </div>
              <div className="flex space-y-2">
                {/* <Input
                label="Year of study"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: constants.yearsofstudy,
                }}
                value={yearOfStudy}
                changed={setYearOfStudy}
                validation={{ required: true }}
                shouldValidate
                error="Year of study is required"
              /> */}
                {(academicLevel > 3) ? <Input
                  label="Combination"
                  elementType="select"
                  elementConfig={{
                    startingValue: "SELECT",
                    options: cbccombinations,
                  }}
                  value={combination}
                  changed={setCombination}
                  validation={{ required: true }}
                  shouldValidate
                  error="Combination is required"
                /> : ""}
              </div>
            </div>
            <div className="hidden md:grid grid-cols-3 gap-2">
              <Input
                label="Province"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: arrayToOptionsHandler(Provinces()),
                  disabled: locationInputsDisabled,
                }}
                value={province}
                changed={setTheProvince}
                validation={{ required: true }}
                shouldValidate
                error="Province is required"
              />
              <Input
                label="District"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: districts,
                  disabled: locationInputsDisabled,
                }}
                value={district}
                changed={setTheDistrict}
                validation={{ required: true }}
                shouldValidate
                error="District is required"
              />
              <Input
                label="Sector"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: sectors,
                  disabled: locationInputsDisabled,
                }}
                value={sector}
                changed={setSector}
                validation={{ required: true }}
                shouldValidate
                error="Sector is required"
              />
              <Input
                label="School Status"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: [
                    { value: "Public", displayValue: "Public" },
                    { value: "Private", displayValue: "Private" }
                  ],
                }}
                value={schoolStatus}
                changed={setSchoolStatus}
                validation={{ required: true }}
                shouldValidate
                error="School Status is required"
              />
              {(!schoolsLoading) ? <Input
                label="School"
                elementType="selectschool"
                elementConfig={{
                  startingValue: "SELECT",
                  options: schools,
                }}
                value={school}
                changed={setSchoolChange}
                validation={{ notEmpty: true }}
                shouldValidate
                onCreateOption={createNewSchool}
                error="School is required"
              /> : ""}
              <Input
                label="Academic Level"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: constants.cbclevels,
                }}
                value={academicLevel}
                changed={setAcademicLevel}
                validation={{ required: true }}
                shouldValidate
                error="Your academic level is required"
              />
              {/* <Input
              label="Year of study"
              elementType="select"
              elementConfig={{
                startingValue: "SELECT",
                options: constants.yearsofstudy,
              }}
              value={yearOfStudy}
              changed={setYearOfStudy}
              validation={{ required: true }}
              shouldValidate
              error="Year of study is required"
            /> */}
              {(academicLevel > 3) ? <Input
                label="Combination"
                elementType="select"
                elementConfig={{
                  startingValue: "SELECT",
                  options: cbccombinations,
                }}
                value={combination}
                changed={setCombination}
                validation={{ required: true }}
                shouldValidate
                error="Combination is required"
              /> : ""}
            </div>
            <SubjectOfInterestItem ref={interestRef} /></div>
          <Message type={"error"} viewable={true} message={dataerror} />
          <div className="flex space-x-2 justify-center py-5">
            {/* <Button name="Previous" outline="true" color="blue" /> */}
            <Button
              name="Next"
              outline="true"
              color="blue"
              clicked={submitacademicinfo}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AcademicInformation;
