import { useEffect, useRef, useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import parse from "react-html-parser";
import { AiOutlineLeftCircle, AiOutlineRightCircle } from "react-icons/ai";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { FaComments, FaLock, FaLockOpen } from "react-icons/fa";
import Comments from "../../../common/features/CommentsOnTheBook";
import { Button, GoBackButton } from "../../../elements/button";
import Input from "../../../elements/input";
import { CardBody, CardTitle } from "../../../elements/text";
import PageContent from "../pageContent";
import EndOfResources from "../../../common/features/EndOfResources";
import { useDispatch } from "react-redux";
import TextSelector from "../../../elements/TextSelector";
import { useSelector } from "react-redux";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";
import { useParams } from "react-router-dom";
import { student_dashboard_homepage_get_books_start, student_dashboard_homepage_get_book_page_mark_as_viewed_start, student_dashboard_homepage_get_book_page_start } from "../../../../store/actions/student.dashboard.library";
import { decrypt } from "../../../../functions/cyphers";
import MyPDF from "./pdf";
import Spinner from "../../../elements/spinner";



const Book = (props) => {
  const chapters = [
    {
      name: "Deserunt laboris",
      viewed: true,
    },
    {
      name: "Adipisicing ",
      viewed: false,
    },
    {
      name: "Dolore minim",
      viewed: false,
    },
    {
      name: "Dolore ex ",
      viewed: false,
    },
    {
      name: "Sint eiusmod fugiat.",
      viewed: false,
    },
    {
      name: "Adipisicing occaecat",
      viewed: false,
    },
    {
      name: "Dolore minimum",
      viewed: false,
    },
    {
      name: "Dolore ex enim",
      viewed: false,
    },
  ];
  const params = useParams();
  const [searchterm, setSearchterm] = useState("");
  const [showComments, setShowComments] = useState(false);
  const [commentsCount, setcommentsCount] = useState(0);
  const [showChapters, setShowChapters] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const userAuth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const selectedText = useSelector(
    (state) => state.languages.selectedCommentText
  );

  const panelDiv = useRef();

  const scrollTop = () => {
    panelDiv.current.scrollTo({ top: 0, behavior: "smooth" });
  };
  const comments_counter_pull = (newComments) => {
    setcommentsCount(newComments);
  };
  const urlSearchParams = new URLSearchParams(window.location.search);
  const paramsO = Object.fromEntries(urlSearchParams.entries());
  const [isADocumentToView, setIsADocumentToView] = useState(false);
  const [chosenPage, setChosenPage] = useState(0);


  useEffect(() => {
    const bookId = params.bookId;
    //------------------------
    if (paramsO.bookType === "digital") {
      dispatch(
        student_dashboard_homepage_get_book_page_start({
          token: userAuth.token,
          user_id: userAuth.userId,
          academic_level: userAuth.academic_level,
          bookId: bookId
        })
      );
      //---
      dispatch(
        student_dashboard_homepage_get_book_page_mark_as_viewed_start({
          token: userAuth.token,
          user_id: userAuth.userId,
          academic_level: userAuth.academic_level,
          bookId: 0 + "###" + bookId
        })
      );
    } else {
      setIsADocumentToView(true);


    }

    scrollTop();
  }, []);
  const studentDashboardLibrary = useSelector(
    (state) => state.studentDashboardLibrary
  );
  const getChapters = (data_pool) => {
    if (data_pool?.data?.results) {
      return data_pool?.data?.results
    }
    return []

  }
  const tabs = getChapters(studentDashboardLibrary.student_dashboard_homepage_get_books).map((chapter, index) => chapter.chapter_number + "" + index);
  const [selectedChapter, setSelectedChapter] = useTabs(tabs);


  return (
    <PageContent>
      <div
        className={`${showComments ? "relative" : ""
          } md:grid grid-cols-12 w-full min-h-screen-sec`}
      >
        {(!isADocumentToView) ? <div
          className={`p-3 bg-gray-200 ${showChapters ? "h-screen-sec" : ""
            } md:h-full ${(!isADocumentToView) ? "col-span-3" : "col-span-12"} `}
        >
          <div className="flex justify-between items-center">
            <CardTitle name={`Book name: ${(getChapters(studentDashboardLibrary.student_dashboard_homepage_get_books).length > 0) ? getChapters(studentDashboardLibrary.student_dashboard_homepage_get_books)[0].name : ""}`} color="blue" />
          </div>
          <div className={`${showChapters ? "" : "hidden"} md:block`}>
            <Input
              elementType="input"
              elementConfig={{
                type: "text",
                placeholder: "Search...",
              }}
              value={searchterm}
              changed={setSearchterm}
            />
            {(getChapters(studentDashboardLibrary.student_dashboard_homepage_get_books).length > 0) ? <ul className="py-5  p-5 h-90 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100">
              {getChapters(studentDashboardLibrary.student_dashboard_homepage_get_books)?.filter((chapter, index) => ("Page " + chapter.chapter_number?.match(/\d+/)[0]).toLowerCase().includes(searchterm.toLowerCase()) || searchterm === "")?.map((chapter, index) => (
                <li
                  key={index}
                  className="flex items-center justify-between py-2 cursor-pointer"
                  onClick={() => {
                    setChosenPage(index)
                    setSelectedChapter(`${chapter.chapter_number}${index}`);
                    setShowChapters(false);
                    //--------Mark as opened-----------
                    dispatch(
                      student_dashboard_homepage_get_book_page_mark_as_viewed_start({
                        token: userAuth.token,
                        user_id: userAuth.userId,
                        academic_level: userAuth.academic_level,
                        bookId: index + "###" + params.bookId
                      })
                    );
                  }}
                >
                  <CardBody
                    name={`${(chapter.chapter_number?.match(/\d+/)[0]) ? "Page " + chapter.chapter_number?.match(/\d+/)[0] : ""}`}
                    color="blue"
                    additional={
                      chapter.chapter_number + "" + index === selectedChapter
                        ? "font-bold flex-1"
                        : "flex-1"
                    }
                  />
                  {chapter.viewed ? (
                    <FaLockOpen
                      color="#183F71"
                      className="shrink-0 w-16px h-16px"
                    />
                  ) : (
                    <FaLock
                      color="#183F71"
                      className="shrink-0 w-16px h-16px"
                    />
                  )}
                </li>
              ))}
            </ul> : <Spinner size="20" color="blue" />}
          </div>
        </div> : ""}
        <div
          ref={panelDiv}
          className={`${showChapters ? "hidden md:block" : ""
            } md:relative p-3 bg-white md:h-full h-screen-second mb-16 pb-16 md:max-h-screen-sec ${(!isADocumentToView) ? "col-span-8" : "col-span-11"} overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
        >
          {(!isADocumentToView && getChapters(studentDashboardLibrary.student_dashboard_homepage_get_books)?.length > 0) ?
            (getChapters(studentDashboardLibrary.student_dashboard_homepage_get_books).length <= 0) ? <Spinner size="40" color="blue" /> : [getChapters(studentDashboardLibrary.student_dashboard_homepage_get_books)[chosenPage]]?.map((chapter, index) => (
              (getChapters(studentDashboardLibrary.student_dashboard_homepage_get_books).length > 0) ? <TabPanel
                key={index}
                hidden={false}
                className="transform transition duration-500 ease-in-out"
              ><span onClick={() => {
                const baseUrl = window.location.origin;
                window.location.href = `${baseUrl}/teacher/features/library`;
              }}><GoBackButton
                    link={`#`}
                    action="Back"
                    location="PDF Book"
                  /></span>
                <CardBody
                  name={`Page ${pageNumber + 1}`}
                  color="blue"
                  additional="font-bold"
                />
                <TextSelector onSelection={() => setShowComments(true)}>
                  <div className="py-3 bookcontent">
                    {chapter?.pages && parse(chapter?.pages[pageNumber]?.panda_pages_data || "")}
                    {chapter?.pages?.length - 1 === pageNumber ? <EndOfResources /> : null}
                  </div>
                </TextSelector>
                <div className="flex justify-between items-start mb-2">
                  {(pageNumber < chapter?.pages?.length - 1) ? <Button
                    name="Previous"
                    outline="true"
                    color="blue"
                    clicked={() => {
                      if (pageNumber > 0) {
                        setPageNumber((pageNumber) => pageNumber - 1);
                      }
                    }}
                  /> : ""}
                  {(chapter?.pages?.length && chapter?.pages?.length > 0) ? <CardBody
                    name={`${pageNumber + 1}/${chapter?.pages?.length}`}
                    color="blue"
                    additional="col-span-5 text-center"
                  /> : <Spinner size="40" color="blue" />}
                  <div className="flex flex-col">
                    {(pageNumber < chapter?.pages?.length && pageNumber > 0) ? <Button
                      name="Next"
                      outline="true"
                      color="blue"
                      clicked={() => {
                        if (pageNumber < chapter?.pages?.length - 1) {
                          setPageNumber((pageNumber) => pageNumber + 1);
                        }
                        if (pageNumber === chapter?.pages?.length - 1) {
                          setPageNumber(0);
                          setSelectedChapter(chapter?.chapter_number);
                        }
                      }}
                    /> : ""}
                    {pageNumber === chapter?.pages?.length - 1 && chapter && (
                      <CardBody
                        name={`Page ${pageNumber + 1}`}
                        color="blue"
                        additional="opacity-60"
                      />
                    )}
                  </div>
                </div>
              </TabPanel> : <Spinner size="50" color="blue" />
            )) : <MyPDF url={`${decrypt("O'Genius Panda Library", paramsO.dataUrl)}`} />}
        </div>
        <div
          className={`${showChapters ? "block" : ""
            } fixed w-full md:w-auto md:relative p-3 bg-gray-200 bottom-0 flex flex-row md:flex-col justify-between items-center`}
        >
          <div className="flex w-full justify-around md:flex-col md:items-center md:space-y-10">
            {showChapters ? (
              <GrClose
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowChapters(!showChapters)}
              />
            ) : (
              <GiHamburgerMenu
                className="md:hidden"
                size={28}
                color="#183F71"
                onClick={() => setShowChapters(!showChapters)}
              />
            )}
            <div className="relative cursor-pointer">
              <FaComments
                color="#183F71"
                size={36}
                onClick={() => {
                  setShowComments(!showComments);
                }}
              />
              {commentsCount > 0 ? (
                <span
                  style={{ fontWeight: "bold !important" }}
                  className="absolute top-1 left-5 inline-flex items-center justify-center px-1 py-px text-xs font-bold leading-none bg-white rounded-full"
                >
                  {commentsCount}
                </span>
              ) : (
                <></>
              )}
            </div>
            {showComments ? (
              <AiOutlineRightCircle
                className="cursor-pointer hidden md:block"
                size={36}
                color="#183F71"
                onClick={() => {
                  setShowComments(false);
                }}
              />
            ) : (
              <AiOutlineLeftCircle
                className="cursor-pointer hidden md:block"
                size={36}
                color="#183F71"
                onClick={() => setShowComments(true)}
              />
            )}
          </div>

          {showComments ? (
            <Comments
              getcomments={comments_counter_pull}
              selected={selectedText}
              bookId={params.bookId}
            />
          ) : null}
        </div>
      </div>
    </PageContent>
  );
};

export default Book;
