import axios from "../../axios-base";
import { getCachedData } from "../../shared/caching";

export const TEACHER_DASHBOARD_LIBRARY_WRITE_DOC_INIT =
  "TEACHER_DASHBOARD_LIBRARY_WRITE_DOC_INIT";
export const TEACHER_DASHBOARD_LIBRARY_WRITE_DOC_SUCCESS =
  "TEACHER_DASHBOARD_LIBRARY_WRITE_DOC_SUCCESS";
export const TEACHER_DASHBOARD_LIBRARY_WRITE_DOC_FAILED =
  "TEACHER_DASHBOARD_LIBRARY_WRITE_DOC_FAILED";

export function teacher_dashboard_library_write_doc_init() {
  return {
    type: TEACHER_DASHBOARD_LIBRARY_WRITE_DOC_INIT
  };
}
export function teacher_dashboard_library_write_doc_success(data) {
  return {
    type: TEACHER_DASHBOARD_LIBRARY_WRITE_DOC_SUCCESS,
    payload: data
  };
}
export function teacher_dashboard_library_write_doc_failed(error) {
  return {
    type: TEACHER_DASHBOARD_LIBRARY_WRITE_DOC_FAILED,
    payload: error
  };
}

export function teacher_dashboard_library_write_doc_start(data) {
  return (dispatch) => {
    dispatch(teacher_dashboard_library_write_doc_init());
    dispatch(teacher_dashboard_library_write_doc_success({
      bookTitle: data.bookTitle,
      description: data.description,
      createLevel: data.createLevel,
      subject: data.subject,
      bookId: data.bookId,
    }));

  };
}
