import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCachedData_mine } from '../../../../shared/caching';
import Spinner from '../../../elements/spinner';
import { propTypes } from 'react-bootstrap/esm/Image';

const RadioOptions = ({ selectedOption, onOptionChange, classId }) => {
  const options = [
    { label: 'Published', value: 'published' },
    { label: 'Unpublished', value: 'unpublished' },
    { label: 'Archived', value: 'archived' },
  ];
  const [loader, setLoader] = useState(false);
  const token = useSelector((state) => state?.auth?.token);

  return (
    <div className="flex flex-col">
      {(!loader) ? options.map((option, index) => (
        <div key={index} className="flex items-center mb-2">
          <input
            type="radio"
            id={option.value}
            name="status"
            value={option.value}
            checked={selectedOption === option.value}
            onChange={() => {
              if (parseInt(classId) === 0) {
                alert('Please save a course first!!?!!!')
              } else {
                setLoader(true);
                getCachedData_mine('cbcsubject-cache-subjects', `/opanda/summarynotes/cbcsubject_course_publish_status`, 'POST', 10800,
                  {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                  }, {
                  classId: classId,
                  value: option.value
                })
                  .then((response) => {
                    setLoader(false);
                    onOptionChange(option.value)
                    if (propTypes.clearCache()) {
                      propTypes.clearCache()
                    }

                  })
                  .catch((err) => {
                    console.log(err);
                  });

              }
            }}
            className="form-radio h-5 w-5 text-indigo-600 border-indigo-600 focus:ring-indigo-500"
          />
          <label htmlFor={option.value} className="ml-2 text-gray-700">
            {option.label}
          </label>
        </div>
      )) : <Spinner size="32" color="blue" />}
    </div>
  );
};

export default RadioOptions;
