import axios from "../../axios-base";

import { getCachedData } from "../../shared/caching";
import fileDownload from "js-file-download";

export const SCHOOL_USERS_LASTWEEK_SESSIONDURATION_INIT =
  "SCHOOL_USERS_LASTWEEK_SESSIONDURATION_INIT";
export const SCHOOL_USERS_LASTWEEK_SESSIONDURATION_SUCCESS =
  "SCHOOL_USERS_LASTWEEK_SESSIONDURATION_SUCCESS";
export const SCHOOL_USERS_LASTWEEK_SESSIONDURATION_FAILED =
  "SCHOOL_USERS_LASTWEEK_SESSIONDURATION_FAILED";

  export const SCHOOL_USERS_LAST_SEEN_DATE_INIT =
  "SCHOOL_USERS_LAST_SEEN_DATE_INIT";
export const SCHOOL_USERS_LAST_SEEN_DATE_SUCCESS =
  "SCHOOL_USERS_LAST_SEEN_DATE_SUCCESS";
export const SCHOOL_USERS_LAST_SEEN_DATE_FAILED =
  "SCHOOL_USERS_LAST_SEEN_DATE_FAILED";  

export function school_users_lastweek_sessionduration_init() {
  return {
    type: SCHOOL_USERS_LASTWEEK_SESSIONDURATION_INIT,
  };
}
export function school_users_lastweek_sessionduration_success(data) {
  return {
    type: SCHOOL_USERS_LASTWEEK_SESSIONDURATION_SUCCESS,
    payload: data,
  };
}
export function school_users_lastweek_sessionduration_failed(error) {
  return {
    type: SCHOOL_USERS_LASTWEEK_SESSIONDURATION_FAILED,
    payload: error,
  };
}

export function school_users_lastweek_sessionduration_start(data) {
  return (dispatch) => {
    dispatch(school_users_lastweek_sessionduration_init());

    axios
      .post(`/opanda/student/lastweeksessionduration`, data, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        dispatch(
          school_users_lastweek_sessionduration_success(response.data.response)
        );
      })
      .catch((err) => {
        dispatch(school_users_lastweek_sessionduration_failed(err.message));
      });
  };
}

export function school_users_last_seen_date_init() {
  return {
    type: SCHOOL_USERS_LAST_SEEN_DATE_INIT,
  };
}
export function school_users_last_seen_date_success(data) {
  return {
    type: SCHOOL_USERS_LAST_SEEN_DATE_SUCCESS,
    payload: data,
  };
}
export function school_users_last_seen_date_failed(error) {
  return {
    type: SCHOOL_USERS_LAST_SEEN_DATE_FAILED,
    payload: error,
  };
}

export function school_users_last_seen_date_start(data) {
  return (dispatch) => {
    dispatch(school_users_last_seen_date_init());

    axios
      .post(`/opanda/student/lastseendate`, data, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        dispatch(
          school_users_last_seen_date_success(response.data.response[0].lastdate)
        );
      })
      .catch((err) => {
        dispatch(school_users_last_seen_date_failed(err.message));
      });
  };
}
