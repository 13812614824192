import { updateObject } from "../../shared/utility";
import {
  TEACHER_DASHBOARD_LIBRARY_WRITE_DOC_INIT,
  TEACHER_DASHBOARD_LIBRARY_WRITE_DOC_SUCCESS,
  TEACHER_DASHBOARD_LIBRARY_WRITE_DOC_FAILED,
} from "../actions/teacher.dashboard.library";
//--
const initialReducer = {
  bookCreateLoading: false,
  bookTitle: "",
  description: "",
  createLevel: "",
  subject: "",
  message: "",
  bookId: 0
};

function teacher_dashboard_library_write_doc_init(state, action) {
  return updateObject(state, {
    bookCreateLoading: false,
  });
}
function teacher_dashboard_library_write_doc_success(state, action) {
  return updateObject(state, {
    bookTitle: action.payload.bookTitle,
    description: action.payload.description,
    createLevel: action.payload.createLevel,
    subject: action.payload.subject,
    message: "success",
    bookId: action.payload.bookId
  });
}
function teacher_dashboard_library_write_doc_failed(state, action) {
  return updateObject(state, {
    message: action.payload,
  });
}


const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case TEACHER_DASHBOARD_LIBRARY_WRITE_DOC_INIT:
      return teacher_dashboard_library_write_doc_init(state, action);
    case TEACHER_DASHBOARD_LIBRARY_WRITE_DOC_SUCCESS:
      return teacher_dashboard_library_write_doc_success(state, action);
    case TEACHER_DASHBOARD_LIBRARY_WRITE_DOC_FAILED:
      return teacher_dashboard_library_write_doc_failed(state, action);

    default:
      return state;
  }
};
export default reducer;
