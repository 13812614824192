import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import PageContent from "./../../../pageContent";
import Modal from "../../../../../elements/modal/modal";
import { PageBody, SectionTitle } from "../../../../../elements/text";
import {
  Button,
  ButtonLink,
  GoBackButton,
  TextButton,
} from "../../../../../elements/button";
import Spinner from "../../../../../elements/spinner";
import Input from "../../../../../elements/input";
import Notification from "../../../../../elements/notification/notification";
import SuccessIcon from "../../../../../../images/icons/success.svg";
import Table from "../../../../../elements/table/table";

const CreateForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.programs.programsLoading);

  const tableheader1 = ["Student names", "Class", "Status", "Action"];
  const tablebody1 = [
    {
      td1: { data: "Havugiyambaye Marcel", type: "text" },
      td2: { data: "S2 B", type: "text" },
      td3: { data: "Registered", type: "text" },
      td4: { data: <TextButton name="Add" color="red" />, type: "component" },
    },
    {
      td1: { data: "Niyitegeka Douce", type: "text" },
      td2: { data: "S2 B", type: "text" },
      td3: { data: "Registered", type: "text" },
      td4: { data: <TextButton name="Add" color="red" />, type: "component" },
    },
    {
      td1: { data: "Niyitegeka Douce", type: "text" },
      td2: { data: "S2 B", type: "text" },
      td3: { data: "Registered", type: "text" },
      td4: { data: <TextButton name="Add" color="red" />, type: "component" },
    },
    {
      td1: { data: "Niyitegeka Douce", type: "text" },
      td2: { data: "S2 B", type: "text" },
      td3: { data: "Registered", type: "text" },
      td4: { data: <TextButton name="Add" color="red" />, type: "component" },
    },
    {
      td1: { data: "Niyitegeka Douce", type: "text" },
      td2: { data: "S2 B", type: "text" },
      td3: { data: "Registered", type: "text" },
      td4: { data: <TextButton name="Add" color="red" />, type: "component" },
    },
    {
      td1: { data: "Niyitegeka Douce", type: "text" },
      td2: { data: "S2 B", type: "text" },
      td3: { data: "Registered", type: "text" },
      td4: { data: <TextButton name="Add" color="red" />, type: "component" },
    },
    {
      td1: { data: "Niyitegeka Douce", type: "text" },
      td2: { data: "S2 B", type: "text" },
      td3: { data: "Registered", type: "text" },
      td4: { data: <TextButton name="Add" color="red" />, type: "component" },
    },
   
  ];

  return (
    <PageContent>
      <div
        className={`relative w-full p-3 col-span-9 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100`}
      >
        <GoBackButton
          link="/teacher/features/classes"
          action="Add student"
          location="Subjects"
        />
        {loading ? (
          <Spinner size="32" color="blue" />
        ) : (
          <div className="bg-white drop-shadow-md mt-5 p-2 md:px-10 md:h-tab-screen md:overflow-y-auto scrollbar-thin scrollbar-thumb-blue scrollbar-track-gray-100">
            <div className="relative overflow-x-auto sm:rounded-lg">
              <Table thead={tableheader1} tbody={tablebody1} />
            </div>
            <div className="flex space-x-2 justify-center py-5">
              <ButtonLink
                name="Cancel"
                outline="true"
                color="blue"
                page="/teacher/features/subjects"
              />
              <Button
                name="Next"
                outline="false"
                color="blue"
                clicked={() => history.push("/teacher/features/subjects/complete/2/admins")}
              />
            </div>
          </div>
        )}
      </div>
      <div className="w-35% mt-5">
        <Notification auth />
      </div>
    </PageContent>
  );
};

const getProgramNameList = (programObject) => {
  let names = Object.keys(programObject);
  return names;
};
const getSectionListByProgram = (programObject, program = "CBC") => {
  let sections = Object.keys(programObject[program].structure);
  return sections;
};
const getclassesList = (
  programObject,
  program = "CBC",
  section = "Ordinary level"
) => {
  let classes = programObject[program]["structure"][section]["Classes"];
  return classes;
};
const getcombinationsList = (
  programObject,
  program = "CBC",
  section = "Advanced level"
) => {
  let combinations = [];
  if (section.includes("Ordinary level")) {
  } else {
    combinations = Object.keys(
      programObject[program]["structure"][section].Combinations
    );
  }
  return combinations;
};

const getsubjectsList = (
  programObject,
  program = "CBC",
  section = "Ordinary level",
  combination = ""
) => {
  let subjects = [];
  if (section.includes("Ordinary level")) {
    subjects = programObject[program]["structure"][section].Subjects;
  } else {
    if (combination !== undefined && combination !== "") {
      console.log(combination);
      subjects =
        programObject[program]["structure"][section]["Combinations"][
          combination
        ]["default"];
      subjects.push(programObject[program]["structure"][section]["Subjects"]);
    } else {
      subjects.push(programObject[program]["structure"][section].Subjects);
    }
  }

  return subjects;
};

export default CreateForm;
